export default {
  "container": "coq",
  "text-content": "coZ",
  "text-wrapper": "coR",
  "actions-container": "coQ",
  "illustration": "coe",
  "feature-tour-link": "coB",
  "link-illustration": "coE",
  "lottie": "cod"
};
