/* import __COLOCATED_TEMPLATE__ from './inail.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

// @ts-expect-error
import { ERROR_KEYS } from 'qonto/constants/f24';

const MAX_ITEMS_COUNT = 3;

const FIELD_NAME_KEYS = {
  branchCode: 'f24.manual-declaration.module.inail.field1',
  companyCode: 'f24.manual-declaration.module.inail.field2',
  checkCode: 'f24.manual-declaration.module.inail.field3',
  referenceNumber: 'f24.manual-declaration.module.inail.field4',
  taxType: 'f24.manual-declaration.module.inail.field5',
  taxAmount: 'f24.manual-declaration.module.inail.field6',
  compensationAmount: 'f24.manual-declaration.module.inail.field7',
};

interface F24FormErarioSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLFieldSetElement;
}

export default class F24FormErario extends Component<F24FormErarioSignature> {
  fieldNameKeys = FIELD_NAME_KEYS;

  @action
  // @ts-expect-error
  getErrorKey(code) {
    return ERROR_KEYS[code] ?? ERROR_KEYS.generic_error;
  }

  get isAddItemDisabled() {
    // @ts-expect-error
    return this.args.taxInformation.inailTaxList.length >= MAX_ITEMS_COUNT;
  }

  get totalTax() {
    return this.sumUp('taxAmount');
  }

  get totalCompensation() {
    return this.sumUp('compensationAmount');
  }

  get netTotal() {
    return Number(this.totalTax - this.totalCompensation).toFixed(2);
  }

  // @ts-expect-error
  sumUp(attributeName) {
    return (
      // @ts-expect-error
      this.args.taxInformation.inailTaxList
        // @ts-expect-error
        .reduce((acc, item) => {
          let value = item[attributeName] || 0;
          return acc + Number(value);
        }, 0)
        .toFixed(2)
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'F24::Form::Inail': typeof F24FormErario;
  }
}
