/* import __COLOCATED_TEMPLATE__ from './transfer.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { Disclaimer } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';

import { FINANCING_TRANSFER_DECLINED_REASON } from 'qonto/constants/financing';
import {
  ERROR_ALREADY_CANCELED,
  ERROR_CANNOT_CANCEL,
  TRANSFER_FLOW_ORIGIN,
} from 'qonto/constants/transfers';
import { ERROR_CANNOT_REFUND, ERROR_REFUND_ALREADY_REQUESTED } from 'qonto/models/direct-debit';
import { Amount } from 'qonto/react/components/transactions/sidebar/header/amount';
import { Counterparty } from 'qonto/react/components/transactions/sidebar/header/counterparty';
import { Date } from 'qonto/react/components/transactions/sidebar/header/date';
import { Type } from 'qonto/react/components/transactions/sidebar/header/type';
import { formatExchangeRateWithDetails } from 'qonto/utils/international-out/format';
import { getPaymentPurposeLabel } from 'qonto/utils/transfers';

interface SidebarHeaderTransferSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class SidebarHeaderTransfer extends Component<SidebarHeaderTransferSignature> {
  amount = Amount;
  disclaimerInline = Disclaimer.Inline;
  Counterparty = Counterparty;
  Date = Date;
  type = Type;

  @service declare abilities: Services['abilities'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare intl: Services['intl'];
  @service declare modals: Services['modals'];
  @service declare flowLinkManager: Services['flowLinkManager'];
  @service declare segment: Services['segment'];

  Disclaimer = Disclaimer.Block;

  get subject() {
    // @ts-expect-error
    return this.args.highlightedItem.subject;
  }

  get shouldDisplayProofSection() {
    // @ts-expect-error
    let { isDirectDebit, isIncome } = this.args.highlightedItem;
    return !isIncome && !isDirectDebit;
  }

  get unavailableProofDisclaimer() {
    // @ts-expect-error
    let { isInternationalOutTransfer } = this.args.highlightedItem;
    if (isInternationalOutTransfer) {
      return this.intl.t('transactions.sidebar.international-out.proof_not_available');
    }
    return this.intl.t('transactions.sidebar.proof_not_available');
  }

  get shouldDisplayGenerateProofButton() {
    let isCompleted = this.subject.get('isCompleted');

    // @ts-expect-error
    if (this.args.highlightedItem.isInternationalOutTransfer) {
      return isCompleted && this.abilities.can('read transfer') && this.subject.get('proofPdfUrl');
    }

    return (
      // @ts-expect-error
      isCompleted && (this.args.isUserInitiator || this.abilities.can('generate proof transfer'))
    );
  }

  get shouldDisplayProofDisclaimer() {
    let defaultConditions =
      !this.subject.get('isDeclined') &&
      !this.subject.get('isCanceled') &&
      // @ts-expect-error
      !this.args.highlightedItem.isSwiftIncome;

    // @ts-expect-error
    if (this.args.highlightedItem.isInternationalOutTransfer) {
      return defaultConditions && this.abilities.can('read transfer');
    }

    return defaultConditions && this.abilities.can('generate proof transfer');
  }

  get shouldDisplayRefundRequestedIndicator() {
    // @ts-expect-error
    return this.subject.get('refundRequestedAt') && this.args.highlightedItem.debit;
  }

  get shouldDisplayRecallDisclaimer() {
    // @ts-expect-error
    return this.args.highlightedItem.operationType === 'recall';
  }

  get paymentPurpose() {
    if (this.subject.get('fxPaymentPurpose')) {
      return getPaymentPurposeLabel({
        code: this.subject.get('fxPaymentPurpose'),
        country: this.subject.get('beneficiary.country'),
        currency: this.subject.get('beneficiary.currency'),
        intlService: this.intl,
      });
    }
  }

  get canRequestMandateRefund() {
    return this.abilities.can('requestRefund in mandate', this.subject);
  }

  get shouldDisplayAdditionalInfobox() {
    return (
      this.paymentPurpose ||
      this.canRequestMandateRefund ||
      this.shouldDisplayRefundRequestedIndicator ||
      this.shouldDisplayRecallDisclaimer ||
      this.shouldDisplayProofSection
    );
  }

  get shouldDisplayFinancingNotCompliantDisclaimer() {
    return this.subject.get('declinedReason') === FINANCING_TRANSFER_DECLINED_REASON;
  }

  requestRefundTask = dropTask(async close => {
    try {
      let record = await this.subject;
      await record.requestRefund();

      let message = this.intl.t('transactions.sidebar.request_refund_feedback.success');
      this.toastFlashMessages.toastSuccess(message);
    } catch (error) {
      let message;
      // @ts-expect-error
      if (error.code === ERROR_CANNOT_REFUND) {
        message = this.intl.t('transactions.sidebar.request_refund_feedback.not_refundable');
        // @ts-expect-error
      } else if (error.code === ERROR_REFUND_ALREADY_REQUESTED) {
        message = this.intl.t('transactions.sidebar.request_refund_feedback.already_requested');
      } else {
        message = this.intl.t('transactions.sidebar.request_refund_feedback.other_error');
      }

      this.toastFlashMessages.toastError(message);
    } finally {
      close();
    }
  });

  get shouldDisplayCancelButton() {
    return this.subject.get('cancelable') && this.abilities.can('cancel transfer');
  }

  get exchangeRate() {
    if (
      this.subject.get('localAmountCurrency') === this.subject.get('amountCurrency') ||
      // @ts-expect-error
      this.args.highlightedItem.isSwiftIncome
    ) {
      return;
    }
    if (this.subject.get('beneficiary') && this.subject.get('beneficiary.fx')) {
      // @ts-expect-error
      let fxRate = this.args.highlightedItem.localAmount / this.args.highlightedItem.amount;
      return formatExchangeRateWithDetails(fxRate || 0);
    }
  }

  get shouldDisplayRepeatTransfer() {
    // @ts-expect-error
    return this.abilities.can('create transfer') && this.args.highlightedItem.isRepeatableTransfer;
  }

  cancelTransferTask = dropTask(async close => {
    this.segment.track('history_list_open_side_panel_cancel');
    try {
      let record = await this.subject;
      await record.cancel();

      let message = this.intl.t('transfers.cancel-transfer-feedback.success');
      this.toastFlashMessages.toastSuccess(message);
    } catch (error) {
      let message;
      // @ts-expect-error
      if (error.code === ERROR_CANNOT_CANCEL) {
        message = this.intl.t('transfers.cancel-transfer-feedback.cannot-cancel');
        // @ts-expect-error
      } else if (error.code === ERROR_ALREADY_CANCELED) {
        message = this.intl.t('transfers.cancel-transfer-feedback.already-canceled');
      } else {
        message = this.intl.t('transfers.cancel-transfer-feedback.other-error');
      }

      this.toastFlashMessages.toastError(message);
    } finally {
      close();
    }
  });

  @action
  openCancelModal() {
    this.modals.open('popup/destructive', {
      title: this.intl.t('transfers.modals.cancel-transfer.title'),
      description: this.intl.t('transfers.modals.cancel-transfer.body', { htmlSafe: true }),
      cancel: this.intl.t('transfers.modals.cancel-transfer.cancel-button'),
      confirm: this.intl.t('transfers.modals.cancel-transfer.confirm-button'),
      confirmTask: this.cancelTransferTask,
    });
  }

  @action
  openRequestRefundModal() {
    this.modals.open('popup/confirmation', {
      title: this.intl.t('transactions.modals.request_refund.title'),
      description: this.intl.t('transactions.modals.request_refund.body', {
        // @ts-expect-error
        amount: this.intl.formatMoney(this.subject.get('amount'), {
          currency: this.subject.get('amountCurrency'),
        }),
        emitterName: this.subject.get('emitterName'),
      }),
      cancel: this.intl.t('transactions.modals.request_refund.cancel_button'),
      confirm: this.intl.t('transactions.modals.request_refund.confirm_button'),
      confirmTask: this.requestRefundTask,
    });
  }

  @action
  repeatTransfer() {
    this.segment.track('history-side-panel_repeat-transfer_clicked');
    if (this.subject.get('beneficiary') && !this.subject.get('beneficiary.hidden')) {
      return this.flowLinkManager.replaceWith({
        name: 'sepa-transfer',
        stepId: 'details',
        queryParams: {
          repeatedTransferId: this.subject.get('id'),
          origin: TRANSFER_FLOW_ORIGIN.REPEAT_TRANSFER,
        },
      });
    } else {
      this.toastFlashMessages.toastError(this.intl.t('transfers.repeat.sepa.error.toast'));
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transactions::Sidebar::Header::Transfer': typeof SidebarHeaderTransfer;
  }
}
