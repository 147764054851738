/* import __COLOCATED_TEMPLATE__ from './flow.hbs'; */
import { service } from '@ember/service';

import { DATE_FORMAT_TOKENS, dateToken } from '@qonto/ui-kit/utils/date-token';
import dayjs from 'dayjs';

import { CashflowFrequencyToInterval } from 'qonto/react/models/cash-flow-period';

import OverviewChartTooltipMonthComponent from './month';

export default class OverviewChartTooltipFlowComponent extends OverviewChartTooltipMonthComponent {
  // @ts-expect-error
  @service intl;

  get title() {
    // @ts-expect-error
    return this.args.flow?.isInflow
      ? this.intl.t('overview.inflows')
      : this.intl.t('overview.outflows');
  }

  get flowPreviousPeriod() {
    // @ts-expect-error
    let frequency = this.args.selectedFrequency || 'monthly';
    // @ts-expect-error
    let frequencyInterval = CashflowFrequencyToInterval[frequency];
    let startDate = dateToken({
      // @ts-expect-error
      date: dayjs().startOf(frequencyInterval).subtract(1, frequencyInterval).toDate(),
      token: DATE_FORMAT_TOKENS.DATE_YEAR_S,
      locale: this.intl.primaryLocale,
    });

    let endDate = dateToken({
      // @ts-expect-error
      date: dayjs().subtract(1, frequencyInterval).toDate(),
      token: DATE_FORMAT_TOKENS.DATE_YEAR_S,
      locale: this.intl.primaryLocale,
    });

    return `${startDate} - ${endDate}`;
  }

  get flowVariation() {
    // @ts-expect-error
    let { variation } = this.args.flow;

    return Number.isFinite(variation)
      ? this.intl.formatNumber(variation, {
          style: 'percent',
          minimumFractionDigits: 1,
          maximumFractionDigits: 1,
          signDisplay: 'always',
        })
      : '--';
  }

  get flowVariationDisplayClass() {
    // @ts-expect-error
    let { variation, isInflow } = this.args.flow;

    if (variation && Number.isFinite(variation)) {
      let isPositive = isInflow ? variation > 0 : variation < 0;
      return isPositive ? 'positive' : 'negative';
    }
  }

  get frequencyIntervalComparison() {
    let labels = {
      monthly: this.intl.t('overview.month-comparison'),
      quarterly: this.intl.t('overview.quarter-comparison'),
      yearly: this.intl.t('overview.year-comparison'),
    };

    // @ts-expect-error
    return this.args.selectedFrequency ? labels[this.args.selectedFrequency] : labels.monthly;
  }
}
