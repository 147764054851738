export default {
  "dropdown-icon": "ZSs",
  "dropdown": "ZSF",
  "download-button": "ZSg",
  "connections-banner": "ZSD",
  "recommendation-cards": "ZSV",
  "wrapper-with-cards": "ZSL",
  "transactions-wrapper": "ZST",
  "separator": "ZSz",
  "with-transaction": "ZSa",
  "annual-billing-banner": "ZSH",
  "mt-132": "ZSO",
  "transactions-sidebar": "ZSm",
  "empty-state-wrapper": "ZSp",
  "wrapper-with-modular": "ZSx",
  "modular-table-container": "ZSw",
  "modular-table-sidebar": "ZSN",
  "sidebar-overlay": "ZSv",
  "overlay-visible": "ZSo"
};
