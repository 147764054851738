/* import __COLOCATED_TEMPLATE__ from './signature.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import window from 'ember-window-mock';

import {
  PAY_LATER_APPLICATION_ORIGIN,
  PAY_LATER_SIGNATURE_EVENT,
  PAY_LATER_SIGNATURE_STATUS,
} from 'qonto/constants/financing';
import { TRANSFER_FLOW_ORIGIN } from 'qonto/constants/transfers';

interface FlowsPayLaterApplicationSignatureSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsPayLaterApplicationSignature extends Component<FlowsPayLaterApplicationSignatureSignature> {
  @service declare segment: Services['segment'];
  @service declare zendeskWidget: Services['zendeskWidget'];

  // @ts-expect-error
  signatureUrl = `${this.args.context.signature.url}&disable_domain_validation=true`;

  constructor(owner: unknown, args: FlowsPayLaterApplicationSignatureSignature['Args']) {
    super(owner, args);
    this.handleEvents = this.handleEvents.bind(this);
    window.addEventListener('message', this.handleEvents);
    this.zendeskWidget.hide();
  }

  // @ts-expect-error
  handleEvents(e) {
    let status;

    if (
      e.origin === PAY_LATER_SIGNATURE_EVENT.ORIGIN &&
      e.data?.type === PAY_LATER_SIGNATURE_EVENT.TYPE
    ) {
      if (e.data.event === 'success') {
        // @ts-expect-error
        let { origin, sepaTransfer } = this.args.context;
        let applicationFlowOrigins = Object.values(PAY_LATER_APPLICATION_ORIGIN);
        let trackingPayload = {};

        status = PAY_LATER_SIGNATURE_STATUS.SUCCESS;

        if (sepaTransfer) {
          if (origin === TRANSFER_FLOW_ORIGIN.SUPPLIER_INVOICES) {
            origin = PAY_LATER_APPLICATION_ORIGIN.SUPPLIER_INVOICES;
          } else if (origin !== PAY_LATER_APPLICATION_ORIGIN.PAY_BY_BENEFICIARY) {
            origin = PAY_LATER_APPLICATION_ORIGIN.TRANSFER;
          }
        }

        if (applicationFlowOrigins.includes(origin)) {
          trackingPayload = {
            origin,
          };
        }

        this.segment.track('pay-later_signed_displayed', trackingPayload);
      }

      if (e.data.event === 'error') {
        status = PAY_LATER_SIGNATURE_STATUS.ERROR;
      }
    }

    if (status) {
      // @ts-expect-error
      this.args.context.signature.status = status;
      // @ts-expect-error
      this.args.transitionToNext();
    }
  }

  willDestroy() {
    // @ts-expect-error
    super.willDestroy(...arguments);
    this.zendeskWidget.show();
    window.removeEventListener('message', this.handleEvents);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Financing::PayLater::Application::Signature': typeof FlowsPayLaterApplicationSignature;
  }
}
