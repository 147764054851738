/* import __COLOCATED_TEMPLATE__ from './success-trial.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { LottiePlayer } from '@repo/design-system-kit';

import { TRACKING_TRIAL_STATE } from 'qonto/constants/subscriptions';

interface SuccessTrialSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class SuccessTrialComponent extends Component<SuccessTrialSignature> {
  lottiePlayer = LottiePlayer;

  @service declare organizationManager: Services['organizationManager'];
  @service declare segment: Services['segment'];

  TRACKING_TRIAL_STATE = TRACKING_TRIAL_STATE;

  constructor(owner: unknown, args: SuccessTrialSignature['Args']) {
    super(owner, args);
    this.sendTrackingEventOnLoad();
  }

  sendTrackingEventOnLoad() {
    // @ts-expect-error
    let { context } = this.args;
    this.segment.track('plans_change_confirmation_success', {
      recurrence: context.recurrence,
      trial_state: context.isFreeTrial
        ? TRACKING_TRIAL_STATE.FREE_TRIAL
        : TRACKING_TRIAL_STATE.INITIAL_TRIAL,
      target_plan: context.subscription.product?.get('code'),
      current_plan: context.currentPricePlanCode,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::SubscriptionChange::SuccessTrial': typeof SuccessTrialComponent;
  }
}
