export default {
  "signin": "quB",
  "form": "quE",
  "illustration": "qud",
  "purple": "quI",
  "mint": "qut",
  "mustard": "quM",
  "peach": "quP",
  "form-wrapper": "qul",
  "header-logo": "quX",
  "links": "quC",
  "no-account-label": "quk",
  "spinner-container": "quJ",
  "main-image-container": "qus",
  "main-image": "quF"
};
