export default {
  "header": "qSn",
  "header-type": "qSi",
  "close": "qSA",
  "close-icon": "qSY",
  "header-infos": "qSh",
  "title": "qSf body-1",
  "declined": "qSK",
  "canceled": "qSG",
  "approved": "qSr",
  "date": "qSb body-2",
  "container": "qSy",
  "picto": "qSU",
  "request": "qSj",
  "picto-status": "qSW",
  "check": "qSu",
  "stop": "qcS",
  "warning": "qcc",
  "general": "qcq",
  "amount": "qcZ",
  "counterparty": "qcR body-2",
  "initiator": "qcQ",
  "initiator-avatar": "qce",
  "avatar-image": "qcB",
  "initiator-id": "qcE",
  "name": "qcd body-2",
  "role": "qcI caption-bold",
  "infobox": "qct",
  "account-infobox": "qcM qct",
  "beneficiary-warning": "qcP caption-bold",
  "dates-wrapper": "qcl",
  "dates": "qcX",
  "recurrence": "qcC caption-bold",
  "arrow": "qck",
  "notify-checkbox": "qcJ"
};
