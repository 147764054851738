export default {
  "container": "qgf",
  "header": "qgK",
  "title": "qgG caption-bold",
  "search-icon": "qgr",
  "text": "qgb body-2",
  "trigger": "qgy",
  "loading-state": "qgU",
  "dropzone-placeholder-wrapper": "qgj",
  "dropzone-placeholder": "qgW",
  "file-loading": "qgu",
  "search-qonto-cta": "qDS",
  "search-qonto-cta-content": "qDc",
  "tooltip-wrapper": "qDq",
  "invoices-tooltip": "qDZ"
};
