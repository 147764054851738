import Controller from '@ember/controller';
import { service } from '@ember/service';

import { ErrorState } from '@repo/design-system-kit';

import { FRENCH_EINVOICING_RECEIVE_INVOICES_STATUS } from 'qonto/constants/french-einvoicing-settings';
import {
  FrenchEinvoicingStatus,
  FrenchEInvoicingStatusLoading,
} from 'qonto/react/components/settings/einvoicing';
import { getEinvoicingFrSettingsStatusTag } from 'qonto/utils/einvoicing-fr-settings';

export default class SettingsEinvoicingController extends Controller {
  FrenchEinvoicingStatus = FrenchEinvoicingStatus;
  frenchEInvoicingStatusLoading = FrenchEInvoicingStatusLoading;
  ErrorState = ErrorState;

  @service abilities;
  @service intl;
  @service organizationManager;
  @service localeManager;

  get canSeeDisableEinvoicingCta() {
    return this.abilities.can('write einvoicingSetting');
  }

  get einvoicingFrSettings() {
    return this.model?.settingsTask?.lastSuccessful?.value?.einvoicingFrSettings || {};
  }

  get sendStatus() {
    let status = this.einvoicingFrSettings?.einvoicingSendingEnabled
      ? FRENCH_EINVOICING_RECEIVE_INVOICES_STATUS.enabled
      : undefined;

    return getEinvoicingFrSettingsStatusTag(this.intl, status);
  }

  get receiveStatus() {
    let status = this.einvoicingFrSettings?.einvoicingReceivingStatus;

    return getEinvoicingFrSettingsStatusTag(this.intl, status);
  }

  get generateTypeformUrl() {
    let currentLocale = this.localeManager.locale;
    let { organization } = this.organizationManager;
    let orgId = organization?.id;
    let orgLegalNumber = organization?.legalNumber;
    let orgName = organization?.name;

    let typeFormBaseUrl = `https://qonto.typeform.com`;
    let typeFormUrlParams = `orga_id=${orgId}&legal_number=${orgLegalNumber}&name=${orgName}`;
    let typeFormUrl;

    if (currentLocale === 'fr') {
      typeFormUrl = `${typeFormBaseUrl}/e-facturation#${typeFormUrlParams}`;
    } else {
      typeFormUrl = `${typeFormBaseUrl}/e-invoicing#${typeFormUrlParams}`;
    }

    return typeFormUrl;
  }
}
