/* import __COLOCATED_TEMPLATE__ from './additional-items.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { Disclaimer, TextAreaField } from '@repo/design-system-kit';

interface RequestTransferAdditionalItemsSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class RequestTransferAdditionalItems extends Component<RequestTransferAdditionalItemsSignature> {
  disclaimerBlock = Disclaimer.Block;
  textAreaField = TextAreaField;

  @service declare attachmentsManager: Services['attachmentsManager'];
  @service declare modals: Services['modals'];

  get uploaderErrors() {
    // @ts-expect-error
    let { enableTransferValidations, requestTransfer } = this.args;
    if (enableTransferValidations) {
      if (requestTransfer.attachmentsFiles.length) {
        return this.attachmentsManager.fileErrors;
      }
      return requestTransfer.validations.attrs.attachments.errors;
    }

    return false;
  }

  // @ts-expect-error
  @action addAttachment(file) {
    // @ts-expect-error
    this.attachmentsManager.addAttachment(false, this.args.requestTransfer, file);
  }

  // @ts-expect-error
  @action removeAttachment(file) {
    // @ts-expect-error
    this.attachmentsManager.removeAttachment(false, this.args.requestTransfer, file);
  }

  // @ts-expect-error
  @action cancelUploadAttachment(file) {
    // @ts-expect-error
    this.attachmentsManager.cancelUploadAttachment(this.args.requestTransfer, file);
  }

  // @ts-expect-error
  @action showAttachment(selectedFile) {
    this.modals.open('file-preview-modal', {
      isFullScreenModal: true,
      deletable: false,
      selectedFile,
      // @ts-expect-error
      model: this.args.requestTransfer,
      download: this.attachmentsManager.downloadAttachment,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Request::Transfer::AdditionalItems': typeof RequestTransferAdditionalItems;
  }
}
