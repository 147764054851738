export default {
  "invoice-sidebar": "qBo",
  "body": "qBn",
  "box": "qBi",
  "box-header": "qBA",
  "sidebar-box": "qBY",
  "border-top": "qBh",
  "row": "qBf",
  "greyed-out": "qBK",
  "strike-through": "qBG"
};
