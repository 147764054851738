/* import __COLOCATED_TEMPLATE__ from './period-selector.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Badge } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';

// @ts-expect-error
import { PERIOD_KEYS } from 'qonto/constants/overview';

interface PeriodSelectorSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class PeriodSelectorComponent extends Component<PeriodSelectorSignature> {
  badgeHighlight = Badge;

  @service declare intl: Services['intl'];
  @service declare modals: Services['modals'];
  @service declare segment: Services['segment'];
  @service declare subscriptionManager: Services['subscriptionManager'];

  @tracked showCustomPeriodForm = false;
  total = 0;

  get description() {
    // @ts-expect-error
    if (this.args.type !== 'date-picker') {
      return this.intl.t('overview.period-selection.custom-period-builder.body');
    }
  }

  get isCustomPeriodSelected() {
    // @ts-expect-error
    return this.args.selectedPeriod?.key === PERIOD_KEYS.CUSTOM_PERIOD;
  }

  get selectedIndex() {
    if (this.isCustomPeriodSelected) {
      // @ts-expect-error
      return this.args.periods.length;
    }

    // @ts-expect-error
    let selectedPeriod = this.args.selectedPeriod;

    // @ts-expect-error
    return this.args.periods.findIndex(({ interval, key }) => {
      if (selectedPeriod?.key) {
        return selectedPeriod.key === key;
      }
      return selectedPeriod.interval === interval;
    });
  }

  get trialInfo() {
    return this.subscriptionManager.currentSubscription?.findTrial('advanced_dashboard');
  }

  @action
  // @ts-expect-error
  onOpen(dropdownApi) {
    // @ts-expect-error
    this.dropdownApi = dropdownApi;
    this.total = 0;
  }

  @action
  getIndex() {
    let result = this.total++;
    return result;
  }

  @action onTrialClick() {
    this.modals.open('discover/trial/confirm', {
      isFullScreenModal: true,
      trialInfo: this.trialInfo,
    });
  }

  @action
  toggleCustomPeriodForm() {
    this.showCustomPeriodForm = !this.showCustomPeriodForm;
    if (!this.showCustomPeriodForm) {
      this.total = 0;
      // @ts-expect-error
      document.querySelector(`[data-ebd-id="${this.dropdownApi.uniqueId}-trigger"]`).focus();
    }
  }

  openUpsellModalTask = dropTask(async () => {
    this.segment.track('upsell_dashboard_clicked', {
      // @ts-expect-error
      widget_type: this.args.trackingWidgetType,
      upsell_type: 'period_selection',
    });

    await this.subscriptionManager.upgradeWithRecommendation('advanced_dashboard');
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Overview::Chart::PeriodSelector': typeof PeriodSelectorComponent;
  }
}
