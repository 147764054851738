export default {
  "row": "Ta",
  "active": "TH",
  "cell": "TO",
  "empty-cell": "Tm",
  "counterparty": "Tp",
  "counterparty-info": "Tx",
  "name": "Tw body-1",
  "qonto": "TN",
  "currency": "Tv",
  "account-number": "To caption",
  "custom-labels": "Tn",
  "label": "Ti"
};
