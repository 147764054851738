/* import __COLOCATED_TEMPLATE__ from './signature-warning.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask } from 'ember-concurrency';

import { TRANSFER_FLOW_ORIGIN } from 'qonto/constants/transfers';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';

interface FlowsTransfersPayLaterApplicationSignatureWarningSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsTransfersPayLaterApplicationSignatureWarningComponent extends Component<FlowsTransfersPayLaterApplicationSignatureWarningSignature> {
  @service declare intl: Services['intl'];
  @service declare sentry: Services['sentry'];
  @service declare financing: Services['financing'];
  @service declare segment: Services['segment'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];

  requestPayLaterSignatureTask = dropTask(async () => {
    try {
      let { url } = await this.financing.requestPayLaterSignature();
      // @ts-expect-error
      this.args.context.signature.url = url;
      // @ts-expect-error
      this.args.transitionToNext();
    } catch (error) {
      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      this.toastFlashMessages.toastError(
        this.intl.t('financing.pay-later.application-flow.value-proposition.error-toast')
      );
    }

    // @ts-expect-error
    if (this.args.context.origin === TRANSFER_FLOW_ORIGIN.PAY_BY_INVOICE) {
      this.segment.track('pay-later-modular_sign-contract-CTA_clicked', {
        origin: 'lower_balance',
      });
    } else {
      this.segment.track('pay-later-modular_sign-contract-CTA_clicked', {
        origin: 'enough_balance',
      });
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Transfers::PayLaterApplication::SignatureWarning': typeof FlowsTransfersPayLaterApplicationSignatureWarningComponent;
  }
}
