/* import __COLOCATED_TEMPLATE__ from './page-header.hbs'; */
import Component from '@glimmer/component';

// @ts-expect-error
import { REQUEST_ORIGINS } from 'qonto/constants/requests';

interface RequestPageHeaderSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class RequestPageHeader extends Component<RequestPageHeaderSignature> {
  origin = REQUEST_ORIGINS.reimbursements;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reimbursements::PageHeader': typeof RequestPageHeader;
  }
}
