/* import __COLOCATED_TEMPLATE__ from './summary-placeholder.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface SummaryPlaceholderSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const SummaryPlaceholder = templateOnlyComponent<SummaryPlaceholderSignature>();

export default SummaryPlaceholder;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Checks::SummaryPlaceholder': typeof SummaryPlaceholder;
  }
}
