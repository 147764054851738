export default {
  "container": "hn",
  "summary": "hi",
  "sideview": "hA",
  "steps-container": "hY",
  "invoice-upload-container": "hh",
  "pdf-preview": "hf",
  "uploader": "hK",
  "template-text": "hG",
  "file-dropzone": "hr",
  "overlay": "hb",
  "footer": "hy",
  "upload-prompt": "hU",
  "step-selector": "hj",
  "disclaimer": "hW"
};
