/* import __COLOCATED_TEMPLATE__ from './transaction-amount.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { AmountField } from '@repo/design-system-kit';
import { restartableTask, task, timeout } from 'ember-concurrency';

// @ts-expect-error
import { DEBOUNCE_MS } from 'qonto/constants/timers';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';

interface QontoHubNotificationsCreateStepsTransactionAmountSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class QontoHubNotificationsCreateStepsTransactionAmount extends Component<QontoHubNotificationsCreateStepsTransactionAmountSignature> {
  amountField = AmountField;

  @service declare sentry: Services['sentry'];
  @service declare intl: Services['intl'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];

  get amountInEUR() {
    // @ts-expect-error
    return this.args.context.hubNotificationRule.value / 100;
  }

  get errorMessage() {
    // @ts-expect-error
    let amount = this.args.context.hubNotificationRule.value;

    if (!amount || amount < 0) {
      return this.intl.t('validations.errors.blank');
    }

    return null;
  }

  estimateAlertCountPerDayTask = restartableTask(async () => {
    try {
      await timeout(DEBOUNCE_MS);
      // @ts-expect-error
      let { estimate } = await this.args.context.hubNotificationRule.estimate();

      return estimate.average;
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));

      // @ts-expect-error
      if (!error.isAdapterError) {
        throw error;
      }
    }
  });

  confirmAmountTask = task(async e => {
    e.preventDefault();
    try {
      // @ts-expect-error
      await this.args.context.hubNotificationRule.save();

      // @ts-expect-error
      this.args.transitionToNext();
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));

      // @ts-expect-error
      if (!error.isAdapterError) {
        throw error;
      }
    }
  });

  changeValueTask = task(async value => {
    await this.estimateAlertCountPerDayTask.cancelAll({ resetState: true });

    let amount = parseInt(value, 10);

    // @ts-expect-error
    this.args.context.hubNotificationRule.set('value', Number.isNaN(amount) ? 0 : amount * 100);

    if (amount > 0) {
      await this.estimateAlertCountPerDayTask.perform();
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Connect::Applications::Activation::TransactionAmount': typeof QontoHubNotificationsCreateStepsTransactionAmount;
  }
}
