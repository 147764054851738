/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

// @ts-expect-error
import { STATUS } from 'qonto/constants/requests';

interface ReimbursementsRequestsSidebarMileageHeaderSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class ReimbursementsRequestsSidebarMileageHeaderComponent extends Component<ReimbursementsRequestsSidebarMileageHeaderSignature> {
  @service declare intl: Services['intl'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare abilities: Services['abilities'];
  @service declare modals: Services['modals'];
  @service declare segment: Services['segment'];

  get distance() {
    // @ts-expect-error
    let distanceKm = (this.args.request.mileageCalculation.distanceMeters / 1000).toFixed(2);
    // @ts-expect-error
    let rate = this.args.request.mileageCalculation.rate?.value;
    return {
      distanceKm,
      rate,
    };
  }

  get lastActionDate() {
    // @ts-expect-error
    let { processedAt, createdAt } = this.args.request;
    return processedAt || createdAt;
  }

  get titleStatus() {
    // @ts-expect-error
    let { approver, initiator, status } = this.args.request;
    let pastStatuses = [STATUS.CANCELED, STATUS.DECLINED, STATUS.APPROVED];

    if (pastStatuses.includes(status)) {
      let user = approver || initiator;
      let { firstName, lastName } = user.getProperties('firstName', 'lastName');
      let fullName = `${firstName.charAt(0)}. ${lastName}`;

      let statuses = {
        [STATUS.DECLINED]: this.intl.t('requests.sidebar.header.status.declined', {
          approver_name: fullName,
        }),
        [STATUS.APPROVED]: this.intl.t('requests.sidebar.header.status.accepted', {
          approver_name: fullName,
        }),
        [STATUS.CANCELED]: this.intl.t('requests.transfers.final_status.canceled', {
          name: fullName,
        }),
      };

      return statuses[status];
    }

    return this.intl.t('requests.sidebar.header.status.pending');
  }

  get requestDate() {
    // @ts-expect-error
    let { status } = this.args.request;
    let formattedDate = this.intl.formatDate(this.lastActionDate, { format: 'short' });

    if (status === STATUS.PENDING) {
      return this.intl.t('checks.sidebar.requested_at') + ' ' + formattedDate;
    }

    if ([STATUS.DECLINED, STATUS.APPROVED].includes(status)) {
      return this.intl.t('requests.sidebar.header.updated-date', { updated_at: formattedDate });
    }

    return formattedDate;
  }

  get canSeeAmountModal() {
    let { organization } = this.organizationManager;
    return organization.isFrench;
  }

  @action
  handleCalculationAmountClick() {
    this.segment.track('request_calculation-details_clicked', {
      origin: 'mileage_request',
    });

    this.modals.open('mileage/modals/mileage-calculation-details', {
      isFullScreenModal: true,
      // @ts-expect-error
      amount: this.args.request.amount,
      // @ts-expect-error
      mileageCalculation: this.args.request.mileageCalculation,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reimbursements::Requests::Sidebar::Mileage::Header': typeof ReimbursementsRequestsSidebarMileageHeaderComponent;
  }
}
