export default {
  "row": "cyR",
  "active": "cyQ",
  "dot": "cye",
  "red": "cyB",
  "green": "cyE",
  "yellow": "cyd",
  "status": "cyI",
  "align-right": "cyt",
  "animated": "cyM",
  "fadein-item": "cyP",
  "cell": "cyl body-2",
  "cell-content": "cyX",
  "amount": "cyC body-2",
  "subtitle": "cyk caption",
  "padding-left": "cyJ",
  "padding-right": "cys",
  "note": "cyF"
};
