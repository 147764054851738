/* import __COLOCATED_TEMPLATE__ from './created.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface CreatedSignature {
  Element: HTMLDivElement;
  Args: {};
  Blocks: {
    default: [];
  };
}

const Created = templateOnlyComponent<CreatedSignature>();

export default Created;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ReceivableInvoices::InvoiceModal::Sidebar::Placeholder::Created': typeof Created;
  }
}
