/* import __COLOCATED_TEMPLATE__ from './item.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { Checkbox } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';
import window from 'ember-window-mock';

import { INVOICE_STATUSES } from 'qonto/constants/supplier-invoice';
import { StatusTag } from 'qonto/react/components/supplier-invoices/status-tag';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';

interface ReviewDuplicatesModalItemSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLElement;
}

export default class ReviewDuplicatesModalItem extends Component<ReviewDuplicatesModalItemSignature> {
  checkbox = Checkbox;
  statusTag = StatusTag;

  @service declare intl: Services['intl'];
  @service declare store: Services['store'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare sentry: Services['sentry'];

  openAttachmentInNewWindow = dropTask(async () => {
    try {
      // @ts-expect-error
      let attachment = await this.store.findRecord('attachment', this.args.duplicate.attachment_id);
      window.open(attachment.file.fileUrl, '_blank', 'noopener,noreferrer');
    } catch (error) {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.generic'));

      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  });

  get amountLabel() {
    // @ts-expect-error
    return this.args.duplicate.status === INVOICE_STATUSES.toReview
      ? this.intl.t('supplier-invoices.modals.review-duplicates.item.invoice-amount')
      : this.intl.t('supplier-invoices.modals.review-duplicates.item.payment-amount');
  }

  get dateLabel() {
    let DATE_LABELS = {
      [INVOICE_STATUSES.toReview]: this.intl.t(
        'supplier-invoices.modals.review-duplicates.item.import-date'
      ),
      [INVOICE_STATUSES.pending]: this.intl.t(
        'supplier-invoices.modals.review-duplicates.item.due-date'
      ),
      [INVOICE_STATUSES.scheduled]: this.intl.t(
        'supplier-invoices.modals.review-duplicates.item.operation-date'
      ),
      [INVOICE_STATUSES.paid]: this.intl.t(
        'supplier-invoices.modals.review-duplicates.item.payment-date'
      ),
    };

    // @ts-expect-error
    return DATE_LABELS[this.args.duplicate.status];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'SupplierInvoices::ReviewDuplicatesModal::Item': typeof ReviewDuplicatesModalItem;
  }
}
