export default {
  "rule-card": "DK",
  "rule-content": "DG",
  "rule-options": "Dr",
  "visible": "Db",
  "dropdown-btn": "Dy",
  "delete-btn": "DU",
  "btn--tertiary": "Dj",
  "delete-rule-btn": "DW",
  "transaction-estimate": "Du"
};
