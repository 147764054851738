export default {
  "splash-landing": "qNx",
  "container": "qNw",
  "options": "qNN",
  "physical": "qNv",
  "card-label": "qNo",
  "physical-cta-container": "qNn",
  "physical-cta": "qNi",
  "coming-soon": "qNA"
};
