export default {
  "attachment-viewer": "cWa",
  "hide-sidebar": "cWH",
  "sidebar": "cWO",
  "header": "cWm",
  "header-top": "cWp",
  "header-icon": "cWx",
  "header-amount": "cWw",
  "infobox": "cWN",
  "vat": "cWv",
  "validation": "cWo"
};
