export default {
  "color-option": "cda",
  "color-dot": "cdH",
  "any": "cdO",
  "red": "cdm",
  "orange": "cdp",
  "yellow": "cdx",
  "green": "cdw",
  "blue": "cdN",
  "purple": "cdv",
  "pink": "cdo",
  "black": "cdn",
  "grey": "cdi",
  "brown": "cdA",
  "color-name": "cdY"
};
