/* import __COLOCATED_TEMPLATE__ from './counterparty-avatar.hbs'; */
import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';

import colorSelector from 'qonto/utils/color-selector';

interface CounterpartyAvatarSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class CounterpartyAvatar extends Component<CounterpartyAvatarSignature> {
  get counterpartyFirstLetter() {
    // @ts-expect-error
    return this.args.counterpartyName?.match(/\w/)?.[0];
  }

  get style() {
    // @ts-expect-error
    return htmlSafe(`background-color: ${colorSelector(this.args.counterpartyName)}`);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    CounterpartyAvatar: typeof CounterpartyAvatar;
  }
}
