export default {
  "header-cell": "Oq",
  "empty": "OZ",
  "right": "OR",
  "debitor": "OQ",
  "frequency": "Oe",
  "date": "OB",
  "status": "OE",
  "amount": "Od",
  "header-content": "OI caption-bold",
  "active": "Ot",
  "row--sidebar-open": "OM"
};
