/* import __COLOCATED_TEMPLATE__ from './disclaimer.hbs'; */
import ArrayProxy from '@ember/array/proxy';
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { Disclaimer } from '@repo/design-system-kit';
import window from 'ember-window-mock';

import {
  GMI_STATUSES_ACTION_REQUIRED,
  GMI_STATUSES_FAILED_STATUSES,
  // @ts-expect-error
} from 'qonto/constants/gmi-solution-instance';
import type GmiSolutionInstanceModel from 'qonto/models/gmi-solution-instance';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

// @ts-expect-error
import styles from './disclaimer.module.css';

interface GmiSolutionInstanceDisclaimerSignature {
  // The arguments accepted by the component
  Args: {
    gmiSolutionInstanceId?: string;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class GmiSolutionInstanceDisclaimerComponent extends Component<GmiSolutionInstanceDisclaimerSignature> {
  @service declare gmiSolutionInstanceManager: Services['gmiSolutionInstanceManager'];
  @service declare store: Services['store'];
  @service declare sentry: Services['sentry'];
  @service declare connectManager: Services['connectManager'];
  @service declare router: Services['router'];

  styles = styles;

  disclaimerBlock = Disclaimer.Block;

  constructor(owner: unknown, args: GmiSolutionInstanceDisclaimerSignature['Args']) {
    super(owner, args);
    this.gmiSolutionInstanceManager.subscribe();
    this.gmiSolutionInstanceManager.fetchGMISolutionInstancesTask
      .perform()
      .catch(ignoreCancelation);
  }

  get gmiSolutionInstances(): ArrayProxy<GmiSolutionInstanceModel> {
    return this.store.peekAll('gmi-solution-instance');
  }

  get disclaimerType() {
    let gmiSolutionDetails;
    let isMultipleFailing = false;

    switch (true) {
      // First we check for specific gmi-solution-instance with given id
      case Boolean(this.args.gmiSolutionInstanceId):
        if (!this.specificSolutionInstance) return null;
        gmiSolutionDetails = this.specificSolutionInstance;
        break;
      // Secondly we check for an action_required instance
      case Boolean(this.actionRequiredInstance):
        gmiSolutionDetails = this.actionRequiredInstance;
        break;
      // Thirdly we check for a failed instance and consider multiple ones for a different message.
      case Boolean(this.failedInstances.length):
        gmiSolutionDetails = this.failedInstances?.[0];
        isMultipleFailing = this.failedInstances.length > 1;
        break;
      default:
        return null;
    }

    this.enrichGmiSolutionInstance(gmiSolutionDetails);

    return {
      troubleshootingUrl: gmiSolutionDetails?.troubleshootingUrl,
      status: gmiSolutionDetails?.gmiStatus,
      bankName: gmiSolutionDetails?.name || '',
      id: gmiSolutionDetails?.id,
      isMultipleFailing,
    };
  }

  @action
  handleClick() {
    if (this.disclaimerType?.troubleshootingUrl) {
      // @ts-expect-error
      this.popup = window.open(this.disclaimerType.troubleshootingUrl, 'gmi', 'popup');
    } else {
      this.router.transitionTo('settings.connections.invoices', {
        queryParams: {
          highlight: this.disclaimerType?.isMultipleFailing ? undefined : this.disclaimerType?.id,
        },
      });
    }
  }

  enrichGmiSolutionInstance(gmiSolutionDetails?: GmiSolutionInstanceModel) {
    // To enrich the solution instance we need to fetch from Prismic
    if (!gmiSolutionDetails?.name && this.failedInstances.length <= 1) {
      this.gmiSolutionInstanceManager.fetchPrismicDataTask
        .perform(gmiSolutionDetails)
        .catch(ignoreCancelation);
    }
  }

  get actionRequiredInstance() {
    return this.gmiSolutionInstances.find(({ gmiStatus }) =>
      GMI_STATUSES_ACTION_REQUIRED.includes(gmiStatus)
    );
  }

  get failedInstances() {
    return this.gmiSolutionInstances.filter(({ gmiStatus }) =>
      GMI_STATUSES_FAILED_STATUSES.includes(gmiStatus)
    );
  }

  get specificSolutionInstance() {
    return this.gmiSolutionInstances.find(({ gmiStatus, solutionId }) => {
      return (
        solutionId === this.args.gmiSolutionInstanceId &&
        [...GMI_STATUSES_ACTION_REQUIRED, ...GMI_STATUSES_FAILED_STATUSES].includes(gmiStatus)
      );
    });
  }

  get isActionRequired() {
    return GMI_STATUSES_ACTION_REQUIRED.includes(this.disclaimerType?.status);
  }

  get disclaimerLevel() {
    return this.isActionRequired ? 'warning' : 'error';
  }

  get isMultipleFailing() {
    return this.disclaimerType?.isMultipleFailing;
  }

  get bankName() {
    return this.disclaimerType?.bankName;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Connect::GmiSolutionInstance::Disclaimer': typeof GmiSolutionInstanceDisclaimerComponent;
  }
}
