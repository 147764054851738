/* import __COLOCATED_TEMPLATE__ from './table.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

// @ts-expect-error
import { REQUEST_ORIGINS } from 'qonto/constants/requests';

interface ReimbursementsPendingTableSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class ReimbursementsPendingTable extends Component<ReimbursementsPendingTableSignature> {
  @service declare organizationManager: Services['organizationManager'];
  @service declare subscriptionManager: Services['subscriptionManager'];
  @service declare abilities: Services['abilities'];

  REIMBURSEMENTS_EMPTY_STATE_ORIGIN = REQUEST_ORIGINS.REIMBURSEMENTS_EMPTY_STATE;

  get isApproveCompactView() {
    // @ts-expect-error
    return this.args.isSingleAccountApprover && !this.args.isSidepanelOpen;
  }

  get remainingInvitationCount() {
    let limit = this.subscriptionManager.getLimitByFeatureCode('additional_users');
    return limit === null ? null : limit - this.activeMembershipsCount;
  }

  get activeMembershipsCount() {
    let { organization } = this.organizationManager;

    let activeMembershipsCount = this.abilities.can('access accountant-access')
      ? organization.membershipsCountingTowardsPlanLimitCount
      : organization.activeMembershipsCount;

    return activeMembershipsCount;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reimbursements::Pending::Table': typeof ReimbursementsPendingTable;
  }
}
