export default {
  "checks": "yj",
  "content": "yW",
  "form": "yu",
  "title": "US",
  "description": "Uc",
  "wrapper": "Uq",
  "aside": "UZ",
  "amount-field": "UR"
};
