export default {
  "container": "qrW",
  "form-container": "qru",
  "title": "qbS",
  "title-border": "qbc",
  "tabs-container": "qbq",
  "preview-container": "qbZ",
  "email-preview-container": "qbR",
  "close-button": "qbQ btn btn--icon-only btn--tertiary btn--large"
};
