export default {
  "row": "qqb",
  "active": "qqy",
  "dot": "qqU",
  "red": "qqj",
  "green": "qqW",
  "yellow": "qqu",
  "status": "qZS",
  "align-right": "qZc",
  "animated": "qZq",
  "fadein-item": "qZZ",
  "cell": "qZR body-2",
  "cell-content": "qZQ",
  "amount": "qZe body-2",
  "subtitle": "qZB caption",
  "padding-left": "qZE",
  "padding-right": "qZd",
  "note": "qZI"
};
