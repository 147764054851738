import { InvalidError } from '@ember-data/adapter/error';
import Model, { attr, belongsTo } from '@ember-data/model';
import { service, type Registry as Services } from '@ember/service';
import { waitFor } from '@ember/test-waiters';

// @ts-expect-error
import { apiAction } from '@mainmatter/ember-api-actions';
import { equal, or } from 'macro-decorators';

import { apiBaseURL, requestsNamespace } from 'qonto/constants/hosts';
import {
  APPROVAL_WORKFLOW_STATUS,
  REQUEST_STATUS_COLORS,
  REQUEST_TYPES,
  STATUS,
  // @ts-expect-error
} from 'qonto/constants/requests';
// @ts-expect-error
import { errorsArrayToHash } from 'qonto/utils/errors-array-to-hash';

export default class RequestModel extends Model {
  @service declare intl: Services['intl'];
  @service declare networkManager: Services['networkManager'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare featuresManager: Services['featuresManager'];

  // @ts-expect-error
  @belongsTo('membership', { async: false, inverse: null }) approver;
  // @ts-expect-error
  @belongsTo('approval-workflow-state', { async: true, inverse: null }) approvalWorkflowState;

  @attr('string') declare requestType: string;
  @attr('string') declare status: string;
  @attr('string') declare approvalWorkflowStatus: string;
  @attr('string') declare declinedNote: string;
  // @ts-expect-error
  @attr note;
  // @ts-expect-error
  @attr() processedAt;

  // @ts-expect-error
  @or(STATUS.CANCELED, STATUS.DECLINED) ghost;

  // @ts-expect-error
  @equal('status', STATUS.CANCELED) canceled;
  // @ts-expect-error
  @equal('status', STATUS.DECLINED) declined;
  // @ts-expect-error
  @equal('status', STATUS.APPROVED) approved;
  // @ts-expect-error
  @equal('status', STATUS.PENDING) pending;

  // @ts-expect-error
  @equal('approvalWorkflowStatus', APPROVAL_WORKFLOW_STATUS.COMPLETED) completed;
  // @ts-expect-error
  @equal('approvalWorkflowStatus', APPROVAL_WORKFLOW_STATUS.AWAITS_MY_APPROVAL) awaitsApproval;
  @attr('boolean', { defaultValue: true }) declare lastStep: boolean;

  get displayedStatus() {
    switch (this.status) {
      case STATUS.APPROVED:
        return this.intl.t('request.status.approved');
      case STATUS.DECLINED:
        return this.intl.t('request.status.rejected');
      case STATUS.PENDING:
        return this.intl.t('request.status.pending');
      case STATUS.CANCELED:
        return this.intl.t('request.status.canceled');
      default:
        return null;
    }
  }

  get statusDisplayInfo() {
    return {
      displayedStatus: this.displayedStatus,
      color: REQUEST_STATUS_COLORS[this.status],
    };
  }

  get requestAmount() {
    switch (this.requestType) {
      case REQUEST_TYPES.MILEAGE:
      case REQUEST_TYPES.EXPENSE_REPORT: {
        // @ts-expect-error
        return this.amount;
      }
      case REQUEST_TYPES.MULTI_DIRECT_DEBIT_COLLECTION: {
        // @ts-expect-error
        return this.totalAmount;
      }
      case REQUEST_TYPES.TRANSFER:
        // @ts-expect-error
        return { value: this.amount, currency: this.amountCurrency };
      case REQUEST_TYPES.MULTI_TRANSFER:
        return {
          // @ts-expect-error
          value: this.totalTransfersAmount,
          // @ts-expect-error
          currency: this.totalTransfersAmountCurrency,
        };
      case REQUEST_TYPES.VIRTUAL_CARD:
        // @ts-expect-error
        return { value: this.paymentMonthlyLimit, currency: this.currency };
      case REQUEST_TYPES.FLASH_CARD:
        // @ts-expect-error
        return { value: this.paymentLifespanLimit, currency: this.currency };
    }
  }

  get isCredit() {
    return this.requestType === REQUEST_TYPES.MULTI_DIRECT_DEBIT_COLLECTION;
  }

  @waitFor
  async isAwaitingMyApproval() {
    let organization = this.organizationManager.organization;
    let { requests } = await this.networkManager.request(
      `${apiBaseURL}/${requestsNamespace}/requests?organization_id=${organization.id}&approval_workflow_status=${APPROVAL_WORKFLOW_STATUS.AWAITS_MY_APPROVAL}&per_page=500`,
      {
        method: 'GET',
      }
    );
    // @ts-expect-error
    return requests?.some(({ id }) => id === this.id);
  }

  @waitFor
  async approveRequest() {
    // @ts-expect-error
    let { bank_account_id } = this.serialize();
    let data = { bank_account_id };

    try {
      let response = await apiAction(this, { method: 'POST', path: 'approve', data });

      if (
        this.featuresManager.isEnabled('approvalWorkflows') &&
        !(await this.isAwaitingMyApproval())
      ) {
        let transferKey = Object.keys(response)[0];
        response[transferKey].status = STATUS.APPROVED;
      }

      this.store.pushPayload(response);
    } catch (error) {
      // @ts-expect-error
      if (error instanceof InvalidError && error.errors) {
        // @ts-expect-error
        let errors = errorsArrayToHash(error.errors);
        this.networkManager.errorModelInjector(this, errors);
      }

      throw error;
    }
  }

  @waitFor
  async cancelRequest() {
    try {
      let response = await apiAction(this, { method: 'POST', path: 'cancel' });
      if (Object.keys(response).length === 0) {
        throw new Error('Request cancel failed');
      }
      this.store.pushPayload(response);
    } catch (error) {
      // @ts-expect-error
      if (error instanceof InvalidError && error.errors) {
        // @ts-expect-error
        let errors = errorsArrayToHash(error.errors);
        this.networkManager.errorModelInjector(this, errors);
      }

      throw error;
    }
  }

  @waitFor
  async declineRequest() {
    // @ts-expect-error
    let { declined_note } = this.serialize();
    let data = { declined_note };

    try {
      let response = await apiAction(this, { method: 'POST', path: 'decline', data });
      this.store.pushPayload(response);
    } catch (error) {
      // @ts-expect-error
      if (error instanceof InvalidError && error.errors) {
        // @ts-expect-error
        let errors = errorsArrayToHash(error.errors);
        this.networkManager.errorModelInjector(this, errors);
      }

      throw error;
    }
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    request: RequestModel;
  }
}
