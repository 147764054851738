export default {
  "supplier-selector-wrapper": "qMP",
  "select-supplier": "qMl",
  "dropdown": "qMX",
  "iban": "qMC",
  "card": "qMk body-2",
  "add-btn": "qMJ btn btn--square btn--icon-only",
  "archived-card": "qMs",
  "title": "qMF body-1",
  "archived-title": "qMg",
  "edit-supplier-tooltip": "qMD",
  "add-supplier": "qMV btn btn--tertiary",
  "error": "qML caption",
  "form-title": "qMT title2"
};
