export default {
  "header": "cah",
  "top-section": "caf",
  "close": "caK btn btn--icon-only btn--tertiary",
  "container": "caG",
  "picto": "car",
  "avatar": "cab",
  "details": "cay",
  "expiration-date": "caU",
  "payment-method": "caj"
};
