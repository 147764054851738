/* import __COLOCATED_TEMPLATE__ from './select-transaction.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { DATE_FORMAT_TOKENS, dateToken } from '@qonto/ui-kit/utils/date-token';
import dayjs from 'dayjs';
import { task, timeout } from 'ember-concurrency';

// @ts-expect-error
import { DATE_PICKER_FIELD_FORMAT } from 'qonto/constants/dates';
// @ts-expect-error
import { DEBOUNCE_MS } from 'qonto/constants/timers';
// @ts-expect-error
import { TRANSACTIONS_ATTACHMENTS_LIMIT_REACHED } from 'qonto/constants/transactions';
import { InvoicePreview } from 'qonto/react/components/match-invoice/invoice-preview';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

interface MatchInvoiceFlowMatchingSelectedTransactionSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class MatchInvoiceFlowMatchingSelectedTransactionComponent extends Component<MatchInvoiceFlowMatchingSelectedTransactionSignature> {
  @service declare store: Services['store'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare intl: Services['intl'];
  @service declare segment: Services['segment'];
  invoicePreview = InvoicePreview;

  get invoice() {
    // @ts-expect-error
    return this.args.context.invoice;
  }

  get invoiceName() {
    return this.invoice.customerSnapshot?.name
      ? this.invoice.customerSnapshot?.name
      : this.invoice.fileName;
  }

  get attachment() {
    // @ts-expect-error
    return this.args.context.attachment;
  }

  get isUnpaidInvoice() {
    return this.invoice.status === 'unpaid';
  }

  @action
  emptyStateCopy(searchQuery = '') {
    if (searchQuery.length > 1) {
      if (!this.isUnpaidInvoice) {
        return this.intl.t(
          'receivable-invoices.mark-as-paid.select-transaction-step.errors.no-results.another-transaction.subtitle'
        );
      } else {
        return this.intl.t(
          'receivable-invoices.mark-as-paid.select-transaction-step.errors.no-results.subtitle'
        );
      }
    } else {
      return this.intl.t(
        'receivable-invoices.mark-as-paid.select-transaction-step.errors.no-transactions.subtitle'
      );
    }
  }

  // @ts-expect-error
  @action formatSubtitle(item) {
    let date = dateToken({
      date: item.emittedAt,
      // @ts-expect-error
      locale: this.intl.primaryLocale,
      token: DATE_FORMAT_TOKENS.DATE_YEAR_S,
    });
    let description = item.description ? ` · ${item.description}` : '';

    return `${date}${description}`;
  }

  @action markAsPaidWithoutTransaction() {
    // @ts-expect-error
    this.args.transitionToNext();
    this.segment.track('receivable_invoice_linked_paid_wo_transaction', {
      origin: 'invoice_modal',
    });
  }

  filterGroup = {
    conditional: 'and',
    expressions: [
      {
        property: 'attachment_ids',
        values: [this.attachment.id],
        operator: 'not_in',
      },
      {
        property: 'status',
        values: ['completed'],
        operator: 'in',
      },
      {
        property: 'side',
        operator: 'eq',
        values:
          parseFloat(this.invoice.amountDue) < 0 && this.invoice.imported ? ['debit'] : ['credit'],
      },
    ],
  };

  @action
  // @ts-expect-error
  updatePeriodSelection(period) {
    let indexToUpdate = this.filterGroup.expressions.findIndex(
      expression => expression.property === 'settled_at'
    );

    if (period) {
      let periodFilter = { property: 'settled_at' };

      switch (period.key) {
        case 'this_month':
          Object.assign(periodFilter, { values: ['current_month'], operator: 'interval' });
          break;
        case 'last_month':
          Object.assign(periodFilter, { values: ['last_month'], operator: 'interval' });
          break;
        case 'custom_period':
          Object.assign(periodFilter, {
            values: [
              dayjs(period.startDate).startOf('day').valueOf(),
              dayjs(period.endDate).endOf('day').valueOf(),
            ],
            operator: 'within',
          });
          break;
        default:
          return;
      }

      if (indexToUpdate !== -1) {
        // @ts-expect-error
        this.filterGroup.expressions[indexToUpdate] = periodFilter;
      } else {
        // @ts-expect-error
        this.filterGroup.expressions.push(periodFilter);
      }
    } else {
      this.filterGroup.expressions.splice(indexToUpdate, 1);
    }

    this.fetchTransactionsTask.perform().catch(ignoreCancelation);
  }

  fetchTransactionsTask = task(this, { restartable: true }, async (searchQuery = '') => {
    await timeout(DEBOUNCE_MS);

    if (searchQuery !== '') {
      this.segment.track('receivable_invoice_search');
    }

    let sharedOptions = {
      includes: [
        'cards',
        'checks',
        'direct_debits',
        'incomes',
        'transfers',
        'beneficiaries',
        'wallet_to_wallets',
        'swift_incomes',
      ],
      sort: { property: 'emitted_at', direction: 'desc' },
      pagination: { page: 1, per_page: 20 },
      organization_id: this.organizationManager.organization.id,
    };

    let bankAccountId = this.invoice.bankAccount.get('id');
    if (bankAccountId !== null) {
      // @ts-expect-error
      sharedOptions.bank_account_ids = [bankAccountId];
    }
    // @ts-expect-error
    let { transactions: matchedTransactions } = await this.store.adapterFor('transaction').search({
      filter_group: {
        conditional: 'and',
        expressions: [
          {
            property: 'attachment_ids',
            values: [this.attachment.id],
            operator: 'in',
          },
        ],
      },
      ...sharedOptions,
    });
    // @ts-expect-error
    this.args.context.hasBeenMatchedBefore = matchedTransactions.length > 0;
    // @ts-expect-error
    this.args.context.isPaidWithNoTransaction =
      // @ts-expect-error
      !this.args.context.hasBeenMatchedBefore && this.args.context.invoice.status === 'paid';

    // @ts-expect-error
    let { transactions } = await this.store.adapterFor('transaction').search({
      filter_group: this.filterGroup,
      search: searchQuery,
      ...sharedOptions,
    });
    return transactions;
  });

  linkTransactionTask = task(this, { drop: true }, async transaction => {
    try {
      await transaction.linkAttachment([this.attachment]);
      this.segment.track('receivable_invoice_linked', { origin: 'invoice_modal' });
      this._markInvoiceAsPaid(transaction.emittedAt);
      // @ts-expect-error
      this.args.context.hasMatchedTransaction = true;
      // @ts-expect-error
      this.args.completeFlow();
    } catch (error) {
      // @ts-expect-error
      if (error.status === 422) {
        this._abortOnError(TRANSACTIONS_ATTACHMENTS_LIMIT_REACHED);
      } else {
        this._abortOnError('error');
      }
    }
  });

  // @ts-expect-error
  _markInvoiceAsPaid(transactionEmittedAt) {
    this.invoice.status = 'paid';

    let paidAtDate = this.invoice.paidAt
      ? // @ts-expect-error
        dayjs.max(dayjs(this.invoice.paidAt), dayjs(transactionEmittedAt)).toDate()
      : transactionEmittedAt;

    this.invoice.paidAt = dayjs(paidAtDate).format(DATE_PICKER_FIELD_FORMAT);
  }

  // @ts-expect-error
  _abortOnError(errorCase) {
    // @ts-expect-error
    this.args.context.abortOrigin = errorCase;
    // @ts-expect-error
    this.args.abort();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::MatchInvoice::SelectTransaction': typeof MatchInvoiceFlowMatchingSelectedTransactionComponent;
  }
}
