import type { ReactNode } from 'react';
import cx from 'clsx';
import { FormattedMessage } from 'react-intl';
import { Popover } from 'qonto/react/components/table-v2/popover';
import type { ModularTableColumn } from 'qonto/react/constants';
import styles from './styles.strict-module.css';
import { ColumnManagerItem } from './item';

interface ColumnManagerPopoverProps {
  shownColumns: ModularTableColumn[];
  hiddenColumns: ModularTableColumn[];
}

export function ColumnManagerPopover({
  shownColumns,
  hiddenColumns,
  ...props
}: ColumnManagerPopoverProps): ReactNode {
  return (
    <Popover
      className={styles.popover}
      crossOffset={16}
      height={342}
      offset={8}
      width={448}
      {...props}
    >
      <div className={styles.wrapper}>
        <div className={styles.section}>
          <div className={styles.header}>
            <span className={cx(styles.title, 'body-2')}>
              <FormattedMessage id="transactions.modular_table.manage_column.dialog.label.shown" />
            </span>
          </div>
          <div className={styles.scrollable}>
            <ul className={styles.list}>
              {shownColumns.map(column => (
                <li key={column.id}>
                  <ColumnManagerItem
                    column={column}
                    isDisabled={column.id === 'Transactions'}
                    isVisible
                    title={column.id}
                  />
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className={styles.separator} />

        <div className={styles.section}>
          <div className={styles.header}>
            <span className={cx(styles.title, 'body-2')}>
              <FormattedMessage id="transactions.modular_table.manage_column.dialog.label.hidden" />
            </span>
          </div>
          <div className={styles.scrollable}>
            <ul className={styles.list}>
              {hiddenColumns.map(column => (
                <li key={column.id}>
                  <ColumnManagerItem column={column} isVisible={false} title={column.id} />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </Popover>
  );
}
