import Model, { attr } from '@ember-data/model';
import { service, type Registry as Services } from '@ember/service';

import { Addons } from 'qonto/constants/addons';

export default class SubscriptionProduct extends Model {
  @attr('string') declare productType: string;
  @attr('string') declare code: string;
  @attr('string') declare groupCode: string;
  @attr('string') declare lineup: string;
  @attr('string') declare description: string;
  @attr('boolean') declare descriptionHasDecoration: boolean;
  @attr('string') declare subDescription: string;
  // @ts-expect-error
  @attr('hash') monthlyPrice;
  // @ts-expect-error
  @attr('hash') annualPrice;
  // @ts-expect-error
  @attr('hash') benefitGroups;
  // @ts-expect-error
  @attr('hash') featureGroups;
  // @ts-expect-error
  @attr features;

  // @ts-expect-error
  @attr productCompatibilities;

  @attr('boolean') declare disabled: boolean;
  @attr('boolean') declare isAvailable: boolean;
  @attr('string') declare iconLightUrl: string;
  @attr('string') declare iconDarkUrl: string;

  @service declare intl: Services['intl'];

  // @ts-expect-error
  hasFeature(featureCode) {
    // @ts-expect-error
    return Boolean(this.features?.find(({ code }) => code === featureCode));
  }

  // @ts-expect-error
  getLimitByFeatureCode(featureCode) {
    // @ts-expect-error
    return this.features?.find(({ code }) => code === featureCode)?.limit;
  }

  get isAddon() {
    return this.productType === 'addon';
  }

  get localName() {
    return codeToName(this.intl, this.groupCode);
  }
}

// @ts-expect-error
export function codeToName(intl, groupCode) {
  switch (groupCode) {
    case Addons.AccountsPayable:
      return intl.t(
        'subscription.consumption-table.addon-overview.addon-list.accounts-payable.title'
      );
    case Addons.AccountsReceivable:
      return intl.t(
        'subscription.consumption-table.addon-overview.addon-list.accounts-receivable.title'
      );
    case Addons.ExpenseSpendManagement:
      return intl.t('subscription.consumption-table.addon-overview.addon-list.expense-spend.title');
    case 'solo_basic':
      return intl.t('subscription.change.plans.basic');
    case 'solo_smart':
      return intl.t('subscription.change.plans.smart');
    case 'solo_premium':
      return intl.t('subscription.change.plans.premium');
    case 'team_essential':
      return intl.t('subscription.change.plans.essential');
    case 'team_business':
      return intl.t('subscription.change.plans.business');
    case 'team_enterprise':
      return intl.t('subscription.change.plans.enterprise');
    default:
      return null;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'subscription-product': SubscriptionProduct;
  }
}
