/* import __COLOCATED_TEMPLATE__ from './card.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

interface ClientsSidebarCardSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class ClientsSidebarCardComponent extends Component<ClientsSidebarCardSignature> {
  @service declare intl: Services['intl'];

  get clientCountryName() {
    // @ts-expect-error
    let { countryCode } = this.args.client.billingAddress;
    // @ts-expect-error
    return countryCode ? this.intl.formatCountry(countryCode) : '';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Clients::Sidebar::Card': typeof ClientsSidebarCardComponent;
  }
}
