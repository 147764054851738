export default {
  "card": "tQ",
  "avatar": "te",
  "owner": "tB",
  "content": "tE",
  "identity": "td",
  "fullname": "tI",
  "type": "tt",
  "menu-container": "tM",
  "dropdown-menu": "tP",
  "menu-item": "tl body-2",
  "delete": "tX"
};
