/* import __COLOCATED_TEMPLATE__ from './simple-value.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface SimpleValueSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const SimpleValue = templateOnlyComponent<SimpleValueSignature>();

export default SimpleValue;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'OrganizationProfile::SimpleValue': typeof SimpleValue;
  }
}
