export default {
  "guests-loading": "cIu",
  "header": "ctS",
  "body": "ctc",
  "members": "ctq",
  "title": "ctZ caption-bold",
  "placeholder-container": "ctR",
  "icon": "ctQ",
  "details": "cte"
};
