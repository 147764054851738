/* import __COLOCATED_TEMPLATE__ from './processing.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface ProcessingSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const Processing = templateOnlyComponent<ProcessingSignature>();

export default Processing;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'HelpCenter::SelectedItem::Claim::Processing': typeof Processing;
  }
}
