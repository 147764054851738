export default {
  "status-avatar": "FM mr-16",
  "row": "FP",
  "active": "Fl",
  "animated": "FX",
  "fadein-item": "FC",
  "cell": "Fk",
  "cell-content": "FJ",
  "disabled": "Fs",
  "not-disabled": "FF",
  "cell-amount": "Fg",
  "ghost": "FD",
  "validated": "FV",
  "status-pin": "FL",
  "item-emitter-name": "FT",
  "item-emitter-name-text": "Fz"
};
