export default {
  "donut-chart-container": "cVk",
  "donut-chart-wrapper": "cVJ",
  "chart": "cVs",
  "donut-chart-legend-container": "cVF caption",
  "donut-chart-legend-item-empty": "cVg",
  "donut-chart-legend-bullet-empty": "cVD",
  "donut-chart-legend-bullet": "cVV",
  "donut-chart-legend-text-empty": "cVL",
  "donut-chart-legend-text": "cVT",
  "donut-chart-custom-label-uncategorized": "cVz",
  "custom-label-uncategorized-button": "cVa body-1",
  "donut-chart-legend": "cVH",
  "donut-chart-legend-item": "cVO",
  "donut-chart-legend-other-item": "cVm",
  "donut-chart-legend-uncategorized-item": "cVp",
  "donut-chart-legend-text-name": "cVx",
  "tooltip": "cVw"
};
