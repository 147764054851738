/* import __COLOCATED_TEMPLATE__ from './mapping.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Button, Disclaimer } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';
import { reads } from 'macro-decorators';
import { TrackedObject } from 'tracked-built-ins';

import { apiBaseURL, productsNamespace } from 'qonto/constants/hosts';
// @ts-expect-error
import { TEMPLATES } from 'qonto/constants/products-import-flow';
import { formatPayload } from 'qonto/utils/invoices/imports/format-payload';
import { matchTemplate } from 'qonto/utils/invoices/imports/match-template';
import { prepareColumns } from 'qonto/utils/invoices/imports/prepare-columns';

interface FlowsInvoicesProductsImportMappingSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLElement;
}

export default class FlowsInvoicesProductsImportMapping extends Component<FlowsInvoicesProductsImportMappingSignature> {
  @service declare intl: Services['intl'];
  @service declare networkManager: Services['networkManager'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare segment: Services['segment'];
  // @ts-expect-error
  @reads('args.context') context;
  // @ts-expect-error
  @reads('args.context.xlsFile') xlsFile;
  // @ts-expect-error
  @reads('args.context.xlsFile.columns') columns;
  // @ts-expect-error
  @reads('args.context.xlsFile.rows') rows;
  // @ts-expect-error
  @reads('args.context.mapping') mapping;

  constructor(owner: unknown, args: FlowsInvoicesProductsImportMappingSignature['Args']) {
    super(owner, args);

    if (!this.context.status.isMapped) {
      let { mapping, colsToHide } = matchTemplate(this.xlsFile, TEMPLATES);
      // @ts-expect-error
      this.context.mapping = new TrackedObject(mapping);
      this.context.status.isMapped = true;

      prepareColumns(this.xlsFile, mapping, colsToHide);
    }
  }

  button = Button;
  disclaimer = Disclaimer.Block;

  @tracked nameNotMappedWarning = false;

  get stepSubtitle() {
    return this.intl.t('products-import.mapping.subtitle', {
      number: this.rows?.length || 0,
    });
  }

  get warningMessage() {
    return this.intl.t('products-import.mapping.errors.missing-product-name', {
      count: this.rows?.length || 0,
    });
  }

  get options() {
    return [
      { id: 'title', name: this.intl.t('products-import.mapping.column.dropdown.name') },
      {
        id: 'description',
        name: this.intl.t('products-import.mapping.column.dropdown.details'),
      },
      {
        id: 'unit_price.value',
        name: this.intl.t('products-import.mapping.column.dropdown.unit-price'),
      },
      { id: 'unit', name: this.intl.t('products-import.mapping.column.dropdown.unit') },
      { id: 'vat_rate', name: this.intl.t('products-import.mapping.column.dropdown.vat') },
      {
        id: 'type',
        name: this.intl.t('products-import.mapping.column.dropdown.item-type'),
      },
      {
        id: 'internal_note',
        name: this.intl.t('products-import.mapping.column.dropdown.personal-notes'),
      },
    ];
  }

  @action
  // @ts-expect-error
  onMappingUpdate(column, selectedField) {
    if (selectedField) {
      this.mapping[column] = selectedField;
    } else {
      delete this.mapping[column];
    }
  }

  onConfirm = dropTask(async () => {
    this.nameNotMappedWarning = false;

    let mappedValues = Object.values(this.mapping);

    let isProductNameMapped = mappedValues.includes('title');

    if (!isProductNameMapped) {
      this.nameNotMappedWarning = true;
      return;
    }

    this.segment.track('items-import_confirm_clicked');

    let payload = formatPayload(this.xlsFile, this.mapping, 'products-import');

    try {
      let response = await this.networkManager.rawRequest(
        `${apiBaseURL}/${productsNamespace}/products/imports`,
        {
          method: 'POST',
          data: payload,
        }
      );

      if (response.status !== 202) {
        throw new Error('Unexpected response status');
      }

      let { data } = await response.json();
      let {
        attributes: { status },
      } = data;

      if (status === 'failed') {
        throw new Error('Failed to import products');
      }

      // @ts-expect-error
      this.args.transitionToNext();
    } catch {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.generic'));
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Invoices::ProductsImport::Mapping': typeof FlowsInvoicesProductsImportMapping;
  }
}
