/* eslint-disable @qonto/no-redirect-in-unrelated-hooks */
import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { IS_PERIODIC_WARNING_REVIEWED } from 'qonto/constants/kyc-kyb-update-process';
import { getSessionStorageItem } from 'qonto/utils/session-storage';

export default class OrganizationsRoute extends Route {
  @service sentry;
  @service abilities;
  @service router;
  @service featuresManager;
  @service organizationManager;
  @service subscriptionManager;
  @service topBanners;
  @service bannerFlashMessages;
  @service store;
  @service periodicUpdate;
  @service kycVerification;

  model(params) {
    let slug = params.organization_slug;
    let organization = this.organizationManager.getOrganizationBySlug(slug);

    if (organization) {
      return organization;
    }

    this.router.replaceWith('/404');
    return null;
  }

  async afterModel(organization, transition) {
    super.afterModel(...arguments, transition);

    // If the model is not loaded at this point could mean that the user was redirected to 404.
    if (!organization) {
      this.sentry.captureMessage('afterModel: organization is not loaded');
      return;
    }

    await this.organizationManager.setCurrentOrganizationAndMembership(organization);

    await Promise.all([
      this.subscriptionManager.refresh(organization),
      organization.loadTeams(),
      this.loadFlexKyb(organization),
    ]);

    let promises = [
      organization.loadBankAccounts(),
      ...(this.abilities.can('view connections external-account')
        ? [organization.loadBankConnections()]
        : []),
    ];

    let { kycAccepted, kycSubmitted } = this.organizationManager.membership;
    if (!kycAccepted || !kycSubmitted) {
      promises.push(
        this.kycVerification.generateUrl().catch(() => {
          // we don't want to block the user
        })
      );
    }

    await Promise.all(promises);

    // Hide dual nav animations on load, signin, org change
    document.body.classList.add('prevent-nav-animation');

    // Redirect to the `periodic-review-warning` route if the user has not yet reviewed their info.
    // As the `periodic-review-warning` route is part of the `organizations.show` route, we need
    // to ensure that the organization is fully loaded before redirecting.
    await this.handlePeriodicReviewWarning(transition);
  }

  serialize(model) {
    // in some cases `model` can be undefined. in these cases we fall back to
    // the current route model to get the current organization slug.
    return { organization_slug: (model || this.currentModel).get('slug') };
  }

  async loadFlexKyb(organization) {
    if (organization.isDeFreelancer || organization.isDeCompanyCreation) {
      await organization.loadFlexKyb();
    }
  }

  async handlePeriodicReviewWarning(transition) {
    if (!getSessionStorageItem(IS_PERIODIC_WARNING_REVIEWED)) {
      let transitionTargetName = transition.targetName;
      let updateProcess = await this.periodicUpdate.fetchUpdateProcess();

      if (this.periodicUpdate.shouldDisplayPeriodReviewWarning({ updateProcess })) {
        this.router.replaceWith('periodic-review-warning', {
          queryParams: {
            // In case the client was trying to access a page directly and was redirected to the
            // `periodic-review-warning` page, we want to redirect the user back to the original page.
            redirectRoute: !transitionTargetName.includes('periodic-review-warning')
              ? transitionTargetName
              : null,
          },
        });
      }
    }
  }

  activate() {
    let { underRegistration } = this.organizationManager.organization;
    if (underRegistration) {
      this.bannerFlashMessages.clearTopBannerMessages();
    }

    this.router.on('routeDidChange', this, this._triggerBanners);
  }

  deactivate() {
    this.bannerFlashMessages.clearTopBannerMessages();
    this.router.off('routeDidChange', this, this._triggerBanners);
  }

  _triggerBanners() {
    this.topBanners.triggerBanners();
  }
}
