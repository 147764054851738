export default {
  "row": "cAf",
  "cell": "cAK",
  "greyed-out": "cAG",
  "struck-through": "cAr",
  "flex-cell": "cAb",
  "details-container": "cAy",
  "overdue": "cAU",
  "align-right": "cAj",
  "customer-name-container": "cAW",
  "icon": "cAu",
  "warning": "cYS",
  "with-separator": "cYc",
  "align-center": "cYq"
};
