/* import __COLOCATED_TEMPLATE__ from './pin-code-input.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

interface PinCodeInputSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class PinCodeInputComponent extends Component<PinCodeInputSignature> {
  @tracked hidden = true;

  get inputType() {
    return this.hidden ? 'password' : 'text';
  }

  get hasError() {
    // @ts-expect-error
    return Boolean(this.args.errorMessage);
  }

  @action
  toggleView() {
    this.hidden = !this.hidden;
  }

  @action
  // @ts-expect-error
  valueChanged(event) {
    let { value } = event.target;

    // @ts-expect-error
    this.args.valueChanged(value);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    PinCodeInput: typeof PinCodeInputComponent;
  }
}
