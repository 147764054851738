export default {
  "container": "cNH",
  "depositAmount": "cNO",
  "flexRow": "cNm",
  "depositInput": "cNp",
  "dropdown": "cNx",
  "itemRow": "cNw",
  "line": "cNN",
  "estimates-container": "cNv",
  "estimates": "cNo",
  "dt": "cNn",
  "dd": "cNi"
};
