/* import __COLOCATED_TEMPLATE__ from './settlement.hbs'; */
import Component from '@glimmer/component';

import { Settlement } from 'qonto/react/components/flows/financing/early-repayment/settlement';

interface FlowsFinancingPayLaterEarlyRepaymentSettlementSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsFinancingPayLaterEarlyRepaymentSettlementComponent extends Component<FlowsFinancingPayLaterEarlyRepaymentSettlementSignature> {
  Settlement = Settlement;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Financing::PayLater::EarlyRepayment::Settlement': typeof FlowsFinancingPayLaterEarlyRepaymentSettlementComponent;
  }
}
