export default {
  "wrapper": "pj",
  "header": "pW",
  "no-separator": "pu",
  "tabs": "xS",
  "tab": "xc body-1 overlay",
  "selected": "xq",
  "organization-list": "xZ",
  "content": "xR",
  "empty-state": "xQ",
  "illustration": "xe",
  "body": "xB body-1",
  "cta": "xE btn btn--tertiary"
};
