export default {
  "wrapper": "qeN",
  "content": "qev",
  "title": "qeo",
  "aside": "qen",
  "description": "qei",
  "faq-link": "qeA",
  "close-btn": "qeY",
  "container": "qeh",
  "mandate-field-list": "qef",
  "mandate-field-item": "qeK",
  "mandate-field-item__dot": "qeG",
  "mandate-field-item__text": "qer"
};
