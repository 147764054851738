/* import __COLOCATED_TEMPLATE__ from './empty-state.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { DATE_FORMAT_TOKENS, dateToken } from '@qonto/ui-kit/utils/date-token';
import dayjs from 'dayjs';

interface AttachmentsEmptyStateSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class AttachmentsEmptyStateComponent extends Component<AttachmentsEmptyStateSignature> {
  @service declare intl: Services['intl'];

  get illustrationUrl() {
    // @ts-expect-error
    return this.args.transaction.isBilling
      ? '/illustrations/attachments/pending.svg'
      : '/illustrations/attachments/empty-state.svg';
  }

  get emptyStateTitle() {
    // @ts-expect-error
    if (this.args.transaction.isBilling) {
      return this.intl.t('attachment-viewer.bookkeeping.empty-state.invoice-fees.title');
    } else {
      return this.intl.t('attachment-viewer.bookkeeping.empty-state.attachment-not-required.title');
    }
  }

  get emptyStateSubtitle() {
    // @ts-expect-error
    if (this.args.transaction.isBilling) {
      return `${this.intl.t(
        'attachment-viewer.bookkeeping.empty-state.invoice-fees.subtitle'
      )} ${dateToken({
        // @ts-expect-error
        date: dayjs(this.args.transaction.emittedAt).endOf('month').add(1, 'day'),
        // @ts-expect-error
        locale: this.intl.primaryLocale,
        token: DATE_FORMAT_TOKENS.DATE_YEAR_S,
      })}`;
    } else {
      return this.intl.t(
        'attachment-viewer.bookkeeping.empty-state.attachment-not-required.subtitle'
      );
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Attachments::EmptyState': typeof AttachmentsEmptyStateComponent;
  }
}
