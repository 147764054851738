export default {
  "envelope": "am",
  "payment-page": "ap",
  "header": "ax",
  "title": "aw",
  "logo": "aN",
  "detail": "av",
  "dummy-field": "ao",
  "input": "an",
  "input--select": "ai",
  "input--readonly": "aA",
  "placeholder": "aY",
  "columns": "ah",
  "mandate": "af",
  "mandate-title": "aK",
  "icon-chevron": "aG",
  "mandate-details": "ar",
  "checkbox": "ab",
  "secure-note": "ay",
  "icon-lock": "aU",
  "cta": "aj"
};
