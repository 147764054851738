/* import __COLOCATED_TEMPLATE__ from './slide.hbs'; */
import Component from '@glimmer/component';

import { BadgeHighlight } from '@repo/design-system-kit';

interface DiscoverOverviewSlideSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class DiscoverOverviewSlideComponent extends Component<DiscoverOverviewSlideSignature> {
  badgeHighlight = BadgeHighlight;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Discover::Overview::Slide': typeof DiscoverOverviewSlideComponent;
  }
}
