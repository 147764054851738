export default {
  "request-expense-report-validation": "cuV",
  "account-selector": "cuL",
  "has-error": "cuT",
  "actions": "cuz",
  "action-btn": "cua",
  "action-btn-success": "cuH",
  "warnings": "cuO",
  "top-border": "cum",
  "account-selector-subtitle": "cup",
  "action-btn-right": "cux",
  "funds-disclaimer": "cuw",
  "account-options": "cuN",
  "account-balance": "cuv"
};
