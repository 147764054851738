export default {
  "mandates": "qhK",
  "mandates-empty": "qhG",
  "header": "qhr",
  "header-empty": "qhb",
  "header-content": "qhy",
  "search": "qhU",
  "search-bar": "qhj",
  "body": "qhW",
  "isEmpty": "qhu",
  "left-section": "qfS",
  "mandates-list": "qfc",
  "mandate-suspended": "qfq",
  "list-title": "qfZ caption-bold",
  "list-empty": "qfR",
  "empty-illustration": "qfQ",
  "empty-title": "qfe title-3",
  "empty-description": "qfB body-2",
  "body-details": "qfE",
  "l-app-content__page-header": "qfd",
  "pagination-footer": "qfI"
};
