/* import __COLOCATED_TEMPLATE__ from './terms-link-fields.hbs'; */
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { next } from '@ember/runloop';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

// @ts-expect-error
import scrollIntoView from 'qonto/utils/scroll-into-view';

interface InvoiceSettingsFormTermsLinkFieldsSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class InvoiceSettingsFormTermsLinkFields extends Component<InvoiceSettingsFormTermsLinkFieldsSignature> {
  @service declare intl: Services['intl'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare networkManager: Services['networkManager'];
  @service declare sentry: Services['sentry'];
  @service declare segment: Services['segment'];

  termsLinkFieldsId = `${guidFor(this)}-terms-link-field`;

  @tracked showTermsLinkFields = Boolean(
    // @ts-expect-error
    this.args.settings.tcLinkUrl || this.args.settings.tcLinkText
  );

  @action
  handleAddTermsConditionsLink() {
    this.showTermsLinkFields = true;
    next(() => {
      let element = document.getElementById(this.termsLinkFieldsId);
      scrollIntoView(element);
    });
  }

  @action
  handleRemoveTermsConditionsLink() {
    this.showTermsLinkFields = false;
    // @ts-expect-error
    this.args.settings.setProperties({
      tcLinkText: null,
      tcLinkUrl: null,
    });
  }

  @action
  handleFocusOutTcLinkUrl() {
    // @ts-expect-error
    if (this.args.settings.tcLinkUrl) {
      // @ts-expect-error
      this.args.settings.setProperties({
        // @ts-expect-error
        tcLinkUrl: this._normalizeLinkUrl(this.args.settings.tcLinkUrl),
      });
    }
  }

  /**
   * @todo: This can be refactored to use URL.parse() after updating core-js to 3.38.0
   */
  // @ts-expect-error
  _normalizeLinkUrl(urlInput) {
    try {
      let normalizedUrl = new URL(urlInput);
      if (normalizedUrl.protocol !== 'https:') {
        return urlInput.replace(/(.*):\/\//, 'https://');
      }
      return urlInput;
    } catch {
      return `https://${urlInput}`;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InvoiceSettings::Form::TermsLinkFields': typeof InvoiceSettingsFormTermsLinkFields;
  }
}
