export default {
  "content": "em",
  "balance": "ep",
  "balance-failed": "ex",
  "bank-infos": "ew",
  "bank-name": "eN",
  "bank-identifier": "ev",
  "bank-iban": "eo",
  "footer-container": "en",
  "links": "ei",
  "links--start-verification": "eA",
  "link": "eY",
  "start-verification-link": "eh",
  "last-sync": "ef"
};
