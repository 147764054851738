export default {
  "fullscreen-modal": "cmE",
  "creation-form-modal": "cmd",
  "links-container": "cmI",
  "link-field": "cmt",
  "link": "cmM",
  "add-link": "cmP",
  "remove-link": "cml",
  "flex-row": "cmX",
  "flex-item": "cmC",
  "unit-trigger": "cmk",
  "dynamic-placeholders": "cmJ"
};
