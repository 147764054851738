/* import __COLOCATED_TEMPLATE__ from './success.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { LottiePlayer } from '@repo/design-system-kit';

interface FlowsFinancingPayLaterApplicationSuccessSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsFinancingPayLaterApplicationSuccessComponent extends Component<FlowsFinancingPayLaterApplicationSuccessSignature> {
  lottiePlayer = LottiePlayer;

  @service declare segment: Services['segment'];

  @action
  goToTransfers() {
    this.segment.track('pay-later-signed_usage_clicked');
    // @ts-expect-error
    this.args.transitionToFlow({
      flowName: 'pay-later-transfer',
      stepId: 'invoice-upload',
      queryParams: {
        origin: 'pay-later-application.success',
      },
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Financing::PayLater::Application::Success': typeof FlowsFinancingPayLaterApplicationSuccessComponent;
  }
}
