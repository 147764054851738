/* import __COLOCATED_TEMPLATE__ from './form.hbs'; */
import { action } from '@ember/object';
import { next } from '@ember/runloop';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { Checkbox, Disclaimer, TextField } from '@repo/design-system-kit';
import move from 'ember-animated/motions/move';
import { fadeIn, fadeOut } from 'ember-animated/motions/opacity';

import ENV from 'qonto/config/environment';
// @ts-expect-error
import scrollIntoView from 'qonto/utils/scroll-into-view';

const MAX_RULES = 5;

interface RemindersConfigurationFormPanelSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class RemindersConfigurationFormPanel extends Component<RemindersConfigurationFormPanelSignature> {
  checkbox = Checkbox;

  @service declare userManager: Services['userManager'];
  @service declare store: Services['store'];
  @service declare intl: Services['intl'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare segment: Services['segment'];

  get contactEmail() {
    // @ts-expect-error
    return this.userManager.currentUser.email;
  }

  get displaySendToError() {
    // @ts-expect-error
    return this.args.displayErrors && !this.args.configuration.validations.attrs.sendTo.isValid;
  }

  // @ts-expect-error
  isExpanded = rule => !this.args.collapsedRules?.includes(rule);

  @action
  // @ts-expect-error
  updateSendTo(event) {
    // @ts-expect-error
    this.args.configuration.sendTo = event.target.value;
  }

  @action
  toggleCopyToSelf() {
    // @ts-expect-error
    this.args.configuration.copyToSelf = !this.args.configuration.copyToSelf;
  }

  @action
  addRule() {
    if (this.limitReached) {
      return;
    }

    this.segment.track('reminder_add-rule_clicked');

    this.store.createRecord('reminders-configuration/rule', {
      // @ts-expect-error
      remindersConfiguration: this.args.configuration,
    });

    // @ts-expect-error
    let newRuleIndex = this.args.configuration.visibleRules.length - 1;

    // @ts-expect-error
    this.args.setFocusedRule(newRuleIndex);

    next(scrollIntoView, `[data-test-reminder-rule="${newRuleIndex}"]`);
  }

  @action
  // @ts-expect-error
  deleteRule(index) {
    // @ts-expect-error
    this.args.configuration.visibleRules[index].deleteRecord();
    this.toastFlashMessages.toastSuccess(
      this.intl.t('payment-reminders.toasts.success.delete-reminders', { count: 1 })
    );

    // @ts-expect-error
    if (index < this.args.focusedRuleIndex) {
      // @ts-expect-error
      this.args.setFocusedRule(this.args.focusedRuleIndex - 1);
    }
  }

  get limitReached() {
    // @ts-expect-error
    let isLimitReached = this.args.configuration.visibleRules.length >= MAX_RULES;

    if (isLimitReached) {
      this.segment.track('reminder_limit-disclaimer_shown');
    }

    return isLimitReached;
  }

  get duration() {
    return ENV.environment === 'test' ? 0 : 150;
  }

  // @ts-expect-error
  *transition({ insertedSprites, keptSprites, removedSprites }) {
    yield insertedSprites.forEach(fadeIn);
    yield keptSprites.forEach(move);
    yield removedSprites.forEach(fadeOut);
  }

  TextField = TextField;
  Disclaimer = Disclaimer;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'RemindersConfiguration::Form': typeof RemindersConfigurationFormPanel;
  }
}
