/* import __COLOCATED_TEMPLATE__ from './validity-period.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { DATE_FORMAT_TOKENS, dateToken } from '@qonto/ui-kit/utils/date-token';
import dayjs from 'dayjs';
import { Changeset } from 'ember-changeset';
import { dropTask } from 'ember-concurrency';

// @ts-expect-error
import { DATE_PICKER_FIELD_FORMAT } from 'qonto/constants/dates';

interface CardSettingsValidityPeriodSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class CardSettingsValidityPeriodComponent extends Component<CardSettingsValidityPeriodSignature> {
  @service declare intl: Services['intl'];

  // @ts-expect-error
  changeset = new Changeset(this.args.card);

  get period() {
    let today = dayjs().endOf('day');
    let oneMonth = today.add(1, 'month');
    let yesterday = dayjs().subtract(1, 'day').endOf('day');
    let sixMonths = yesterday.add(6, 'month');
    let oneYear = yesterday.add(1, 'year');

    let range = [today, oneMonth, sixMonths, oneYear].map(date => ({
      date: date.toDate(),
      formatted: date.format(DATE_PICKER_FIELD_FORMAT),
    }));

    // Cannot be in map due to ember-intl-analyzer and unused translations rule
    // @ts-expect-error
    range[0].name = this.intl.t('cards.settings.validity-period.chip1');
    // @ts-expect-error
    range[1].name = this.intl.t('cards.settings.validity-period.chip2');
    // @ts-expect-error
    range[2].name = this.intl.t('cards.settings.validity-period.chip3');
    // @ts-expect-error
    range[3].name = this.intl.t('cards.settings.validity-period.chip4');

    return { min: today, max: oneYear, range };
  }

  customValidationMessages = {
    // @ts-expect-error
    valueMissing: this.intl.t('validations.errors.presence'),
    // @ts-expect-error
    rangeOverflow: this.intl.t('cards.steps.validity-period.datepicker.error.maximum-date'),
    // @ts-expect-error
    rangeUnderflow: this.intl.t('cards.steps.validity-period.datepicker.error.past-date', {
      today: dateToken({
        // @ts-expect-error
        date: new Date(),
        // @ts-expect-error
        locale: this.intl.primaryLocale,
        token: DATE_FORMAT_TOKENS.COMPACT,
      }),
    }),
  };

  persistState() {
    // persist only valid changes so when going back in step we have valid card record
    // @ts-expect-error
    if (!this.args.disablePersistingState && !this.isCtaDisabled) {
      this.changeset.execute();
    }
  }

  submitTask = dropTask(async () => {
    this.changeset.execute();
    // @ts-expect-error
    await this.args.onSubmit();
  });

  @action
  // @ts-expect-error
  setProperty(prop, val) {
    let newDate = val && dayjs(val).endOf('day').toDate();
    this.changeset.set(prop, newDate);
    this.persistState();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Card::Settings::ValidityPeriod': typeof CardSettingsValidityPeriodComponent;
  }
}
