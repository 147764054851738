export default {
  "wrapper": "cQX",
  "wrapper-revamp": "cQC",
  "container": "cQk",
  "container-revamp": "cQJ",
  "box-wrapper": "cQs",
  "warning-label": "cQF",
  "subtitle-container": "cQg",
  "info-container": "cQD",
  "card": "cQV",
  "disclaimer": "cQL",
  "list": "cQT",
  "list-item": "cQz body-2",
  "total": "cQa"
};
