/* import __COLOCATED_TEMPLATE__ from './filters.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { SearchFieldWithDebounce, Toggle } from '@repo/design-system-kit';

import { ColumnManagerTrigger } from 'qonto/react/components/table-v2/column-manager/trigger';

interface TransactionsFiltersSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class TransactionsFiltersComponent extends Component<TransactionsFiltersSignature> {
  // @ts-expect-error
  @tracked activeFilterId;
  // @ts-expect-error
  @tracked selectedPreset = this.args.selectedPreset;
  @tracked cachedFormData = [];

  searchField = SearchFieldWithDebounce;
  toggle = Toggle;
  columnManagerTrigger = ColumnManagerTrigger;

  @action
  // @ts-expect-error
  onSelectPreset(preset, id) {
    this.selectedPreset = preset;
    this.updateActiveFilterId(id);

    this.cachedFormData = [];
  }

  @action
  // @ts-expect-error
  applyFilters(query, id) {
    this.selectedPreset = undefined;
    this.updateActiveFilterId(id);

    // @ts-expect-error
    this.args.applyFilters(query);
  }

  @action
  clearFilters() {
    this.updateActiveFilterId();
    this.selectedPreset = undefined;
    this.cachedFormData = [];

    // @ts-expect-error
    this.args.applyFilters();
  }

  @action
  // @ts-expect-error
  refreshCache(formData, isUpdating = false) {
    let filteredCache = this.cachedFormData.filter(({ id }) => id !== formData.id);
    // @ts-expect-error
    this.cachedFormData = isUpdating ? [...filteredCache, formData] : filteredCache;
  }

  @action
  updateActiveFilterId(id = null) {
    this.activeFilterId = id;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transactions::Filters': typeof TransactionsFiltersComponent;
  }
}
