/* import __COLOCATED_TEMPLATE__ from './select-step.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { LottiePlayer, SearchField } from '@repo/design-system-kit';

interface BankAccountsSelectStepSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class BankAccountsSelectStep extends Component<BankAccountsSelectStepSignature> {
  lottiePlayer = LottiePlayer;

  @service declare organizationManager: Services['organizationManager'];
  searchField = SearchField;
  @tracked searchValue = '';

  get listOptions() {
    let { activeSortedQontoAccounts } = this.organizationManager.organization;
    // @ts-expect-error
    let isEnabledFn = this.args.isEnabledFn || (() => true);

    return (
      activeSortedQontoAccounts
        // @ts-expect-error
        .filter(({ isRemunerated }) => !isRemunerated)
        // @ts-expect-error
        .map(account => {
          return { account, isEnabled: isEnabledFn(account) };
        })
    );
  }

  get filteredListOptions() {
    let { searchValue, listOptions } = this;
    searchValue = searchValue?.trim().toLowerCase();

    if (!searchValue) {
      return listOptions;
    }

    // @ts-expect-error
    return listOptions.filter(({ account: { name } }) => name.toLowerCase().includes(searchValue));
  }

  @action
  // @ts-expect-error
  selectAccount({ account, isEnabled }) {
    if (isEnabled) {
      // @ts-expect-error
      let { transitionToNext } = this.args;
      // @ts-expect-error
      this.args.setAccount(account);
      transitionToNext();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'BankAccounts::SelectStep': typeof BankAccountsSelectStep;
  }
}
