export interface Column {
  id: string;
  width: number;
  position: number;
  pinned: 'left' | 'right' | 'unpinned';
}

export interface DisplayColumn extends Column {
  isVisible: boolean;
}

export const DEFAULT_ORDER = [
  'transaction',
  'amount',
  'operationDate',
  'attachments',
  'reference',
  'comments',
  'paymentMethod',
  'membership',
  'settledBalance',
  'settledDate',
  'bkVerificationStatus',
  'status',
];

export const DEFAULT_COLUMNS: DisplayColumn[] = [
  {
    id: 'transaction',
    width: 320,
    position: DEFAULT_ORDER.indexOf('transaction'),
    pinned: 'unpinned',
    isVisible: true,
  },
  {
    id: 'status',
    width: 128,
    position: DEFAULT_ORDER.indexOf('status'),
    pinned: 'unpinned',
    isVisible: false,
  },
  {
    id: 'settledDate',
    width: 128,
    position: DEFAULT_ORDER.indexOf('settledDate'),
    pinned: 'unpinned',
    isVisible: false,
  },
  {
    id: 'settledBalance',
    width: 128,
    position: DEFAULT_ORDER.indexOf('settledBalance'),
    pinned: 'unpinned',
    isVisible: false,
  },
  {
    id: 'reference',
    width: 320,
    position: DEFAULT_ORDER.indexOf('reference'),
    pinned: 'unpinned',
    isVisible: true,
  },
  {
    id: 'paymentMethod',
    width: 160,
    position: DEFAULT_ORDER.indexOf('paymentMethod'),
    pinned: 'unpinned',
    isVisible: true,
  },
  {
    id: 'operationDate',
    width: 128,
    position: DEFAULT_ORDER.indexOf('operationDate'),
    pinned: 'unpinned',
    isVisible: true,
  },
  {
    id: 'membership',
    width: 184,
    position: DEFAULT_ORDER.indexOf('membership'),
    pinned: 'unpinned',
    isVisible: false,
  },
  {
    id: 'comments',
    width: 104,
    position: DEFAULT_ORDER.indexOf('comments'),
    pinned: 'unpinned',
    isVisible: true,
  },
  {
    id: 'bkVerificationStatus',
    width: 128,
    position: DEFAULT_ORDER.indexOf('bkVerificationStatus'),
    pinned: 'unpinned',
    isVisible: false,
  },
  {
    id: 'attachments',
    width: 160,
    position: DEFAULT_ORDER.indexOf('attachments'),
    pinned: 'unpinned',
    isVisible: true,
  },
  {
    id: 'amount',
    width: 128,
    position: DEFAULT_ORDER.indexOf('amount'),
    pinned: 'unpinned',
    isVisible: true,
  },
];
