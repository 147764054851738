export default {
  "navigation-dropdown": "cFq",
  "expanded": "cFZ",
  "trigger": "cFR",
  "trigger-title-icon": "cFQ",
  "collapsed": "cFe",
  "responsive": "cFB",
  "logo": "cFE",
  "active": "cFd",
  "trigger-title": "cFI",
  "content": "cFt",
  "content-container": "cFM",
  "dropdown-icon": "cFP",
  "link": "cFl"
};
