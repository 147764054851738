export default {
  "referral-invite": "cYr",
  "referral": "cYb",
  "illustration": "cYy",
  "steps": "cYU",
  "step": "cYj",
  "share-referral": "cYW",
  "choice": "cYu",
  "line": "chS",
  "email": "chc",
  "email-input": "chq",
  "input-field--error": "chZ",
  "error-message": "chR",
  "external-link-icon": "chQ"
};
