/* import __COLOCATED_TEMPLATE__ from './color-picker.hbs'; */
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

// @ts-expect-error
import styles from 'qonto/components/card/color-picker.module.css';
import { CARD_DESIGNS, CARD_LEVELS } from 'qonto/constants/cards';

interface CardColorPickerSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLFieldSetElement;
}

export default class CardColorPicker extends Component<CardColorPickerSignature> {
  @service declare abilities: Services['abilities'];
  @service declare intl: Services['intl'];
  @tracked pickersGuid = guidFor(this);

  CARD_DESIGNS = CARD_DESIGNS;

  get designs() {
    // @ts-expect-error
    switch (this.args.cardLevel) {
      case CARD_LEVELS.METAL:
        return [
          CARD_DESIGNS.METAL_MINERAL_GRAY_2024,
          CARD_DESIGNS.METAL_SAND_GOLD_2024,
          CARD_DESIGNS.METAL_GRAPHITE_BLACK_2019,
        ];
      case CARD_LEVELS.PLUS:
      default:
        return [
          CARD_DESIGNS.PLUS_RECYCLED_PLASTIC_SILVER_2023,
          CARD_DESIGNS.PLUS_RECYCLED_PLASTIC_LILAC_2023,
          CARD_DESIGNS.PLUS_RECYCLED_PLASTIC_BLACK_2023,
        ];
    }
  }

  get checkedColor() {
    // @ts-expect-error
    if (!this.args.cardDesign) {
      // @ts-expect-error
      switch (this.args.cardLevel) {
        case CARD_LEVELS.METAL:
          return this.intl.t('cards.colors.metal.mineral-gray');
        case CARD_LEVELS.PLUS:
        default:
          return this.intl.t('cards.colors.plus.silver');
      }
    }
    // @ts-expect-error
    switch (this.args.cardDesign) {
      case CARD_DESIGNS.METAL_SAND_GOLD_2024:
        return this.intl.t('cards.colors.metal.sand-gold');
      case CARD_DESIGNS.METAL_MINERAL_GRAY_2024:
        return this.intl.t('cards.colors.metal.mineral-gray');
      case CARD_DESIGNS.METAL_GRAPHITE_BLACK_2019:
        return this.intl.t('cards.colors.metal.graphite-black');
      case CARD_DESIGNS.PLUS_RECYCLED_PLASTIC_LILAC_2023:
        return this.intl.t('cards.colors.plus.light-purple');
      case CARD_DESIGNS.PLUS_RECYCLED_PLASTIC_BLACK_2023:
        return this.intl.t('cards.colors.plus.black');
      case CARD_DESIGNS.PLUS_RECYCLED_PLASTIC_SILVER_2023:
      default:
        return this.intl.t('cards.colors.plus.silver');
    }
  }

  // @ts-expect-error
  getColorClassName(design) {
    switch (design) {
      case CARD_DESIGNS.METAL_GRAPHITE_BLACK_2019:
        return 'graphite-black';
      case CARD_DESIGNS.METAL_MINERAL_GRAY_2024:
        return 'mineral-gray';
      case CARD_DESIGNS.METAL_SAND_GOLD_2024:
        return 'sand-gold';
      case CARD_DESIGNS.PLUS_RECYCLED_PLASTIC_BLACK_2023:
        return 'black';
      case CARD_DESIGNS.PLUS_RECYCLED_PLASTIC_LILAC_2023:
        return 'lilac';
      default:
        return '';
    }
  }

  @action
  // @ts-expect-error
  getColorA11yLabel(design) {
    switch (design) {
      case CARD_DESIGNS.METAL_GRAPHITE_BLACK_2019:
        return this.intl.t('cards.colors.metal.graphite-black');
      case CARD_DESIGNS.METAL_MINERAL_GRAY_2024:
        return this.intl.t('cards.colors.metal.mineral-gray');
      case CARD_DESIGNS.METAL_SAND_GOLD_2024:
        return this.intl.t('cards.colors.metal.sand-gold');
      case CARD_DESIGNS.PLUS_RECYCLED_PLASTIC_BLACK_2023:
        return this.intl.t('cards.colors.plus.black');
      case CARD_DESIGNS.PLUS_RECYCLED_PLASTIC_LILAC_2023:
        return this.intl.t('cards.colors.plus.light-purple');
      default:
        return this.intl.t('cards.colors.plus.silver');
    }
  }

  @action
  // @ts-expect-error
  onPickerClick(design, { currentTarget }) {
    // @ts-expect-error
    this.pickers = document.getElementById(this.pickersGuid);

    // @ts-expect-error
    if (this.args.isDisabled) {
      return;
    }

    currentTarget.classList.add(styles.checked);
    currentTarget.setAttribute('aria-current', true);

    // @ts-expect-error
    this.pickers.querySelectorAll('button').forEach(elem => {
      if (elem !== currentTarget) {
        elem.classList.remove(styles.checked);
        elem.setAttribute('aria-current', false);
      }
    });

    // @ts-expect-error
    this.args.onColorChange(design);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Card::ColorPicker': typeof CardColorPicker;
  }
}
