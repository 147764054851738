/* import __COLOCATED_TEMPLATE__ from './teams.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

const featuresOrderByTrigger = {
  default: ['teamAccess', 'managerRole', 'requests', 'cards', 'flashCard'],
  requests: ['requests', 'teamAccess', 'managerRole', 'flashCard', 'cards'],
  additionalUsers: ['teamAccess', 'managerRole', 'requests', 'flashCard', 'cards'],
  managerRole: ['managerRole', 'teamAccess', 'requests', 'flashCard', 'cards'],
  flashCard: ['flashCard', 'teamAccess', 'managerRole', 'requests', 'cards'],
};

interface DiscoverBundleTeamsSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class DiscoverBundleTeamsComponent extends Component<DiscoverBundleTeamsSignature> {
  @service declare flowLinkManager: Services['flowLinkManager'];
  @service declare intl: Services['intl'];
  @service declare localeManager: Services['localeManager'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare segment: Services['segment'];
  @service declare subscriptionManager: Services['subscriptionManager'];

  name = 'access management';
  lineup = 'teams';

  localSlides = {
    teamAccess: {
      // @ts-expect-error
      title: this.intl.t('upsell.bundle-teams.team-access.title'),
      // @ts-expect-error
      description: this.intl.t('upsell.bundle-teams.team-access.text'),
      img: {
        de: '/illustrations/upsell/de/team-access.png',
        en: '/illustrations/upsell/en/team-access.png',
        es: '/illustrations/upsell/es/team-access.png',
        fr: '/illustrations/upsell/fr/team-access.png',
        it: '/illustrations/upsell/it/team-access.png',
      },
    },
    managerRole: {
      // @ts-expect-error
      title: this.intl.t('upsell.bundle-teams.manager-role.title'),
      // @ts-expect-error
      description: this.intl.t('upsell.bundle-teams.manager-role.text'),
      img: {
        de: '/illustrations/upsell/de/manager-roles.png',
        en: '/illustrations/upsell/en/manager-roles.png',
        es: '/illustrations/upsell/es/manager-roles.png',
        fr: '/illustrations/upsell/fr/manager-roles.png',
        it: '/illustrations/upsell/it/manager-roles.png',
      },
    },
    requests: {
      // @ts-expect-error
      title: this.intl.t('upsell.bundle-teams.transfer-approval.title'),
      // @ts-expect-error
      description: this.intl.t('upsell.bundle-teams.transfer-approval.text'),
      img: {
        de: '/illustrations/upsell/de/requests.png',
        en: '/illustrations/upsell/en/requests.png',
        es: '/illustrations/upsell/es/requests.png',
        fr: '/illustrations/upsell/fr/requests.png',
        it: '/illustrations/upsell/it/requests.png',
      },
    },
    cards: {
      // @ts-expect-error
      title: this.intl.t('upsell.bundle-teams.cards.title'),
      // @ts-expect-error
      description: this.intl.t('upsell.bundle-teams.cards.text'),
      img: {
        de: '/illustrations/upsell/de/cards.png',
        en: '/illustrations/upsell/en/cards.png',
        es: '/illustrations/upsell/es/cards.png',
        fr: '/illustrations/upsell/fr/cards.png',
        it: '/illustrations/upsell/it/cards.png',
      },
    },
    flashCard: {
      // @ts-expect-error
      title: this.intl.t('upsell.bundle-teams.flash.title'),
      // @ts-expect-error
      description: this.intl.t('upsell.bundle-teams.flash.text'),
      img: {
        de: '/illustrations/upsell/card-flash-upsell.png',
        en: '/illustrations/upsell/card-flash-upsell.png',
        es: '/illustrations/upsell/card-flash-upsell.png',
        fr: '/illustrations/upsell/card-flash-upsell.png',
        it: '/illustrations/upsell/card-flash-upsell.png',
      },
    },
  };

  get slides() {
    let slidesByKey = this.upsellTrigger ?? 'default';

    // @ts-expect-error
    return featuresOrderByTrigger[slidesByKey].map(orderedKey => {
      // @ts-expect-error
      let { title, description, img, soon } = this.localSlides[orderedKey];
      return {
        title,
        description,
        img: img[this.localeManager.locale],
        soon,
      };
    });
  }

  get upsellTrigger() {
    // @ts-expect-error
    return this.args.data?.upsellTrigger;
  }

  @action
  onClick() {
    let { code: price_plan } = this.subscriptionManager.currentPricePlan;
    let { role } = this.organizationManager.membership;

    this.segment.track('upsell_bundle_page_clicked', {
      empty_state_name: this.name,
      price_plan,
      role,
    });

    // @ts-expect-error
    if (this.args.transitionToNext) {
      // @ts-expect-error
      this.args.transitionToNext();
    } else {
      this.flowLinkManager.transitionTo({
        name: 'subscription-change',
        stepId: 'discover-teams',
        queryParams: { lineup: this.lineup, upsellTrigger: this.upsellTrigger },
      });
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Discover::Bundle::Teams': typeof DiscoverBundleTeamsComponent;
  }
}
