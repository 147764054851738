// @ts-nocheck
import Service, { service } from '@ember/service';

import { dropTask } from 'ember-concurrency';

import { REQUEST_TYPES } from 'qonto/constants/approval-workflow';
import { apiBaseURL, requestsV4Namespace, supplierInvoiceNamespace } from 'qonto/constants/hosts';
import { CURRENCIES } from 'qonto/constants/international-out/currency';
import transformKeys from 'qonto/utils/transform-keys';

export default class SupplierInvoicesManager extends Service {
  @service attachmentsManager;
  @service errors;
  @service internationalOutManager;
  @service intl;
  @service organizationManager;
  @service store;
  @service toastFlashMessages;
  @service networkManager;

  async findSupplierInvoice(supplierInvoiceId) {
    return await this.store.findRecord('supplier-invoice', supplierInvoiceId);
  }

  /**
   * Task to perform when modal for invoice deletion is confirmed
   * @param {Function} close - the close function for the modal
   * @param {SupplierInvoice} invoice - the invoice to be removed
   * @param {Function} onDeleteInvoice - the function to be called after the invoice is removed
   * @returns {void}
   */
  confirmDeleteInvoiceTask = dropTask(async (close, { invoice, onDeleteInvoice }) => {
    try {
      await this.deleteInvoiceTask.perform(invoice);
    } catch (error) {
      this.errors.handleError(error);
    } finally {
      onDeleteInvoice?.();
      close();
    }
  });

  /**
   * Cleans an invoice from its attachment and destroys it
   * @param {SupplierInvoice} invoice
   * @returns {void}
   */
  deleteInvoiceTask = dropTask(async invoice => {
    let attachmentId = invoice.belongsTo('attachment').id();
    let attachment = await this.store.peekRecord('attachment', attachmentId);

    delete invoice.attachment;
    invoice.notifyPropertyChange('attachment');
    attachment?.unloadRecord();

    await invoice.destroyRecord();

    this.toastFlashMessages.toastSuccess(
      this.intl.t('supplier-invoices.success-toast.delete-invoice')
    );
  });

  /**
   * Extracts the supplier invoice id from the SUPPLIER_INVOICE_EVENTS.ANALYZED event
   * Supports both old and new format
   * TODO : remove me when the updated event format has been shipped in BE
   * @param {Object} eventedResponsePayload
   * @returns string | undefined
   */
  getSupplierInvoiceIdFromAnalyzedEvent(eventedResponsePayload) {
    let { supplier_invoice, object_id } = eventedResponsePayload;
    return object_id ?? supplier_invoice?.id;
  }

  loadMemberships(invoices) {
    let membershipIds = invoices.reduce((ids, invoice) => {
      return [
        ...ids,
        invoice.belongsTo('initiator').id(),
        invoice.requestTransfer?.initiator_id,
        ...(invoice.approvalWorkflow?.approverIds || []),
        invoice.approvalWorkflow?.lastApproverId,
        invoice.approvalWorkflow?.verifiedBy,
      ];
    }, []);

    let uniqueMembershipsIdsSet = new Set([...membershipIds]);

    let loadedIds = this.store.peekAll('membership').map(({ id }) => id);
    let toLoadIds = Array.from(uniqueMembershipsIdsSet).filter(id => id && !loadedIds.includes(id));

    if (toLoadIds.length > 0) {
      return this.store.query('membership', {
        organization_id: this.organizationManager.organization.id,
        filters: { ids: toLoadIds },
        per_page: toLoadIds.length,
      });
    }
  }

  fetchApprovalWorkflowEstimateTask = dropTask(async supplierInvoice => {
    let { supplierId, totalAmount, initiator } = supplierInvoice ?? {};
    if (!supplierId || !totalAmount) {
      return null;
    }

    return await this.store
      .adapterFor('approval-workflow-state')
      .estimate(REQUEST_TYPES.SUPPLIER_INVOICE, {
        supplierId,
        uploaderId: initiator?.id,
        amount: totalAmount,
      });
  });

  fetchCurrencyCodesTask = dropTask(async () => {
    try {
      let currencies = await this.internationalOutManager.getTargetCurrencies();
      // Using a Set in order to remove potential 'EUR' currency duplicates
      return [...new Set([...currencies.map(currency => currency.code), CURRENCIES.EUR])].sort();
    } catch (error) {
      this.errors.handleError(error);
    }
  });

  approveInvoiceTask = dropTask(async invoice => {
    let { supplierInvoiceRequestId } = invoice.approvalWorkflow;

    try {
      let response = await this.networkManager.request(
        `${apiBaseURL}/${requestsV4Namespace}/requests/supplier_invoices/${supplierInvoiceRequestId}/approve`,
        {
          method: 'POST',
          headers: {
            'X-Qonto-Organization-ID': this.organizationManager.organization.id,
          },
        }
      );

      let camelizedResponse = transformKeys(response);
      return camelizedResponse;
    } catch (error) {
      this.errors.handleError(error);
    }
  });

  assignApprovalWorkflowTask = dropTask(async (invoice, action, declineNote) => {
    try {
      return await this.store.adapterFor('supplier-invoice').assignApprovalWorkflow({
        id: invoice.id,
        action,
        declineNote,
      });
    } catch (error) {
      this.errors.handleError(error);
    }
  });

  unlinkInvoiceFromCreditNote = dropTask(async payload => {
    let { creditNoteId, supplierInvoiceId } = payload;
    await this.networkManager.rawRequest(
      `${apiBaseURL}/${supplierInvoiceNamespace}/supplier_invoices/${supplierInvoiceId}/unlink_credit_note`,
      {
        method: 'POST',
        body: JSON.stringify({
          credit_note_id: creditNoteId,
        }),
      }
    );
  });
}
