/* import __COLOCATED_TEMPLATE__ from './self-transfer.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface SelfTransferSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const SelfTransfer = templateOnlyComponent<SelfTransferSignature>();

export default SelfTransfer;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Transfers::Sepa::PayLater::Errors::SelfTransfer': typeof SelfTransfer;
  }
}
