/* import __COLOCATED_TEMPLATE__ from './add-transaction.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

interface AddTransactionSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class AddTransactionComponent extends Component<AddTransactionSignature> {
  @service declare modals: Services['modals'];

  @action
  openTransactionsModal() {
    this.modals.open('supplier-invoices/transactions-modal', {
      isFullScreenModal: true,
      // @ts-expect-error
      attachment: this.args.invoice.attachment,
      // @ts-expect-error
      invoice: this.args.invoice,
      // @ts-expect-error
      onCloseModal: () => this.args.refreshTransaction(),
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'SupplierInvoices::TransactionsContainer::AddTransaction': typeof AddTransactionComponent;
  }
}
