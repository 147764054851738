export default {
  "section": "cnL",
  "header-section": "cnT",
  "header-title": "cnz",
  "header-item": "cna",
  "item": "cnH",
  "center-align": "cnO",
  "content-gap": "cnm",
  "cta-footer": "cnp",
  "section-content": "cnx"
};
