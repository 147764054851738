/* import __COLOCATED_TEMPLATE__ from './discover-teams.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface DiscoverTeamsSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const DiscoverTeams = templateOnlyComponent<DiscoverTeamsSignature>();

export default DiscoverTeams;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::SubscriptionChange::DiscoverTeams': typeof DiscoverTeams;
  }
}
