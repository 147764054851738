/* import __COLOCATED_TEMPLATE__ from './modal.hbs'; */
import { action } from '@ember/object';
import { next } from '@ember/runloop';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { LottiePlayer, Spinner, TextAreaField } from '@repo/design-system-kit';
import { dropTask, task } from 'ember-concurrency';
import { reads } from 'macro-decorators';

import {
  ATTEMPTS_LIMIT,
  GENERATED_LOGO_MODEL,
  LOGO_GENERATOR_EVENTS,
  LOGO_STATUS,
  TRACKING_EVENTS,
  // @ts-expect-error
} from 'qonto/constants/generated-logo';
import { ignoreCancelation } from 'qonto/utils/ignore-error';
// @ts-expect-error
import scrollIntoView from 'qonto/utils/scroll-into-view';

interface GenerateLogoModalSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLElement;
}

export default class GenerateLogoModalComponent extends Component<GenerateLogoModalSignature> {
  lottiePlayer = LottiePlayer;
  textAreaField = TextAreaField;

  spinner = Spinner;

  @service declare store: Services['store'];
  @service declare intl: Services['intl'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare notifierManager: Services['notifierManager'];
  @service declare localeManager: Services['localeManager'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare segment: Services['segment'];

  @tracked isValidationEnabled = false;
  // @ts-expect-error
  @tracked logo;
  @tracked hasAgreedToTermsConditions = false;
  @tracked isInappropriatePrompt = false;

  // @ts-expect-error
  @reads('organizationManager.organization') organization;
  @tracked currentImageIndex = 0;
  // @ts-expect-error
  @tracked logos;

  attemptsLimit = ATTEMPTS_LIMIT;

  constructor(owner: unknown, args: GenerateLogoModalSignature['Args']) {
    super(owner, args);

    this.fetchLogosTask.perform({ isFirstFetch: true }).catch(ignoreCancelation);

    // @ts-expect-error
    this.notifierManager.on(LOGO_GENERATOR_EVENTS.GENERATED, this, '_handleLogoGenerated');
    // @ts-expect-error
    this.notifierManager.on(LOGO_GENERATOR_EVENTS.ERROR, this, '_handleLogoGeneratedError');
  }

  willDestroy() {
    // @ts-expect-error
    this.notifierManager.off(LOGO_GENERATOR_EVENTS.GENERATED, this, '_handleLogoGenerated');
    // @ts-expect-error
    this.notifierManager.off(LOGO_GENERATOR_EVENTS.ERROR, this, '_handleLogoGeneratedError');

    super.willDestroy();
  }

  // @ts-expect-error
  async _handleLogoGenerated(payload) {
    let { organization_id } = payload;
    if (organization_id !== this.organization.id) return;

    try {
      await this.fetchLogosTask.perform({ afterLogoCreate: true });
      this._createNewRecordFromLastLogo();
    } catch (error) {
      ignoreCancelation(error);
    }
  }

  // @ts-expect-error
  _handleLogoGeneratedError(payload) {
    let { status, organization_id } = payload;
    if (organization_id !== this.organization.id) return;

    this._createNewRecordFromLastLogo();

    let statusToFilterFor = [LOGO_STATUS.NEW, LOGO_STATUS.PENDING];
    // @ts-expect-error
    this.store.unloadRecord(this.logos.find(logo => statusToFilterFor.includes(logo.status)));

    if (status === 'inappropriate_prompt') {
      this.isInappropriatePrompt = true;
      this.#scrollToErrorField('[data-error-inappropriate-prompt]');
    } else {
      this.toastFlashMessages.toastError(this.intl.t('generated-logo.toasts.generic.error'));
    }
  }

  _createNewRecordFromLastLogo() {
    let lastLogo = this.logos.at(-1);
    if (lastLogo) {
      let { userInput, color, style } = lastLogo;
      this.logo = this.store.createRecord(GENERATED_LOGO_MODEL, {
        locale: this.localeManager.locale,
        userInput,
        color,
        style,
      });
    }
  }

  getLogoDataToPrefill() {
    if (!this.logos) return {};
    let lastGeneratedLogo = this.logos
      .filter(
        // @ts-expect-error
        logo =>
          logo.id !== null &&
          logo.status !== LOGO_STATUS.PENDING &&
          logo.organization?.id === this.organization.id
      )
      .at(-1);
    if (!lastGeneratedLogo) {
      return {};
    }
    let { userInput, color, style } = lastGeneratedLogo;
    return { userInput, color, style };
  }

  get generatedLogos() {
    return (
      this.logos
        // @ts-expect-error
        ?.filter(logo => logo.status === LOGO_STATUS.GENERATED)
        // @ts-expect-error
        .filter(logo => logo.organization?.id === this.organization.id)
    );
  }

  get attemptsCount() {
    return this.generatedLogos?.length;
  }

  get shoudDisplayUploadButton() {
    return this.generatedLogos?.length > 0 && !this.isLoading;
  }

  get shouldDisplayEmptyState() {
    return this.attemptsCount === 0 || !this.attemptsCount;
  }

  get isLoading() {
    let loadingStatuses = [LOGO_STATUS.NEW, LOGO_STATUS.PENDING];
    // @ts-expect-error
    let isLogoBeingCreated = this.logos?.some(logo => loadingStatuses.includes(logo.status));
    return this.submitFormTask.isRunning || isLogoBeingCreated;
  }

  get shouldDisplayTermsError() {
    return this.isValidationEnabled && !this.hasAgreedToTermsConditions && this.logo.userInput;
  }

  get shouldDisplayInappropriatePromptError() {
    return this.isInappropriatePrompt;
  }

  get maxAttemptsReached() {
    return this.attemptsCount >= ATTEMPTS_LIMIT;
  }

  @action
  toggleHasAgreedToTermsConditions() {
    this.hasAgreedToTermsConditions = !this.hasAgreedToTermsConditions;
    this.isValidationEnabled = false;
  }

  @action
  // @ts-expect-error
  updatePrompt(newPrompt) {
    this.logo.userInput = newPrompt;
    this.isInappropriatePrompt = false;
    this.isValidationEnabled = false;
  }

  @action
  // @ts-expect-error
  selectColor(newColorId) {
    this.logo.setProperties({ color: newColorId.target.value });
  }

  @action
  // @ts-expect-error
  selectStyle(newStyleId) {
    this.logo.setProperties({ style: newStyleId.target.value });
  }

  @action
  // @ts-expect-error
  handleSubmit(event) {
    event.preventDefault();
    if (this.isLoading) return;
    this.submitFormTask.perform().catch(ignoreCancelation);
  }

  // @ts-expect-error
  trackEvent(eventName) {
    let { color, style, userInput } = this.logo;
    let attemptsLeft = this.attemptsLimit - this.attemptsCount;

    this.segment.track(eventName, {
      userInput,
      color,
      style,
      attemptsLeft,
    });
  }

  fetchLogosTask = dropTask(async params => {
    try {
      this.logos = await this.store.findAll(GENERATED_LOGO_MODEL);
    } catch {
      /* empty */
    }

    if (params?.isFirstFetch) {
      let prefillData = this.getLogoDataToPrefill();
      this.logo = this.store.createRecord(GENERATED_LOGO_MODEL, {
        locale: this.localeManager.locale,
        ...prefillData,
      });
      if (this.generatedLogos.length < 1) return;
      this.currentImageIndex = this.generatedLogos.length - 1;
    }

    if (params?.afterLogoCreate) {
      this.currentImageIndex = this.attemptsCount;
    }
  });

  submitFormTask = dropTask(async () => {
    this.isValidationEnabled = true;

    let isValid = await this.validateForm();

    if (isValid) {
      try {
        await this.logo.save();
        this.isValidationEnabled = false;
        //refetch
        // @ts-expect-error
        this.fetchLogosTask.perform().catch(ignoreCancelation);

        this.trackEvent(TRACKING_EVENTS.CREATION);
      } catch (error) {
        // @ts-expect-error
        if (error.status === 422) {
          this.toastFlashMessages.toastError(this.intl.t('generated-logo.toasts.limit-reached'));
        } else {
          this.toastFlashMessages.toastError(this.intl.t('generated-logo.toasts.generic.error'));
        }
      }
    }
  });

  async validateForm() {
    let { validations } = await this.logo.validate();

    if (!this.hasAgreedToTermsConditions && validations.isValid) {
      this.#scrollToErrorField('[data-error-missing-tos-accepted]');
    }

    return validations.isValid && this.hasAgreedToTermsConditions;
  }

  // @ts-expect-error
  #scrollToErrorField(selector) {
    next(() => scrollIntoView(selector));
  }

  uploadAvatarTask = task(async logo => {
    try {
      await logo.uploadLogo();
      this.toastFlashMessages.toastSuccess(
        this.intl.t('generated-logo.toasts.upload-logo.success')
      );

      await this.organization.getAvatar();

      this.trackEvent(TRACKING_EVENTS.UPLOAD);
    } catch {
      this.toastFlashMessages.toastError(this.intl.t('generated-logo.toasts.upload-logo.error'));
    }
  });

  @action
  uploadSelectedLogo() {
    let currentLogo = this.generatedLogos[this.currentImageIndex];
    this.uploadAvatarTask.perform(currentLogo).catch(ignoreCancelation);
  }

  @action
  handleDownloadEvent() {
    this.trackEvent(TRACKING_EVENTS.DOWNLOAD);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'GenerateLogo::Modal': typeof GenerateLogoModalComponent;
  }
}
