/* import __COLOCATED_TEMPLATE__ from './table.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

interface DirectDebitCollectionsTableSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class DirectDebitCollectionsTableComponent extends Component<DirectDebitCollectionsTableSignature> {
  @service declare intl: Services['intl'];

  get headerDateSortField() {
    // @ts-expect-error
    return this.args.currentTab === 'completed' ? 'executed_at' : 'collection_date';
  }

  get headerDateTitle() {
    // @ts-expect-error
    return this.args.currentTab === 'completed'
      ? this.intl.t('sdd-collections.table.headers.operation-date')
      : this.intl.t('sdd-collections.table.headers.due-date');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'DirectDebitCollections::Table': typeof DirectDebitCollectionsTableComponent;
  }
}
