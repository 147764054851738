/* import __COLOCATED_TEMPLATE__ from './link.hbs'; */
import { assert } from '@ember/debug';
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { Badge } from '@repo/design-system-kit';
import window from 'ember-window-mock';

interface DualNavSubMenuLinkSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLLIElement;
}

export default class DualNavSubMenuLinkComponent extends Component<DualNavSubMenuLinkSignature> {
  badge = Badge;

  isHoverSupported = window.matchMedia?.('(any-hover: hover)').matches;

  @service declare segment: Services['segment'];

  constructor(owner: unknown, args: DualNavSubMenuLinkSignature['Args']) {
    super(owner, args);
    // @ts-expect-error
    let { label, link } = this.args;

    assert('<DualNav::SubMenu::Link /> must have a @label', label);
    assert('<DualNav::SubMenu::Link /> must have a @link', link);
  }

  get shouldShowQuickAction() {
    // @ts-expect-error
    return this.isHoverSupported && Boolean(this.args.quickAction);
  }

  @action
  // @ts-expect-error
  handleClick(event) {
    /**
     * link: @type {ember UILink}
     */
    // @ts-expect-error
    let { link, onClick } = this.args;

    link.transitionTo?.(event);
    onClick?.();
  }

  @action
  // @ts-expect-error
  handleQuickActionTracking(eventName) {
    this.segment.track(eventName);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'DualNav::SubMenu::Link': typeof DualNavSubMenuLinkComponent;
  }
}
