/* import __COLOCATED_TEMPLATE__ from './information.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { parseDate } from '@internationalized/date';
import { DATE_FORMAT_TOKENS, dateToken } from '@qonto/ui-kit/utils/date-token';
import { Checkbox, DatePicker, TextAreaField } from '@repo/design-system-kit';
import { reads } from 'macro-decorators';

interface TransferMultiInformationSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class TransferMultiInformation extends Component<TransferMultiInformationSignature> {
  checkbox = Checkbox;
  datePicker = DatePicker;
  textAreaField = TextAreaField;

  @service declare organizationManager: Services['organizationManager'];
  @service declare intl: Services['intl'];

  // @ts-expect-error
  @reads('organizationManager.organization') organization;
  // @ts-expect-error
  @reads('organization.hasMultipleActiveCurrentRemuneratedAccounts') showAccountSelect;
  // @ts-expect-error
  @reads('organization.activeSortedRemuneratedAndCurrentAccounts') activeAccounts;

  get scheduledDate() {
    // @ts-expect-error
    let { multiTransfer } = this.args;

    if (typeof multiTransfer?.scheduledDate === 'string') {
      return parseDate(multiTransfer.scheduledDate);
    }

    if (typeof multiTransfer?.scheduledDate === 'object') {
      return multiTransfer.scheduledDate;
    }

    return null;
  }

  get minValue() {
    // @ts-expect-error
    let { multiTransfer } = this.args;
    return multiTransfer.minDate ? parseDate(multiTransfer.minDate) : null;
  }

  customValidationMessages = {
    // @ts-expect-error
    valueMissing: this.intl.t('validations.errors.presence'),
    // @ts-expect-error
    rangeUnderflow: this.intl.t('transfers.multi.datepicker.error.minimum-date', {
      date: dateToken({
        // @ts-expect-error
        date: new Date(),
        // @ts-expect-error
        locale: this.intl.primaryLocale,
        token: DATE_FORMAT_TOKENS.COMPACT,
      }),
    }),
  };

  @action
  // @ts-expect-error
  onChange(date) {
    // @ts-expect-error
    this.args.updateScheduleSelection(date);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'TransferMulti::Information': typeof TransferMultiInformation;
  }
}
