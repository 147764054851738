/* import __COLOCATED_TEMPLATE__ from './form.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';

interface SubscriptionPromocodeFormSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLFormElement;
}

export default class SubscriptionPromocodeFormComponent extends Component<SubscriptionPromocodeFormSignature> {
  @service declare intl: Services['intl'];
  @service declare store: Services['store'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare segment: Services['segment'];
  @service declare sentry: Services['sentry'];

  @tracked promoCode = null;
  @tracked errorMessage = null;

  @action
  // @ts-expect-error
  updatePromoCode(val) {
    this.promoCode = val;
    this.errorMessage = null;
  }

  saveDiscountTask = dropTask(async () => {
    // @ts-expect-error
    if (!this.promoCode?.trim()) {
      // @ts-expect-error
      this.errorMessage = this.intl.t('subscription.promo-code.form.empty-error');
      return;
    }

    // @ts-expect-error
    let { organization } = this.args;
    let discount = this.store.createRecord('discount', {
      organization,
      voucherCode: this.promoCode,
    });

    let outcome;
    try {
      await discount.save();

      let message = this.intl.t('subscription.promo-code.form.success-toast');
      this.toastFlashMessages.toastSuccess(message);

      this.promoCode = null;
      this.errorMessage = null;
      outcome = 'success';
    } catch (error) {
      // @ts-expect-error
      let errors = discount.errors.get('voucherCode') || [];
      discount.unloadRecord();

      // @ts-expect-error
      if (errors.some(({ message: m }) => m === 'invalid')) {
        // @ts-expect-error
        this.errorMessage = this.intl.t('subscription.promo-code.form.invalid-error');
        outcome = 'invalid';
        // @ts-expect-error
      } else if (errors.some(({ message: m }) => m === 'already_used')) {
        // @ts-expect-error
        this.errorMessage = this.intl.t('subscription.promo-code.form.used-error');
        outcome = 'already_used';
        // @ts-expect-error
      } else if (errors.some(({ message: m }) => m === 'reached_limit')) {
        // @ts-expect-error
        this.errorMessage = this.intl.t('subscription.promo-code.form.reached-limit-error');
        outcome = 'reached_limit';
        // @ts-expect-error
      } else if (error.errors?.discounts === 'rate_limit_reached') {
        // @ts-expect-error
        this.errorMessage = this.intl.t('subscription.promo-code.form.rate-limit-reached-error');
        outcome = 'rate_limit_reached';
      } else {
        outcome = 'backend_error';
        this.sentry.captureException(error);
        this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
      }
    } finally {
      // @ts-expect-error
      this.args.onPromoCodeSave();
      if (outcome) {
        this.segment.track('subscription_promo_clicked', { outcome });
      }
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Subscription::PromoCode::Form': typeof SubscriptionPromocodeFormComponent;
  }
}
