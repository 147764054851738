export default {
  "envelope": "zT",
  "email": "zz",
  "logo": "za",
  "subject": "zH",
  "message": "zO",
  "payment-details": "zm",
  "contact": "zp",
  "detail": "zx",
  "cta": "zw"
};
