import Model, { attr } from '@ember-data/model';

/**
 * @typedef {Object} Amount
 * @property {String} value eg. "EUR"
 * @property {String} currency eg. "15.0"
 *
 * @typedef {Object} PaymentInitiator
 * @property {String} resourceId id of the resource initiating a payment link
 * @property {String} resourceNumber eg. "INV-101"
 *
 * @typedef {'apple_pay' | 'credit_card' | 'bank_transfer' | 'paypal'} PaymentMethod
 */

export default class PaymentLinkModel extends Model {
  /** @type {Amount} */
  // @ts-expect-error
  @attr amount;
  /** @type {PaymentMethod} */
  // @ts-expect-error
  @attr paymentMethod;
  /** @type {string} */
  // @ts-expect-error
  @attr expirationDate;
  /** @type {string} */
  // @ts-expect-error
  @attr debitorName;
  /** @type {PaymentInitiator} */
  // @ts-expect-error
  @attr('hash') paymentInitiator;
  /** @type {'open' | 'paid' | 'cancelled' | 'expired'} */
  // @ts-expect-error
  @attr status;
  /** @type {Array<PaymentMethod>} */
  // @ts-expect-error
  @attr potentialPaymentMethods;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'payment-link': PaymentLinkModel;
  }
}
