/**
 * A map representing a configuration used to introduce support to new currencies, where:
 *
 * - each key is a parameter to be sent via the `enabled_features` query parameter
 * - each value is the currency code of the new currency to support
 *
 * When a new currency is introduced:
 *
 * - a new entry should be added to this map
 *   - the key should follow the pattern `<currency_code>_target`, where `<currency_code>` is the lowercase ISO 4217 currency code
 *   - the value should be the uppercase ISO 4217 currency code of the new currency
 * - the corresponding currency object should be added to the currencies list fixture
 *
 * @remarks
 *
 * It is designed to allow backwards compatibility with old mobile applications, and to remain in place to accommodate future additions of new currencies.
 * Therefore, it is normal for this configuration to be empty at times.
 * Maintaining this structure ensures that we can easily add new currency support as needed without re-implementing the configuration setup.
 */
export const CURRENCY_FEATURES = {
  dkk_target: 'DKK',
  nok_target: 'NOK',
  pln_target: 'PLN',
  ron_target: 'RON',
  sek_target: 'SEK',
};
