export default {
  "wrapper": "cdI input-field mb-4",
  "error": "cdt",
  "inactive": "cdM",
  "hidden-value": "cdP",
  "percentage-sign": "cdl",
  "black": "cdX",
  "input-field": "cdC",
  "disabled": "cdk"
};
