export default {
  "mandate-list-item": "ckh",
  "selected": "ckf",
  "status-avatar": "ckK",
  "infos": "ckG",
  "info-title": "ckr body-1",
  "info-title-suspended": "ckb",
  "info-title-status-suspended": "cky",
  "info-mandate-count": "ckU body-2"
};
