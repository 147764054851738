/* eslint-disable @qonto/no-import-roles-constants */
import { NotFoundError } from '@ember-data/adapter/error';
import { action } from '@ember/object';
import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { hasMFAError } from '@qonto/qonto-sca/utils/mfa-error';
import dayjs from 'dayjs';
import { all, dropTask, restartableTask, timeout, waitForProperty } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';
import { reads } from 'macro-decorators';

import { ROLES } from 'qonto/constants/membership';
import { SUPPLIER_STATUSES } from 'qonto/constants/supplier';
import {
  ANIMATION_IN_DURATION_MS,
  GENERIC_IBAN,
  INVOICE_STATUSES,
  WAIT_FOR_OCR_SCAN,
} from 'qonto/constants/supplier-invoice';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';
import parseConfirmResponse from 'qonto/utils/parse-confirm-response';
import { getQuickTransferApproveSuccessMessage } from 'qonto/utils/transfer-requests';

export default class SupplierInvoicesShowRoute extends Route {
  @service abilities;
  @service errors;
  @service intl;
  @service internationalOutManager;
  @service modals;
  @service organizationManager;
  @service router;
  @service segment;
  @service sentry;
  @service store;
  @service supplierInvoicesManager;
  @service toastFlashMessages;

  @reads('organizationManager.organization') organization;
  @reads('organizationManager.membership') membership;

  queryParams = {
    origin: { refreshModel: true },
  };

  get isReporting() {
    return this.membership.role === ROLES.REPORTING;
  }

  async model(params, { from }) {
    this.fetchSupplierInvoiceDetailsTask
      .perform(params.invoice_id)
      .then(result => {
        if (result) {
          let { request, invoice } = result;
          this.confirmTransferTask.perform(request, invoice).catch(ignoreCancelation);
          if (
            [INVOICE_STATUSES.toReview].includes(invoice?.status) &&
            variation('feature--boolean-approval-workflow-for-supplier-invoices')
          ) {
            this.fetchApprovalWorkflowEstimateTask.perform(invoice).catch(ignoreCancelation);
          }
        }
      })
      .catch(ignoreCancelation);

    if (this.abilities.can('read self-invoice')) {
      this.fetchSupplierInvoiceSelfInvoicesTask
        .perform(params.invoice_id)
        .catch(ignoreCancelation)
        .catch(error => {
          this.toastFlashMessages.toastError(
            this.intl.t('supplier-invoices.modals.errors.self-invoice-loading-failed')
          );

          if (ErrorInfo.for(error).shouldSendToSentry) {
            return this.sentry.captureException(error);
          }
        });
    }

    await this.supplierInvoicesManager.fetchCurrencyCodesTask.perform().catch(ignoreCancelation);

    this.waitForOcrScanTask.perform().catch(ignoreCancelation);

    if (this.abilities.can('update supplier-invoice') && this.abilities.can('view supplier')) {
      this.fetchSuppliersTask.perform().catch(ignoreCancelation);
    }

    // The next code is needed in order to display the self-billing.show
    // modal over the supplier-invoice.show modal without trigger
    // the closing animation.

    // Initialize the modal each time the user gets to the supplier-invoice.show
    // except when it comes from the self-billing.show modal.
    if (from?.name !== 'self-billing.show') {
      this.modal = null;
    }

    // Do not re-fetch the data if the user comes back from the self-billing.show modal
    if (!this.modal) {
      this.modal = this.modals.open(
        'supplier-invoices/details-modal',
        {
          detailsTask: this.fetchSupplierInvoiceDetailsTask,
          fetchSelfInvoicesTask: this.fetchSupplierInvoiceSelfInvoicesTask,
          confirmTransferTask: this.confirmTransferTask,
          waitForOcrScanTask: this.waitForOcrScanTask,
          approveRequestTask: this.approveRequestTask,
          declineRequestTask: this.declineRequestTask,
          fetchSuppliersTask: this.fetchSuppliersTask,
          fetchApprovalWorkflowEstimateTask: this.fetchApprovalWorkflowEstimateTask,
          isEligibleToInternationalTransfers: await this.internationalOutManager.isEligible(),
          supportedCurrencies:
            this.supplierInvoicesManager.fetchCurrencyCodesTask.lastSuccessful?.value,
          onClose: this.handleClose,
          isFullScreenModal: true,
        },
        {
          focusTrapOptions: {
            clickOutsideDeactivates: false,
            // Timeline dropdown LinkTo click is detected as outside click, so allowOutsideClick is needed to enable the navigation
            allowOutsideClick: true,
          },
        }
      );
    }
  }

  deactivate(transition) {
    // Keep the underneath modal open if the user goes to the
    // self-billing.show modal.
    if (transition?.to.name !== 'self-billing.show') {
      this.modal?.close();
    }
  }

  fetchApprovalWorkflowEstimateTask = dropTask(async invoice => {
    try {
      // @ts-expect-error
      let response =
        await this.supplierInvoicesManager.fetchApprovalWorkflowEstimateTask.perform(invoice);
      return response?.approvalWorkflowState;
    } catch (error) {
      this.errors.handleError(error);
      this.sentry.captureException(error);
    }
  });

  fetchSupplierInvoiceDetailsTask = restartableTask(async invoiceId => {
    try {
      let invoice = await this.store.findRecord('supplier-invoice', invoiceId);
      let attachmentId = invoice.belongsTo('attachment').id();
      let displayAttachment = null;
      if (invoice?.isGermanEInvoice) {
        displayAttachment = await invoice.belongsTo('displayAttachment').load();
      }

      let initiatorId = invoice.belongsTo('initiator').id();

      let requestId = invoice.requestTransfer?.id;
      let canLoadRequest =
        requestId &&
        this.abilities.can('load transfer request') &&
        !(this.isReporting && invoice.requestTransfer.initiatorId !== this.membership.id);

      let [attachment, initiator, request] = await all([
        ...(attachmentId ? [this.store.findRecord('attachment', attachmentId)] : []),
        ...(initiatorId ? [this.store.findRecord('membership', initiatorId)] : []),
        ...(canLoadRequest ? [this.store.findRecord('request-transfer', requestId)] : []),
        this.abilities.can('see state approval-workflow') &&
          variation('feature--boolean-approval-workflow-for-supplier-invoices') &&
          [INVOICE_STATUSES.toApprove, INVOICE_STATUSES.awaitingPayment].includes(invoice.status) &&
          invoice.loadApprovalWorkflowState(),
      ]);

      await this.organizationManager.membership.getSpendLimits();
      return { invoice, attachment, displayAttachment, initiator, request };
    } catch (error) {
      this.errors.handleError(error);
      if (error instanceof NotFoundError) {
        this.router.replaceWith('supplier-invoices', this.organization.slug);
      } else {
        throw error;
      }
    }
  });

  fetchSupplierInvoiceSelfInvoicesTask = dropTask(async supplierInvoiceId => {
    let invoices = await this.store.query('self-invoice', {
      filter: {
        'supplier_invoice.id': supplierInvoiceId,
      },
    });
    return invoices;
  });

  fetchSuppliersTask = dropTask(async search => {
    return await this.store.query('supplier', {
      search,
      filter: { status: SUPPLIER_STATUSES.UNARCHIVED },
    });
  });

  confirmTransferTask = restartableTask(async (request, invoice) => {
    if (!request || !invoice || this.abilities.cannot('review transfer request')) return;

    let { dueDate, invoiceNumber, supplierName, totalAmount } = invoice;

    let transfer = this.store.createRecord('transfer', {
      bankAccount: request.bankAccount,
      organization: this.organization,
      amountCurrency: totalAmount.currency,
      name: supplierName,
      iban: GENERIC_IBAN,
      amount: totalAmount.value,
      activityTag: 'other_expense',
      reference: invoiceNumber || 'reference',
      ...(dueDate ? { scheduledDate: dueDate } : {}),
    });

    try {
      let response = await transfer.confirm();
      return parseConfirmResponse(response, this.intl);
    } catch (error) {
      this.errors.handleError(error);
    }
  });

  waitForOcrScanTask = dropTask(async () => {
    await waitForProperty(this, 'fetchSupplierInvoiceDetailsTask.isIdle', true);
    await waitForProperty(this, 'fetchSupplierInvoiceSelfInvoicesTask.isIdle', true);
    await waitForProperty(this, 'fetchSuppliersTask.isIdle', true);

    let invoiceCreationDate =
      this.fetchSupplierInvoiceDetailsTask.lastSuccessful?.value?.invoice?.createdAt;

    if (!invoiceCreationDate) {
      return;
    }

    let diff = Math.max(dayjs().diff(invoiceCreationDate), 0) || 0;

    if (diff > WAIT_FOR_OCR_SCAN) {
      return;
    }

    await timeout(ANIMATION_IN_DURATION_MS);
  });

  approveRequestTask = dropTask(async (request, invoice) => {
    try {
      await request.approveRequest();
    } catch (error) {
      if (hasMFAError(error)) {
        throw error;
      }

      if (error.errors?.[0]?.detail?.code === 'request_not_pending') {
        this.toastFlashMessages.toastError(
          this.intl.t('supplier-invoices.preview.request.info-toast.same-invoice')
        );

        // Now that we know that request status is outdated, we refresh it for the request and the invoice
        // We don't wait for the refresh to allow Request::Validation component to finalize declineTask before it's destroyed
        this.reloadRequestTask.perform(request, invoice).catch(ignoreCancelation);

        return;
      } else {
        throw error;
      }
    }

    let { supplierInvoiceId } = this.router.currentRoute.queryParams;

    this.segment.track('supplier-invoices_request-approved_clicked');
    this.toastFlashMessages.toastSuccess(getQuickTransferApproveSuccessMessage(this.intl, request));

    if (!supplierInvoiceId) {
      this.router.transitionTo('supplier-invoices');
    }
  });

  declineRequestTask = dropTask(async (request, invoice) => {
    try {
      await request.declineRequest();
    } catch (error) {
      if (error.errors?.[0]?.detail?.code === 'request_not_pending') {
        this.toastFlashMessages.toastError(
          this.intl.t('supplier-invoices.preview.request.info-toast.same-invoice')
        );

        // Now that we know that request status is outdated, we refresh it for the request and the invoice
        // We don't wait for the refresh to allow Request::Validation component to finalize declineTask before it's destroyed
        this.reloadRequestTask.perform(request, invoice).catch(ignoreCancelation);

        return;
      } else {
        let isFlashed = this.errors.handleError(error);
        if (!isFlashed) {
          this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
        }

        return;
      }
    }

    let { supplierInvoiceId } = this.router.currentRoute.queryParams;

    this.segment.track('supplier-invoices_rejected_clicked');
    this.toastFlashMessages.toastInfo(this.intl.t('transfers.toasts.info.quick-reject-request'));

    if (!supplierInvoiceId) {
      this.router.transitionTo('supplier-invoices');
    }
  });

  reloadRequestTask = dropTask(async (request, invoice) => {
    try {
      await Promise.all([request.reload(), invoice.reload()]);
    } catch (error) {
      let isFlashed = this.errors.handleError(error);
      if (!isFlashed) {
        this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
      }
    }
  });

  @action
  handleClose() {
    let { supplierInvoiceId } = this.router.currentRoute.queryParams;

    if (!supplierInvoiceId) {
      this.router.transitionTo('supplier-invoices');
    }
  }
}
