export default {
  "title": "qjf title-3",
  "personal-profile-container": "qjK",
  "personal-profile-section": "qjG",
  "member-info-title": "qjr",
  "kyc-complete-profile": "qjb",
  "info-fields": "qjy",
  "field": "qjU",
  "address-fields": "qjj",
  "field-span": "qjW",
  "input": "qju",
  "cancel": "qWS"
};
