export default {
  "header": "cuy",
  "header-type": "cuU",
  "close": "cuj",
  "close-icon": "cuW",
  "header-infos": "cuu",
  "title": "qSS body-1",
  "declined": "qSc",
  "canceled": "qSq",
  "approved": "qSZ",
  "pending": "qSR",
  "date": "qSQ body-2",
  "mileage-icon": "qSe",
  "distance-subtitle": "qSB body-2",
  "link-icon": "qSE",
  "container": "qSd",
  "picto": "qSI",
  "general": "qSt",
  "amount": "qSM",
  "calculation-container": "qSP",
  "counterparty": "qSl body-2",
  "infobox": "qSX"
};
