import { useContext } from 'react';
import { OverlayTriggerStateContext } from 'react-aria-components';
import { useIntl } from 'react-intl';
import type { SortDirection } from '@tanstack/react-table';
import { IconArrowBottomOutlined, IconArrowTopOutlined } from '@repo/monochrome-icons';
import { Popover, PopoverButton, PopoverSection } from 'qonto/react/components/table-v2/popover';
import { SORT_DIRECTION } from 'qonto/react/constants';
import styles from './styles.strict-module.css';

interface HeaderCellPopoverProps {
  onSortBy?: (sortBy: string | undefined) => void;
  sortedColumn?: string;
  sortedDirection?: SortDirection;
  isCurrentColumnSorted?: boolean;
}

export function HeaderCellPopover({
  onSortBy,
  sortedColumn,
  sortedDirection,
  isCurrentColumnSorted,
  ...props
}: HeaderCellPopoverProps): JSX.Element {
  const { formatMessage } = useIntl();
  const triggerState = useContext(OverlayTriggerStateContext);

  const handleSort = (direction: SortDirection): void => {
    const newSort =
      isCurrentColumnSorted && sortedDirection === direction
        ? undefined // Reset sort if already sorted in the same direction
        : `${sortedColumn}:${direction}`;
    onSortBy?.(newSort);
    triggerState?.close();
  };

  const handleToggle = (): void => {
    triggerState?.toggle();
  };

  return (
    <Popover
      crossOffset={4}
      isOpen={triggerState?.isOpen}
      onOpenChange={handleToggle}
      placement="bottom start"
      width={274}
      {...props}
    >
      <PopoverSection className={styles['popover-section']}>
        <PopoverButton
          className={
            isCurrentColumnSorted && sortedDirection === SORT_DIRECTION.ASCENDING
              ? styles.selected
              : undefined
          }
          data-testid="sort-ascending"
          onPress={() => {
            handleSort(SORT_DIRECTION.ASCENDING);
          }}
          slots={{
            icon: <IconArrowTopOutlined />,
            text: formatMessage({
              id: 'transactions.modular_table.manage_column.label.sort_ascending',
            }),
          }}
        />
        <PopoverButton
          className={
            isCurrentColumnSorted && sortedDirection === SORT_DIRECTION.DESCENDING
              ? styles.selected
              : undefined
          }
          data-testid="sort-descending"
          onPress={() => {
            handleSort(SORT_DIRECTION.DESCENDING);
          }}
          slots={{
            icon: <IconArrowBottomOutlined />,
            text: formatMessage({
              id: 'transactions.modular_table.manage_column.label.sort_descending',
            }),
          }}
        />
      </PopoverSection>
    </Popover>
  );
}
