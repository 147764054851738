import type { ReactNode } from 'react';
import cx from 'clsx';
import { Tooltip, Button } from '@repo/design-system-kit';
import { useIntl } from 'react-intl';
import { ActionSlot } from 'qonto/react/components/table-v2/action-slot';
import { DragCellHandle, EyeHiddenOutlined, EyeOutlined } from 'qonto/react/assets/icons';
import type { ModularTableColumn } from 'qonto/react/constants';
import styles from './styles.strict-module.css';

interface ColumnManagerItemProps {
  column: ModularTableColumn;
  title: string;
  isVisible?: boolean;
  setIsVisible?: () => void;
  isDisabled?: boolean;
}

export function ColumnManagerItem({
  column,
  title,
  isVisible,
  isDisabled,
  setIsVisible,
}: ColumnManagerItemProps): ReactNode {
  const { formatMessage } = useIntl();

  return (
    <div
      aria-disabled={isDisabled}
      className={cx(styles.item, 'body-2', isDisabled ? styles['item-disabled'] : 'overlay')}
      data-testid={`column-manager-item-${column.id}`}
    >
      <DragCellHandle className={isDisabled ? styles['icon-disabled'] : styles.icon} />
      {title}
      {!isDisabled ? (
        <ActionSlot className={styles.action} size="28">
          <Tooltip
            closeDelay={0}
            delay={400}
            label={formatMessage({
              id: isVisible
                ? 'transactions.modular_table.manage_column.dialog.tooltip.hide_column'
                : 'transactions.modular_table.manage_column.dialog.tooltip.show_column',
            })}
            tooltipTestId="action-button-tooltip"
          >
            <Button
              className={styles.button}
              data-testid="action-button"
              iconOnly
              onPress={setIsVisible}
              type="button"
              variant="tertiary"
            >
              {isVisible ? (
                <EyeHiddenOutlined data-testid="eye-hidden-icon" />
              ) : (
                <EyeOutlined data-testid="eye-icon" />
              )}
            </Button>
          </Tooltip>
        </ActionSlot>
      ) : null}
    </div>
  );
}
