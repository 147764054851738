import { type ReactNode } from 'react';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { DataWithIconCell } from 'qonto/react/components/table-v2/cells/data-with-icon-cell';
import { BaseCell } from 'qonto/react/components/table-v2/cells/base-cell';
import { CATEGORY_CELL_CLICK_EVENT_NAME } from 'qonto/react/constants';
import { useCategoryAttrs } from './category-map';
import { CategoryCellPopover } from './popover';

interface CategoryCellProps {
  category?: string | null;
  transactionId: string;
}

export function CategoryCell({ category, transactionId }: CategoryCellProps): ReactNode {
  const segment = useEmberService('segment');

  const { title, icon } = useCategoryAttrs(category);

  const trackCellOpen = (isOpen: boolean): void => {
    if (isOpen) {
      segment.track(CATEGORY_CELL_CLICK_EVENT_NAME);
    }
  };

  return (
    <BaseCell
      data-testid="category-cell"
      onOpenChange={trackCellOpen}
      popoverSlot={<CategoryCellPopover category={category} transactionId={transactionId} />}
    >
      <DataWithIconCell icon={icon} title={title} />
    </BaseCell>
  );
}
