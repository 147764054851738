/* import __COLOCATED_TEMPLATE__ from './beneficiary-card.hbs'; */
import Component from '@glimmer/component';

import { Info } from 'qonto/react/components/transfers/sepa/beneficiary-card/info';

interface TransfersSepaBeneficiaryCardSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLElement;
}

export default class TransfersSepaBeneficiaryCardComponent extends Component<TransfersSepaBeneficiaryCardSignature> {
  info = Info;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transfers::Sepa::BeneficiaryCard': typeof TransfersSepaBeneficiaryCardComponent;
  }
}
