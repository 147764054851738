export default {
  "wrapper": "cYH",
  "tabs": "cYO",
  "tabs-nav": "cYm",
  "tabs-panel": "cYp",
  "tabs-panel-wrapper": "cYx",
  "list-head": "cYw",
  "list": "cYN",
  "footer": "cYv",
  "reward-amount": "cYo",
  "rewarded": "cYn",
  "registered": "cYi",
  "disclaimer": "cYA",
  "empty-state-wrapper": "cYY",
  "empty-state": "cYh",
  "illustration": "cYf",
  "spinner": "cYK",
  "total-earned": "cYG"
};
