export default {
  "row": "Vf body-1",
  "active": "VK",
  "cell": "VG",
  "empty": "Vr",
  "connection": "Vb",
  "connection-avatar": "Vy",
  "connection-name": "VU",
  "member-name": "Vj body-2",
  "member-team": "VW caption",
  "status": "Vu",
  "failure": "LS",
  "error": "Lc",
  "hidden": "Lq"
};
