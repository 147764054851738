export default {
  "header": "sO",
  "result": "sm",
  "result-value": "sp",
  "result-value-key": "sx",
  "result-value-icon": "sw",
  "green": "sN",
  "red": "sv",
  "result-value-description": "so",
  "result-title": "sn",
  "chevron": "si",
  "detail": "sA",
  "detail-title": "sY",
  "detail-value": "sh",
  "tooltip-wrapper": "sf",
  "tooltip": "sK",
  "disclaimer": "sG"
};
