/* import __COLOCATED_TEMPLATE__ from './claim.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import dayjs from 'dayjs';
import { dropTask } from 'ember-concurrency';
// @ts-expect-error
import { variation } from 'ember-launch-darkly';

import {
  CLAIM_OUTDATED_TRESHOLD_ITALIAN_FRAUD_ATM,
  CLAIM_TYPE_OUTDATED_THRESHOLDS,
  CLAIM_TYPES,
  // @ts-expect-error
} from 'qonto/constants/transactions';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

interface HelpCenterSelectedItemClaimSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class HelpCenterSelectedItemClaimComponent extends Component<HelpCenterSelectedItemClaimSignature> {
  @service declare flowLinkManager: Services['flowLinkManager'];
  @service declare intl: Services['intl'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare router: Services['router'];
  @service declare segment: Services['segment'];

  get isAtmClaim() {
    // @ts-expect-error
    return this.args.selectedClaimType === CLAIM_TYPES.atm;
  }

  get isCommercialClaim() {
    // @ts-expect-error
    return this.args.selectedClaimType === CLAIM_TYPES.commercial;
  }

  get isFraudClaim() {
    // @ts-expect-error
    return this.args.selectedClaimType === CLAIM_TYPES.fraud;
  }

  get isPending() {
    // @ts-expect-error
    let transaction = this.args.highlightedItem;

    return transaction?.pending;
  }

  get isFailing() {
    // @ts-expect-error
    let transaction = this.args.highlightedItem;
    return transaction?.canceled || transaction?.declined || transaction?.reversed;
  }

  get isItalianOrga() {
    return this.organizationManager.organization.isItalian;
  }

  get errorText() {
    // @ts-expect-error
    let transaction = this.args.highlightedItem;

    if (transaction?.canceled) {
      return this.intl.t(
        'transactions.sidebar.helper.dispute.errors.fail-statuses.description.status.canceled'
      );
    } else if (transaction?.declined) {
      return this.intl.t(
        'transactions.sidebar.helper.dispute.errors.fail-statuses.description.status.declined'
      );
    } else if (transaction?.reversed) {
      return this.intl.t(
        'transactions.sidebar.helper.dispute.errors.fail-statuses.description.status.reverted'
      );
    }
  }

  get isItalianExtendedClaim() {
    // @ts-expect-error
    let { selectedClaimType } = this.args;
    return (
      this.isItalianOrga &&
      (selectedClaimType === CLAIM_TYPES.fraud || selectedClaimType === CLAIM_TYPES.atm)
    );
  }

  get isOutdated() {
    // @ts-expect-error
    let { highlightedItem, selectedClaimType } = this.args;
    let transaction = highlightedItem;
    let settledDay = dayjs(transaction?.settledAt);

    // A special case for Italian fraud/atm claims
    if (this.isItalianExtendedClaim) {
      return dayjs().diff(settledDay, 'day') > CLAIM_OUTDATED_TRESHOLD_ITALIAN_FRAUD_ATM;
    }

    let threshold = CLAIM_TYPE_OUTDATED_THRESHOLDS[selectedClaimType];
    return dayjs().diff(settledDay, 'day') > threshold;
  }

  get isPendingClaim() {
    // @ts-expect-error
    let transaction = this.args.highlightedItem;

    let hasPendingClaims = transaction?.chargebackDisputingTypes?.length > 0;

    return hasPendingClaims;
  }

  get isDisputedClaim() {
    // @ts-expect-error
    let transaction = this.args.highlightedItem;

    let isDisputedClaim = transaction?.chargebackDisputedTypes?.includes(
      // @ts-expect-error
      this.args.selectedClaimType
    );

    return isDisputedClaim;
  }

  get shouldShowProcessingScreen(): boolean {
    if (!variation('feature--boolean-boi-chargeback-claim-flow')) return true;

    return !this.isFraudClaim;
  }

  get validityPeriodText() {
    // @ts-expect-error
    let { selectedClaimType } = this.args;
    // A special case for Italian fraud/atm claims
    if (this.isItalianExtendedClaim) {
      return this.intl.t(
        'transactions.sidebar.helper.dispute.errors.too-old.description.italian-validity-days'
      );
    }
    let threshold = CLAIM_TYPE_OUTDATED_THRESHOLDS[selectedClaimType];
    switch (threshold) {
      case 90:
        return this.intl.t(
          'transactions.sidebar.helper.dispute.errors.too-old.description.validity-days'
        );
      case 56:
        return this.intl.t(
          'transactions.sidebar.helper.dispute.errors.too-old.description.validity-weeks'
        );
    }
  }

  _disputeStartTask = dropTask(async () => {
    this.segment.track('cards-chargeback_start-dispute-cta_clicked', {
      // @ts-expect-error
      type: this.args.selectedClaimType,
    });

    // @ts-expect-error
    let { highlightedItem, onClose, selectedClaimType } = this.args;
    let card = await highlightedItem.subject;

    let firstStepId = 'transactions-selection';
    if (this.isFraudClaim) {
      if (card?.isActive) {
        firstStepId = 'block-card';
      } else if (card?.isPhysical) {
        firstStepId = 'card-possession';
      }
    }

    this.flowLinkManager.transitionTo({
      name: 'chargeback-claim',
      stepId: firstStepId,
      queryParams: {
        ...this.router.currentRoute?.queryParams,
        transactionId: highlightedItem.id,
        claimType: selectedClaimType,
      },
    });

    onClose?.();
  });

  @action
  onDisputeStart() {
    this._disputeStartTask.perform().catch(ignoreCancelation);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'HelpCenter::SelectedItem::Claim': typeof HelpCenterSelectedItemClaimComponent;
  }
}
