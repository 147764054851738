export default {
  "container": "bq",
  "centered": "bZ",
  "content": "bR",
  "spinner": "bQ",
  "holder-selector-wrapper": "be mb-16",
  "holder-selector-error": "bB",
  "info-text": "bE",
  "error-text": "bd",
  "lottie-illustration": "bI",
  "info-text-1": "bt",
  "info-text-2": "bM"
};
