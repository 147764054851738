export default {
  "sidebar": "cob",
  "body": "coy",
  "form": "coU",
  "form-section": "coj",
  "form-title": "coW title2",
  "row": "cou",
  "payment-details": "cnS",
  "col": "cnc",
  "footer": "cnq",
  "footer-button": "cnZ"
};
