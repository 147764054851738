import { attr, belongsTo, hasMany } from '@ember-data/model';

// @ts-expect-error
import { STATUS } from 'qonto/constants/requests';
import Request from 'qonto/models/request';

export default class RequestMultiDirectDebitCollectionModel extends Request {
  @attr('string') declare note: string;
  @attr('string') declare status: string;
  @attr('string') declare operationType: string;
  @attr('string', { defaultValue: 'multi_direct_debit_collection' }) declare requestType: string;
  // @ts-expect-error
  @attr totalAmount;
  @attr('number') declare totalCount: number;
  @attr('date') declare createdAt: Date;
  @attr('date') declare processedAt: Date;

  // @ts-expect-error
  @belongsTo('membership', { async: false, inverse: null }) initiator;
  // @ts-expect-error
  @belongsTo('membership', { async: false, inverse: null }) approver;
  // @ts-expect-error
  @belongsTo('bankAccount', { async: false, inverse: null }) bankAccount;

  @hasMany('request-direct-debit-collection', {
    async: false,
    inverse: 'requestMultiDirectDebitCollection',
  })
  // @ts-expect-error
  requestDirectDebitCollections;

  get isPending() {
    return this.status === STATUS.PENDING;
  }

  get isApproved() {
    return this.status === STATUS.APPROVED;
  }

  get isRejected() {
    return this.status === STATUS.DECLINED;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'request-multi-direct-debit-collection': RequestMultiDirectDebitCollectionModel;
  }
}
