export default {
  "container": "qrl",
  "form-container": "qrX",
  "preview-container": "qrC",
  "pdf-preview-container": "qrk",
  "content": "qrJ",
  "title": "qrs",
  "tabs-container": "qrF",
  "close-button": "qrg btn btn--icon-only btn--tertiary btn--large",
  "pdf-preview": "qrD",
  "header": "qrV",
  "without-tabs": "qrL"
};
