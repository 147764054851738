export default {
  "filters-checkbox": "qFT mb-8",
  "csv-options-button": "qFz",
  "active": "qFa",
  "options-format-settings-wrapper": "qFH",
  "checkbox": "qFO",
  "options-format-settings": "qFm",
  "hidden": "qFp",
  "visible": "qFx",
  "disabled": "qFw",
  "buttons": "qFN"
};
