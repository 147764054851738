export default {
  "wrapper": "KE",
  "wrapper-new": "Kd",
  "icon-list": "KI",
  "list-item": "Kt",
  "total": "KM",
  "box-wrapper": "KP",
  "panel": "Kl",
  "panel-top": "KX",
  "list": "KC",
  "container": "Kk",
  "column": "KJ",
  "icon": "Ks",
  "column-right": "KF"
};
