export default {
  "fileupload": "qmb",
  "file": "qmy",
  "label": "qmU",
  "zone": "qmj",
  "error": "qmW",
  "hide": "qmu",
  "dropzone": "qpS",
  "hidden": "qpc",
  "upload-icon": "qpq",
  "dropping": "qpZ",
  "bounce2": "qpR",
  "error-message": "qpQ"
};
