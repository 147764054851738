export default {
  "gap-32": "cNS",
  "fieldset": "cNc",
  "date-picker": "cNq",
  "with-performance-date": "cNZ",
  "mt-4": "cNR",
  "clients": "cNQ",
  "dropdown-option": "cNe",
  "dropdown-content": "cNB",
  "select-client": "cNE",
  "client-option": "cNd",
  "icon-tooltip": "cNI",
  "payment-details": "cNt",
  "dates-container": "cNM",
  "automatic-number-label": "cNP",
  "label": "cNl",
  "numbering-container": "cNX",
  "fix-width": "cNC",
  "customer-error": "cNk",
  "amount-text": "cNJ",
  "header-text-field-container": "cNs",
  "header-text-field": "cNF",
  "header-text-field-close-button": "cNg",
  "mt-16": "cND"
};
