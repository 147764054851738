export default {
  "bank-accounts": "ch l-app-content__wrapper",
  "header-wrapper": "cf",
  "scrolled-top": "cK",
  "header": "cG l-app-content__page-header",
  "statements-button": "cr btn",
  "add-account-button": "cb btn btn--primary",
  "make-transfer-button": "cy btn btn--primary",
  "main": "cU"
};
