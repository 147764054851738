import { PAY_LATER_SIGNATURE_STATUS } from 'qonto/constants/financing';

const payLaterApplicationSteps = {
  'signature-warning': {
    componentName: 'transfers/pay-later-application/signature-warning',
    nextStepId: 'signature',
  },
  signature: {
    componentName: 'financing/pay-later/application/signature',
    nextStepId: ({ signature }) =>
      signature?.status === PAY_LATER_SIGNATURE_STATUS.SUCCESS ? 'summary' : 'signature-failure',
  },
  'signature-failure': {
    componentName: 'transfers/pay-later-application/signature-failure',
  },
};

export const sepaTransfer = {
  steps: {
    'invoice-upload': {
      componentName: 'transfers/sepa/new/invoice-upload',
      nextStepId: ({ invoice }) => (invoice ? 'invoice' : 'beneficiaries'),
    },
    beneficiaries: {
      componentName: 'transfers/sepa/new/beneficiaries',
      nextStepId: 'details',
    },
    'add-tax-beneficiary': {
      componentName: 'transfers/sepa/new/add-tax-beneficiary',
      nextStepId: 'details',
    },
    details: {
      componentName: 'transfers/sepa/new/details',
      nextStepId: 'additional-settings',
    },
    invoice: {
      componentName: 'transfers/sepa/new/invoice',
      nextStepId: 'additional-settings',
    },
    'additional-settings': {
      componentName: 'transfers/sepa/new/additional-settings',
      nextStepId: 'summary',
    },
    summary: {
      componentName: 'transfers/sepa/new/summary',
      nextStepId: ({
        kycSubmitted,
        sepaTransfer,
        isPayLaterEligible,
        isPayLaterContractSigned,
      }) => {
        if (!kycSubmitted) {
          return 'kyc-intro';
        }
        if (sepaTransfer.isPayLater && isPayLaterEligible && !isPayLaterContractSigned) {
          return 'signature-warning';
        }
        return 'settlement';
      },
    },
    ...payLaterApplicationSteps,
    'kyc-intro': {
      componentName: 'transfers/kyc/transition',
    },
    settlement: {
      componentName: 'transfers/sepa/new/settlement',
    },
  },
  options: {
    enablePersistence: false,
  },
};
