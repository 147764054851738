/* eslint-disable ember/no-controller-access-in-routes */
import { action } from '@ember/object';
import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { dropTask, restartableTask } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';

import { SUPPLIER_INVOICE_EVENTS } from 'qonto/constants/listeners';
import {
  FIRST_EINVOICE_TOOLTIP_DISMISSED_LOCAL_STORAGE_KEY,
  FIRST_GERMAN_EINVOICE_TOOLTIP_DISMISSAL_KEY,
  INVOICE_SOURCES,
  INVOICE_STATUSES,
  SORT_TABS_MAP,
  STATUS_TABS_MAP,
  TABS,
} from 'qonto/constants/supplier-invoice';
import { STATUS } from 'qonto/constants/transfers';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class SupplierInvoicesIndexRoute extends Route {
  @service abilities;
  @service errors;
  @service financing;
  @service intl;
  @service internationalOutManager;
  @service notifierManager;
  @service organizationManager;
  @service router;
  @service segment;
  @service sentry;
  @service store;
  @service supplierInvoicesInsights;
  @service supplierInvoicesUploadManager;
  @service supplierInvoicesManager;
  @service toastFlashMessages;

  queryParams = {
    status: { refreshModel: true },
    page: { refreshModel: true },
    perPage: { refreshModel: true },
    sortBy: { refreshModel: true },
    dueDate: { refreshModel: true },
    supplierId: { refreshModel: true },
    query: { refreshModel: true },
    tab: { refreshModel: true },
    matchedTransactions: { refreshModel: true },
    approverId: { refreshModel: true },
    documentType: { refreshModel: true },
    hasMissingData: { refreshModel: true },
    paymentDate: { refreshModel: true },
  };

  activate() {
    this.notifierManager.on(SUPPLIER_INVOICE_EVENTS.REQUEST_CREATED, this, 'fetchAll');
    this.notifierManager.on(SUPPLIER_INVOICE_EVENTS.REQUEST_CANCELED, this, 'fetchAll');
    this.notifierManager.on(SUPPLIER_INVOICE_EVENTS.SCHEDULED, this, 'fetchAll');
    this.notifierManager.on(SUPPLIER_INVOICE_EVENTS.REQUEST_DECLINED, this, 'fetchAll');
    this.router.on('routeWillChange', this.storeTransition);

    this.loadSupplierInvoicesInsights();
  }

  deactivate() {
    this.notifierManager.off(SUPPLIER_INVOICE_EVENTS.REQUEST_CREATED, this, 'fetchAll');
    this.notifierManager.off(SUPPLIER_INVOICE_EVENTS.REQUEST_CANCELED, this, 'fetchAll');
    this.notifierManager.off(SUPPLIER_INVOICE_EVENTS.SCHEDULED, this, 'fetchAll');
    this.notifierManager.off(SUPPLIER_INVOICE_EVENTS.REQUEST_DECLINED, this, 'fetchAll');
    this.router.off('routeWillChange', this.storeTransition);
  }

  beforeModel() {
    this.segment.track('supplier-invoices');
  }

  async model(params) {
    this.params = params;
    this.handleTransferFlow(params);

    if (this.abilities.can('access supplierInvoice')) {
      this.fetchSupplierInvoices();
    }

    if (this.abilities.can('view supplier')) {
      this.fetchSuppliersTask.perform(undefined, this.params.supplierId).catch(ignoreCancelation);
    }

    this.fetchHasNotCompletedEinvoiceTask
      .perform()
      .catch(ignoreCancelation)
      .catch(error => {
        this.errors.handleError(error);
      });

    if (this.abilities.can('access pay later in financing')) {
      this.fetchPayLaterEligibityTask
        .perform()
        .catch(ignoreCancelation)
        .catch(error => {
          if (ErrorInfo.for(error).shouldSendToSentry) {
            this.sentry.captureException(error);
          }
        });
    }

    this.fetchReceiptForwardEmailTask
      .perform()
      .catch(ignoreCancelation)
      .catch(error => {
        this.errors.handleError(error);
      });

    return {
      supplierInvoicesTask: this.fetchSupplierInvoicesTask,
      refreshModel: this.fetchAll,
      refreshInsights: this.loadSupplierInvoicesInsights,
      fetchSuppliersTask: this.fetchSuppliersTask,
      fetchHasNotCompletedEinvoiceTask: this.fetchHasNotCompletedEinvoiceTask,
      payLaterEligibilityTask: this.fetchPayLaterEligibityTask,
      isEligibleToInternationalTransfers: await this.internationalOutManager.isEligible(),
      fetchReceiptForwardEmailTask: this.fetchReceiptForwardEmailTask,
    };
  }

  setupController(controller, model) {
    super.setupController(controller, model);

    this.supplierInvoicesUploadManager.registerCallback({
      onUploadStarted: () => {
        if (this.controller.isAddInvoicesModalOpen) {
          this.controller.addInvoicesModal.close();
        }
      },
      onUploadFinished: () => {
        this.fetchAll();
      },
    });
  }

  async resetController(controller, isExiting) {
    if (!isExiting || this.router.transition.targetName === 'supplier-invoices.show') return;

    controller.status = undefined;
    controller.items = '';
    controller.dueDate = undefined;
    controller.tab = TABS.TO_PAY;
    controller.query = '';
    controller.matchedTransactions = undefined;
    controller.documentType = undefined;
    controller.hasMissingData = undefined;
    controller.paymentDate = undefined;
    controller.page = 1;
    controller.perPage = 25;
    controller.sortBy = undefined;
    controller.supplierId = undefined;
    controller.suppliersOptions = undefined;

    await Promise.all([
      this.fetchSupplierInvoicesTask.cancelAll({ resetState: true }),
      ...(this.abilities.can('access insights supplierInvoice')
        ? [this.supplierInvoicesInsights.fetchInsightsTask.cancelAll({ resetState: true })]
        : []),
    ]);

    if (!this.supplierInvoicesUploadManager.hasErrors) {
      this.supplierInvoicesUploadManager.resetState();
    }
  }

  loadSupplierInvoicesInsights() {
    if (this.abilities.can('access insights supplierInvoice')) {
      this.supplierInvoicesInsights
        .fetchInsights()
        .catch(error => this._handleInsightsError(error));
    }
  }

  fetchSuppliersTask = dropTask(async (search, supplierId) => {
    let suppliers = await this.store.query('supplier', { search });

    if (supplierId && !suppliers.some(({ id }) => id === supplierId)) {
      let selectedSupplier = await this.store.findRecord('supplier', supplierId);
      return [...suppliers, selectedSupplier];
    }

    return suppliers;
  });

  fetchSupplierInvoicesTask = restartableTask(async params => {
    let {
      page,
      perPage,
      sortBy,
      paymentDate,
      status,
      dueDate,
      supplierId,
      query,
      tab,
      matchedTransactions,
      approverId,
      documentType,
      hasMissingData,
    } = params;

    status =
      status?.split(',') ??
      STATUS_TABS_MAP(variation('feature--boolean-approval-workflow-for-supplier-invoices'))[tab];

    let invoices = await this.store.query('supplier-invoice', {
      per_page: perPage,
      page,
      query,
      query_fields: query ? 'supplier_name,invoice_number' : null,
      supplier_invoices_section: true,
      filter: {
        status,
        due_date: dueDate,
        payment_date: paymentDate,
        supplier_id: supplierId,
        matched_transactions: matchedTransactions ? false : null,
        document_type: documentType,
        missing_data: hasMissingData,
        ...(variation('feature--boolean-approval-workflow-for-supplier-invoices') && approverId
          ? { approver_id: [approverId] }
          : {}),
      },
      sort_by: this.defaultSupplierInvoicesSort(tab, sortBy),
    });

    await this.supplierInvoicesManager.loadMemberships(invoices);

    // fetching customPermissions used in controller for emptyStateRevampOptions
    let { membership } = this.organizationManager;
    if (membership.manager && !membership.customPermissions) {
      await membership.getRole();
    }

    return invoices;
  });

  defaultSupplierInvoicesSort(tab, sortBy) {
    if (sortBy) return sortBy;

    let defaultSorting = 'due_date:asc';

    return (
      SORT_TABS_MAP(variation('feature--boolean-approval-workflow-for-supplier-invoices'))[tab] ||
      defaultSorting
    );
  }

  async handleTransferFlow({ supplierInvoiceId, transferStatus }) {
    if (supplierInvoiceId && transferStatus) {
      try {
        let { status } = await this.store.findRecord('supplier-invoice', supplierInvoiceId);
        let isAfterTransfer = [INVOICE_STATUSES.scheduled, INVOICE_STATUSES.paid].includes(status);
        let isSuccess = [
          STATUS.COMPLETED,
          STATUS.PROCESSING,
          STATUS.PENDING,
          STATUS.STANDING_PROCESSING,
        ].includes(transferStatus);

        if (isAfterTransfer && isSuccess) {
          let toastMessage =
            status === INVOICE_STATUSES.paid
              ? this.intl.t('supplier-invoices.success-toast.mark-as-paid')
              : this.intl.t('supplier-invoices.transfers.scheduled');

          this.toastFlashMessages.clearToastMessages();
          this.toastFlashMessages.toastSuccess(toastMessage);
        }
      } catch (error) {
        this.errors.handleError(error);
      } finally {
        this.router.replaceWith({
          queryParams: {
            status: undefined,
            sortBy: null,
            supplierInvoiceId: null,
            transferStatus: null,
          },
        });
      }
    }
  }

  fetchSupplierInvoices() {
    this.fetchSupplierInvoicesTask
      .perform(this.params)
      .catch(ignoreCancelation)
      .catch(error => {
        this.toastFlashMessages.clearToastMessages();
        this.errors.handleError(error);
      });
  }

  @action
  fetchAll() {
    this.fetchSupplierInvoices();
    this.loadSupplierInvoicesInsights();
  }

  fetchHasNotCompletedEinvoiceTask = restartableTask(async () => {
    let hasDismissedTooltipForFirstEInvoice = false;
    let extraFilterAttributes = {};
    let { organization } = this.organizationManager;
    switch (organization?.legalCountry) {
      case 'DE':
        extraFilterAttributes = { is_einvoice: true };
        hasDismissedTooltipForFirstEInvoice = Boolean(
          safeLocalStorage.getItem(FIRST_GERMAN_EINVOICE_TOOLTIP_DISMISSAL_KEY)
        );
        break;

      case 'FR':
        extraFilterAttributes = { source: [INVOICE_SOURCES.eInvoicing] };
        hasDismissedTooltipForFirstEInvoice = Boolean(
          safeLocalStorage.getItem(FIRST_EINVOICE_TOOLTIP_DISMISSED_LOCAL_STORAGE_KEY)
        );
        break;
    }

    if (!['DE', 'FR'].includes(organization?.legalCountry) || hasDismissedTooltipForFirstEInvoice) {
      return false;
    }

    let completedEinvoices = await this.store.query('supplier-invoice', {
      page: 1,
      per_page: 25,
      filter: {
        status: [INVOICE_STATUSES.scheduled, INVOICE_STATUSES.paid, INVOICE_STATUSES.archived],
        ...extraFilterAttributes,
      },
    });

    if (completedEinvoices.length > 0) {
      return false;
    }

    return true;
  });

  fetchPayLaterEligibityTask = dropTask(async () => {
    return await this.financing.checkPayLaterEligibility();
  });

  storeTransition(transition) {
    this.transition = transition;
  }

  _handleInsightsError(error) {
    if (!this.fetchSupplierInvoicesTask.last.isError) {
      this.toastFlashMessages.toastError(this.intl.t('supplier-invoices.cockpit.error-toast'));
    }

    if (ErrorInfo.for(error).shouldSendToSentry) {
      this.sentry.captureException(error);
    }
  }

  fetchReceiptForwardEmailTask = dropTask(async () => {
    let { organization } = this.organizationManager;
    return await this.store.adapterFor('allowed-email').getBulkEmail(organization.id);
  });
}
