export default {
  "wrapper": "qZu",
  "beneficiary": "qRS",
  "name": "qRc body-1",
  "beneficiary-email": "qRq body-2",
  "dropdown-icon": "qRZ",
  "trusted": "qRR",
  "account-number": "qRQ flex body-2",
  "account-icon": "qRe",
  "m-chip": "qRB",
  "dropdown": "qRE body-2",
  "delete-option": "qRd",
  "beneficiary-name": "qRI"
};
