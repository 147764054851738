/* import __COLOCATED_TEMPLATE__ from './credit-note-modal.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { Spinner } from '@repo/design-system-kit';

interface ReceivableInvoicesCreditNoteModalSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class ReceivableInvoicesCreditNoteModalComponent extends Component<ReceivableInvoicesCreditNoteModalSignature> {
  spinner = Spinner;

  @service declare router: Services['router'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare intl: Services['intl'];
  @service declare segment: Services['segment'];

  get title() {
    return this.intl.t('receivable-invoices.credit-note-modal.credit-note-title.manual', {
      creditNoteNumber: this.creditNote?.number,
    });
  }

  get creditNote() {
    // @ts-expect-error
    return this.args.data.fetchTask.lastSuccessful?.value.creditNote;
  }

  get logo() {
    let { isDefaultAvatar, picture } = this.organizationManager.organization;
    return isDefaultAvatar ? null : picture;
  }

  get receivableInvoice() {
    // @ts-expect-error
    return this.args.data.fetchTask.lastSuccessful?.value.receivableInvoice;
  }

  get isPdfPreviewWithNoToolbar() {
    // @ts-expect-error
    return this.creditNote?.pdfPreviewIframeUrl && this.args.data.isPdfPreviewWithNoToolbar;
  }

  @action goBack() {
    this.segment.track('credit_note_options_escape');
    this.router.transitionTo('receivable-invoices.index');
  }

  // @ts-expect-error
  @action handleEscape(e) {
    if (e.key === 'Escape') {
      this.goBack();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ReceivableInvoices::CreditNoteModal': typeof ReceivableInvoicesCreditNoteModalComponent;
  }
}
