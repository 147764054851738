/* import __COLOCATED_TEMPLATE__ from './success-set-pin.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

interface FlowsCardsSuccessSetPinSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsCardsSuccessSetPin extends Component<FlowsCardsSuccessSetPinSignature> {
  @service declare modals: Services['modals'];
  @service declare segment: Services['segment'];

  @action
  handleClick() {
    // @ts-expect-error
    let { transitionToNext } = this.args;

    this.segment.track('set_pin_success_complete');

    transitionToNext();
  }

  @action handleOpenEducationalModal() {
    this.modals.open('card/modals/digital-first', {
      isFullScreenModal: true,
    });
    this.segment.track('set_pin_digital_first_education_opened');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Cards::SuccessSetPin': typeof FlowsCardsSuccessSetPin;
  }
}
