export default {
  "wrapper": "cPP",
  "content": "cPl",
  "back": "cPX btn btn--tertiary",
  "heading": "cPC",
  "heading-content": "cPk",
  "logo": "cPJ",
  "description": "cPs body-2",
  "cta": "cPF"
};
