/* import __COLOCATED_TEMPLATE__ from './details.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { AmountField, Disclaimer, TextField } from '@repo/design-system-kit';
// @ts-expect-error
import { variation } from 'ember-launch-darkly';

// @ts-expect-error
import { SCHEDULE_OPERATION_TYPES } from 'qonto/constants/standing-orders';
import { PayableAmountTooltip } from 'qonto/react/components/supplier-invoices/payable-amount-tooltip';
import { calculateVatAmount } from 'qonto/utils/transfers';

interface TransfersSepaDetailsSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLElement;
}

export default class TransfersSepaDetailsComponent extends Component<TransfersSepaDetailsSignature> {
  @service declare featuresManager: Services['featuresManager'];
  @service declare intl: Services['intl'];

  operationTypes = Object.values(SCHEDULE_OPERATION_TYPES);
  amountField = AmountField;
  textField = TextField;
  disclaimerInline = Disclaimer.Inline;
  payableAmountTooltip = PayableAmountTooltip;

  @tracked amountIsEdited = false;

  get organization() {
    // @ts-expect-error
    return this.args.sepaTransfer.get('organization');
  }

  get hasCreditNoteLinked() {
    // @ts-expect-error
    return this.args.context?.invoice?.relatedInvoices?.length;
  }

  get showPayableAmountDisclaimer() {
    return (
      variation('experiment--boolean-ap-credit-notes') &&
      !this.amountIsEdited &&
      this.hasCreditNoteLinked
    );
  }

  get totalCreditNotesAmount() {
    // @ts-expect-error
    let { totalAmountCreditNotes } = this.args.context?.invoice || {};

    return totalAmountCreditNotes
      ? // @ts-expect-error
        this.intl.formatMoney(totalAmountCreditNotes?.value, {
          currency: totalAmountCreditNotes?.currency,
          signus: '-',
        })
      : 0;
  }

  get creditNotesCount() {
    // @ts-expect-error
    let { relatedInvoices } = this.args.context?.invoice || {};

    return relatedInvoices?.length || 0;
  }

  get payableAmount() {
    // @ts-expect-error
    let { payableAmount } = this.args.context?.invoice || {};
    // @ts-expect-error
    return this.intl.formatMoney(payableAmount?.value, { currency: payableAmount?.currency });
  }

  get totalAmount() {
    // @ts-expect-error
    let { totalAmount } = this.args.context?.invoice || {};

    // @ts-expect-error
    return this.intl.formatMoney(totalAmount?.value, { currency: totalAmount?.currency });
  }

  @action
  // @ts-expect-error
  onReferenceChange(event) {
    // @ts-expect-error
    this.args.sepaTransfer.reference = event.currentTarget.value;
  }

  @action
  // @ts-expect-error
  onAmountChange(amount) {
    this.amountIsEdited = true;
    // @ts-expect-error
    this.args.sepaTransfer.amount = amount;
    this._updateVatAmount();
  }

  _updateVatAmount() {
    // @ts-expect-error
    let { sepaTransfer } = this.args;
    let { vatRate, amount } = sepaTransfer;

    if (vatRate > 0) {
      sepaTransfer.vatAmount = calculateVatAmount(amount, vatRate);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transfers::Sepa::Details': typeof TransfersSepaDetailsComponent;
  }
}
