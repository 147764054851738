import React, { type ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { Avatar, Badge, BadgeHighlight, type BadgeProps } from '@repo/design-system-kit';
// @ts-expect-error -- TODO remove this when ui-kit constants are typed
import { BADGE_TYPE } from '@qonto/ui-kit/constants/badge-types';
import cx from 'clsx';
import { COLLECTION_TYPES, GMI_SOLUTION_ID_PREFIX } from 'qonto/constants/connect';
// @ts-expect-error -- TODO remove this when utils are typed
import { isDateInTheFuture } from 'qonto/utils/date';
import styles from './styles.strict-module.css';

interface ApplicationDataLogo {
  url: string;
  alt?: string;
}

interface ApplicationData {
  name: string;
  expire_new_badge_date?: string;
  logo?: ApplicationDataLogo;
  minimum_integration_level?: string;
  tray_solution_id?: string;
  bank_bic?: string;
  integration_type?: string;
  category?: {
    name?: string;
  };
  short_description?: {
    text?: string;
  }[];
}
interface BadgeType {
  TRY_FOR_FREE: BadgeProps['type'];
  UPGRADE: BadgeProps['type'];
}

interface ApplicationHeaderContentProps {
  isCompact: boolean;
  application?: ApplicationData;
}

export function ApplicationCardContent({
  application,
  isCompact,
}: ApplicationHeaderContentProps): ReactNode {
  const { formatMessage } = useIntl();
  const abilities = useEmberService('abilities');
  const upsellManager = useEmberService('upsell-manager');

  const badgeTypes = BADGE_TYPE as BadgeType;
  const showBIC = Boolean(application?.bank_bic) && isCompact;
  const isNewBadgeExpired = (isDateInTheFuture as (date: string | undefined) => boolean)(
    application?.expire_new_badge_date
  );

  const cannotAccessIntegrationConnect = abilities.cannot(
    'access integration connect',
    application?.minimum_integration_level
  );

  const isTrialAvailable: boolean = application?.minimum_integration_level
    ? upsellManager.hasAvailableFreeTrial(application.minimum_integration_level)
    : false;

  const collectionTag =
    application?.integration_type === COLLECTION_TYPES.PARTNERSHIPS
      ? formatMessage({ id: 'connect.category.partnerships' })
      : formatMessage({ id: 'connect.category.integrations' });

  const showBetaBadge = Boolean(application?.tray_solution_id?.startsWith(GMI_SOLUTION_ID_PREFIX));

  return (
    <div className={cx(styles['card-container'], isCompact && styles.compact)}>
      <div className={styles['card-header']}>
        {/* Logo */}
        <div className={styles['application-logo']}>
          {application?.logo?.url ? (
            <Avatar loading="eager" size="40" src={application.logo.url} withBorder />
          ) : null}
        </div>

        {/* Badges */}
        {!isCompact ? (
          <>
            {showBetaBadge ? (
              <BadgeHighlight
                data-test-application-beta-badge
                level="secondary"
                text={formatMessage({ id: 'bank_accounts.header.beta-badge' })}
              />
            ) : null}
            {isNewBadgeExpired ? (
              <Badge
                data-test-application-new-badge
                text={formatMessage({ id: 'menu.labels.new' })}
                type="newFeature"
              />
            ) : null}
            {!isNewBadgeExpired && cannotAccessIntegrationConnect ? (
              <Badge
                data-test-application-upsell-badge
                text={
                  isTrialAvailable
                    ? formatMessage({ id: 'promotion-system.badge.trial.label' })
                    : formatMessage({ id: 'upsell.add-ons.badge' })
                }
                type={isTrialAvailable ? badgeTypes.TRY_FOR_FREE : badgeTypes.UPGRADE}
              />
            ) : null}
          </>
        ) : null}
      </div>

      {/* Name */}
      <div className={styles['application-name']}>
        {application?.name ? (
          <h4
            className={styles['application-title']}
            data-test-application-card-name={application.name}
          >
            {application.name}
          </h4>
        ) : null}

        {showBIC ? (
          <p
            className={styles['application-bic']}
            data-test-application-card-bic={application?.bank_bic}
          >
            {formatMessage({ id: 'labels.bic_only' })}:{application?.bank_bic}
          </p>
        ) : null}
      </div>

      {!isCompact ? (
        <>
          {/* Description */}
          <p className={styles['short-description']} data-test-application-card-short-description>
            {application?.short_description?.[0]?.text}
          </p>

          {/* Categories */}
          <section className={styles['application-categories']}>
            {application?.category?.name ? (
              <span
                className={styles['application-card-tag']}
                data-test-application-card-tag={application.category.name}
              >
                {application.category.name}
              </span>
            ) : null}

            <span
              className={styles['application-card-tag']}
              data-test-application-card-tag={collectionTag}
            >
              {collectionTag}
            </span>
          </section>
        </>
      ) : null}
    </div>
  );
}
