import { useMemo, type ReactNode } from 'react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
  type ColumnDef,
} from '@tanstack/react-table';
import { useIntl } from 'react-intl';
import { useFetchLabelLists } from 'qonto/react/hooks/use-fetch-label-lists';
import { useLabelCashflows } from 'qonto/react/components/cash-flow/hooks/use-label-cashflows';
import type {
  UnlabeledStatisticsResponse,
  LabelStatisticsResponse,
} from 'qonto/react/components/cash-flow/api/labels';
import {
  generateLoadingHeaders,
  generateLoadingLabelsData,
} from '../../utils/generate-loading-data';
import type { LabelTableRow } from '../../models/labels-cashflow-display';
import { TableCell } from '../shared/table-cell';
import styles from './styles.strict-module.css';
import { LabelCell } from './label-cell';
import { BalanceCell } from './balance-cell';
import { HeaderCell } from './header-cell';

const buildTableSchema = (
  data: LabelTableRow[],
  isLoading: boolean,
  numberOfColumns: number,
  copy: Record<string, string>
): {
  tableData: LabelTableRow[];
  columns: ColumnDef<LabelTableRow>[];
} => {
  const headersData = isLoading
    ? generateLoadingHeaders(numberOfColumns)
    : Array.from({ length: numberOfColumns }, (_, index) => String(index));
  const tableData = isLoading ? generateLoadingLabelsData(numberOfColumns) : data;

  const columnHelper = createColumnHelper<LabelTableRow>();
  const columns = [
    columnHelper.accessor('attribute', {
      header: copy.analyticLabels,
      cell: info => (
        <LabelCell
          color={info.row.original.color}
          title={info.getValue()}
          type={info.row.original.type}
        />
      ),
    }),
    ...headersData.map((col, index) =>
      columnHelper.accessor('columns', {
        id: col,
        header: ({ table }) => {
          const interval = table.getRowModel().rows[0]?.original.columns[index]?.interval;
          return <HeaderCell interval={interval} />;
        },
        cell: info => {
          return (
            <BalanceCell
              amount={info.getValue()[index]?.amount}
              interval={info.getValue()[index]?.interval}
              labelEntityId={info.row.original.id}
              type={info.row.original.type}
            />
          );
        },
      })
    ),
  ] as ColumnDef<LabelTableRow>[];

  return {
    tableData,
    columns,
  };
};

interface LabelsTableProps {
  data: LabelTableRow[];
  isLoading?: boolean;
  numberOfColumns?: number;
}
export function LabelsTable({
  data,
  isLoading = false,
  numberOfColumns = 6,
  ...props
}: LabelsTableProps): ReactNode {
  const { formatMessage } = useIntl();
  const copy = useMemo(
    () => ({
      analyticLabels: formatMessage({ id: 'cash-flow.table.header-column.analytic-labels' }),
    }),
    [formatMessage]
  );

  const { tableData, columns } = useMemo(
    () => buildTableSchema(data, isLoading, numberOfColumns, copy),
    [data, isLoading, numberOfColumns, copy]
  );

  const table = useReactTable({
    data: tableData,
    columns,
    initialState: {
      expanded: true,
    },
    getCoreRowModel: getCoreRowModel(),
    getSubRows: row => row.subRows,
    getExpandedRowModel: getExpandedRowModel(),
  });

  return (
    <table aria-busy={isLoading} aria-live="polite" className={styles.labelsTable} {...props}>
      <colgroup>
        <col style={{ width: '250px' }} />
        {Array.from({ length: numberOfColumns }, (_, index) => (
          <col key={index} style={{ width: '100%' }} />
        ))}
      </colgroup>
      <thead>
        {table.getHeaderGroups().map(headerGroup => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header, index) => {
              const isFirstColumn = index === 0;
              return (
                <th className={styles.colHeader} key={header.id} scope="col">
                  {header.isPlaceholder ? null : (
                    <TableCell
                      align={isFirstColumn ? 'left' : 'center'}
                      isLoading={isFirstColumn ? false : isLoading}
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                    </TableCell>
                  )}
                </th>
              );
            })}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map(row => (
          <tr key={row.id}>
            {row.getVisibleCells().map((cell, index) => {
              if (index === 0) {
                return (
                  <th
                    className={styles.rowHeader}
                    data-testid="row-header"
                    key={cell.id}
                    scope="row"
                  >
                    <TableCell align="left" isLabel isLoading={isLoading}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  </th>
                );
              }
              return (
                <td className={styles.rowCell} key={cell.id}>
                  <TableCell isLoading={isLoading}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

interface LabelsTableContainerProps {
  isLoading?: boolean;
  labelStatistics: LabelStatisticsResponse;
  numberOfColumns?: number;
  offset: number;
  organizationId: string;
  unlabeledStatistics: UnlabeledStatisticsResponse;
}
export function LabelsTableProvider({
  isLoading = false,
  labelStatistics,
  numberOfColumns = 6,
  offset,
  organizationId,
  unlabeledStatistics,
  ...props
}: LabelsTableContainerProps): ReactNode {
  const { data: labelLists, isLoading: isLoadingLabelLists } = useFetchLabelLists(organizationId);

  const labelTableData = useLabelCashflows(
    offset,
    numberOfColumns,
    labelStatistics,
    unlabeledStatistics,
    labelLists
  );

  const isTableLoading = isLoading || isLoadingLabelLists;

  return <LabelsTable data={labelTableData} isLoading={isTableLoading} {...props} />;
}
