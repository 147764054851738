export default {
  "header-cell": "FR",
  "empty": "FQ",
  "emitter": "Fe",
  "date": "FB",
  "amount": "FE",
  "status": "Fd",
  "header-content": "FI caption-bold",
  "active": "Ft"
};
