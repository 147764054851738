export default {
  "header-cell": "qVi",
  "header-content": "qVA caption-bold",
  "active": "qVY",
  "align-right": "qVh",
  "empty": "qVf",
  "align-checkbox": "qVK",
  "col-5": "qVG",
  "col-9": "qVr",
  "emitted-at-header-tag": "qVb"
};
