/* import __COLOCATED_TEMPLATE__ from './container.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface ContainerSignature {
  Element: HTMLElement;
  Args: {};
  Blocks: {
    default: [];
  };
}

const Container = templateOnlyComponent<ContainerSignature>();

export default Container;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'F24::Sidebar::Container': typeof Container;
  }
}
