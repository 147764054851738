export default {
  "container": "IK",
  "title": "IG",
  "subtitle": "Ir",
  "form": "Ib",
  "uploader": "Iy",
  "submit-button": "IU",
  "error-message": "Ij",
  "inputs": "IW"
};
