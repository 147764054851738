export default {
  "card": "EV",
  "header": "EL",
  "budget-caption": "ET caption",
  "amount-spent-section": "Ez",
  "amount-spent-icon-container": "Ea",
  "amount-spent-title": "EH title-3",
  "amount-budgeted-section": "EO",
  "amount-budgeted-title": "Em title-3"
};
