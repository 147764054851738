export default {
  "not-active": "Br",
  "title": "Bb",
  "details": "By",
  "detail": "BU",
  "text-secondary": "Bj",
  "text-primary-a": "BW",
  "amounts": "Bu",
  "contents-container": "ES",
  "contents": "Ec",
  "menu-item": "Eq",
  "archive": "EZ",
  "amount-settled": "ER",
  "shadow": "EQ",
  "name": "Ee title-4",
  "tooltip": "EB"
};
