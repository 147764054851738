import Route from '@ember/routing/route';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';
import { variation } from 'ember-launch-darkly';

export default class SupplierInvoicesRoute extends Route {
  @service abilities;
  @service homePage;
  @service organizationManager;
  @service router;

  beforeModel() {
    Sentry.getCurrentScope().setTag('CFT', 'invoices');
    let canNavigateV2 = this.abilities.can('navigate v2 supplier-invoice');
    let { organization } = this.organizationManager;

    if (!canNavigateV2 || this.abilities.cannot('view v2 supplierInvoice')) {
      return this.homePage.replaceWithDefaultPage();
    }

    if (
      variation('feature--boolean-improve-first-time-exp-si') &&
      organization.hasSIFirstTimeUsageFeature
    ) {
      return this.router.replaceWith('supplier-invoices.intro');
    }
  }
}
