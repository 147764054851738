export default {
  "mandate-new-no-mandate": "uN",
  "left-side": "uv",
  "left-side__wrapper": "uo",
  "left-side__wrapper-title": "un",
  "left-side__wrapper-details": "ui",
  "left-side__wrapper-details-item": "uA",
  "right-side": "uY",
  "multi-accounts": "uh",
  "right-side__wrapper": "uf",
  "right-side__wrapper-name": "uK",
  "right-side__wrapper-address": "uG",
  "right-side__wrapper-iban-dashed": "ur",
  "right-side__wrapper-address-label": "ub",
  "right-side__wrapper-iban-label": "uy",
  "right-side__wrapper-bic-label": "uU",
  "right-side__wrapper-address-dashed": "uj",
  "right-side__wrapper-bic-dashed": "uW",
  "dropdown-icon": "uu",
  "dropdown": "cSS",
  "multi-accounts-illustration": "cSc"
};
