/* import __COLOCATED_TEMPLATE__ from './beneficiary-iban-field.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { ignoreCancelation } from 'qonto/utils/ignore-error';

interface BankAccountBeneficiaryFormSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class BankAccountBeneficiaryFormComponent extends Component<BankAccountBeneficiaryFormSignature> {
  @service declare segment: Services['segment'];

  @action
  handleAddBeneficiary() {
    this.segment.track('transfer_create_beneficiary_edit_redirection');
    // @ts-expect-error
    this.args.switchToAddBeneficiaryTask.perform().catch(ignoreCancelation);
  }

  @action
  handleUpdateBeneficiary() {
    this.segment.track('transfer_edit_beneficiary_existing_benef_redirection');
    // @ts-expect-error
    this.args.switchToUpdateBeneficiaryTask.perform().catch(ignoreCancelation);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'BeneficiaryFormModal::BeneficiaryIbanField': typeof BankAccountBeneficiaryFormComponent;
  }
}
