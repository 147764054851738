export default {
  "name-wrapper": "cHm",
  "preset-name": "cHp",
  "invoices-entry-period-container": "cHx",
  "transactions-entry-period-container": "cHw",
  "period-wrapper": "cHN",
  "period-btn": "cHv",
  "cancel-placeholder": "cHo",
  "period-cancel-btn": "cHn",
  "transactions-entry-container": "cHi",
  "invoices-entry-container": "cHA",
  "dropdown": "cHY",
  "dropdown-opened": "cHh",
  "dropdown-closed": "cHf"
};
