export default {
  "page-wrapper": "qxO",
  "container": "qxm",
  "sidebar": "qxp",
  "search-input": "qxx",
  "organizations-list": "qxw",
  "content": "qxN",
  "no-result": "qxv",
  "lottie-illustration": "qxo",
  "accounting-header": "qxn",
  "logo": "qxi",
  "accounting-title": "qxA"
};
