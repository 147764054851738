export default {
  "modal": "qse",
  "section": "qsB",
  "header": "qsE",
  "title": "qsd",
  "separator-title": "qsI",
  "icon": "qst",
  "formats": "qsM",
  "columns": "qsP",
  "column-list": "qsl",
  "draggable-column": "qsX",
  "column-field": "qsC",
  "column-selector": "qsk",
  "column-with-error": "qsJ",
  "handle": "qss",
  "label-color": "qsF",
  "disclaimer": "qsg"
};
