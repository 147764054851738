export default {
  "team": "qfV",
  "no-members": "qfL",
  "teams-header": "qfT",
  "header-search": "qfz",
  "search-bar": "qfa",
  "invite": "qfH body-2",
  "invite-text": "qfO",
  "empty-illustration": "qfm",
  "empty-title": "qfp title-3",
  "empty-description": "qfx body-2",
  "pagination-footer": "qfw",
  "filter-search": "qfN",
  "fullsize": "qfv",
  "tabs-container": "qfo",
  "tabs-separator": "qfn",
  "tooltip-wrapper": "qfi",
  "invite-cta": "qfA"
};
