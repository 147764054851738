import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { dropTask } from 'ember-concurrency';

import { ErrorInfo } from 'qonto/utils/error-info';

export default class QuotesShareRoute extends Route {
  @service menu;
  @service store;
  @service organizationManager;
  @service router;
  @service sentry;
  @service toastFlashMessages;
  @service networkManager;
  @service intl;

  activate() {
    this.menu.hide();
  }

  deactivate() {
    this.menu.show();
  }

  async model({ id }) {
    let { organization } = this.organizationManager;

    this.fetchOrganizationAvatarTask.perform(organization).catch(error => {
      this.handleError(error);
    });

    let organizationId = organization.id;

    let quote, settings;

    try {
      quote = await this.store.findRecord('quote', id);
      settings = await this.store.findRecord('receivable-invoices-settings', organizationId);
      if (quote && !quote.isCanceledOrExpired) {
        await this.fetchEmailTemplate.perform(id);
      }
    } catch (error) {
      this.handleError(error);
    }

    return {
      quote,
      settings,
      emailTemplate: this.fetchEmailTemplate.lastSuccessful?.value.data.attributes,
    };
  }

  redirect({ quote }) {
    if (!quote || quote.isCanceledOrExpired) {
      return this.router.replaceWith('quotes.index');
    }
  }

  fetchEmailTemplate = dropTask(async id => {
    try {
      return await this.store.adapterFor('quote').fetchEmailTemplate(id);
    } catch (error) {
      this.handleError(error);
      this.router.transitionTo('quotes.index');
    }
  });

  setupController(controller, model) {
    super.setupController(controller, model);
    controller.setDefaultParams();
  }

  fetchOrganizationAvatarTask = dropTask(async organization => {
    try {
      await organization.getAvatar();
    } catch (error) {
      this.handleError(error);
    }
  });

  handleError(error) {
    if (!error.isAdapterError) {
      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error);
        this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
      }
    }
  }
}
