export default {
  "header": "qIM",
  "subtitle": "qIP body-2",
  "date": "qIl",
  "subtitle-error": "qIX",
  "card": "qIC",
  "card-header": "qIk",
  "line-placeholder": "qIJ",
  "post-closing-list": "qIs",
  "post-closing-container": "qIF",
  "post-closing-list-element": "qIg",
  "free-trial-disclaimer": "qID",
  "description": "qIV body-2",
  "mw-100": "qIL",
  "addon-overview": "qIT",
  "addons-container": "qIz"
};
