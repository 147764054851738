/* import __COLOCATED_TEMPLATE__ from './edit-form.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { parseDate } from '@internationalized/date';
import { DateField, Disclaimer } from '@repo/design-system-kit';

import { GenderSelect } from 'qonto/react/components/gender-select';
import { CorporateOfficerRadioGroup } from 'qonto/react/components/membership/corporate-officer-radio-group';
import { LegalRepresentativeRadioGroup } from 'qonto/react/components/membership/legal-representative-radio-group';
import { TaxPayerOutsideLegalCountryRadioGroup } from 'qonto/react/components/membership/tax-payer-outside-legal-country-radio-group';
import { UboRadioGroup } from 'qonto/react/components/membership/ubo-radio-group';
import { UsTaxPayerRadioGroup } from 'qonto/react/components/membership/us-tax-payer-radio-group';
import {
  getUboConnectionToOrganizationOptions,
  isPrimaryUboConnectionToOrganization,
} from 'qonto/utils/ubo';

import styles from './edit-form.strict-module.css';

interface MembershipEditFormSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class MembershipEditFormComponent extends Component<MembershipEditFormSignature> {
  dateField = DateField;
  disclaimerInline = Disclaimer.Inline;
  genderSelect = GenderSelect;
  styles = styles;

  corporateOfficerRadioGroup = CorporateOfficerRadioGroup;
  legalRepresentativeRadioGroup = LegalRepresentativeRadioGroup;
  taxPayerOutsideLegalCountryRadioGroup = TaxPayerOutsideLegalCountryRadioGroup;
  usTaxPayerRadioGroup = UsTaxPayerRadioGroup;
  uboRadioGroup = UboRadioGroup;
  disclaimerBlock = Disclaimer.Block;

  @service declare modals: Services['modals'];
  @service declare intl: Services['intl'];

  // @ts-expect-error
  previewModal;

  get isEditMode() {
    // @ts-expect-error
    return this.args.mode === 'edit';
  }

  get membershipBirthday() {
    // @ts-expect-error
    let birthdate = this.args.membershipChangeRequest.birthdate;
    return birthdate ? parseDate(birthdate) : birthdate;
  }

  get shouldShowMissingInformation() {
    // @ts-expect-error
    return this.isEditMode && !this.args.showValidations;
  }

  get shouldShowMissingInformationDisclaimer() {
    let {
      firstName,
      lastName,
      nationality,
      birthdate,
      birthCity,
      addressFirstLine,
      addressZipCode,
      addressCity,
      addressCountry,
      taxCountry,
      needsTaxNumber,
      legalRepresentative,
      ubo,
      taxIdentificationNumber,
      // @ts-expect-error
    } = this.args.membershipChangeRequest;

    if (!this.isEditMode) {
      return false;
    }

    return (
      !firstName ||
      !lastName ||
      !nationality ||
      !birthdate ||
      !birthCity ||
      !addressCity ||
      !addressFirstLine ||
      !addressZipCode ||
      !addressCountry ||
      (ubo && !taxCountry) ||
      (ubo && needsTaxNumber && !taxIdentificationNumber) ||
      // Files
      // @ts-expect-error
      (legalRepresentative && !this.args.kbis.length)
    );
  }

  get corporateOfficerErrorMessage() {
    return (
      // @ts-expect-error
      this.args.showValidations &&
      // @ts-expect-error
      this.args.membershipChangeRequest.validations.attrs.corporateOfficer.message
    );
  }

  get legalRepresentativeErrorMessage() {
    return (
      // @ts-expect-error
      this.args.showValidations &&
      // @ts-expect-error
      this.args.membershipChangeRequest.validations.attrs.legalRepresentative.message
    );
  }

  get uboErrorMessage() {
    return (
      // @ts-expect-error
      this.args.showValidations && this.args.membershipChangeRequest.validations.attrs.ubo.message
    );
  }

  get usTaxPayerMessage() {
    return (
      // @ts-expect-error
      this.args.showValidations &&
      // @ts-expect-error
      this.args.membershipChangeRequest.validations.attrs.usTaxPayer.message
    );
  }

  get taxPayerOutsideLegalCountryMessage() {
    return (
      // @ts-expect-error
      this.args.showValidations &&
      // @ts-expect-error
      this.args.membershipChangeRequest.validations.attrs.taxPayerOutsideLegalCountry.message
    );
  }

  get isGermanOrganization() {
    // @ts-expect-error
    return this.args.organization.legalCountry === 'DE';
  }

  get showUsRelatedTaxInformations() {
    // @ts-expect-error
    if (this.args.mode === 'add') return false;

    // @ts-expect-error
    return !this.isGermanOrganization && this.args.membershipChangeRequest.taxCountry !== null;
  }

  get showTaxPayerOutsideLegalCountry() {
    // @ts-expect-error
    if (this.args.mode === 'add') return false;

    return (
      // @ts-expect-error
      (this.args.membershipChangeRequest.taxCountry === 'FR' ||
        // @ts-expect-error
        this.args.membershipChangeRequest.taxCountry === 'IT') &&
      !this.isGermanOrganization
    );
  }

  get isNameDisabled() {
    // @ts-expect-error
    return this.args.membershipChangeRequest.membership.get('owner');
  }

  get shouldDisplayUboConnectionToOrganization() {
    // @ts-expect-error
    return this.args.organization.isItalianShareCapitalCompany;
  }

  get isUboConnectionToOrganizationFieldOptional() {
    // @ts-expect-error
    return this.shouldDisplayUboConnectionToOrganization && !this.args.membershipChangeRequest.ubo;
  }

  get uboConnectionToOrganizationFieldTitle() {
    // @ts-expect-error
    return this.args.membershipChangeRequest.ubo === true
      ? this.intl.t('organizations.profile.owner-form.ubo-connection-dropdown.title')
      : this.intl.t('organizations.profile.owner-form.ubo-connection-dropdown.title-optional');
  }

  get uboConnectionToOrganizationOptions() {
    return getUboConnectionToOrganizationOptions(this.intl);
  }

  get selectedUboConnectionToOrganization() {
    return this.uboConnectionToOrganizationOptions.find(
      // @ts-expect-error
      ({ value }) => value === this.args.membershipChangeRequest.uboConnectionToOrganization
    );
  }

  @action
  // @ts-expect-error
  changeUboConnectionToOrganization({ value }) {
    // @ts-expect-error
    this.args.membershipChangeRequest.uboConnectionToOrganization = value;
    // @ts-expect-error
    this.args.membershipChangeRequest.uboConnectionToOrganizationRaw =
      isPrimaryUboConnectionToOrganization(value) ? value : null;
  }

  @action
  // @ts-expect-error
  updateBirthDate(value) {
    if (value) {
      // @ts-expect-error
      this.args.membershipChangeRequest.birthdate = value.toString();
    }
  }

  @action
  // @ts-expect-error
  updateMembershipNationalities(countryCodes) {
    // @ts-expect-error
    this.args.membershipChangeRequest.nationalities = countryCodes;
  }

  @action
  // @ts-expect-error
  handlePoiUploaded(file) {
    // @ts-expect-error
    this.args.setShowEmptyPoiError(false);
    // @ts-expect-error
    this.args.pois.push(file);
  }

  @action
  // @ts-expect-error
  handleKbisUploaded(file) {
    // @ts-expect-error
    this.args.setShowEmptyKbisError(false);
    // @ts-expect-error
    this.args.kbis.push(file);
  }

  @action
  // @ts-expect-error
  handleDeleteKbis(file) {
    file.deleteRecord();
    // @ts-expect-error
    let index = this.args.kbis.indexOf(file);
    // @ts-expect-error
    this.args.kbis.splice(index, 1);
    this.previewModal?.close();
  }

  @action
  // @ts-expect-error
  handleDeletePoi(file) {
    file.deleteRecord();
    // @ts-expect-error
    let index = this.args.pois.indexOf(file);
    // @ts-expect-error
    this.args.pois.splice(index, 1);
    this.previewModal?.close();
  }

  @action
  // @ts-expect-error
  handlePreviewFile(file) {
    this.previewModal = this.modals.open('file-preview-modal', {
      isFullScreenModal: true,
      selectedFile: file,
      deletable: false,
    });
  }

  @action
  // @ts-expect-error
  handleChangeTaxCountry(taxCountry) {
    // @ts-expect-error
    this.args.membershipChangeRequest.taxCountry = taxCountry;

    // If Tax Country already exists on the original Organization,
    // => Then we use the Tax Identification Number associated to this country
    // => If not we clear the field
    // @ts-expect-error
    let { membership } = this.args;
    if (membership && membership.taxResidenceCountry === taxCountry) {
      // @ts-expect-error
      this.args.membershipChangeRequest.taxIdentificationNumber =
        membership.taxResidenceTaxIdentificationNumber;
      // @ts-expect-error
    } else if (taxCountry !== this.args.membershipChangeRequest.taxResidenceCountry) {
      // @ts-expect-error
      this.args.membershipChangeRequest.taxIdentificationNumber = '';
    }

    if (
      !this.isGermanOrganization &&
      // @ts-expect-error
      this.args.mode === 'edit' &&
      // @ts-expect-error
      this.args.membershipChangeRequest.owner &&
      taxCountry === 'US'
    ) {
      // @ts-expect-error
      this.args.membershipChangeRequest.usTaxPayer = true;
    }
  }

  // @ts-expect-error
  @action handleCorpOfficerChange(value) {
    if (!value) {
      // @ts-expect-error
      this.args.membershipChangeRequest.legalRepresentative = false;
    } else {
      // @ts-expect-error
      this.args.membershipChangeRequest.legalRepresentative = null;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Membership::EditForm': typeof MembershipEditFormComponent;
  }
}
