import Controller from '@ember/controller';
import { service } from '@ember/service';

import { reads } from 'macro-decorators';

export default class OverviewIndexController extends Controller {
  @service abilities;
  @service organizationManager;
  @service notifierCounterManager;
  @service featuresManager;

  @reads('organizationManager.organization.hasExternalAccounts') hasExternalAccounts;

  get shouldDisplaySubmitKycUpsizeBanner() {
    return (
      this.organizationManager.membership.shouldSubmitKyc &&
      this.organizationManager.organization.kybPending
    );
  }
}
