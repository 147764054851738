export default {
  "container": "qUw",
  "content": "qUN",
  "setup-box": "qUv",
  "setup-wizard": "qUo",
  "setup-success": "qUn",
  "setup-success-lottie": "qUi",
  "setup-close-btn": "qUA",
  "tray-connection": "qUY",
  "close-button": "qUh"
};
