/* import __COLOCATED_TEMPLATE__ from './request-table.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

interface TransfersRequestTableSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class TransfersRequestTableComponent extends Component<TransfersRequestTableSignature> {
  @service declare router: Services['router'];

  get isApproveCompactView() {
    // @ts-expect-error
    return this.args.isSingleAccountApprover && !this.args.isSidebarOpen;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transfers::RequestTable': typeof TransfersRequestTableComponent;
  }
}
