/* import __COLOCATED_TEMPLATE__ from './regioni.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

// @ts-expect-error
import { ERROR_KEYS } from 'qonto/constants/f24';

const MAX_ITEMS_COUNT = 4;

const FIELD_NAME_KEYS = {
  regionCode: 'f24.manual-declaration.module.regioni.section1.field1',
  taxCode: 'f24.manual-declaration.module.regioni.section1.field2',
  referenceMonth: 'f24.manual-declaration.module.regioni.section1.field3',
  referenceYear: 'f24.manual-declaration.module.regioni.section1.field4',
  taxAmount: 'f24.manual-declaration.module.regioni.section1.field5',
  compensationAmount: 'f24.manual-declaration.module.regioni.section1.field6',
};

interface F24FormRegioniSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLFieldSetElement;
}

export default class F24FormRegioni extends Component<F24FormRegioniSignature> {
  fieldNameKeys = FIELD_NAME_KEYS;

  @action
  // @ts-expect-error
  getErrorKey(code) {
    return ERROR_KEYS[code] ?? ERROR_KEYS.generic_error;
  }

  get isAddItemDisabled() {
    // @ts-expect-error
    return this.args.regioniTaxList.length >= MAX_ITEMS_COUNT;
  }

  get totalTax() {
    return this.sumUp('taxAmount');
  }

  get totalCompensation() {
    return this.sumUp('compensationAmount');
  }

  get netTotal() {
    return Number(this.totalTax - this.totalCompensation).toFixed(2);
  }

  // @ts-expect-error
  sumUp(attributeName) {
    return (
      // @ts-expect-error
      this.args.regioniTaxList
        // @ts-expect-error
        .reduce((acc, item) => {
          let value = item[attributeName] ?? 0;
          return acc + Number(value);
        }, 0)
        .toFixed(2)
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'F24::Form::Regioni': typeof F24FormRegioni;
  }
}
