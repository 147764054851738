/* import __COLOCATED_TEMPLATE__ from './currencies-dropdown.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { groupCurrencies } from 'qonto/utils/currency';
// @ts-expect-error
import { getSupportedCurrencies } from 'qonto/utils/receivable-invoicing';

interface ReceivableInvoicesCurrenciesDropdownSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class ReceivableInvoicesCurrenciesDropdown extends Component<ReceivableInvoicesCurrenciesDropdownSignature> {
  @service declare intl: Services['intl'];
  // @ts-expect-error
  allCurrencies = getSupportedCurrencies(this.intl);
  defaultCurrency = {
    code: 'EUR',
    // @ts-expect-error
    displayName: this.intl.t(`currency.EUR`),
    countryCode: 'EU',
    // @ts-expect-error
    keywords: ['EUR', 'EU', this.intl.t(`currency.EUR`)],
    suggestionPriority: null,
    // @ts-expect-error
    currencyCodeWithName: `EUR - ${this.intl.t(`currency.EUR`)}`,
  };

  @tracked selectedCurrency = this.correctSelectedCurrency;

  get currencies() {
    let [popularCurrencies, otherCurrencies] = groupCurrencies(this.allCurrencies);

    // @ts-expect-error
    return popularCurrencies.length
      ? [
          {
            groupName: this.intl.t(
              'receivable-invoices.customer-creation.invoice-currency.dropdown.popular'
            ),
            options: popularCurrencies,
          },
          {
            groupName: this.intl.t(
              'receivable-invoices.customer-creation.invoice-currency.dropdown.all'
            ),
            options: otherCurrencies,
          },
        ]
      : this.allCurrencies;
  }

  get correctSelectedCurrency() {
    return (
      // @ts-expect-error
      this.allCurrencies.find(({ code }) => code === this.args.customer?.currency) ??
      this.defaultCurrency
    );
  }

  @action
  // @ts-expect-error
  changeCurrency(currency) {
    // @ts-expect-error
    this.args.onChange?.(currency.code);
    // @ts-expect-error
    this.args.customer.currency = currency.code;
    this.selectedCurrency = currency;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ReceivableInvoices::CurrenciesDropdown': typeof ReceivableInvoicesCurrenciesDropdown;
  }
}
