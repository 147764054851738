/* import __COLOCATED_TEMPLATE__ from './account-feature-list.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface AccountFeatureListSignature {
  Element: HTMLUListElement;
  Args: {};
  Blocks: {
    default: [];
  };
}

const AccountFeatureList = templateOnlyComponent<AccountFeatureListSignature>();

export default AccountFeatureList;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    AccountFeatureList: typeof AccountFeatureList;
  }
}
