/* import __COLOCATED_TEMPLATE__ from './decline-invoice.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { TextAreaField } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';

interface SupplierInvoicesModalsDeclineInvoiceSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class SupplierInvoicesModalsDeclineInvoiceComponent extends Component<SupplierInvoicesModalsDeclineInvoiceSignature> {
  @service declare errors: Services['errors'];

  textAreaField = TextAreaField;

  @tracked declinedNote = '';
  @tracked hasEmptyDeclinedNoteError = false;

  confirmTask = dropTask(async () => {
    this.hasEmptyDeclinedNoteError = false;
    let {
      // @ts-expect-error
      close,
      // @ts-expect-error
      data: { confirmTask },
    } = this.args;

    if (!this.declinedNote) {
      return (this.hasEmptyDeclinedNoteError = true);
    }

    try {
      if (confirmTask) {
        await confirmTask.linked().perform(close, this.declinedNote);
      } else {
        close('confirm');
      }
    } catch (error) {
      this.errors.handleError(error);
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'SupplierInvoices::Modals::DeclineInvoice': typeof SupplierInvoicesModalsDeclineInvoiceComponent;
  }
}
