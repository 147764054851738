export default {
  "card": "qpe",
  "card-primary": "qpB",
  "content": "qpE",
  "caption-text": "qpd",
  "content-primary": "qpI",
  "disabled": "qpt",
  "illustration": "qpM",
  "illustration-primary": "qpP",
  "illustration-disabled": "qpl",
  "cta": "qpX",
  "dismiss-button": "qpC",
  "buttons": "qpk",
  "required-badge": "qpJ"
};
