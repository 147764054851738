/* import __COLOCATED_TEMPLATE__ from './country-trigger.hbs'; */
import Component from '@glimmer/component';

import { Country } from 'qonto/react/components/transfers/international-out/country';

interface Signature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class TransfersInternationalOutDynamicFormFieldSelectCountryTriggerComponent extends Component<Signature> {
  country = Country;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transfers::InternationalOut::DynamicForm::Field::Select::CountryTrigger': typeof TransfersInternationalOutDynamicFormFieldSelectCountryTriggerComponent;
  }
}
