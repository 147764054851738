/* import __COLOCATED_TEMPLATE__ from './atm.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface AtmSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const Atm = templateOnlyComponent<AtmSignature>();

export default Atm;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'HelpCenter::SelectedItem::Claim::Atm': typeof Atm;
  }
}
