export default {
  "card-sidebar-header": "Mt",
  "content": "MM",
  "card-image-container": "MP",
  "card-image": "Ml",
  "legacy": "MX",
  "type": "MC",
  "type-container": "Mk",
  "type-close": "MJ",
  "type-status-awaiting": "Ms",
  "type-status-live": "MF",
  "type-status-paused": "Mg",
  "type-status-ghost": "MD",
  "actions-panel": "MV",
  "actions-panel-header": "ML",
  "action-btn": "MT",
  "disclaimer": "Mz",
  "infobox": "Ma",
  "btn-panel": "MH",
  "required-action-btn": "MO",
  "kyc-disclaimer": "Mm"
};
