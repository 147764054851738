import type { Amount } from 'qonto/react/models/amount';
import type {
  CashflowCategoryDataPoint,
  CashflowSideDatapoint,
  CashflowTimeframe,
} from 'qonto/react/models/cash-flow-timeseries';

export function ensureForecastAmount(
  amount: Amount | undefined,
  currency: string
): { value: number; currency: string } {
  return amount || { value: 0, currency };
}
export function updateCategoryForecast(
  categories: CashflowCategoryDataPoint[],
  categoryId: string,
  value: number,
  currency: string
): CashflowCategoryDataPoint[] {
  return categories.map(category => {
    if (category.categoryId === categoryId) {
      // Ensure forecastAmount exists
      category.forecastAmount = ensureForecastAmount(category.forecastAmount, currency);

      // Subtract the old value, if it exists
      if (category.forecastAmount.value) {
        category.forecastAmount.value -= category.forecastAmount.value;
      }

      // Add the new value
      category.forecastAmount.value = value;
    }
    return category;
  });
}

export function getTypeAndData(
  categoryId: string,
  timeframe: CashflowTimeframe
): {
  type: 'inflow' | 'outflow';
  data: CashflowSideDatapoint;
} | null {
  if (timeframe.cashFlowData.inflows.categoriesDataPoints.some(c => c.categoryId === categoryId)) {
    return {
      type: 'inflow',
      data: timeframe.cashFlowData.inflows,
    };
  }
  if (timeframe.cashFlowData.outflows.categoriesDataPoints.some(c => c.categoryId === categoryId)) {
    return {
      type: 'outflow',
      data: timeframe.cashFlowData.outflows,
    };
  }
  return null;
}
