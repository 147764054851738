export default {
  "header-cell": "qcj",
  "header-content": "qcW caption-bold",
  "active": "qcu",
  "empty": "qqS",
  "align-right": "qqc",
  "request-type": "qqq",
  "requester": "qqZ",
  "amount": "qqR"
};
