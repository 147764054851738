import Model, { attr } from '@ember-data/model';

export default class EinvoicingFrSettingsModel extends Model {
  @attr('string') declare einvoicingReceivingStatus:
    | 'enabled'
    | 'no_qonto'
    | 'disabled'
    | 'pending_creation'
    | 'pending_deletion';
  @attr('boolean', { defaultValue: false }) declare einvoicingSendingEnabled: boolean;
  @attr({ defaultValue: null }) declare addresses: Array<{
    isQonto: boolean;
    pdpName: string;
    address: string;
  }> | null;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'einvoicing-fr-settings': EinvoicingFrSettingsModel;
  }
}
