/* import __COLOCATED_TEMPLATE__ from './empty-states.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';

interface RequestsProvidersPastEmptyStatesSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class RequestsProvidersPastEmptyStates extends Component<RequestsProvidersPastEmptyStatesSignature> {
  @service declare abilities: Services['abilities'];
  @service declare intl: Services['intl'];
  @service declare router: Services['router'];
  @service declare flowLinkManager: Services['flowLinkManager'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare zendeskLocalization: Services['zendeskLocalization'];

  // @ts-expect-error
  canReadEbics = this.abilities.can('read ebics request');
  // @ts-expect-error
  canCreateCardRequests = this.abilities.can('create card request');
  // @ts-expect-error
  canReviewCardRequests = this.abilities.can('review card request');
  // @ts-expect-error
  canCreateTransferRequests = this.abilities.can('create transfer request');
  // @ts-expect-error
  canReviewTransferRequests = this.abilities.can('review transfer request');
  // @ts-expect-error
  canInviteMembers = this.abilities.can('create invite');
  lottieSrc = '/lotties/empty-state/search-no-result.json';

  get subtitle() {
    if (this.canReadEbics) {
      let linkText = htmlSafe(
        `<a
            href=${this.intl.t('transfers.requests.third-party.empty.link', {
              faqUrl: this.zendeskLocalization.getLocalizedArticle(4359616),
            })}
            target="_blank"
            rel="noopener noreferrer"
            class="body-link"
          >${this.intl.t('transfers.requests.third-party.empty.link-text')}</a>`
      );
      let { legalCountry } = this.organizationManager.organization;

      if (legalCountry === 'DE') {
        // @ts-expect-error
        return this.intl.t('empty-states.requests.subtitle.ebics', { htmlSafe: true, linkText });
      } else {
        return this.intl.t('empty-states.requests.subtitle.third-party', {
          htmlSafe: true,
          // @ts-expect-error
          linkText,
        });
      }
    }

    if (this.canReviewTransferRequests && this.canReviewCardRequests && this.canInviteMembers) {
      return this.intl.t('empty-states.requests.rev-transfer-rev-card-invite.subtitle');
    }

    if (this.canReviewTransferRequests && this.canReviewCardRequests && !this.canInviteMembers) {
      return this.intl.t('empty-states.requests.subtitle.past-reviewed');
    }

    if (this.canCreateTransferRequests && this.canReviewCardRequests) {
      return this.intl.t('empty-states.requests.subtitle.past-reviewed-submitted');
    }

    if (this.canReviewTransferRequests && this.canCreateCardRequests) {
      return this.intl.t('empty-states.requests.subtitle.past-reviewed-submitted');
    }
  }

  get emptyStates() {
    let transitionToRequestLanding = () =>
      this.router.transitionTo('requests.landing', this.organizationManager.organization.slug);
    let transitionToInviteFlow = () => this.flowLinkManager.transitionTo({ name: 'member-invite' });

    // Organizations without the requests feature, but with the ebics feature
    if (this.canReadEbics) {
      return {
        title: this.intl.t('empty-states.requests.title.no-history'),
        subtitle: this.subtitle,
        lottieSrc: this.lottieSrc,
      };
    }

    // Admins and managers with review permissions
    if (this.canReviewTransferRequests && this.canReviewCardRequests && this.canInviteMembers) {
      return {
        title: this.intl.t('empty-states.requests.title.no-history'),
        subtitle: this.subtitle,
        button: {
          label: this.intl.t('empty-states.requests.cta.invite-members'),
          callback: transitionToInviteFlow,
        },
        lottieSrc: this.lottieSrc,
      };
    }
    // Managers with review permissions but without invite permissions
    if (this.canReviewTransferRequests && this.canReviewCardRequests && !this.canInviteMembers) {
      return {
        title: this.intl.t('empty-states.requests.title.no-history'),
        subtitle: this.subtitle,
        lottieSrc: this.lottieSrc,
      };
    }
    // Managers with review card request, create transfer request and invite permissions
    if (this.canCreateTransferRequests && this.canReviewCardRequests && this.canInviteMembers) {
      return {
        title: this.intl.t('empty-states.requests.title.no-history'),
        subtitle: this.subtitle,
        button: {
          label: this.intl.t('empty-states.requests.cta.invite-members'),
          callback: transitionToInviteFlow,
        },
        secondaryButton: {
          label: this.intl.t('empty-states.requests.cta.make-request'),
          callback: transitionToRequestLanding,
        },
        lottieSrc: this.lottieSrc,
      };
    }
    // Managers with review transfer request, create card request and invite permissions
    if (this.canReviewTransferRequests && this.canCreateCardRequests && this.canInviteMembers) {
      return {
        title: this.intl.t('empty-states.requests.title.no-history'),
        subtitle: this.subtitle,
        button: {
          label: this.intl.t('empty-states.requests.cta.invite-members'),
          callback: transitionToInviteFlow,
        },
        secondaryButton: {
          label: this.intl.t('empty-states.requests.cta.make-request'),
          callback: transitionToRequestLanding,
        },
        lottieSrc: this.lottieSrc,
      };
    }
    // Managers with review card request and create transfer request permissions but without invite permissions
    if (this.canCreateTransferRequests && this.canReviewCardRequests && !this.canInviteMembers) {
      return {
        title: this.intl.t('empty-states.requests.title.no-history'),
        subtitle: this.subtitle,
        button: {
          label: this.intl.t('empty-states.requests.cta.make-request'),
          callback: transitionToRequestLanding,
        },
        lottieSrc: this.lottieSrc,
      };
    }
    // Managers with review transfer request and create card request permissions but without invite permissions
    if (this.canReviewTransferRequests && this.canCreateCardRequests && !this.canInviteMembers) {
      return {
        title: this.intl.t('empty-states.requests.title.no-history'),
        subtitle: this.subtitle,
        button: {
          label: this.intl.t('empty-states.requests.cta.make-request'),
          callback: transitionToRequestLanding,
        },
        lottieSrc: this.lottieSrc,
      };
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Requests::Providers::Past::EmptyStates': typeof RequestsProvidersPastEmptyStates;
  }
}
