export default {
  "step-container": "Wd",
  "content": "WI",
  "section": "Wt",
  "section-content": "WM",
  "section-content-text": "WP",
  "section-illustration": "Wl",
  "close-form-cta": "WX",
  "bank-account-select": "WC"
};
