import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { PAYMENT_STATUSES } from 'qonto/constants/card-acquirer';
import { LAYOUT } from 'qonto/constants/empty-states/system';
import { ORGA_STATUS } from 'qonto/constants/organization';
import { Sidebar } from 'qonto/react/cards/components/card-acquirer/payments/sidebar';

export default class CardAcquirerPaymentsController extends Controller {
  sidebar = Sidebar;

  @service intl;
  @service organizationManager;
  @service segment;

  queryParams = ['page', 'per_page', 'status'];

  @tracked page = 1;
  @tracked per_page = 25;
  @tracked status = '';
  @tracked selectedPayment = null;

  get payments() {
    return this.model.fetchPaymentsTask.lastSuccessful?.value.payments || [];
  }

  get meta() {
    return this.model.fetchPaymentsTask.lastSuccessful?.value.meta || {};
  }

  get shouldShowLoadingState() {
    return this.model.fetchPaymentsTask.isRunning;
  }

  get shouldShowErrorState() {
    return this.model.fetchPaymentsTask.last.isError;
  }

  get hasActiveFilters() {
    return Boolean(this.status);
  }

  get shouldShowEmptyState() {
    if (this.shouldShowLoadingState || this.shouldShowErrorState || this.hasActiveFilters) {
      return false;
    }
    return this.payments.length === 0;
  }

  get emptyStateOptions() {
    let isOrganizationDeactivated =
      this.organizationManager.organization.status === ORGA_STATUS.DEACTIVATED;
    return {
      layout: LAYOUT.INFORM,
      title: isOrganizationDeactivated
        ? this.intl.t('tap-to-pay.payments.empty-state.closed-account.title')
        : this.intl.t('tap-to-pay.payments.empty-state.default.title'),
      subtitle: isOrganizationDeactivated
        ? this.intl.t('tap-to-pay.payments.empty-state.closed-account.subtitle')
        : this.intl.t('tap-to-pay.payments.empty-state.default.subtitle'),
      lottieSrc: '/lotties/empty-state/no-tap-to-pay.json',
    };
  }

  get statusFilters() {
    let { COMPLETED, DECLINED, PROCESSING } = PAYMENT_STATUSES;
    return [
      { label: this.intl.t('tap-to-pay.payments.status.processing'), value: PROCESSING },
      { label: this.intl.t('tap-to-pay.payments.status.completed'), value: COMPLETED },
      { label: this.intl.t('tap-to-pay.payments.status.declined'), value: DECLINED },
    ];
  }

  get statusValue() {
    return this.status && this.statusFilters.find(status => status.value === this.status);
  }

  get hasSelectedPayment() {
    return Boolean(this.selectedPayment);
  }

  @action changePage(page) {
    this.page = page;
  }

  @action changePerPage(perPage) {
    this.per_page = perPage;
    this.page = 1;
  }

  @action
  resetQueryParams() {
    this.page = 1;
    this.per_page = 25;
  }

  @action
  handleStatusSelect(selectedStatus) {
    if (selectedStatus) {
      this.status = selectedStatus.value;
      this.segment.track('acceptance-list_status_filter_clicked', {
        status: selectedStatus.value,
      });
    } else {
      this.status = '';
    }
  }

  @action
  highlightItem(selectedPaymentId) {
    this.selectedPayment = this.payments.find(payment => payment.id === selectedPaymentId);
  }

  @action
  closeSidebar() {
    this.selectedPayment = null;
  }
}
