export default {
  "connection-sidebar-header": "VD",
  "title": "VV",
  "close": "VL",
  "api-client": "VT",
  "api-client-avatar": "Vz",
  "api-client-name": "Va",
  "disclaimer": "VH",
  "expired": "VO",
  "connect-button": "Vm"
};
