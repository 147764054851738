export default {
  "header": "cfb",
  "header-type": "cfy",
  "close": "cfU",
  "close-icon": "cfj",
  "header-infos": "cfW",
  "title": "cfu body-1",
  "declined": "cKS",
  "canceled": "cKc",
  "approved": "cKq",
  "pending": "cKZ",
  "date": "cKR body-2",
  "mileage-icon": "cKQ",
  "distance-subtitle": "cKe body-2",
  "link-icon": "cKB",
  "container": "cKE",
  "picto": "cKd",
  "general": "cKI",
  "amount": "cKt",
  "calculation-container": "cKM",
  "counterparty": "cKP body-2",
  "infobox": "cKl"
};
