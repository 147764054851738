/* import __COLOCATED_TEMPLATE__ from './fraud-alert.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

import { TopBanner } from '@repo/design-system-kit';

import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';

interface TopbarFraudAlertSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class TopbarFraudAlertComponent extends Component<TopbarFraudAlertSignature> {
  topBanner = TopBanner;

  @action
  close() {
    // @ts-expect-error
    safeLocalStorage.setItem('top_banner_fraud_alert', true);

    // @ts-expect-error
    this.args.close();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Topbar::FraudAlert': typeof TopbarFraudAlertComponent;
  }
}
