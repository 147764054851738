export default {
  "label-select": "qEK",
  "puce": "qEG",
  "puce-wrapper": "qEr",
  "puce-container": "qEb",
  "readonly": "qEy",
  "label-display": "qEU",
  "label-list-name": "qEj body-1",
  "label-list-value": "qEW body-2"
};
