/* import __COLOCATED_TEMPLATE__ from './additional-settings.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Disclaimer } from '@repo/design-system-kit';

interface FlowsDirectDebitCollectionsSubscriptionAdditionalSettingsSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsDirectDebitCollectionsSubscriptionAdditionalSettingsComponent extends Component<FlowsDirectDebitCollectionsSubscriptionAdditionalSettingsSignature> {
  disclaimerInline = Disclaimer.Inline;

  @service declare attachmentsManager: Services['attachmentsManager'];
  @service declare modals: Services['modals'];
  @service declare segment: Services['segment'];

  @tracked isAttachmentActive = Boolean(this.subscription.attachments?.length);

  get subscription() {
    // @ts-expect-error
    return this.args.context.directDebitSubscription;
  }

  get isActive() {
    return this.isAttachmentActive || this.subscription.savedAttachments.length;
  }

  @action
  toggleAttachment() {
    this.isAttachmentActive = !this.isAttachmentActive;

    if (!this.isAttachmentActive && this.subscription.attachments.content) {
      this.subscription.attachments.content = [];
    }
  }

  // @ts-expect-error
  @action addAttachment(file) {
    this.attachmentsManager.addAttachment(false, this.subscription, file);
  }

  // @ts-expect-error
  @action removeAttachment(file) {
    this.attachmentsManager.removeAttachment(false, this.subscription, file);
  }

  // @ts-expect-error
  @action cancelUploadAttachment(file) {
    this.attachmentsManager.cancelUploadAttachment(this.subscription, file);
  }

  // @ts-expect-error
  @action showAttachment(selectedFile) {
    this.modals.open('file-preview-modal', {
      isFullScreenModal: true,
      deletable: false,
      selectedFile,
      model: this.subscription,
      download: this.attachmentsManager.downloadAttachment,
    });
  }

  @action
  onCTAClick() {
    // @ts-expect-error
    this.args.transitionToNext();

    let { notifyClient, isOneOff, attachment } = this.subscription;

    this.segment.track('incoming-direct-debit-flow_additional-options_submitted', {
      notify_client: notifyClient,
      collection_type: isOneOff ? 'one_off' : 'recurring',
      attachment: Boolean(attachment),
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::DirectDebitCollections::Subscription::AdditionalSettings': typeof FlowsDirectDebitCollectionsSubscriptionAdditionalSettingsComponent;
  }
}
