export default {
  "wrapper": "HP",
  "header": "Hl",
  "status": "HX",
  "close-button": "HC",
  "slug": "Hk",
  "category": "HJ",
  "category-title": "Hs",
  "activity-tag-select": "HF",
  "footer": "Hg"
};
