import Model, { attr, belongsTo } from '@ember-data/model';
import { service, type Registry as Services } from '@ember/service';

// @ts-expect-error
import { ACTIVITY_TAGS } from 'qonto/constants/categories';
import {
  DIRECT_DEBIT_COLLECTION_STATUS,
  DIRECT_DEBIT_COLLECTION_STATUS_LEVELS,
  DIRECT_DEBIT_COLLECTION_STATUS_REASON,
  DIRECT_DEBIT_COLLECTION_TYPES,
  // @ts-expect-error
} from 'qonto/constants/direct-debit-collections';

// @ts-expect-error
export const getStatusLabel = (value, intl) => {
  let statusMap = {
    [DIRECT_DEBIT_COLLECTION_STATUS.COMPLETED]: 'completed',
    [DIRECT_DEBIT_COLLECTION_STATUS.CANCELED]: 'canceled',
    [DIRECT_DEBIT_COLLECTION_STATUS.DECLINED]: 'declined',
    [DIRECT_DEBIT_COLLECTION_STATUS.REFUNDED]: 'refunded',
    [DIRECT_DEBIT_COLLECTION_STATUS.ON_HOLD]: 'on-hold',
  };

  if (!statusMap[value]) {
    return '';
  }

  return intl.t(`sdd-collections.status.${statusMap[value]}`);
};

export default class DirectDebitCollectionModel extends Model {
  @service declare intl: Services['intl'];

  // @ts-expect-error
  @attr() amount;
  @attr('string') declare debitorIban: string;
  @attr('string') declare debitorName: string;
  @attr('string') declare debitorBic: string;
  @attr('string') declare reference: string;
  @attr('date') declare collectionDate: Date;
  @attr('date') declare executedAt: Date;
  @attr('string') declare slug: string;
  @attr('string', { defaultValue: DIRECT_DEBIT_COLLECTION_STATUS.COMPLETED })
  declare status: string;
  @attr('string') declare statusReason: string;
  @attr('string', { defaultValue: DIRECT_DEBIT_COLLECTION_TYPES.CORE }) declare type: string;
  @attr('string') declare uniqueMandateReference: string;
  @attr('string', { defaultValue: ACTIVITY_TAGS.OTHER_INCOME }) declare activityTag: string;
  @attr('string') declare rTransactionType: string;
  @attr('date') declare rTransactionExecutedAt: Date;
  @attr('string') declare originalTransactionId: string;
  // @ts-expect-error
  @attr() onHoldAmount;
  @attr('string') declare onHoldRate: string;
  @attr('string') declare onHoldReleaseDate: string;

  // @ts-expect-error
  @belongsTo('bankAccount', { async: false, inverse: null }) bankAccount;
  // @ts-expect-error
  @belongsTo('organization', { async: false, inverse: null }) organization;
  // @ts-expect-error
  @belongsTo('membership', { async: false, inverse: null }) initiator;
  // @ts-expect-error
  @belongsTo('transaction', { async: false, inverse: null }) transaction;
  // @ts-expect-error
  @belongsTo('transaction', { async: false, inverse: null }) rTransaction;

  get isStatusReasonAmountLimitReached() {
    return this.statusReason === DIRECT_DEBIT_COLLECTION_STATUS_REASON.AMOUNT_LIMIT_REACHED;
  }

  get isStatusReasonEligibilityLost() {
    return this.statusReason === DIRECT_DEBIT_COLLECTION_STATUS_REASON.ELIGIBILITY_LOST;
  }

  get isCanceled() {
    return this.status === DIRECT_DEBIT_COLLECTION_STATUS.CANCELED;
  }

  get isDeclined() {
    return this.status === DIRECT_DEBIT_COLLECTION_STATUS.DECLINED;
  }

  get isCompleted() {
    return this.status === DIRECT_DEBIT_COLLECTION_STATUS.COMPLETED;
  }

  get isRefunded() {
    return this.status === DIRECT_DEBIT_COLLECTION_STATUS.REFUNDED;
  }

  get isOnHold() {
    return this.status === DIRECT_DEBIT_COLLECTION_STATUS.ON_HOLD;
  }

  get statusDisplayInfo() {
    return {
      title: getStatusLabel(this.status, this.intl),
      level: DIRECT_DEBIT_COLLECTION_STATUS_LEVELS[this.status],
    };
  }

  get avatarInfo() {
    let avatarStatusIconSrc = null;
    let activityTagSVG = `/icon/category/${this.activityTag}-m.svg`;

    if (this.isCanceled || this.isDeclined || this.isRefunded) {
      avatarStatusIconSrc = 'status_cancelled';
    } else {
      avatarStatusIconSrc = 'status_approved';
    }

    return {
      mediumLogo: activityTagSVG,
      smallLogo: activityTagSVG,
      icon: avatarStatusIconSrc,
    };
  }

  get statusFullText() {
    switch (this.status) {
      case DIRECT_DEBIT_COLLECTION_STATUS.DECLINED: {
        return this.intl.t('sdd-collections.status.declined-full');
      }
      case DIRECT_DEBIT_COLLECTION_STATUS.CANCELED: {
        return this.intl.t('sdd-collections.status.canceled-full');
      }
      case DIRECT_DEBIT_COLLECTION_STATUS.COMPLETED: {
        return this.intl.t('sdd-collections.status.completed-full');
      }

      case DIRECT_DEBIT_COLLECTION_STATUS.REFUNDED: {
        return this.intl.t('sdd-collections.status.refunded-full');
      }

      case DIRECT_DEBIT_COLLECTION_STATUS.ON_HOLD: {
        return this.intl.t('sdd-collections.status.on-hold-full');
      }
    }
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'direct-debit-collection': DirectDebitCollectionModel;
  }
}
