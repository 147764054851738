export default {
  "container": "cPi",
  "header": "cPA",
  "content": "cPY",
  "info-list": "cPh",
  "info-label": "cPf body-2",
  "info-value": "cPK body-2",
  "provider": "cPG",
  "policy-container": "cPr",
  "policy-label": "cPb body-2",
  "policy-number": "cPy body-2",
  "copy-to-clipboard": "cPU",
  "button": "cPj btn btn--primary btn--stretch btn--large"
};
