/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { TrackedArray } from 'tracked-built-ins';

interface FlowsCardChargebackUploadLayoutSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsCardChargebackUploadLayoutComponent extends Component<FlowsCardChargebackUploadLayoutSignature> {
  @service declare intl: Services['intl'];
  @service declare segment: Services['segment'];

  errors = new TrackedArray();

  get files() {
    // @ts-expect-error
    let { context, uploadStepIndex } = this.args;
    return context.files[uploadStepIndex];
  }

  @action
  onFileSubmission() {
    if (this.files.length === 0) {
      this.errors.length = 0;

      return this.errors.push({
        message: this.intl.t('dispute-flow.documents-upload.steps.error'),
      });
    }

    this.continue();
  }

  continue() {
    this.segment.track('cards-chargeback_document-upload-continue-cta_clicked', {
      // @ts-expect-error
      type: this.args.trackingType,
    });
    // @ts-expect-error
    this.args.transitionToNext();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::ChargebackClaim::UploadLayout::Index': typeof FlowsCardChargebackUploadLayoutComponent;
  }
}
