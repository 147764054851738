import { type ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { NumberField } from '@repo/design-system-kit';
import { PayableAmountTooltip } from '../payable-amount-tooltip';
import type { PayableAmountTooltipProps } from '../payable-amount-tooltip/payable-amount-tooltip';
import styles from './payable-amount-field.strict-module.css';

export interface PayableAmountFieldProps extends PayableAmountTooltipProps {
  payableAmount: string;
  payableAmountForNumberField: string;
  isInvoicePaid: boolean;
}

export function PayableAmountField({
  creditNotesAmount,
  creditNotesCount,
  payableAmount,
  payableAmountForNumberField,
  isInvoicePaid,
  totalAmount,
}: PayableAmountFieldProps): ReactNode {
  const { formatMessage } = useIntl();
  const labelText = isInvoicePaid
    ? formatMessage({ id: 'supplier-invoices.edit.modal.paid-amount.label' })
    : formatMessage({ id: 'supplier-invoices.edit.modal.payable-amount.label' });

  return (
    <div className={styles.container} data-testid="payable-amount-and-tooltip">
      <NumberField
        className="mb-16"
        isReadOnly
        label={labelText}
        placeholder="0.00"
        value={payableAmountForNumberField}
      />
      <div className={styles.tooltip}>
        <PayableAmountTooltip
          creditNotesAmount={creditNotesAmount}
          creditNotesCount={creditNotesCount}
          isInvoicePaid={isInvoicePaid}
          payableAmount={payableAmount}
          shouldAddMargin
          totalAmount={totalAmount}
        />
      </div>
    </div>
  );
}
