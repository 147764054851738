export default {
  "container": "uM",
  "content": "uP",
  "buttons": "ul",
  "genders": "uX",
  "birth-details": "uC",
  "has-error": "uk",
  "gender-option": "uJ",
  "radio-button": "us"
};
