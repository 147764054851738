/* import __COLOCATED_TEMPLATE__ from './main-menu.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

interface DualNavMainMenuSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLUListElement;
}

export default class DualNavMainMenuComponent extends Component<DualNavMainMenuSignature> {
  @service declare abilities: Services['abilities'];
  @service declare featuresManager: Services['featuresManager'];

  get lastTopItemId() {
    // @ts-expect-error
    let { items } = this.args;

    // @ts-expect-error
    return items.filter(item => item.position === 'top').at(-1)?.id;
  }

  get displayQontoPilot() {
    let isQontoPilotFeatureEnabled = this.featuresManager.isEnabled('qontoPilot');
    let canUseQontoPilot = this.abilities.can('use qonto-pilot');

    return isQontoPilotFeatureEnabled && canUseQontoPilot;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'DualNav::MainMenu': typeof DualNavMainMenuComponent;
  }
}
