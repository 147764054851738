import Controller from '@ember/controller';
import { service } from '@ember/service';

import { DEFAULT_FLOW_QUERY_PARAMS } from 'qonto/constants/query-params/flow';

export default class FlowsController extends Controller {
  @service flow;
  queryParams = [
    {
      addon_recurrence: {
        scope: 'controller',
      },
      amount: {
        scope: 'controller',
      },
      cardLevel: {
        scope: 'controller',
      },
      companyType: {
        scope: 'controller',
      },
      holderId: {
        scope: 'controller',
      },
      id: {
        scope: 'controller',
      },
      invoiceId: {
        scope: 'controller',
      },
      lineup: {
        scope: 'controller',
      },
      memberId: {
        scope: 'controller',
      },
      origin: {
        scope: 'controller',
      },
      quoteId: {
        scope: 'controller',
      },
      recurrence: {
        scope: 'controller',
      },
      reference: {
        scope: 'controller',
      },
      repeatedTransferId: {
        scope: 'controller',
      },
      applicationSlug: {
        scope: 'controller',
      },
      suggested: {
        scope: 'controller',
      },
      supplierInvoiceId: {
        scope: 'controller',
      },
      taxBeneficiaryName: {
        scope: 'controller',
      },
      taxModel: {
        scope: 'controller',
      },
      transferId: {
        scope: 'controller',
      },
      targetCurrency: {
        scope: 'controller',
      },
    },
  ];

  recurrence = DEFAULT_FLOW_QUERY_PARAMS.recurrence;
}
