/* import __COLOCATED_TEMPLATE__ from './item.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { DATE_FORMAT_TOKENS, dateToken } from '@qonto/ui-kit/utils/date-token';
import { BadgeStatus } from '@repo/design-system-kit';

import {
  getInsuranceContractPaymentFrequency,
  getInsuranceContractStatusProps,
  // @ts-expect-error
} from 'qonto/utils/insurance-hub/insurance-contract';

interface InsuranceHubTableItemSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLTableRowElement;
}

export default class InsuranceHubTableItemComponent extends Component<InsuranceHubTableItemSignature> {
  badgeStatus = BadgeStatus;

  @service declare intl: Services['intl'];
  @service declare localeManager: Services['localeManager'];

  get paymentFrequency() {
    // @ts-expect-error
    let { paymentFrequency } = this.args.item;

    return getInsuranceContractPaymentFrequency({
      intl: this.intl,
      frequency: paymentFrequency,
    });
  }

  get insuranceContractStatusProps() {
    // @ts-expect-error
    let { status } = this.args.item;

    return getInsuranceContractStatusProps({ intl: this.intl, status });
  }

  get renewalDate() {
    // @ts-expect-error
    let { isExpired, renewalDate } = this.args.item;

    return isExpired || !renewalDate
      ? '-'
      : dateToken({
          date: renewalDate,
          token: DATE_FORMAT_TOKENS.DATE_YEAR_S,
          // @ts-expect-error
          locale: this.localeManager.locale,
        });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InsuranceHub::Table::Item': typeof InsuranceHubTableItemComponent;
  }
}
