/* import __COLOCATED_TEMPLATE__ from './biller-disclaimer.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { Disclaimer } from '@repo/design-system-kit';

import { SUBSCRIPTION_RECURRENCES } from 'qonto/constants/subscriptions';
// @ts-expect-error
import { TRANSACTION_FEE_OPERATION_SUBTYPES } from 'qonto/constants/transactions';

interface TransactionsSidebarBillerDisclaimerSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLElement;
}

export default class TransactionsSidebarBillerDisclaimerComponent extends Component<TransactionsSidebarBillerDisclaimerSignature> {
  disclaimerInline = Disclaimer.Inline;

  @service declare intl: Services['intl'];
  @service declare organizationManager: Services['organizationManager'];

  // @ts-expect-error
  additionalUserCopy = (isRecurring, quantity) => {
    if (!quantity)
      return this.intl.t('transactions.sidebar.subscription_details.disclaimer.additional_user');
    if (!isRecurring)
      return this.intl.t(
        'transactions.sidebar.subscription_details.disclaimer.additional_user_creation'
      );
    return this.intl.t('transactions.sidebar.subscription_details.disclaimer.additional_user_fee', {
      extraMembers: quantity,
      number: quantity,
    });
  };

  // @ts-expect-error
  addonCopy = (addonName, recurrence) => {
    if (!recurrence)
      return this.intl.t(
        'transactions.sidebar.subscription_details.disclaimer.addon_billing_recurrence_fallback'
      );

    let recurrenceCopy;

    switch (recurrence) {
      case SUBSCRIPTION_RECURRENCES.MONTHLY:
        recurrenceCopy = this.intl.t('transaction.fees.addon-monthly');
        break;
      case SUBSCRIPTION_RECURRENCES.ANNUAL:
        recurrenceCopy = this.intl.t('transaction.fees.addon-annually');
        break;
    }

    let addonNameCopy;

    switch (addonName) {
      case TRANSACTION_FEE_OPERATION_SUBTYPES.ACCOUNTS_PAYABLE:
        addonNameCopy = this.intl.t('transactions.fee.accounts-payable');
        break;
      case TRANSACTION_FEE_OPERATION_SUBTYPES.ACCOUNTS_RECEIVABLE:
        addonNameCopy = this.intl.t('transactions.fee.accounts-receivable');
        break;
      case TRANSACTION_FEE_OPERATION_SUBTYPES.EXPENSE_SPEND_MANAGEMENT:
        addonNameCopy = this.intl.t('transactions.fee.expense-spend-management');
        break;
    }

    return this.intl.t(
      'transactions.sidebar.subscription_details.disclaimer.addon_billing_recurrence',
      {
        addonName: addonNameCopy,
        recurrence: recurrenceCopy,
      }
    );
  };

  // @ts-expect-error
  physicalCardCopy = (isRecurring, quantity) => {
    if (!quantity)
      return this.intl.t('transactions.sidebar.subscription_details.disclaimer.physical_card');
    if (!isRecurring)
      return this.intl.t(
        'transactions.sidebar.subscription_details.disclaimer.physical_card_creation'
      );
    return this.intl.t('transactions.sidebar.subscription_details.disclaimer.physical_card_fee', {
      physicalOneCards: quantity,
      number: quantity,
    });
  };

  // @ts-expect-error
  physicalCardPlusCopy = (isRecurring, quantity) => {
    if (!quantity)
      return this.intl.t('transactions.sidebar.subscription_details.disclaimer.physical_card_plus');
    if (!isRecurring)
      return this.intl.t(
        'transactions.sidebar.subscription_details.disclaimer.physical_card_plus_creation'
      );
    return this.intl.t(
      'transactions.sidebar.subscription_details.disclaimer.physical_card_plus_fee',
      {
        physicalPlusCards: quantity,
        number: quantity,
      }
    );
  };

  // @ts-expect-error
  physicalCardMetalCopy = (isRecurring, quantity) => {
    if (!quantity)
      return this.intl.t(
        'transactions.sidebar.subscription_details.disclaimer.physical_card_metal'
      );
    if (!isRecurring)
      return this.intl.t(
        'transactions.sidebar.subscription_details.disclaimer.physical_card_metal_creation'
      );
    return this.intl.t(
      'transactions.sidebar.subscription_details.disclaimer.physical_card_metal_fee',
      {
        physicalXCards: quantity,
        number: quantity,
      }
    );
  };

  // @ts-expect-error
  virtualCardCopy = (isRecurring, quantity) => {
    if (!quantity)
      return this.intl.t('transactions.sidebar.subscription_details.disclaimer.virtual_card');
    if (!isRecurring)
      return this.intl.t(
        'transactions.sidebar.subscription_details.disclaimer.virtual_card_creation'
      );
    return this.intl.t('transactions.sidebar.subscription_details.disclaimer.virtual_card_fee', {
      virtualCards: quantity,
      number: quantity,
    });
  };

  // @ts-expect-error
  subscriptionCopy = (recurrence, planName) => {
    if (recurrence && planName) {
      return this.intl.t('transactions.sidebar.subscription_details.disclaimer.subscription_fee', {
        recurrence,
        planName,
      });
    }
    return this.intl.t('transactions.sidebar.subscription_details.disclaimer.subscription');
  };

  // @ts-expect-error
  feeTypeTranslation = (isRecurring, quantity, recurrence, pricePlanName) => {
    return {
      account_closed: this.intl.t(
        'transactions.sidebar.subscription_details.disclaimer.account_closed'
      ),
      additional_user: this.additionalUserCopy(isRecurring, quantity),
      atm: this.intl.t('transactions.sidebar.subscription_details.disclaimer.atm'),
      atm_metal: this.intl.t('transactions.sidebar.subscription_details.disclaimer.atm_metal'),
      atm_plus: this.intl.t('transactions.sidebar.subscription_details.disclaimer.atm_plus'),
      capital_increase: this.intl.t(
        'transactions.sidebar.subscription_details.disclaimer.capital_increase'
      ),
      card_acquirer_payout: this.intl.t(
        'transactions.sidebar.subscription_details.disclaimer.card_acquirer_payout'
      ),
      cheque: this.intl.t('transactions.sidebar.subscription_details.disclaimer.cheque'),

      deposit_interest: this.intl.t(
        'transactions.sidebar.subscription_details.disclaimer.deposit_interest'
      ),
      eckert_law: this.intl.t('transactions.sidebar.subscription_details.disclaimer.eckert_law'),
      fraud: this.intl.t('transactions.sidebar.subscription_details.disclaimer.fraud'),
      fx_card: this.intl.t('transactions.sidebar.subscription_details.disclaimer.fx_card'),
      fx_card_metal: this.intl.t(
        'transactions.sidebar.subscription_details.disclaimer.fx_card_metal'
      ),
      fx_card_plus: this.intl.t(
        'transactions.sidebar.subscription_details.disclaimer.fx_card_plus'
      ),
      fx_inbound_transfer: this.intl.t(
        'transactions.sidebar.subscription_details.disclaimer.fx_inbound_transfer'
      ),
      fx_outbound_transfer: this.intl.t(
        'transactions.sidebar.subscription_details.disclaimer.fx_outbound_transfer'
      ),
      fx_refund: this.intl.t('transactions.sidebar.subscription_details.disclaimer.fx_refund'),
      judicial_requisition: this.intl.t(
        'transactions.sidebar.subscription_details.disclaimer.judicial_requisition'
      ),
      other: this.intl.t('transactions.sidebar.subscription_details.disclaimer.other'),
      pagopa: this.intl.t('transactions.sidebar.subscription_details.disclaimer.pagopa'),
      physical_card: this.physicalCardCopy(isRecurring, quantity),
      physical_card_delivery: this.intl.t(
        'transactions.sidebar.subscription_details.disclaimer.physical_card_delivery'
      ),
      physical_card_metal: this.physicalCardMetalCopy(isRecurring, quantity),
      physical_card_plus: this.physicalCardPlusCopy(isRecurring, quantity),
      rejected: this.intl.t('transactions.sidebar.subscription_details.disclaimer.rejected'),
      rejected_debit: this.intl.t(
        'transactions.sidebar.subscription_details.disclaimer.rejected_debit'
      ),
      rejected_fx_transfer: this.intl.t(
        'transactions.sidebar.subscription_details.disclaimer.rejected_fx_transfer'
      ),
      rejected_transfer: this.intl.t(
        'transactions.sidebar.subscription_details.disclaimer.rejected_transfer'
      ),
      returned_cheque: this.intl.t(
        'transactions.sidebar.subscription_details.disclaimer.returned_cheque'
      ),
      satd: this.intl.t('transactions.sidebar.subscription_details.disclaimer.satd'),
      sci_number: this.intl.t('transactions.sidebar.subscription_details.disclaimer.sci_number'),
      sci_number_obtention: this.intl.t(
        'transactions.sidebar.subscription_details.disclaimer.sci_number_obtention'
      ),
      seizure_foreclosure: this.intl.t(
        'transactions.sidebar.subscription_details.disclaimer.seizure_foreclosure'
      ),
      sepa_inbound: this.intl.t(
        'transactions.sidebar.subscription_details.disclaimer.sepa_inbound'
      ),
      sepa_inbound_transfer: this.intl.t(
        'transactions.sidebar.subscription_details.disclaimer.sepa_inbound_transfer'
      ),
      sepa_outbound_transfer: this.intl.t(
        'transactions.sidebar.subscription_details.disclaimer.sepa_outbound_transfer'
      ),
      shortfall: this.intl.t('transactions.sidebar.subscription_details.disclaimer.shortfall'),
      subscription: this.subscriptionCopy(recurrence, pricePlanName),
      swift_income: this.intl.t(
        'transactions.sidebar.subscription_details.disclaimer.swift_income'
      ),
      third_party_notice: this.intl.t(
        'transactions.sidebar.subscription_details.disclaimer.third_party_notice'
      ),
      unpaid_cheque: this.intl.t(
        'transactions.sidebar.subscription_details.disclaimer.unpaid_cheque'
      ),
      virtual_card: this.virtualCardCopy(isRecurring, quantity),
      [TRANSACTION_FEE_OPERATION_SUBTYPES.DIRECT_DEBIT_COLLECTION_SEND]: this.intl.t(
        'transactions.sidebar.subscription_details.disclaimer.direct_debit_collection_send'
      ),
      [TRANSACTION_FEE_OPERATION_SUBTYPES.DIRECT_DEBIT_COLLECTION_RETURN]: this.intl.t(
        'transactions.sidebar.subscription_details.disclaimer.direct_debit_collection_return'
      ),
      [TRANSACTION_FEE_OPERATION_SUBTYPES.DIRECT_DEBIT_COLLECTION_REFUND]: this.intl.t(
        'transactions.sidebar.subscription_details.disclaimer.direct_debit_collection_refund'
      ),
      [TRANSACTION_FEE_OPERATION_SUBTYPES.DIRECT_DEBIT_COLLECTION_REFUND_LATE]: this.intl.t(
        'transactions.sidebar.subscription_details.disclaimer.direct_debit_collection_refund_late'
      ),
      [TRANSACTION_FEE_OPERATION_SUBTYPES.FX_OUTBOUND_TRANSFER_LOCAL_NON_STANDARD_CURRENCY]:
        this.intl.t(
          'transactions.sidebar.subscription_details.disclaimer.fx_outbound_transfer_local'
        ),
      [TRANSACTION_FEE_OPERATION_SUBTYPES.FX_OUTBOUND_TRANSFER_LOCAL_STANDARD_CURRENCY]:
        this.intl.t(
          'transactions.sidebar.subscription_details.disclaimer.fx_outbound_transfer_local'
        ),
      [TRANSACTION_FEE_OPERATION_SUBTYPES.FX_OUTBOUND_TRANSFER_SWIFT_OUR]: this.intl.t(
        'transactions.sidebar.subscription_details.disclaimer.fx_outbound_transfer_swift_our'
      ),
      [TRANSACTION_FEE_OPERATION_SUBTYPES.FX_OUTBOUND_TRANSFER_SWIFT_SHA]: this.intl.t(
        'transactions.sidebar.subscription_details.disclaimer.fx_outbound_transfer_swift_sha'
      ),
      [TRANSACTION_FEE_OPERATION_SUBTYPES.ACCOUNTS_PAYABLE]: this.addonCopy(
        TRANSACTION_FEE_OPERATION_SUBTYPES.ACCOUNTS_PAYABLE,
        recurrence
      ),
      [TRANSACTION_FEE_OPERATION_SUBTYPES.ACCOUNTS_RECEIVABLE]: this.addonCopy(
        TRANSACTION_FEE_OPERATION_SUBTYPES.ACCOUNTS_RECEIVABLE,
        recurrence
      ),
      [TRANSACTION_FEE_OPERATION_SUBTYPES.EXPENSE_SPEND_MANAGEMENT]: this.addonCopy(
        TRANSACTION_FEE_OPERATION_SUBTYPES.EXPENSE_SPEND_MANAGEMENT,
        recurrence
      ),
    };
  };

  get billerDisclaimer() {
    // @ts-expect-error
    let { subject: walletToWallet, isFee, isCreditNote } = this.args.transaction;
    let feeType = walletToWallet.get('feeType');

    if (isFee) {
      let enrichmentData = walletToWallet.get('enrichmentData');

      let isRecurring = enrichmentData?.isRecurring;
      let quantity = enrichmentData?.quantity;
      let recurrence = enrichmentData?.recurrence;
      let pricePlanName = enrichmentData?.pricePlanName;
      let translations = this.feeTypeTranslation(isRecurring, quantity, recurrence, pricePlanName);

      return translations[feeType] ?? translations['other'];
    }
    if (isCreditNote) {
      return this.intl.t('transactions.sidebar.subscription_details.disclaimer.credit_note');
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transactions::Sidebar::Details::BillerDisclaimer': typeof TransactionsSidebarBillerDisclaimerComponent;
  }
}
