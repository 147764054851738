/* import __COLOCATED_TEMPLATE__ from './periodic-update-banner.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

interface PeriodicUpdateBannerSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class PeriodicUpdateBanner extends Component<PeriodicUpdateBannerSignature> {
  @service('segment') declare segment: Services['segment'];

  @action
  ctaClicked() {
    this.segment.track('kyc_update_banner_clicked', { action: 'cta' });
    // @ts-expect-error
    this.args.buttonAction?.();
  }

  @action
  close() {
    this.segment.track('kyc_update_banner_clicked', { action: 'close' });
    // @ts-expect-error
    this.args.close();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Discover::PeriodicUpdateBanner': typeof PeriodicUpdateBanner;
  }
}
