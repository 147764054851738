export default {
  "row": "AI",
  "active": "At",
  "cell": "AM",
  "empty": "AP",
  "supplier": "Al",
  "supplier-content": "AX",
  "declined": "AC",
  "total-repayment": "Ak body-1",
  "amount": "AJ",
  "supplier-avatar": "As mr-16",
  "financing-status": "AF body-2",
  "installments": "Ag body-2",
  "overflow-hidden": "AD",
  "ellipsis": "AV",
  "completed-on": "AL",
  "financed-amount": "AT body-2"
};
