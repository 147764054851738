/* import __COLOCATED_TEMPLATE__ from './sidebar.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { DATE_FORMAT_TOKENS } from '@qonto/ui-kit/utils/date-token';
import window from 'ember-window-mock';

// @ts-expect-error
import { NRC_STATUS } from 'qonto/constants/nrc-payments';

interface NrcSidebarSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLElement;
}

export default class NrcSidebarComponent extends Component<NrcSidebarSignature> {
  @service declare intl: Services['intl'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];

  get titleTranslationKey() {
    // @ts-expect-error
    let { payment } = this.args;
    let statusToTranslationKey = {
      [NRC_STATUS.SCHEDULED]: this.intl.t('aeat.sidepanel.header.scheduled'),
      [NRC_STATUS.COMPLETED]: this.intl.t('aeat.sidepanel.header.completed'),
      [NRC_STATUS.DECLINED]: this.intl.t('aeat.sidepanel.header.declined'),
      [NRC_STATUS.CANCELED]: this.intl.t('aeat.sidepanel.header.cancelled'),
    };
    return statusToTranslationKey[payment.status];
  }

  get isTerminatedPayment() {
    return (
      // @ts-expect-error
      this.args.payment.status === NRC_STATUS.DECLINED ||
      // @ts-expect-error
      this.args.payment.status === NRC_STATUS.CANCELED
    );
  }

  get isCompletedPayment() {
    // @ts-expect-error
    return this.args.payment.status === NRC_STATUS.COMPLETED;
  }

  get statusIcon() {
    // @ts-expect-error
    switch (this.args.payment.status) {
      case NRC_STATUS.SCHEDULED:
        return 'status_scheduled-s';
      case NRC_STATUS.DECLINED:
        return 'status_cancelled-s';
      case NRC_STATUS.CANCELED:
        return 'status_cancelled-s';
      default:
        return 'status_check-s';
    }
  }

  get dateToken() {
    // @ts-expect-error
    let { payment } = this.args;
    let statusToDate = {
      [NRC_STATUS.SCHEDULED]: {
        date: payment.executionDate,
        token: DATE_FORMAT_TOKENS.DATE_YEAR_S,
      },
      [NRC_STATUS.COMPLETED]: {
        date: payment.executionDate,
        token: DATE_FORMAT_TOKENS.DATE_TIME_L,
      },
      [NRC_STATUS.DECLINED]: {
        date: payment.executionDate,
        token: DATE_FORMAT_TOKENS.DATE_TIME_L,
      },
      [NRC_STATUS.CANCELED]: { date: payment.canceledAt, token: DATE_FORMAT_TOKENS.DATE_TIME_L },
    };

    return statusToDate[payment.status];
  }

  get modeloDescription() {
    // @ts-expect-error
    let { payment } = this.args;
    return `${this.intl.t('aeat.sidepanel.labels.modelo')} ${payment.taxCode} - ${payment.taxDescription}`;
  }

  get isDeclinedForInsufficientFunds() {
    // @ts-expect-error
    let { payment } = this.args;
    return payment.status === NRC_STATUS.DECLINED && payment.declineReason === 'insufficient_funds';
  }

  @action
  downloadProof() {
    // @ts-expect-error
    let { payment } = this.args;
    try {
      let receiptUrl = payment.get('receiptUrl');
      window.location.href = receiptUrl;
    } catch {
      this.handleError();
    }
  }

  handleError() {
    this.toastFlashMessages.toastError(this.intl.t('toasts.errors.try-later'));
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Nrc::Sidebar': typeof NrcSidebarComponent;
  }
}
