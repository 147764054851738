/* import __COLOCATED_TEMPLATE__ from './item.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

// @ts-expect-error
import { BADGE_TYPE } from '@qonto/ui-kit/constants/badge-types';
import { Badge } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';

import { ignoreCancelation } from 'qonto/utils/ignore-error';

interface FlowsMemberRoleSelectionItemSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsMemberRoleSelectionItemComponent extends Component<FlowsMemberRoleSelectionItemSignature> {
  badgeHighlight = Badge;

  @service declare memberManager: Services['memberManager'];

  @action onClick() {
    this.onClickTask.perform().catch(ignoreCancelation);
  }

  BADGE_TYPE = BADGE_TYPE;

  onClickTask = dropTask(async () => {
    // @ts-expect-error
    let { context, transitionToNext, role, pushFlow } = this.args;
    await this.memberManager.handleSelectRoleTask
      .unlinked()
      // @ts-expect-error
      .perform(context, role, transitionToNext, pushFlow);
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Member::RoleSelection::Item': typeof FlowsMemberRoleSelectionItemComponent;
  }
}
