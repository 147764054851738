/* import __COLOCATED_TEMPLATE__ from './checkmark-list-item.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface CheckmarkListItemSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const CheckmarkListItem = templateOnlyComponent<CheckmarkListItemSignature>();

export default CheckmarkListItem;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    CheckmarkListItem: typeof CheckmarkListItem;
  }
}
