/* import __COLOCATED_TEMPLATE__ from './multiple.hbs'; */
import { action } from '@ember/object';
import { debounce } from '@ember/runloop';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import RSVP from 'rsvp';

import { dropTask } from 'ember-concurrency';

import { MEMBER_STATUS } from 'qonto/constants/membership';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';
// @ts-expect-error
import { sortByKey } from 'qonto/utils/sort-by-keys';

const usersStatusActiveAndRevoked = [MEMBER_STATUS.ACTIVE, MEMBER_STATUS.REVOKED];
const SENTRY_IGNORE_HTTP_STATUSES = [401];

interface MemberSelectMultipleSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class MemberSelectMultiple extends Component<MemberSelectMultipleSignature> {
  @service declare intl: Services['intl'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare store: Services['store'];
  @service declare sentry: Services['sentry'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];

  @tracked filters = {};
  @tracked defaultOptions = [];
  @tracked allOptions = [];

  constructor(owner: unknown, args: MemberSelectMultipleSignature['Args']) {
    super(owner, args);

    this._updateAllOptions();

    // Errors will be caught in the _findMembers function
    this.findMemberTask.perform().catch(ignoreCancelation);
  }

  get selectedMembers() {
    if (this.allOptions.length) {
      return (
        // @ts-expect-error
        this.args.memberIds
          // @ts-expect-error
          .map(memberKey => this.allOptions.find(({ key }) => key === memberKey))
          .filter(Boolean)
      );
    }
  }

  _updateAllOptions() {
    // @ts-expect-error
    this.allOptions = this._peekAllMembers()
      .filter(({ status }) => [MEMBER_STATUS.ACTIVE, MEMBER_STATUS.REVOKED].includes(status))
      .map(member => ({
        key: member.id,
        value: member.fullName,
        url: member.avatarThumb?.fileUrl,
        status: member.status,
      }));
  }

  // @ts-expect-error
  _mapMembers(members) {
    let membersArray = members
      // @ts-expect-error
      .map(({ id }) => this.allOptions.find(({ key }) => key === id))
      .filter(Boolean);

    membersArray = membersArray.sort(sortByKey('value'));

    // @ts-expect-error
    let activeMembers = membersArray.filter(({ status }) => status === MEMBER_STATUS.ACTIVE);
    // @ts-expect-error
    let revokedMembers = membersArray.filter(({ status }) => status === MEMBER_STATUS.REVOKED);

    return [...activeMembers, ...revokedMembers];
  }

  // @ts-expect-error
  async _findMembers(query) {
    // @ts-expect-error
    this.filters['status'] = usersStatusActiveAndRevoked;

    try {
      return await this.organizationManager.findMembers(query, this.filters);
    } catch (error) {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));

      let errorInfo = ErrorInfo.for(error);

      // @ts-expect-error
      if (!SENTRY_IGNORE_HTTP_STATUSES.includes(error.status) && errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      return this._peekAllMembers();
    }
  }

  // @ts-expect-error
  _performMemberSearch(query, resolve, reject) {
    return this._findMembers(query)
      .then(records => {
        this._updateAllOptions();
        let filteredOptions = this._mapMembers(records);
        return resolve(filteredOptions);
      })
      .catch(error => reject(error));
  }

  _peekAllMembers() {
    let organizationId = this.organizationManager.organization.id;
    return this.store
      .peekAll('membership')
      .filter(membership => membership.belongsTo('organization').id() === organizationId);
  }

  @action
  // @ts-expect-error
  handleMemberSearch(query) {
    return new RSVP.Promise((resolve, reject) => {
      debounce(this, this._performMemberSearch, query, resolve, reject, 300);
    });
  }

  findMemberTask = dropTask(async () => {
    // @ts-expect-error
    let members = await this._findMembers();

    this._updateAllOptions();
    // @ts-expect-error
    this.defaultOptions = this._mapMembers(members);
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Member::Select::Multiple': typeof MemberSelectMultiple;
  }
}
