/* import __COLOCATED_TEMPLATE__ from './item.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';

// @ts-expect-error
import { ACCOUNT_TYPES } from 'qonto/constants/beneficiaries';
import { VAT_RATES } from 'qonto/constants/vat';
import { formatIban } from 'qonto/utils/format-input';

interface CounterpartyListItemSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLTableRowElement;
}

export default class CounterpartyListItemComponent extends Component<CounterpartyListItemSignature> {
  @service declare intl: Services['intl'];

  get isVatRateEmpty() {
    // @ts-expect-error
    return isEmpty(this.args.counterparty.vatRate);
  }

  get areCustomLabelsEmpty() {
    // @ts-expect-error
    return isEmpty(this.args.counterparty.labels);
  }

  get accountNumber() {
    // @ts-expect-error
    let { accountNumber, accountType, iban } = this.args.counterparty;
    let isIban = accountType === ACCOUNT_TYPES.IBAN;

    let displayedAccountNumber = isIban ? iban : accountNumber;

    if (displayedAccountNumber.length <= 12) {
      // @ts-expect-error
      return isIban ? formatIban(displayedAccountNumber) : displayedAccountNumber;
    }

    // extract the first 8 and last 4 chars of the iban or account number
    // to then format it with an ellipse in between
    // @ts-expect-error
    return displayedAccountNumber.replace(/^(\S{8})\S*(\S{4})$/, (match, p1, p2) => {
      // @ts-expect-error
      let firstPart = isIban ? formatIban(p1) : p1;
      return `${firstPart} ... ${p2}`;
    });
  }

  get vatRate() {
    // @ts-expect-error
    let { vatRate } = this.args.counterparty;

    if (this.isVatRateEmpty) {
      return this.intl.t('counterparties.table.item.placeholder-empty');
    }

    if (vatRate === VAT_RATES.NOT_AUTOMATIC) {
      return this.intl.t('counterparties.table.item.placeholder-not-automatic');
    }

    return this.intl.formatNumber(vatRate / 100, {
      style: 'percent',
      minimumSignificantDigits: 1,
    });
  }

  get remainingLabels() {
    // @ts-expect-error
    return this.args.counterparty.labels ? this.args.counterparty.labels.length - 1 : 0;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Counterparties::Table::Item': typeof CounterpartyListItemComponent;
  }
}
