import { template as template_7dbe633e5fe74dadb991b7680051150e } from "@ember/template-compiler";
import { hash } from '@ember/helper';
import { service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import { Button, DropdownButton } from '@repo/design-system-kit';
import { t } from 'ember-intl';
import ReactBridge from 'qonto/components/react-bridge';
import ReactBridgeWithProviders from 'qonto/components/react-bridge-with-providers';
import { INVOICE_STATUSES } from 'qonto/constants/supplier-invoice';
import { currentStep, isCurrentStepToApprove, isCurrentStepToPay, userIsCurrent } from 'qonto/utils/approval-workflow-state';
export default class DetailsDropdownCta extends Component {
    @service
    intl;
    @service
    abilities;
    noop() {}
    get approvalWorkflowStateEstimate() {
        return this.args.approvalWorkflowStateEstimateTask.lastSuccessful?.value?.approvalWorkflowState;
    }
    // items
    get items() {
        if (this.args.approvalWorkflowStateEstimateTask.isRunning) {
            return [];
        }
        return [
            ...(this.showPay ? [
                this.payItem
            ] : []),
            ...(this.showMoveToToPay ? [
                this.moveToToPayItem
            ] : []),
            ...(this.showApprove ? [
                this.approveItem
            ] : []),
            ...(this.showMoveToToApprove ? [
                this.moveToToApproveItem
            ] : []),
            ...(this.showMarkAsPaid ? [
                this.markAsPaidItem
            ] : [])
        ];
    }
    get ctaItem() {
        return this.items.at(0);
    }
    get dropdownItems() {
        return this.items.slice(1);
    }
    get payItem() {
        return {
            label: this.intl.t('supplier-invoices.cta.pay'),
            onSelect: this.noop
        };
    }
    get moveToToPayItem() {
        return {
            label: this.intl.t('supplier-invoices.cta.move-to-to-pay'),
            onSelect: this.noop
        };
    }
    get markAsPaidItem() {
        return {
            label: this.intl.t('supplier-invoices.cta.mark-as-paid'),
            onSelect: this.noop
        };
    }
    get approveItem() {
        return {
            label: this.intl.t('supplier-invoices.cta.approve'),
            onSelect: this.noop
        };
    }
    get moveToToApproveItem() {
        return {
            label: this.intl.t('supplier-invoices.cta.move-to-to-approve'),
            onSelect: this.noop
        };
    }
    // what items to show
    get showPay() {
        return (!this.isCurrentStepBroken && this.canUpdateSupplierInvoice && (this.hasStatusToReview || this.hasStatusToPay) && ((this.userIsCurrent && this.isCurrentStepToPay) || (!this.hasApprovalWorkflow && this.canPay)));
    }
    get showMoveToToPay() {
        return (!this.isCurrentStepBroken && this.canUpdateSupplierInvoice && this.hasStatusToReview && (this.isCurrentStepToPay || (!this.hasApprovalWorkflow && this.canPay)));
    }
    get showMarkAsPaid() {
        return (!this.isCurrentStepBroken && this.canUpdateSupplierInvoice && (this.hasStatusToReview || (this.hasStatusToApprove && (this.userIsCurrent || (!this.hasApprovalWorkflow && this.canPay))) || (this.hasStatusToPay && this.userIsCurrent && this.isCurrentStepToPay)));
    }
    get showApprove() {
        return ((!this.isCurrentStepBroken && this.canUpdateSupplierInvoice && (this.hasStatusToReview || this.hasStatusToApprove) && this.userIsCurrent && this.isCurrentStepToApprove) || (this.hasStatusToApprove && !this.hasApprovalWorkflow && this.canPay));
    }
    get showMoveToToApprove() {
        return (!this.isCurrentStepBroken && this.canUpdateSupplierInvoice && this.hasStatusToReview && this.isCurrentStepToApprove);
    }
    get showReject() {
        return (!this.isCurrentStepBroken && this.canUpdateSupplierInvoice && (this.hasStatusToReview || this.hasStatusToApprove || this.hasStatusToPay) && (this.userIsCurrent || (!this.hasApprovalWorkflow && this.canPay)));
    }
    // show helper functions
    get canUpdateSupplierInvoice() {
        return this.abilities.can('update supplier-invoice');
    }
    get hasStatusToReview() {
        return this.args.supplierInvoice.status === INVOICE_STATUSES.toReview;
    }
    get hasStatusToApprove() {
        return this.args.supplierInvoice.status === INVOICE_STATUSES.toApprove;
    }
    get hasStatusToPay() {
        return this.args.supplierInvoice.status === INVOICE_STATUSES.toPay;
    }
    get userIsCurrent() {
        return (this.approvalWorkflowStateEstimate && userIsCurrent(this.approvalWorkflowStateEstimate, this.args.membershipId));
    }
    get isCurrentStepToPay() {
        return (this.approvalWorkflowStateEstimate && isCurrentStepToPay(this.approvalWorkflowStateEstimate));
    }
    get isCurrentStepToApprove() {
        return (this.approvalWorkflowStateEstimate && isCurrentStepToApprove(this.approvalWorkflowStateEstimate));
    }
    get hasApprovalWorkflow() {
        return Boolean(this.approvalWorkflowStateEstimate);
    }
    get isCurrentStepBroken() {
        if (!this.approvalWorkflowStateEstimate) return false;
        let step = currentStep(this.approvalWorkflowStateEstimate);
        return step ? isEmpty(step.value) : false;
    }
    get canPay() {
        return this.abilities.can('create transfer');
    }
    static{
        template_7dbe633e5fe74dadb991b7680051150e(`
    {{#if this.items.length}}
      <ReactBridgeWithProviders
        @reactComponent={{DropdownButton}}
        @props={{hash className='mr-16' items=this.dropdownItems}}
      >{{this.ctaItem.label}}</ReactBridgeWithProviders>
      {{#if this.showReject}}
        <ReactBridge @reactComponent={{Button}} @props={{hash variant='secondary'}}>{{t
            'supplier-invoices.cta.reject'
          }}</ReactBridge>
      {{/if}}
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
