export default {
  "container": "rN",
  "container-metal": "rv",
  "content": "ro",
  "button": "rn",
  "embossed-selector": "ri",
  "carousel-container": "rA",
  "carousel": "rY",
  "asset": "rh",
  "poster": "rf",
  "video-embossed": "rK",
  "video": "rG",
  "active": "rr",
  "embossed": "rb",
  "safari": "ry",
  "poster-open": "rU"
};
