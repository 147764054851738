/* import __COLOCATED_TEMPLATE__ from './pricing.hbs'; */
import Component from '@glimmer/component';

interface CardStepsPricingOnboardingSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class CardStepsPricingOnboarding extends Component<CardStepsPricingOnboardingSignature> {
  get cardEstimates() {
    // @ts-expect-error
    let { cardLevel, estimates } = this.args;
    return estimates?.[cardLevel];
  }

  get monthlyCost() {
    return this.cardEstimates?.monthly_cost;
  }

  get discountedMonthlyCost() {
    return this.cardEstimates.discounted_monthly_cost;
  }

  get discountFinishDate() {
    return this.cardEstimates.discount_finish_date;
  }

  get hasDiscount() {
    return (
      this.cardEstimates.discount_finish_date && this.monthlyCost !== this.discountedMonthlyCost
    );
  }

  get cardOptionPrice() {
    // @ts-expect-error
    let { optionsPrices, cardLevel } = this.args;
    let optionPrice = optionsPrices?.[cardLevel]?.value;
    return optionPrice > 0 ? optionPrice : null;
  }

  get isCardFree() {
    return this.monthlyCost === 0;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Cards::ChooseCard::Pricing': typeof CardStepsPricingOnboarding;
  }
}
