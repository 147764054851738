export default {
  "row": "cfZ",
  "active": "cfR",
  "animated": "cfQ",
  "fadein-item": "cfe",
  "cell": "cfB",
  "cell-content": "cfE",
  "row-sidebar": "cfd",
  "empty": "cfI",
  "cell-amount": "cft",
  "subtitle": "cfM caption",
  "hidden": "cfP",
  "cell-approve": "cfl",
  "quick-actions": "cfX",
  "account-select": "cfC",
  "note": "cfk",
  "cell-content-amount": "cfJ body-1",
  "cell-status": "cfs"
};
