import type { ReactNode } from 'react';
import cx from 'clsx';
import { useIntl } from 'react-intl';
import { Side, Status } from 'qonto/react/graphql';
import { cellContextManager } from 'qonto/react/contexts/cell-context';
import { BaseCell } from '../base-cell';
import { CopyButton } from '../../buttons';
import styles from './styles.strict-module.css';

export function AmountCell(): ReactNode {
  const transaction = cellContextManager.useCellContext();
  const { amount, side, status, fx, localAmount } = transaction;
  const { currency, value } = amount;
  const { formatNumber } = useIntl();
  const isTransaction = Boolean(side);
  const amountNumber = Number(`${side === Side.Debit && value !== '0' ? '-' : ''}${value}`);
  const localAmountNumber = Number(
    `${side === Side.Debit && localAmount?.value !== '0' ? '-' : ''}${localAmount?.value}`
  );
  const isDeclined = status === Status.Declined || status === Status.Reversed;

  const isNull = amountNumber === 0;
  const isNegative = amountNumber < 0 && !isNull;
  const positiveHighlight = isTransaction && !isNegative && !isNull;
  const negativeHighlight = !isTransaction && isNegative && !isNull;
  let formattedAmount = formatNumber(amountNumber, {
    currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    style: 'decimal',
  });
  let formattedLocalAmount = formatNumber(localAmountNumber, {
    currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    style: 'decimal',
  });

  if (isNegative) {
    const NO_BREAKING_SPACE_CHAR = '\xA0';
    formattedAmount = formattedAmount.replace('-', `-${NO_BREAKING_SPACE_CHAR}`);
    formattedLocalAmount = formattedLocalAmount.replace('-', `-${NO_BREAKING_SPACE_CHAR}`);
  }

  const hasLocalAmount = fx && localAmount;

  return (
    <BaseCell
      actionSlot={<CopyButton text={value} trackingEventSource="amount_cell_copy_amount" />}
      align="right"
      className={cx(hasLocalAmount && styles['with-local-amount'])}
    >
      <span
        className={cx(
          styles.amount,
          positiveHighlight && styles.positiveHighlight,
          isDeclined && styles.declined
        )}
        data-test-negative-highlight={negativeHighlight}
        data-test-positive-highlight={positiveHighlight}
        data-testid="amount-cell"
      >
        {positiveHighlight ? <span className={styles['plus-sign']}>+ </span> : null}
        {formattedAmount} {currency}
      </span>

      {hasLocalAmount ? (
        <span
          className={cx(positiveHighlight && styles.positiveHighlight, styles['amount-foreign'])}
          data-test-local-amount
          data-test-negative-highlight={negativeHighlight}
          data-test-positive-highlight={positiveHighlight}
        >
          {positiveHighlight ? <span className={styles['plus-sign']}>+ </span> : null}
          {formattedLocalAmount} {localAmount.currency}
        </span>
      ) : null}
    </BaseCell>
  );
}
