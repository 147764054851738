export default {
  "container": "qrA",
  "form-container": "qrY",
  "preview-container": "qrh",
  "pdf-preview-container": "qrf",
  "content": "qrK",
  "title": "qrG",
  "tabs-container": "qrr",
  "close-button": "qrb btn btn--icon-only btn--tertiary btn--large",
  "pdf-preview": "qry",
  "header": "qrU",
  "without-tabs": "qrj"
};
