export default {
  "mt-16": "qye",
  "title": "qyB",
  "avatar": "qyE",
  "edit-organization-form": "qyd",
  "dropdown-content": "qyI",
  "city-block": "qyt",
  "zip-code-input": "qyM",
  "cancel-button": "qyP",
  "error-message": "qyl",
  "nature-of-operations": "qyX",
  "error": "qyC",
  "tax-country": "qyk",
  "tooltip": "qyJ",
  "legal-code": "qys",
  "activity-type": "qyF"
};
