export default {
  "wrapper": "vp",
  "subtitle": "vx body-2",
  "legend": "vw",
  "table": "vN",
  "footer": "vv",
  "footer-codice-1": "vo",
  "footer-codice-2": "vn",
  "footer-total-a": "vi",
  "footer-total-b": "vA",
  "footer-balance": "vY",
  "errors": "vh"
};
