export default {
  "container": "cnw",
  "form-container": "cnN",
  "content": "cnv",
  "fields": "cno",
  "input-settings": "cnn",
  "current-year": "cni",
  "next-invoice-number": "cnA",
  "preview-container": "cnY",
  "actions": "cnh",
  "close-button": "cnf btn btn--icon-only btn--tertiary btn--large",
  "preview-image-container": "cnK",
  "preview-image": "cnG"
};
