import Model, { attr, belongsTo, hasMany } from '@ember-data/model';

// @ts-expect-error
import Validations from 'qonto/validations/label';

// @ts-expect-error
export default class LabelModel extends Model.extend(Validations) {
  @attr('string') declare name: string;
  @attr('date', {
    defaultValue() {
      return new Date();
    },
  })
  // @ts-expect-error
  createdAt;

  // @ts-expect-error
  @belongsTo('label-list', { async: false, inverse: 'labels' }) labelList;
  // @ts-expect-error
  @belongsTo('organization', { async: false, inverse: 'labels' }) organization;
  // @ts-expect-error
  @hasMany('transaction', { async: false, inverse: 'labels' }) transaction;
  // @ts-expect-error
  @hasMany('beneficiary', { async: false, inverse: 'labels' }) beneficiary;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    label: LabelModel;
  }
}
