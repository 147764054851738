/* import __COLOCATED_TEMPLATE__ from './pending-invoice-quick-actions.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';

// @ts-expect-error
import { REQUEST_EVENT_METHOD, REQUEST_ORIGINS } from 'qonto/constants/requests';

interface SupplierInvoicesPendingInvoiceQuickActionsSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class SupplierInvoicesPendingInvoiceQuickActionsComponent extends Component<SupplierInvoicesPendingInvoiceQuickActionsSignature> {
  @service declare organizationManager: Services['organizationManager'];
  @service declare segment: Services['segment'];

  @tracked
  // @ts-expect-error
  bankAccount = this.organizationManager.organization.mainAccount;

  get activeAccounts() {
    return this.organizationManager.organization.activeAccounts;
  }

  get hideAccountSelect() {
    return this.activeAccounts.length === 1;
  }

  approveRequestTask = dropTask(async () => {
    this.segment.track('request_approved_clicked', {
      origin: REQUEST_ORIGINS.SUPPLIER_INVOICES,
      method: REQUEST_EVENT_METHOD.QUICK_ACTIONS,
    });

    // @ts-expect-error
    await this.args.approveRequestTask.perform({
      // @ts-expect-error
      supplierInvoice: this.args.invoice,
      bankAccount: this.bankAccount,
    });
  });

  rejectRequestTask = dropTask(async () => {
    this.segment.track('request_declined_clicked', {
      origin: REQUEST_ORIGINS.SUPPLIER_INVOICES,
      method: REQUEST_EVENT_METHOD.QUICK_ACTIONS,
    });

    // @ts-expect-error
    await this.args.rejectRequestTask.perform(this.args.invoice);
  });

  // @ts-expect-error
  stopPropagation(event) {
    event.stopPropagation();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'SupplierInvoices::PendingInvoiceQuickActions': typeof SupplierInvoicesPendingInvoiceQuickActionsComponent;
  }
}
