export default {
  "company-logo": "cFF",
  "list-item": "cFg",
  "link": "cFD",
  "title-wrapper": "cFV",
  "title": "cFL body-2",
  "subtitle": "cFT caption",
  "truncated-text": "cFz",
  "image-placeholder": "cFa"
};
