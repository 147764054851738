/* import __COLOCATED_TEMPLATE__ from './choose-type.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

// @ts-expect-error
import { BADGE_TYPE } from '@qonto/ui-kit/constants/badge-types';
import { Badge, Disclaimer } from '@repo/design-system-kit';
// @ts-expect-error
import { variation } from 'ember-launch-darkly';

import { CARD_LEVELS } from 'qonto/constants/cards';
// @ts-expect-error
import { REQUEST_ORIGINS, REQUEST_TYPES } from 'qonto/constants/requests';

interface RequestChooseTypeSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class RequestChooseType extends Component<RequestChooseTypeSignature> {
  disclaimerInline = Disclaimer.Inline;

  @service declare abilities: Services['abilities'];
  @service declare flowLinkManager: Services['flowLinkManager'];
  @service declare intl: Services['intl'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare router: Services['router'];
  @service declare segment: Services['segment'];
  @service declare modals: Services['modals'];
  @service declare sentry: Services['sentry'];
  @service declare subscriptionManager: Services['subscriptionManager'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare upsellManager: Services['upsellManager'];

  badgeHighlight = Badge;
  BADGE_TYPE = BADGE_TYPE;

  typesPerOrigin = {
    default: [
      REQUEST_TYPES.TRANSFER,
      REQUEST_TYPES.MULTI_TRANSFER,
      REQUEST_TYPES.FLASH_CARD,
      REQUEST_TYPES.VIRTUAL_CARD,
      REQUEST_TYPES.EXPENSE_REPORT,
      REQUEST_TYPES.MILEAGE,
    ],
    cards: [REQUEST_TYPES.FLASH_CARD, REQUEST_TYPES.VIRTUAL_CARD],
    transfers: [REQUEST_TYPES.TRANSFER, REQUEST_TYPES.MULTI_TRANSFER],
    reimbursements: [REQUEST_TYPES.EXPENSE_REPORT, REQUEST_TYPES.MILEAGE],
  };

  get availableTypes() {
    // @ts-expect-error
    return this.typesPerOrigin[this.args.origin] || this.typesPerOrigin.default;
  }

  get title() {
    let canCreateCardRequest = this.abilities.can('create card request');
    let canCreateTransfer =
      this.abilities.can('create transfer request') || this.abilities.can('create bulk transfer');
    let canCreateExpenseReport = this.abilities.can('use expense reports request');

    if (
      // @ts-expect-error
      this.args.origin === 'reimbursements' ||
      // @ts-expect-error
      this.args.origin === REQUEST_ORIGINS.REIMBURSEMENTS_EMPTY_STATE
    ) {
      return this.intl.t('requests.creation.landing.reimbursements.title');
    }
    // @ts-expect-error
    if (this.args.origin === 'cards') {
      return this.intl.t('requests.creation.landing.cards.title');
    }
    // @ts-expect-error
    if (this.args.origin === 'transfers') {
      return this.intl.t('requests.creation.landing.transfers.title');
    }

    if (canCreateExpenseReport) {
      return this.intl.t('requests.creation.landing.title-transfer');
    }

    if (canCreateCardRequest && canCreateTransfer) {
      return this.intl.t('requests.creation.landing.title');
    }

    if (canCreateTransfer) {
      return this.intl.t('requests.creation.landing.title-transfer');
    }

    return this.intl.t('requests.creation.landing.title-card');
  }

  get isKycSubmitted() {
    return this.organizationManager.membership.kycSubmitted;
  }

  get isItalianOrga() {
    return (
      variation('operational--boolean-disable-card-creation-for-italy') &&
      this.organizationManager.organization.legalCountry === 'IT'
    );
  }

  get hasAvailableFreeTrial() {
    return this.upsellManager.hasAvailableFreeTrial('request_reimbursement_mileage');
  }

  @action
  handleClose() {
    // @ts-expect-error
    switch (this.args.origin) {
      case REQUEST_ORIGINS.REIMBURSEMENTS_EMPTY_STATE:
        this.router.transitionTo('reimbursements');
        break;
      case 'reimbursements':
        this.router.transitionTo('reimbursements.requests');
        break;
      case 'cards':
        this.router.transitionTo('cards.my-cards');
        break;
      case 'transfers':
        this.router.transitionTo('transfers.requests');
        break;
      default:
        this.router.transitionTo('requests.pending');
    }
  }

  @action
  // @ts-expect-error
  handleGo(internal) {
    this.router.transitionTo('requests.transfers.new', {
      // @ts-expect-error
      queryParams: { currency: null, internal, origin: this.args.origin ?? null },
    });
  }

  @action
  handleGoExpenseReport() {
    let sendOrigin =
      // @ts-expect-error
      this.args.origin === REQUEST_ORIGINS.reimbursements ||
      // @ts-expect-error
      this.args.origin === REQUEST_ORIGINS.REIMBURSEMENTS_EMPTY_STATE;

    this.flowLinkManager.transitionTo({
      name: 'request-expense-report',
      stepId: 'receipt',
      // @ts-expect-error
      queryParams: sendOrigin ? { origin: this.args.origin } : {},
    });
  }

  @action
  handleGoMileage() {
    let sendOrigin =
      // @ts-expect-error
      this.args.origin === REQUEST_ORIGINS.reimbursements ||
      // @ts-expect-error
      this.args.origin === REQUEST_ORIGINS.REIMBURSEMENTS_EMPTY_STATE;

    this.flowLinkManager.transitionTo({
      name: 'request-mileage',
      // @ts-expect-error
      queryParams: sendOrigin ? { origin: this.args.origin } : {},
    });
  }

  @action
  handleGoMileageUpsell() {
    try {
      this.segment.track('upsell_mileage_clicked');
      this.subscriptionManager.upgradeWithRecommendation('request_reimbursement_mileage');
    } catch (error) {
      this.sentry.captureException(error);
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    }
  }

  @action
  handleGoBulk() {
    this.router.transitionTo('requests.transfers.multi.new', {
      // @ts-expect-error
      queryParams: { currency: null, origin: this.args.origin ?? null },
    });
  }

  @action
  handleGoFlashCard() {
    if (this.isItalianOrga) {
      return this.modals.open('card/modals/bank-of-italy-restrictions');
    }

    this.segment.track('request_started', {
      // @ts-expect-error
      origin: this.args.origin,
      request_type: 'flash_card',
    });

    this.flowLinkManager.transitionTo({
      name: 'requests-cards',
      stepId: 'budget',
      queryParams: { cardLevel: CARD_LEVELS.FLASH },
    });
  }

  @action
  handleGoVirtualCard() {
    if (this.isItalianOrga) {
      return this.modals.open('card/modals/bank-of-italy-restrictions');
    }

    this.segment.track('request_started', {
      // @ts-expect-error
      origin: this.args.origin,
      request_type: 'virtual_card',
    });

    return this.flowLinkManager.transitionTo({
      name: 'requests-cards',
      stepId: 'budget',
      queryParams: { cardLevel: CARD_LEVELS.VIRTUAL },
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Request::ChooseType': typeof RequestChooseType;
  }
}
