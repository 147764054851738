export default {
  "row": "qkl",
  "active": "qkX",
  "dot": "qkC",
  "red": "qkk",
  "green": "qkJ",
  "yellow": "qks",
  "align-right": "qkF",
  "animated": "qkg",
  "fadein-item": "qkD",
  "cell": "qkV body-2",
  "cell-content": "qkL",
  "amount": "qkT body-1",
  "subtitle": "qkz caption",
  "status": "qka",
  "cell-amount": "qkH"
};
