export default {
  "wrapper": "mT",
  "modal": "mz",
  "close-button": "ma",
  "content": "mH",
  "title": "mO title-1",
  "description": "mm body-2",
  "slides-container": "mp",
  "slide": "mx"
};
