/* import __COLOCATED_TEMPLATE__ from './success.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { SuccessScreen } from 'qonto/react/components/flows/subscription/hear-from-you';

interface SubscriptionHearFromYouSuccessSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class SubscriptionHearFromYouSuccessComponent extends Component<SubscriptionHearFromYouSuccessSignature> {
  @service declare flow: Services['flow'];
  @service declare segment: Services['segment'];
  @service declare subscriptionManager: Services['subscriptionManager'];
  @service declare zendeskWidget: Services['zendeskWidget'];

  successScreen = SuccessScreen;

  @action onClose() {
    this.segment.track('account-closing_cs-callback_call-request-completed', {
      entry:
        // @ts-expect-error
        this.flow.dataContext.closingReasonCategory?.key === 'service_quality'
          ? 'screen'
          : 'banner',
      pricePlan: this.subscriptionManager.currentPricePlan.code,
    });
    this.zendeskWidget.show();
    this.flow.complete();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Subscription::HearFromYou::Success': typeof SubscriptionHearFromYouSuccessComponent;
  }
}
