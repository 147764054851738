export default {
  "selector": "cNY",
  "pension-selector-content": "cNh",
  "withholding-tax-selector-content": "cNf",
  "withholding-tax-selector-content-row": "cNK",
  "power-select-field": "cNG",
  "rate-field": "cNr",
  "stamp-revenue-field": "cNb",
  "dropdown-option": "cNy",
  "dropdown-content": "cNU"
};
