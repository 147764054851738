/* import __COLOCATED_TEMPLATE__ from './review-and-pay.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

interface ReviewAndPaySignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class ReviewAndPayComponent extends Component<ReviewAndPaySignature> {
  @service declare segment: Services['segment'];
  @service declare router: Services['router'];

  @action
  handleButtonClick() {
    this.segment.track('request_review_clicked', { request_type: 'expense_report' });
    this.router.transitionTo('reimbursements.pending', {
      // @ts-expect-error
      queryParams: { highlight: this.args.context.requestExpenseReport.id },
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::RequestExpenseReport::ReviewAndPay': typeof ReviewAndPayComponent;
  }
}
