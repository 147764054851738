/* import __COLOCATED_TEMPLATE__ from './linked-tooltip.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class SupplierInvoicesTableLinkedTooltipComponent extends Component {
  @service intl;

  get creditNotesCount() {
    let relatedInvoices = this.args.relatedInvoices || [];

    return relatedInvoices?.length;
  }

  get invoiceNumber() {
    let relatedInvoices = this.args.relatedInvoices || [];

    return relatedInvoices[0]?.invoice_number;
  }

  get tooltipMessage() {
    let { isCreditNote } = this.args;
    if (isCreditNote && this.invoiceNumber) {
      return this.intl.t('supplier-invoices.invoices-list.invoices.linked-invoice', {
        invoiceNumber: this.invoiceNumber,
      });
    } else if (isCreditNote) {
      return this.intl.t('supplier-invoices.invoices-list.invoices.no-number-linked-invoice');
    } else {
      return this.intl.t('supplier-invoices.invoices-list.invoices.linked-credit-note', {
        creditNotesNumber: this.creditNotesCount,
      });
    }
  }
}
