import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { dropTask } from 'ember-concurrency';

import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class ReceivableInvoicesShareRoute extends Route {
  @service menu;
  @service store;
  @service organizationManager;
  @service router;
  @service sentry;
  @service toastFlashMessages;
  @service intl;
  @service networkManager;

  activate() {
    this.menu.hide();
  }

  deactivate() {
    this.menu.show();
  }

  async model({ id }) {
    let { organization } = this.organizationManager;

    this.fetchOrganizationAvatarTask.perform(organization).catch(ignoreCancelation);

    let organizationId = organization.id;

    let invoice = await this.store.findRecord('receivable-invoice', id).catch(error => {
      return this.handleError({
        error,
        redirect: 'receivable-invoices.index',
      });
    });

    await invoice.belongsTo('quote').load();

    let settings = await this.store
      .findRecord('receivable-invoices-settings', organizationId)
      .catch(error => {
        return this.handleError({
          error,
          redirect: 'receivable-invoices.index',
        });
      });

    await this.fetchEmailTemplate.perform(id).catch(ignoreCancelation);

    return {
      invoice,
      settings,
      emailTemplate: this.fetchEmailTemplate.lastSuccessful?.value.data.attributes,
    };
  }

  setupController(controller, model) {
    super.setupController(controller, model);
    controller.setDefaultParams();
  }

  fetchEmailTemplate = dropTask(async id => {
    try {
      return await this.store.adapterFor('receivable-invoice').fetchEmailTemplate(id);
    } catch (error) {
      this.handleError({ error, redirect: 'receivable-invoices.index' });
    }
  });

  fetchOrganizationAvatarTask = dropTask(async organization => {
    try {
      await organization.getAvatar();
    } catch (error) {
      this.handleError({
        error,
        ignoreAdapterError: true,
      });
    }
  });

  handleError({ error, ignoreAdapterError, redirect }) {
    if (ignoreAdapterError && error.isAdapterError) return;

    let errorInfo = ErrorInfo.for(error);
    if (errorInfo.shouldSendToSentry) {
      this.sentry.captureException(error);
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    }

    if (redirect) {
      return this.router.transitionTo(redirect);
    }
  }
}
