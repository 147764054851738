export default {
  "journey": "cZK",
  "content": "cZG",
  "distance": "cZr",
  "google-link-container": "cZb",
  "link-icon": "cZy",
  "continue-btn": "cZU",
  "origin": "cZj",
  "input-width": "cZW"
};
