/* import __COLOCATED_TEMPLATE__ from './success.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { LottiePlayer } from '@repo/design-system-kit';

import { TRACKING_TRIAL_STATE } from 'qonto/constants/subscriptions';

interface DiscoverTrialSuccessSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class DiscoverTrialSuccessComponent extends Component<DiscoverTrialSuccessSignature> {
  lottiePlayer = LottiePlayer;

  @service declare organizationManager: Services['organizationManager'];
  @service declare segment: Services['segment'];

  constructor(owner: unknown, args: DiscoverTrialSuccessSignature['Args']) {
    super(owner, args);
    this.segment.track('plans_change_confirmation_success', {
      trial_state: TRACKING_TRIAL_STATE.FREE_TRIAL,
      // @ts-expect-error
      recurrence: this.args.data.recurrence,
      // @ts-expect-error
      target_plan: this.args.data.targetPlan.code,
      // @ts-expect-error
      current_plan: this.args.data.currentPlan.code,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Discover::Trial::Success': typeof DiscoverTrialSuccessComponent;
  }
}
