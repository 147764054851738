/* import __COLOCATED_TEMPLATE__ from './table.hbs'; */
import Component from '@glimmer/component';

import { PlaceholderRow } from 'qonto/react/components/products/table/placeholder';

interface TableSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class TableComponent extends Component<TableSignature> {
  placeholderRow = PlaceholderRow;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Products::Table': typeof TableComponent;
  }
}
