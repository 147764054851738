/* import __COLOCATED_TEMPLATE__ from './insurance.hbs'; */
import Component from '@glimmer/component';

import { CARD_LEVELS } from 'qonto/constants/cards';

interface CardComparisonModalInsuranceSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class CardComparisonModalInsurance extends Component<CardComparisonModalInsuranceSignature> {
  get isMetalCard() {
    // @ts-expect-error
    return this.args.cardLevel === CARD_LEVELS.METAL;
  }

  get isPlusCard() {
    // @ts-expect-error
    return this.args.cardLevel === CARD_LEVELS.PLUS;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Card::Modals::Comparison::Insurance': typeof CardComparisonModalInsurance;
  }
}
