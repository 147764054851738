/* import __COLOCATED_TEMPLATE__ from './discover-overview.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface DiscoverOverviewSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const DiscoverOverview = templateOnlyComponent<DiscoverOverviewSignature>();

export default DiscoverOverview;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::SubscriptionChange::DiscoverOverview': typeof DiscoverOverview;
  }
}
