export default {
  "savings-account": "qQJ",
  "closed": "qQs",
  "badge": "qQF",
  "subtitle": "qQg",
  "amount": "qQD",
  "processing": "qQV",
  "gain": "qQL",
  "active": "qQT",
  "progress": "qQz",
  "progress-bar": "qQa",
  "progress-text": "qQH",
  "info": "qQO",
  "menu-item": "qQm body-2",
  "close-account": "qQp"
};
