export default {
  "file-uploader": "cpt",
  "file-uploader-content": "cpM",
  "file-uploader-dropzone": "cpP",
  "fixedSize": "cpl",
  "two-column": "cpX",
  "file-uploader-label": "cpC body-1",
  "required-chip": "cpk",
  "animated-container": "cpJ",
  "toggle-button": "cps",
  "toggle-button-icon": "cpF"
};
