// @ts-nocheck
/* eslint-disable @qonto/no-import-roles-constants, require-await */
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { waitFor } from '@ember/test-waiters';

import { apiAction } from '@mainmatter/ember-api-actions';
import macro, { bool, equal, not, or } from 'macro-decorators';

import { ACCOUNT_STATUS } from 'qonto/constants/bank-account';
import { KYC_STATUS, MEMBER_STATUS, ROLES } from 'qonto/constants/membership';
import titleize from 'qonto/utils/titleize';
import MembershipValidations from 'qonto/validations/membership';

export const attrsToCreateMember = ['firstName', 'lastName', 'email', 'role'];

export const requestTypes = {
  getAllowedBankAccounts: 'getAllowedBankAccounts',
  updateAllowedBankAccounts: 'updateAllowedBankAccounts',
  getRole: 'getRole',
  updateRole: 'updateRole',
};

export default class MembershipModel extends Model.extend(MembershipValidations) {
  @attr('string') declare gender: string;
  @attr birthdate;
  @attr('string') declare birthCity: string;
  @attr('string') declare birthCountry: string;

  @attr('string') declare firstName: string;
  @attr('string') declare lastName: string;
  @attr('string') declare email: string;
  @attr('string') declare phoneNumber: string;

  @attr('string') declare nationality: string;
  @attr nationalities;
  @attr('string') declare role: string;
  @attr('string') declare position: string;

  @attr('boolean') declare proxy: boolean;

  /**
   * The list of permissions associated to this membership.
   *
   * This field may be `null` if the membership was not fetched using
   * `/memberships/:id` endpoint.
   *
   * @type object | null
   */
  @attr permissions;

  @attr('string') declare userId: string;
  @attr('string') declare identityId: string;
  @attr('string') declare status: string;
  @attr('string') declare kycStatus: string;
  @attr('boolean') declare kycSubmitted: boolean;
  @attr('boolean') declare kycRequired: boolean;
  @attr('boolean') declare personalInfoProvided: boolean;
  @attr('date') declare createdAt: Date;
  @attr('date') declare revokedAt: Date;
  @attr('boolean') declare unrevokable: boolean;
  @attr originalCardLevel;
  @attr physicalCardsLimitExceeded;
  @attr activePhysicalCardsLimit;
  @attr('number') declare requestsCount: number;
  @attr features;
  @attr customPermissions;

  /**
   * Spend limits params.
   * @type object {value: string, currency: string}
   */
  @attr currentMonthSpendings;
  @attr monthlyTransferLimit;
  @attr perTransferLimit;

  /**
   * Is the person legally responsible for the organization?
   * @type boolean
   */
  @attr corporateOfficer;

  /**
   * Is the person legitimized to legally represent the organization?
   * @type boolean
   */
  @attr legalRepresentative;

  /**
   * The two-letter country code of the country where the person pays
   * their taxes.
   *
   * This field may be `null` if the person has not provided the
   * information yet.
   *
   * @type string | null
   */
  @attr taxResidenceCountry;

  /**
   * The tax identification number of the person in the country
   * represented by `taxResidenceCountry`.
   *
   * This field may be `null` if the person has not provided the
   * information yet.
   *
   * @type string | null
   */
  @attr taxResidenceTaxIdentificationNumber;

  /**
   * Is the person an "Ultimate Beneficial Owner" of the organization?
   * @type boolean
   */
  @attr ubo;

  // onboarding
  // onboarding
  @attr('boolean') declare onboardedCard: boolean;
  @attr topupsStatus;

  @attr('file') avatar;
  @attr('file') avatarThumb;
  @attr('file') defaultAvatar;
  @attr('file') defaultAvatarThumb;

  @belongsTo('address', { async: false, inverse: null }) address;
  @belongsTo('organization', { async: true, inverse: 'memberships' }) organization;
  @belongsTo('user', { async: false, inverse: 'memberships' }) user;
  @hasMany('document', { async: false, inverse: 'membership' }) documents;
  @hasMany('subscription', { async: false, inverse: 'membership' }) subscriptions;
  @hasMany('bankAccount', { async: true, inverse: null }) allowedBankAccounts;
  @belongsTo('team', { async: true, inverse: null }) team;
  @belongsTo('membership-kyb-detail', { async: false, inverse: 'membership' }) kybDetails;

  // This is a temporary solution to overcome a limitation in how the BE set up LaunchDarkly.
  // The product_discovery_system will be available at the same time within
  // the organization.features and membership.features array.
  @hasFeature('product_discovery_system') hasProductDiscoverySystemFeature;

  get fullName() {
    let firstName = this.firstName?.trim();
    let lastName = this.lastName?.trim();
    if (firstName && lastName) {
      return titleize(`${firstName} ${lastName}`);
    }
  }

  get membershipId(): string {
    return this.id;
  }

  @bool('avatar.fileUrl') hasAvatar;

  get picture() {
    let hasNoAvatar = !this.hasAvatar;
    let hasDefaultAvatar = this.get('defaultAvatar.fileUrl');
    return hasNoAvatar && hasDefaultAvatar ? this.defaultAvatar : this.avatar;
  }

  get pictureThumb() {
    let hasNoAvatar = !this.hasAvatar;
    let hasDefaultAvatarThumb = this.get('defaultAvatarThumb.fileUrl');

    return hasNoAvatar && hasDefaultAvatarThumb ? this.defaultAvatarThumb : this.avatarThumb;
  }

  loadSubscriptions() {
    return this.store.query('subscription', {
      membership_id: this.id,
      adapterOptions: { headers: { 'X-Qonto-Organization-ID': this.organization.id } },
    });
  }
  get hasSpendLimits() {
    return Boolean(this.monthlyTransferLimit || this.perTransferLimit);
  }

  get proofOfIdentity() {
    return this.documents.find(document => document.docType === 'poi');
  }

  get secondProofOfIdentity() {
    return this.documents.find(document => document.docType === 'second_poi');
  }

  get proofOfAddress() {
    return this.documents.find(document => document.docType === 'poa');
  }

  get powerOfAttorney() {
    return this.documents.find(document => document.docType === 'power');
  }

  @equal('role', ROLES.OWNER) owner;
  @equal('role', ROLES.ADMIN) admin;
  @equal('role', ROLES.MANAGER) manager;
  @equal('role', ROLES.EMPLOYEE) employee;
  @equal('role', ROLES.REPORTING) reporting;

  @equal('kycStatus', KYC_STATUS.ACCEPTED) kycAccepted;
  @equal('kycStatus', KYC_STATUS.PENDING) kycPending;
  @equal('kycStatus', KYC_STATUS.REFUSED) kycRefused;

  @equal('status', MEMBER_STATUS.PENDING) pending;
  @equal('status', MEMBER_STATUS.ACTIVE) active;
  @equal('status', MEMBER_STATUS.REVOKED) revoked;
  @equal('status', MEMBER_STATUS.INVITABLE) invitable;

  @not('revoked') notRevoked;
  @not('unrevokable') revokable;
  @or('unrevokable', 'notRevoked') visible;

  get memberName() {
    let hasLongName = this.fullName?.length > 15;
    if (hasLongName) {
      return this._shortenName(this.firstName, this.lastName);
    }
    return this.fullName || '-';
  }

  get hasActiveAllowedBankAccounts() {
    if (!this.allowedBankAccounts.length) return false;

    let activeAllowedBankAccounts = this.allowedBankAccounts.filter(
      bankAccount => bankAccount.status === ACCOUNT_STATUS.ACTIVE
    );

    return activeAllowedBankAccounts.length > 0;
  }

  @waitFor
  async revoke() {
    let response = await apiAction(this, { method: 'PUT', path: 'revoke' });
    this.store.pushPayload(response);
    if (this.status === MEMBER_STATUS.DELETED) {
      this.unloadRecord();
    }
  }

  @waitFor
  async unrevoke() {
    let response = await apiAction(this, { method: 'PUT', path: 'unrevoke' });
    this.store.pushPayload(response);
  }

  @waitFor
  async renewInvite() {
    let response = await apiAction(this, { method: 'POST', path: 'renew_invite' });
    this.store.pushPayload(response);
  }

  @waitFor
  async getRole() {
    let response = await apiAction(this, {
      method: 'GET',
      path: 'role',
      requestType: requestTypes.getRole,
    });
    if (response) {
      let { role } = response;
      this.role = role.name;
      this.customPermissions = role.custom_permissions;
    }
  }

  @waitFor
  async updateRole() {
    let data = {
      role: {
        name: this.role,
        custom_permissions: this.customPermissions,
      },
    };
    let { role } = await apiAction(this, {
      method: 'PATCH',
      path: 'role',
      data,
      requestType: requestTypes.updateRole,
    });
    this.role = role.name;
    this.customPermissions = role.custom_permissions;
  }

  @waitFor
  async getAllowedBankAccounts() {
    let response = await apiAction(this, {
      method: 'GET',
      path: 'allowed_bank_accounts',
      requestType: requestTypes.getAllowedBankAccounts,
    });

    if (response?.ids?.length) {
      this.allowedBankAccounts = response.ids
        .map(id => this.store.peekRecord('bank-account', id))
        .filter(Boolean);
    }
  }

  @waitFor
  async updateAllowedBankAccounts(allowedBankAccountsIds) {
    let data = { ids: allowedBankAccountsIds };
    let response = await apiAction(this, {
      method: 'PUT',
      path: 'allowed_bank_accounts',
      data,
      requestType: requestTypes.updateAllowedBankAccounts,
    });
    if (response?.ids?.length) {
      this.allowedBankAccounts = response.ids
        .map(id => this.store.peekRecord('bank-account', id))
        .filter(Boolean);
    }
  }

  @waitFor
  async getSpendLimits() {
    let response = await apiAction(this, { method: 'GET', path: 'spend_limits' });
    if (response) {
      this.currentMonthSpendings = response.spend_limits.current_month_spendings;
      this.monthlyTransferLimit = response.spend_limits.monthly_transfer_limit;
      this.perTransferLimit = response.spend_limits.per_transfer_limit;

      if (this.monthlyTransferLimit) {
        this.monthlyTransferLimit.value = this.monthlyTransferLimit.value.split('.')[0];
      }

      if (this.perTransferLimit) {
        this.perTransferLimit.value = this.perTransferLimit.value.split('.')[0];
      }
    }
  }

  @waitFor
  async updateSpendLimits() {
    let monthlyTransferLimit = null;
    let perTransferLimit = null;

    if (this.monthlyTransferLimit?.value) {
      monthlyTransferLimit = {
        value: String(this.monthlyTransferLimit.value),
        currency: this.monthlyTransferLimit.currency,
      };
    }

    if (this.perTransferLimit?.value) {
      perTransferLimit = {
        value: String(this.perTransferLimit.value),
        currency: this.perTransferLimit.currency,
      };
    }

    let data = {
      spend_limits: {
        monthly_transfer_limit: monthlyTransferLimit,
        per_transfer_limit: perTransferLimit,
      },
    };

    let response = await apiAction(this, { method: 'PATCH', path: 'spend_limits', data });
    if (response) {
      this.currentMonthSpendings = response.spend_limits.current_month_spendings;
      this.monthlyTransferLimit = response.spend_limits.monthly_transfer_limit;
      this.perTransferLimit = response.spend_limits.per_transfer_limit;
    }
  }

  @waitFor
  async getIban() {
    let response = await apiAction(this, { method: 'GET', path: 'bank_details' });
    if (response) {
      this.iban = response.membership_bank_details?.iban;
    }
  }

  @waitFor
  async updateIban() {
    return this.saveIban('PATCH');
  }

  @waitFor
  async createIban() {
    return this.saveIban('POST');
  }

  @waitFor
  async saveIban(method) {
    let data = {
      membership_bank_details: {
        iban: this.iban,
      },
    };

    let response = await apiAction(this, { method, path: 'bank_details', data });
    if (response) {
      this.iban = response.membership_bank_details?.iban;
    }
  }

  @waitFor
  async deleteIban() {
    let response = await apiAction(this, { method: 'DELETE', path: 'bank_details' });
    if (response) {
      this.iban = null;
    }
  }

  @waitFor
  async isEligibleForRegate() {
    let response = await apiAction(this, {
      method: 'GET',
      path: 'eligible_for_regate',
    });
    return response.eligible;
  }

  _shortenName(firstName, lastName) {
    let shortFirstName = `${firstName.substring(0, 1)}.`;
    return `${shortFirstName} ${lastName}`;
  }

  @waitFor
  async submitKYC() {
    let data = {
      kyc: {
        nationality: this.nationality,
      },
    };

    let response = await apiAction(this, { method: 'PATCH', path: 'kyc', data });
    this.kycSubmitted = response.membership.kyc_submitted;
    this.nationality = response.membership.nationality;
  }

  get hasFetchedSpendLimits() {
    return Boolean(
      this.currentMonthSpendings || this.monthlyTransferLimit || this.perTransferLimit
    );
  }

  get shouldSubmitKyc() {
    return this.kycRequired && !this.kycSubmitted;
  }
}

function hasFeature(name) {
  return macro(function () {
    return this.features?.includes(name);
  });
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    membership: MembershipModel;
  }
}
