export default {
  "page-header": "ZcX",
  "header-breadcrumb": "ZcC",
  "tabs-container": "Zck",
  "bottom-border": "ZcJ",
  "tabs-separator": "Zcs",
  "previous-request": "ZcF",
  "body-link": "Zcg",
  "border-top": "ZcD"
};
