export default {
  "tooltip": "xg",
  "wrapper": "xD",
  "collapsed": "xV",
  "label": "xL body-1",
  "selected": "xT",
  "icon-wrapper": "xz",
  "icon": "xa",
  "locked-icon": "xH",
  "notification-count": "xO",
  "fade-in": "xm",
  "responsive": "xp"
};
