/* import __COLOCATED_TEMPLATE__ from './matched-transactions.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import dayjs from 'dayjs';
import { dropTask, timeout } from 'ember-concurrency';

// @ts-expect-error
import { DATE_PICKER_FIELD_FORMAT } from 'qonto/constants/dates';
import { MATCHED_TRANSACTIONS_DELAY } from 'qonto/constants/receivable-invoice';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';

interface MatchedTransactionsSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class MatchedTransactionsComponent extends Component<MatchedTransactionsSignature> {
  @service declare flowLinkManager: Services['flowLinkManager'];
  @service declare store: Services['store'];
  @service declare segment: Services['segment'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare intl: Services['intl'];
  @service declare sentry: Services['sentry'];
  @service declare errors: Services['errors'];

  get filterGroup() {
    return {
      conditional: 'and',
      expressions: [
        {
          property: 'attachment_ids',
          // @ts-expect-error
          values: [this.args.invoice?.belongsTo('attachment').id()],
          operator: 'in',
        },
      ],
    };
  }

  @action openMarkAsPaidFlow() {
    /**
     * @todo This logic should be moved to the flow setup once it is possible to abort the transition
     * @see https://www.notion.so/qonto/Allow-flows-to-abort-a-transition-into-the-flows-route-b657347ce54a49d686e410ede0920132
     */
    // @ts-expect-error
    let hasAttachment = Boolean(this.args.invoice?.belongsTo('attachment').id());
    if (!hasAttachment) {
      // @ts-expect-error
      this.sentry.captureMessage(`Invoice ${this.args.invoice.id} is missing an attachment.`);
      return this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    }

    this.segment.track('receivable_invoice_add_transaction', { origin: 'invoice_modal' });
    this.flowLinkManager.transitionTo({
      name: 'match-invoice',
      stepId: 'select-transaction',
      // @ts-expect-error
      queryParams: { trigger: 'receivableInvoices', invoiceId: this.args.invoice.id },
    });
  }

  onUnlinkTask = dropTask(async refreshTransactionsTask => {
    let currentlyLinkedTransactions = refreshTransactionsTask.last.value;
    try {
      this.segment.track('receivable_invoice_unlink_transaction', { origin: 'invoice_modal' });
      if (currentlyLinkedTransactions?.length === 1) {
        // @ts-expect-error
        this.args.onUnlinkLastTransactionTask?.perform();
      }
      await timeout(MATCHED_TRANSACTIONS_DELAY);
      await refreshTransactionsTask.perform();
      let refreshedLinkedTransactions = refreshTransactionsTask.last.value;

      if (refreshedLinkedTransactions?.length > 0) {
        this._updatePaymentDate(refreshedLinkedTransactions);
        this.toastFlashMessages.toastSuccess(
          this.intl.t('receivable-invoices.invoice-modal.success.unlinked-paid-transaction')
        );
      }
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      // @ts-expect-error
      if (this.errors.shouldFlash(error)) {
        // @ts-expect-error
        this.toastFlashMessages.toastError(this.errors.messageForStatus(error));
      }
    }
  });

  // @ts-expect-error
  #updatePaymentDate(transactions) {
    if (transactions.length === 0) return;

    // @ts-expect-error
    let paymentDates = transactions.map(({ emittedAt }) => dayjs(emittedAt));
    // @ts-expect-error
    this.args.invoice.paidAt = dayjs.max(paymentDates).format(DATE_PICKER_FIELD_FORMAT);
  }

  // @ts-expect-error
  _updatePaymentDate(transactions) {
    if (transactions.length === 0) return;

    // @ts-expect-error
    let paymentDates = transactions.map(({ emittedAt }) => emittedAt);
    // @ts-expect-error
    this.args.invoice.paidAt = dayjs(dayjs.max(paymentDates)).format(DATE_PICKER_FIELD_FORMAT);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ReceivableInvoices::InvoiceModal::MatchedTransactions': typeof MatchedTransactionsComponent;
  }
}
