export default {
  "container": "li",
  "content": "lA",
  "table": "lY",
  "table-body": "lh",
  "title": "lf",
  "title-with-badge": "lK",
  "metal-card-title": "lG",
  "subtitle": "lr",
  "card-image": "lb",
  "button-container": "ly"
};
