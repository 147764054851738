import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';

import { NAVATIC_TOUR_URLS, TABS } from 'qonto/constants/supplier-invoice';

export default class SupplierInvoicesController extends Controller {
  @service intl;
  @service router;
  @service segment;

  get navaticTourUrls() {
    return NAVATIC_TOUR_URLS;
  }

  get cardsContent() {
    return [
      {
        type: 'accelerate-efficiency',
        illustration: '/illustrations/supplier-invoices/scan-invoice.svg',
        title: this.intl.t('supplier-invoices.empty-state.education.block-1.title'),
        list: [
          this.intl.t('supplier-invoices.empty-state.education.block-1.bullet-1'),
          this.intl.t('supplier-invoices.empty-state.education.block-1.bullet-2'),
          this.intl.t('supplier-invoices.empty-state.education.block-1.bullet-3'),
        ],
      },
      {
        type: 'keep-track',
        illustration: '/illustrations/supplier-invoices/control-payment.svg',
        title: this.intl.t('supplier-invoices.empty-state.education.block-2.title'),
        list: [
          this.intl.t('supplier-invoices.empty-state.education.block-2.bullet-1'),
          this.intl.t('supplier-invoices.empty-state.education.block-2.bullet-2'),
          this.intl.t('supplier-invoices.empty-state.education.block-2.bullet-3'),
          this.intl.t('supplier-invoices.empty-state.education.block-2.bullet-4'),
        ],
      },
      {
        type: 'boost-bookkeeping',
        illustration: '/illustrations/supplier-invoices/bookkeeping.svg',
        title: this.intl.t('supplier-invoices.empty-state.education.block-3.title'),
        list: [
          this.intl.t('supplier-invoices.empty-state.education.block-3.bullet-1'),
          this.intl.t('supplier-invoices.empty-state.education.block-3.bullet-2'),
          this.intl.t('supplier-invoices.empty-state.education.block-3.bullet-3'),
        ],
      },
    ];
  }

  get completedTab() {
    return TABS.COMPLETED;
  }

  get showFooter() {
    return this.model.fetchAutomatedUploadedInvoicesTask.last?.value?.length > 0 || false;
  }

  @action
  onGetStartedClick() {
    this.segment.track('supplier-invoices_empty-state_get-started_clicked');
    return this.router.transitionTo('supplier-invoices.index');
  }

  @action
  onDemoCtaClick() {
    this.segment.track('supplier-invoices_empty-state_product-tour_clicked');
  }
}
