/* import __COLOCATED_TEMPLATE__ from './disputed.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface DisputedSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const Disputed = templateOnlyComponent<DisputedSignature>();

export default Disputed;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'HelpCenter::SelectedItem::Claim::Disputed': typeof Disputed;
  }
}
