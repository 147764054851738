/* import __COLOCATED_TEMPLATE__ from './email-preview.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

// @ts-expect-error
import { BADGE_TYPE } from '@qonto/ui-kit/constants/badge-types';
import { Disclaimer, PromotionalCard } from '@repo/design-system-kit';
// @ts-expect-error
import { variation } from 'ember-launch-darkly';

import { EmailPreviewFooter } from 'qonto/react/components/receivable-invoices/email-preview';

interface EmailPreviewSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class EmailPreviewComponent extends Component<EmailPreviewSignature> {
  disclaimerInline = Disclaimer.Inline;

  promotionalCard = PromotionalCard;

  emailPreviewFooter = EmailPreviewFooter;

  tryBadgeType = BADGE_TYPE.TRY_FOR_FREE;

  @service declare intl: Services['intl'];
  @service declare theme: Services['theme'];
  @service declare abilities: Services['abilities'];
  @service declare organizationManager: Services['organizationManager'];

  get relatedQuoteNumber() {
    // @ts-expect-error
    return this.args.invoice.quote?.get('number');
  }

  get currency() {
    // @ts-expect-error
    return this.args.invoice.currency || 'EUR';
  }

  get showPaymentReferenceDisclaimer() {
    return (
      // @ts-expect-error
      !this.args.isDraft &&
      // @ts-expect-error
      !this.args.isQuote &&
      // @ts-expect-error
      !this.args.invoice.directDebitEnabled &&
      // @ts-expect-error
      !this.args.invoice.isEinvoice &&
      // @ts-expect-error
      !(this.abilities.can('write paymentLink') && this.args.invoice.paymentLink?.methods?.length)
    );
  }

  get footerDisclaimer() {
    return this.intl
      .t('receivable-invoices.invoicing-sending.preview.invoicing-disclaimer', {
        // @ts-expect-error
        language: this.args.displayLanguage,
        htmlSafe: true,
      })
      .toString();
  }

  get displayEinvoicingAcquisitionPromoCard() {
    let isFrenchCustomer =
      // @ts-expect-error
      this.args.invoice.customerSnapshot?.billingAddress?.countryCode === 'FR' ||
      // @ts-expect-error
      this.args.invoice.customerSnapshot?.countryCode === 'FR';
    let isFrenchOrga = this.organizationManager.organization.isFrench;

    return (
      // @ts-expect-error
      !this.args.invoice.isEinvoice &&
      isFrenchCustomer &&
      isFrenchOrga &&
      variation('feature--boolean-einvoicing-acquisition')
    );
  }

  @action
  disabledAction() {
    // Do nothing
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ReceivableInvoices::EmailPreview': typeof EmailPreviewComponent;
  }
}
