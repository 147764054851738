/* import __COLOCATED_TEMPLATE__ from './category.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

// @ts-expect-error
import { SUBJECT_TYPES } from 'qonto/constants/transactions';

interface TransactionsSidebarCategorySignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLElement;
}

export default class TransactionsSidebarCategoryComponent extends Component<TransactionsSidebarCategorySignature> {
  @service declare abilities: Services['abilities'];

  get isActivityTagSelectDisabled() {
    // @ts-expect-error
    let { isDisabled } = this.args;
    let { isBilling, isTax, isPayLater, credit, isDirectDebitHold, isTapToPay } =
      // @ts-expect-error
      this.args.highlightedItem;

    return (
      isDisabled ||
      isBilling ||
      isTax ||
      isDirectDebitHold ||
      isTapToPay ||
      (isPayLater && credit) ||
      this.abilities.cannot('update transaction')
    );
  }

  get transactionType() {
    // @ts-expect-error
    let { operationType, side, subjectType } = this.args.highlightedItem;

    // for SDD R_transactions we need to return all available debit categories
    // so here we need to return a special subject type
    if (subjectType === SUBJECT_TYPES.DIRECT_DEBIT_COLLECTION && side === 'debit') {
      return SUBJECT_TYPES.DIRECT_DEBIT_COLLECTION_R_TRANSACTION;
    }

    if (operationType === 'other') {
      return 'Other';
    }

    return subjectType;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transactions::Sidebar::Category': typeof TransactionsSidebarCategoryComponent;
  }
}
