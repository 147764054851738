export default {
  "form": "nu",
  "fieldset": "iS",
  "form-body": "ic",
  "conditional-select": "iq",
  "conditional-text": "iZ",
  "group": "iR",
  "add-filter-button": "iQ btn btn--tertiary",
  "footer": "ie",
  "btn-icon": "iB",
  "add-icon": "iE",
  "cancel-button": "id btn",
  "form-header": "iI",
  "align-start": "it",
  "align-right": "iM",
  "button-wrapper": "iP",
  "wrapper": "il",
  "label": "iX body-2",
  "input-wrapper": "iC",
  "input": "ik input-field body-2",
  "error": "iJ",
  "error-message": "is"
};
