export default {
  "wrapper": "Tb",
  "label": "Ty",
  "input": "TU",
  "icon-trash": "Tj",
  "hidden": "TW",
  "icon-color": "Tu",
  "icon-edit": "zS",
  "icon-edit-save": "zc",
  "button-container": "zq"
};
