export default {
  "cell": "qlk",
  "supplier-cell": "qlJ",
  "supplier-container": "qls",
  "cell-label-container": "qlF",
  "cell-total-amount": "qlg",
  "cell-label": "qlD",
  "overflow": "qlV",
  "sub-label": "qlL body-2",
  "overdue": "qlT",
  "file-name-warning": "qlz",
  "missing": "qla",
  "pending-icon": "qlH",
  "icon": "qlO",
  "flex-label": "qlm",
  "with-separator": "qlp",
  "self-invoice-icon": "qlx",
  "tooltip-wrapper": "qlw",
  "credit-note-amount": "qlN",
  "payable-amount": "qlv"
};
