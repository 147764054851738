/* import __COLOCATED_TEMPLATE__ from './details-box.hbs'; */
import Component from '@glimmer/component';

import { Disclaimer } from '@repo/design-system-kit';

interface NrcSidebarDetailsBoxSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class NrcSidebarDetailsBoxComponent extends Component<NrcSidebarDetailsBoxSignature> {
  disclaimerInline = Disclaimer.Inline;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Nrc::Sidebar::DetailsBox': typeof NrcSidebarDetailsBoxComponent;
  }
}
