export default {
  "attachments-suggested-sidebar": "Ra",
  "aside-section": "RH",
  "search-section": "RO",
  "scrolled-top": "Rm",
  "aside-title": "Rp caption-bold",
  "search-input": "Rx",
  "toolbar-row": "Rw",
  "invoices-list-section": "RN",
  "attachments-suggested-sidebar-footer": "Rv",
  "pagination-wrapper": "Ro",
  "pagination-loading": "Rn",
  "cta-wrapper": "Ri",
  "cancel-button": "RA",
  "content": "RY",
  "illustration": "Rh mb-32",
  "title": "Rf mb-8 title-4",
  "subtitle": "RK body-2"
};
