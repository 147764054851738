export default {
  "container": "cdK",
  "container--collapsed": "cdG",
  "spin": "cdr",
  "cta-tooltip": "cdb",
  "cta--collapsed": "cdy",
  "cta--expanded": "cdU",
  "collapsed-svg-icon": "cdj",
  "expanded-svg-icon": "cdW",
  "generate-logo-cta": "cdu",
  "badge": "cIS",
  "scaleDown": "cIc",
  "fadeIn": "cIq",
  "textRiseButton": "cIZ",
  "cta-description": "cIR",
  "textRiseDescription": "cIQ"
};
