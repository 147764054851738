export default {
  "container": "qyi",
  "wrapper": "qyA",
  "grey-header-block": "qyY",
  "connect-app-header": "qyh",
  "connect-app-header-link": "qyf",
  "connect-app-header-content": "qyK",
  "connect-app-header-content-default": "qyG",
  "connect-app-header-content-left": "qyr",
  "button-block": "qyb",
  "connect-app-body": "qyy",
  "connect-app-body-left-content": "qyU",
  "connect-app-body-sidebar": "qyj"
};
