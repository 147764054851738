import * as React from 'react';
import type { Key } from 'react';
import { useIntl } from 'react-intl';
import { clsx } from 'clsx';
import {
  Button as AriaButton,
  ListBox,
  ListBoxItem,
  Popover,
  Select,
  SelectValue,
} from 'react-aria-components';
import { BadgeStatus, ExternalLink, type StatusProps } from '@repo/design-system-kit';
import { IconChevronBottomOutlined, IconDownloadOutlined } from '@repo/monochrome-icons';
import styles from './checkout-header.strict-module.css';

const BADGE_LEVELS: Record<CheckoutHeaderProps['status'], StatusProps['level']> = {
  open: 'in-progress',
  paid: 'validated',
};
export interface CheckoutHeaderProps {
  amount: string;
  organizationName: string;
  status: 'open' | 'paid';
  invoiceNumber?: string;
  locales: {
    id: string;
    label: string;
  }[];
  selectedLocale?: string;
  showActions?: boolean;
  pdfUrl?: string;
  onLocaleSelectionChange?: (key: Key) => void;
}

export function CheckoutHeader({
  amount,
  organizationName,
  status,
  invoiceNumber,
  locales,
  selectedLocale,
  onLocaleSelectionChange,
  pdfUrl,
  showActions = true,
}: CheckoutHeaderProps): React.JSX.Element {
  const { formatMessage } = useIntl();

  const badgeTexts = {
    open: formatMessage(
      { id: 'receivable-invoices.public-payment-link.status.unpaid' },
      { language: selectedLocale }
    ),
    paid: formatMessage(
      { id: 'receivable-invoices.public-payment-link.status.paid' },
      { language: selectedLocale }
    ),
  };

  return (
    <header className={styles.header}>
      {showActions ? (
        <div className={styles.actions}>
          <ExternalLink display="button" href={pdfUrl} size="small" variant="tertiary">
            <IconDownloadOutlined aria-hidden="true" className="mr-8" />
            {formatMessage(
              { id: 'receivable-invoices.public-payment-link.download' },
              { language: selectedLocale }
            )}
          </ExternalLink>
          <Select
            className={styles['locale-switcher']}
            onSelectionChange={onLocaleSelectionChange}
            selectedKey={selectedLocale}
          >
            <AriaButton className={clsx('body-1', styles.trigger)} data-testid="trigger">
              <SelectValue />
              <IconChevronBottomOutlined aria-hidden="true" />
            </AriaButton>
            <Popover
              UNSTABLE_portalContainer={document.getElementById('ember-testing') ?? document.body}
              className={styles.popover}
              maxHeight={200}
              placement="bottom right"
            >
              <ListBox items={locales}>
                {item => (
                  <ListBoxItem
                    className={clsx('body-1', styles['list-item'])}
                    data-testid={item.id}
                    key={item.id}
                  >
                    {item.label}
                  </ListBoxItem>
                )}
              </ListBox>
            </Popover>
          </Select>
        </div>
      ) : null}
      <div className={styles.content}>
        <BadgeStatus level={BADGE_LEVELS[status]} text={badgeTexts[status]} />
        {Boolean(invoiceNumber) && (
          <div className="title-3" data-checkout-header-title>
            {formatMessage(
              { id: 'receivable-invoices.public-payment-link.invoice-number' },
              {
                invoiceNumber,
                language: selectedLocale,
              }
            )}
          </div>
        )}
        <div>
          <h1 className={clsx(styles.title, 'title-1')}>
            <span className="mr-8" data-checkout-header-amount>
              {amount}
            </span>
            <span className={clsx(styles.vat, 'caption')}>
              {formatMessage(
                { id: 'receivable-invoices.public-payment-link.include-vat' },
                { language: selectedLocale }
              )}
            </span>
          </h1>
          <div className="title-4" data-checkout-header-org-name>
            {formatMessage(
              { id: 'receivable-invoices.public-payment-link.sent-by' },
              { companyName: organizationName, language: selectedLocale }
            )}
          </div>
        </div>
      </div>
    </header>
  );
}
