/* import __COLOCATED_TEMPLATE__ from './not-eligible-state.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { EmptyStatesDiscover } from '@repo/domain-kit/pricing';
import window from 'ember-window-mock';

interface FinancingPayLaterCockpitNotEligibleStateSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FinancingPayLaterCockpitNotEligibleStateComponent extends Component<FinancingPayLaterCockpitNotEligibleStateSignature> {
  emptyStatesDiscover = EmptyStatesDiscover;

  @service declare segment: Services['segment'];
  @service declare router: Services['router'];
  @service declare zendeskLocalization: Services['zendeskLocalization'];

  @action
  onPrimaryCtaClick() {
    this.segment.track('pay-later-partners_clicked', {
      origin: 'cockpit',
    });
    this.router.transitionTo('financing.partners');
  }

  @action
  onSecondaryCtaClick() {
    let href = this.zendeskLocalization.getLocalizedArticle('8271688');

    this.segment.track('pay-later-faq_clicked', {
      origin: 'cockpit_noneligible',
    });
    window.open(href, '_blank', 'noopener noreferrer');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Financing::PayLater::Cockpit::NotEligibleState': typeof FinancingPayLaterCockpitNotEligibleStateComponent;
  }
}
