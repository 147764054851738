export default {
  "invite-review": "ccw",
  "invite-review-info": "ccN",
  "invite-review-info--invitee": "ccv",
  "disclaimer-button": "cco",
  "info-block": "ccn",
  "ev": "cci",
  "tooltip": "ccA",
  "description": "ccY"
};
