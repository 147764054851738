import { FilterConditional, FilterExpressionOperator } from 'qonto/react/models/filters';
import type { FilterGroup, FilterExpression } from 'qonto/react/models/filters';
import type { CategoriesTableRow } from '../models/categories-table-display';
import type { LabelTableInterval } from '../models/labels-cashflow-display';

export function setTransactionsFilterByCategories(
  categories: CategoriesTableRow[],
  interval: LabelTableInterval | undefined,
  organizationId: string
): void {
  const property = 'category_assignment_category_id';
  const expressions = [] as FilterExpression[];
  const isUnlabeled = categories.length === 1 && categories[0]?.type === 'uncategorized';

  const getCategoriesIds = (): string[] => {
    return categories.flatMap(({ id, subRows }) => {
      if (!id) return [];
      if (subRows) {
        const subRowsIds = subRows.map(subRow => subRow.id).filter(Boolean) as string[];
        return [id, ...subRowsIds];
      }
      return [id];
    });
  };

  if (isUnlabeled) {
    expressions.push({
      property,
      operator: FilterExpressionOperator.NotExists,
      values: [],
    });
  } else {
    expressions.push({
      property,
      operator: FilterExpressionOperator.In,
      values: getCategoriesIds(),
    });
  }

  if (interval) {
    expressions.push({
      property: 'emitted_at',
      operator: FilterExpressionOperator.Within,
      values: [interval.start, interval.end],
    });
  }

  const filterGroup: FilterGroup = {
    conditional: FilterConditional.And,
    expressions: [
      {
        conditional: FilterConditional.And,
        expressions,
      },
    ],
  };

  localStorage.setItem(`${organizationId}-transactions-filter-group`, JSON.stringify(filterGroup));
}
