export default {
  "section-container": "crK",
  "disclaimer-section": "crG",
  "trip-title": "crr caption-bold mb-16",
  "google-link-container": "crb",
  "link-icon": "cry",
  "trip-details": "crU body-2",
  "trip-details-icon": "crj",
  "trip-details-title": "crW",
  "subtitle": "cru caption-bold mb-16",
  "disclaimer": "cbS",
  "visualizer": "cbc"
};
