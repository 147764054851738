import React, { useState, useEffect, useId, useRef } from 'react';
import { Form, FieldError, Label, TextField } from 'react-aria-components';
import { useIntl } from 'react-intl';
import { clsx } from 'clsx';
import { Button, Input } from '@repo/design-system-kit';
import {
  IconCalendarOutlined,
  IconCardOutlined,
  IconLockOutlined,
  IconUserOutlined,
} from '@repo/monochrome-icons';
import { CreditCardIcons } from '../internal/credit-card-icons.tsx';
import type { Mollie, MollieComponentInstance } from '../../../types/mollie.js';
import styles from './credit-card-fields.strict-module.css';

interface CreditCardFieldsProps {
  disabled?: boolean;
  mollie?: Mollie | null;
  onSubmit?: (token: string) => void;
  setIsLoading?: (loading: boolean) => void;
  selectedLocale?: string;
}

export function CreditCardFields({
  disabled,
  mollie,
  onSubmit,
  setIsLoading,
  selectedLocale,
}: CreditCardFieldsProps): React.ReactNode {
  const { formatMessage } = useIntl();

  const [hasSubmitted, setHasSubmitted] = useState(false);

  const onPress = async (): Promise<void> => {
    if (!mollie) {
      return;
    }

    setIsLoading?.(true);
    setHasSubmitted(true);

    const { token, error } = await mollie.createToken();

    setIsLoading?.(false);

    if (error) {
      return;
    }

    onSubmit?.(token);
  };

  // If `mollie` is undefined it means we are not expecting Mollie because Mollie default value is `null`
  const withMollie = typeof mollie !== 'undefined';
  const isMollieLoading = withMollie && !mollie;

  return (
    <Form className={styles.container}>
      {!isMollieLoading ? (
        <div className={styles['card-fields']}>
          <CreditCardInput
            disabled={disabled}
            hasSubmitted={hasSubmitted}
            label={formatMessage(
              {
                id: 'receivable-invoices.public-payment-link.credit-card.labels.number',
              },
              { language: selectedLocale }
            )}
            mollie={mollie}
            type="cardNumber"
            withMollie={withMollie}
          />
          <CreditCardInput
            disabled={disabled}
            hasSubmitted={hasSubmitted}
            label={formatMessage(
              {
                id: 'receivable-invoices.public-payment-link.credit-card.labels.name',
              },
              { language: selectedLocale }
            )}
            mollie={mollie}
            type="cardHolder"
            withMollie={withMollie}
          />
          <div className={styles.asides}>
            <CreditCardInput
              disabled={disabled}
              hasSubmitted={hasSubmitted}
              label={formatMessage(
                {
                  id: 'receivable-invoices.public-payment-link.credit-card.labels.expiration-date',
                },
                { language: selectedLocale }
              )}
              mollie={mollie}
              type="expiryDate"
              withMollie={withMollie}
            />
            <CreditCardInput
              disabled={disabled}
              hasSubmitted={hasSubmitted}
              label={formatMessage(
                {
                  id: 'receivable-invoices.public-payment-link.credit-card.labels.security-code',
                },
                { language: selectedLocale }
              )}
              mollie={mollie}
              type="verificationCode"
              withMollie={withMollie}
            />
          </div>
        </div>
      ) : null}
      <div className={styles.footnote}>
        <span className="caption">
          {formatMessage(
            { id: 'receivable-invoices.public-payment-link.credit-card.footnote' },
            { language: selectedLocale }
          )}
        </span>
        <CreditCardIcons />
      </div>
      <Button
        className="mb-16"
        data-pay-invoice-button
        isDisabled={isMollieLoading || disabled}
        onPress={() => void onPress()}
      >
        {formatMessage(
          { id: 'receivable-invoices.public-payment-link.cta' },
          { language: selectedLocale }
        )}
      </Button>
    </Form>
  );
}

const CREDIT_CARD_ICONS = {
  cardNumber: <IconCardOutlined aria-hidden="true" />,
  cardHolder: <IconUserOutlined aria-hidden="true" />,
  expiryDate: <IconCalendarOutlined aria-hidden="true" />,
  verificationCode: <IconLockOutlined aria-hidden="true" />,
};

interface CreditCardInputProps {
  disabled?: boolean;
  hasSubmitted: boolean;
  label: string;
  mollie?: Mollie | null;
  type: 'cardHolder' | 'cardNumber' | 'expiryDate' | 'verificationCode';
  withMollie: boolean;
}

export function CreditCardInput({
  disabled,
  hasSubmitted,
  mollie,
  type,
  label,
  withMollie,
}: CreditCardInputProps): React.ReactNode {
  const fieldId = useId();
  const componentRef = useRef<MollieComponentInstance | null>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [hasTyped, setHasTyped] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (mollie && !componentRef.current) {
      const mollieComponent = mollie.createComponent(type, {
        styles: {
          // We need to inject CSS this way as Mollie Component is an input in an iframe
          base: { color: '#1d1d1b' },
        },
      });
      mollieComponent.mount(`[id="${fieldId}"]`);
      mollieComponent.addEventListener('focus', () => {
        setIsFocused(true);
      });
      mollieComponent.addEventListener('blur', () => {
        setIsFocused(false);
      });
      mollieComponent.addEventListener('change', event => {
        if (event.dirty && !hasTyped) {
          setHasTyped(true);
        }
        setErrorMessage(event.error ?? '');
      });
      componentRef.current = mollieComponent;
    }
  }, [componentRef, fieldId, mollie, type, hasTyped]);

  useEffect(() => {
    return () => {
      if (componentRef.current) {
        componentRef.current.unmount();
        componentRef.current = null;
      }
    };
  }, [componentRef]);

  if (!withMollie) {
    return (
      <TextField className={styles['credit-card-input']} name={type}>
        <Label>{label}</Label>
        <div className={styles['input-container']}>
          <Input className={styles['input-sample']} maxLength={20} />
          <span className={styles['input-icon']}>{CREDIT_CARD_ICONS[type]}</span>
        </div>
      </TextField>
    );
  }

  const shouldShowIcon = !(hasTyped && type === 'cardNumber');

  return (
    <TextField
      className={styles['credit-card-input']}
      isDisabled={disabled}
      isInvalid={hasSubmitted ? Boolean(errorMessage) : false}
    >
      {/* We pass `type` as the `htmlFor` prop because the Mollie component passes this value to the input as its `id` */}
      <Label className="body-1" htmlFor={type}>
        {label}
      </Label>
      <div
        className={clsx(
          styles['input-container'],
          styles['input-field'],
          isFocused && styles['input-field-focused']
        )}
        id={fieldId}
      >
        {shouldShowIcon ? (
          <span className={styles['input-icon']}>{CREDIT_CARD_ICONS[type]}</span>
        ) : null}
      </div>
      <FieldError className={styles.error}>{hasSubmitted ? errorMessage : ''}</FieldError>
    </TextField>
  );
}
