export default {
  "wrapper": "cSN",
  "promotion-title": "cSv",
  "comparison-table": "cSo",
  "table-head": "cSn",
  "title-wrapper": "cSi",
  "illustration-wrapper": "cSA",
  "illustration": "cSY",
  "role-wrapper": "cSh",
  "role-name": "cSf",
  "role-disabled": "cSK",
  "role-access": "cSG",
  "disabled-caption": "cSr",
  "table-head-sticky": "cSb",
  "show-border": "cSy",
  "group-row": "cSU",
  "table-body": "cSj",
  "group": "cSW",
  "table-body-row": "cSu",
  "feature-description": "ccS body-2",
  "feature": "ccc body-2",
  "group-spacer": "ccq"
};
