export default {
  "supplier-invoices-page-header": "quO",
  "filters-container": "qum",
  "search-bar": "qup",
  "filters": "qux",
  "bulk-actions": "quw",
  "item-count": "quN",
  "higher-index": "quv",
  "title-wrapper": "quo",
  "title": "qun",
  "subtitle": "qui",
  "table-container": "quA",
  "file-dropzone": "quY",
  "dropzone-visible": "quh",
  "header-actions": "quf",
  "header-wrapper": "quK",
  "disclaimer-wrapper": "quG"
};
