import type { CashflowTimeseries } from 'qonto/react/models/cash-flow-timeseries';
import type { ForecastEntry } from 'qonto/react/models/cash-flow-forecast-entry';
import { ensureForecastAmount, updateCategoryForecast, getTypeAndData } from './helpers';

export function optimisticUpdateForecastValue(
  timeseries: CashflowTimeseries,
  forecastEntries: ForecastEntry[]
): CashflowTimeseries {
  const updatedTimeseries = { ...timeseries };

  updatedTimeseries.timeframes = updatedTimeseries.timeframes.map(timeframe => {
    const startDate = new Date(timeframe.inclusiveStartDate);
    const month = startDate.getMonth() + 1;
    const year = startDate.getFullYear();

    const matchingEntries = forecastEntries.filter(
      entry => entry.month === month && entry.year === year
    );

    matchingEntries.forEach(entry => {
      const { category_id: categoryId, amount } = entry;
      const { value, currency } = amount.value;

      const typeAndData = getTypeAndData(categoryId, timeframe);

      if (typeAndData) {
        const { data } = typeAndData;

        // Ensure forecastAmount exists
        data.forecastAmount = ensureForecastAmount(data.forecastAmount, currency);

        // Subtract the old forecast value
        const oldCategory = data.categoriesDataPoints.find(c => c.categoryId === categoryId);
        if (oldCategory?.forecastAmount) {
          data.forecastAmount.value -= oldCategory.forecastAmount.value;
        }

        // Add the new forecast value
        data.forecastAmount.value += parseFloat(value);

        // Update category forecast
        data.categoriesDataPoints = updateCategoryForecast(
          data.categoriesDataPoints,
          categoryId,
          parseFloat(value),
          currency
        );
      }
    });

    return timeframe;
  });

  return updatedTimeseries;
}
