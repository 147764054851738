/* import __COLOCATED_TEMPLATE__ from './layout.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

// @ts-expect-error
import { STATUS } from 'qonto/constants/requests';

interface RequestSidebarExpenseReportLayoutSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class RequestSidebarExpenseReportLayoutComponent extends Component<RequestSidebarExpenseReportLayoutSignature> {
  @service declare abilities: Services['abilities'];

  get isPastExpenseReport() {
    // @ts-expect-error
    let { status } = this.args.request;
    let pastStatuses = [STATUS.CANCELED, STATUS.DECLINED, STATUS.APPROVED];

    return pastStatuses.includes(status);
  }

  get showValidations() {
    // @ts-expect-error
    let { request } = this.args;
    let userId = request.get('initiator.userId');
    let isNotPastExpenseReport = !this.isPastExpenseReport;

    return (
      (this.abilities.can('cancel expense report request', userId) ||
        this.abilities.can('review expense report request')) &&
      isNotPastExpenseReport
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Request::Sidebar::ExpenseReport::Layout': typeof RequestSidebarExpenseReportLayoutComponent;
  }
}
