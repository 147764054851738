export default {
  "header-cell": "On",
  "empty": "Oi",
  "right": "OA",
  "debitor": "OY",
  "date": "Oh",
  "status": "Of",
  "amount": "OK",
  "header-amount": "OG",
  "header-content": "Or caption-bold",
  "active": "Ob"
};
