import * as React from 'react';
import { Selector } from '@repo/design-system-kit';
import type { SelectorProps } from '@repo/design-system-kit';
import type { PaymentMethods } from '../internal/payment-methods.ts';
import { PaymentMethodIcon } from '../internal/payment-method-icon.tsx';
import { PaymentMethodTitle } from '../payment-method-title/index.ts';

export type PaymentMethodValues = PaymentMethods;

interface PaymentMethodSelectorProps extends Omit<SelectorProps, 'icon' | 'title'> {
  method: PaymentMethodValues;
  selectedLocale?: string;
}

export function PaymentMethodSelector({
  method,
  selectedLocale,
  ...props
}: PaymentMethodSelectorProps): React.ReactNode {
  return (
    <Selector
      data-test-payment-method-selector={method}
      icon={<PaymentMethodIcon method={method} />}
      title={<PaymentMethodTitle method={method} selectedLocale={selectedLocale} />}
      {...props}
    />
  );
}
