// @ts-nocheck
import Service, { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import dayjs from 'dayjs';
import { dropTask } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';

import {
  apiBaseURL,
  cashFlowCategoriesNamespace,
  cashFlowCategoriesNamespaceV2,
} from 'qonto/constants/hosts';
import {
  CASHFLOW_FORECASTED_MONTHS_DEFAULT,
  CASHFLOW_REQUEST_DATE_FORMAT,
  type CashflowTimeseries,
} from 'qonto/react/api/models/cash-flow-timeseries';
import { mapTimeseriesToStatistics } from 'qonto/react/components/cash-flow/adapters/timeseries-statistics';
import {
  type CashflowPeriod,
  CashflowPeriodInterval,
  CashflowPeriodKey,
  type CashflowPeriodRate,
} from 'qonto/react/models/cash-flow-period';
import {
  getOrganizationTimeframeInterval,
  getPeriodTimeframeInterval,
} from 'qonto/react/utils/cash-flow-timeseries/period-timeframe-interval';
import { ErrorInfo } from 'qonto/utils/error-info';

interface CashFlowTimeseriesManagerProps {
  period: CashflowPeriod;
  bankAccounts: string;
  frequency?: CashflowPeriodRate;
}

export default class CashFlowTimeseriesManager extends Service {
  @service organizationManager;
  @service networkManager;
  @service sentry;

  fetchTimeseriesTask = dropTask(async ({ bankAccounts, period, frequency }) => {
    const forecastedMonths = variation('feature--boolean-cash-flow-forecast-slice-1')
      ? CASHFLOW_FORECASTED_MONTHS_DEFAULT
      : 0;
    const timeframe = frequency
      ? getOrganizationTimeframeInterval(this.organizationManager.organization, forecastedMonths)
      : getPeriodTimeframeInterval(period);

    const bankAccountsCollection = bankAccounts?.[0]?.split(',') ?? undefined;

    const request = {
      bank_account_ids: bankAccountsCollection,
      ...timeframe,
      ...(frequency && { aggregation_interval: frequency }),
    };

    const namespace = variation('feature--boolean-cash-flow-forecast-slice-1')
      ? cashFlowCategoriesNamespaceV2
      : cashFlowCategoriesNamespace;

    try {
      let response: CashflowTimeseries = await this.networkManager.request(
        `${apiBaseURL}/${namespace}/cash-flow/timeseries`,
        {
          method: 'POST',
          body: JSON.stringify(request),
        }
      );

      return mapTimeseriesToStatistics(response);
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  });
}

declare module '@ember/service' {
  interface Registry {
    'cash-flow-timeseries-manager': CashFlowTimeseriesManager;
    cashFlowTimeseriesManager: CashFlowTimeseriesManager;
  }
}
