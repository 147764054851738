export default {
  "container": "fA",
  "container-content": "fY",
  "content": "fh",
  "underline": "ff",
  "inline-content": "fK",
  "payment-rate": "fG",
  "payment-subtitle": "fr",
  "grey-text": "fb",
  "earnings-header": "fy",
  "earnings-tooltip": "fU"
};
