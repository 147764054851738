export default {
  "header-cell": "kf",
  "border-top": "kK",
  "header-content": "kG caption-bold",
  "active": "kr",
  "empty": "kb",
  "card": "ky",
  "card-holder": "kU",
  "limit": "kj",
  "align-right": "kW",
  "status": "ku",
  "hidden": "JS",
  "no-padding": "Jc"
};
