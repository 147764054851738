export default {
  "wrapper": "mb",
  "badge-trial": "my",
  "header": "mU",
  "list": "mj body-2",
  "main": "mW",
  "card": "mu",
  "container": "pS",
  "header-button": "pc",
  "error-text": "pq",
  "mt-16": "pZ"
};
