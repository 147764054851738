/* import __COLOCATED_TEMPLATE__ from './section.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

interface NotificationsSettingsSectionSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class NotificationsSettingsSection extends Component<NotificationsSettingsSectionSignature> {
  @service declare intl: Services['intl'];
  get titleKey() {
    try {
      // @ts-expect-error
      return this.args.catCode
        ? // @ts-expect-error
          this.intl.t(`users.subscriptions.${this.args.catCode}.name`)
        : this.intl.t('users.subscriptions.account.name');
    } catch {
      this.intl.t('users.subscriptions.account.name');
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NotificationsSettings::Section': typeof NotificationsSettingsSection;
  }
}
