/* import __COLOCATED_TEMPLATE__ from './value.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface ValueSignature {
  Element: HTMLSpanElement;
  Args: {};
  Blocks: {
    default: [];
  };
}

const Value = templateOnlyComponent<ValueSignature>();

export default Value;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'OrganizationProfile::Value': typeof Value;
  }
}
