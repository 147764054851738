/* import __COLOCATED_TEMPLATE__ from './summary.hbs'; */
/* eslint-disable @qonto/no-import-roles-constants */
import { get } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

// @ts-expect-error
import { hasMFAError } from '@qonto/qonto-sca/utils/mfa-error';
import { Button, Disclaimer, Flag } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';

import type { FlowStepArgs } from 'qonto/components/flow-in-flow';
import { LIMIT_TYPES, WARNINGS } from 'qonto/constants/international-out/confirmation';
import { CURRENCIES } from 'qonto/constants/international-out/currency';
import { PAY_OUT, RATE_TYPE } from 'qonto/constants/international-out/quote';
import { EVENTS } from 'qonto/constants/international-out/tracking';
// @ts-expect-error
import { ERROR_CODE } from 'qonto/constants/international-out/transfer';
import { ROLES } from 'qonto/constants/membership';
import { Summary } from 'qonto/react/components/transfers/international-out/summary';
import type { DataContext } from 'qonto/routes/flows/setup/transfers/international-out/data-context';
import type { ConfirmationWithLimit } from 'qonto/services/international-out/types';
import { formatAmountToNumber } from 'qonto/utils/amount';
import { getAllowedDecimalPlaces } from 'qonto/utils/currency';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';

interface DisclaimerConfiguration {
  level: 'error' | 'info' | 'warning';
  message: string;
}

interface Signature {
  Args: FlowStepArgs<
    DataContext & {
      beneficiary: NonNullable<DataContext['beneficiary']>;
      confirmationResult: NonNullable<DataContext['confirmationResult']>;
      fees: NonNullable<DataContext['fees']>;
      quote: NonNullable<DataContext['quote']>;
      targetAccount: NonNullable<DataContext['targetAccount']>;
      transferDetails: NonNullable<DataContext['transferDetails']>;
    }
  >;
}

export default class FlowsTransfersInternationalOutNewSummaryComponent extends Component<Signature> {
  button = Button;
  disclaimerBlock: typeof Disclaimer.Block = Disclaimer.Block;
  flag = Flag;
  summary = Summary;

  @service declare internationalOutManager: Services['internationalOutManager'];
  @service declare intl: Services['intl'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare segment: Services['segment'];
  @service declare sentry: Services['sentry'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];

  constructor(owner: unknown, args: Signature['Args']) {
    super(owner, args);
    this.segment.track(EVENTS.TRANSFER_SUMMARY_ACCESSED, {
      pay_out: this.args.context.quote?.payOut,
    });
  }

  get disclaimers(): DisclaimerConfiguration[] {
    const { confirmationResult, quote } = this.args.context;

    if (confirmationResult?.warnings.includes(WARNINGS.INSUFFICIENT_FUNDS)) {
      return [this.#insufficientFundsDisclaimer];
    }

    if (confirmationResult?.isAboveLimit) {
      return [this.#aboveLimitDisclaimer];
    }

    const disclaimers = [];

    if (quote?.rateType === RATE_TYPE.FLOATING) {
      disclaimers.push(this.#volatilityDisclaimer);
    }

    if (confirmationResult?.warnings.includes(WARNINGS.ALREADY_ISSUED)) {
      disclaimers.push(this.#alreadyIssuedDisclaimer);
    }

    if (quote?.payOut === PAY_OUT.SHA) {
      disclaimers.push(this.#shaDisclaimer);
    }

    return disclaimers;
  }

  get #aboveLimitDisclaimer(): DisclaimerConfiguration {
    const { limitType, spendings } = this.args.context.confirmationResult as ConfirmationWithLimit;

    return {
      level: 'error',
      message:
        limitType === LIMIT_TYPES.MONTHLY
          ? this.intl.t('international-out.summary.errors.monthly-limit', {
              balanceMonthlyTransferLimit: spendings.monthlyLimit - spendings.currentMonth,
            })
          : this.intl.t('international-out.summary.errors.per-transfer-limit', {
              perTransferLimit: spendings.perTransferLimit,
            }),
    };
  }

  get #alreadyIssuedDisclaimer(): DisclaimerConfiguration {
    return {
      level: 'warning',
      message: this.intl.t('international-out.summary.warnings.already-sent'),
    };
  }

  get #insufficientFundsDisclaimer(): DisclaimerConfiguration {
    return {
      level: 'error',
      message:
        this.organizationManager.membership.role === ROLES.MANAGER
          ? this.intl.t('international-out.summary.errors.non-admin-insufficient-funds')
          : this.intl.t('international-out.summary.errors.insufficient-funds'),
    };
  }

  get #shaDisclaimer(): DisclaimerConfiguration {
    const targetCurrency = this.args.context.quote.targetCurrency;
    let message = this.intl.t('international-out.summary.information.sha', {
      currency: targetCurrency,
    });

    if (targetCurrency === CURRENCIES.EUR) {
      message = this.intl.t('international-out.summary.information.sha-eur');
    }

    if (targetCurrency === CURRENCIES.USD) {
      message = this.intl.t('international-out.summary.information.sha-usd');
    }

    return {
      level: 'info',
      message,
    };
  }

  get #volatilityDisclaimer(): DisclaimerConfiguration {
    return {
      level: 'warning',
      message: this.intl.t('international-out.summary.warnings.volatile', {
        currency: this.args.context.quote.targetCurrency,
      }),
    };
  }

  get #isAttachmentRequired(): boolean {
    return (
      Boolean(this.args.context.invoice) ||
      this.#totalAmount >= this.args.context.settings.attachment.maximumAmountWithout
    );
  }

  get #totalAmount(): number {
    const { quote, fees } = this.args.context;
    return formatAmountToNumber({
      amount: quote.sourceAmount + fees.total,
      precision: getAllowedDecimalPlaces(quote.sourceCurrency),
    });
  }

  createTransferTask = dropTask(async (): Promise<void> => {
    const { context, transitionToNext } = this.args;
    const { attachments, bankAccount, beneficiary, quote, targetAccount, transferDetails } =
      context;

    this.internationalOutManager.addIdempotencyHeader();

    try {
      context.transfer = await this.internationalOutManager.createTransfer({
        bankAccountId: bankAccount.id,
        beneficiaryId: beneficiary.id,
        quoteId: quote.id,
        targetAccountId: targetAccount.id,
        sourceAmount: {
          currency: quote.sourceCurrency,
          value: this.#totalAmount,
        },
        targetAmount: {
          currency: quote.targetCurrency,
          value: quote.targetAmount,
        },
        details: transferDetails,
        ...(this.#isAttachmentRequired && {
          attachmentIds: attachments.map(({ id }) => id),
        }),
      });

      attachments
        .filter(attachment => get(attachment, 'isNew'))
        // @ts-expect-error
        .forEach(attachment => attachment.unloadRecord());

      this.segment.track(EVENTS.TRANSFER_SUBMITTED);

      transitionToNext();
    } catch (error) {
      // @ts-expect-error
      if (hasMFAError(error?.errors)) {
        throw error;
      }

      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));

      // @ts-expect-error
      if (error?.errors?.some(({ code }) => code === ERROR_CODE.QUOTE_EXPIRED)) {
        this.segment.track(EVENTS.QUOTE_EXPIRED);
      }
    } finally {
      this.internationalOutManager.removeIdempotencyHeader();
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Transfers::InternationalOut::New::Summary': typeof FlowsTransfersInternationalOutNewSummaryComponent;
  }
}
