/* import __COLOCATED_TEMPLATE__ from './email-form.hbs'; */
import Component from '@glimmer/component';

import { Disclaimer, TextAreaField, TextField } from '@repo/design-system-kit';

interface DirectDebitCollectionsEmailFormSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class DirectDebitCollectionsEmailFormComponent extends Component<DirectDebitCollectionsEmailFormSignature> {
  disclaimerInline = Disclaimer.Inline;
  textAreaField = TextAreaField;
  textField = TextField;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'DirectDebitCollections::EmailForm': typeof DirectDebitCollectionsEmailFormComponent;
  }
}
