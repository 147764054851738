/* import __COLOCATED_TEMPLATE__ from './vat-rate-selector.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
// @ts-expect-error
import { cached, tracked } from '@glimmer/tracking';

import { reads } from 'macro-decorators';

import { VAT_RATE_PRECISION } from 'qonto/constants/receivable-invoice';
// @ts-expect-error
import { getVatExemptionReasons } from 'qonto/utils/receivable-invoicing';

interface VatRateSelectorSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class VatRateSelectorComponent extends Component<VatRateSelectorSignature> {
  @service declare intl: Services['intl'];
  @service declare organizationManager: Services['organizationManager'];

  @tracked selectedVatOption = this.getVatRateOption();
  // @ts-expect-error
  @tracked vatRatePercentage = String(this.model.vatRate * 100).replace(
    /[,.]/g,
    this.decimalSeparator
  );
  // @ts-expect-error
  @reads('args.model') model;
  // @ts-expect-error
  @reads('organizationManager.organization.legalCountry') legalCountry;

  @cached
  get vatExemptionReasons() {
    return getVatExemptionReasons(this.intl, this.legalCountry);
  }

  get decimalSeparator() {
    // @ts-expect-error
    return new Intl.NumberFormat(this.intl.primaryLocale)
      .formatToParts(0.5)
      .find(({ type }) => type === 'decimal').value;
  }

  get shouldDisplayVatExemptionField() {
    return parseFloat(this.model.vatRate) === 0 || this.model.errors.get('vatExemptionCode');
  }

  get selectedVatExemptionReason() {
    // @ts-expect-error
    let vatExemptionCode;

    if (this.model.vatExemptionCode) {
      vatExemptionCode = this.model.vatExemptionCode;
    } else if (this.model.vatRate === 0 && this.legalCountry === 'IT') {
      vatExemptionCode = 'N2.2';
    }

    return this.vatExemptionReasons.find(
      // @ts-expect-error
      vatExemptionReason => vatExemptionReason.key === vatExemptionCode
    );
  }

  // @ts-expect-error
  @action handleVatRateInput(newRate) {
    this.vatRatePercentage = newRate;
    this.setItemVatRate(newRate.replace(/[,.]/g, '.') / 100);
  }

  // @ts-expect-error
  @action handleVatRateChange(newRate) {
    this.vatRatePercentage = newRate.replace(/[,.]/g, this.decimalSeparator);
    this.setItemVatRate(newRate.replace(/[,.]/g, '.') / 100);
  }

  // @ts-expect-error
  @action updateVatOption(option) {
    this.selectedVatOption = option;
    if (option.value >= 0) {
      this.setItemVatRate(option.value / 100);
    } else {
      // @ts-expect-error
      this.vatRatePercentage = null;
      this.setItemVatRate(null);
    }
  }

  // @ts-expect-error
  @action updateVatExemptionReason({ key }) {
    this.model.vatExemptionCode = key;
  }

  // @ts-expect-error
  setItemVatRate(value) {
    this.model.vatRate = value === null ? null : String(value);
    if (value !== 0) this.model.vatExemptionCode = null;
    if (value === 0 && this.legalCountry === 'IT') {
      this.model.vatExemptionCode = 'N2.2';
    } else this.model.vatExemptionCode = undefined;
  }

  getVatRateOption() {
    if (this.model.vatRate === undefined) return undefined;
    let vatCategory = this.organizationManager.organization.vatRatesCategories.find(
      // @ts-expect-error
      vatRateCategory =>
        Math.abs(vatRateCategory - parseFloat(this.model.vatRate) * 100) < VAT_RATE_PRECISION
    );

    if (vatCategory !== undefined)
      return {
        clearable: false,
        value: vatCategory,
      };

    return { clearable: true, value: -1 };
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Products::CreationFormModal::VatRateSelector': typeof VatRateSelectorComponent;
  }
}
