export default {
  "budget-creation-form": "Et",
  "period-label": "EM",
  "periods": "EP",
  "yearly": "El",
  "yearly-toggle": "EX",
  "toggle-tooltip": "EC",
  "tooltip": "Ek",
  "year": "EJ",
  "total-budget": "Es",
  "total-budget-value": "EF",
  "divider": "Eg"
};
