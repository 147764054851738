export default {
  "wrapper": "lU",
  "content-wrapper": "lj",
  "no-fee": "lW",
  "concierge": "lu",
  "no-fee-item": "XS",
  "concierge-item": "Xc",
  "no-fee-icon": "Xq",
  "lounge-icon": "XZ",
  "insurance-conditions": "XR",
  "description": "XQ",
  "footer-detail": "Xe",
  "title": "XB",
  "footer-price": "XE",
  "footer-content": "Xd",
  "lounge-text": "XI",
  "lounge": "Xt",
  "lounge-images": "XM",
  "img": "XP",
  "footer-sticky-bar": "Xl",
  "visible": "XX",
  "content": "XC",
  "content-card": "Xk"
};
