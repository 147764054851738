/* import __COLOCATED_TEMPLATE__ from './row.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { DATE_FORMAT_TOKENS, dateToken } from '@qonto/ui-kit/utils/date-token';

import { InstructionalTooltip } from 'qonto/react/components/product-discovery/instructional-tooltip';

interface FinancingPayLaterCockpitInProgressTableRowSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLTableRowElement;
}

export default class FinancingPayLaterCockpitInProgressTableRowComponent extends Component<FinancingPayLaterCockpitInProgressTableRowSignature> {
  InstructionalTooltip = InstructionalTooltip;

  @service declare intl: Services['intl'];
  @service declare segment: Services['segment'];

  constructor(owner: unknown, args: FinancingPayLaterCockpitInProgressTableRowSignature['Args']) {
    super(owner, args);

    // @ts-expect-error
    if (this.args.shouldDisplayEarlyRepaymentTooltip) {
      this.segment.track('pay-later_early-repayment-promo_displayed');
    }
  }

  get warning() {
    // @ts-expect-error
    let { bankAccountName, nextInstallmentAt } = this.args.financing;
    let formattedDate = dateToken({
      date: nextInstallmentAt,
      token: DATE_FORMAT_TOKENS.DATE_S,
      // @ts-expect-error
      locale: this.intl.primaryLocale,
    });

    // @ts-expect-error
    switch (this.args.financing.repaymentStatus) {
      case 'at_risk':
        return {
          tooltipMessage: this.intl.t(
            'financing.pay-later.cockpit.table.row.installment-amount.at-risk.tooltip',
            { account_name: bankAccountName, date: formattedDate }
          ),
          icon: 'icon_warning_sign_filled',
          text: this.intl.t(
            'financing.pay-later.cockpit.table.row.installment-amount.at-risk.disclaimer'
          ),
        };
      case 'late':
        return {
          tooltipMessage: this.intl.t(
            'financing.pay-later.cockpit.table.row.installment-amount.overdue.tooltip',
            { account_name: bankAccountName }
          ),
          icon: 'icon_error_filled',
          text: this.intl.t(
            'financing.pay-later.cockpit.table.row.installment-amount.overdue.disclaimer'
          ),
        };
      default:
        return null;
    }
  }

  get isProcessing() {
    // @ts-expect-error
    return this.args.financing.isProcessing;
  }

  @action
  handleOnCloseTooltip() {
    // @ts-expect-error
    this.args.onCloseTooltip();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Financing::PayLater::Cockpit::InProgress::Table::Row': typeof FinancingPayLaterCockpitInProgressTableRowComponent;
  }
}
