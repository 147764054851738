// @ts-nocheck
import Service, { service } from '@ember/service';

export default class AccountReceivableOnboardingManager extends Service {
  @service store;
  @service organizationManager;

  get isNotEligibleForOnboarding() {
    let onboardingState = this.store.peekRecord(
      'accounts-receivable-onboarding',
      this.organizationManager.organization.id
    );
    return onboardingState?.status === 'not_eligible';
  }
}

declare module '@ember/service' {
  interface Registry {
    'account-receivable-onboarding-manager': AccountReceivableOnboardingManager;
    accountReceivableOnboardingManager: AccountReceivableOnboardingManager;
  }
}
