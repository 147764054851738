export default {
  "row": "cgm",
  "terminated": "cgp",
  "status-avatar": "cgx",
  "amount": "cgw body-1",
  "item-info": "cgN",
  "date-cell": "cgv",
  "cell": "cgo body-2",
  "status-icon": "cgn",
  "amount-cell": "cgi",
  "item-name-cell": "cgA",
  "item-name-container": "cgY"
};
