export default {
  "step-container": "cqE",
  "subtitle": "cqd",
  "step-container-content": "cqI",
  "continue-button": "cqt",
  "period-select": "cqM",
  "period-dropdown": "cqP",
  "readonly": "cql",
  "period-error": "cqX",
  "toggle-options-container": "cqC",
  "receipt-code-toggle-input": "cqk"
};
