export default {
  "row": "CC",
  "active": "Ck",
  "status": "CJ",
  "align-right": "Cs",
  "quick-actions": "CF",
  "cell-approve": "Cg",
  "account-select": "CD",
  "hidden": "CV",
  "no-padding": "CL",
  "animated": "CT",
  "fadein-item": "Cz",
  "cell": "Ca body-2",
  "cell-content": "CH",
  "amount": "CO body-1",
  "subtitle": "Cm caption"
};
