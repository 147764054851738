/* import __COLOCATED_TEMPLATE__ from './collapsable-switcher.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface CollapsableSwitcherSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const CollapsableSwitcher = templateOnlyComponent<CollapsableSwitcherSignature>();

export default CollapsableSwitcher;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'FormFields::CollapsableSwitcher': typeof CollapsableSwitcher;
  }
}
