export default {
  "card": "cwH",
  "error": "cwO",
  "customer-info": "cwm",
  "edit-customer-tooltip": "cwp",
  "title": "cwx",
  "flex-row": "cww",
  "dot-separator": "cwN",
  "flex-span": "cwv",
  "faq-link": "cwo",
  "tooltip-wrapper": "cwn",
  "badge-union": "cwi"
};
