export default {
  "step-container": "cqO",
  "flex-table": "cqm",
  "flex-row": "cqp",
  "flex-cell": "cqx",
  "with-border": "cqw",
  "with-border-top": "cqN",
  "secondary": "cqv",
  "flex-info": "cqo",
  "amount": "cqn",
  "legal-name": "cqi"
};
