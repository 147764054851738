export default {
  "bucket-item": "ctf",
  "button": "ctK",
  "content": "ctG",
  "bucket-icon": "ctr",
  "bucket-title-container": "ctb",
  "bucket-new-badge": "cty",
  "bucket-title": "ctU",
  "arrow-icon": "ctj"
};
