export default {
  "edit": "Pe",
  "upsell": "PB",
  "options": "PE",
  "option": "Pd body-2 mb-16",
  "option-line": "PI",
  "category-tags": "Pt",
  "option-icons": "PM",
  "option-icon": "PP",
  "valid": "Pl"
};
