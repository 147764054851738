export default {
  "card": "rq",
  "asset": "rZ",
  "container": "rR",
  "subtitle": "rQ",
  "header": "re",
  "header-name": "rB",
  "option-list": "rE",
  "feature": "rd",
  "highlighted": "rI"
};
