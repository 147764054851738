/* import __COLOCATED_TEMPLATE__ from './insight-pill-v2.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Tooltip } from '@repo/design-system-kit';

import { DUE_FILTER_EVENT_MAP } from 'qonto/constants/supplier-invoice';
import { Counter } from 'qonto/react/components/counter';

interface SupplierInvoicesInsightPillV2Signature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLElement;
}

export default class SupplierInvoicesInsightPillV2 extends Component<SupplierInvoicesInsightPillV2Signature> {
  @service declare keyboardFocus: Services['keyboardFocus'];
  @service declare abilities: Services['abilities'];
  @service declare intl: Services['intl'];

  @tracked focused = false;

  Counter = Counter;
  tooltip = Tooltip;

  constructor(owner: unknown, args: SupplierInvoicesInsightPillV2Signature['Args']) {
    super(owner, args);
    this.keyboardFocus.init();
  }

  get eventProps() {
    // @ts-expect-error
    return { card_type: DUE_FILTER_EVENT_MAP[this.args.query.dueDate] };
  }

  get hideMissingAmounts() {
    // @ts-expect-error
    return this.args.isLoading || !this.args.missingAmounts;
  }

  get missingAmountsMessage() {
    // @ts-expect-error
    let missingAmounts = this.intl.formatNumber(this.args.missingAmounts);

    return this.intl.t('supplier-invoices.cockpit.to-pay-today.missing-amount-tooltip-label', {
      count: missingAmounts,
    });
  }

  get isInteractive() {
    // @ts-expect-error
    return this.args.currentInsight > 0 && this.args.query;
  }

  @action
  // @ts-expect-error
  setFocus(focused) {
    this.focused = focused;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'SupplierInvoices::InsightPillV2': typeof SupplierInvoicesInsightPillV2;
  }
}
