export default {
  "integration-item": "cMQ",
  "icon-container": "cMe",
  "content": "cMB",
  "title-container": "cME",
  "new-badge": "cMd",
  "title": "cMI",
  "description": "cMt",
  "no-attributes": "cMM",
  "connect-cta": "cMP"
};
