/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { DATE_FORMAT_TOKENS, dateToken } from '@qonto/ui-kit/utils/date-token';
import { BadgeHighlight, Disclaimer, SkeletonLoader } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';
// @ts-expect-error
import { variation } from 'ember-launch-darkly';
import { reads } from 'macro-decorators';

import { registerJsURL } from 'qonto/constants/hosts';
import { PRICE_PLAN_LINEUPS } from 'qonto/constants/price-plan';
import { SUBSCRIPTION_RECURRENCES, TRACKING_TRIAL_STATE } from 'qonto/constants/subscriptions';
import { HeaderAddonsOverview } from 'qonto/react/components/subscription/header/addons-overview';
import { SubscriptionHeaderDeactivated } from 'qonto/react/components/subscription/header/deactivated';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

// @ts-expect-error
import styles from './header.module.css';

interface SubscriptionHeaderSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class SubscriptionHeaderComponent extends Component<SubscriptionHeaderSignature> {
  disclaimerInline = Disclaimer.Inline;

  badgeHighlight = BadgeHighlight;
  subscriptionHeaderDeactivated = SubscriptionHeaderDeactivated;
  HeaderAddonsOverview = HeaderAddonsOverview;

  @service declare organizationManager: Services['organizationManager'];
  @service declare subscriptionManager: Services['subscriptionManager'];
  @service declare intl: Services['intl'];
  @service declare abilities: Services['abilities'];
  @service declare flowLinkManager: Services['flowLinkManager'];
  @service declare segment: Services['segment'];
  @service declare store: Services['store'];

  styles = styles;
  placeholderLine = SkeletonLoader.Line;
  placeholderBlock = SkeletonLoader.Block;

  // @ts-expect-error
  @tracked deactivationRequester = this.args.deactivationRequester;
  // @ts-expect-error
  @tracked deactivated = this.args.deactivated;
  // @ts-expect-error
  @tracked previousPlanCode;

  // @ts-expect-error
  @reads('subscriptionManager.currentSubscription') currentSubscription;
  // @ts-expect-error
  @reads('subscriptionManager.currentPricePlan') currentPlan;
  // @ts-expect-error
  @reads('organizationManager.membership.role') role;
  // @ts-expect-error
  @reads('organizationManager.organization') organization;

  registerLink = `${registerJsURL}/signup/success`;

  constructor(owner: unknown, args: SubscriptionHeaderSignature['Args']) {
    super(owner, args);
    this.fetchPreviousProductTask.perform().catch(ignoreCancelation);
  }

  fetchPreviousProductTask = dropTask(async () => {
    if (this.activeTrial?.previous_product_id) {
      let plan = await this.store.findRecord(
        'subscription-product',
        this.activeTrial.previous_product_id
      );
      this.previousPlanCode = plan?.code;
    }
  });

  get planOverviewDescription() {
    let isAnnual =
      this.subscriptionManager.currentSubscription?.recurrence === SUBSCRIPTION_RECURRENCES.ANNUAL;
    if (isAnnual) {
      return {
        part1: this.intl.t(
          'subscription.consumption-table.plan-overview.plan.description.annual.part-1',
          {
            planName: this.currentPlan.localName,
            htmlSafe: true,
          }
        ),
        part2: this.intl.t(
          'subscription.consumption-table.plan-overview.plan.description.annual.part-2'
        ),
      };
    }

    return {
      part1: this.intl.t('subscription.consumption-table.plan-overview.plan.description.part-1', {
        planName: this.currentPlan.localName,
        htmlSafe: true,
      }),
      part2: this.intl.t('subscription.consumption-table.plan-overview.plan.description.part-2'),
    };
  }

  get trialInfo() {
    return this.subscriptionManager.currentSubscription?.availableTrials.find(
      // @ts-expect-error
      trial => trial.productType === 'core'
    );
  }

  get monthDuration() {
    return this.trialInfo.monthDuration;
  }

  get isSuspendedItalianOrganization() {
    return (
      variation('feature--boolean-boi-restrictions-suspended-accounts') &&
      this.organization.legalCountry === 'IT' &&
      this.organizationManager.organization.isSuspended
    );
  }

  get previousPlanRecurrence() {
    return this.activeTrial?.previous_recurrence;
  }

  get nextSubscriptionBillingDateFormatted() {
    return dateToken({
      date: this.currentSubscription?.nextSubscriptionBillingDate,
      // @ts-expect-error
      locale: this.intl.primaryLocale,
      token: DATE_FORMAT_TOKENS.DATE_YEAR_L,
    });
  }

  get isActiveTrial() {
    return Boolean(this.currentSubscription?.activeTrial?.previous_product_id);
  }

  get activeTrial() {
    return this.currentSubscription?.activeTrial;
  }

  get defaultLineup() {
    return this.currentPlan.lineup || PRICE_PLAN_LINEUPS.SOLO;
  }

  @action onDeactivationCanceled() {
    this.deactivationRequester = null;
  }

  get canSeeAddonOverview() {
    return (
      this.abilities.can('update subscription') &&
      this.abilities.can('view subscription') &&
      !this.deactivated &&
      !this.deactivationRequester &&
      !this.isSuspendedItalianOrganization
    );
  }

  @action
  // @ts-expect-error
  handleAddonClick(trackingId) {
    this.segment.track('addon_management_entrypoint_clicked', {
      price_plan: this.currentPlan.code,
      price_plan_recurrence: this.currentSubscription.recurrence,
      trial_state: this.currentSubscription.hasInitialTrial
        ? TRACKING_TRIAL_STATE.INITIAL_TRIAL
        : TRACKING_TRIAL_STATE.NONE,
      has_active_addon: this.subscriptionManager.currentAddons?.length > 0,
      origin: trackingId,
    });

    this.flowLinkManager.transitionTo({
      name: 'addon-change',
      stepId: 'addons',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Subscription::Header': typeof SubscriptionHeaderComponent;
  }
}
