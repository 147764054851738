/* import __COLOCATED_TEMPLATE__ from './transactions-bulk-selection.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { reads } from 'macro-decorators';

// @ts-expect-error
import { CLAIM_TYPES } from 'qonto/constants/transactions';
import { CardDescriptionBox } from 'qonto/react/components/flows/chargeback-claim/card-description-box';

interface FlowsChargebackClaimTransactionsBulkSelectionTransactionsBulkSelectionSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsChargebackClaimTransactionsBulkSelectionTransactionsBulkSelectionComponent extends Component<FlowsChargebackClaimTransactionsBulkSelectionTransactionsBulkSelectionSignature> {
  @service declare intl: Services['intl'];
  @service declare segment: Services['segment'];

  CardDescriptionBox = CardDescriptionBox;

  // @ts-expect-error
  @reads('args.context.selectedTransactions') selectedTransactions;

  @tracked shouldShowFooter = true;

  get subtitle() {
    // @ts-expect-error
    let { claimType } = this.args.context;
    switch (claimType) {
      case CLAIM_TYPES.atm:
        return this.intl.t('dispute-flow.transactions-selection.subtitle.atm');
      case CLAIM_TYPES.fraud:
        return this.intl.t('dispute-flow.transactions-selection.subtitle.fraud');
      case CLAIM_TYPES.commercial:
        return this.intl.t('dispute-flow.transactions-selection.subtitle.commercial');
      default:
        return null;
    }
  }

  get total() {
    // @ts-expect-error
    return this.selectedTransactions?.reduce((total, transaction) => total + transaction.amount, 0);
  }

  get isContinueDisabled() {
    return this.selectedTransactions?.length === 0;
  }

  @action
  // @ts-expect-error
  setShouldShowFooter(value) {
    this.shouldShowFooter = value;
  }

  @action
  continue() {
    this.segment.track('cards-chargeback_bulk-selection-continue-cta_clicked');
    // @ts-expect-error
    this.args.transitionToNext();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::ChargebackClaim::TransactionsBulkSelection': typeof FlowsChargebackClaimTransactionsBulkSelectionTransactionsBulkSelectionComponent;
  }
}
