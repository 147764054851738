/* import __COLOCATED_TEMPLATE__ from './sdi-status-box.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { Disclaimer, Tag } from '@repo/design-system-kit';

import { EINVOICING_STATUS, EINVOICING_STATUS_COLORS } from 'qonto/constants/receivable-invoice';

interface ReceivableInvoicesEInvoicingStatusSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class ReceivableInvoicesEInvoicingStatusComponent extends Component<ReceivableInvoicesEInvoicingStatusSignature> {
  disclaimerInline = Disclaimer.Inline;

  tag = Tag;

  @service declare intl: Services['intl'];

  _einvoicingStatusTranslations = {
    // @ts-expect-error
    [EINVOICING_STATUS.APPROVED]: this.intl.t(
      'receivable-invoices.invoice-modal.sdi.approved.status'
    ),
    // @ts-expect-error
    [EINVOICING_STATUS.NOT_DELIVERED]: this.intl.t(
      'receivable-invoices.invoice-modal.sdi.pending-delivery.status'
    ),
    // @ts-expect-error
    [EINVOICING_STATUS.PENDING]: this.intl.t(
      'receivable-invoices.invoice-modal.sdi.pending.status'
    ),
    // @ts-expect-error
    [EINVOICING_STATUS.REJECTED]: this.intl.t(
      'receivable-invoices.invoice-modal.sdi.declined.status'
    ),
    // @ts-expect-error
    [EINVOICING_STATUS.SUBMITTED]: this.intl.t(
      'receivable-invoices.invoice-modal.sdi.submitted.status'
    ),
    // @ts-expect-error
    [EINVOICING_STATUS.SUBMISSION_FAILED]: this.intl.t(
      'receivable-invoices.invoice-modal.sdi.submission-failed.status'
    ),
  };

  _creditNoteStatusTranslations = {
    // @ts-expect-error
    [EINVOICING_STATUS.APPROVED]: this.intl.t(
      'receivable-invoices.credit-note-modal.sdi.approved.status'
    ),
    // @ts-expect-error
    [EINVOICING_STATUS.NOT_DELIVERED]: this.intl.t(
      'receivable-invoices.credit-note-modal.sdi.pending-delivery.status'
    ),
    // @ts-expect-error
    [EINVOICING_STATUS.PENDING]: this.intl.t(
      'receivable-invoices.invoice-modal.sdi.pending.status'
    ),
    // @ts-expect-error
    [EINVOICING_STATUS.REJECTED]: this.intl.t(
      'receivable-invoices.credit-note-modal.sdi.declined.status'
    ),
    // @ts-expect-error
    [EINVOICING_STATUS.SUBMITTED]: this.intl.t(
      'receivable-invoices.credit-note-modal.sdi.submitted.status'
    ),
    // @ts-expect-error
    [EINVOICING_STATUS.SUBMISSION_FAILED]: this.intl.t(
      'receivable-invoices.credit-note-modal.sdi.submission-failed.status'
    ),
  };

  _einvoicingStatusDisclaimerTranslations = {
    // @ts-expect-error
    [EINVOICING_STATUS.APPROVED]: this.intl.t(
      'receivable-invoices.invoice-modal.sdi.approved.disclaimer'
    ),
    // @ts-expect-error
    [EINVOICING_STATUS.NOT_DELIVERED]: this.intl.t(
      'receivable-invoices.invoice-modal.sdi.pending-delivery.disclaimer'
    ),
    // @ts-expect-error
    [EINVOICING_STATUS.PENDING]: this.intl.t(
      'receivable-invoices.invoice-modal.sdi.pending.disclaimer'
    ),
    // @ts-expect-error
    [EINVOICING_STATUS.REJECTED]: this.intl.t(
      'receivable-invoices.invoice-modal.sdi.declined.disclaimer'
    ),
    // @ts-expect-error
    [EINVOICING_STATUS.SUBMITTED]: this.intl.t(
      'receivable-invoices.invoice-modal.sdi.submitted.disclaimer'
    ),
    // @ts-expect-error
    [EINVOICING_STATUS.SUBMISSION_FAILED]: this.intl.t(
      'receivable-invoices.invoice-modal.sdi.submission-failed.disclaimer'
    ),
  };

  _einvoicingCreditNoteStatusDisclaimerTranslations = {
    // @ts-expect-error
    [EINVOICING_STATUS.APPROVED]: this.intl.t(
      'receivable-invoices.credit-note-modal.sdi.approved.disclaimer'
    ),
    // @ts-expect-error
    [EINVOICING_STATUS.NOT_DELIVERED]: this.intl.t(
      'receivable-invoices.credit-note-modal.sdi.pending-delivery.disclaimer'
    ),
    // @ts-expect-error
    [EINVOICING_STATUS.PENDING]: this.intl.t(
      'receivable-invoices.credit-note-modal.sdi.pending.disclaimer'
    ),
    // @ts-expect-error
    [EINVOICING_STATUS.REJECTED]: this.intl.t(
      'receivable-invoices.credit-note-modal.sdi.declined.disclaimer'
    ),
    // @ts-expect-error
    [EINVOICING_STATUS.SUBMITTED]: this.intl.t(
      'receivable-invoices.credit-note-modal.sdi.submitted.disclaimer'
    ),
    // @ts-expect-error
    [EINVOICING_STATUS.SUBMISSION_FAILED]: this.intl.t(
      'receivable-invoices.credit-note-modal.sdi.submission-failed.disclaimer'
    ),
  };

  _einvoicingSelfBilledInvoiceDisclaimerTranslations = {
    // @ts-expect-error
    [EINVOICING_STATUS.APPROVED]: this.intl.t('self-billing.view-modal.sdi.approved.disclaimer'),
    // @ts-expect-error
    [EINVOICING_STATUS.NOT_DELIVERED]: this.intl.t(
      'self-billing.view-modal.sdi.pending.disclaimer'
    ),
    // @ts-expect-error
    [EINVOICING_STATUS.PENDING]: this.intl.t('self-billing.view-modal.sdi.pending.disclaimer'),
    // @ts-expect-error
    [EINVOICING_STATUS.REJECTED]: this.intl.t('self-billing.view-modal.sdi.declined.disclaimer'),
    // @ts-expect-error
    [EINVOICING_STATUS.SUBMITTED]: this.intl.t('self-billing.view-modal.sdi.submitted.disclaimer'),
    // @ts-expect-error
    [EINVOICING_STATUS.SUBMISSION_FAILED]: this.intl.t(
      'self-billing.view-modal.sdi.submission-failed.disclaimer'
    ),
  };

  get eStatus() {
    // @ts-expect-error
    let { einvoicingStatus } = this.args;

    return {
      text: this.einvoicingStatus,
      // @ts-expect-error
      color: EINVOICING_STATUS_COLORS[einvoicingStatus],
    };
  }

  get einvoicingStatus() {
    // @ts-expect-error
    let { isCreditNote, einvoicingStatus } = this.args;

    if (isCreditNote) {
      return this._creditNoteStatusTranslations[einvoicingStatus];
    }

    return this._einvoicingStatusTranslations[einvoicingStatus];
  }

  get einvoicingStatusDisclaimer() {
    // @ts-expect-error
    if (this.args.isSelfBillingInvoice)
      // @ts-expect-error
      return this._einvoicingSelfBilledInvoiceDisclaimerTranslations[this.args.einvoicingStatus];

    // @ts-expect-error
    if (this.args.isCreditNote)
      // @ts-expect-error
      return this._einvoicingCreditNoteStatusDisclaimerTranslations[this.args.einvoicingStatus];

    // @ts-expect-error
    return this._einvoicingStatusDisclaimerTranslations[this.args.einvoicingStatus];
  }

  get einvoicingStatusLevel() {
    return {
      [EINVOICING_STATUS.APPROVED]: 'info',
      [EINVOICING_STATUS.NOT_DELIVERED]: 'error',
      [EINVOICING_STATUS.PENDING]: 'warning',
      [EINVOICING_STATUS.REJECTED]: 'error',
      [EINVOICING_STATUS.SUBMITTED]: 'warning',
      [EINVOICING_STATUS.SUBMISSION_FAILED]: 'error',
      // @ts-expect-error
    }[this.args.einvoicingStatus];
  }

  get einvoicingStatusDisplay() {
    // @ts-expect-error
    return Object.values(EINVOICING_STATUS).includes(this.args.einvoicingStatus);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Invoices::SdiStatusBox': typeof ReceivableInvoicesEInvoicingStatusComponent;
  }
}
