/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';

// @ts-expect-error
import { ACTIVITY_TAGS } from 'qonto/constants/categories';
// @ts-expect-error
import { CLAIM_TYPES } from 'qonto/constants/transactions';
import { Loading } from 'qonto/react/components/help-center/loading';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

const CLAIM_ITEMS_ICONS = {
  [CLAIM_TYPES.commercial]: 'icon_nav_payment_issue',
  [CLAIM_TYPES.fraud]: 'icon_warning_sign_outlined',
  [CLAIM_TYPES.atm]: 'icon_nav_atm',
};

interface HelpCenterSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class HelpCenterComponent extends Component<HelpCenterSignature> {
  loading = Loading;
  @service declare intl: Services['intl'];
  @service declare prismic: Services['prismic'];
  @service declare segment: Services['segment'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare zendeskWidget: Services['zendeskWidget'];
  @service declare zendeskHelpcenter: Services['zendeskHelpcenter'];

  CLAIM_ITEMS_ICONS = CLAIM_ITEMS_ICONS;

  // @ts-expect-error
  @tracked articles;
  @tracked articlesCount = 0;
  @tracked selectedArticle = null;
  @tracked selectedClaimType = null;

  constructor(owner: unknown, args: HelpCenterSignature['Args']) {
    super(owner, args);
    this._fetchArticlesTask.perform().catch(ignoreCancelation);
  }

  get hasCardSubject() {
    // Card transactions aggregated from external accounts
    // do not have a proper card subject
    // @ts-expect-error
    return Boolean(this.args.highlightedItem.subjectId);
  }

  get atmClaimType() {
    return CLAIM_TYPES.atm;
  }

  get commercialClaimType() {
    return CLAIM_TYPES.commercial;
  }

  get fraudClaimType() {
    return CLAIM_TYPES.fraud;
  }

  get isAtmWithdrawal() {
    // @ts-expect-error
    let activityTag = this.args.highlightedItem?.activityTag;
    return activityTag === ACTIVITY_TAGS.ATM;
  }

  get categoriesTypesFromArticles() {
    return this.zendeskHelpcenter.categoriesTypesFromArticles({ articles: this.articles });
  }

  get hasBackButton() {
    return Boolean(this.selectedArticle) || Boolean(this.selectedClaimType);
  }

  get headerTitle() {
    if (this.selectedArticle) {
      // @ts-expect-error
      return this.selectedArticle.data.faq_article_title;
    }

    if (this.selectedClaimType) {
      switch (this.selectedClaimType) {
        case this.commercialClaimType:
          return this.intl.t('transactions.sidebar.helper.dispute.commercial.title');
        case this.fraudClaimType:
          return this.intl.t('transactions.sidebar.helper.dispute.fraud.title');
        case this.atmClaimType:
          return this.intl.t('transactions.sidebar.helper.dispute.atm.title');
      }
    }
    return this.intl.t('transactions.sidebar.helper.title');
  }

  get isEmpty() {
    return this.articlesCount === 0 && !this.isLoading;
  }

  get isError() {
    return this._fetchArticlesTask.last?.isError;
  }

  get isLoading() {
    return this._fetchArticlesTask.isRunning;
  }

  @action
  onBack() {
    this.selectedArticle = null;
    this.selectedClaimType = null;
  }

  @action
  // @ts-expect-error
  setSelectedArticle(article) {
    this.selectedArticle = article;
    this.segment.track('faq_page_clicked', {
      language: this.prismic.lang,
      page_slug: article.data.slug,
      type: 'article',
      source: 'transaction-helper',
    });
  }

  @action
  // @ts-expect-error
  setSelectedClaimType(claimType) {
    this.selectedClaimType = claimType;
  }

  @action
  toggleCustomerSupportChat() {
    this.zendeskWidget.open();
    this.segment.track('transaction-helper_contact-us_clicked', {
      // @ts-expect-error
      transaction_type: this.args.highlightedItem.operationType,
    });
  }

  _fetchArticlesTask = dropTask(async () => {
    // @ts-expect-error
    let operationType = this.args.highlightedItem?.operationType;
    try {
      // @ts-expect-error
      let { articles, count } = await this.zendeskHelpcenter.fetchArticlesByOperationType({
        operationType,
      });
      this.articles = articles;
      this.articlesCount = count;
    } catch (error) {
      this.segment.track('faq_pageload_error', {
        language: this.prismic.lang,
      });
      throw error;
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'HelpCenter::Index': typeof HelpCenterComponent;
  }
}
