/* import __COLOCATED_TEMPLATE__ from './error.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

interface FlowsCheckErrorSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsCheckErrorComponent extends Component<FlowsCheckErrorSignature> {
  @action
  backToSummary() {
    // @ts-expect-error
    this.args.backToStep('summary');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Check::Error': typeof FlowsCheckErrorComponent;
  }
}
