/* import __COLOCATED_TEMPLATE__ from './accordion.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

interface InContextIntegrationsSideDrawerIntegrationQualityAccordionSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class InContextIntegrationsSideDrawerIntegrationQualityAccordionComponent extends Component<InContextIntegrationsSideDrawerIntegrationQualityAccordionSignature> {
  @tracked isAccordionOpen = false;

  @action
  toggleAccordion() {
    this.isAccordionOpen = !this.isAccordionOpen;
    // @ts-expect-error
    this.args.trackEvent('ICI_integration_quality_clicked');
  }

  get uniqueQualities() {
    // @ts-expect-error
    let { inboundItems, outboundItems } = this.args;

    // @ts-expect-error
    let inboundQualities = inboundItems.flatMap(item => item.qualities);
    // @ts-expect-error
    let outboundQualities = outboundItems.flatMap(item => item.qualities);

    return inboundQualities.length + outboundQualities.length;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InContextIntegrations::SideDrawer::Integration::Quality::Accordion': typeof InContextIntegrationsSideDrawerIntegrationQualityAccordionComponent;
  }
}
