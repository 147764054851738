/* import __COLOCATED_TEMPLATE__ from './account-close-modal.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask, task } from 'ember-concurrency';
import { reads } from 'macro-decorators';

// @ts-expect-error
import { ACCOUNT_TYPE } from 'qonto/constants/bank-account';

interface AccountCloseModalSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class AccountCloseModal extends Component<AccountCloseModalSignature> {
  @service declare intl: Services['intl'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare modals: Services['modals'];
  @service declare sensitiveActions: Services['sensitiveActions'];
  @service declare segment: Services['segment'];

  // @ts-expect-error
  @reads('args.data.remuneratedAccountDetails') remuneratedAccountDetails;

  closeAccountTask = task(async () => {
    // @ts-expect-error
    await this.args.data.bankAccount.close();
    let successMessage = this.intl.t('bank-accounts.close.success-toast');
    this.toastFlashMessages.toastSuccess(successMessage);
  });

  get title() {
    // @ts-expect-error
    if (this.args.data.bankAccount.accountType === ACCOUNT_TYPE.REMUNERATED) {
      return this.intl.t('accounts.remunerated.closure.review.title');
    }

    return this.intl.t('bank-accounts.close-modal.title');
  }

  get noRemuneration() {
    return this.remuneratedAccountDetails.currentMaturity < this.noRemunerationDay;
  }

  get noRemunerationDay() {
    return this.remuneratedAccountDetails.interestRates.at(1).start_day;
  }

  get currentMaturityRate() {
    let currentMaturity = this.remuneratedAccountDetails.currentMaturity;
    return this.remuneratedAccountDetails.interestRates.find(
      // @ts-expect-error
      ({ start_day, end_day }) => start_day <= currentMaturity && end_day >= currentMaturity
    )?.rate;
  }

  checkAccountCanBeClosedTask = dropTask(async () => {
    try {
      let { closure_restrictions: closureRestrictions } =
        // @ts-expect-error
        await this.args.data.bankAccount.closureRestrictions();

      if (closureRestrictions.length) {
        this.modals.open('bank-accounts/account-close-error-modal', {
          closureRestrictions,
        });
        // @ts-expect-error
        this.args.close();
      } else {
        // @ts-expect-error
        await this.sensitiveActions.runTask.perform(this.closeAccountTask);
        // @ts-expect-error
        let { slug, accountType: account_type } = this.args.data.bankAccount;
        this.segment.track('bank_account_closed', {
          slug,
          account_type,
        });
        // @ts-expect-error
        this.args.close();
      }
    } catch {
      let errorMessage = this.intl.t('toasts.errors.server_error');
      this.toastFlashMessages.toastError(errorMessage);
      // @ts-expect-error
      this.args.close();
    }
  });

  @action
  cancel() {
    if (this.checkAccountCanBeClosedTask.isRunning) {
      return;
    }
    // @ts-expect-error
    this.args.close();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'BankAccounts::AccountCloseModal': typeof AccountCloseModal;
  }
}
