import { useState, type CSSProperties, type ReactElement, type ReactNode } from 'react';
import cx from 'clsx';
import { flexRender, type Header } from '@tanstack/react-table';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import styles from './styles.strict-module.css';
import { PopoverTrigger } from './popover-trigger';

interface ResizerProps<TData, TValue> {
  header: Header<TData, TValue>;
}

function Resizer<TData, TValue>({ header }: ResizerProps<TData, TValue>): ReactElement {
  const showResizer = header.column.getIsResizing();

  return (
    <div
      {...{
        onMouseDown: header.getResizeHandler(),
        onTouchStart: header.getResizeHandler(),
        style: {
          transform: header.column.getIsResizing() ? `translateX(0px)` : '',
        },
      }}
      className={cx(styles.resizer, styles.ltr, showResizer && styles['resizer-visible'])}
    >
      <span className={styles['border-resizer']} />
      <span className={styles['middle-resizer']} />
      <span className={styles['border-resizer']} />
    </div>
  );
}

interface HeaderCellProps<TData, TValue> {
  header: Header<TData, TValue>;
  resizedColumn: string | false;
  align?: 'right';
  handleSortBy?: (sortBy: string | undefined) => void;
  isSortable?: boolean;
  sortBy?: string;
}

export function HeaderCell<TData, TValue>({
  header,
  align,
  resizedColumn,
  handleSortBy,
  isSortable,
  sortBy,
}: HeaderCellProps<TData, TValue>): ReactNode {
  const { attributes, isDragging, listeners, setNodeRef, transform } = useSortable({
    id: header.column.id,
  });
  const [isHovered, setIsHovered] = useState(false);

  const isDraggable = !header.column.getIsPinned();
  const isPinned = header.column.getIsPinned();
  const isFixedColumn = header.index === 0;

  const canResize = (): boolean => {
    if (resizedColumn) {
      return header.column.id === resizedColumn;
    }
    return header.column.getCanResize();
  };

  const getPadding = (): string => {
    return '0 16px';
  };

  const getZIndex = (): number => {
    if (isPinned) {
      return 3;
    } else if (isHovered || isDragging) {
      return 2;
    }
    return 1;
  };

  const dndStyle: CSSProperties = {
    opacity: isDragging ? 0.8 : 1,
    transform: CSS.Translate.toString(transform),
    transition: 'width transform 0.2s ease-in-out',
    whiteSpace: 'nowrap',
    zIndex: getZIndex(),
    position: 'sticky',
    width: `${header.column.getSize().toString()}px`,
    maxWidth: `${header.column.getSize().toString()}px`,
    left: isPinned === 'left' ? `${header.column.getStart('left').toString()}px` : undefined,
    top: 0,
    borderRight: isFixedColumn ? '1.25px solid var(--border-tertiary)' : 'none',
    minWidth: isFixedColumn ? '300px' : '100px',
  };

  return (
    <th
      className={styles['table-header']}
      colSpan={header.colSpan}
      data-testid={`col-header-${header.id}`}
      onMouseEnter={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
      }}
      ref={setNodeRef}
      style={{ ...dndStyle }}
    >
      <div
        className={cx(
          styles['table-header-content'],
          isFixedColumn && styles['fixed-column'],
          align === 'right' && styles.right,
          isDragging && styles['on-drag']
        )}
        style={{ padding: getPadding() }}
        {...(isDraggable ? { ...attributes, ...listeners } : {})}
      >
        <span className={cx(styles['header-title'], isPinned && styles['header-title-pinned'])}>
          {header.isPlaceholder
            ? null
            : flexRender(header.column.columnDef.header, header.getContext())}
        </span>
        {isSortable ? (
          <PopoverTrigger columnId={header.column.id} handleSortBy={handleSortBy} sortBy={sortBy} />
        ) : null}
      </div>
      {canResize() && <Resizer header={header} />}
    </th>
  );
}
