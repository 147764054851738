export default {
  "transfer-form": "qzZ",
  "transfer-form-title": "qzR",
  "transfer-form-subtitle": "qzQ",
  "form": "qze",
  "form-footer": "qzB",
  "footer-btn": "qzE",
  "header": "qzd",
  "main-settings": "qzI",
  "additional-settings": "qzt",
  "form-container": "qzM"
};
