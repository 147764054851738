/* import __COLOCATED_TEMPLATE__ from './payment-limit-bar.hbs'; */
import Component from '@glimmer/component';

// @ts-expect-error
import { METER_AMOUNTS_THRESHOLDS } from 'qonto/constants/budget';
import { Meter } from 'qonto/react/components/meter';

interface PaymentLimitBarSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class PaymentLimitBar extends Component<PaymentLimitBarSignature> {
  amountsThresholds = METER_AMOUNTS_THRESHOLDS;
  meter = Meter;

  get meterHeight() {
    // @ts-expect-error
    if (this.args.layout === 'list') {
      return 4;
    }

    return 6;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    PaymentLimitBar: typeof PaymentLimitBar;
  }
}
