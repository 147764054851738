import React, { useEffect, useState, type ReactNode } from 'react';
import { Button as AriaButton, DialogTrigger, type Selection } from 'react-aria-components';
import { Button } from '@repo/design-system-kit';
import { AnimatePresence, motion } from 'framer-motion';
import { useRouter } from '@qonto/react-migration-toolkit/react/hooks';
import { FormattedMessage } from 'react-intl';
import { IconChevronBottomOutlined } from '@repo/monochrome-icons';
import type { LabelList as LabelListType } from 'qonto/react/models/label';
import { Label as LabelComponent } from 'qonto/react/components/label';
import { EmptyDot } from 'qonto/react/assets/icons';
import { bulkLabelsManager, type BulkLabelList } from 'qonto/react/hooks/use-bulk-labels';
import { bulkTransactionsManager } from 'qonto/react/contexts/bulk-transactions-context';
import { useOrganizationManager } from 'qonto/react/hooks/use-organization-manager';
import type { OverlayTriggerState } from 'qonto/react/components/table-v2/popover';
import { LabelPopover } from '../../../../popovers/label-popover';
import { getLabelValue } from '../../../../cells/label-cell/utils';
import styles from './styles.strict-module.css';

interface LabelsProps {
  labelLists: LabelListType[];
}
export function LabelsSection({ labelLists }: LabelsProps): ReactNode {
  const { bulkLabelLists } = bulkLabelsManager.useBulkLabels(labelLists);
  const [visibleCount, setVisibleCount] = useState(3);
  const isExpanded = visibleCount > 3;
  const needsExpansion = bulkLabelLists.length > visibleCount;
  const emptyLabelLists = !labelLists.length;

  const handleToggle = (): void => {
    setVisibleCount(isExpanded ? 3 : bulkLabelLists.length);
  };

  return (
    <section className={styles.section} data-testid="labels-section">
      <h2 className={styles.title} data-testid="title">
        <FormattedMessage id="transactions.sidebar.analytics" />
      </h2>
      <div className={styles['label-lists']}>
        {emptyLabelLists ? (
          <EmptyLabelLists />
        ) : (
          <>
            <AnimatePresence>
              {bulkLabelLists.slice(0, visibleCount).map(labelList => (
                <motion.div
                  animate={{ opacity: 1, height: 'auto' }}
                  exit={{ opacity: 0 }}
                  initial={{ opacity: 0, height: 0 }}
                  key={labelList.id}
                  transition={{ duration: 0.2 }}
                >
                  <LabelList key={labelList.id} labelList={labelList} />
                </motion.div>
              ))}
            </AnimatePresence>
            {!isExpanded && needsExpansion ? (
              <Button
                className={styles['toggle-button']}
                data-testid="toggle-button"
                onPress={handleToggle}
                size="small"
                variant="tertiary"
              >
                <FormattedMessage id="transactions.sidebar.analytics-section.show-more" />
              </Button>
            ) : null}
          </>
        )}
      </div>
    </section>
  );
}

interface LabelListProps {
  labelList: BulkLabelList;
}
function LabelList({ labelList }: LabelListProps): ReactNode {
  return (
    <div className={styles['label-list']} data-testid="label-list">
      <div className={styles['list-name']} data-testid="list-name">
        <p>{labelList.name}</p>
      </div>
      <PopoverTrigger labelList={labelList} />
    </div>
  );
}

interface PopoverTriggerProps {
  labelList: BulkLabelList;
}
function PopoverTrigger({ labelList }: PopoverTriggerProps): ReactNode {
  const { sharedLabel, color, labels } = labelList;
  const {
    labels: { setUpdatedLabels },
  } = bulkTransactionsManager.useBulkTransactions();
  const [activeLabelIds, setActiveLabelIds] = useState<Selection>(
    new Set(sharedLabel ? [sharedLabel.id] : [])
  );

  useEffect(() => {
    setActiveLabelIds(new Set(sharedLabel ? [sharedLabel.id] : []));
  }, [sharedLabel]);

  const activeLabel = getLabelValue(activeLabelIds, labels);

  const handleSelectionChange = (
    selection: Selection,
    triggerState: OverlayTriggerState | null
  ): void => {
    const updatedLabel = getLabelValue(selection, labels);

    setActiveLabelIds(selection);
    setUpdatedLabels(labelList.id, updatedLabel?.id ?? '');
    triggerState?.close();
  };

  return (
    <DialogTrigger>
      <AriaButton className={styles['popover-trigger']} data-testid="popover-trigger">
        {activeLabel ? (
          <LabelComponent color={color}>
            {labels.find(({ id }) => id === activeLabel.id)?.name}
          </LabelComponent>
        ) : (
          <EmptyLabel />
        )}

        <IconChevronBottomOutlined aria-hidden="true" />

        <LabelPopover
          activeLabelIds={activeLabelIds}
          customPopoverProps={{ offset: -40, placement: 'bottom end' }}
          labelList={labelList}
          onSelectionChange={handleSelectionChange}
        />
      </AriaButton>
    </DialogTrigger>
  );
}

function EmptyLabel(): ReactNode {
  return (
    <div className={styles['empty-label']}>
      <EmptyDot />
      <FormattedMessage id="placeholders.analytics_choice" />
    </div>
  );
}

function EmptyLabelLists(): ReactNode {
  const router = useRouter();
  const { organization } = useOrganizationManager();

  return (
    <div className={styles['empty-label-lists']} data-testid="empty-label-lists">
      <p>
        <FormattedMessage id="empty-states.custom-labels.transactions-body" />
      </p>
      <AriaButton
        data-testid="edit-labels"
        onPress={() => void router.push(`/organizations/${organization.slug}/custom-labels`)}
      >
        <FormattedMessage id="empty-states.custom-labels.button" />
      </AriaButton>
    </div>
  );
}
