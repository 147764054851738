/* import __COLOCATED_TEMPLATE__ from './company-details.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask } from 'ember-concurrency';

import { ONBOARDING_TRACKING_EVENTS } from 'qonto/constants/receivable-invoice';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';

interface FlowsAccountReceivableOnboardingCompanyDetailsSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class FlowsAccountReceivableOnboardingCompanyDetails extends Component<FlowsAccountReceivableOnboardingCompanyDetailsSignature> {
  @service declare segment: Services['segment'];
  @service declare intl: Services['intl'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare networkManager: Services['networkManager'];
  @service declare sentry: Services['sentry'];

  constructor(
    owner: unknown,
    args: FlowsAccountReceivableOnboardingCompanyDetailsSignature['Args']
  ) {
    super(owner, args);

    this.prefillSettings();
  }

  willDestroy() {
    // @ts-expect-error
    super.willDestroy(...arguments);

    if (!this.settings || this.settings.isDestroyed || this.settings.isDestroying) return;

    this.settings.rollbackAttributes();
  }

  prefillSettings() {
    if (this.stepStatus !== 'confirmed') {
      // @ts-expect-error
      this.args.context.settings.setProperties({
        contactEmail: this.prefillData?.contactEmail || this.organization.contactEmail,
        vatNumber: this.prefillData?.vatNumber || this.organization.vatNumber,
      });

      if (this.isFrenchOrganization) {
        // @ts-expect-error
        this.args.context.settings.setProperties({
          legalCapitalShare: this.prefillData?.legalCapitalShare,
          commercialRegisterNumber: this.prefillData?.commercialRegisterNumber,
        });
      }
    }
  }

  get organization() {
    return this.organizationManager.organization;
  }

  get settings() {
    // @ts-expect-error
    return this.args.context.settings;
  }

  get onboardingState() {
    // @ts-expect-error
    return this.args.context.onboardingState;
  }

  get stepStatus() {
    return this.onboardingState.stepStatusCompanyDetails;
  }

  get isFrenchOrganization() {
    // @ts-expect-error
    return this.args.context.isFrenchOrganization;
  }

  get isGermanOrganization() {
    // @ts-expect-error
    return this.args.context.isGermanOrganization;
  }

  get prefillData() {
    return this.onboardingState?.prefillData;
  }

  get title() {
    if (this.organization.isAssociation) {
      return this.intl.t('receivable-invoices.onboarding.company-details.title.association', {
        legalCountry: this.organization.legalCountry,
      });
    } else if (this.organization.isBusiness) {
      return this.intl.t('receivable-invoices.onboarding.company-details.title.business', {
        legalCountry: this.organization.legalCountry,
      });
    } else {
      return this.intl.t('receivable-invoices.onboarding.company-details.title.company', {
        legalCountry: this.organization.legalCountry,
      });
    }
  }

  get placeholderConfig() {
    return {
      enablePlaceholders: true,
      activeSections: [
        this.exampleInvoice.number && 'number',
        !this.isGermanOrganization && 'contactOrganization',
      ],
    };
  }

  get exampleInvoice() {
    if (!this.isGermanOrganization) {
      return {
        number: this.settings?.invoiceNextNumberFormatted,
        organization: {
          legalName: this.organization.legalName,
          legalNumber: this.organization.legalNumber,
          address: this.organization.address,
          vatNumber: this.settings.vatNumber,
        },
        contactEmail: this.settings.contactEmail,
      };
    }

    return {
      number: this.settings?.invoiceNextNumberFormatted,
      issueDate: true,
      performanceDate: true,
      purchaseOrder: true,
      beneficiaryName: this.organization.name,
      quote: {
        number: true,
      },
      customerSnapshot: {
        vatNumber: true,
        tinNumber: true,
      },
      organization: {
        legalName: this.organization.legalName,
        legalNumber: this.organization.legalNumber,
        address: this.organization.address,
        vatNumber: this.settings.vatNumber,
        companyLeadership: this.settings.companyLeadership,
        taxNumber: this.settings.taxNumber,
      },
      contactEmail: this.settings.contactEmail,
    };
  }

  get contactEmailErrorMessage() {
    let errors = this.settings.errors.get('contactEmail');

    if (errors.length === 0) return null;

    // @ts-expect-error
    if (errors.some(error => error.message === 'invalid')) {
      return this.intl.t(
        'receivable-invoices.invoice-creation.new.tab-settings.errors.invalid-contact-email'
      );
    }
    return this.intl.t('receivable-invoices.invoice-creation.errors.required-field');
  }

  @action
  // @ts-expect-error
  handleEmailUpdate(email) {
    this.settings.contactEmail = email;
  }

  get vatNumberErrorMessage() {
    let errors = this.settings.errors.get('vatNumber');

    if (errors.length === 0) return null;

    // @ts-expect-error
    if (errors.some(error => error.message === 'invalid')) {
      return this.intl.t(
        'receivable-invoices.invoice-setting.settings-modal.general.company-details.vat-error-message'
      );
    }
  }

  @action
  // @ts-expect-error
  handleVatNumberUpdate(vatNumber) {
    this.settings.vatNumber = vatNumber;
  }

  confirmTask = dropTask(async () => {
    try {
      this.segment.track(ONBOARDING_TRACKING_EVENTS.STEP_COMPLETED, {
        // @ts-expect-error
        source: this.args.context.source,
        step: 'company-details',
      });
      // Update settings
      await this.settings.save();

      // Update onboarding status
      // TODO: Update substep status if there is one
      this.onboardingState.stepStatusCompanyDetails = 'confirmed';
      this.onboardingState.substepStatusCompanyDetails = 'confirmed';
      await this.onboardingState.save();

      if (this.isFrenchOrganization) {
        // @ts-expect-error
        this.args.transitionToNext();
      } else {
        this.onboardingState.stepStatusCompanyDetails = 'confirmed';
        this.onboardingState.substepStatusCompanyDetails = 'confirmed';
        await this.onboardingState.save();
        // @ts-expect-error
        this.args.backToStep('summary');
      }
    } catch (error) {
      // @ts-expect-error
      if (error.status === 422) {
        // @ts-expect-error
        let normalizedErrors = this._normalizeErrors(error.errors);
        // @ts-expect-error
        this.networkManager.errorModelInjector(this.settings, normalizedErrors, error);
      } else {
        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }
        this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
      }
    }
  });

  // @ts-expect-error
  _normalizeErrors(errors) {
    return (
      errors
        // @ts-expect-error
        .filter(e => Boolean(e.source?.pointer))
        // @ts-expect-error
        .reduce((errs, { source, detail }) => {
          let attribute = source.pointer.split('/').pop();
          //camelize did not work here for some reason so we are temporarily going with this solution
          switch (attribute) {
            case 'contactemail':
              attribute = 'contactEmail';
              break;
            case 'vatnumber':
              attribute = 'vatNumber';
              break;
          }
          return {
            [attribute]: detail,
            ...errs,
          };
        }, {})
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::AccountReceivableOnboarding::CompanyDetails': typeof FlowsAccountReceivableOnboardingCompanyDetails;
  }
}
