/* import __COLOCATED_TEMPLATE__ from './email-preview.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { Disclaimer } from '@repo/design-system-kit';

import { EmailPreviewFooter } from 'qonto/react/components/receivable-invoices/email-preview';

interface RemindersConfigurationEmailPreviewSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class RemindersConfigurationEmailPreview extends Component<RemindersConfigurationEmailPreviewSignature> {
  disclaimerInline = Disclaimer.Inline;

  @service declare theme: Services['theme'];

  emailPreviewFooter = EmailPreviewFooter;

  @service declare intl: Services['intl'];
  @service declare organizationManager: Services['organizationManager'];

  get footerDisclaimer() {
    return this.intl
      .t('receivable-invoices.invoicing-sending.preview.invoicing-disclaimer', {
        // @ts-expect-error
        language: this.args.language,
        htmlSafe: true,
      })
      .toString();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'RemindersConfiguration::EmailPreview': typeof RemindersConfigurationEmailPreview;
  }
}
