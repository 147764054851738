export default {
  "multi-transfer": "ZSK",
  "container": "ZSG",
  "title": "ZSr",
  "subtitle": "ZSb",
  "form-wrapper": "ZSy",
  "form": "ZSU",
  "form-title": "ZSj",
  "files-buttons": "ZSW",
  "files-buttons-item": "ZSu",
  "selected": "ZcS"
};
