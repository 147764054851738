export default {
  "cell-content": "cjF",
  "icon": "cjg",
  "status_check-m": "cjD",
  "description": "cjV body-1",
  "from": "cjL body-2",
  "from--abort": "cjT",
  "description--abort": "cjz",
  "title": "cja body-2"
};
