export default {
  "insight-pill": "qMU",
  "insight-pill-figure": "qMj",
  "insight-pill-renderer": "qMW",
  "loading": "qMu",
  "counter": "qPS",
  "error": "qPc",
  "insight-pill-label": "qPq",
  "insight-pill-subtitle": "qPZ caption",
  "hidden": "qPR",
  "missing-amounts-warning": "qPQ",
  "missing-amounts": "qPe"
};
