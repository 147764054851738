export default {
  "row": "cmG",
  "cell": "cmr",
  "title": "cmb",
  "internal-note": "cmy",
  "unit": "cmU",
  "price": "cmj",
  "quick-actions": "cmW",
  "missing-details-container": "cmu",
  "missing-details-label": "cpS body-2",
  "warning-icon": "cpc",
  "empty": "cpq",
  "checkbox": "cpZ",
  "align-checkbox": "cpR"
};
