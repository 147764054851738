export const TRANSFER_STATUSES = {
  INITIATED: 'INITIATED',
};

export const TRANSFER_EVENTS = {
  COMPLETION: 'completion',
  CREATION: 'creation',
  REJECTION: 'rejection',
  SHIPMENT: 'shipment',
  VALIDATION: 'validation',
};

export const TRANSFER_EVENT_STATUSES = {
  AWAITING: 'awaiting',
  COMPLETED: 'completed',
  IN_PROGRESS: 'in_progress',
};
