import { Timeline } from '@repo/design-system-kit';
import type { ReactNode } from 'react';
import {
  IconCheckmarkRoundedFilled,
  IconClockOutlined,
} from '@repo/design-system-kit/assets/icons';
import { useIntl } from 'react-intl';
import { IconCheckmarkRoundedOutlined } from '@repo/monochrome-icons';
import { STATUS } from 'qonto/constants/transfers';
import type { STEPS } from 'qonto/constants/international-out/lifecycle';
import { dateToken } from '@qonto/ui-kit/utils/date-token';

type TimelineStatus = (typeof STATUS)[keyof typeof STATUS];
type TimelineStepName = (typeof STEPS)[keyof typeof STEPS];

interface TimelineEvent {
  order: number;
  status: TimelineStatus;
  name: TimelineStepName;
  date: string | null;
}

interface TransferTimelineProps {
  timeline: TimelineEvent[] | null;
  isLoading: boolean;
}

export function TransferTimeline({ timeline, isLoading }: TransferTimelineProps): ReactNode {
  const intl = useIntl();

  if (isLoading) {
    const loadingItems = new Array(4).fill(null).map((_, index) => ({
      id: String(index),
      slots: {
        media: <IconClockOutlined style={{ color: 'var(--content-secondary)' }} />,
        title: intl.formatMessage({ id: 'international-out.timeline.step.loading' }),
        content: null,
      },
      lineVariant: 'dashed' as const,
      'data-testid': `timeline-item-loading-${index}`,
    }));

    return <Timeline list={loadingItems} />;
  }

  if (!timeline) {
    return null;
  }

  const items = timeline.map(event => ({
    id: String(event.order),
    slots: {
      media: getIcon(event, timeline.length),
      title: intl.formatMessage({
        id: `international-out.timeline.step.${event.name.toLowerCase()}`,
      }),
      content:
        event.date &&
        dateToken({
          date: event.date,
          locale: intl.locale,
          token: 'date-time-s',
        }),
    },
    lineVariant: event.status === STATUS.COMPLETED ? ('solid' as const) : ('dashed' as const),
    'data-testid': `timeline-item-${event.order}`,
  }));

  return <Timeline list={items} />;
}

function getIcon(event: TimelineEvent, totalItems: number): ReactNode {
  if (event.status === STATUS.PROGRESS) {
    return <IconCheckmarkRoundedFilled style={{ color: 'var(--content-accent)' }} />;
  }

  if (event.status === STATUS.COMPLETED) {
    if (event.order !== totalItems - 1) {
      return <IconCheckmarkRoundedOutlined style={{ color: 'var(--content-secondary)' }} />;
    }

    return <IconCheckmarkRoundedFilled style={{ color: 'var(--content-success)' }} />;
  }

  return <IconClockOutlined style={{ color: 'var(--content-secondary)' }} />;
}
