export default {
  "checks": "Ul",
  "wrapper": "UX",
  "form": "UC",
  "title": "Uk",
  "wrapper-body": "UJ",
  "aside": "Us",
  "steps-container": "UF",
  "step": "Ug",
  "container-dot": "UD",
  "text": "UV",
  "text-title": "UL",
  "text-desc": "UT",
  "start-button": "Uz"
};
