/* import __COLOCATED_TEMPLATE__ from './label.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface LabelSignature {
  Element: HTMLLabelElement;
  Args: {};
  Blocks: {
    default: [];
  };
}

const Label = templateOnlyComponent<LabelSignature>();

export default Label;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'F24::FormElements::Label': typeof Label;
  }
}
