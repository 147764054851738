export default {
  "row": "Jq",
  "active": "JZ",
  "animated": "JR",
  "fadein-item": "JQ",
  "cell": "Je body-2",
  "cell-content": "JB",
  "no-padding": "JE",
  "hidden": "Jd",
  "disabled": "JI",
  "not-disabled": "Jt",
  "card-icon": "JM",
  "card-infos": "JP",
  "card-digits": "Jl",
  "card-digits-warning": "JX",
  "status-puce": "JC",
  "live": "Jk",
  "waiting-funds": "JJ",
  "ghost": "Js",
  "payment-limit-bar": "JF",
  "align-right": "Jg"
};
