/* import __COLOCATED_TEMPLATE__ from './restrictions.hbs'; */
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { later } from '@ember/runloop';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { isTesting, macroCondition } from '@embroider/macros';
import { Disclaimer } from '@repo/design-system-kit';
import { Changeset } from 'ember-changeset';
import { dropTask } from 'ember-concurrency';

// @ts-expect-error
import scrollIntoView from 'qonto/utils/scroll-into-view';

interface CardSettingsRestrictionsSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class CardSettingsRestrictionsComponent extends Component<CardSettingsRestrictionsSignature> {
  disclaimerInline = Disclaimer.Inline;

  @service declare intl: Services['intl'];

  // @ts-expect-error
  @tracked hasActiveDayLimits = this.args.card.activeDaysOption;
  @tracked oldActiveDaysState = null;
  // @ts-expect-error
  @tracked hasCategoryTags = Boolean(this.args.card.categoryTags?.length);
  @tracked oldCategoryTags = null;
  // @ts-expect-error
  changeset = new Changeset(this.args.card);
  activeDaysId = `${guidFor(this)}-active-days`;
  categoriesSelectorId = `${guidFor(this)}-category-selector`;

  get hasError() {
    return Boolean(this.activeDaysError || this.categoriesError);
  }

  get weekdays() {
    let { intl } = this;
    return [
      { name: intl.t('cards.settings.restrictions.active-days.weekday.mon'), value: 1 },
      { name: intl.t('cards.settings.restrictions.active-days.weekday.tue'), value: 2 },
      { name: intl.t('cards.settings.restrictions.active-days.weekday.wen'), value: 3 },
      { name: intl.t('cards.settings.restrictions.active-days.weekday.thu'), value: 4 },
      { name: intl.t('cards.settings.restrictions.active-days.weekday.fri'), value: 5 },
      { name: intl.t('cards.settings.restrictions.active-days.weekday.sat'), value: 6 },
      { name: intl.t('cards.settings.restrictions.active-days.weekday.sun'), value: 0 },
    ];
  }

  get categories() {
    let { intl } = this;
    return [
      {
        icon: 'food_and_grocery-xs',
        label: intl.t('cards.settings.restrictions.categories.options.food'),
        value: 'food_and_grocery',
      },
      {
        icon: 'restaurant_and_bar-xs',
        label: intl.t('cards.settings.restrictions.categories.options.restaurants'),
        value: 'restaurant_and_bar',
      },
      {
        icon: 'gas_station-xs',
        label: intl.t('cards.settings.restrictions.categories.options.gas-station'),
        value: 'gas_station',
      },
      {
        icon: 'hotel_and_lodging-xs',
        label: intl.t('cards.settings.restrictions.categories.options.hotels'),
        value: 'hotel_and_lodging',
      },
      {
        icon: 'transport-xs',
        label: intl.t('cards.settings.restrictions.categories.options.transport'),
        value: 'transport',
      },
      {
        icon: 'hardware_and_equipment-xs',
        label: intl.t('cards.settings.restrictions.categories.options.equipment'),
        value: 'hardware_and_equipment',
      },
    ];
  }

  get activeDaysError() {
    let { hasActiveDayLimits, changeset } = this;

    if (hasActiveDayLimits) {
      if (changeset.activeDays.length === 0) {
        return this.intl.t('cards.settings.restrictions.active-days.no-days-selected-error');
      }

      if (changeset.activeDays.length === 7) {
        return this.intl.t('cards.settings.restrictions.active-days.all-days-selected-error');
      }
    }

    return '';
  }

  get categoriesError() {
    let { hasCategoryTags, changeset } = this;

    if (hasCategoryTags) {
      if (changeset.categoryTags.length === 0) {
        return this.intl.t('cards.settings.restrictions.categories.errors.none-selected');
      }

      if (changeset.categoryTags.length === 6) {
        return this.intl.t('cards.settings.restrictions.categories.errors.all-selected');
      }
    }

    return '';
  }

  persistState() {
    // persist only valid changes so when going back in step we have valid card record
    // @ts-expect-error
    if (!this.args.disablePersistingState && !this.isCtaDisabled) {
      this.changeset.execute();
    }
  }

  @action
  toggleActiveDaysLimit() {
    let { changeset } = this;
    this.hasActiveDayLimits = !this.hasActiveDayLimits;
    if (this.hasActiveDayLimits) {
      scrollIntoView(`#${this.activeDaysId}`);

      this.oldActiveDaysState = null;
      changeset.set('activeDays', [1, 2, 3, 4, 5]);
    } else {
      // used to fix visual glitch https://gitlab.qonto.co/qonto/qonto-js/-/merge_requests/9738
      // @ts-expect-error
      this.oldActiveDaysState = [...changeset.activeDays];
      changeset.set('activeDays', [0, 1, 2, 3, 4, 5, 6]);
    }
    this.persistState();
  }

  @action
  // @ts-expect-error
  toggleActiveDay(day) {
    let activeDays = [...this.changeset.activeDays];
    let index = activeDays.indexOf(day);
    if (index > -1) {
      activeDays.splice(index, 1);
    } else {
      activeDays.push(day);
    }
    this.changeset.set('activeDays', activeDays);
    this.persistState();
  }

  @action
  toggleCategorySelector() {
    let { changeset } = this;
    this.hasCategoryTags = !this.hasCategoryTags;

    if (this.hasCategoryTags) {
      let defaultTags = ['food_and_grocery', 'restaurant_and_bar'];
      // @ts-expect-error
      let hasOldCategoryTags = Boolean(this.oldCategoryTags?.length);
      let tags = hasOldCategoryTags ? this.oldCategoryTags : defaultTags;
      changeset.set('categoryTags', tags);
      later(
        scrollIntoView,
        // @ts-expect-error
        document.getElementById(this.categoriesSelectorId),
        macroCondition(isTesting()) ? 0 : 200
      );
    } else {
      this.oldCategoryTags = changeset.categoryTags;
      changeset.set('categoryTags', []);
    }
    this.persistState();
  }

  @action
  // @ts-expect-error
  toggleCategoryTag(tag) {
    let categoryTags = [...this.changeset.categoryTags];
    let index = categoryTags.indexOf(tag);
    if (index > -1) {
      categoryTags.splice(index, 1);
    } else {
      categoryTags.push(tag);
    }
    this.changeset.set('categoryTags', categoryTags);
    this.persistState();
  }

  submitTask = dropTask(async () => {
    if (this.hasError) return;

    this.changeset.execute();
    // @ts-expect-error
    await this.args.onSubmit();
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Card::Settings::Restrictions': typeof CardSettingsRestrictionsComponent;
  }
}
