export default {
  "banner": "cDo",
  "content": "cDn",
  "title-row": "cDi",
  "title": "cDA title-3",
  "badge": "cDY",
  "text": "cDh body-2",
  "missing-info-disclaimer": "cDf",
  "button": "cDK btn btn--primary",
  "spinner": "cDG",
  "illustration": "cDr",
  "image": "cDb"
};
