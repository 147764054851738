import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';

import dayjs from 'dayjs';
import { variation } from 'ember-launch-darkly';

import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import { ArPromotionalPopup } from 'qonto/react/components/account-receivable/promotional-popup';
import { KycPendingPopup } from 'qonto/react/components/kyc/kyc-pending-popup';
import { NrcPromotionalPopup } from 'qonto/react/components/nrc-promotional-popup';

export default class OverviewController extends Controller {
  queryParams = [{ bankAccounts: 'bank-accounts' }];

  @service router;
  @service flowLinkManager;
  @service abilities;
  @service segment;
  @service featuresManager;
  @service organizationManager;

  get hasSpainTaxesAbility() {
    return this.abilities.can('view nrc');
  }

  get hasContractBeenSignedLessThan90DaysAgo() {
    let { organization } = this.organizationManager;
    let { contractSignedAt } = organization;

    return dayjs().isBetween(contractSignedAt, dayjs(contractSignedAt).add(90, 'days'));
  }

  get isBefore6thJanuary2025() {
    return dayjs().isBefore(dayjs('2025-01-06'));
  }

  get hasAlreadySeenConnectExternalAccountsPopup() {
    let { organization } = this.organizationManager;
    let { id } = organization;
    return safeLocalStorage.getItem(`connect-external-accounts-popup-shown_${id}`);
  }

  get hasAlreadySeenMoveRecurringPaymentsPopup() {
    let { organization } = this.organizationManager;
    let { id } = organization;
    return safeLocalStorage.getItem(`move-recurring-payments-popup-shown_${id}`);
  }

  get externalBankRecommendationsName() {
    return this.model.recommendationsBundle?.bankName;
  }

  get hasRecommendations() {
    return this.model.recommendationsBundle?.recommendations.length > 0;
  }

  /**
   * Handles popup rendering.
   * Sort by priority.
   * Only one can be rendered per condition.
   * @returns {{reactComponent: () => React.ReactNode, props?: Record<any, any>}}}
   */
  get popup() {
    let { organization, membership } = this.organizationManager;
    let { legalCountry } = organization;

    if (
      membership.shouldSubmitKyc &&
      // We only focus on owners
      organization.kybPending &&
      variation('feature--boolean-improve-modal-to-increase-kyc-rate')
    ) {
      return {
        reactComponent: KycPendingPopup,
      };
    }

    if (
      !organization.underRegistration &&
      this.hasSpainTaxesAbility &&
      variation('feature--boolean-nrc-payments-promo-signed-up-users')
    ) {
      return {
        reactComponent: NrcPromotionalPopup,
      };
    }

    if (
      legalCountry === 'DE' &&
      this.abilities.can('create receivableInvoice') &&
      this.abilities.can('read receivableInvoice')
    ) {
      return {
        reactComponent: ArPromotionalPopup,
      };
    }
  }

  get createTransfer() {
    let isQontoPilotFeatureEnabled = this.featuresManager.isEnabled('qontoPilot');
    let canUseQontoPilot = this.abilities.can('use qonto-pilot');
    let canCreateTransfer = this.abilities.can('create transfer');

    if (isQontoPilotFeatureEnabled) {
      if (canUseQontoPilot) {
        return false;
      } else {
        return canCreateTransfer && !canUseQontoPilot;
      }
    } else {
      return canCreateTransfer;
    }
  }

  @action
  redirectToTransferPage() {
    this.segment.track('overview_create_transfer_clicked');
    return this.router.transitionTo('transfers.new');
  }
}
