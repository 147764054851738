import { type ReactElement } from 'react';
import { useIntl } from 'react-intl';
import { DetailsList, type DetailsItem } from 'qonto/react/components/sidebar/details-list';
import type { CardAcquirerPaymentModel } from 'qonto/react/models/card-acquirer-payment';

function usePaymentItems(payment: CardAcquirerPaymentModel): DetailsItem[] {
  const intl = useIntl();

  return [
    {
      label: intl.formatMessage({
        id: 'tap-to-pay.payments.sidepanel.execution-date',
      }),
      text: intl.formatDate(payment.executionDate, {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: '2-digit',
      }),
    },
    {
      label: intl.formatMessage({
        id: 'tap-to-pay.payments.sidepanel.card',
      }),
      text: payment.card,
    },
    {
      label: intl.formatMessage({
        id: 'tap-to-pay.payments.sidepanel.reference',
      }),
      text: payment.reference,
    },
  ];
}

interface PaymentDetailsProps {
  payment: CardAcquirerPaymentModel;
}

export function PaymentDetails({ payment }: PaymentDetailsProps): ReactElement {
  const items = usePaymentItems(payment);

  return <DetailsList items={items} />;
}
