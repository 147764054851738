export default {
  "header-cell": "qCb",
  "header-content": "qCy caption-bold",
  "active": "qCU",
  "empty": "qCj",
  "align-right": "qCW",
  "type": "qCu",
  "requester": "qkS",
  "request-date": "qkc",
  "amount": "qkq",
  "status": "qkZ"
};
