import type { ReactNode } from 'react';
import { FormattedNumber, useIntl } from 'react-intl';
import { IconNavTransactionOutlined } from '@repo/design-system-kit';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { useSegment } from '@repo/poly-hooks/segment';
import { dateToken } from '@qonto/ui-kit/utils/date-token';
import type { IncludedTransaction } from 'qonto/react/models/financing';
import styles from './styles.strict-module.css';

export function TransactionHistoryRow({
  transaction,
}: {
  transaction: IncludedTransaction;
}): ReactNode {
  const { locale } = useIntl();
  const router = useEmberService('router');
  const segment = useSegment();

  const formattedDate = dateToken({
    date: transaction.emittedAt,
    locale,
    token: 'date-time-s',
  });

  const handleLinkClick = (e: React.MouseEvent): void => {
    e.preventDefault();

    segment.track('pay-later_cockpit-financing-details_transactions-history-clicked', {
      movement: transaction.side,
    });

    void router.transitionTo('transactions.index', { queryParams: { highlight: transaction.id } });
  };

  return (
    <div
      className={styles['transaction-history-row']}
      data-testid={`transaction-history-row-${transaction.id}`}
    >
      <div className={styles.icon} data-testid="transaction-history-row-icon">
        <IconNavTransactionOutlined />
      </div>
      <div className={styles['transaction-history-row-details']}>
        <button
          className={styles['transaction-history-row-details-amount']}
          data-testid="transaction-history-row-link"
          onClick={handleLinkClick}
          type="button"
        >
          <span>{transaction.side === 'credit' ? '+\xA0' : '-\xA0'}</span>
          <FormattedNumber
            compactDisplay="long"
            currency={transaction.amountCurrency}
            style="currency"
            value={transaction.amount}
          />
        </button>
        <div
          className={styles['transaction-history-row-details-description']}
          data-testid="transaction-history-row-date"
        >
          {formattedDate}
        </div>
      </div>
    </div>
  );
}
