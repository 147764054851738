/* import __COLOCATED_TEMPLATE__ from './row.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { DATE_FORMAT_TOKENS, dateToken } from '@qonto/ui-kit/utils/date-token';
import { BadgeStatus } from '@repo/design-system-kit';
import dayjs from 'dayjs';

import {
  BANK_CONNECTION_FAILURE_STATUSES,
  BANK_CONNECTION_STATUS,
  CONNECTION_EXPIRATION_STATUS,
  SOLUTION_INSTANCE_STATUSES,
} from 'qonto/constants/connect';
import {
  GMI_STATUSES_ACTION_REQUIRED,
  GMI_STATUSES_CONNECTING_STATUSES,
  GMI_STATUSES_FAILED_STATUSES,
  GMI_STATUSES_SUCCESS_EVENTS,
  // @ts-expect-error
} from 'qonto/constants/gmi-solution-instance';
import { Avatar } from 'qonto/react/components/connections/avatar';

interface ConnectionsTableRowSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLTableRowElement;
}

export default class ConnectionsTableRowComponent extends Component<ConnectionsTableRowSignature> {
  @service declare intl: Services['intl'];

  avatar = Avatar;
  badgeStatus = BadgeStatus;

  get statusLevel() {
    // @ts-expect-error
    let { connectionStatus } = this.args;

    switch (true) {
      case BANK_CONNECTION_FAILURE_STATUSES.includes(connectionStatus):
        return 'failure';

      case connectionStatus === CONNECTION_EXPIRATION_STATUS.EXPIRING:
        return 'warning';

      case connectionStatus === CONNECTION_EXPIRATION_STATUS.EXPIRED:
        return 'error';

      case connectionStatus === CONNECTION_EXPIRATION_STATUS.ACTIVE:
        return 'info';
    }
  }

  get iconName() {
    // @ts-expect-error
    let { connectionStatus } = this.args;

    switch (true) {
      case BANK_CONNECTION_FAILURE_STATUSES.includes(connectionStatus):
        return 'icon_error_filled';

      case connectionStatus === CONNECTION_EXPIRATION_STATUS.EXPIRING:
      case connectionStatus === CONNECTION_EXPIRATION_STATUS.EXPIRED:
        return 'icon_warning_sign_filled';

      case connectionStatus === BANK_CONNECTION_STATUS.SYNCHRONIZED:
      case connectionStatus === BANK_CONNECTION_STATUS.SYNC_IN_PROGRESS:
      case connectionStatus === CONNECTION_EXPIRATION_STATUS.ACTIVE:
        return 'icon_checkmark_rounded_filled';
    }
  }

  get statusMessage() {
    // @ts-expect-error
    let { connectionStatus, expiresAt, lastSuccessfulSyncAt } = this.args;

    let expirationDate = dateToken({
      date: expiresAt,
      // @ts-expect-error
      locale: this.intl.primaryLocale,
      token: DATE_FORMAT_TOKENS.DATE_YEAR_S,
    });

    switch (true) {
      case BANK_CONNECTION_FAILURE_STATUSES.includes(connectionStatus): {
        // @ts-expect-error
        let relativeLastSyncTime = dayjs(lastSuccessfulSyncAt).fromNow();

        return this.intl.t('settings.connections.status.failed', {
          relativeTime: relativeLastSyncTime,
        });
      }

      case connectionStatus === BANK_CONNECTION_STATUS.SYNCHRONIZED:
      case connectionStatus === BANK_CONNECTION_STATUS.SYNC_IN_PROGRESS:
      case connectionStatus === CONNECTION_EXPIRATION_STATUS.ACTIVE:
        return this.intl.t('settings.connections.status.connected', { expirationDate });

      case connectionStatus === CONNECTION_EXPIRATION_STATUS.EXPIRING:
        return this.intl.t('settings.connections.status.expires-soon', { expirationDate });

      case connectionStatus === CONNECTION_EXPIRATION_STATUS.EXPIRED:
        return this.intl.t('settings.connections.status.expired', { expirationDate });

      case connectionStatus === CONNECTION_EXPIRATION_STATUS.UNKNOWN:
        return '-';
    }
  }

  get badgeStatusProps() {
    // @ts-expect-error
    let { connectionStatus } = this.args;

    switch (true) {
      case GMI_STATUSES_SUCCESS_EVENTS.includes(connectionStatus):
      case connectionStatus === SOLUTION_INSTANCE_STATUSES.ENABLED:
        return {
          level: 'validated',
          text: this.intl.t('settings.connections.status.active'),
        };
      case GMI_STATUSES_ACTION_REQUIRED.includes(connectionStatus):
        return {
          level: 'warning',
          text: this.intl.t('settings.connections.list.status-action-needed'),
        };
      case GMI_STATUSES_FAILED_STATUSES.includes(connectionStatus):
        return {
          level: 'error',
          text: this.intl.t('settings.connections.list.status-failed'),
        };
      case GMI_STATUSES_CONNECTING_STATUSES.includes(connectionStatus):
        return {
          level: 'waiting',
          text: this.intl.t('settings.connections.list.status-connecting'),
        };
      default:
        return {};
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Connections::Table::Row': typeof ConnectionsTableRowComponent;
  }
}
