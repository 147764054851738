import type { FrontendUrlMode } from '@repo/shared-config/environment';

import ENV from 'qonto/config/environment';

type QontentApiConfig = {
  qontentApiHost: string;
  qontentApiToken: string;
};

const { frontendUrlMode: environment } = ENV;

const qontentApi: Record<FrontendUrlMode, QontentApiConfig> = {
  development: {
    qontentApiHost: 'https://qontent-api.dev.qonto.co',
    qontentApiToken: 'dev-xxxx-xxxx-xxxx-xxxx',
  },
  staging: {
    qontentApiHost: 'https://qontent-api.staging.qonto.co',
    qontentApiToken: 'staging-xxxx-xxxx-xxxx-xxxx',
  },
  production: {
    qontentApiHost: 'https://qontent-api.qonto.co',
    qontentApiToken: 'prod-xxxx-xxxx-xxxx-xxxx',
  },
};

export const { qontentApiHost, qontentApiToken } = qontentApi[environment];
