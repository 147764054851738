export default {
  "row": "tf",
  "active": "tK",
  "cell": "tG body-2",
  "empty": "tr",
  "no-padding": "tb",
  "declined": "ty",
  "not-declined": "tU",
  "item-amount-declined": "tj",
  "cell-content": "tW",
  "item-avatar": "tu mr-16",
  "avatar": "MS",
  "item-card-name": "Mc",
  "item-card-name-text": "Mq",
  "item-amount": "MZ body-1"
};
