/* import __COLOCATED_TEMPLATE__ from './foldable-item.hbs'; */
import { guidFor } from '@ember/object/internals';
import Component from '@glimmer/component';

interface RemindersConfigurationFoldableItemSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class RemindersConfigurationFoldableItemComponent extends Component<RemindersConfigurationFoldableItemSignature> {
  id = `foldable-item-${guidFor(this)}`;

  get isExpandable() {
    // @ts-expect-error
    return this.args.isExpandable ?? true;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'RemindersConfiguration::FoldableItem': typeof RemindersConfigurationFoldableItemComponent;
  }
}
