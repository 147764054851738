export default {
  "header-cell": "Ce",
  "header-content": "CB caption-bold",
  "active": "CE",
  "hidden": "Cd",
  "empty": "CI",
  "align-right": "Ct",
  "no-padding": "CM",
  "request-type": "CP",
  "amount": "Cl",
  "approval": "CX"
};
