export default {
  "container": "qWp",
  "header-cell": "qWx caption-bold",
  "col-8": "qWw",
  "table": "qWN",
  "cell": "qWv body-2",
  "cell-content": "qWo",
  "row": "qWn",
  "empty": "qWi",
  "quick-actions": "qWA",
  "card-infos": "qWY",
  "card-digits": "qWh",
  "empty-state": "qWf"
};
