/* import __COLOCATED_TEMPLATE__ from './transaction-item.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { Badge } from '@repo/design-system-kit';

interface OverviewWidgetsTransactionItemSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class OverviewWidgetsTransactionItemComponent extends Component<OverviewWidgetsTransactionItemSignature> {
  @service declare upsellManager: Services['upsellManager'];
  badge = Badge;

  get sepaTransferUpsellRecommendation() {
    // @ts-expect-error
    return this.upsellManager.sepaTransferUpsellRecommendation(this.args.transaction);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Overview::Widgets::TransactionItem': typeof OverviewWidgetsTransactionItemComponent;
  }
}
