export default {
  "container": "qrq",
  "form-container": "qrZ",
  "preview-container": "qrR",
  "pdf-preview-container": "qrQ",
  "content": "qre",
  "title": "qrB",
  "tabs-container": "qrE",
  "close-button": "qrd btn btn--icon-only btn--tertiary btn--large",
  "pdf-preview": "qrI",
  "header": "qrt",
  "without-tabs": "qrM"
};
