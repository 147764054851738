/* import __COLOCATED_TEMPLATE__ from './table.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

interface ClientsTableSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class ClientsTable extends Component<ClientsTableSignature> {
  @service declare segment: Services['segment'];

  @action
  // @ts-expect-error
  handleClick(itemId) {
    // @ts-expect-error
    this.args.highlightItem(itemId);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Clients::Table': typeof ClientsTable;
  }
}
