export default {
  "modal": "qej",
  "container": "qeW",
  "form-container": "qeu",
  "preview-container": "qBS",
  "preview-content": "qBc",
  "switch-container": "qBq",
  "supplier-invoice-preview": "qBZ",
  "header": "qBR",
  "footer": "qBQ",
  "form-content": "qBe",
  "form-content-section": "qBB",
  "form-disclaimer": "qBE",
  "hidden": "qBd"
};
