/* import __COLOCATED_TEMPLATE__ from './upsize-banner.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import dayjs from 'dayjs';

interface KycInAppBannerSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class KycInAppBannerComponent extends Component<KycInAppBannerSignature> {
  @service declare intl: Services['intl'];
  @service declare subscriptionManager: Services['subscriptionManager'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare segment: Services['segment'];
  @service declare router: Services['router'];

  get activeTrialRemainingDays() {
    return this.subscriptionManager.currentSubscription?.activeTrialRemainingDays;
  }

  get bannerTitleCopy() {
    if (this.activeTrialRemainingDays === 0) {
      return this.intl.t('kyc.upsize-banner.trial-ended.title');
    }

    return this.intl.t('kyc.upsize-banner.title');
  }

  get bannerDescriptionCopy() {
    if (this.activeTrialRemainingDays === 0) {
      return this.intl.t('kyc.upsize-banner.trial-ended.subtitle');
    }

    return this.intl.t('kyc.upsize-banner.subtitle', {
      amount: this.activeTrialRemainingDays,
    });
  }

  get isInFirst3DaysPeriod() {
    let today = dayjs();
    return (
      Math.floor(today.diff(this.organizationManager.membership.createdAt, 'hours') / 24) + 1 < 3
    );
  }

  get bannerIllustrationUrl() {
    return this.isInFirst3DaysPeriod
      ? '/illustrations/kyc-in-app/upsize-banner-kyc.svg'
      : '/illustrations/kyc-in-app/upsize-banner-kyc-after-3-days.svg';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KycInApp::UpsizeBanner': typeof KycInAppBannerComponent;
  }
}
