export default {
  "aside": "ZN",
  "aside-section": "Zv",
  "aside-content": "Zo",
  "aside-footer": "Zn",
  "aside-title": "Zi caption-bold",
  "accountant-upsell": "ZA",
  "file-status": "ZY",
  "file-status-icon": "Zh",
  "file-status-text": "Zf",
  "file-status-label": "ZK body-1",
  "file-status-description": "ZG body-2"
};
