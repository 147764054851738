import Model, { attr } from '@ember-data/model';

interface Column {
  id: string;
  width: number;
  position: number;
  pinned: string;
}

export default class TableViewModel extends Model {
  @attr('string') name!: string;
  @attr('string') resource!: string;
  @attr('number') position!: number;

  @attr() columns!: Column[];
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'table-view': TableViewModel;
    tableView: TableViewModel;
  }
}
