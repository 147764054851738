export default {
  "two-column-layout": "cRy",
  "points-list": "cRU",
  "point-title": "cRj",
  "point-content": "cRW",
  "old-container": "cRu",
  "columns": "cQS",
  "left-column": "cQc",
  "right-column": "cQq",
  "video-wrapper": "cQZ",
  "selling-points-wrapper": "cQR",
  "actions": "cQQ"
};
