/* import __COLOCATED_TEMPLATE__ from './account-receivable-onboarding-promo-box.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { Badge } from '@repo/design-system-kit';

import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';

export const LOCAL_STORAGE_KEY = 'account-receivable-onboarding-promo-box-dismissed';

interface DualNavAccountReceivableOnboardingPromoBoxSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class DualNavAccountReceivableOnboardingPromoBoxComponent extends Component<DualNavAccountReceivableOnboardingPromoBoxSignature> {
  badge = Badge;

  @service
  declare accountReceivableOnboardingManager: Services['accountReceivableOnboardingManager'];

  get shouldDisplay() {
    return (
      this.accountReceivableOnboardingManager.isNotEligibleForOnboarding &&
      !safeLocalStorage.getItem(LOCAL_STORAGE_KEY)
    );
  }

  @action
  handleOnClick() {
    // @ts-expect-error
    safeLocalStorage.setItem(LOCAL_STORAGE_KEY, Date.now());
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'DualNav::AccountReceivableOnboardingPromoBox': typeof DualNavAccountReceivableOnboardingPromoBoxComponent;
  }
}
