export default {
  "row": "Oy",
  "icon": "OU",
  "cell": "Oj",
  "empty": "OW",
  "no-padding": "Ou",
  "cell-content": "mS",
  "right": "mc",
  "cube": "mq"
};
