/* import __COLOCATED_TEMPLATE__ from './item.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

// @ts-expect-error
import { F24_STATUS } from 'qonto/constants/f24';

interface F24V2TableItemSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLTableRowElement;
}

export default class F24V2TableItemComponent extends Component<F24V2TableItemSignature> {
  @service declare intl: Services['intl'];

  status = {
    // @ts-expect-error
    [F24_STATUS.SCHEDULED]: this.intl.t('f24.status.scheduled'),
    // @ts-expect-error
    [F24_STATUS.COMPLETED]: this.intl.t('f24.status.completed'),
    // @ts-expect-error
    [F24_STATUS.DECLINED]: this.intl.t('f24.status.declined'),
    // @ts-expect-error
    [F24_STATUS.CANCELED]: this.intl.t('f24.status.canceled'),
  };

  get isTerminatedPayment() {
    // @ts-expect-error
    return this.args.item.status === 'declined' || this.args.item.status === 'canceled';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'F24::Table::Item': typeof F24V2TableItemComponent;
  }
}
