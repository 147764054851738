export default {
  "container": "GI",
  "back-button": "Gt",
  "card-list": "GM",
  "upsell-badge": "GP",
  "pricing": "Gl",
  "pricing-period": "GX",
  "ad-modal-button": "GC",
  "eligibility-disclaimer": "Gk",
  "feature": "GJ",
  "highlighted": "Gs"
};
