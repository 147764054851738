/* import __COLOCATED_TEMPLATE__ from './image-zoomer.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

interface ImageZoomerSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class ImageZoomerComponent extends Component<ImageZoomerSignature> {
  @tracked isLoading = true;

  @action
  onImageLoaded() {
    this.isLoading = false;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    ImageZoomer: typeof ImageZoomerComponent;
  }
}
