export default {
  "wrapper": "cIe",
  "close-button": "cIB",
  "content-wrapper": "cIE",
  "main": "cId",
  "main-content": "cII",
  "description": "cIt",
  "mt-26": "cIM",
  "mt-62": "cIP",
  "user-prompt": "cIl",
  "user-prompt-inappropriate-warning": "cIX",
  "logo-preview": "cIC",
  "static-loader": "cIk",
  "loading-state-illustration": "cIJ",
  "sticky-panel": "cIs"
};
