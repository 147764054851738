import { FormattedMessage, useIntl } from 'react-intl';
import type { ReactElement } from 'react';
import { CockpitTile } from 'qonto/react/components/financing/pay-later/cockpit/insights/cockpit-tile';
import type { Amount } from 'qonto/react/models/amount.ts';
import { Badge } from './internal/badge';
import styles from './styles.strict-module.css';
import { Tooltip } from './internal/tooltip';

export type RepaymentStatus = 'at_risk' | 'late' | 'frozen';

export interface NextInstallmentsTileProps {
  nextRepaymentAmount: Amount;
  count: number;
  repaymentStatus?: RepaymentStatus;
}

export function NextInstallmentsTile({
  nextRepaymentAmount: amount,
  count,
  repaymentStatus,
}: NextInstallmentsTileProps): ReactElement {
  const intl = useIntl();
  const shouldShowBadge = repaymentStatus === 'at_risk' || repaymentStatus === 'late';
  const shouldShowTooltip = shouldShowBadge && count;

  return (
    <CockpitTile
      amount={amount}
      amountClassName={repaymentStatus === 'frozen' ? styles.red : ''}
      badge={shouldShowBadge ? <Badge repaymentStatus={repaymentStatus} /> : undefined}
      dataTestId="next-installments-tile"
      subtitle={
        <span className={styles.subtitle} data-test-next-installments-subtitle="">
          <FormattedMessage
            id="financing.pay-later.cockpit.insights.next-installments.subtitle"
            values={{ count }}
          />
        </span>
      }
      title={intl.formatMessage({
        id: 'financing.pay-later.cockpit.insights.next-installments.label',
      })}
      tooltip={
        shouldShowTooltip ? <Tooltip count={count} repaymentStatus={repaymentStatus} /> : undefined
      }
    />
  );
}
