export default {
  "form": "qtj",
  "form-section": "qtW",
  "form-title": "qtu title2",
  "divider": "qMS",
  "row": "qMc",
  "col": "qMq",
  "actions": "qMZ",
  "buttons": "qMR",
  "collapsible-timeline": "qMQ",
  "timeline-status-label": "qMe",
  "approval-workflow-state-section-wrapper": "qMB"
};
