/* import __COLOCATED_TEMPLATE__ from './flow-with-forecast.hbs'; */
import { service } from '@ember/service';

import { PERIOD_RATES } from 'qonto/react/models/cash-flow-period';

import OverviewChartTooltipFlowComponent from './flow';

export default class OverviewChartTooltipFlowWithForecastComponent extends OverviewChartTooltipFlowComponent {
  // @ts-expect-error
  @service intl;

  get forecastTitle() {
    // @ts-expect-error
    return this.args.flow?.isInflow
      ? this.intl.t('cash-flow.graph.period-tooltip.inflows.forecast')
      : this.intl.t('cash-flow.graph.period-tooltip.outflows.forecast');
  }

  get monthlyTotalCopy() {
    const frequencyMap = {
      [PERIOD_RATES.YEARLY]: this.intl.t('overview.yearly-total'),
      [PERIOD_RATES.QUARTERLY]: this.intl.t('overview.quarterly-total'),
      [PERIOD_RATES.MONTHLY]: this.intl.t('overview.monthly-total'),
    };

    // @ts-expect-error
    return frequencyMap[this.args.selectedFrequency] || frequencyMap.monthly;
  }

  get isCurrent() {
    // @ts-expect-error
    return this.args.flow.isCurrent;
  }

  get isFuture() {
    // @ts-expect-error
    return this.args.flow.isFuture;
  }
}
