/* import __COLOCATED_TEMPLATE__ from './account-feature-item.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface AccountFeatureItemSignature {
  Element: HTMLLIElement;
  Args: {};
  Blocks: {
    default: [];
  };
}

const AccountFeatureItem = templateOnlyComponent<AccountFeatureItemSignature>();

export default AccountFeatureItem;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    AccountFeatureItem: typeof AccountFeatureItem;
  }
}
