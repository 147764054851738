export default {
  "review": "qeQ",
  "review-content": "qee",
  "review-parameters": "qeB",
  "row": "qeE",
  "row__border": "qed",
  "label": "qeI",
  "value": "qet",
  "error": "qeM",
  "tooltip-icon": "qeP",
  "terms-link": "qel"
};
