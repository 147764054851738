/* import __COLOCATED_TEMPLATE__ from './email-preview.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { EmailPreviewFooter } from 'qonto/react/components/receivable-invoices/email-preview';

interface EmailPreviewSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class EmailPreviewComponent extends Component<EmailPreviewSignature> {
  emailPreviewFooter = EmailPreviewFooter;

  @service declare intl: Services['intl'];
  @service declare theme: Services['theme'];

  get footerDisclaimer() {
    return this.intl
      .t('receivable-invoices.share-email.preview.credit-note-disclaimer', {
        // @ts-expect-error
        language: this.args.displayLanguage,
        htmlSafe: true,
      })
      .toString();
  }

  get currency() {
    // @ts-expect-error
    return this.args.creditNote.currency || 'EUR';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ReceivableInvoices::CreditNotes::EmailPreview': typeof EmailPreviewComponent;
  }
}
