/* import __COLOCATED_TEMPLATE__ from './multi-transfer-detail.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { Disclaimer } from '@repo/design-system-kit';

// @ts-expect-error
import { SPEND_LIMITS_WARNINGS } from 'qonto/utils/extract-confirmation-response';

interface RequestHeaderMultiTransferDetailSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class RequestHeaderMultiTransferDetailComponent extends Component<RequestHeaderMultiTransferDetailSignature> {
  disclaimerInline = Disclaimer.Inline;

  @service declare abilities: Services['abilities'];

  get showInfoDisclaimer() {
    // @ts-expect-error
    let { request, warnings } = this.args;
    if (
      !warnings ||
      this.abilities.cannot('view own request info disclaimer transfer-limit', request)
    ) {
      return false;
    }

    // @ts-expect-error
    return SPEND_LIMITS_WARNINGS.some(warning => warnings.includes(warning));
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Request::Header::MultiTransferDetail': typeof RequestHeaderMultiTransferDetailComponent;
  }
}
