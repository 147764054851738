export default {
  "sidebar-header": "Hv",
  "sidebar-header__top": "Ho",
  "sidebar-header__close-button": "Hn",
  "close-button": "Hi",
  "sidebar-header__body": "HA",
  "sidebar-header__content": "HY",
  "sidebar-header__date": "Hh",
  "infobox": "Hf",
  "infobox--link": "HK",
  "ghost": "HG",
  "fail": "Hr",
  "success": "Hb"
};
