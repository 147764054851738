/* import __COLOCATED_TEMPLATE__ from './total-fees.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { DATE_FORMAT_TOKENS, dateToken } from '@qonto/ui-kit/utils/date-token';

import { ORGA_STATUS } from 'qonto/constants/organization';
import { SUBSCRIPTION_RECURRENCES } from 'qonto/constants/subscriptions';
import { codeToName } from 'qonto/models/subscription-product';

interface SubscriptionTotalFeesSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class SubscriptionTotalFeesComponent extends Component<SubscriptionTotalFeesSignature> {
  @service declare intl: Services['intl'];
  @service declare subscriptionManager: Services['subscriptionManager'];
  @service declare organizationManager: Services['organizationManager'];

  SUBSCRIPTION_RECURRENCES = SUBSCRIPTION_RECURRENCES;

  get currentMonthYear() {
    return dateToken({
      // @ts-expect-error
      date: new Date(),
      // @ts-expect-error
      locale: this.intl.primaryLocale,
      token: DATE_FORMAT_TOKENS.MONTH_YEAR_L,
    });
  }

  get switchAnnualEnabled() {
    return (
      this.organizationManager.organization.status === ORGA_STATUS.ACTIVATED &&
      // @ts-expect-error
      !this.args.hasDeactivationInProgress
    );
  }

  get isAnnual() {
    return (
      this.subscriptionManager.currentSubscription?.recurrence === SUBSCRIPTION_RECURRENCES.ANNUAL
    );
  }

  get currentPricePlanCode() {
    // eslint-disable-next-line no-restricted-syntax
    return `${this.subscriptionManager.currentPricePlan.groupCode}_2024`;
  }

  // @ts-expect-error
  getNameFromCode = code => {
    return codeToName(this.intl, code);
  };
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Subscription::TotalFees': typeof SubscriptionTotalFeesComponent;
  }
}
