/* import __COLOCATED_TEMPLATE__ from './breadcrumbs.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface BreadcrumbsSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const Breadcrumbs = templateOnlyComponent<BreadcrumbsSignature>();

export default Breadcrumbs;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'DualNav::Breadcrumbs': typeof Breadcrumbs;
  }
}
