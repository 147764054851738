/* import __COLOCATED_TEMPLATE__ from './overview.hbs'; */
/* eslint-disable @qonto/no-async-action */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { BadgeHighlight } from '@repo/design-system-kit';

const SLIDES_PER_PAGE = 3;

interface DiscoverOverviewSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class DiscoverOverviewComponent extends Component<DiscoverOverviewSignature> {
  badgeHighlight = BadgeHighlight;

  @service declare segment: Services['segment'];
  @service declare subscriptionManager: Services['subscriptionManager'];

  get isSwipable() {
    // @ts-expect-error
    return this.args.slides?.length > SLIDES_PER_PAGE;
  }

  @action
  async onClick() {
    // @ts-expect-error
    await this.args.close?.();
    // @ts-expect-error
    await this.args.onClick?.();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Discover::Overview': typeof DiscoverOverviewComponent;
  }
}
