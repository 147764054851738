/* import __COLOCATED_TEMPLATE__ from './duration-selection.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

const DURATION_TYPE = {
  DEFAULT: 'default',
  CUSTOM: 'custom',
};

interface DirectDebitCollectionsNewDurationSelectionSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class DirectDebitCollectionsNewDurationSelectionComponent extends Component<DirectDebitCollectionsNewDurationSelectionSignature> {
  @service declare intl: Services['intl'];

  durationOptions = [
    {
      value: DURATION_TYPE.DEFAULT,
      // @ts-expect-error
      label: this.intl.t('sdd-collections.duration.no-end-date'),
      clearable: false,
    },
    {
      value: DURATION_TYPE.CUSTOM,
      // @ts-expect-error
      label: this.intl.t('sdd-collections.duration.number-of-payments'),
      clearable: true,
    },
  ];

  get selectedDurationOption() {
    // @ts-expect-error
    return this.args.duration.isCustomDuration ? this.durationOptions[1] : this.durationOptions[0];
  }

  @action
  // @ts-expect-error
  onUpdateDuration(durationOption) {
    // @ts-expect-error
    this.args.onUpdateDuration({
      scheduleTotalCollectionCount: null,
      isCustomDuration: durationOption.value === DURATION_TYPE.CUSTOM,
    });
  }

  @action
  // @ts-expect-error
  onUpdateScheduleTotalCollectionCount(scheduleTotalCollectionCount) {
    // @ts-expect-error
    this.args.onUpdateDuration({
      scheduleTotalCollectionCount,
      // @ts-expect-error
      isCustomDuration: this.args.duration.isCustomDuration,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'DirectDebitCollections::Subscription::DurationSelection': typeof DirectDebitCollectionsNewDurationSelectionComponent;
  }
}
