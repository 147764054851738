/* import __COLOCATED_TEMPLATE__ from './date-picker.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

import { parseDate } from '@internationalized/date';
import { DatePicker } from '@repo/design-system-kit';

import isFunction from 'qonto/utils/is-function';

interface Signature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class TransfersInternationalOutDynamicFormFieldDatePickerComponent extends Component<Signature> {
  datePicker = DatePicker;

  get date() {
    // @ts-expect-error
    return this.args?.value ? parseDate(this.args.value) : null;
  }

  get #shouldRefreshOnChange() {
    // @ts-expect-error
    let { field, onRefresh } = this.args;
    return field.refreshRequirementsOnChange && isFunction(onRefresh);
  }

  @action
  // @ts-expect-error
  onUpdate(date) {
    // @ts-expect-error
    let { field, onRefresh, onUpdate } = this.args;

    onUpdate(field.key, date ? date.toString() : null);

    if (this.#shouldRefreshOnChange) {
      onRefresh(field.key);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transfers::InternationalOut::DynamicForm::Field::DatePicker': typeof TransfersInternationalOutDynamicFormFieldDatePickerComponent;
  }
}
