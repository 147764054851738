export default {
  "sections": "qZK",
  "actions": "qZG",
  "search-input": "qZr",
  "no-upload": "qZb",
  "new-link": "qZy body-2",
  "between-links": "qZU",
  "action-bar": "qZj",
  "empty-state": "qZW"
};
