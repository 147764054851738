export default {
  "wrapper": "qRX",
  "header": "qRC mb-48",
  "form-title": "qRk title-2 mb-16",
  "form-subtitle": "qRJ body-2",
  "form": "qRs",
  "section": "qRF mb-48",
  "section-title": "qRg title-3 mb-16",
  "form-footer": "qRD",
  "footer-btn": "qRV",
  "transfer-form": "qRL"
};
