export default {
  "budget-edit-form": "dP",
  "period-label": "dl",
  "periods": "dX",
  "yearly": "dC",
  "tooltips": "dk",
  "year": "dJ",
  "total-budget": "ds",
  "total-budget-value": "dF",
  "divider": "dg",
  "buttons": "dD"
};
