/* import __COLOCATED_TEMPLATE__ from './row.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { DATE_FORMAT_TOKENS, dateToken } from '@qonto/ui-kit/utils/date-token';
import { Checkbox } from '@repo/design-system-kit';
import dayjs from 'dayjs';
import { dropTask, race, rawTimeout } from 'ember-concurrency';
// @ts-expect-error
import { variation } from 'ember-launch-darkly';

// @ts-expect-error
import { SUPPLIER_INVOICE_EVENTS } from 'qonto/constants/listeners';
import {
  INVOICE_STATUSES,
  LOKALIZE_SOURCE_TYPES,
  SOURCE_TYPES,
  TABS,
} from 'qonto/constants/supplier-invoice';

interface SupplierInvoicesTableRowSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLTableRowElement;
}

export default class SupplierInvoicesTableRowComponent extends Component<SupplierInvoicesTableRowSignature> {
  checkbox = Checkbox;

  @service declare abilities: Services['abilities'];
  @service declare intl: Services['intl'];
  @service declare store: Services['store'];
  @service declare requestsManager: Services['requestsManager'];
  @service declare notifierManager: Services['notifierManager'];
  @service declare organizationManager: Services['organizationManager'];

  @tracked isRowActive = false;

  get showPendingInvoiceQuickActions() {
    // @ts-expect-error
    return this.args.tab === TABS.TASKS;
  }

  get showInvoiceQuickActions() {
    return (
      !this.showPendingInvoiceQuickActions &&
      // @ts-expect-error
      (this.args.tab !== TABS.TO_APPROVE || this.canApproveRequest)
    );
  }

  get canApproveRequest() {
    // @ts-expect-error
    return this.args.invoice.approvalWorkflow?.approverIds?.includes(
      this.organizationManager.membership.id
    );
  }

  get requesterName() {
    // @ts-expect-error
    let { requestTransfer } = this.args.invoice || {};

    if (requestTransfer?.initiator_id) {
      let { fullName } = this.store.peekRecord('membership', requestTransfer.initiator_id) || {};

      return fullName || '-';
    }
  }

  get supplierName() {
    let {
      invoiceNumber,
      isAttachmentNonFinancial,
      source,
      hasDuplicates,
      selfInvoiceId,
      status,
      id,
      isCreditNote,
      supplierSnapshot,
      // @ts-expect-error
    } = this.args.invoice;

    let isItalian = this.organizationManager.organization?.isItalian;

    return {
      id: id || '-',
      status: status || '-',
      title: supplierSnapshot?.name || '-',
      subtitle: invoiceNumber || '-',
      missing: !supplierSnapshot?.name,
      isEInvoice: source === SOURCE_TYPES.E_INVOICING && isItalian,
      hasSelfInvoice: selfInvoiceId,
      hasDuplicates,
      isAttachmentNonFinancial,
      isCreditNote,
    };
  }

  get shouldDisplayPayableAmount() {
    // @ts-expect-error
    let { isCreditNote, relatedInvoices, status, payableAmount } = this.args.invoice;

    if (status === INVOICE_STATUSES.archived || status === INVOICE_STATUSES.paid) {
      return false;
    }

    return (
      variation('feature--boolean-ap-credit-notes') &&
      relatedInvoices?.length &&
      !isCreditNote &&
      payableAmount?.value > 0
    );
  }

  get totalAmount() {
    let { totalAmount, isCreditNote, payableAmount, totalAmountCreditNotes, relatedInvoices } =
      // @ts-expect-error
      this.args.invoice || {};
    return {
      // @ts-expect-error
      title: this.intl.formatMoney(totalAmount?.value, {
        currency: totalAmount?.currency,
        signus:
          variation('experiment--boolean-ap-credit-notes') ||
          variation('feature--boolean-ap-credit-notes')
            ? isCreditNote
              ? '-'
              : null
            : '-',
      }),
      isCreditNote,
      missing: !totalAmount || !totalAmount.value,
      creditNotesCount: relatedInvoices?.length || 0,
      // @ts-expect-error
      totalAmountCreditNotes: this.intl.formatMoney(totalAmountCreditNotes?.value, {
        currency: totalAmountCreditNotes?.currency,
        signus: '-',
      }),
      payableAmount: this.shouldDisplayPayableAmount
        ? // @ts-expect-error
          this.intl.formatMoney(payableAmount?.value, {
            currency: payableAmount?.currency,
          })
        : 0,
    };
  }

  get showInvoiceSelector() {
    return (
      // @ts-expect-error
      [TABS.ALL_INVOICES, TABS.INBOX, TABS.TO_PAY].includes(this.args.tab) &&
      this.abilities.can('update supplier-invoice')
    );
  }

  get isSupplierInvoiceReviewable() {
    // @ts-expect-error
    let { invoice, status } = this.args;
    let { selfInvoiceId } = invoice;

    return status === INVOICE_STATUSES.toReview && !selfInvoiceId;
  }

  get showDueDate() {
    // @ts-expect-error
    let { invoice } = this.args;
    let { isCreditNote } = invoice;

    return !variation('feature--boolean-ap-credit-notes') || !isCreditNote;
  }

  get specificCells() {
    // @ts-expect-error
    let { invoice, tab } = this.args;
    let { paymentDate, dueDate, status, matchedTransactionsIds, source, isCreditNote } = invoice;

    let showOverdue =
      dueDate && status === INVOICE_STATUSES.toReview && dayjs().isAfter(new Date(dueDate));

    let cellTypes = {
      [TABS.TASKS]: {
        ...(this.abilities.can('load transfer request') ? { requester: this.requesterName } : {}),
        dueDate: {
          date: dueDate
            ? dateToken({
                date: dueDate,
                // @ts-expect-error
                locale: this.intl.primaryLocale,
                token: DATE_FORMAT_TOKENS.DATE_YEAR_S,
              })
            : '-',
          overdue: dueDate ? dayjs().isAfter(new Date(dueDate)) : false,
          missing: !dueDate,
        },
      },
      [TABS.ALL_INVOICES]: {
        status,
        dueDate: {
          date:
            dueDate && this.showDueDate
              ? dateToken({
                  date: dueDate,
                  // @ts-expect-error
                  locale: this.intl.primaryLocale,
                  token: DATE_FORMAT_TOKENS.DATE_YEAR_S,
                })
              : '-',
          overdue: showOverdue,
          missing: !dueDate,
          isCreditNote,
        },
        paymentDate: {
          date:
            ![INVOICE_STATUSES.scheduled, INVOICE_STATUSES.paid].includes(status) || !paymentDate
              ? '-'
              : dateToken({
                  date: paymentDate,
                  // @ts-expect-error
                  locale: this.intl.primaryLocale,
                  token: DATE_FORMAT_TOKENS.DATE_YEAR_S,
                }),
          withClockIcon: INVOICE_STATUSES.scheduled === status && paymentDate,
        },
        transactions: {
          shouldDisplayTransactionsBadge: true,
          hasMatchedTransactions: Boolean(matchedTransactionsIds.length),
        },
      },
      [TABS.INBOX]: {
        dueDate: {
          date:
            dueDate && this.showDueDate
              ? dateToken({
                  date: dueDate,
                  // @ts-expect-error
                  locale: this.intl.primaryLocale,
                  token: DATE_FORMAT_TOKENS.DATE_YEAR_S,
                })
              : '-',
          overdue: dueDate ? dayjs().isAfter(new Date(dueDate)) : false,
          missing: !dueDate,
          isCreditNote,
        },
      },
      [TABS.TO_APPROVE]: {
        ...(variation('feature--boolean-approval-workflow-for-supplier-invoices') && {
          previousStep: {
            step: this.invoiceApprovalWorkflowPreviousStep,
          },
          approvers: {
            memberships: this.invoiceApproversString,
          },
        }),
        dueDate: {
          date:
            dueDate && this.showDueDate
              ? dateToken({
                  date: dueDate,
                  // @ts-expect-error
                  locale: this.intl.primaryLocale,
                  token: DATE_FORMAT_TOKENS.DATE_YEAR_S,
                })
              : '-',
          overdue: dueDate ? dayjs().isAfter(new Date(dueDate)) : false,
          missing: !dueDate,
          isCreditNote,
        },
      },
      [TABS.TO_PAY]: {
        ...(variation('feature--boolean-improve-first-time-exp-si') && {
          importedVia: {
            source: LOKALIZE_SOURCE_TYPES(this.intl, source) || '-',
          },
        }),
        ...(variation('feature--boolean-approval-workflow-for-supplier-invoices') && {
          previousStep: {
            step: this.invoiceApprovalWorkflowPreviousStep,
          },
          approvers: {
            memberships: this.invoiceApproversString,
          },
        }),
        dueDate: {
          date:
            dueDate && this.showDueDate
              ? dateToken({
                  date: dueDate,
                  // @ts-expect-error
                  locale: this.intl.primaryLocale,
                  token: DATE_FORMAT_TOKENS.DATE_YEAR_S,
                })
              : '-',
          overdue: dueDate ? dayjs().isAfter(new Date(dueDate)) : false,
          missing: !dueDate,
          isCreditNote,
        },
      },
      [TABS.SCHEDULED]: {
        dueDate: {
          date:
            dueDate && this.showDueDate
              ? dateToken({
                  date: dueDate,
                  // @ts-expect-error
                  locale: this.intl.primaryLocale,
                  token: DATE_FORMAT_TOKENS.DATE_YEAR_S,
                })
              : '-',
          missing: !dueDate,
          isCreditNote,
        },
        paymentDate:
          status === INVOICE_STATUSES.archived || !paymentDate
            ? '-'
            : dateToken({
                date: paymentDate,
                // @ts-expect-error
                locale: this.intl.primaryLocale,
                token: DATE_FORMAT_TOKENS.DATE_YEAR_S,
              }),
      },
      [TABS.COMPLETED]: {
        status,
        paymentDate:
          status === INVOICE_STATUSES.archived || !paymentDate
            ? '-'
            : dateToken({
                date: paymentDate,
                // @ts-expect-error
                locale: this.intl.primaryLocale,
                token: DATE_FORMAT_TOKENS.DATE_YEAR_S,
              }),
        transactions: {
          shouldDisplayTransactionsBadge: true,
          hasMatchedTransactions: Boolean(matchedTransactionsIds.length),
        },
      },
    };

    // @ts-expect-error
    return cellTypes[tab];
  }

  get invoiceApproversString() {
    // @ts-expect-error
    let { invoice } = this.args;
    return (
      invoice.approvalWorkflow?.approverIds
        ?.map((id: string) => {
          let membership = this.store.peekRecord('membership', id);
          return membership?.fullName || '-';
        })
        .join(', ') || '-'
    );
  }

  get invoiceApprovalWorkflowPreviousStep() {
    // @ts-expect-error
    let { invoice } = this.args;

    let { verifiedBy, lastApprovedBy } = invoice.approvalWorkflow || {};

    if (lastApprovedBy) {
      let membership = this.store.peekRecord('membership', lastApprovedBy);
      return `${this.intl.t('supplier-invoices.preview.previous-step.approved-by')} ${membership?.fullName}`;
    }

    if (verifiedBy) {
      let membership = this.store.peekRecord('membership', verifiedBy);
      return `${this.intl.t('supplier-invoices.preview.previous-step.reviewed-by')} ${membership?.fullName}`;
    }

    return this.intl.t('supplier-invoices.preview.previous-step.assigned-through-workflow') || '-';
  }

  @action
  // @ts-expect-error
  toggleRowState(boolean) {
    this.isRowActive = boolean;
  }

  approveRequestTask = dropTask(async ({ supplierInvoice, bankAccount }) => {
    let requestId = supplierInvoice.requestTransfer?.id;
    let request = await this.store.findRecord('request-transfer', requestId);
    request.bankAccount = bankAccount;
    await this.requestsManager.quickApproveTransferRequestTask.perform(request);
    // @ts-expect-error
    let isInvoiceUpdated = this.notifierManager.waitForEventTask.perform(
      SUPPLIER_INVOICE_EVENTS.SCHEDULED
    );
    await race([isInvoiceUpdated, rawTimeout(1000)]);
  });

  rejectRequestTask = dropTask(async supplierInvoice => {
    let requestId = supplierInvoice.requestTransfer?.id;
    let request = await this.store.findRecord('request-transfer', requestId);
    await this.requestsManager.quickRejectTransferRequestTask.perform(request);
    // @ts-expect-error
    let isInvoiceUpdated = this.notifierManager.waitForEventTask.perform(
      SUPPLIER_INVOICE_EVENTS.REQUEST_DECLINED
    );
    await race([isInvoiceUpdated, rawTimeout(1000)]);
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'SupplierInvoices::Table::Row': typeof SupplierInvoicesTableRowComponent;
  }
}
