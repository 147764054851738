export default {
  "header-cell": "cAp",
  "empty": "cAx",
  "header-content": "cAw caption-bold",
  "active": "cAN",
  "align-right": "cAv",
  "col-8": "cAo",
  "col-7": "cAn",
  "col-5": "cAi",
  "col-4": "cAA"
};
