import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { reads } from 'macro-decorators';

import { PRODUCT_ROUTE, PROVIDERS_MAP } from 'qonto/constants/insurance-hub';

export default class InsuranceHubProductController extends Controller {
  @service router;
  @service segment;
  @service organizationManager;

  @reads('organizationManager.organization.legalCountry') legalCountry;

  @tracked showCompactHeader = false;

  constructor() {
    super(...arguments);

    this.router.on('routeWillChange', transition => {
      if (transition.from && transition.from.name === PRODUCT_ROUTE) {
        this.showCompactHeader = false;
      }
    });
  }

  @action
  handleIntersect(entries) {
    this.showCompactHeader = entries.some(entry => !entry.isIntersecting);
  }

  @action
  handleChangeTab(tab) {
    this.segment.track('insurance-hub-offer-details_tab_clicked', { tab });
  }

  get partnerName() {
    return PROVIDERS_MAP[this.legalCountry].providerName;
  }
}
