/* import __COLOCATED_TEMPLATE__ from './transfer-funds.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { TransferFundsScreen } from 'qonto/react/components/flows/subscription/transfer-funds/index.ts';

export default class SubscriptionCloseTransferFundsComponent extends Component {
  @service declare segment: Services['segment'];
  @service declare flow: Services['flow'];
  @service declare router: Services['router'];
  transferFundsScreen = TransferFundsScreen;

  get dueDate() {
    return (this.flow.dataContext as { dueDate: string | undefined }).dueDate;
  }

  @action onClose() {
    this.flow.next();
  }

  @action onTransferFunds() {
    this.router.transitionTo('transfers.landing');
  }
}
