/* import __COLOCATED_TEMPLATE__ from './amount-input.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import {
  formatAmountWithoutCurrency,
  getAmountProperties,
  parseFormattedAmount,
} from 'qonto/utils/amount';

// @ts-expect-error
const removeTrailingSpaces = value => value?.replace(/\s/g, '');

interface Signature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLInputElement;
}

/**
 * An amount/money input component .
 *
 * @public
 * @class AmountInput
 *
 * @example
 *
 * ```hbs
 *  <AmountInput @value={{this.value}} @currency={{this.currency}} @update={{fn (mut this.value)}} />
 * ```
 */
export default class TransfersInternationalOutAmountCurrencyFieldAmountInputComponent extends Component<Signature> {
  @service declare localeManager: Services['localeManager'];

  get amountProperties() {
    // @ts-expect-error
    return getAmountProperties(this.args.currency, this.localeManager.locale);
  }

  /**
   * Specifies the number of decimals to use for the amount value.
   * Can be >= 0.
   * Defaults to based in the currency code
   */
  get numberOfDecimals() {
    // @ts-expect-error
    return this.args.numberOfDecimals || this.amountProperties.numberOfDecimals;
  }

  /**
   * The placeholder displayed in the input.
   * Defaults to based in the currency code
   */
  get placeholder() {
    // @ts-expect-error
    return this.args.placeholder || this.amountProperties.placeholder;
  }

  get value() {
    // @ts-expect-error
    let { value, currency } = this.args;
    return isNaN(parseFloat(value))
      ? ''
      : // @ts-expect-error
        formatAmountWithoutCurrency({ amount: value, currency }, this.localeManager.locale);
  }

  /**
   * Handles the blur event for the amount input field.
   *
   * @param {Event} event - The blur event object.
   * @returns {boolean} Always returns true.
   */
  @action
  // @ts-expect-error
  onBlur(event) {
    let { value } = event.target;
    let sanitizedValue = removeTrailingSpaces(value);
    let parsedValue = this.#parseFormattedAmount(sanitizedValue);

    // @ts-expect-error
    if (parsedValue !== this.args.value) {
      if (sanitizedValue === '') {
        // @ts-expect-error
        this.args.update(null);
      } else {
        event.target.value = sanitizedValue;
        // @ts-expect-error
        this.args.update(parsedValue);
      }
    }
    return true;
  }

  /**
   * Handles the input event. This action is responsible for cleaning, parsing, and updating the value.
   *
   * @param {Event} event - The input event object.
   * @returns {boolean} Returns true if the input value is valid and updated, false otherwise.
   */
  @action
  // @ts-expect-error
  onInput(event) {
    let { value } = event.target;
    let sanitizedValue = removeTrailingSpaces(value);
    let parsedValue = this.#parseFormattedAmount(sanitizedValue);

    if (sanitizedValue === '') {
      // @ts-expect-error
      this.args.update(null);
      return true;
    }

    // If the parsed value is not a valid number, we wait for the onBlur event to fire an update
    if (isNaN(parsedValue)) {
      event.preventDefault();
      return false;
    }
    // If the last character value is decimal separator or decimal part is only zero, we wait for the next input or blur
    if (
      this.#isLastCharacterDecimalSeparator(sanitizedValue) ||
      this.#isDecimalPartZero(sanitizedValue)
    ) {
      event.preventDefault();
      return false;
    }

    // @ts-expect-error
    this.args.update(parsedValue);
    return true;
  }

  /**
   * Prevents user from typing non-numeric characters except for the decimal/group separator and the special keys (allowing copy paste).
   *
   * @param {KeyboardEvent} event - The keydown event object.
   * @returns {boolean} True if the event is valid, false otherwise.
   */
  @action
  // @ts-expect-error
  onKeyDown(event) {
    let { key, altKey, ctrlKey, metaKey } = event;

    if (this.numberOfDecimals === 0 && ['.', ','].includes(event.key)) {
      event.preventDefault();
      return false;
    }

    let isValidKey =
      key.length > 1 ||
      altKey ||
      ctrlKey ||
      metaKey ||
      ['.', ',', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(event.key);

    if (!isValidKey) {
      event.preventDefault();
    }

    return isValidKey;
  }

  /**
   * Handles the paste event for the amount input field.
   *
   * @param {ClipboardEvent} event - The paste event object.
   * @returns {boolean} Always returns true.
   */
  @action
  // @ts-expect-error
  onPaste(event) {
    let value = event.clipboardData?.getData('text');

    let sanitizedValue = removeTrailingSpaces(value);
    let parsedValue = this.#parseFormattedAmount(sanitizedValue);

    // If the parsed value is not a valid number, we wait for the onBlur event to fire an update
    if (isNaN(parsedValue)) {
      event.preventDefault();
      return false;
    }

    // @ts-expect-error
    this.args.update(parsedValue);

    event.preventDefault();
    return true;
  }

  // @ts-expect-error
  #isLastCharacterDecimalSeparator(value) {
    return value.slice(-1) === this.amountProperties.decimalSeparator;
  }

  // @ts-expect-error
  #isDecimalPartZero(value) {
    let { groupSeparator, decimalSeparator } = this.amountProperties;
    let normalizedAmount = value.replaceAll(groupSeparator, '').replace(decimalSeparator, '.');
    let decimals = normalizedAmount.split('.').at(1);
    return decimals === '0';
  }

  // @ts-expect-error
  #parseFormattedAmount(value) {
    return parseFormattedAmount(value, this.amountProperties);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transfers::InternationalOut::AmountCurrencyField::AmountInput': typeof TransfersInternationalOutAmountCurrencyFieldAmountInputComponent;
  }
}
