/* import __COLOCATED_TEMPLATE__ from './option.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface OptionSignature {
  Element: HTMLLIElement;
  Args: {};
  Blocks: {
    default: [];
  };
}

const Option = templateOnlyComponent<OptionSignature>();

export default Option;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Cards::ChooseCard::Option': typeof Option;
  }
}
