export default {
  "team-card": "qJB",
  "card-link": "qJE",
  "shadow": "qJd",
  "history-button": "qJI",
  "cards-button": "qJt",
  "menu-item": "qJM",
  "subtitle": "qJP",
  "delete-team": "qJl"
};
