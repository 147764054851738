/* import __COLOCATED_TEMPLATE__ from './card-possession.hbs'; */
import Component from '@glimmer/component';

import { CardPossession } from 'qonto/react/components/flows/chargeback-claim/card-possession';

interface FlowsRequestCardsCardPossessionSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsRequestCardsCardPossessionComponent extends Component<FlowsRequestCardsCardPossessionSignature> {
  cardPossession = CardPossession;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::ChargebackClaim::CardPossession': typeof FlowsRequestCardsCardPossessionComponent;
  }
}
