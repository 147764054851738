export default {
  "container": "csG",
  "close-button": "csr",
  "content-wrapper": "csb",
  "main": "csy",
  "description": "csU",
  "calculation-item": "csj",
  "total-km": "csW",
  "calculation-details": "csu",
  "btn": "cFS"
};
