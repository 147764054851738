import { type ReactNode } from 'react';
import { Button } from '@repo/design-system-kit';
import { DialogTrigger } from 'react-aria-components';
import cx from 'clsx';
import { FormattedMessage } from 'react-intl';
import { TableOutlined } from 'qonto/react/assets/icons/table-outlined';
import styles from './styles.strict-module.css';

interface ColumnManagerTriggerProps {
  className?: string;
}

export function ColumnManagerTrigger({
  className,
  ...props
}: ColumnManagerTriggerProps): ReactNode {
  return (
    <DialogTrigger>
      <Button
        className={cx(styles.trigger, className)}
        data-testid="column-manager-trigger"
        {...props}
      >
        <div className={styles['trigger-content']}>
          <TableOutlined className={styles.icon} />
          <FormattedMessage id="transactions.modular_table.filter_button.columns" />
        </div>
      </Button>
      {/* <ColumnManagerPopover /> */}
    </DialogTrigger>
  );
}
