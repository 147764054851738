/* import __COLOCATED_TEMPLATE__ from './row.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface RowSignature {
  Element: HTMLTableRowElement;
  Args: {};
  Blocks: {
    default: [];
  };
}

const Row = templateOnlyComponent<RowSignature>();

export default Row;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Financing::PayLater::Cockpit::Completed::Table::Row': typeof Row;
  }
}
