import { type ReactElement } from 'react';
import type {
  DatePickerProps as AriaDatePickerProps,
  DateValue,
  ValidationResult,
} from 'react-aria-components';
import { DatePicker as AriaDatePicker, Dialog, Group, Popover, Text } from 'react-aria-components';
import { Button } from '../button';
import { DateField } from '../date-field';
import { Label } from '../form-elements';
import { Calendar } from './calendar';
import styles from './date-picker.strict-module.css';
import { IconCalendarOutlined } from './icon/icon-calendar-outlined';
import { useDatePickerValidation } from './use-validation';

interface DatePickerProps<T extends DateValue> extends AriaDatePickerProps<T> {
  description?: string;
  label?: string;
  customValidationMessages?: { [key in keyof ValidationResult['validationDetails']]?: string };
  portalContainerId?: string;
  errorMessage?: string;
  isOptional?: boolean;
}

export function DatePicker<T extends DateValue>({
  description,
  label,
  isOptional,
  customValidationMessages,
  portalContainerId,
  errorMessage,
  ...props
}: DatePickerProps<T>): ReactElement {
  // https://www.notion.so/qonto/React-popover-library-used-within-react-aria-components-does-mount-popovers-modals-outside-of-qunit--12631ee4c696806e87dbef81e9a27922
  const PORTAL_CONTAINER =
    ((portalContainerId ? document.getElementById(portalContainerId) : undefined) ||
      document.getElementById('ember-testing')) ??
    undefined;

  const { error, isInvalid, handleChange } = useDatePickerValidation({
    value: props.value,
    onChange: props.onChange,
    isRequired: props.isRequired,
    minValue: props.minValue,
    maxValue: props.maxValue,
    customValidationMessages,
    errorMessage,
  });

  return (
    <AriaDatePicker
      className={styles.wrapper}
      {...props}
      isInvalid={isInvalid}
      onChange={handleChange}
    >
      <Label data-test-date-picker-label isOptional={isOptional}>
        {label}
      </Label>
      <Group className={styles.group}>
        <DateField
          className={styles.datefield}
          data-test-date-picker-input
          errorMessage={error}
          isReadOnly={props.isReadOnly}
        />
        <Button
          className={styles.calendarIcon}
          data-test-date-picker-calendar-button
          iconOnly
          size="small"
          variant="tertiary"
        >
          <IconCalendarOutlined />
        </Button>
      </Group>
      {description ? <Text slot="description">{description}</Text> : null}
      <Popover UNSTABLE_portalContainer={PORTAL_CONTAINER}>
        <Dialog>
          <Calendar data-testid="calendar" />
        </Dialog>
      </Popover>
    </AriaDatePicker>
  );
}
