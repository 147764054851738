/* import __COLOCATED_TEMPLATE__ from './reason-details.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { TextAreaField } from '@repo/design-system-kit';

interface SubscriptionCloseStepsReasonDetailsSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class SubscriptionCloseStepsReasonDetailsComponent extends Component<SubscriptionCloseStepsReasonDetailsSignature> {
  textAreaField = TextAreaField;

  @tracked showError = false;

  @service declare intl: Services['intl'];

  get error() {
    // @ts-expect-error
    let { closingReasonDetailsOther } = this.args.context;
    return !closingReasonDetailsOther
      ? this.intl.t('subscription.account-closing.errors.explain-closing-reason')
      : null;
  }

  // @ts-expect-error
  @action updateNote(val) {
    this.showError = false;
    // @ts-expect-error
    this.args.context.closingReasonDetailsOther = val;
  }

  // @ts-expect-error
  @action onSubmit(e) {
    e.preventDefault();
    if (this.error) {
      this.showError = true;
      return;
    }
    // @ts-expect-error
    this.args.transitionToNext();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Subscription::Close::ReasonDetails': typeof SubscriptionCloseStepsReasonDetailsComponent;
  }
}
