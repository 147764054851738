export default {
  "transfer-header": "qVg",
  "infobox": "qVD header-section",
  "proof-section": "qVV",
  "proof-link": "qVL",
  "download-icon": "qVT",
  "cancel-button": "qVz",
  "request-refund-button": "qVa",
  "refund-requested-indicator": "qVH",
  "recall-indicator": "qVO",
  "payment-purpose": "qVm",
  "payment-purpose-label": "qVp",
  "repeat-infobox": "qVx qVD header-section",
  "additional-infobox": "qVw qVD header-section",
  "repeat-transfer-action": "qVN",
  "financing-not-compliant-disclaimer": "qVv"
};
