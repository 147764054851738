/* import __COLOCATED_TEMPLATE__ from './sidebar.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { DATE_FORMAT_TOKENS } from '@qonto/ui-kit/utils/date-token';
import { Disclaimer } from '@repo/design-system-kit';
import window from 'ember-window-mock';

// @ts-expect-error
import { F24_STATUS } from 'qonto/constants/f24';
import { F24SidebarHeader } from 'qonto/react/components/f24/sidebar/header';

interface F24SidebarSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class F24SidebarComponent extends Component<F24SidebarSignature> {
  disclaimerBlock = Disclaimer.Block;
  sidebarHeader = F24SidebarHeader;

  @service declare intl: Services['intl'];
  @service declare modals: Services['modals'];
  @service declare segment: Services['segment'];

  get titleTranslationKey() {
    // @ts-expect-error
    let { order } = this.args;
    let statusToTranslationKey = {
      [F24_STATUS.SCHEDULED]: this.intl.t('f24.archive.sidepanel.header.scheduled'),
      [F24_STATUS.COMPLETED]: this.intl.t('f24.archive.sidepanel.header.completed'),
      [F24_STATUS.DECLINED]: this.intl.t('f24.archive.sidepanel.header.declined'),
      [F24_STATUS.CANCELED]: this.intl.t('f24.archive.sidepanel.header.canceled'),
    };
    return statusToTranslationKey[order.status];
  }

  get headerDateToken() {
    // @ts-expect-error
    let { order } = this.args;
    let statusToDate = {
      [F24_STATUS.SCHEDULED]: { date: order.createdAt, token: DATE_FORMAT_TOKENS.DATE_TIME_L },
      [F24_STATUS.COMPLETED]: {
        date: order.expirationDate,
        token: DATE_FORMAT_TOKENS.DATE_YEAR_L,
      },
      [F24_STATUS.DECLINED]: {
        date: order.expirationDate,
        token: DATE_FORMAT_TOKENS.DATE_YEAR_L,
      },
      [F24_STATUS.CANCELED]: { date: order.canceledAt, token: DATE_FORMAT_TOKENS.DATE_TIME_L },
    };

    return statusToDate[order.status];
  }

  get isDeclinedForInsufficientFunds() {
    // @ts-expect-error
    let { order } = this.args;
    return order.status === F24_STATUS.DECLINED && order.declinedReason === 'insufficient_funds';
  }

  get shouldDisplayFooter() {
    return (
      // @ts-expect-error
      (this.args.order.status === F24_STATUS.COMPLETED && this.args.order.receiptUrl) ||
      // @ts-expect-error
      (this.args.order.status === F24_STATUS.SCHEDULED && this.args.order.isCancelable)
    );
  }

  @action cancelPayment() {
    // @ts-expect-error
    let f24Order = this.args.order;

    this.modals.open('f24/cancel-confirmation-modal', {
      f24Order,
      // @ts-expect-error
      closeSidebar: this.args.closeSidebar,
    });
  }

  @action handleDownload() {
    // @ts-expect-error
    window.open(this.args.order.receiptUrl);
    this.segment.track('f24_details_receipt-downloaded');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'F24::Sidebar': typeof F24SidebarComponent;
  }
}
