export default {
  "card": "cDa",
  "header": "cDH",
  "avatar": "cDO",
  "title": "cDm",
  "subtitle": "cDp",
  "label": "cDx",
  "item": "cDw body-2",
  "header-icon": "cDN",
  "mt-8": "cDv"
};
