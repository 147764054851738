export default {
  "details": "qtI",
  "details-header": "qtt",
  "details-content": "qtM",
  "details-footer": "qtP",
  "invoice-status-container": "qtl body-2",
  "invoice-dates": "qtX",
  "overdue": "qtC",
  "details-list": "qtk",
  "details-list-item": "qtJ",
  "details-list-item-label": "qts body-2",
  "details-list-item-value": "qtF",
  "border-top": "qtg",
  "description": "qtD",
  "description-value": "qtV",
  "edit-button": "qtL body-2",
  "edit-icon": "qtT",
  "badge-union": "qtz",
  "edit-description": "qta",
  "amount-disclaimer": "qtH",
  "strike-amount": "qtO"
};
