/* import __COLOCATED_TEMPLATE__ from './amount-currency-field.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { Currency } from 'qonto/react/components/transfers/international-out/currency';
import { groupCurrencies } from 'qonto/utils/currency';

interface Signature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class TransfersInternationalOutAmountCurrencyFieldComponent extends Component<Signature> {
  @service declare intl: Services['intl'];

  currency = Currency;

  get currencies() {
    // @ts-expect-error
    let allCurrencies = this.args.currencies;
    let [popularCurrencies, otherCurrencies] = groupCurrencies(allCurrencies);

    // @ts-expect-error
    return popularCurrencies.length
      ? [
          {
            groupName: this.intl.t('international-out.quote.currencies.popular-label'),
            options: popularCurrencies,
          },
          {
            groupName: this.intl.t('international-out.quote.currencies.other-label'),
            options: otherCurrencies,
          },
        ]
      : allCurrencies;
  }

  get hasMultipleCurrencies() {
    // @ts-expect-error
    return this.args.currencies.length > 1;
  }

  get selectedCurrency() {
    // @ts-expect-error
    return this.args.currencies.find(({ code }) => code === this.args.currency);
  }

  @action
  // @ts-expect-error
  onCurrencyUpdate({ code }) {
    // @ts-expect-error
    this.args.onCurrencyUpdate(code);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transfers::InternationalOut::AmountCurrencyField': typeof TransfersInternationalOutAmountCurrencyFieldComponent;
  }
}
