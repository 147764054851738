/* import __COLOCATED_TEMPLATE__ from './x-card.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface XCardSignature {
  Element: HTMLSpanElement;
  Args: {};
  Blocks: {
    default: [];
  };
}

const XCard = templateOnlyComponent<XCardSignature>();

export default XCard;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Badge::XCard': typeof XCard;
  }
}
