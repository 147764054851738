import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { get } from '@ember/object';
import { dependentKeyCompat } from '@ember/object/compat';
import { service, type Registry as Services } from '@ember/service';
import { waitFor } from '@ember/test-waiters';

// @ts-expect-error
import { apiAction } from '@mainmatter/ember-api-actions';

// @ts-expect-error
import titleize from 'qonto/utils/titleize';
// @ts-expect-error
import InviteValidations from 'qonto/validations/invite';

// @ts-expect-error
export default class InviteModel extends Model.extend(InviteValidations) {
  @service declare intl: Services['intl']; //this service is used in the validator
  @service declare organizationManager: Services['organizationManager'];

  @attr('boolean', { defaultValue: true }) declare documentsToUpload: boolean;

  // read only
  // read only
  @attr('string') declare cbs: string;
  @attr('string') declare organizationName: string;
  // @ts-expect-error
  @attr() organizationLegalCountry;
  // @ts-expect-error
  @attr() organizationAvatarThumb;
  // @ts-expect-error
  @attr() organizationAvatar;
  // @ts-expect-error
  @attr() organizationDefaultAvatarThumb;
  // @ts-expect-error
  @attr() organizationDefaultAvatar;
  // @ts-expect-error
  @attr() suggested;
  @attr('string') declare recipientId: string;
  @attr('date') declare birthdate: Date;
  @attr('string') declare loginToken: string;
  @attr('string') declare italianFiscalCode: string;

  // @ts-expect-error
  @belongsTo('team', { async: true, inverse: null }) team;
  // @ts-expect-error
  @belongsTo('address', { async: false, inverse: null }) address;
  // @ts-expect-error
  @belongsTo('organization', { async: false, inverse: null }) organization;
  // @ts-expect-error
  @hasMany('document', { async: false, inverse: 'invite' }) documents;

  @attr('string') declare firstName: string;
  @attr('string') declare lastName: string;
  @attr('string') declare email: string;
  @attr('string') declare phoneNumber: string;

  /**
   * Spend limits params.
   * @type object {value: string, currency: string}
   */
  // @ts-expect-error
  @attr currentMonthSpendings;
  // @ts-expect-error
  @attr monthlyTransferLimit;
  // @ts-expect-error
  @attr perTransferLimit;

  @attr('string') declare nationality: string;
  @attr('string') declare role: string;

  // @ts-expect-error
  @attr('file') avatar;
  // @ts-expect-error
  @attr('file') avatarThumb;
  // @ts-expect-error
  @attr('file') defaultAvatar;
  // @ts-expect-error
  @attr('file') defaultAvatarThumb;

  @attr('boolean') declare kycRequired: boolean;

  // @ts-expect-error
  @attr() customPermissions;

  // @ts-expect-error
  @attr() allowedBankAccountsIds;

  get fullName() {
    let firstName = this.firstName?.trim();
    let lastName = this.lastName?.trim();
    if (firstName && lastName) {
      return titleize(`${firstName} ${lastName}`);
    }
  }

  @waitFor
  async accept() {
    let data = { invite: this.serialize() };
    return await apiAction(this, { method: 'PUT', path: 'accept', data });
  }

  @dependentKeyCompat
  get proofOfIdentity() {
    // @ts-expect-error
    return this.documents.find(item => get(item, 'docType') === 'poi');
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    invite: InviteModel;
  }
}
