export default {
  "card-details-container": "cjb",
  "title": "cjy caption-bold mb-16",
  "section": "cjU",
  "divider": "cjj",
  "top-divider": "cjW",
  "content": "cju body-2",
  "description": "cWS",
  "justify-end": "cWc",
  "icon": "cWq ml-8"
};
