export default {
  "modal": "ciO",
  "action-container": "cim",
  "similar-label": "cip",
  "info-icon": "cix",
  "container": "ciw",
  "subtitle": "ciN caption-bold",
  "error": "civ",
  "item-container": "cio"
};
