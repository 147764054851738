/* import __COLOCATED_TEMPLATE__ from './form-table.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface FormTableSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const FormTable = templateOnlyComponent<FormTableSignature>();

export default FormTable;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'F24::FormTable': typeof FormTable;
  }
}
