export default {
  "header": "if",
  "header-top": "iK",
  "header-inner": "iG",
  "disclaimer": "ir mb-16",
  "header-main-wrapper": "ib",
  "header-main": "iy",
  "logo": "iU mr-16",
  "header-right": "ij",
  "partner-description": "iW body-2"
};
