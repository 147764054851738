/* import __COLOCATED_TEMPLATE__ from './validity-period.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { DATE_FORMAT_TOKENS, dateToken } from '@qonto/ui-kit/utils/date-token';

interface CardSidebarValidityPeriodSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class CardSidebarValidityPeriod extends Component<CardSidebarValidityPeriodSignature> {
  @service declare localeManager: Services['localeManager'];

  get expirationDate() {
    // @ts-expect-error
    let { preExpiresAt } = this.args.card;

    if (!preExpiresAt) {
      return null;
    }

    return dateToken({
      date: preExpiresAt,
      token: DATE_FORMAT_TOKENS.DATE_TIME_S,
      // @ts-expect-error
      locale: this.localeManager.locale,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'CardSidebar::ValidityPeriod': typeof CardSidebarValidityPeriod;
  }
}
