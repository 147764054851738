export default {
  "row": "OV body-1",
  "active": "OL",
  "cell": "OT body-2",
  "empty": "Oz",
  "no-padding": "Oa",
  "cell-content": "OH",
  "debitor": "OO",
  "avatar": "Om",
  "debitor-details": "Op",
  "debitor-name": "Ox",
  "reference": "Ow",
  "amount": "ON",
  "row--sidebar-open": "Ov",
  "status": "Oo"
};
