/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

// @ts-expect-error
import { variation } from 'ember-launch-darkly';

import { DEFAULT_SORT_ORDER } from 'qonto/constants/sort';
import { SORT_TABS_MAP, TABS } from 'qonto/constants/supplier-invoice';

interface SupplierInvoicesTableHeaderSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLTableRowElement;
}

export default class SupplierInvoicesTableHeaderComponent extends Component<SupplierInvoicesTableHeaderSignature> {
  @service declare intl: Services['intl'];
  @service declare abilities: Services['abilities'];

  get showInvoiceSelector() {
    return (
      (this.abilities.can('update supplier-invoice') &&
        // @ts-expect-error
        [TABS.ALL_INVOICES, TABS.INBOX, TABS.TO_PAY].includes(this.args.tab)) ||
      // @ts-expect-error
      ![TABS.TASKS, TABS.SCHEDULED, TABS.TO_APPROVE, TABS.COMPLETED].includes(this.args.tab)
    );
  }

  get columnTypes() {
    return {
      [TABS.TASKS]: [
        {
          name: 'supplier_name',
          label: this.intl.t('supplier-invoices.table.header.supplier-name'),
          sortable: true,
          width: this.abilities.can('load transfer request') ? '32%' : '44%',
        },
        ...(this.abilities.can('load transfer request')
          ? [
              {
                name: 'requester',
                label: this.intl.t('request.table.header.requester'),
                sortable: true,
                width: '24%',
              },
            ]
          : []),
        {
          name: 'due_date',
          label: this.intl.t('supplier-invoices.table.header.due-date'),
          sortable: true,
          width: this.abilities.can('load transfer request') ? '24%' : '36%',
        },
        {
          name: 'total_amount',
          label: this.intl.t('supplier-invoices.table.header.amount'),
          sortable: true,
          width: '10%',
          align: 'right',
        },
        {
          name: 'quick_actions',
          ariaHidden: 'true',
        },
      ],
      [TABS.ALL_INVOICES]: [
        { type: this.abilities.can('update supplier-invoice') ? 'checkbox' : null },
        {
          name: 'supplier_name',
          label: this.intl.t('supplier-invoices.table.header.supplier-name'),
          sortable: true,
          width: '25%',
        },
        {
          name: 'status',
          label: this.intl.t('supplier-invoices.table.header.status'),
          sortable: true,
          width: '15%',
        },
        {
          name: 'due_date',
          label: this.intl.t('supplier-invoices.table.header.due-date'),
          sortable: true,
          width: '15%',
        },
        {
          name: 'payment_date',
          label: this.intl.t('supplier-invoices.table.header.payment-date'),
          sortable: true,
          width: '15%',
        },
        {
          name: 'matched_transactions',
          label: this.intl.t('supplier-invoices.table.header.transactions'),
          sortable: true,
          width: '15%',
        },
        {
          name: 'total_amount',
          label: this.intl.t('supplier-invoices.table.header.amount'),
          sortable: true,
          width: '15%',
          align: 'right',
        },
        {
          name: 'quick_actions',
          ariaHidden: 'true',
        },
      ],
      [TABS.INBOX]: [
        { type: this.abilities.can('update supplier-invoice') ? 'checkbox' : null },
        {
          name: 'supplier_name',
          label: this.intl.t('supplier-invoices.table.header.supplier-name'),
          sortable: true,
          width: '44%',
        },
        {
          name: 'due_date',
          label: this.intl.t('supplier-invoices.table.header.due-date'),
          sortable: true,
          width: '46%',
        },
        {
          name: 'total_amount',
          label: this.intl.t('supplier-invoices.table.header.amount'),
          sortable: true,
          width: '10%',
          align: 'right',
        },
        {
          name: 'quick_actions',
          ariaHidden: 'true',
        },
      ],
      [TABS.TO_PAY]: [
        { type: this.abilities.can('update supplier-invoice') ? 'checkbox' : null },
        {
          name: 'supplier_name',
          label: this.intl.t('supplier-invoices.table.header.supplier-name'),
          sortable: true,
          width: this.ffBasedColumnWidth({
            defaultWidth: '44%',
            approvalWorkflowFFWidth: '20%',
            improveFirstTimeExpFFWidth: '25%',
          }),
        },
        ...(variation('feature--boolean-improve-first-time-exp-si')
          ? [
              {
                name: 'imported_via',
                label: this.intl.t('supplier-invoices.table.header.imported-via'),
                sortable: false,
                width: variation('feature--boolean-approval-workflow-for-supplier-invoices')
                  ? '15%'
                  : '25%',
              },
            ]
          : []),
        ...(variation('feature--boolean-approval-workflow-for-supplier-invoices')
          ? [
              {
                name: 'previous_step',
                label: this.intl.t('supplier-invoices.table.header.previous-step'),
                sortable: false,
                width: '20%',
              },
              {
                name: 'to_be_paid_by',
                label: this.intl.t('supplier-invoices.table.header.to-be-paid'),
                sortable: false,
                width: '20%',
              },
            ]
          : []),
        {
          name: 'due_date',
          label: this.intl.t('supplier-invoices.table.header.due-date'),
          sortable: true,
          width: this.ffBasedColumnWidth({
            defaultWidth: '46%',
            approvalWorkflowFFWidth: '15%',
            improveFirstTimeExpFFWidth: '25%',
          }),
        },
        {
          name: 'total_amount',
          label: this.intl.t('supplier-invoices.table.header.amount'),
          sortable: true,
          width: this.ffBasedColumnWidth({
            defaultWidth: '10%',
            approvalWorkflowFFWidth: '20%',
            improveFirstTimeExpFFWidth: '25%',
          }),
          align: 'right',
        },
        {
          name: 'quick_actions',
          ariaHidden: 'true',
        },
      ],
      [TABS.TO_APPROVE]: [
        {
          name: 'supplier_name',
          label: this.intl.t('supplier-invoices.table.header.supplier-name'),
          sortable: true,
          width: variation('feature--boolean-approval-workflow-for-supplier-invoices')
            ? '25%'
            : '44%',
        },
        ...(variation('feature--boolean-approval-workflow-for-supplier-invoices')
          ? [
              {
                name: 'previous_step',
                label: this.intl.t('supplier-invoices.table.header.previous-step'),
                sortable: false,
                width: '20%',
              },
              {
                name: 'to_be_approved_by',
                label: this.intl.t('supplier-invoices.table.header.to-be-approved'),
                sortable: false,
                width: '20%',
              },
            ]
          : []),
        {
          name: 'due_date',
          label: this.intl.t('supplier-invoices.table.header.due-date'),
          sortable: true,
          width: variation('feature--boolean-approval-workflow-for-supplier-invoices')
            ? '20%'
            : '46%',
        },
        {
          name: 'total_amount',
          label: this.intl.t('supplier-invoices.table.header.amount'),
          sortable: true,
          width: variation('feature--boolean-approval-workflow-for-supplier-invoices')
            ? '15%'
            : '10%',
          align: 'right',
        },
        {
          name: 'quick_actions',
          ariaHidden: 'true',
        },
      ],
      [TABS.SCHEDULED]: [
        {
          name: 'supplier_name',
          label: this.intl.t('supplier-invoices.table.header.supplier-name'),
          sortable: true,
          width: '30%',
        },
        {
          name: 'due_date',
          label: this.intl.t('supplier-invoices.table.header.due-date'),
          sortable: true,
          width: '23%',
        },
        {
          name: 'payment_date',
          label: this.intl.t('supplier-invoices.table.header.payment-date'),
          sortable: true,
          width: '37%',
        },
        {
          name: 'total_amount',
          label: this.intl.t('supplier-invoices.table.header.amount'),
          sortable: true,
          width: '10%',
          align: 'right',
        },
        {
          name: 'quick_actions',
          ariaHidden: 'true',
        },
      ],
      [TABS.COMPLETED]: [
        {
          name: 'supplier_name',
          label: this.intl.t('supplier-invoices.table.header.supplier-name'),
          sortable: true,
          width: '25%',
        },
        {
          name: 'status',
          label: this.intl.t('supplier-invoices.table.header.status'),
          sortable: true,
          width: '15%',
        },
        {
          name: 'payment_date',
          label: this.intl.t('supplier-invoices.table.header.payment-date'),
          sortable: true,
          width: '25%',
        },
        {
          name: 'matched_transactions',
          label: this.intl.t('supplier-invoices.table.header.transactions'),
          sortable: true,
          width: '20%',
        },
        {
          name: 'total_amount',
          label: this.intl.t('supplier-invoices.table.header.amount'),
          sortable: true,
          width: '15%',
          align: 'right',
        },
        {
          name: 'quick_actions',
          ariaHidden: 'true',
        },
      ],
    };
  }

  ffBasedColumnWidth({
    defaultWidth,
    approvalWorkflowFFWidth,
    improveFirstTimeExpFFWidth,
  }: {
    defaultWidth: string;
    approvalWorkflowFFWidth: string;
    improveFirstTimeExpFFWidth: string;
  }) {
    if (variation('feature--boolean-improve-first-time-exp-si')) {
      return improveFirstTimeExpFFWidth;
    }

    if (variation('feature--boolean-approval-workflow-for-supplier-invoices')) {
      return approvalWorkflowFFWidth;
    }

    return defaultWidth;
  }

  get columns() {
    // @ts-expect-error
    return this.columnTypes[this.args.tab];
  }

  get sortField() {
    // @ts-expect-error
    return this.args.sortBy?.split(':')[0];
  }

  get sortOrder() {
    // @ts-expect-error
    return this.args.sortBy?.split(':')[1] || DEFAULT_SORT_ORDER;
  }

  @action
  // @ts-expect-error
  changeSortBy(name) {
    let sortOrder = DEFAULT_SORT_ORDER;

    if (name === this.sortField) {
      switch (this.sortOrder) {
        case 'asc': {
          // @ts-expect-error
          sortOrder = 'desc';
          break;
        }
        case 'desc': {
          // @ts-expect-error
          return this.args.changeSortBy?.(
            // @ts-expect-error
            SORT_TABS_MAP(variation('feature--boolean-approval-workflow-for-supplier-invoices'))[
              // @ts-expect-error
              this.args.tab
            ]
          );
        }
        default: {
          sortOrder = 'asc';
        }
      }
    }

    // @ts-expect-error
    return this.args.changeSortBy?.(`${name}:${sortOrder}`);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'SupplierInvoices::Table::Header': typeof SupplierInvoicesTableHeaderComponent;
  }
}
