/* import __COLOCATED_TEMPLATE__ from './creation-form.hbs'; */
// @ts-nocheck
// @ts-expect-error
import { recordIdentifierFor } from '@ember-data/store';
import { assert } from '@ember/debug';
import { action } from '@ember/object';
import { next } from '@ember/runloop';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { AmountField, Disclaimer } from '@repo/design-system-kit';
import dayjs from 'dayjs';
import { dropTask, waitForProperty } from 'ember-concurrency';

// @ts-expect-error
import { PERIOD_VALUE_LIMIT } from 'qonto/constants/budget';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';

interface CreationFormSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLFormElement;
}

export default class CreationFormComponent extends Component<CreationFormSignature> {
  amountField = AmountField;
  disclaimerBlock = Disclaimer.Block;

  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare intl: Services['intl'];
  @service declare sentry: Services['sentry'];
  @service declare segment: Services['segment'];

  @tracked submitButtonIsDisabled = false;
  @tracked showErrors = false;

  constructor(owner: unknown, args: CreationFormSignature['Args']) {
    // @ts-expect-error
    super(owner, args);
    // @ts-expect-error
    assert('You must pass an @submitTask', this.args.submitTask);
  }

  get globalAmountFieldErrorMessage() {
    // @ts-expect-error
    if (this.args.exercise.isGlobal && this.showErrors) {
      return this.intl.t('team-budgets.creation.customize.error.global');
    }
  }

  // @ts-expect-error
  lid(model) {
    return recordIdentifierFor(model).lid;
  }

  // @ts-expect-error
  @action shouldDisplayYear(period) {
    return (
      // @ts-expect-error
      // @ts-expect-error
      !this.args.exercise.isGlobal &&
      (this.args.exercise.periods.indexOf(period) === 0 || dayjs(period.startDate).month() === 0)
    );
  }

  // @ts-expect-error
  @action displayYear(date) {
    return this.intl.formatDate(date, { year: 'numeric' });
  }

  @action toggleExerciseKind() {
    this.showErrors = false;
    this.submitButtonIsDisabled = false;
    // @ts-expect-error
    this.args.exercise.toggleExerciseKind();

    this.segment.track('budget-creation_overall-budget_toggled', {
      // @ts-expect-error
      state: this.args.exercise.isGlobal,
    });
  }

  // @ts-expect-error
  @action updateAmount(period, value) {
    this.submitButtonIsDisabled = false;
    if (value > PERIOD_VALUE_LIMIT) {
      value = PERIOD_VALUE_LIMIT.toString();
    }

    next(() => {
      period.amountBudgetedValue = value;
    });
  }

  submitTask = dropTask(async () => {
    if (!this.submitButtonIsDisabled) {
      try {
        // @ts-expect-error
        await this.args.exercise.validate();
        // @ts-expect-error
        if (this.args.exercise.validations.isValid) {
          // @ts-expect-error
          await this.args.submitTask.perform();
        } else {
          // @ts-expect-error
          await this.handleError();
        }
      } catch (error) {
        await this.handleError(error);
      } finally {
        // @ts-expect-error
        this.showErrors = !this.args.exercise.validations.attrs.totalBudgeted.isValid;
      }
    }
  });

  // @ts-expect-error
  async handleError(error) {
    this.submitButtonIsDisabled = true;
    // any error that doesn't result in totatlBugeted validation failure isn't displayed in form so lets display a toast error.
    // @ts-expect-error
    await waitForProperty(this.args.exercise.validations, 'isValidating', false);
    // @ts-expect-error
    if (this.args.exercise.validations.attrs.totalBudgeted.isValid) {
      this.toastFlashMessages.toastError(this.intl.t('errors.unexpected_error_occured'));
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Budget::CreationForm': typeof CreationFormComponent;
  }
}
