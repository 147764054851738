export default {
  "dual-nav": "pw",
  "header": "pN",
  "expanded": "pv",
  "collapsed": "po",
  "responsive": "pn",
  "overlay": "pi",
  "visible": "pA",
  "footer": "pY"
};
