/* import __COLOCATED_TEMPLATE__ from './discover-multi-accounts.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface DiscoverMultiAccountsSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const DiscoverMultiAccounts = templateOnlyComponent<DiscoverMultiAccountsSignature>();

export default DiscoverMultiAccounts;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::SubscriptionChange::DiscoverMultiAccounts': typeof DiscoverMultiAccounts;
  }
}
