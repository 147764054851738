import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { service, type Registry as Services } from '@ember/service';
import { tracked } from '@glimmer/tracking';

// @ts-expect-error
import { ACTIVITY_TAGS } from 'qonto/constants/categories';
import {
  DIRECT_DEBIT_SUBSCRIPTION_SCHEDULE_TYPES,
  DIRECT_DEBIT_SUBSCRIPTION_STATUS,
  DIRECT_DEBIT_SUBSCRIPTION_STATUS_ICONS,
  DIRECT_DEBIT_SUBSCRIPTION_STATUS_LEVELS,
  FREQUENCY_CUSTOM_UNITS,
  // @ts-expect-error
} from 'qonto/constants/direct-debit-subscriptions';
// @ts-expect-error
import DirectDebitSubscriptionValidations from 'qonto/validations/direct-debit-subscription';

// @ts-expect-error
export const getLabelForFrequencyCustomUnit = (value, intl) => {
  switch (value) {
    case FREQUENCY_CUSTOM_UNITS.WEEKS: {
      return intl.t('sdd-collections.recurrence.weeks');
    }
    case FREQUENCY_CUSTOM_UNITS.MONTHS: {
      return intl.t('sdd-collections.recurrence.months');
    }
    case FREQUENCY_CUSTOM_UNITS.YEARS: {
      return intl.t('sdd-collections.recurrence.years');
    }
  }
};

// @ts-expect-error
export const getScheduleTypeLabel = (value, intl) => {
  switch (value) {
    case DIRECT_DEBIT_SUBSCRIPTION_SCHEDULE_TYPES.ONE_OFF: {
      return intl.t('sdd-collections.recurrence.once');
    }
    case DIRECT_DEBIT_SUBSCRIPTION_SCHEDULE_TYPES.WEEKLY: {
      return intl.t('sdd-collections.recurrence.weekly');
    }
    case DIRECT_DEBIT_SUBSCRIPTION_SCHEDULE_TYPES.MONTHLY: {
      return intl.t('sdd-collections.recurrence.monthly');
    }
    case DIRECT_DEBIT_SUBSCRIPTION_SCHEDULE_TYPES.QUARTERLY: {
      return intl.t('sdd-collections.recurrence.quarterly');
    }
    case DIRECT_DEBIT_SUBSCRIPTION_SCHEDULE_TYPES.YEARLY: {
      return intl.t('sdd-collections.recurrence.yearly');
    }
    case DIRECT_DEBIT_SUBSCRIPTION_SCHEDULE_TYPES.CUSTOM: {
      return intl.t('sdd-collections.recurrence.custom');
    }
  }
};

// @ts-expect-error
export const getStatusLabel = (value, intl) => {
  let statusMap = {
    [DIRECT_DEBIT_SUBSCRIPTION_STATUS.PENDING_MANDATE_SIGNATURE]: 'pending-mandate',
    [DIRECT_DEBIT_SUBSCRIPTION_STATUS.SCHEDULED]: 'scheduled',
    [DIRECT_DEBIT_SUBSCRIPTION_STATUS.IN_PROGRESS]: 'in-progress',
  };

  if (!statusMap[value]) {
    return '';
  }

  return intl.t(`sdd-collections.status.${statusMap[value]}`);
};

// @ts-expect-error
export default class DirectDebitSubscriptionModel extends Model.extend(
  DirectDebitSubscriptionValidations
) {
  @service declare intl: Services['intl'];

  @tracked isCustomDuration = false;

  // @ts-expect-error
  @attr() amount;
  @attr('string') declare initialCollectionDate: string;
  @attr('string') declare nextCollectionDate: string;
  @attr('string') declare endDate: string;
  @attr('string') declare reference: string;
  @attr('string') declare debitorName: string;
  @attr('string', { defaultValue: DIRECT_DEBIT_SUBSCRIPTION_SCHEDULE_TYPES.MONTHLY })
  declare scheduleType: string;
  @attr('string') declare status: string;
  @attr('string') declare slug: string;
  @attr('number') declare scheduleCustomInterval: number;
  @attr('string', { defaultValue: FREQUENCY_CUSTOM_UNITS.MONTHS })
  declare scheduleCustomUnit: string;
  @attr('number') declare scheduleTotalCollectionCount: number;
  @attr('boolean', { defaultValue: true }) declare notifyClient: boolean;
  @attr('string', { defaultValue: ACTIVITY_TAGS.OTHER_INCOME }) declare activityTag: string;

  // @ts-expect-error
  @belongsTo('bankAccount', { async: false, inverse: null }) bankAccount;
  // @ts-expect-error
  @belongsTo('direct-debit-collection-mandate', { async: false, inverse: null }) mandate;
  // @ts-expect-error
  @belongsTo('customer', { async: false, inverse: null }) client;

  // @ts-expect-error
  @hasMany('attachment', { async: true, inverse: null }) attachments;

  get isOneOff() {
    return this.scheduleType === DIRECT_DEBIT_SUBSCRIPTION_SCHEDULE_TYPES.ONE_OFF;
  }

  get isRecurring() {
    return !this.isOneOff;
  }

  get isPendingMandateSignature() {
    return this.status === DIRECT_DEBIT_SUBSCRIPTION_STATUS.PENDING_MANDATE_SIGNATURE;
  }

  get isScheduled() {
    return this.status === DIRECT_DEBIT_SUBSCRIPTION_STATUS.SCHEDULED;
  }

  get isInProgress() {
    return this.status === DIRECT_DEBIT_SUBSCRIPTION_STATUS.IN_PROGRESS;
  }

  get scheduleCustomUnitLabel() {
    return getLabelForFrequencyCustomUnit(this.scheduleCustomUnit, this.intl);
  }

  get scheduleTypeLabel() {
    if (this.scheduleType === DIRECT_DEBIT_SUBSCRIPTION_SCHEDULE_TYPES.CUSTOM) {
      if (this.scheduleCustomInterval > 1) {
        return this.intl.t('sdd-collections.recurrence.every-custom', {
          number: this.scheduleCustomInterval,
          recurrence: getLabelForFrequencyCustomUnit(this.scheduleCustomUnit, this.intl),
        });
      } else if (this.scheduleCustomInterval === 1) {
        switch (this.scheduleCustomUnit) {
          case FREQUENCY_CUSTOM_UNITS.WEEKS: {
            return this.intl.t('sdd-collections.recurrence.weekly');
          }
          case FREQUENCY_CUSTOM_UNITS.MONTHS: {
            return this.intl.t('sdd-collections.recurrence.monthly');
          }
          case FREQUENCY_CUSTOM_UNITS.YEARS: {
            return this.intl.t('sdd-collections.recurrence.yearly');
          }
        }
      }
    }

    return getScheduleTypeLabel(this.scheduleType, this.intl);
  }

  get typeCopy() {
    if (this.isOneOff) {
      return this.intl.t('sdd-collections.types.one-off');
    } else if (this.isRecurring) {
      return this.intl.t('sdd-collections.types.recurring');
    }
  }

  get attachment() {
    return this.attachments.content[0];
  }

  get savedAttachments() {
    // @ts-expect-error
    return this.attachments.filter(attachment => !attachment.isNew);
  }

  get attachmentsFiles() {
    // @ts-expect-error
    return this.attachments.map(it => it.file).filter(Boolean);
  }

  get statusDisplayInfo() {
    return {
      title: getStatusLabel(this.status, this.intl),
      icon: DIRECT_DEBIT_SUBSCRIPTION_STATUS_ICONS[this.status],
      level: DIRECT_DEBIT_SUBSCRIPTION_STATUS_LEVELS[this.status],
    };
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'direct-debit-subscription': DirectDebitSubscriptionModel;
  }
}
