/* import __COLOCATED_TEMPLATE__ from './success-new-digital.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { CARD_LEVELS, CARD_LEVELS_TRACKING } from 'qonto/constants/cards';

interface FlowsCardsSuccessNewDigitalSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsCardsSuccessNewDigital extends Component<FlowsCardsSuccessNewDigitalSignature> {
  @service declare intl: Services['intl'];
  @service declare segment: Services['segment'];

  constructor(owner: unknown, args: FlowsCardsSuccessNewDigitalSignature['Args']) {
    super(owner, args);
    // @ts-expect-error
    let { flowTrackingOrigin, isUserCardHolder } = this.args.context;

    this.segment.track('cards_order_completed', {
      card_holder: isUserCardHolder ? 'self' : 'team_member',
      card_type: this.trackingCardLevel,
      origin: flowTrackingOrigin,
    });
  }

  get title() {
    let {
      card: { cardLevel },
      isUserCardHolder,
      // @ts-expect-error
    } = this.args.context;

    switch (cardLevel) {
      case CARD_LEVELS.ADVERTISING:
        return isUserCardHolder
          ? this.intl.t('cards.steps.success.title.advertising.holder')
          : this.intl.t('cards.steps.success.title.advertising.non-holder');
      case CARD_LEVELS.FLASH:
        return isUserCardHolder
          ? this.intl.t('cards.steps.success.title.flash.holder')
          : this.intl.t('cards.steps.success.title.flash.non-holder');
      case CARD_LEVELS.VIRTUAL:
        return isUserCardHolder
          ? this.intl.t('cards.steps.success.title.virtual.holder')
          : this.intl.t('cards.steps.success.title.virtual.non-holder');
      default:
        return null;
    }
  }

  get subtitle() {
    // @ts-expect-error
    let { card, isUserCardHolder } = this.args.context;

    switch (card.cardLevel) {
      case CARD_LEVELS.ADVERTISING:
        return isUserCardHolder
          ? this.intl.t('cards.steps.success.subtitle.advertising.holder')
          : this.intl.t('cards.steps.success.subtitle.advertising.non-holder', {
              cardHolderName: card.holder.get('fullName'),
            });
      case CARD_LEVELS.FLASH:
        return isUserCardHolder
          ? this.intl.t('cards.steps.success.subtitle.flash.holder', { htmlSafe: true })
          : this.intl.t('cards.steps.success.subtitle.flash.non-holder', {
              cardholder_name: card.holder.get('fullName'),
            });
      case CARD_LEVELS.VIRTUAL:
        return this.intl.t('cards.steps.success.subtitle.virtual');
      default:
        return null;
    }
  }

  get mainCtaText() {
    // @ts-expect-error
    let { card, isUserCardHolder } = this.args.context;

    switch (card.cardLevel) {
      case CARD_LEVELS.ADVERTISING:
        return isUserCardHolder
          ? this.intl.t('cards.steps.success.cta.advertising.holder')
          : this.intl.t('cards.steps.success.cta.non-holder');
      case CARD_LEVELS.FLASH:
        return this.intl.t('cards.steps.success.cta.non-holder');
      case CARD_LEVELS.VIRTUAL:
        return isUserCardHolder
          ? this.intl.t('cards.steps.success.cta.holder')
          : this.intl.t('cards.steps.success.cta.non-holder');
      default:
        return null;
    }
  }

  get trackingCardLevel() {
    // @ts-expect-error
    let { cardLevel } = this.args.context.card;
    return CARD_LEVELS_TRACKING[cardLevel];
  }

  @action
  handleNext() {
    // @ts-expect-error
    let { flowTrackingOrigin } = this.args.context;
    this.segment.track('cards_order_success_complete', {
      card_type: this.trackingCardLevel,
      origin: flowTrackingOrigin,
    });
    // @ts-expect-error
    this.args.transitionToNext();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Cards::SuccessNewDigital': typeof FlowsCardsSuccessNewDigital;
  }
}
