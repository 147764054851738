/* import __COLOCATED_TEMPLATE__ from './holder.hbs'; */
/* eslint-disable @qonto/no-async-action, @qonto/no-import-roles-constants */
import { action, set } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { LottiePlayer, SearchFieldWithDebounce } from '@repo/design-system-kit';
import { restartableTask, task } from 'ember-concurrency';

import { MEMBER_STATUS, ROLES } from 'qonto/constants/membership';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';

const USER_ROLES = [ROLES.OWNER, ROLES.ADMIN, ROLES.MANAGER, ROLES.EMPLOYEE];
const USER_STATUS = [MEMBER_STATUS.ACTIVE, MEMBER_STATUS.INVITED];

interface FlowsCardsCardHolderSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class FlowsCardsCardHolder extends Component<FlowsCardsCardHolderSignature> {
  lottiePlayer = LottiePlayer;
  searchField = SearchFieldWithDebounce;

  @service declare cardsManager: Services['cardsManager'];
  @service declare errors: Services['errors'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare intl: Services['intl'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare segment: Services['segment'];
  @service declare sentry: Services['sentry'];
  @service declare store: Services['store'];
  @service declare abilities: Services['abilities'];

  // @ts-expect-error
  @tracked members;
  @tracked membersCount = null;
  @tracked memberWithCardLimit = null;
  @tracked searchQuery = null;

  constructor(owner: unknown, args: FlowsCardsCardHolderSignature['Args']) {
    super(owner, args);
    // Disabling the rule since the following task is already handling errors
    // eslint-disable-next-line ember-concurrency/no-perform-without-catch
    this.loadMembersTask.perform();
  }

  get currentMembershipId() {
    return this.organizationManager.membership.id;
  }

  get localizedRole() {
    return {
      [ROLES.OWNER]: this.intl.t('roles.owner'),
      [ROLES.ADMIN]: this.intl.t('roles.admin'),
      [ROLES.MANAGER]: this.intl.t('roles.manager'),
      [ROLES.EMPLOYEE]: this.intl.t('roles.employee'),
    };
  }

  loadMembersTask = task(async () => {
    // @ts-expect-error
    this.members = await this.findMembers();
    this.membersCount = this.members?.length;
  });

  searchUserTask = restartableTask(async searchQuery => {
    this.searchQuery = searchQuery;
    this.memberWithCardLimit = null;
    this.members = await this.findMembers(searchQuery);
  });

  @action
  // @ts-expect-error
  async handleNext(member) {
    // @ts-expect-error
    let { card } = this.args.context;

    let isSameHolderReselected = member.id === card.holder?.id;

    this.memberWithCardLimit = null;
    // @ts-expect-error
    set(this.args.context, 'card.holder', member);

    if (member.physicalCardsLimitExceeded && card.isPhysical) {
      this.memberWithCardLimit = member;
      // @ts-expect-error
      set(this.args.context, 'card.holder', null);
    } else {
      let isUserCardHolder = member.id === this.currentMembershipId;
      // @ts-expect-error
      set(this.args.context, 'isUserCardHolder', isUserCardHolder);

      // Options must be reset if user goes back in flow and re-selects another holder
      if (card.isPhysical && !isSameHolderReselected) {
        set(card, 'atmOption', Boolean(isUserCardHolder));
        set(card, 'nfcOption', Boolean(isUserCardHolder));
        set(card, 'foreignOption', true);
        set(card, 'onlineOption', true);
      }

      if (this.abilities.can('read budget')) {
        try {
          // @ts-expect-error
          let results = await this.store.adapterFor('budget').search({
            initiatorId: member.id,
            scheduledDate: new Date(),
          });
          if (results.length) {
            // @ts-expect-error
            this.args.context.budgetName = results[0].budget.name;
          }
        } catch (error) {
          if (ErrorInfo.for(error).shouldSendToSentry) {
            this.sentry.captureException(error);
          }
        }
      }

      try {
        // @ts-expect-error
        let { flowTrackingOrigin } = this.args.context;
        await this.fetchNewLimits();

        this.segment.track('cards_order_cardholder_selected', {
          cardHolder: isUserCardHolder ? 'self' : 'team_member',
          origin: flowTrackingOrigin,
        });

        // @ts-expect-error
        this.args.transitionToNext();
      } catch (error) {
        let errorInfo = ErrorInfo.for(error);
        if (errorInfo.shouldSendToSentry) {
          this.sentry.captureException(error);
        }
        // @ts-expect-error
        if (this.errors.shouldFlash(error)) {
          // @ts-expect-error
          this.toastFlashMessages.toastError(this.errors.messageForStatus(error));
        }
      }
    }
  }

  async fetchNewLimits() {
    // @ts-expect-error
    let { card } = this.args.context;
    if (!card.isPhysical && !card.isAdvertising) return;

    let cardsLimits = await this.cardsManager.fetchCardsMaxLimitsTask.perform(
      card.holder.get('id')
    );
    // @ts-expect-error
    set(this.args.context, 'cardsLimits', cardsLimits);

    // @ts-expect-error
    let { payment_monthly_limit_maximum } = this.args.context.cardsLimits[card.cardLevel];
    card.set('paymentMonthlyLimit', payment_monthly_limit_maximum);

    if (card.isPhysical) {
      // @ts-expect-error
      let { atm_monthly_limit_maximum } = this.args.context.cardsLimits[card.cardLevel];
      card.set('atmMonthlyLimit', atm_monthly_limit_maximum);
    }
  }

  // @ts-expect-error
  async findMembers(query) {
    let filters = {};
    // @ts-expect-error
    filters['roles'] = USER_ROLES;
    // @ts-expect-error
    filters['status'] = USER_STATUS;
    let members;
    let sortedMembers;

    try {
      members = await this.store.query('membership', {
        // @ts-expect-error
        organization_id: this.args.context.card.get('organization.id'),
        query,
        filters,
        per_page: 250,
      });

      let currentMember = members.find(m => m.id === this.currentMembershipId);

      if (currentMember) {
        sortedMembers = [currentMember, ...members.filter(member => member !== currentMember)];
      }
    } catch (error) {
      // @ts-expect-error
      if (this.errors.shouldFlash(error)) {
        // @ts-expect-error
        this.toastFlashMessages.toastError(this.errors.messageForStatus(error));
      }

      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }

    return sortedMembers || members;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Cards::Holder': typeof FlowsCardsCardHolder;
  }
}
