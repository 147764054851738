/* import __COLOCATED_TEMPLATE__ from './client-form.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
// @ts-expect-error
import { cached } from '@glimmer/tracking';

import { Disclaimer } from '@repo/design-system-kit';

// @ts-expect-error
import { CLIENT_KINDS } from 'qonto/constants/clients';
import COUNTRIES_TO_LOCALES_ARRAY, {
  DEFAULT_LOCALE_CODE,
  // @ts-expect-error
} from 'qonto/constants/countries-to-locales';
// @ts-expect-error
import italianProvinces from 'qonto/constants/it-provinces';

interface DirectDebitCollectionsClientFormSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLFormElement;
}

export default class DirectDebitCollectionsClientFormComponent extends Component<DirectDebitCollectionsClientFormSignature> {
  disclaimerInline = Disclaimer.Inline;

  @service declare segment: Services['segment'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare intl: Services['intl'];

  CLIENT_KINDS = CLIENT_KINDS;

  get client() {
    // @ts-expect-error
    return this.args.client;
  }

  get isItalianOrga() {
    return this.organizationManager.organization.legalCountry === 'IT';
  }

  @cached
  get provinceObjects() {
    // @ts-expect-error
    return italianProvinces.map(key => ({
      key,
      label: `${key} - ${this.intl.t(`italy.provinces.${key.toLowerCase()}`)}`,
    }));
  }

  get provinceObject() {
    // @ts-expect-error
    return this.provinceObjects.find(({ key }) => key === this.client.provinceCode);
  }

  set provinceObject(provinceObject) {
    this.client.provinceCode = provinceObject?.key;
  }

  @action
  // @ts-expect-error
  onCountryUpdate(countryCode) {
    this.client.countryCode = countryCode;

    let newLocaleCode =
      COUNTRIES_TO_LOCALES_ARRAY.find(
        // @ts-expect-error
        ({ country_code }) => country_code === countryCode?.toLowerCase()
      )?.code ?? DEFAULT_LOCALE_CODE;

    // @ts-expect-error
    this.client.localeObject = this.intl.getLocales.find(({ code }) => code === newLocaleCode);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'DirectDebitCollections::ClientForm': typeof DirectDebitCollectionsClientFormComponent;
  }
}
