export default {
  "header": "zn",
  "header__main": "zi",
  "note": "zA",
  "review-status": "zY",
  "reviewer-wrapper": "zh",
  "reviewer": "zf",
  "decline-note": "zK",
  "decline-note-title": "zG",
  "status-dot": "zr",
  "approved": "zb",
  "rejected": "zy"
};
