/* import __COLOCATED_TEMPLATE__ from './sub-menu.hbs'; */
import { assert } from '@ember/debug';
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

// @ts-expect-error
import { variation } from 'ember-launch-darkly';
import window from 'ember-window-mock';

// @ts-expect-error
import { DUAL_NAV_STATES } from 'qonto/constants/dual-nav';
import { PROMO_BOX_LOCAL_STORAGE_KEY } from 'qonto/constants/invoice-subscriptions';
// @ts-expect-error
import { NRC_GENERATION_PROMO_BOX_KEY } from 'qonto/constants/nrc';
import { PAYMENT_LINKS_SURVEY_PROMO_BOX_KEY } from 'qonto/constants/receivable-invoice';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import { MODULAR_TABLES_FEEDBACK_FORM_URL } from 'qonto/react/constants';

interface DualNavSubMenuSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class DualNavSubMenuComponent extends Component<DualNavSubMenuSignature> {
  @service declare abilities: Services['abilities'];
  @service declare router: Services['router'];
  @service declare intl: Services['intl'];
  @service declare invoiceSubscriptionManager: Services['invoiceSubscriptionManager'];
  @service declare subscriptionManager: Services['subscriptionManager'];
  @service declare flowLinkManager: Services['flowLinkManager'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare segment: Services['segment'];
  @service declare localeManager: Services['localeManager'];
  @service declare userManager: Services['userManager'];
  @tracked hasClickedSurveyLink = Boolean(
    safeLocalStorage.getItem(PAYMENT_LINKS_SURVEY_PROMO_BOX_KEY)
  );

  constructor(owner: unknown, args: DualNavSubMenuSignature['Args']) {
    super(owner, args);

    // @ts-expect-error
    let { label, groups, state } = this.args;

    assert('<DualNav::SubMenu /> must have @label', label);
    assert('<DualNav::SubMenu /> must have @groups', groups);
    assert('<DualNav::SubMenu /> must have @groups.length > 0', groups.length);

    if (this.isVisible && state !== DUAL_NAV_STATES.COLLAPSED) {
      document.body.classList.remove('prevent-nav-animation');
    }
  }

  get isVisible() {
    // @ts-expect-error
    let { activeMenuItemId, currentMenuItemId, hoveredMenuItemId, state } = this.args;

    if (state === DUAL_NAV_STATES.RESPONSIVE) {
      return hoveredMenuItemId === currentMenuItemId;
    }
    return (hoveredMenuItemId ?? activeMenuItemId) === currentMenuItemId;
  }

  get shouldDisplayAccountAggregationPromoBox() {
    // @ts-expect-error
    let { state, hoveredMenuItemId } = this.args;

    if (state === DUAL_NAV_STATES.RESPONSIVE && hoveredMenuItemId !== 'business-account') {
      return false;
    }

    return (
      this.abilities.can('import external-account') &&
      this.router.currentRouteName === 'accounts.index'
    );
  }

  get shouldShowModularTablesPromoBox() {
    // do not show the promo box for bankless customers
    if (!this.subscriptionManager.features.bankAccount) {
      return false;
    }
    let transactionsRoute = 'transactions.index';
    let isVisible =
      variation('feature--boolean-modular-tables-external-release') &&
      this.router.currentRouteName === transactionsRoute;

    return isVisible;
  }

  generateModularTablesTypeformUrl() {
    let currentLocale = this.localeManager.locale;
    let { organization } = this.organizationManager;
    // @ts-expect-error
    let currentUserEmail = this.userManager.currentUser?.email;
    let baseUrl =
      // @ts-expect-error
      MODULAR_TABLES_FEEDBACK_FORM_URL[currentLocale] || MODULAR_TABLES_FEEDBACK_FORM_URL.en;
    let typeFormUrlPath = `#email=${currentUserEmail}&slug=${organization?.slug}`;

    return `${baseUrl}/${typeFormUrlPath}`;
  }

  @action
  handleModularTablesPromoBoxCTAClick() {
    this.segment.track('discovery_sub-nav-promotional-card_clicked', {
      feature: 'modular_tables',
      source: 'transaction_screen',
    });
    window.open(this.generateModularTablesTypeformUrl(), '_blank', 'noopener,noreferrer');
  }

  @action
  handleReimbursementsPromoBoxCTAClick() {
    this.segment.track('product-discovery_user-action_clicked', {
      name: 'approval-workflow-action',
      price_plan_code: this.subscriptionManager.currentPricePlan?.code,
    });
    this.router.transitionTo('settings.approval-workflows');
  }

  get shouldShowRecurringInvoicesPromoBox() {
    return (
      this.invoiceSubscriptionManager.shouldShowPromoBox &&
      this.router.currentRouteName === 'invoice-subscriptions.index'
    );
  }

  get shouldDisplayAccountReceivableOnboardingPromoBox() {
    return (
      variation('feature-invoices-ar-onboarding') &&
      (this.router.currentRouteName === 'receivable-invoices.index' ||
        this.router.currentRouteName === 'invoice-subscriptions.index')
    );
  }

  get shouldDisplayPayLaterPromoBox() {
    // @ts-expect-error
    let { state, hoveredMenuItemId, activeSubMenuItem } = this.args;

    if (state === DUAL_NAV_STATES.RESPONSIVE && hoveredMenuItemId !== 'financing') {
      return false;
    }

    return (
      variation('feature--boolean-pay-later-promotion-installments') &&
      activeSubMenuItem?.id === 'pay-later'
    );
  }

  get shouldShowSupplierInvoiceArchivePromoBox() {
    // @ts-expect-error
    let { state, hoveredMenuItemId } = this.args;

    return !(state === DUAL_NAV_STATES.RESPONSIVE && hoveredMenuItemId !== 'invoices');
  }

  get shouldShowPaymentLinksSurveyPromoBox() {
    let isReceivableInvoicesIndexRoute =
      this.router.currentRouteName === 'receivable-invoices.index';
    let isFrenchOrGermanOrganization = ['DE', 'FR'].includes(
      this.organizationManager.organization?.legalCountry
    );

    let isVisible =
      variation('feature--invoices-payment-links-survey') &&
      isFrenchOrGermanOrganization &&
      isReceivableInvoicesIndexRoute &&
      !this.hasClickedSurveyLink;

    return isVisible;
  }

  get shouldShowNrcGenerationPromoBox() {
    // @ts-expect-error
    let { state, hoveredMenuItemId } = this.args;

    if (state === DUAL_NAV_STATES.RESPONSIVE && hoveredMenuItemId !== 'taxes-and-duties') {
      return false;
    }

    let isCorrectRoute = this.router.currentRouteName?.includes('agencia-tributaria.list');
    let hasAbility = this.abilities.can('generate nrc');
    let featureFlagEnabled = variation('feature--boolean-nrc-generation-promo-dual-nav');
    let hasClickedPromoBox = Boolean(safeLocalStorage.getItem(NRC_GENERATION_PROMO_BOX_KEY));

    return isCorrectRoute && hasAbility && featureFlagEnabled && !hasClickedPromoBox;
  }

  @action
  handleNrcGenerationPromoBoxClick() {
    // @ts-expect-error
    safeLocalStorage.setItem(NRC_GENERATION_PROMO_BOX_KEY, true);
    this.flowLinkManager.transitionTo({
      name: 'nrc-payment',
      stepId: 'modelo-select',
    });
  }

  @action
  recurringInvoicePromoBadgeClick() {
    this.flowLinkManager.transitionTo({
      name: 'sdd-activation',
      stepId: 'intro',
      queryParams: { origin: 'invoice-subscriptions.index' },
    });
    // @ts-expect-error
    safeLocalStorage.setItem(PROMO_BOX_LOCAL_STORAGE_KEY, true);
  }

  @action
  onAnimationEnd() {
    document.body.classList.remove('prevent-nav-animation');
  }

  @action
  paymentLinksSurveyRedirectClick() {
    let typeFormUrl = this.generateFormUrl();
    // @ts-expect-error
    safeLocalStorage.setItem(PAYMENT_LINKS_SURVEY_PROMO_BOX_KEY, true);
    this.segment.track('payment-link_promo-card_clicked');
    this.hasClickedSurveyLink = true;

    window.open(typeFormUrl, '_blank');
  }

  generateFormUrl() {
    let currentLocale = this.localeManager.locale;
    let { organization } = this.organizationManager;
    // @ts-expect-error
    let currentUserEmail = this.userManager?.currentUser?.email;
    let typeFormBaseUrl = `https://qonto.typeform.com/to`;
    let typeFormUrlPath = `#email=${currentUserEmail}&legal_country=${organization?.legalCountry}&organisation_id=${organization?.id}`;

    let typeFormUrl = `${typeFormBaseUrl}/oyrjBzCe/${typeFormUrlPath}`;

    switch (currentLocale) {
      case 'en':
        typeFormUrl = `${typeFormBaseUrl}/oyrjBzCe/${typeFormUrlPath}`;
        break;

      case 'fr':
        typeFormUrl = `${typeFormBaseUrl}/fvpBUcmx/${typeFormUrlPath}`;
        break;

      case 'de':
        typeFormUrl = `${typeFormBaseUrl}/hlsWTOHB/${typeFormUrlPath}`;
        break;

      default:
        typeFormUrl = `${typeFormBaseUrl}/oyrjBzCe/${typeFormUrlPath}`;
        break;
    }

    return typeFormUrl;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'DualNav::SubMenu': typeof DualNavSubMenuComponent;
  }
}
