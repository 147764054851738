export default {
  "custom-export-loading": "qFS",
  "title": "qFc",
  "description": "qFq",
  "section": "qFZ",
  "placeholder-avatar": "qFR",
  "_with-avatar": "qFQ",
  "_with-list": "qFe",
  "list-item": "qFB",
  "radio": "qFE",
  "header": "qFd",
  "header-line": "qFI"
};
