/* import __COLOCATED_TEMPLATE__ from './attachment-preview.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Spinner } from '@repo/design-system-kit';
import { match } from 'macro-decorators';

import { InvoiceUnavailablePreview } from 'qonto/react/components/supplier-invoices/invoice-unavailable-preview';
import { NonFinancialAttachmentPreview } from 'qonto/react/components/supplier-invoices/non-financial-attachment-preview';

interface AttachmentPreviewSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class AttachmentPreviewComponent extends Component<AttachmentPreviewSignature> {
  invoiceUnavailablePreview = InvoiceUnavailablePreview;
  nonFinancialAttachmentPreview = NonFinancialAttachmentPreview;
  spinner = Spinner;

  // @ts-expect-error
  @match('args.attachmentType', /^image/) isImagePreview;
  // @ts-expect-error
  @match('args.attachmentType', /pdf/) isPdfPreview;
  // @ts-expect-error
  @match('args.attachmentType', /xml/) isXmlPreview;

  // @ts-expect-error
  @tracked isIframeLoaded;

  get shouldRenderInvoiceUnavailablePreview() {
    // @ts-expect-error
    return this.args.isFrenchEInvoice && this.isXmlPreview;
  }

  get shouldRenderNonFinancialAttachmentPreview() {
    // @ts-expect-error
    return this.args.isInvalidGermanEInvoice && this.isXmlPreview;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Attachments::AttachmentPreview': typeof AttachmentPreviewComponent;
  }
}
