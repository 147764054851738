/* import __COLOCATED_TEMPLATE__ from './share-panel.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Checkbox, Disclaimer, TextAreaField } from '@repo/design-system-kit';
import { dropTask, task } from 'ember-concurrency';

// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';

interface ReceivableInvoicesSharePanelShareByEmailSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class ReceivableInvoicesSharePanelShareByEmail extends Component<ReceivableInvoicesSharePanelShareByEmailSignature> {
  checkbox = Checkbox;
  disclaimerBlock = Disclaimer.Block;
  textAreaField = TextAreaField;

  @service declare intl: Services['intl'];
  @service declare segment: Services['segment'];
  @service declare networkManager: Services['networkManager'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare sentry: Services['sentry'];
  @service declare errors: Services['errors'];
  @service declare store: Services['store'];
  @service declare router: Services['router'];

  @tracked displaySubjectErrors = false;
  @tracked areEmailsInvalid = false;
  // @ts-expect-error
  @tracked sendTo = this.args.sendTo || '';
  @tracked displaySendToErrors = false;
  @tracked saveEmailTemplate = false;

  sendDocumentByEmail = dropTask(async () => {
    // @ts-expect-error
    if (this.args.isQuote) {
      return await this.shareQuoteByEmailTask.perform();
    }
    return await this.shareByEmailTask.perform();
  });

  @action
  // @ts-expect-error
  trackingEvent(eventName) {
    this.toastFlashMessages.toastInfo(this.intl.t('receivable-invoices.share-link.toaster-copied'));
    this.segment.track(eventName);
  }

  get showDraftDisclaimer() {
    // @ts-expect-error
    return !this.args.isAccountantTabActive && this.args.isDraft;
  }

  get previousRoute() {
    return this.router.currentRoute?.queryParams?.origin;
  }

  get showFrEinvoiceDisclaimer() {
    let creationRoutes = [
      'receivable-invoices.edit',
      'receivable-invoices.new',
      'receivable-invoices.duplicate',
      'receivable-invoices.draft-finalize',
    ];

    // @ts-expect-error
    if (creationRoutes.includes(this.previousRoute)) {
      // @ts-expect-error
      return !this.args.isDraft && this.args.document?.isEinvoice;
    }
    return false;
  }

  get documentType() {
    // @ts-expect-error
    if (this.args.isQuote) {
      return this.intl.t('receivable-invoices.document-types.quotes');
    }

    // @ts-expect-error
    if (this.args.isDraft) {
      return this.intl.t('receivable-invoices.document-types.drafts');
    }

    return this.intl.t('receivable-invoices.document-types.invoices');
  }

  get sendToFieldMessage() {
    if (!this.displaySendToErrors) {
      return this.intl.t('receivable-invoices.share-email.field-send-to.disclaimer');
    }

    if (this.areEmailsInvalid) {
      return this.intl.t('receivable-invoices.share-email.field-send-to.error');
    }

    if (!this.sendTo) {
      return this.intl.t('receivable-invoices.share-email.required-field');
    }
  }

  get shouldShowSubtitle() {
    // @ts-expect-error
    return !this.args.isAccountantTabActive && !this.args.isCreditNote;
  }

  get subtitle() {
    // @ts-expect-error
    if (this.args.isQuote) {
      return this.intl.t('receivable-invoices.share-email.subtitle.quotes');
    }

    // @ts-expect-error
    if (this.args.isDraft) {
      return this.intl.t('receivable-invoices.share-email.subtitle.drafts');
    }

    return this.intl.t('receivable-invoices.share-email.subtitle.invoices');
  }

  @action
  // @ts-expect-error
  updateSendTo(value) {
    this.sendTo = value;
    this.displaySendToErrors = false;
  }

  get subjectFieldMessage() {
    this.updateDisplaySubjectErrors(false, this.subjectHasError);

    if (this.displaySubjectErrors) {
      return this.intl.t('receivable-invoices.share-email.required-field');
    }
  }

  get emailsArray() {
    let { sendTo } = this;

    if (!sendTo) return [];

    //Delete space after ',' char,
    //Delete ',' char when first or last param
    //Return an array of emails from a string where emails are separated by a comma
    let emailsArray = sendTo.replace(/^,/, '').replace(/,$/, '').split(',');

    // @ts-expect-error
    return emailsArray.map(email => email.trim());
  }

  get subjectHasError() {
    // @ts-expect-error
    let { emailSubject } = this.args;
    return !emailSubject;
  }

  get saveTemplate() {
    // @ts-expect-error
    return this.args.showUpdateEmailTrigger && this.saveEmailTemplate;
  }

  shareByEmailTask = task(async () => {
    this.updateDisplaySubjectErrors(true, this.subjectHasError);
    let isSendToEmpty = !this.sendTo;
    this.areEmailsInvalid = false;
    this.displaySendToErrors = true;

    if (!isSendToEmpty && !this.displaySubjectErrors) {
      this.sendEmailTrackingEvent();

      // @ts-expect-error
      let { emailSubject, emailMessage, copyToSelf, close } = this.args;

      try {
        await this.networkManager.request(this.sendURL, {
          method: 'POST',
          data: JSON.stringify({
            send_to: this.emailsArray,
            email_title: emailSubject,
            email_body: emailMessage,
            copy_to_self: copyToSelf,
            save_template: this.saveTemplate,
          }),
        });

        this.toastFlashMessages.toastSuccess(this.sendEmailSuccessMessage);
        close();
      } catch (error) {
        if (
          // @ts-expect-error
          error.status === 422 &&
          // @ts-expect-error
          error.errors.some(
            // @ts-expect-error
            ({ code, source }) => code === 'invalid' && source?.pointer.includes('sendto')
          )
        ) {
          this.areEmailsInvalid = true;
        } else {
          let errorInfo = ErrorInfo.for(error);
          if (errorInfo.shouldSendToSentry) {
            this.sentry.captureException(error);
          }
          // @ts-expect-error
          if (this.errors.shouldFlash(error)) {
            this.toastFlashMessages.toastError(
              this.intl.t('receivable-invoices.share-email.toast-error')
            );
          }
        }
      }
    }
  });

  shareQuoteByEmailTask = task(async () => {
    this.updateDisplaySubjectErrors(true, this.subjectHasError);
    let isSendToEmpty = !this.sendTo;
    this.areEmailsInvalid = false;
    this.displaySendToErrors = true;

    if (!isSendToEmpty && !this.displaySubjectErrors) {
      this.sendEmailTrackingEvent();
      // @ts-expect-error
      let { emailSubject, emailMessage, copyToSelf, close } = this.args;

      try {
        await this.networkManager.request(this.sendURL, {
          method: 'POST',
          data: JSON.stringify({
            send_to: this.emailsArray,
            email_title: emailSubject,
            email_body: emailMessage,
            copy_to_self: copyToSelf,
            save_template: this.saveTemplate,
          }),
        });
        this.toastFlashMessages.toastSuccess(this.sendEmailSuccessMessage);
        close();
      } catch (error) {
        if (
          // @ts-expect-error
          error.status === 422 &&
          // @ts-expect-error
          error.errors.some(
            // @ts-expect-error
            ({ code, source }) => code === 'invalid' && source?.pointer.includes('sendto')
          )
        ) {
          this.areEmailsInvalid = true;
        } else {
          let errorInfo = ErrorInfo.for(error);
          if (errorInfo.shouldSendToSentry) {
            this.sentry.captureException(error);
          }
          // @ts-expect-error
          if (this.errors.shouldFlash(error)) {
            this.toastFlashMessages.toastError(
              this.intl.t('receivable-invoices.share-email.toast-error')
            );
          }
        }
      }
    }
  });

  get sendURL() {
    let adapter;
    // @ts-expect-error
    let { isCreditNote, isQuote } = this.args;
    if (isCreditNote) {
      adapter = 'receivable-credit-note';
    } else if (isQuote) {
      adapter = 'quote';
    } else {
      adapter = 'receivable-invoice';
    }
    // @ts-expect-error
    return this.store.adapterFor(adapter).sendUrl(this.args.document.id);
  }

  sendEmailTrackingEvent() {
    let {
      // @ts-expect-error
      isDefaultEmailTemplate,
      // @ts-expect-error
      isDraft,
      // @ts-expect-error
      isAccountantTabActive,
      // @ts-expect-error
      isCreditNote,
      // @ts-expect-error
      isQuote,
      // @ts-expect-error
      showUpdateEmailTrigger,
    } = this.args;

    let props = { isQontoDefault: isDefaultEmailTemplate && !showUpdateEmailTrigger };

    if (isDraft) {
      if (isAccountantTabActive) {
        return this.segment.track('invoice-draft_send-by-email-accountant_confirmed');
      } else {
        return this.segment.track('invoice-draft_send-by-email_confirmed', props);
      }
    } else if (isCreditNote) {
      return this.segment.track('credit-note_send-by-email_confirmed');
    } else if (isQuote) {
      return this.segment.track('quote_send-by-email_confirmed', props);
    } else {
      return this.segment.track('invoice_send-by-email_confirmed', props);
    }
  }

  get sendEmailSuccessMessage() {
    // @ts-expect-error
    if (this.args.isQuote) {
      return this.intl.t('receivable-invoices.quote-sending.send-quote.toast-success');
      // @ts-expect-error
    } else if (this.args.isDraft) {
      return this.intl.t('receivable-invoices.share-email.draft.toast-success');
      // @ts-expect-error
    } else if (this.args.isCreditNote) {
      return this.intl.t('receivable-invoices.share-email.credit-note-toaster');
    } else {
      return this.intl.t('receivable-invoices.share-email.toaster');
    }
  }

  // @ts-expect-error
  updateDisplayErrors(clickOnSend, hasErrors) {
    if (clickOnSend && hasErrors) {
      return hasErrors;
    } else if (!hasErrors) {
      return false;
    }
    return null;
  }

  // @ts-expect-error
  updateDisplaySubjectErrors(clickOnSend, hasErrors) {
    let updateValue = this.updateDisplayErrors(clickOnSend, hasErrors);
    if (updateValue !== null) {
      this.displaySubjectErrors = updateValue;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ReceivableInvoices::SharePanel::SharePanel': typeof ReceivableInvoicesSharePanelShareByEmail;
  }
}
