export default {
  "wrapper": "cTf",
  "header": "cTK",
  "fadeIn": "cTG",
  "header-content": "cTr",
  "with-cta": "cTb",
  "header-title": "cTy title-4",
  "advanced-filters-trigger": "cTU",
  "filters-button": "cTj",
  "filters-button-upsell": "cTW",
  "filters-upsell-item": "cTu",
  "filters-badge": "czS",
  "chart-container": "czc",
  "chart-legend-container": "czq mb-24",
  "chart-watermark-container": "czZ",
  "chart-watermark": "czR",
  "placeholder-title": "czQ",
  "placeholder-subtitle": "cze",
  "advanced-filters": "czB"
};
