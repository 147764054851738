// @ts-nocheck
import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class HeadDataService extends Service {
  @tracked title;
}

declare module '@ember/service' {
  interface Registry {
    'head-data': HeadDataService;
    headData: HeadDataService;
  }
}
