export default {
  "header-cell": "cmx",
  "checkbox-cell": "cmw",
  "title-col": "cmN",
  "internal-note-col": "cmv",
  "unit-col": "cmo",
  "price-col": "cmn",
  "header-content": "cmi caption-bold",
  "active": "cmA",
  "empty": "cmY",
  "align-checkbox": "cmh"
};
