/* import __COLOCATED_TEMPLATE__ from './page-header.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface PageHeaderSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const PageHeader = templateOnlyComponent<PageHeaderSignature>();

export default PageHeader;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Financing::PayLater::Cockpit::PageHeader': typeof PageHeader;
  }
}
