/* import __COLOCATED_TEMPLATE__ from './review.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Disclaimer, Spinner } from '@repo/design-system-kit';
import { dropTask, rawTimeout } from 'ember-concurrency';
import { reads } from 'macro-decorators';

// @ts-expect-error
import { ESTIMATE_TIMEOUT } from 'qonto/constants/bank-account';
import CURRENCIES from 'qonto/constants/currencies';
import { apiBaseURL, apiNamespace } from 'qonto/constants/hosts';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';

const REMUNERATED_ACCOUNT_ACTIVITY_TAG = 'treasury_and_interco';
const TIMEOUT = 'timeout';

interface FlowsAccountsRemuneratedFundingReviewSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class FlowsAccountsRemuneratedFundingReview extends Component<FlowsAccountsRemuneratedFundingReviewSignature> {
  disclaimerInline = Disclaimer.Inline;

  spinner = Spinner;

  // @ts-expect-error
  @reads('organizationManager.organization') organization;
  // @ts-expect-error
  @reads('organization.bankAccounts') bankAccounts;
  // @ts-expect-error
  @reads('args.context.accountToDebit') accountToDebit;
  // @ts-expect-error
  @reads('args.context.accountToCredit') accountToCredit;

  // @ts-expect-error
  @tracked maxMaturity;
  // @ts-expect-error
  @tracked maxMaturityRate;
  // @ts-expect-error
  @tracked maxMaturityEarnings;
  @tracked isTaxApplicable = false;
  @tracked isLoading = true;

  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare intl: Services['intl'];
  @service declare networkManager: Services['networkManager'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare errors: Services['errors'];
  @service declare segment: Services['segment'];
  @service declare sentry: Services['sentry'];
  @service declare store: Services['store'];

  constructor(owner: unknown, args: FlowsAccountsRemuneratedFundingReviewSignature['Args']) {
    super(owner, args);
    // eslint-disable-next-line ember-concurrency/no-perform-without-catch
    this.fetchEstimates.perform();
  }

  fetchEstimates = dropTask(async () => {
    // @ts-expect-error
    let url = `${apiBaseURL}/${apiNamespace}/remunerated_accounts/${this.organization.id}/accounts/${this.args.context.accountToCredit.id}/estimate`;
    let data = {
      deposit_amount: {
        // @ts-expect-error
        value: this.args.context.amount,
        currency: CURRENCIES.default,
      },
    };
    let timeoutPromise = new Promise((resolve, reject) => {
      /* eslint-disable-next-line promise/catch-or-return */
      rawTimeout(ESTIMATE_TIMEOUT).then(() => reject(TIMEOUT)); // We will handle the error in the catch block of the actual request
    });

    let request = this.networkManager.request(url, {
      method: 'POST',
      data: JSON.stringify(data),
    });

    try {
      let response = await Promise.race([request, timeoutPromise]);
      let { max_maturity, max_maturity_rate, max_maturity_earnings, is_tax_applicable } = response;
      this.maxMaturity = max_maturity;
      this.maxMaturityRate = max_maturity_rate;
      this.maxMaturityEarnings = max_maturity_earnings;
      this.isTaxApplicable = is_tax_applicable;
      this.isLoading = false;
    } catch (error) {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
      // @ts-expect-error
      this.args.backToStep('add-funds');
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  });

  get maxMaturityPercentage() {
    return this.maxMaturityRate / 100;
  }

  handleNextTask = dropTask(async () => {
    // @ts-expect-error
    let { amount } = this.args.context;

    let transfer = this.store.createRecord('transfer', {
      bankAccount: this.accountToDebit,
      organization: this.organization,
      fx: false,
      name: this.accountToCredit.name,
      amountCurrency: CURRENCIES.default,
      amount,
      reference: this.intl.t('transfers.new.internal-transfer'),
      activityTag: REMUNERATED_ACCOUNT_ACTIVITY_TAG,
      creditBankAccountId: this.accountToCredit.id,
    });

    transfer.addIdempotencyKey();
    try {
      await transfer.save();
      // @ts-expect-error
      this.args.context.maxMaturity = this.maxMaturity;
      this.segment.track('remunerated-account_fund_confirmed');
      // Make sure we get the most recent bank account data since the account status will be updated to `active`
      await this.organizationManager.updateBankAccounts();
      // @ts-expect-error
      this.args.transitionToNext();
    } catch (error) {
      this.errors.handleError(error);
    } finally {
      transfer.removeIdempotencyHeader();
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Accounts::Remunerated::Funding::Review': typeof FlowsAccountsRemuneratedFundingReview;
  }
}
