import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';

import { Sidebar } from 'qonto/react/components/financing/pay-later/cockpit/sidebar';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class FinancingPayLaterCompletedController extends Controller {
  sidebar = Sidebar;

  @service intl;
  @service financing;
  @service router;
  @service segment;
  @service sentry;
  @service toastFlashMessages;

  queryParams = ['highlight'];

  @tracked highlight = null;

  @action
  closeSidebar() {
    this.highlight = null;
  }

  @action
  onRowClick(financing) {
    this.highlight = financing.id;
  }

  @action
  onKeyPress(financing, event) {
    if (event.key === 'Enter') {
      this.onRowClick(financing);
    }
  }

  @action
  refreshRoute() {
    this.router.refresh('financing.pay-later.completed');
  }

  @action
  onGenerateStatementBtnClick() {
    this.segment.track('pay-later_cockpit-financing-details_statement-clicked');
    this.generateStatementTask.perform().catch(ignoreCancelation);
  }

  generateStatementTask = dropTask(async () => {
    try {
      await this.financing.postStatementGenerate(this.highlight);
      this.toastFlashMessages.toastInfo(
        this.intl.t('financing.pay-later.cockpit.details.statement.toast.success')
      );
    } catch (error) {
      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.generic'));
    }
  });

  get financings() {
    return this.model.fetchFinancingsTask.lastSuccessful?.value ?? [];
  }

  get selectedFinancing() {
    return this.financings.find(financing => financing.id === this.highlight);
  }

  get localState() {
    let state = {
      isEmpty: false,
      isError: false,
    };

    if (this.model.fetchFinancingsTask.isRunning) return state;

    state.isError = this.model.fetchFinancingsTask.last.isError;
    state.isEmpty = this.financings.length === 0;

    return state;
  }
}
