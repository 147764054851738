export default {
  "header-cell": "cHZ",
  "method-col": "cHR",
  "expiration-col": "cHQ",
  "status-col": "cHe",
  "amount-col": "cHB",
  "with-sidebar": "cHE",
  "header-content": "cHd caption-bold",
  "empty": "cHI",
  "align-right": "cHt"
};
