import { useCallback, type ReactNode } from 'react';
import { Badge, Button, Tooltip } from '@repo/design-system-kit';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { useIntl } from 'react-intl';
import cx from 'clsx';
import { BaseCell } from 'qonto/react/components/table-v2/cells/base-cell';
import { DataWithIconCell } from 'qonto/react/components/table-v2/cells/data-with-icon-cell';
import { cellContextManager } from 'qonto/react/contexts/cell-context';
import { Status } from 'qonto/react/graphql';
import { StatusAvatar } from 'qonto/react/components/avatar/status-avatar';
import { useOrganizationManager } from 'qonto/react/hooks/use-organization-manager';
import { StopXS } from 'qonto/react/assets/icons/stop-xs';
import { OpenLink } from 'qonto/react/assets/icons/open-link';
import type { SepaTransferUpsellRecommendation } from 'qonto/services/upsell-manager';
import { routeContextManager } from 'qonto/react/contexts/route-context';
import styles from './styles.strict-module.css';

export function TransactionCell(): ReactNode {
  const transaction = cellContextManager.useCellContext();
  const { counterpartyName: name, enrichmentData, status } = transaction;
  const avatar = enrichmentData.logo.small;
  const isDeclined = status === Status.Declined || status === Status.Reversed;
  const { highlightItem } = routeContextManager.useRouteContext();
  const store = useEmberService('store');
  const segment = useEmberService('segment');
  const { organization } = useOrganizationManager();
  const { formatMessage } = useIntl();
  const upsellManager = useEmberService('upsell-manager');
  const sepaTransferPromotionRecommendation: SepaTransferUpsellRecommendation | null =
    upsellManager.sepaTransferUpsellRecommendation(transaction, true);
  const refetchEmberTransaction = useCallback(
    async (transactionId: string): Promise<void> => {
      const emberTransaction = store.peekRecord('transaction', transactionId) as {
        reload: () => Promise<void>;
      } | null;
      if (!emberTransaction) {
        await store.findRecord('transaction', transactionId);
        return;
      }
      await emberTransaction.reload();
    },
    [store]
  );

  const openSidePanel = useCallback(() => {
    segment.track('tables_primary-column-cta_clicked', {
      ...(organization.hasFeeUpsell
        ? { has_upsell_entrypoint: Boolean(sepaTransferPromotionRecommendation) }
        : {}),
    });
    void refetchEmberTransaction(transaction.id);
    highlightItem(transaction.id);
  }, [
    highlightItem,
    organization.hasFeeUpsell,
    refetchEmberTransaction,
    segment,
    sepaTransferPromotionRecommendation,
    transaction.id,
  ]);

  const icon = avatar ? (
    <StatusAvatar
      alt={name}
      dataTestId="avatar"
      hasBorder
      icon={isDeclined ? <StopXS /> : undefined}
      iconSize="16"
      src={avatar}
    />
  ) : (
    <StatusAvatar
      avatar="custom"
      dataTestId="custom-avatar"
      icon={isDeclined ? <StopXS /> : undefined}
      iconSize="16"
      name={name}
    />
  );

  const sidePanelButton = (
    <Tooltip
      closeDelay={0}
      delay={300}
      label={formatMessage({
        id: 'transactions.modular_table.open_transaction.tooltip',
      })}
      placement="top"
      tooltipTestId="tooltip"
    >
      <Button
        className={styles.button}
        data-testid="sidepanel-button"
        iconOnly
        onPress={openSidePanel}
        type="button"
        variant="tertiary"
      >
        <OpenLink />
      </Button>
    </Tooltip>
  );
  const subtitle = sepaTransferPromotionRecommendation ? (
    <div className={styles.subtitle}>
      <Tooltip
        label={sepaTransferPromotionRecommendation.translations.subtitle}
        needsButton
        placement="top"
      >
        <Badge
          data-test-sepa-transfer-upsell-badge
          text={sepaTransferPromotionRecommendation.translations.badgeText}
          type="save"
        />
      </Tooltip>
    </div>
  ) : undefined;

  return (
    <BaseCell
      actionSlot={sidePanelButton}
      actionSlotAlignment="right"
      data-testid="transaction-cell"
    >
      <DataWithIconCell
        cellSubtitleClassName={isDeclined ? styles.declined : undefined}
        cellTitleClassName={isDeclined ? cx(styles.declined, 'body-1') : 'body-1'}
        icon={icon}
        subtitle={subtitle}
        title={name}
      />
    </BaseCell>
  );
}
