/* import __COLOCATED_TEMPLATE__ from './bookkeeping.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

// @ts-expect-error
const featuresOrderByTrigger = lastFeatureToDisplay => ({
  default: [
    'accountantAccess',
    'certifiedReceipts',
    'receiptsForward',
    'vatAutodetection',
    'customLabels',
    'suppliersManagement',
    'multiAccounts',
    'transactionsReview',
  ],
  multiAccounts: [
    'multiAccounts',
    'accountantAccess',
    'vatAutodetection',
    'certifiedReceipts',
    'receiptsForward',
    'customLabels',
    'suppliersManagement',
    'transactionsReview',
  ],
  suppliersManagement: [
    'suppliersManagement',
    'vatAutodetection',
    'accountantAccess',
    'receiptsForward',
    'customLabels',
    'multiAccounts',
    'certifiedReceipts',
    lastFeatureToDisplay,
  ],
  customLabels: [
    'customLabels',
    'accountantAccess',
    'certifiedReceipts',
    'receiptsForward',
    'vatAutodetection',
    'multiAccounts',
    'suppliersManagement',
    lastFeatureToDisplay,
  ],
  accountantAccess: [
    'accountantAccess',
    'vatAutodetection',
    'multiAccounts',
    'receiptsForward',
    'certifiedReceipts',
    'customLabels',
    'suppliersManagement',
    lastFeatureToDisplay,
  ],
  attachmentCollection: [
    'receiptsForward',
    'accountantAccess',
    'multiAccounts',
    'certifiedReceipts',
    'vatAutodetection',
    'customLabels',
    'suppliersManagement',
    lastFeatureToDisplay,
  ],
  vatAutodetection: [
    'vatAutodetection',
    'accountantAccess',
    'certifiedReceipts',
    'receiptsForward',
    'customLabels',
    'multiAccounts',
    'suppliersManagement',
    'transactionsReview',
  ],
  supplierInvoices: [
    'supplierInvoices',
    'receiptsForward',
    'accountantAccess',
    'multiAccounts',
    'vatAutodetection',
    'customLabels',
    'suppliersManagement',
    'certifiedReceipts',
  ],
  customExportTemplates: [
    'customExportTemplates',
    'accountantAccess',
    'certifiedReceipts',
    'receiptsForward',
    'vatAutodetection',
    'customLabels',
    'suppliersManagement',
    'multiAccounts',
    'transactionsReview',
  ],
});

interface DiscoverBundleBookkeepingSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class DiscoverBundleBookkeepingComponent extends Component<DiscoverBundleBookkeepingSignature> {
  @service declare abilities: Services['abilities'];
  @service declare flowLinkManager: Services['flowLinkManager'];
  @service declare intl: Services['intl'];
  @service declare localeManager: Services['localeManager'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare segment: Services['segment'];
  @service declare subscriptionManager: Services['subscriptionManager'];

  localSlides = {
    accountantAccess: {
      // @ts-expect-error
      title: this.intl.t('upsell.bundle-bookkeeping.accountant-access.title'),
      // @ts-expect-error
      description: this.intl.t('upsell.bundle-bookkeeping.accountant-access.text'),
      img: {
        de: '/illustrations/upsell/de/accountant-access.png',
        en: '/illustrations/upsell/en/accountant-access.png',
        es: '/illustrations/upsell/es/accountant-access.png',
        fr: '/illustrations/upsell/fr/accountant-access.png',
        it: '/illustrations/upsell/it/accountant-access.png',
      },
    },
    certifiedReceipts: {
      // @ts-expect-error
      title: this.intl.t('upsell.bundle-bookkeeping.certified-receipt.title'),
      // @ts-expect-error
      description: this.intl.t('upsell.bundle-bookkeeping.certified-receipt.text'),
      img: {
        de: '/illustrations/upsell/de/certified-receipts.png',
        en: '/illustrations/upsell/en/certified-receipts.png',
        es: '/illustrations/upsell/es/certified-receipts.png',
        fr: '/illustrations/upsell/fr/certified-receipts.png',
        it: '/illustrations/upsell/it/certified-receipts.png',
      },
    },
    receiptsForward: {
      // @ts-expect-error
      title: this.intl.t('upsell.bundle-bookkeeping.receipt-forward.title'),
      // @ts-expect-error
      description: this.intl.t('upsell.bundle-bookkeeping.receipt-forward.text'),
      img: {
        de: '/illustrations/upsell/de/receipts-forward.png',
        en: '/illustrations/upsell/en/receipts-forward.png',
        es: '/illustrations/upsell/es/receipts-forward.png',
        fr: '/illustrations/upsell/fr/receipts-forward.png',
        it: '/illustrations/upsell/it/receipts-forward.png',
      },
    },
    vatAutodetection: {
      // @ts-expect-error
      title: this.intl.t('upsell.bundle-bookkeeping.vat-auto-detect.title'),
      // @ts-expect-error
      description: this.intl.t('upsell.bundle-bookkeeping.vat-auto-detect.text'),
      img: {
        de: '/illustrations/upsell/de/vat-autodetection.png',
        en: '/illustrations/upsell/en/vat-autodetection.png',
        es: '/illustrations/upsell/es/vat-autodetection.png',
        fr: '/illustrations/upsell/fr/vat-autodetection.png',
        it: '/illustrations/upsell/it/vat-autodetection.png',
      },
    },
    customLabels: {
      // @ts-expect-error
      title: this.intl.t('upsell.bundle-bookkeeping.tagging.title'),
      // @ts-expect-error
      description: this.intl.t('upsell.bundle-bookkeeping.tagging.text'),
      img: {
        de: '/illustrations/upsell/de/custom-labels.png',
        en: '/illustrations/upsell/en/custom-labels.png',
        es: '/illustrations/upsell/es/custom-labels.png',
        fr: '/illustrations/upsell/fr/custom-labels.png',
        it: '/illustrations/upsell/it/custom-labels.png',
      },
    },
    suppliersManagement: {
      // @ts-expect-error
      title: this.intl.t('upsell.bundle-bookkeeping.suppliers.title'),
      // @ts-expect-error
      description: this.intl.t('upsell.bundle-bookkeeping.suppliers.text'),
      img: {
        de: '/illustrations/upsell/de/suppliers-management.png',
        en: '/illustrations/upsell/en/suppliers-management.png',
        es: '/illustrations/upsell/es/suppliers-management.png',
        fr: '/illustrations/upsell/fr/suppliers-management.png',
        it: '/illustrations/upsell/it/suppliers-management.png',
      },
    },
    multiAccounts: {
      // @ts-expect-error
      title: this.intl.t('upsell.bundle-bookkeeping.multi-account.title'),
      // @ts-expect-error
      description: this.intl.t('upsell.bundle-bookkeeping.multi-account.text'),
      img: {
        de: '/illustrations/upsell/de/multi-accounts.png',
        en: '/illustrations/upsell/en/multi-accounts.png',
        es: '/illustrations/upsell/es/multi-accounts.png',
        fr: '/illustrations/upsell/fr/multi-accounts.png',
        it: '/illustrations/upsell/it/multi-accounts.png',
      },
    },
    transactionsReview: {
      // @ts-expect-error
      title: this.intl.t('upsell.bundle-bookkeeping.transactions-review.title'),
      // @ts-expect-error
      description: this.intl.t('upsell.bundle-bookkeeping.transactions-review.text'),
      img: {
        de: '/illustrations/upsell/de/transactions-review.png',
        en: '/illustrations/upsell/en/transactions-review.png',
        es: '/illustrations/upsell/es/transactions-review.png',
        fr: '/illustrations/upsell/fr/transactions-review.png',
        it: '/illustrations/upsell/it/transactions-review.png',
      },
    },
    supplierInvoices: {
      // @ts-expect-error
      title: this.intl.t('upsell.bundle-bookkeeping.supplier-invoices.title'),
      // @ts-expect-error
      description: this.intl.t('upsell.bundle-bookkeeping.supplier-invoices.subtitle'),
      img: {
        de: '/illustrations/upsell/de/supplier-invoices.png',
        en: '/illustrations/upsell/en/supplier-invoices.png',
        es: '/illustrations/upsell/es/supplier-invoices.png',
        fr: '/illustrations/upsell/fr/supplier-invoices.png',
        it: '/illustrations/upsell/it/supplier-invoices.png',
      },
    },
    customExportTemplates: {
      // @ts-expect-error
      title: this.intl.t('upsell.bundle-bookkeeping.exports.title'),
      // @ts-expect-error
      description: this.intl.t('upsell.bundle-bookkeeping.exports.subtitle'),
      img: {
        de: '/illustrations/upsell/de/custom-export-templates.png',
        en: '/illustrations/upsell/en/custom-export-templates.png',
        es: '/illustrations/upsell/es/custom-export-templates.png',
        fr: '/illustrations/upsell/fr/custom-export-templates.png',
        it: '/illustrations/upsell/it/custom-export-templates.png',
      },
    },
  };

  get upsellTrigger() {
    // @ts-expect-error
    return this.args.data?.upsellTrigger ?? 'default';
  }

  get slides() {
    let lastFeatureToDisplay =
      this.abilities.can('view supplierInvoice') && this.abilities.cannot('access supplierInvoice')
        ? 'supplierInvoices'
        : 'transactionsReview';

    // @ts-expect-error
    return featuresOrderByTrigger(lastFeatureToDisplay)[this.upsellTrigger].map(orderedKey => {
      // @ts-expect-error
      let { title, description, img, soon } = this.localSlides[orderedKey];
      return {
        title,
        description,
        img: img[this.localeManager.locale],
        soon,
      };
    });
  }

  @action
  onClick() {
    let { code: price_plan } = this.subscriptionManager.currentPricePlan;
    let { role } = this.organizationManager.membership;

    this.segment.track('upsell_bundle_page_clicked', {
      // @ts-expect-error
      empty_state_name: this.args.data.name,
      price_plan,
      role,
    });

    // @ts-expect-error
    if (this.args.transitionToNext) {
      // @ts-expect-error
      this.args.transitionToNext();
    } else {
      this.flowLinkManager.transitionTo({
        name: 'subscription-change',
        stepId: 'discover-bookkeeping',
        queryParams: { upsellTrigger: this.upsellTrigger },
      });
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Discover::Bundle::Bookkeeping': typeof DiscoverBundleBookkeepingComponent;
  }
}
