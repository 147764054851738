/* import __COLOCATED_TEMPLATE__ from './q-page-selector.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

interface QPageSelectorSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class QPageSelector extends Component<QPageSelectorSignature> {
  perPageOptions = [25, 50, 100];

  get previousAvailable() {
    // @ts-expect-error
    return this.args.currentPage > 1;
  }

  get nextAvailable() {
    // @ts-expect-error
    return this.args.currentPage < this.args.totalPages;
  }

  get summary() {
    // @ts-expect-error
    let currentPage = this.args.currentPage;
    // @ts-expect-error
    let perPage = this.args.perPage;
    // @ts-expect-error
    let totalCount = this.args.totalCount;
    let offset = currentPage * perPage;
    let end = Math.min(offset, totalCount);
    let start = totalCount && offset - perPage + 1;

    // @ts-expect-error
    return `${start}-${end} ${this.args.paginationLabel ?? 'of'} ${totalCount}`;
  }

  @action
  handlePrevious() {
    if (this.previousAvailable) {
      // @ts-expect-error
      this.args.changePage(this.args.currentPage - 1);
    }
  }

  @action
  handleNext() {
    if (this.nextAvailable) {
      // @ts-expect-error
      this.args.changePage(this.args.currentPage + 1);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    QPageSelector: typeof QPageSelector;
  }
}
