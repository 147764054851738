/* import __COLOCATED_TEMPLATE__ from './confirm-pin.hbs'; */
import { InvalidError } from '@ember-data/adapter/error';
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

// @ts-expect-error
import { hasMFAError } from '@qonto/qonto-sca/utils/mfa-error';
import { CardsInfo } from '@repo/encryption/hpke';
import { dropTask } from 'ember-concurrency';

import { CARD_LEVELS_TRACKING } from 'qonto/constants/cards';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';

interface FlowsCardsConfirmPinSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class FlowsCardsConfirmPin extends Component<FlowsCardsConfirmPinSignature> {
  @service declare cardsManager: Services['cardsManager'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare intl: Services['intl'];
  @service declare segment: Services['segment'];
  @service declare sensitiveActions: Services['sensitiveActions'];
  @service declare sentry: Services['sentry'];
  @service declare organizationManager: Services['organizationManager'];

  constructor(owner: unknown, args: FlowsCardsConfirmPinSignature['Args']) {
    super(owner, args);

    // @ts-expect-error
    this.args.context.card.pinConfirmation = null;
    this.clearInputError();
  }

  @tracked pinConfirmationError = '';

  get isPinInvalid() {
    return this.pinConfirmationError !== '';
  }

  @action
  // @ts-expect-error
  pinConfirmationChanged(value) {
    // @ts-expect-error
    let { card } = this.args.context;
    card.pinConfirmation = value;

    this.clearInputError();
    this.validatePin(card);
  }

  // @ts-expect-error
  validatePin(card) {
    if (!this.isNumber(card.pinConfirmation)) {
      this.pinConfirmationError = this.intl.t('validations.errors.pin_not_a_number');
    } else if (!card.pinConfirmation || card.pinConfirmation.length < 4) {
      this.pinConfirmationError = this.intl.t('validations.errors.pin_invalid_length');
    } else if (card.pinConfirmation !== card.pin) {
      this.pinConfirmationError = this.intl.t('validations.errors.pin_do_not_match');
    }
  }

  submitPinTask = dropTask(async providerSaveCardTask => {
    // @ts-expect-error
    let { context, transitionToNext } = this.args;
    let { card, flowTrackingOrigin, isCardUnderCreation, isOnboardingFlow, isResetPin } = context;

    this.validatePin(card);

    if (this.isPinInvalid || this.saveCardPinTask.isRunning || this.saveQcpCardPinTask.isRunning)
      return;

    if (isCardUnderCreation) {
      let payload = {
        card_type: CARD_LEVELS_TRACKING[card.cardLevel],
        origin: flowTrackingOrigin,
      };
      let eventPayload = isOnboardingFlow ? { ...payload, card_holder: 'self' } : payload;
      this.segment.track('cards_order_pin_submitted', eventPayload);

      if (isOnboardingFlow) {
        // the error is handled inside providerSaveCardTask
        await providerSaveCardTask.perform(context, transitionToNext);
        this.organizationManager.flagMembershipOnboarded();
      } else {
        transitionToNext();
      }
    } else {
      this.segment.track(
        isResetPin ? 'cards_side_panel_pin_reset_pin_chosen' : 'cards_order_cardholder_pin_chosen'
      );

      try {
        if (card.isQcp) {
          let encryptedPin = await this.cardsManager.encryptPin({
            pin: card.pin,
            plainTextAad: card.id,
            plainTextInfo: CardsInfo.SetPin,
          });

          // @ts-expect-error
          await this.sensitiveActions.runTask.perform(this.saveQcpCardPinTask, {
            card,
            encryptedPin,
            isResetPin,
          });
        } else {
          // @ts-expect-error
          await this.sensitiveActions.runTask.perform(this.saveCardPinTask, { card, isResetPin });
        }
      } catch (error) {
        this.#handlePinError(error);
      }
    }
  });

  saveCardPinTask = dropTask(async ({ card, isResetPin }) => {
    try {
      await card.setPin(card.pinSet);
      this.#handleNext(isResetPin);
    } catch (error) {
      // errors are not thrown above sensitiveActions.runTask.perform()
      this.#handlePinError(error);
    }
  });

  saveQcpCardPinTask = dropTask(async ({ card, encryptedPin, isResetPin }) => {
    try {
      await this.cardsManager.saveEncryptedPinTask.perform({
        card,
        encryptedPin,
        isResetPin,
      });
      this.#handleNext(isResetPin);
    } catch (error) {
      // errors are not thrown above sensitiveActions.runTask.perform()
      this.#handlePinError(error);
    }
  });

  // @ts-expect-error
  isNumber(pin) {
    return !pin || /^[0-9]+$/.test(pin);
  }

  clearInputError() {
    this.pinConfirmationError = '';
  }

  // @ts-expect-error
  #handleNext(isResetPin) {
    this.segment.track(
      isResetPin
        ? 'cards_side_panel_pin_reset_pin_submitted'
        : 'cards_order_cardholder_pin_submitted'
    );
    // @ts-expect-error
    this.args.transitionToNext();
  }

  // @ts-expect-error
  #handlePinError(error) {
    if (hasMFAError(error?.errors)) {
      throw error;
    }

    let errorInfo = ErrorInfo.for(error);
    if (errorInfo.shouldSendToSentry) {
      this.sentry.captureException(error);
    }

    if (error instanceof InvalidError) {
      // @ts-expect-error
      this.args.context.pinResetInProgress = true;
      // @ts-expect-error
      this.args.transitionToNext();
    } else {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Cards::ConfirmPin': typeof FlowsCardsConfirmPin;
  }
}
