export default {
  "card": "cDP",
  "header": "cDl",
  "avatar": "cDX",
  "title": "cDC",
  "subtitle": "cDk",
  "label": "cDJ",
  "item": "cDs body-2",
  "header-icon": "cDF",
  "dropdown-menu": "cDg",
  "menu-item": "cDD body-2",
  "delete": "cDV"
};
