/* import __COLOCATED_TEMPLATE__ from './add-client.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';

// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';

interface FlowsDirectDebitCollectionsAddClientSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsDirectDebitCollectionsAddClientComponent extends Component<FlowsDirectDebitCollectionsAddClientSignature> {
  @service declare store: Services['store'];
  @service declare sentry: Services['sentry'];
  @service declare intl: Services['intl'];
  @service declare segment: Services['segment'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];

  @tracked isValidationEnabled = false;

  get client() {
    // @ts-expect-error
    return this.args.context.newClient;
  }

  onSaveClientTask = dropTask(async newClient => {
    this.isValidationEnabled = true;

    newClient.validate({}, false);

    if (newClient.validations.isValid) {
      try {
        await newClient.save();
      } catch (error) {
        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }
        this.toastFlashMessages.toastError(this.intl.t('toasts.errors.try-later'));

        return;
      }

      // @ts-expect-error
      this.args.context.directDebitSubscription.client = newClient;

      // @ts-expect-error
      this.args.transitionToNext();

      this.segment.track('incoming-direct-debit-flow_client_selected', {
        client_address: Boolean(newClient.address),
        client_selection: 'new',
      });
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::DirectDebitCollections::Client::AddClient': typeof FlowsDirectDebitCollectionsAddClientComponent;
  }
}
