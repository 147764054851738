/* import __COLOCATED_TEMPLATE__ from './form.hbs'; */
/* eslint-disable ember/no-computed-properties-in-native-classes */
import EmberObject, { action } from '@ember/object';
import { reads } from '@ember/object/computed';
import { getOwner } from '@ember/owner';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import dayjs from 'dayjs';
import { dropTask, waitForQueue } from 'ember-concurrency';
// @ts-expect-error
import { buildValidations, validator } from 'ember-cp-validations';

// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';
// @ts-expect-error
import { deserializeExpressions, serializeExpressions } from 'qonto/utils/expressions-serializer';
import { ignoreCancelation } from 'qonto/utils/ignore-error';
// @ts-expect-error
import { getFilterGroupExpressions } from 'qonto/utils/search-preset';

const Validations = buildValidations({
  name: {
    validators: [
      validator('presence', {
        presence: true,
        disabled: reads('model.skipNameValidations'),
        messageKey: 'transactions.filters.view-name.error-message.empty-field',
      }),
      validator('length', {
        max: 50,
        messageKey: 'transactions.filters.view-name.error-message.limit-char-count',
      }),
    ],
  },
});

// @ts-expect-error
const isAmountProperty = property => property === 'amount';
// @ts-expect-error
const isDateProperty = property => ['emitted_at', 'updated_at', 'settled_at'].includes(property);
// @ts-expect-error
const isRange = (property, values) => isDateProperty(property) && values.length === 2;

class FiltersExpression {
  @tracked property;
  @tracked operator;
  @tracked values = [];
  @tracked operatorType;
  @tracked showValidations = false;

  // @ts-expect-error
  constructor(property, operator, values) {
    this.property = property;
    this.operator = operator;
    this.values = values;

    if (isRange(property, values) && !dayjs(values[0]).isSame(dayjs(values[1]), 'day')) {
      this.operatorType = 'range';
    }
  }

  get isDateProperty() {
    return isDateProperty(this.property);
  }

  get dateRangeFields() {
    let [dateFrom, dateTo] = this.values;

    return {
      dateFrom: {
        value: dateFrom,
        isInvalid: !dateFrom,
        showErrors: !dateFrom && this.showValidations,
      },
      dateTo: {
        value: dateTo,
        isInvalid: !dateTo,
        showErrors: !dateTo && this.showValidations,
      },
    };
  }

  get hasInvalidValues() {
    if (isAmountProperty(this.property)) {
      return !this.values.length || this.values[0] === '';
    }

    return !this.values.length && !['exists', 'not_exists'].includes(this.operator);
  }

  get isInvalid() {
    if (isRange(this.property, this.values)) {
      return this.dateRangeFields.dateFrom.isInvalid || this.dateRangeFields.dateTo.isInvalid;
    }

    return !this.property || this.hasInvalidValues;
  }

  get showPropertyErrors() {
    return !this.property && this.showValidations;
  }

  get showValuesErrors() {
    return this.hasInvalidValues && this.showValidations && !isDateProperty(this.property);
  }
}

class FiltersGroup {
  @tracked conditional;
  @tracked expressions;

  // @ts-expect-error
  constructor(conditional, expressions) {
    this.conditional = conditional;
    this.expressions = expressions;
  }
}

// @ts-expect-error
class FiltersPreset extends EmberObject.extend(Validations) {
  // @ts-expect-error
  @tracked id;
  // @ts-expect-error
  @tracked conditional;
  // @ts-expect-error
  @tracked groups;
  // @ts-expect-error
  @tracked name;
  @tracked showValidations = false;
  @tracked skipNameValidations = true;
}

export { FiltersExpression, FiltersGroup, FiltersPreset };

const emptyQuery = {
  filter_group: {
    conditional: 'and',
    expressions: [{ property: null, operator: null, values: [] }],
  },
};

interface FiltersPresetFormSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLFormElement;
}

export default class FiltersPresetFormComponent extends Component<FiltersPresetFormSignature> {
  @service declare intl: Services['intl'];
  @service declare segment: Services['segment'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare sentry: Services['sentry'];

  // @ts-expect-error
  @tracked formPreset = this.createFormPreset(this.args.formData);
  @tracked shouldCacheFilters = true;
  @tracked clearedFilters = false;

  constructor(owner: unknown, args: FiltersPresetFormSignature['Args']) {
    super(owner, args);
    // @ts-expect-error
    if (!this.args.mainTitle && !this.args.title) {
      this.setAutoFocusTask.perform().catch(ignoreCancelation);
    }
  }
  willDestroy() {
    // @ts-expect-error
    super.willDestroy(...arguments);

    if (this.shouldCacheFilters && !this.clearedFilters) {
      let group = this.buildPresetGroup();
      // @ts-expect-error
      this.args.refreshCache?.(group, this.formPreset.name);
    }
  }

  get inputFormElement() {
    return document.getElementById('form-input');
  }

  setAutoFocusTask = dropTask(async () => {
    await waitForQueue('afterRender');

    try {
      // @ts-expect-error
      if (!this.inputFormElement.value) {
        // @ts-expect-error
        this.inputFormElement.focus();
      }
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  });

  get hasTitle() {
    // @ts-expect-error
    return Boolean(this.args.title) || Boolean(this.args.mainTitle);
  }

  get skipNameValidations() {
    // @ts-expect-error
    return this.args.skipNameValidations ?? false;
  }

  get cacheFiltersOnSubmit() {
    // @ts-expect-error
    return this.args.cacheFiltersOnSubmit ?? false;
  }

  get selectedConditional() {
    return (
      this.conditionals.find(it => it.key === this.formPreset.conditional) || this.conditionals[0]
    );
  }

  get isRemoveDisabled() {
    // @ts-expect-error
    return this.formPreset.groups.reduce((acc, group) => acc + group.expressions.length, 0) === 1;
  }

  get conditionals() {
    return [
      { key: 'and', label: this.intl.t('transactions.filters.conditionals.and') },
      { key: 'or', label: this.intl.t('transactions.filters.conditionals.or') },
    ];
  }

  get isFormPristine() {
    let { groups } = this.formPreset;
    let isFirstExpressionDirty = Boolean(groups[0].expressions[0].property);

    return !(groups[0].expressions.length > 1 || groups.length > 1 || isFirstExpressionDirty);
  }

  get isResetDisabled() {
    return this.isFormPristine || this.onSubmitTask.isRunning;
  }

  get labelLists() {
    // The `filter()` should not be necessary, but Ember Data 3.28 apparently
    // started to fill `hasMany` relationships with `null` values when the app
    // is torn down, which is causing some of our tests to throw up. The
    // `filter()` call ensures that these `null` values are filtered out.
    return this.organizationManager.organization.labelLists.filter(Boolean);
  }

  get isApplyDisabled() {
    return !this.clearedFilters && (this.isFormPristine || this.onSubmitTask.isRunning);
  }

  @action
  // @ts-expect-error
  updatePresetConditional({ key }) {
    this.formPreset.conditional = key;
  }

  @action
  // @ts-expect-error
  updateGroupConditional(group, conditional) {
    group.conditional = conditional;
  }

  @action
  // @ts-expect-error
  updateExpression(expression, property, operator, values, operatorType) {
    expression.property = property;
    expression.operator = operator;
    expression.values = values;
    expression.operatorType = operatorType;
    expression.showValidations = false;
  }

  @action
  // @ts-expect-error
  addExpression(group) {
    // @ts-expect-error
    group.expressions = [...group.expressions, new FiltersExpression()];
  }

  @action
  // @ts-expect-error
  removeExpression(group, index, groupIndex) {
    if (!this.isRemoveDisabled) {
      group.expressions.splice(index, 1);
      group.expressions = [...group.expressions];

      if (!group.expressions.length) {
        this.formPreset.groups.splice(groupIndex, 1);
        this.formPreset.groups = [...this.formPreset.groups];
      }
    }
  }

  addGroupTask = dropTask(async () => {
    let { groups, conditional } = this.formPreset;
    // @ts-expect-error
    let expressions = [new FiltersExpression()];
    this.formPreset.groups = [...groups, new FiltersGroup(conditional, expressions)];

    await waitForQueue('afterRender');
    this.scrollToLastChild();
  });

  @action
  scrollToLastChild() {
    try {
      let groupCount = `${this.formPreset.groups.length - 1}`;
      let filtersGroup = document.getElementById(`form-group-${groupCount}`);
      // @ts-expect-error
      filtersGroup.scrollIntoView({ behavior: 'smooth' });
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  }

  @action
  clearFilters() {
    this.formPreset = this.createFormPreset();

    // @ts-expect-error
    if (this.args.formData) {
      // @ts-expect-error
      this.args.onUpdate?.();
      this.clearedFilters = true;
    }
  }

  onUpdateTask = dropTask(async () => {
    if (!this.isFormPristine) {
      let { isValid } = await this.validateForm();

      if (isValid) {
        let group = this.buildPresetGroup();
        // @ts-expect-error
        await this.args.onUpdate?.(group, this.formPreset.name);
      }
    }
  });

  onSubmitTask = dropTask(async () => {
    let { isValid } = await this.validateForm();
    let canSubmit = isValid || !this.isApplyDisabled;

    if (canSubmit) {
      let group = undefined;

      if (isValid) {
        group = this.buildPresetGroup();
        this.shouldCacheFilters = this.cacheFiltersOnSubmit;
      }

      // @ts-expect-error
      if (!this.args.isTrackingDisabled) {
        this.trackFilters();
      }
      // eslint-disable-next-line no-unused-expressions
      this.clearedFilters
        ? // @ts-expect-error
          await this.args.clearFilters?.()
        : // @ts-expect-error
          await this.args.onSubmit(group, this.formPreset.name);
    }
  });

  @action
  cancelFilters() {
    this.shouldCacheFilters = false;
    // @ts-expect-error
    this.args.cancelFilters();
  }

  @action
  // @ts-expect-error
  valueChanged(event) {
    this.formPreset.name = this.getLocalizedName(event.target.value);
    this.formPreset.showValidations = false;
  }

  // @ts-expect-error
  getLocalizedName(name) {
    let translations = {
      missing_receipts: this.intl.t('transactions.filters.presets.items.missing_receipts'),
      settled_last_month: this.intl.t('transactions.filters.presets.items.settled_last_month'),
      to_verify: this.intl.t('transactions.filters.presets.items.to_verify'),
    };

    // @ts-expect-error
    return translations[name] || name;
  }

  buildPresetGroup() {
    let { conditional, groups } = this.formPreset;
    let expressions = serializeExpressions(groups, this.labelLists);

    return { conditional, expressions };
  }

  // @ts-expect-error
  createFormPreset({ id, query, name } = {}) {
    let { filter_group } = query || emptyQuery;
    let { conditional, expressions } = filter_group;
    let expressionsGroup = deserializeExpressions(conditional, expressions, this.labelLists);

    // @ts-expect-error
    let groups = expressionsGroup.map(({ conditional, expressions }) => {
      let filterExpressions = expressions.map(
        // @ts-expect-error
        ({ property, operator, values }) => new FiltersExpression(property, operator, values)
      );

      return new FiltersGroup(conditional, filterExpressions);
    });

    name = this.getLocalizedName(name);

    // @ts-expect-error
    let preset = FiltersPreset.create(getOwner(this).ownerInjection());

    preset.setProperties({
      id,
      conditional,
      groups,
      name,
      skipNameValidations: this.skipNameValidations,
    });

    return preset;
  }

  trackFilters() {
    let filtersProperties = getFilterGroupExpressions(this.formPreset.groups, this.labelLists);
    let isOrConditionalUsed =
      this.formPreset.conditional === 'or' ||
      // @ts-expect-error
      this.formPreset.groups.some(({ conditional }) => conditional === 'or');

    this.segment.track('history_filter_apply_button_clicked', {
      filter_types_count: filtersProperties.length,
      or_conditional_used: isOrConditionalUsed,
      filter_types: filtersProperties,
    });
  }

  async validateForm() {
    let { validations } = await this.formPreset.validate();

    // @ts-expect-error
    let hasInvalidExpressions = this.formPreset.groups.some(group =>
      // @ts-expect-error
      group.expressions.find(expression => expression.isInvalid)
    );

    let isFormInvalid = hasInvalidExpressions || validations.isInvalid;

    if (isFormInvalid) {
      if (hasInvalidExpressions) {
        // @ts-expect-error
        this.formPreset.groups.forEach(group =>
          // @ts-expect-error
          group.expressions.forEach(expression => (expression.showValidations = true))
        );
      }

      if (validations.isInvalid) {
        this.formPreset.showValidations = true;
      }
    }

    return { isValid: !isFormInvalid };
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Filters::Preset::Form': typeof FiltersPresetFormComponent;
  }
}
