/* import __COLOCATED_TEMPLATE__ from './copy-to-clipboard.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

interface CopyToClipboardSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class CopyToClipboard extends Component<CopyToClipboardSignature> {
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare segment: Services['segment'];

  @action
  onCopySuccess() {
    // @ts-expect-error
    let { toastMessage, trackEvent, trackParams } = this.args;
    this.toastFlashMessages.toastInfo(toastMessage);

    if (trackEvent) {
      this.segment.track(trackEvent, trackParams);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    CopyToClipboard: typeof CopyToClipboard;
  }
}
