export default {
  "insight-pill": "qMn",
  "interactive": "qMi",
  "focus": "qMA",
  "subtitle": "qMY caption",
  "hidden": "qMh",
  "missing-amounts-warning": "qMf",
  "totals": "qMK",
  "counter": "qMG title-3",
  "loading": "qMr",
  "error": "qMb",
  "title": "qMy title-4"
};
