import { useEffect, useRef, type ReactNode } from 'react';
import { useFlags } from '@qonto/react-migration-toolkit/react/hooks';
import { useIntl } from 'react-intl';
import { EmptyStatesInform } from '@repo/domain-kit/pricing';
import { useQueryClient } from '@tanstack/react-query';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import {
  useSearchTransactions,
  SEARCH_TRANSACTIONS_QUERY_KEY,
} from 'qonto/react/hooks/use-search-transactions';
import { Pagination } from 'qonto/react/components/transactions/table/pagination';
import { useFetchLabelLists } from 'qonto/react/hooks/use-fetch-label-lists';
import { BulkSelectionProvider } from 'qonto/react/contexts/bulk-selection-context';
import type { SearchTransactionsMeta, Transaction, TransactionsSearch } from 'qonto/react/graphql';
import { RouteContextProvider } from 'qonto/react/contexts/route-context';
import type ModalsService from 'ember-promise-modals/services';
import { useThemedAssetPath } from 'qonto/react/hooks/use-themed-asset-path';
import { QPageSelectorPlaceholder } from 'qonto/react/components/q-page-selector/placeholder';
import { DEFAULT_SORT_PARAM } from 'qonto/react/constants';
import { DataTable } from '../../table-v2/data-table';
import { generateColumns } from './columns';
import styles from './styles.strict-module.css';
import { BulkSidePanel } from './bulk-side-panel';
import { TransactionTablePlaceholder } from './placeholder/placeholder';

interface TransactionsTableProps {
  bankAccountsQPValue?: string;
  organizationId: string;
  page: number;
  perPage: number;
  searchQuery: string;
  filterGroup: TransactionsSearch['filterGroup'];
  updatePage: (page: number) => void;
  updatePerPage: (perPage: number) => void;
  updateMeta: (meta: SearchTransactionsMeta) => void;
  highlightItem: (id: string) => void;
  selectedItemIds?: string[];
  selectItem: (id: string) => void;
  selectAll: () => void;
  highlightedItemId?: string;
  openModal: ModalsService['open'];
  didLoadItems?: (items: { transactions: Transaction[]; meta: SearchTransactionsMeta }) => void;
  sortBy?: string;
  handleSortBy?: (sortBy: string | undefined) => void;
}

export function TransactionsTable({
  bankAccountsQPValue,
  filterGroup,
  organizationId,
  page,
  perPage,
  updatePage,
  updatePerPage,
  searchQuery = '',
  updateMeta,
  highlightItem,
  highlightedItemId,
  selectedItemIds = [],
  selectItem,
  selectAll,
  openModal,
  didLoadItems,
  handleSortBy,
  sortBy,
}: TransactionsTableProps): ReactNode {
  const { formatMessage } = useIntl();
  const lottieSrc = useThemedAssetPath('/lotties/empty-state/search-no-result.json');
  const abilities = useEmberService('abilities');
  const { featureBooleanImproveEmberDataGraphqlBinding } = useFlags();

  /*feature--boolean-improve-ember-data-graphql-binding*/
  const isImproveEmberGraphQLBindingFF = Boolean(featureBooleanImproveEmberDataGraphqlBinding);

  const querySort = sortBy || DEFAULT_SORT_PARAM;
  const [sortProperty, sortDirection] = querySort.split(':');

  const pagination = { page, perPage };
  const query: TransactionsSearch = {
    search: searchQuery,
    sort: {
      property: sortProperty,
      direction: sortDirection as 'asc' | 'desc',
    },
    ...(bankAccountsQPValue ? { bankAccountIds: bankAccountsQPValue.split(',') } : {}),
    filterGroup,
  };
  const {
    data: labelLists,
    isLoading: isLabelListsLoading,
    isError: isLabelListsError,
  } = useFetchLabelLists(organizationId);

  const queryClient = useQueryClient();
  const { data, isLoading, isError } = useSearchTransactions(updateMeta, {
    query,
    pagination,
  });

  /**
   * This is a hack to make sure the data is synced between the react component and the ember controller
   * @see https://tkdodo.eu/blog/breaking-react-querys-api-on-purpose#ondatachanged
   */
  useEffect(() => {
    if (data && didLoadItems) {
      didLoadItems({ transactions: data.transactions, meta: data.meta });
    }
  }, [data]);

  const highlightedItemRef = useRef<string | undefined>();

  useEffect(() => {
    if (!isImproveEmberGraphQLBindingFF) {
      // if the highlighted item is undefined, it means the sidepanel is closed
      if (typeof highlightedItemId === 'undefined' && highlightedItemRef.current) {
        // Refetch the transactions
        void queryClient.invalidateQueries({
          queryKey: [SEARCH_TRANSACTIONS_QUERY_KEY],
        });
      }
      highlightedItemRef.current = highlightedItemId;
    }
  }, [highlightedItemId, queryClient, isImproveEmberGraphQLBindingFF]);

  if (isLoading || isLabelListsLoading) {
    return (
      <div className={styles['loading-wrapper']}>
        <table className={styles['table-loading']} data-testid="transactions-table-loading">
          <tbody>
            <TransactionTablePlaceholder isTableHeader />
            {Array.from({ length: 8 }, (_, index) => (
              <TransactionTablePlaceholder key={index} />
            ))}
          </tbody>
        </table>
        <div className={styles['empty-loading']} />
        <div className={styles['pagination-loading']}>
          <QPageSelectorPlaceholder />
        </div>
      </div>
    );
  }

  if (isError || isLabelListsError) {
    return <div data-testid="transactions-table-error">Error fetching data</div>;
  }

  if (data && labelLists) {
    const { columns, defaultColumnOrder } = generateColumns(labelLists, formatMessage, abilities);
    const showEmptyState = !data.transactions.length;

    return (
      <RouteContextProvider routeContext={{ openModal, highlightItem }}>
        <BulkSelectionProvider
          selectAll={selectAll}
          selectItem={selectItem}
          selectedItemIds={selectedItemIds}
        >
          <div className={styles.main}>
            {showEmptyState ? (
              <EmptyStatesInform
                data-testid="transactions-empty-state"
                lottieSrc={lottieSrc}
                subtitle={formatMessage({ id: 'empty-states.system.inform.i2.subtitle' })}
                title={formatMessage({ id: 'empty-states.system.inform.i2.title' })}
              />
            ) : (
              <div className={styles.container} data-testid="transactions-table">
                <DataTable
                  columns={columns}
                  data={data.transactions}
                  defaultColumnOrder={defaultColumnOrder}
                  handleSortBy={handleSortBy}
                  sortBy={sortBy}
                />
              </div>
            )}
            {(data.meta.totalCount > data.meta.perPage || data.meta.totalCount > 25) && (
              <Pagination
                responseMeta={data.meta}
                updatePage={updatePage}
                updatePerPage={updatePerPage}
              />
            )}
            <BulkSidePanel labelLists={labelLists} organizationId={organizationId} />
          </div>
        </BulkSelectionProvider>
      </RouteContextProvider>
    );
  }

  return null;
}
