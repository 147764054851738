export default {
  "wrapper": "ol",
  "legend": "oX",
  "input": "oC",
  "table": "ok",
  "footer": "oJ",
  "footer-total-m": "os",
  "footer-total-n": "oF",
  "footer-balance": "og"
};
