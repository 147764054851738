export default {
  "wrapper": "che",
  "tabs": "chB",
  "tabs-nav": "chE",
  "tabs-panel-wrapper": "chd",
  "list-head": "chI",
  "list": "cht",
  "footer": "chM",
  "reward-amount": "chP",
  "earned": "chl",
  "pending": "chX",
  "empty-state-wrapper": "chC",
  "empty-state": "chk",
  "illustration": "chJ",
  "error-wrapper": "chs",
  "error-illustration": "chF",
  "error-description": "chg",
  "spinner": "chD",
  "total-earned": "chV"
};
