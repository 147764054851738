/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { and } from 'macro-decorators';

// @ts-expect-error
import cmc7Splitter from 'qonto/utils/cmc7-splitter';

interface CheckSidebarHeaderSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class CheckSidebarHeader extends Component<CheckSidebarHeaderSignature> {
  @service declare intl: Services['intl'];

  @and('args.check.bankAccount.name', 'args.check.bankAccount.organization.hasMultipleBankAccounts')
  // @ts-expect-error
  showAccountName;

  @action
  // @ts-expect-error
  closeSidebar(checkId) {
    // @ts-expect-error
    this.args.closeSidebar();
    // @ts-expect-error
    document.querySelector(`[data-item-id="${checkId}"]`)?.focus();
  }

  get checkCmc7() {
    // @ts-expect-error
    return cmc7Splitter(this.args.check.cmc7, ' ');
  }

  get checkHeaderTypeWording() {
    // @ts-expect-error
    let { status } = this.args.check;

    switch (status) {
      case 'canceled':
        return this.intl.t('checks.sidebar.title.canceled');
      case 'declined':
        return this.intl.t('checks.sidebar.title.declined');
      case 'pending':
        return this.intl.t('checks.sidebar.title.pending');
      case 'received':
        return this.intl.t('checks.sidebar.title.received');
      case 'refunded':
        return this.intl.t('checks.sidebar.title.refunded');
      case 'validated':
        return this.intl.t('checks.sidebar.title.validated');
      default:
        return '';
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'CheckSidebar::Header': typeof CheckSidebarHeader;
  }
}
