import ApplicationAbility from './application';

export default class OverviewAbility extends ApplicationAbility {
  get canView() {
    return Boolean(this.permissions.statistics.read);
  }

  get canNavigate() {
    return this.canView;
  }

  get canAccessAdvanced() {
    return Boolean(this.pricePlanFeatures.advancedDashboard);
  }

  get canUpdate() {
    return Boolean(this.permissions.subscriptions.update);
  }

  get canAccessOrUpdate() {
    return (this.canAccessAdvanced || this.canUpdate) && !this.subscriptionManager.shouldHideUpsell;
  }

  get canHaveTeams() {
    return Boolean(this.pricePlanFeatures.teamManagement);
  }

  get canGroupByCustomLabels() {
    return Boolean(this.pricePlanFeatures.customLabels);
  }
}
