export default {
  "referral-invite-new": "cYI",
  "referral": "cYt",
  "center": "cYM",
  "illustration": "cYP",
  "share-referral": "cYl",
  "choice": "cYX",
  "line": "cYC",
  "email": "cYk",
  "email-input": "cYJ",
  "input-field--error": "cYs",
  "error-message": "cYF",
  "external-link-icon": "cYg"
};
