import type { ReactElement, ReactNode } from 'react';
import { useEffect, useRef } from 'react';
import cx from 'clsx';
import { CloseIcon } from '../../assets/icons';
import { Button } from '../button';
import styles from './top-banner.strict-module.css';
import { IconInfoRoundedOutlined } from './icons/icon-info-rounded-outlined';
import { IconWarningSignOutlined } from './icons/icon-warning-sign-outlined';
import { IconErrorOutlined } from './icons/icon-error-outlined';

interface TopBannerProps {
  type?: 'info' | 'warning' | 'error';
  onClose: () => void;
  children: ReactNode;
  autoFocus?: boolean;
  className?: string;
  message?: string;
}

export function TopBanner({
  type = 'info',
  onClose,
  children,
  autoFocus = true,
  className,
  message,
  ...props
}: TopBannerProps): ReactElement {
  const bannerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (autoFocus && bannerRef.current) {
      bannerRef.current.focus();
    }
  }, [autoFocus]);

  const iconMap: Record<string, ReactNode> = {
    info: <IconInfoRoundedOutlined />,
    warning: <IconWarningSignOutlined />,
    error: <IconErrorOutlined />,
  };

  const icon = iconMap[type];

  const isString = typeof message === 'string';

  return (
    <div
      aria-labelledby="top-banner-message"
      className={cx(styles.topbar, styles[type], className)}
      data-test-topbanner
      data-test-topbanner-type={type}
      ref={bannerRef}
      role="banner"
      {...props}
    >
      <div className={styles.wrapper}>
        <span className={styles.icon} data-test-topbanner-icon={type}>
          {icon}
        </span>
        {Boolean(isString) && (
          <p
            className={cx(styles.message, 'body-1')}
            dangerouslySetInnerHTML={{ __html: String(message ?? '') }}
            data-test-topbanner-message
            id="top-banner-message"
          />
        )}
        {!isString && (
          <p
            className={cx(styles.message, 'body-1')}
            data-test-topbanner-message
            id="top-banner-message"
          >
            {message}
          </p>
        )}
        {children}
      </div>
      <Button
        aria-label="Close"
        className={styles.close}
        data-test-topbanner-close-button
        iconOnly
        onPress={onClose}
        type="button"
        variant="tertiary"
      >
        <CloseIcon aria-hidden />
      </Button>
    </div>
  );
}
