import { useEmberService, useFlags } from '@qonto/react-migration-toolkit/react/hooks';
import { type ReactElement, useEffect } from 'react';
import { FINANCING_TRANSFER_STATUS } from 'qonto/constants/financing';
import {
  Sidebar as BaseSidebar,
  type SidebarProps as BaseSidebarProps,
  type SidebarSlots,
} from 'qonto/react/components/sidebar';
import { TransactionHistory } from 'qonto/react/financing/components/pay-later/cockpit/sidebar/transaction-history';
import type { FinancingModel, IncludedTransaction } from 'qonto/react/models/financing';
import type { InstallmentModel } from 'qonto/react/models/installment';
import { FinancingStatement } from 'qonto/react/financing/components/pay-later/cockpit/sidebar/financing-statement';
import { FinancingDetails } from './financing-details';
import { Header } from './header';
import { ProcessingRepaymentInfo } from './processing-repayment-info';
import { FinancingInstallments } from './financing-installments';

interface SidebarProps extends BaseSidebarProps {
  financing: FinancingModel;
  installments: InstallmentModel[];
  transactions: IncludedTransaction[];
  isEarlyRepaymentLoading: boolean;
  isGenerateStatementLoading: boolean;
  repayBtnClickFn: () => void;
  generateStatementBtnClickFn: () => void;
}

function useSlots(
  repayBtnClickFn: () => void,
  generateStatementBtnClickFn: () => void,
  isEarlyRepaymentLoading: boolean,
  isGenerateStatementLoading: boolean,
  financing?: FinancingModel,
  installments?: InstallmentModel[],
  transactions?: IncludedTransaction[]
): SidebarSlots | undefined {
  const { featureBooleanPayLaterSidebarStatement } = useFlags();

  if (!financing) {
    return undefined;
  }

  const contents = [];

  if (financing.status === 'processing_repayment') {
    contents.push(<ProcessingRepaymentInfo key="processing-repayment-info" />);
  } else if (installments?.length) {
    contents.push(
      <FinancingInstallments
        financing={financing}
        installments={installments}
        isEarlyRepaymentLoading={isEarlyRepaymentLoading}
        key="financing-installments"
        onPressRepay={repayBtnClickFn}
      />
    );
  }

  contents.push(<FinancingDetails financing={financing} key="financing-details" />);

  if (
    [
      FINANCING_TRANSFER_STATUS.PROCESSING_REPAYMENT,
      FINANCING_TRANSFER_STATUS.COMPLETED,
      FINANCING_TRANSFER_STATUS.IN_PROGRESS,
    ].includes(financing.status)
  ) {
    if (transactions?.length) {
      contents.push(<TransactionHistory key="transaction-history" transactions={transactions} />);
    }

    if (featureBooleanPayLaterSidebarStatement) {
      contents.push(
        <FinancingStatement
          isButtonDisabled={FINANCING_TRANSFER_STATUS.PROCESSING_REPAYMENT === financing.status}
          isButtonLoading={isGenerateStatementLoading}
          key="financing-statement"
          onClickGenerate={generateStatementBtnClickFn}
        />
      );
    }
  }

  return {
    header: <Header financing={financing} />,
    contents,
  };
}

export function Sidebar({
  financing,
  installments,
  isOpened,
  onClose,
  repayBtnClickFn,
  generateStatementBtnClickFn,
  isEarlyRepaymentLoading,
  isGenerateStatementLoading,
  title,
  transactions,
}: SidebarProps): ReactElement {
  const segment = useEmberService('segment');
  const { featureBooleanPayLaterSidebarTransactions } = useFlags();
  const slots = useSlots(
    repayBtnClickFn,
    generateStatementBtnClickFn,
    isEarlyRepaymentLoading,
    isGenerateStatementLoading,
    financing,
    installments,
    featureBooleanPayLaterSidebarTransactions ? transactions : []
  );

  useEffect(() => {
    if (isOpened) {
      segment.track('pay-later_cockpit-financing-details_displayed');
    }
  }, [segment, isOpened]);

  return <BaseSidebar isOpened={isOpened} onClose={onClose} slots={slots} title={title} />;
}
