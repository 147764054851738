import Model, { attr, belongsTo } from '@ember-data/model';

import { equal } from 'macro-decorators';

import CURRENCIES from 'qonto/constants/currencies';

export default class FeeModel extends Model {
  @attr('number') declare amount: number;
  @attr('string', { defaultValue: CURRENCIES.default }) declare amountCurrency: string;
  @attr('string') declare feeType: string;
  @attr('string') declare reference: string;

  // @ts-expect-error
  @belongsTo('organization', { async: true, inverse: null }) organization;

  // @ts-expect-error
  @equal('feeType', 'physical_card') isPhysicalCard;
  // @ts-expect-error
  @equal('feeType', 'virtual_card') isVirtualCard;
  // @ts-expect-error
  @equal('feeType', 'sepa_inbound_transfer') isSepaInboundTransfer;
  // @ts-expect-error
  @equal('feeType', 'sepa_outbound_transfer') isSepaOutboundTransfer;
  // @ts-expect-error
  @equal('feeType', 'atm') isAtm;
  // @ts-expect-error
  @equal('feeType', 'fx_card') isFxCard;
  // @ts-expect-error
  @equal('feeType', 'fx_inbound_transfer') isFxInboundTransfer;
  // @ts-expect-error
  @equal('feeType', 'fx_outbound_transfer') isFxOutboundTransfer;
  // @ts-expect-error
  @equal('feeType', 'subscription') isSubscription;
  // @ts-expect-error
  @equal('feeType', 'physical_card_delivery') isPhysicalCardDelivery;
  // @ts-expect-error
  @equal('feeType', 'additional_user') isAdditionalUser;

  // Temporary until deletion
  isFee = true;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    fee: FeeModel;
  }
}
