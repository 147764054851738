export default {
  "wrapper": "qjB",
  "integrations": "qjE",
  "description": "qjd body-2",
  "api": "qjI flex flex-column",
  "api-container": "qjt flex large",
  "api-input": "qjM large",
  "api-btn": "qjP",
  "key-input": "qjl"
};
