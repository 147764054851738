/* import __COLOCATED_TEMPLATE__ from './fnci-result.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Disclaimer } from '@repo/design-system-kit';
import fade from 'ember-animated/transitions/fade';

interface ChecksFNCIResultSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class ChecksFNCIResult extends Component<ChecksFNCIResultSignature> {
  disclaimerBlock = Disclaimer.Block;

  @tracked isFNCIresultOpened = false;

  transition = fade;

  get isGreen() {
    // @ts-expect-error
    return this.args.result.color === 'green';
  }

  get isOrange() {
    // @ts-expect-error
    return this.args.result.color === 'orange';
  }

  get isRed() {
    // @ts-expect-error
    return this.args.result.color === 'red';
  }

  @action
  openFNCIresult() {
    this.isFNCIresultOpened = !this.isFNCIresultOpened;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Checks::FnciResult': typeof ChecksFNCIResult;
  }
}
