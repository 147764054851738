export default {
  "wrapper": "xU",
  "active": "xj",
  "expanded": "xW",
  "fade-in": "xu",
  "hidden": "wS",
  "visible": "wc",
  "item-list": "wq",
  "item-list-slide-down": "wZ",
  "dropdown-trigger": "wR overlay",
  "settings-dropdown-slide-down": "wQ",
  "responsive": "we",
  "is-selected": "wB",
  "is-active": "wE",
  "dropdown": "wd"
};
