/* import __COLOCATED_TEMPLATE__ from './insurance-claim-link.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface InsuranceClaimLinkSignature {
  Element: HTMLDivElement;
  Args: {};
  Blocks: {
    default: [];
  };
}

const InsuranceClaimLink = templateOnlyComponent<InsuranceClaimLinkSignature>();

export default InsuranceClaimLink;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InsuranceAssistance::InsuranceClaimLink': typeof InsuranceClaimLink;
  }
}
