export default {
  "refunds-total": "qIx",
  "switch-recurrence": "qIw",
  "flex-wrapper": "qIN",
  "promo-code": "qIv",
  "promo-code-total": "qIo",
  "others": "qIn",
  "others-total": "qIi",
  "subscription-detail": "qIA",
  "total-vat": "qIY",
  "fee-description": "qIh"
};
