/* import __COLOCATED_TEMPLATE__ from './table.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

interface ConnectionsInvoicesTableSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class ConnectionsInvoicesTableComponent extends Component<ConnectionsInvoicesTableSignature> {
  @service declare gmiSolutionInstanceManager: Services['gmiSolutionInstanceManager'];

  constructor(owner: unknown, args: ConnectionsInvoicesTableSignature['Args']) {
    super(owner, args);
    this.gmiSolutionInstanceManager.subscribe();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Connections::Invoices::Table': typeof ConnectionsInvoicesTableComponent;
  }
}
