export default {
  "modal": "LJ",
  "features": "Ls",
  "close-button": "LF",
  "header": "Lg title-2",
  "header-text": "LD body-2",
  "feature": "LV",
  "subheader": "LL title-4",
  "text": "LT body-2",
  "cta": "Lz",
  "container-img": "La",
  "img": "LH",
  "link": "LO"
};
