import { useCallback, type ReactNode } from 'react';
import cx from 'clsx';
import { Button } from '@repo/design-system-kit';
import { DialogTrigger } from 'react-aria-components';
import type { SortDirection } from '@tanstack/react-table';
import {
  IconChevronBottomOutlined,
  IconArrowTopOutlined,
  IconArrowBottomOutlined,
} from '@repo/monochrome-icons';
import { SORT_DIRECTION, SORT_QUERY_PARAM } from 'qonto/react/constants';
import { HeaderCellPopover } from '../popover';
import styles from './styles.strict-module.css';

interface PopoverTriggerProps {
  sortBy?: string;
  handleSortBy?: (sortBy: string | undefined) => void;
  columnId?: string;
}

export function PopoverTrigger({ handleSortBy, sortBy, columnId }: PopoverTriggerProps): ReactNode {
  const [sortColumn, sortDirection] = sortBy?.split(':') ?? [];
  const columnSortParam = SORT_QUERY_PARAM[columnId as keyof typeof SORT_QUERY_PARAM];
  const isCurrentColumnSorted = Boolean(
    sortColumn && columnSortParam && sortColumn === columnSortParam
  );

  const getIcon = useCallback((): ReactNode => {
    if (isCurrentColumnSorted) {
      if (sortDirection === SORT_DIRECTION.ASCENDING)
        return (
          <IconArrowTopOutlined className={styles['arrow-icon']} data-testid="icon-arrow-top" />
        );
      else if (sortDirection === SORT_DIRECTION.DESCENDING)
        return (
          <IconArrowBottomOutlined
            className={styles['arrow-icon']}
            data-testid="icon-arrow-bottom"
          />
        );
    }
    return (
      <IconChevronBottomOutlined className={styles['chevron-icon']} data-testid="icon-chevron" />
    );
  }, [isCurrentColumnSorted, sortDirection]);

  return (
    <DialogTrigger>
      <Button
        className={cx(
          styles['header-button'],
          isCurrentColumnSorted && styles['header-button-active']
        )}
        data-testid="header-popover-trigger"
        iconOnly
        variant="tertiary"
      >
        {getIcon()}
      </Button>
      <HeaderCellPopover
        data-testid="header-cell-popover"
        isCurrentColumnSorted={isCurrentColumnSorted}
        onSortBy={handleSortBy}
        sortedColumn={columnSortParam}
        sortedDirection={sortDirection as SortDirection}
      />
    </DialogTrigger>
  );
}
