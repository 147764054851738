export default {
  "wrapper": "cjS",
  "request-header": "cjc",
  "request": "cjq",
  "scheduled": "cjZ",
  "scheduled-label": "cjR",
  "note": "cjQ",
  "note-label": "cje",
  "note-label-danger": "cjB",
  "note-content": "cjE",
  "status": "cjd",
  "transfer-amount": "cjI"
};
