/* import __COLOCATED_TEMPLATE__ from './modal.hbs'; */
import { getOwner } from '@ember/application';
import { action } from '@ember/object';
import { debounce, next } from '@ember/runloop';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

// @ts-expect-error
import { hasMFAError } from '@qonto/qonto-sca/utils/mfa-error';
import { dateToken } from '@qonto/ui-kit/utils/date-token';
// @ts-expect-error
import formatFileSize from '@qonto/ui-kit/utils/format-bytes';
import { LottiePlayer } from '@repo/design-system-kit';
import { dropTask, task, timeout } from 'ember-concurrency';
import fuzzysort from 'fuzzysort';
import { TrackedArray } from 'tracked-built-ins';

import {
  initializeQuickActions,
  modifierKey,
  QONTO_PILOT_DELAYS,
  // @ts-expect-error
} from 'qonto/constants/qonto-pilot';
// @ts-expect-error
import { DEBOUNCE_MS } from 'qonto/constants/timers';
// @ts-expect-error
import { DEFAULT_SEARCH_INCLUDES } from 'qonto/constants/transactions';
import { DISCLAIMER_TYPES, TRANSFER_FLOW_ORIGIN } from 'qonto/constants/transfers';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';
// @ts-expect-error
import { isMac } from 'qonto/utils/is-mac';
// @ts-expect-error
import parseConfirmResponse from 'qonto/utils/parse-confirm-response';
const QPILOT_CREATE_TRANSFER_STEPS = {
  QUICK_ACTIONS: 'quick_actions', // the first step
  SUPPLIER_INVOICES: 'supplier_invoices',
  PROMPT: 'prompt', // the step where we ask the user to insert the prompt
  PROMPT_EVALUATION: 'prompt_evaluation', // when the user submit the prompt and the LLM tries to parse it
  TRANSFER_DETAILS: 'transfer_details', // when a transfer model is created and is ready for the user review and submission
  TRANSFER_CREATION: 'transfer_creation', // when the "confirm" of the transfer is in progress
  TRANSFER_CREATED: 'transfer_created', // when the "confirm" of the transfer is completed and the transfer is created
};

const ERROR_MESSAGES = {
  [DISCLAIMER_TYPES.BILLER_INSUFFICIENT_FUNDS]: 'transfers.warnings.insufficient_funds',
};

interface QontoPilotSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class QontoPilotComponent extends Component<QontoPilotSignature> {
  lottiePlayer = LottiePlayer;

  @service declare qontoPilotService: Services['qontoPilotService'];
  @service declare router: Services['router'];
  @service declare sentry: Services['sentry'];
  @service declare segment: Services['segment'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare beneficiariesManager: Services['beneficiariesManager'];
  @service declare userManager: Services['userManager'];
  @service declare intl: Services['intl'];
  @service declare sensitiveActions: Services['sensitiveActions'];
  @service declare flowLinkManager: Services['flowLinkManager'];
  @service declare store: Services['store'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare supplierInvoicesUploadManager: Services['supplierInvoicesUploadManager'];

  @tracked userInput = '';
  // @ts-expect-error
  @tracked transfer;
  // @ts-expect-error
  @tracked errorMessage;
  @tracked quickActions;

  @tracked selectedTransactionIndex = -1;

  selectedItems = [];

  steps = QPILOT_CREATE_TRANSFER_STEPS;
  // @ts-expect-error
  @tracked currentStep = this.args.data.action;

  mentionTrigger = '@';

  defaultQuickActions = initializeQuickActions(getOwner(this));

  modifierKey = modifierKey(isMac());

  get feedbackUrl() {
    let { currentUser } = this.userManager;

    return this.intl.t('qonto-pilot.modal.feedback', {
      // @ts-expect-error
      userid: currentUser?.id,
      // @ts-expect-error
      email: currentUser?.email,
    });
  }

  get flattenedQuickActions() {
    return Object.values(this.quickActions).flat();
  }

  get dropZoneLabel() {
    let { organization } = this.organizationManager;
    let shouldUseXMLText = organization?.legalCountry === 'DE';

    return shouldUseXMLText
      ? this.intl.t('labels.upload-message-with-xml', {
          maxSize: formatFileSize(this.intl, this.maxFileSize),
        })
      : this.intl.t('labels.upload-message', {
          maxSize: formatFileSize(this.intl, this.maxFileSize),
        });
  }

  @action
  // @ts-expect-error
  handleActionClick(quickAction) {
    this.segment.track('qontopilot_quick-action_selected', {
      quickAction: quickAction.key,
    });
    this.qontoPilotService.addRecentQuickAction(quickAction.key);
    if (quickAction.key === 'task-sepa') {
      this.handleInstantSepaTransferActionClick();
      return;
    }
    if (quickAction.key === 'task-receipt') {
      this.handleReceiptActionClick();
      return;
    }
    if (quickAction.isEnabled && typeof quickAction.action === 'function') {
      this.close();
      quickAction.action();
      // @ts-expect-error
      document.querySelector('#mentionable-input')?.focus();
    }
  }

  @action
  handleInstantSepaTransferActionClick() {
    next(this, () => {
      this.currentStep = QPILOT_CREATE_TRANSFER_STEPS.PROMPT;
    });
  }

  @action
  handleReceiptActionClick() {
    next(this, () => {
      this.currentStep = QPILOT_CREATE_TRANSFER_STEPS.SUPPLIER_INVOICES;
    });
  }

  @action
  close({ cleanCache = true } = {}) {
    if (this.submitPromptTask.isRunning) {
      return;
    }
    this.removeConfirmEvent();
    if (cleanCache) {
      this.store.unloadRecord(this.transfer);
    }
    // @ts-expect-error
    this.supplierInvoicesUploadManager.resetState();
    // @ts-expect-error
    this.args.close();
  }

  @action
  closeModalAndNavigateToTransfer() {
    this.router.transitionTo('transfers.past', {
      queryParams: { highlight: this.transfer.id },
    });
    this.close();
  }

  @action
  handleEdit() {
    this.segment.track('qontopilot_transfer_edit-details');
    this.flowLinkManager.transitionTo({
      name: 'sepa-transfer',
      stepId: 'details',
      queryParams: {
        origin: TRANSFER_FLOW_ORIGIN.QONTO_PILOT,
      },
    });
    this.close({ cleanCache: false });
  }

  constructor(owner: unknown, args: QontoPilotSignature['Args']) {
    super(owner, args);
    this.loadBeneficiariesTask.perform().catch(ignoreCancelation);
    this.quickActions = this.defaultQuickActions;
    // @ts-expect-error
    this.supplierInvoicesUploadManager.resetState();
    // @ts-expect-error
    this.supplierInvoicesUploadManager.registerCallback({
      onUploadFinished: this.onUploadFinish.bind(this),
    });
    // @ts-expect-error
    this.fetchTransactionsTask.perform().catch(ignoreCancelation);
  }

  async onUploadFinish() {
    // @ts-expect-error
    for (let file of this.supplierInvoicesUploadManager.files) {
      file.startProcessing();
    }

    // @ts-expect-error
    let attachmentIds = this.supplierInvoicesUploadManager.files.map(file => file?.attachment?.id);
    if (attachmentIds.includes(undefined)) return;

    let filterGroup = {
      conditional: 'and',
      expressions: [
        {
          property: 'attachment_ids',
          values: attachmentIds,
          operator: 'in',
        },
      ],
    };

    // TODO:: to be replaced by websocket event
    await timeout(QONTO_PILOT_DELAYS.supplierInvoices);

    let transactions = await this.fetchTransactionsTask.perform(filterGroup);

    // @ts-expect-error
    for (let file of this.supplierInvoicesUploadManager.files) {
      // @ts-expect-error
      let matchedTransaction = transactions.find(transaction =>
        transaction.attachmentIds.includes(file.attachment.id)
      );

      if (matchedTransaction) {
        let message = this.intl.t('qonto-pilot.modal.upload-receipt.match', {
          description: matchedTransaction.description,
          date: matchedTransaction.emittedAt,
          amount: `${matchedTransaction.amount} ${matchedTransaction.amountCurrency}`,
        });
        file.finishProcessing(message);
      } else {
        file.finishProcessing(this.intl.t('qonto-pilot.modal.upload-receipt.nomatch'));
      }
    }
  }

  fetchTransactionsTask = task(this, { restartable: true }, async filterGroup => {
    await timeout(DEBOUNCE_MS);

    // @ts-expect-error
    let { transactions } = await this.store.adapterFor('transaction').search({
      includes: DEFAULT_SEARCH_INCLUDES,
      filter_group: filterGroup,
      sort: { property: 'emitted_at', direction: 'desc' },
      pagination: { page: 1, per_page: 50 },
      search: '',
      organization_id: this.organizationManager.organization.id,
    });

    return transactions;
  });

  get beneficiaries() {
    return this.store.peekAll('beneficiary');
  }

  loadBeneficiariesTask = task(async () => {
    try {
      await this._loadBeneficiaries();
    } catch (error) {
      this.sentry.captureException(
        new Error(`Beneficiary load triggered an error`, { cause: error })
      );
    }
  });

  _loadBeneficiaries() {
    let organizationId = this.organizationManager.organization.id;
    // @ts-expect-error
    return this.beneficiariesManager.loadSepaBeneficiaries(organizationId);
  }

  @action
  // @ts-expect-error
  handleArrowsNavigation(event) {
    event.preventDefault();
    let delta = event.key === 'ArrowDown' ? 1 : -1;
    let flattenedActions = [];
    // @ts-expect-error
    let groupIndexes = [];
    let groupStartIndexes = {};
    let totalLength = 0;
    let currentIndex = -1;

    Object.keys(this.quickActions).forEach((group, groupIndex) => {
      let groupActions = this.quickActions[group];
      // @ts-expect-error
      groupStartIndexes[groupIndex] = totalLength;
      totalLength += groupActions.length;

      // @ts-expect-error
      groupActions.forEach(action => {
        flattenedActions.push(action);
        groupIndexes.push(groupIndex);
        if (action.isFocused) {
          currentIndex = flattenedActions.length - 1;
        }
      });
    });

    let newIndex = currentIndex + delta;

    if (newIndex >= flattenedActions.length || this.selectedTransactionIndex > 0) {
      currentIndex = this.selectedTransactionIndex;
      newIndex = currentIndex + delta;
      let transactionIndex = newIndex;

      if (transactionIndex >= this.filteredTransactions.length) {
        // @ts-expect-error
        document.querySelector(`[data-transactions-search-all]`)?.focus();
        this.selectedTransactionIndex = this.filteredTransactions.length;
        return;
      }

      this.selectedTransactionIndex = transactionIndex;
      // @ts-expect-error
      document.querySelector(`[data-transaction-index="${transactionIndex}"]`)?.focus();

      return;
    }

    if (newIndex < 0) {
      return;
    }

    this.selectedTransactionIndex = -1;

    newIndex = (newIndex + flattenedActions.length) % flattenedActions.length;

    // @ts-expect-error
    let newGroupIndex = groupIndexes[newIndex];
    // @ts-expect-error
    let newActionIndex = newIndex - groupStartIndexes[newGroupIndex];

    let updatedQuickActions = {};
    Object.keys(this.quickActions).forEach((group, groupIndex) => {
      // @ts-expect-error
      updatedQuickActions[group] = this.quickActions[group].map((action, actionIndex) => ({
        ...action,
        isFocused: groupIndex === newGroupIndex && actionIndex === newActionIndex,
      }));
    });

    this.quickActions = updatedQuickActions;
  }

  // @ts-expect-error
  trackQuery(query) {
    this.segment.track('qontopilot_search-query', {
      query,
    });
  }

  @action
  // @ts-expect-error
  onTransactionKeyDown(transactionId, event) {
    if (event.key === 'Enter') {
      this.segment.track('qontopilot_transaction-open', { trigger: 'keyboard' });
      return this.onSelectItem(transactionId);
    }
    this.onKeyDown(event);
  }

  @action
  // @ts-expect-error
  onKeyDown(event) {
    this.errorMessage = null;

    if (['ArrowUp', 'ArrowDown'].includes(event.key)) {
      this.handleArrowsNavigation(event);
    }
  }

  @action
  // @ts-expect-error
  onKeyUp(event) {
    let query = event.target.value;
    debounce(this, this.trackQuery, query, QONTO_PILOT_DELAYS.searchInputDebounce);
  }

  @action
  // @ts-expect-error
  onInputChange(event) {
    this.userInput = event.target.value;
    this.selectedTransactionIndex = -1;
    if (this.userInput.length === 0) {
      this.quickActions = this.defaultQuickActions;
      return;
    }

    Object.keys(this.defaultQuickActions).forEach(key => {
      let results = fuzzysort.go(this.userInput, this.defaultQuickActions[key], { key: 'copy' });
      // @ts-expect-error
      let updatedActions = results.map(result => ({ ...result.obj }));
      this.quickActions = {
        ...this.quickActions,
        [key]: updatedActions,
      };
    });
  }

  @action
  // @ts-expect-error
  onSubmit(value) {
    this.submitPromptTask.perform(value).catch(ignoreCancelation);
  }

  submitPromptTask = dropTask(async value => {
    try {
      this.currentStep = QPILOT_CREATE_TRANSFER_STEPS.PROMPT_EVALUATION;
      let result = await this.qontoPilotService.processUserInput(value);
      if (typeof result === 'string' || result === null) {
        this.errorMessage = result || this.intl.t('qonto-pilot.modal.error.wrong-prompt');
        this.currentStep = QPILOT_CREATE_TRANSFER_STEPS.PROMPT;
      } else {
        this.segment.track('qontopilot_transfer-request_made', {
          amount: result.amount,
          beneficiary: result.beneficiary.get('name'),
        });
        this.errorMessage = null;
        this.transfer = result;
        this.currentStep = QPILOT_CREATE_TRANSFER_STEPS.TRANSFER_DETAILS;
        // @ts-expect-error
        document.activeElement.blur();
        this.setConfirmEvent();
      }
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  });

  confirmTransferTask = dropTask(async () => {
    // @ts-expect-error
    await this.sensitiveActions.runTask.perform(this.confirmAndSaveTransfer, this.transfer);
  });

  confirmAndSaveTransfer = dropTask(async transfer => {
    this.currentStep = QPILOT_CREATE_TRANSFER_STEPS.TRANSFER_CREATION;
    try {
      let confirmationResult = await transfer.confirm();
      if (confirmationResult.errors.length === 0) {
        this.transfer = await transfer.save();
        this.currentStep = QPILOT_CREATE_TRANSFER_STEPS.TRANSFER_CREATED;
        this.segment.track('qontopilot_transfer_confirmed', {
          amount: transfer.amount,
          beneficiary: transfer.beneficiary.get('name'),
        });
      } else {
        let confirmResponseErrors = parseConfirmResponse(confirmationResult, this.intl);
        this.#displayConfirmErrors(confirmResponseErrors.confirmErrors);
      }
    } catch (error) {
      // @ts-expect-error
      if (hasMFAError(error?.errors)) {
        throw error;
      } else {
        this.currentStep = QPILOT_CREATE_TRANSFER_STEPS.TRANSFER_DETAILS;
      }
    } finally {
      transfer.removeIdempotencyHeader();
    }
  });

  @action
  // @ts-expect-error
  confirmEvent(event) {
    // @ts-expect-error
    let tagName = document.activeElement.tagName.toLowerCase();
    // @ts-expect-error
    let isEditable = document.activeElement.isContentEditable;

    if (event.key === 'Enter' && tagName !== 'input' && tagName !== 'textarea' && !isEditable) {
      if (this.currentStep === QPILOT_CREATE_TRANSFER_STEPS.TRANSFER_CREATED) {
        this.closeModalAndNavigateToTransfer();
        return;
      }
      this.confirmTransferTask.perform().catch(ignoreCancelation);
    }
  }

  setConfirmEvent() {
    this.removeConfirmEvent();
    document.addEventListener('keydown', this.confirmEvent);
  }

  removeConfirmEvent() {
    document.removeEventListener('keydown', this.confirmEvent);
  }

  // @ts-expect-error
  #displayConfirmErrors(confirmErrors) {
    // @ts-expect-error
    let errorsToDisplay = confirmErrors.map(errorCode => ERROR_MESSAGES[errorCode]);

    // @ts-expect-error
    errorsToDisplay.forEach(errorMessage => {
      this.toastFlashMessages.toastError(this.intl.t(errorMessage));
    });

    this.close({ cleanCache: true });
  }

  get displayPromptLoading() {
    return this.currentStep === QPILOT_CREATE_TRANSFER_STEPS.PROMPT_EVALUATION;
  }

  get displayTransferDetails() {
    return this.currentStep === QPILOT_CREATE_TRANSFER_STEPS.TRANSFER_DETAILS;
  }

  get displayButtons() {
    return this.currentStep === QPILOT_CREATE_TRANSFER_STEPS.TRANSFER_DETAILS;
  }

  get displayMFA() {
    return this.currentStep === QPILOT_CREATE_TRANSFER_STEPS.TRANSFER_CREATION;
  }

  get displaySuccessScreen() {
    return this.currentStep === QPILOT_CREATE_TRANSFER_STEPS.TRANSFER_CREATED;
  }

  get displaySelectKeyControl() {
    return this.currentStep === QPILOT_CREATE_TRANSFER_STEPS.QUICK_ACTIONS;
  }

  get displayQuickActions() {
    return this.currentStep === QPILOT_CREATE_TRANSFER_STEPS.QUICK_ACTIONS;
  }

  get displayPromptInput() {
    return this.currentStep === QPILOT_CREATE_TRANSFER_STEPS.PROMPT && !this.displaySuccessScreen;
  }

  get displayBeneficiary() {
    return this.currentStep === QPILOT_CREATE_TRANSFER_STEPS.PROMPT;
  }

  get displayEnter() {
    return this.currentStep !== QPILOT_CREATE_TRANSFER_STEPS.SUPPLIER_INVOICES;
  }

  get displaySupplierInvoicesDropzone() {
    return this.currentStep === QPILOT_CREATE_TRANSFER_STEPS.SUPPLIER_INVOICES;
  }

  @action
  // @ts-expect-error
  handleSupplierInvoiceUploaded(file) {
    // @ts-expect-error
    this.supplierInvoicesUploadManager.queue.add(file);
    this.supplierInvoicesErrors = new TrackedArray();
  }

  // @ts-expect-error
  @action onPreviewFile(file) {
    this.close();
    this.router.transitionTo('supplier-invoices.show', file.invoiceId);
  }

  get filteredTransactions() {
    let transactions = this.fetchTransactionsTask.lastSuccessful?.value || [];

    return fuzzysort
      .go(this.userInput, transactions, {
        all: true,
        keys: [
          'note',
          'status',
          'description',
          'signedAmount',
          'activityTag',
          'counterpartyName',
          // @ts-expect-error
          obj => dateToken({ date: obj.emittedAt, token: 'date-year-l' }),
        ],
      })
      .map(result => result.obj);
  }

  // @ts-expect-error
  @action onSelectItem(transactionId) {
    this.close();
    return this.router.replaceWith('transactions.index', {
      queryParams: {
        highlight: transactionId,
      },
    });
  }

  @action
  handleNoResultsClick() {
    this.close();
    return this.router.replaceWith('transactions.index', {
      queryParams: {
        query: this.userInput,
      },
    });
  }

  supplierInvoicesErrors = new TrackedArray();
  supplierInvoices = new TrackedArray();
  maxFileSize = 30 * 1e6;
  uploadOptions = { callEndpoint: false };
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'QontoPilot::Modal': typeof QontoPilotComponent;
  }
}
