/* import __COLOCATED_TEMPLATE__ from './mandate.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Checkbox } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';

interface FlowsCheckMandateSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsCheckMandateComponent extends Component<FlowsCheckMandateSignature> {
  checkbox = Checkbox;

  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare intl: Services['intl'];
  @service declare store: Services['store'];

  @tracked isFNCIAccepted = false;
  @tracked showFNCIerror = false;

  constructor(owner: unknown, args: FlowsCheckMandateSignature['Args']) {
    super(owner, args);

    // @ts-expect-error
    if (this.args.context.hasValidMandate) {
      this.isFNCIAccepted = true;
    }
  }

  handleNext = dropTask(async () => {
    if (this.isFNCIAccepted) {
      try {
        await this.store
          // @ts-expect-error
          .adapterFor('check')
          // @ts-expect-error
          .createMandate(this.args.context.check.get('organization.id'));

        // @ts-expect-error
        this.args.context.hasValidMandate = true;
        // @ts-expect-error
        this.args.transitionToNext();
      } catch {
        this.toastFlashMessages.toastError(this.intl.t('checks.errors.general'));
      }
    } else {
      this.showFNCIerror = true;
    }
  });

  @action
  onFNCIAccept() {
    this.isFNCIAccepted = !this.isFNCIAccepted;
    this.showFNCIerror = false;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Check::Mandate': typeof FlowsCheckMandateComponent;
  }
}
