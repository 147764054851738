export default {
  "modal-wrapper": "ciy",
  "spinner-container": "ciU",
  "spinner": "cij",
  "container": "ciW",
  "tabs-item": "ciu",
  "form-container": "cAS",
  "fieldset": "cAc",
  "preview-container": "cAq",
  "switch-container": "cAZ",
  "pdf-preview-container": "cAR",
  "form-content": "cAQ",
  "row": "cAe",
  "numbering-format": "cAB",
  "numbering-fields": "cAE",
  "input-settings": "cAd",
  "next-invoice-number": "cAI",
  "spacing": "cAt",
  "label-tooltip": "cAM",
  "tooltip-icon": "cAP",
  "customize-field": "cAl",
  "button-container-footer": "cAX"
};
