export default {
  "sidebar": "LA",
  "scroll": "LY",
  "header": "Lh",
  "close": "Lf",
  "vat": "LK",
  "labels": "LG",
  "actions": "Lr",
  "buttons-container": "Lb"
};
