export default {
  "progress-container": "qJT",
  "line-container": "qJz",
  "dashed-line": "qJa",
  "solid-line": "qJH",
  "circle-container": "qJO",
  "circle": "qJm",
  "current-step-dot": "qJp",
  "success-icon": "qJx",
  "rejected-icon": "qJw"
};
