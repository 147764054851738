export default {
  "qonto-pilot-modal": "cph",
  "content": "cpf",
  "header": "cpK",
  "transition-state-container": "cpG",
  "lottie": "cpr",
  "prompt-input": "cpb",
  "buttons": "cpy",
  "footer": "cpU caption",
  "divider": "cpj",
  "illustration": "cpW",
  "icon": "cpu",
  "quick-actions-container": "cxS",
  "action": "cxc body-2",
  "action-title": "cxq",
  "disabled": "cxZ",
  "action-tag": "cxR caption-bold",
  "hotkey": "cxQ body-1",
  "footer-action": "cxe",
  "grow": "cxB",
  "footer-feedback-link": "cxE",
  "buttons-container": "cxd",
  "transfer-details-container": "cxI",
  "error-container": "cxt",
  "option-iban": "cxM",
  "actions": "cxP",
  "title": "cxl",
  "group-title": "cxX caption-bold",
  "beta-badge": "cxC",
  "hotkeys": "cxk",
  "supplier-invoices-dropzone": "cxJ",
  "success-icon": "cxs",
  "error-icon": "cxF",
  "file-items": "cxg",
  "file-item": "cxD",
  "uploader-file": "cxV",
  "transactions-table": "cxL",
  "transaction": "cxT",
  "prompt-example": "cxz"
};
