export default {
  "row": "qaz",
  "cell": "qaa",
  "empty": "qaH",
  "icon": "qaO",
  "type": "qam",
  "amount-content": "qap",
  "cell-approve": "qax",
  "approve-content": "qaw",
  "row-compact": "qaN"
};
