/* import __COLOCATED_TEMPLATE__ from './item.hbs'; */
import { action } from '@ember/object';
import { getOwner } from '@ember/owner';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Avatar } from '@repo/design-system-kit';
import { task } from 'ember-concurrency';

import { INTEGRATION_SOURCES } from 'qonto/constants/connect';
import { HrisConnection } from 'qonto/models/hris-integration';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';

interface InContextIntegrationsSideDrawerIntegrationItemSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLLIElement;
}

export default class InContextIntegrationsSideDrawerIntegrationItemComponent extends Component<InContextIntegrationsSideDrawerIntegrationItemSignature> {
  @service declare connectManager: Services['connectManager'];
  @service declare intl: Services['intl'];
  @service declare sentry: Services['sentry'];
  @service declare store: Services['store'];
  @service declare segment: Services['segment'];

  @tracked inboundItems = {};
  @tracked outboundItems = {};
  @tracked model = {};

  source = INTEGRATION_SOURCES.ICI;

  // React components
  avatar = Avatar;

  constructor(
    owner: unknown,
    args: InContextIntegrationsSideDrawerIntegrationItemSignature['Args']
  ) {
    super(owner, args);

    this.setData();
  }

  setData() {
    // @ts-expect-error
    let { integration } = this.args;
    let inboundDomains = [];
    let outboundDomains = [];

    if (integration.qualities.length) {
      // @ts-expect-error
      let qualities = Object.groupBy(integration.qualities, ({ dataflow }) => dataflow);
      if (qualities.in) {
        // @ts-expect-error
        inboundDomains = Object.groupBy(
          // This issue needs an action on the data logged in Prismic
          // @ts-expect-error
          qualities.in.filter(({ domain }) => domain && domain.data),
          // @ts-expect-error
          ({ domain }) => domain.data.name
        );
      }
      if (qualities.out) {
        // @ts-expect-error
        outboundDomains = Object.groupBy(
          // This issue needs an action on the data logged in Prismic
          // @ts-expect-error
          qualities.out.filter(({ domain }) => domain && domain.data),
          // @ts-expect-error
          ({ domain }) => domain.data.name
        );
      }
    }

    this.inboundItems = this.flattenQualities(inboundDomains);
    this.outboundItems = this.flattenQualities(outboundDomains);

    this.loadStatusTask.perform().catch(error => {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    });
  }

  loadStatusTask = task(async () => {
    // @ts-expect-error
    let { organization, integration: prismicData } = this.args;
    let solutionId = prismicData.tray_solution_id;
    let applicationSlug = prismicData.slug;
    let komboConnectProviderName = prismicData.kombo_connect_provider_name;

    // @ts-expect-error
    let [[hubConnection], [solutionInstance], [hubApplication], hrisConnection] = await Promise.all(
      [
        this.store.query('hub-connection', {
          organization_id: organization.id,
          filters: {
            external_service: applicationSlug,
          },
        }),
        solutionId
          ? this.store.query('solution-instance', {
              organization_id: organization.id,
              solution_id: solutionId,
            })
          : [],
        this.store.query('hub-application', {
          organization_id: organization.id,
          filters: { slug: applicationSlug },
        }),
        komboConnectProviderName && HrisConnection.create(getOwner(this), komboConnectProviderName),
      ]
    );

    this.model = {
      hubApplication,
      hubConnection,
      prismicData,
      solutionInstance,
      hrisConnection,
    };
  });

  installApplicationTask = task(async (model, app) => {
    await this.connectManager.installApplicationTask.perform(model, app);
  });

  // @ts-expect-error
  flattenQualities(domainsObject) {
    let keys = Object.keys(domainsObject);
    // @ts-expect-error
    let flatQualities = [];

    keys.forEach(key => {
      flatQualities.push({
        domain: key,
        // @ts-expect-error
        qualities: domainsObject[key].map(item => item.quality.data.name),
      });
    });

    // @ts-expect-error
    return flatQualities;
  }

  get description() {
    // @ts-expect-error
    let { integration } = this.args;

    return integration.waitlisted
      ? this.intl.t('qonto-hub.connect.integration.waitlist.description')
      : integration.short_description[0].text || '';
  }

  get noAttributesAvailable() {
    // @ts-expect-error
    return !this.inboundItems.length && !this.outboundItems.length;
  }

  get integrationRedirectionLink() {
    // @ts-expect-error
    if (this.args?.integration?.buckets[0]?.bucket?.slug === 'connect-to-financing-partners') {
      return 'financing.partners.offer';
    } else {
      return 'settings.connect-hub.applications.hub-application.details';
    }
  }

  @action
  // @ts-expect-error
  trackEvent(eventName) {
    this.segment.track(eventName, {
      // @ts-expect-error
      page: this.args.page,
      // @ts-expect-error
      integration: this.args.integration.name,
    });
  }

  @action
  // @ts-expect-error
  trackConnectCta(cta_type) {
    this.segment.track('ICI_connect_clicked', {
      // @ts-expect-error
      page: this.args.page,
      // @ts-expect-error
      integration: this.args.integration.name,
      cta_type,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InContextIntegrations::SideDrawer::Integration::Item': typeof InContextIntegrationsSideDrawerIntegrationItemComponent;
  }
}
