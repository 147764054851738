export default {
  "header-cell": "qaM",
  "empty": "qaP",
  "header-type": "qal",
  "header-requester": "qaX",
  "header-amount": "qaC",
  "header-approval": "qak",
  "header-content": "qaJ caption-bold",
  "active": "qas",
  "row-compact": "qaF",
  "row-sidebar": "qag",
  "hidden": "qaD",
  "animated": "qaV",
  "fadein-item": "qaL",
  "animated-cell": "qaT"
};
