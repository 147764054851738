/* eslint-disable camelcase -- API adapter */
import type {
  CashflowTimeframe,
  CashflowTimeseries,
  TimeframeInterval,
} from 'qonto/react/api/models/cash-flow-timeseries';
import type { CashflowSide, CurrentBalanceAmount } from 'qonto/react/models/cash-flow-timeseries';
import type { MonthlyBalance, MonthlyFlow } from '../api/balance';
import type { AggregateAmounts } from '../api/statistics';
import type { ComboChartStatistics } from '../api/combo-chart';

interface StatisticsInterval {
  start_date: number;
  end_date: number;
}

export function buildStatisticsInterval(timeframe: TimeframeInterval): StatisticsInterval {
  const start_date = Date.parse(timeframe.inclusive_start_date);
  const end_date = Date.parse(timeframe.exclusive_end_date);
  return { start_date, end_date };
}

export function getMonthlyCashflow(
  timeframe: CashflowTimeframe,
  side: CashflowSide,
  interval: StatisticsInterval
): MonthlyFlow {
  const datapoint = timeframe.cash_flow_data[side];
  const aggregateAmounts: AggregateAmounts = {
    amount_count: datapoint.transactions_count,
    amount_sum: Number(datapoint.amount_sum.value).toFixed(2),
    forecast_amount: Number(datapoint.forecast_amount_sum?.value ?? 0).toFixed(2),
    growth_rate: datapoint.growth_rate_over_previous_period,
  };

  return {
    data: aggregateAmounts,
    ...interval,
  };
}

export function getMonthlyBalance(
  timeframe: CashflowTimeframe,
  interval: StatisticsInterval,
  currentBalance?: CurrentBalanceAmount
): MonthlyBalance {
  const datapoint = timeframe.cash_flow_data;
  const {
    vat,
    starting_amount,
    forecasted_starting_amount,
    ending_amount,
    forecasted_ending_amount,
  } = datapoint;
  const vat_net_amount = vat?.net_amount?.value;
  const vat_computed_amount = vat ? vat.credit_amount.value - vat.debit_amount.value : undefined;
  const vat_due_amount = vat_net_amount ?? vat_computed_amount;

  return {
    data: {
      current_balance: currentBalance ? Number(currentBalance.value).toFixed(2) : undefined,
      starting_balance: starting_amount ? Number(starting_amount.value).toFixed(2) : undefined,
      forecasted_starting_balance: forecasted_starting_amount
        ? Number(forecasted_starting_amount.value).toFixed(2)
        : undefined,
      ending_balance: ending_amount ? Number(ending_amount.value).toFixed(2) : undefined,
      forecasted_ending_balance: forecasted_ending_amount
        ? Number(forecasted_ending_amount.value).toFixed(2)
        : undefined,
      vat_credit_amount: datapoint.vat
        ? Number(datapoint.vat.credit_amount.value).toFixed(2)
        : undefined,
      vat_debit_amount: datapoint.vat
        ? Number(datapoint.vat.debit_amount.value).toFixed(2)
        : undefined,
      vat_due_amount: vat_due_amount ? Number(vat_due_amount).toFixed(2) : undefined,
    },
    ...interval,
  };
}

export function mapTimeseriesToStatistics(timeseries: CashflowTimeseries): ComboChartStatistics {
  const inflowMonthlyData: MonthlyFlow[] = [];
  const outflowMonthlyData: MonthlyFlow[] = [];
  const balanceMonthlyData: MonthlyBalance[] = [];
  const currentBalance = timeseries.current_balance_amount;
  const { start_date: min, end_date: max } = buildStatisticsInterval(timeseries.timeframe);
  const meta = { bounds: { min, max } };

  for (const timeframe of timeseries.timeframes) {
    const interval = buildStatisticsInterval(timeframe);

    inflowMonthlyData.push(getMonthlyCashflow(timeframe, 'inflows', interval));
    outflowMonthlyData.push(getMonthlyCashflow(timeframe, 'outflows', interval));
    balanceMonthlyData.push(getMonthlyBalance(timeframe, interval, currentBalance));
  }

  const inflowFlowStatistics = { data: { display_at_monthly: inflowMonthlyData }, meta };
  const outflowFlowStatistics = { data: { display_at_monthly: outflowMonthlyData }, meta };
  const balanceStatistics = { data: { display_at_monthly: balanceMonthlyData }, meta };

  return [inflowFlowStatistics, outflowFlowStatistics, balanceStatistics];
}
