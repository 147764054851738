export default {
  "container": "PN",
  "container-metal": "Pv",
  "pickers": "Po",
  "picker-wrapper": "Pn",
  "picker": "Pi",
  "checked": "PA",
  "lilac": "PY",
  "black": "Ph",
  "mineral-gray": "Pf",
  "sand-gold": "PK",
  "graphite-black": "PG"
};
