/* import __COLOCATED_TEMPLATE__ from './global-discount.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { NumberField } from '@repo/design-system-kit';
import { reads } from 'macro-decorators';

import { DISCOUNT_TYPE } from 'qonto/constants/receivable-invoice';

import styles from './global-discount.strict-module.css';

const PERCENT_SYMBOL = '%';

interface ReceivableInvoicesFormGlobalDiscountSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class ReceivableInvoicesFormGlobalDiscountComponent extends Component<ReceivableInvoicesFormGlobalDiscountSignature> {
  numberField = NumberField;

  styles = styles;

  @service declare intl: Services['intl'];

  // @ts-expect-error
  @reads('args.document.discount') discount;

  @tracked discountValue = this.initializeDiscountValue();
  // @ts-expect-error
  @tracked discountType = this.discount?.type ?? DISCOUNT_TYPE.ABSOLUTE;

  get discountTypeOptionsArray() {
    return [
      // @ts-expect-error
      { value: DISCOUNT_TYPE.ABSOLUTE, label: this.args.currency },
      { value: DISCOUNT_TYPE.PERCENTAGE, label: PERCENT_SYMBOL },
    ];
  }

  get discountTypeOptions() {
    return this.discountTypeOptionsArray.map(({ label }) => label);
  }

  get selectedDiscountOption() {
    // @ts-expect-error
    return this.discountType === DISCOUNT_TYPE.ABSOLUTE ? this.args.currency : PERCENT_SYMBOL;
  }

  initializeDiscountValue() {
    if (!this.discount) return null;
    let value = parseFloat(this.discount.value);
    return this.discount.type === DISCOUNT_TYPE.PERCENTAGE
      ? this.formatPercentageValue(value)
      : value.toFixed(2);
  }

  // @ts-expect-error
  formatPercentageValue(value) {
    let percentage = value * 100;
    return Number.isInteger(percentage) ? String(percentage) : percentage.toFixed(2);
  }

  formatDiscountValue() {
    if (!this.discountValue) return;
    let parsedValue = Math.abs(parseFloat(this.discountValue));

    if (this.discountType === DISCOUNT_TYPE.ABSOLUTE) {
      this.discountValue = parsedValue.toFixed(2);
    } else {
      let percentage = parsedValue;
      this.discountValue = Number.isInteger(percentage)
        ? String(percentage)
        : percentage.toFixed(2);
    }
  }

  // @ts-expect-error
  calculatePercentageValue(value) {
    if (!value || isNaN(value)) return null;
    let decimalValue = Math.abs(parseFloat(value)) / 100;
    return decimalValue.toFixed(4);
  }

  updateDiscountObject() {
    // @ts-expect-error
    if (!this.discountValue || isNaN(this.discountValue) || parseFloat(this.discountValue) === 0) {
      // @ts-expect-error
      this.args.document.discount = null;
      return;
    }

    let value =
      this.discountType === DISCOUNT_TYPE.ABSOLUTE
        ? String(this.discountValue)
        : this.calculatePercentageValue(this.discountValue);

    // @ts-expect-error
    this.args.document.discount = { type: this.discountType, value };
  }

  get discountError() {
    return (
      // @ts-expect-error
      this.args.document?.get('errors.discount') || this.args.document?.get('errors.discountAmount')
    );
  }

  get discountErrorMessage() {
    if (!this.discountError) return;
    return this.discountType === 'percentage'
      ? this.intl.t('receivable-invoices.invoice-creation.items.discount.percentage-error-message')
      : this.intl.t('receivable-invoices.invoice-creation.items.discount.amount-error-message');
  }

  get hasDiscountError() {
    return (
      // @ts-expect-error
      this.args.document?.errors?.has('discount') ||
      // @ts-expect-error
      this.args.document?.errors?.has('discountAmount')
    );
  }

  @action
  // @ts-expect-error
  handleDiscountOptionChange(optionLabel) {
    let option = this.discountTypeOptionsArray.find(({ label }) => label === optionLabel);
    if (!option) return;

    if (this.hasDiscountError) {
      // @ts-expect-error
      this.args.document.errors.remove('discount');
      // @ts-expect-error
      this.args.document.errors.remove('discountAmount');
    }

    this.discountType = option.value;
    if (this.discountValue !== null) {
      this.formatDiscountValue();
      this.updateDiscountObject();
    }
  }

  @action
  // @ts-expect-error
  updateDiscountValue(value) {
    if (this.hasDiscountError) {
      // @ts-expect-error
      this.args.document.errors.remove('discount');
      // @ts-expect-error
      this.args.document.errors.remove('discountAmount');
    }

    if (value === '') {
      this.discountValue = null;
      // @ts-expect-error
      this.args.document.discount = null;
      return;
    }

    this.discountValue = String(value);
    this.updateDiscountObject();
  }

  @action
  setDiscountOnFocusOut() {
    if (!this.discountValue) return;

    this.formatDiscountValue();
    this.updateDiscountObject();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ReceivableInvoices::Form::GlobalDiscount': typeof ReceivableInvoicesFormGlobalDiscountComponent;
  }
}
