export default {
  "sidepanel": "qlr",
  "close-icon": "qlb",
  "close-button": "qly",
  "supplier-card": "qlU",
  "supplier-card-details": "qlj",
  "supplier-name": "qlW title-3 mb-8",
  "supplier-card-placeholder": "qlu",
  "box": "qXS",
  "box-element": "qXc",
  "related-invoices-title": "qXq",
  "placeholder-container": "qXZ",
  "related-invoices-wrapper": "qXR",
  "related-invoice-placeholder": "qXQ",
  "related-invoice-placeholder-inner": "qXe",
  "actions": "qXB",
  "currency": "qXE"
};
