export default {
  "page-wrapper": "qvP",
  "back-button": "qvl",
  "abort-button": "qvX",
  "container": "qvC",
  "subtitle": "qvk",
  "button": "qvJ",
  "panel-list": "qvs",
  "panel-list-item": "qvF",
  "card-illustration": "qvg"
};
