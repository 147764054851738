export default {
  "row": "qHm body-1",
  "active": "qHp",
  "cell": "qHx",
  "empty": "qHw",
  "ellipsis": "qHN",
  "text-secondary": "qHv",
  "cell-type": "qHo",
  "cell-reason": "qHn",
  "cell-amount": "qHi",
  "row-sidebar": "qHA",
  "animated": "qHY",
  "fadein-item": "qHh",
  "animated-cell": "qHf"
};
