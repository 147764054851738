import { type ReactNode } from 'react';
import cx from 'clsx';
import { FormattedMessage } from 'react-intl';
import { DEFAULT_SORT_ORDER, SORT_ORDER } from 'qonto/constants/sort.ts';
import { SortIcon } from 'qonto/react/components/paginated-table/header/sort/sort';
import styles from './styles.strict-module.css';

type AriaSort = 'none' | 'ascending' | 'descending';
type SortField =
  | 'beneficiary.name'
  | 'operation_type'
  | 'next_recursion_date'
  | 'last_recursion_date'
  | 'amount';
type SortOrder = (typeof SORT_ORDER)[keyof typeof SORT_ORDER];
type SortFull = `${SortField}:${SortOrder}`;

interface HeaderProps {
  sort?: SortFull;
  onSortChange?: (sort: SortFull) => void;
  isSidepanelOpen?: boolean;
}

export function Header({ sort, onSortChange, isSidepanelOpen }: HeaderProps): ReactNode {
  const sortField = sort?.split(':')[0] as SortField;
  const sortOrder = sort?.split(':')[1] || DEFAULT_SORT_ORDER;
  const isAscending = sortOrder === SORT_ORDER.ASC;

  const getAriaSort = (columnField: string): AriaSort => {
    const isCurrentFieldActive = columnField === sortField;
    if (!isCurrentFieldActive) return 'none';
    return sortOrder === SORT_ORDER.ASC ? 'ascending' : 'descending';
  };

  const handleSortBy = (field: SortField): void => {
    let currentSortOrder: SortOrder = DEFAULT_SORT_ORDER;

    if (field === sortField) {
      currentSortOrder = sortOrder === SORT_ORDER.ASC ? SORT_ORDER.DESC : SORT_ORDER.ASC;
    }

    return onSortChange?.(`${field}:${currentSortOrder}`);
  };

  return (
    <tr>
      <th aria-hidden="true" className={cx(styles['header-cell'], styles.empty)} scope="col" />

      <th
        aria-sort={getAriaSort('beneficiary.name')}
        className={cx(styles['header-cell'], isSidepanelOpen ? styles['col-8'] : styles['col-6'])}
        data-test-table-header-beneficiary-name
        scope="col"
      >
        <button
          className={cx(
            styles['header-content'],
            sortField === 'beneficiary.name' && styles.active
          )}
          onClick={() => {
            handleSortBy('beneficiary.name');
          }}
          type="button"
        >
          <FormattedMessage id="transfers.columns.beneficiary" />
          <SortIcon isActive={sortField === 'beneficiary.name'} isAscending={isAscending} />
        </button>
      </th>

      <th
        aria-sort={getAriaSort('operation_type')}
        className={cx(styles['header-cell'], isSidepanelOpen ? styles['col-6'] : styles['col-4'])}
        data-test-table-header-operation-type
        scope="col"
      >
        <button
          className={cx(styles['header-content'], sortField === 'operation_type' && styles.active)}
          onClick={() => {
            handleSortBy('operation_type');
          }}
          type="button"
        >
          <FormattedMessage id="transfers.columns.frequency" />
          <SortIcon isActive={sortField === 'operation_type'} isAscending={isAscending} />
        </button>
      </th>

      <th
        aria-sort={getAriaSort('next_recursion_date')}
        className={cx(styles['header-cell'], isSidepanelOpen ? styles['col-6'] : styles['col-4'])}
        data-test-table-header-next-recursion-date
        scope="col"
      >
        <button
          className={cx(
            styles['header-content'],
            sortField === 'next_recursion_date' && styles.active
          )}
          onClick={() => {
            handleSortBy('next_recursion_date');
          }}
          type="button"
        >
          <FormattedMessage id="transfers.columns.next_date" />
          <SortIcon isActive={sortField === 'next_recursion_date'} isAscending={isAscending} />
        </button>
      </th>

      <th
        aria-sort={getAriaSort('last_recursion_date')}
        className={cx(styles['header-cell'], isSidepanelOpen ? styles.hide : styles['col-4'])}
        data-test-table-header-last-recursion-date
        scope="col"
      >
        <button
          className={cx(
            styles['header-content'],
            sortField === 'last_recursion_date' && styles.active
          )}
          onClick={() => {
            handleSortBy('last_recursion_date');
          }}
          type="button"
        >
          <FormattedMessage id="transfers.columns.end_date" />
          <SortIcon isActive={sortField === 'last_recursion_date'} isAscending={isAscending} />
        </button>
      </th>

      <th
        className={cx(styles['header-cell'], isSidepanelOpen ? styles.hide : styles['col-2'])}
        data-test-table-header-status
        scope="col"
      >
        <div className={cx(styles['header-content'], styles['header-text'])}>
          <FormattedMessage id="transfers.columns.status" />
        </div>
      </th>

      <th
        aria-sort={getAriaSort('amount')}
        className={cx(styles['header-cell'], styles['align-right'], styles['col-4'])}
        data-test-table-header-amount
        scope="col"
      >
        <button
          className={cx(styles['header-content'], sortField === 'amount' && styles.active)}
          onClick={() => {
            handleSortBy('amount');
          }}
          type="button"
        >
          <FormattedMessage id="transfers.columns.amount" />
          <SortIcon isActive={sortField === 'amount'} isAscending={isAscending} />
        </button>
      </th>

      <th aria-hidden="true" className={cx(styles['header-cell'], styles.empty)} scope="col" />
    </tr>
  );
}
