/* import __COLOCATED_TEMPLATE__ from './trigger.hbs'; */
import Component from '@glimmer/component';

const MAX_ILLUSTRATED_ACCOUNTS = 3;

interface PowerSelectAccountsMultipleTriggerSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class PowerSelectAccountsMultipleTrigger extends Component<PowerSelectAccountsMultipleTriggerSignature> {
  get selectedAccounts() {
    // @ts-expect-error
    return this.args.selectedAccounts || [];
  }

  get displayableAccounts() {
    return this.selectedAccounts.slice(0, MAX_ILLUSTRATED_ACCOUNTS);
  }

  get hasIllustrationOverflow() {
    return this.selectedAccounts.length > MAX_ILLUSTRATED_ACCOUNTS;
  }

  get showAllAccountOption() {
    // @ts-expect-error
    return this.args.isAllAccountSelected || this.selectedAccounts.length === 0;
  }

  get allAccountOption() {
    return {
      id: '',
      icon: 'icon_layers_outlined',
    };
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'PowerSelect::Accounts::Multiple::Trigger': typeof PowerSelectAccountsMultipleTrigger;
  }
}
