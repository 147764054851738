export default {
  "body": "csR",
  "members": "csQ",
  "members-results": "cse",
  "members-list": "csB",
  "members-title": "csE caption-bold",
  "member": "csd",
  "empty-illustration": "csI",
  "empty-title": "cst title-3",
  "empty-description": "csM body-2",
  "invitable-members": "csP",
  "details": "csl",
  "pagination-footer": "csX"
};
