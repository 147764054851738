import { useRef, useLayoutEffect, type ReactNode } from 'react';
import { useEmberService, useFlags } from '@qonto/react-migration-toolkit/react/hooks';
import {
  prepareCashflowTimeseriesRequest,
  useCashflowTimeseries,
} from 'qonto/react/hooks/use-cashflow-timeseries';
import { CashflowPeriodRate, type CashflowPeriod } from 'qonto/react/models/cash-flow-period';
import { useOrganizationManager } from 'qonto/react/hooks/use-organization-manager';
import { CASHFLOW_FORECASTED_MONTHS_DEFAULT } from 'qonto/react/api/models/cash-flow-timeseries';
import type { LabelStatisticsResponse, UnlabeledStatisticsResponse } from '../../api/labels';
import { BalanceTable } from '../balance-table';
import { LabelsTableProvider as LabelsTable } from '../labels-table';
import { CategoriesTable } from '../categories-table/categories-table';
import { useTimeseriesBalances } from '../../hooks/use-timeseries-balances';
import { useCategoriesCashflows } from '../../hooks/use-categories-cashflow';
import styles from './styles.strict-module.css';

interface TablesProps {
  organizationId: string;
  bankAccounts: string;
  selectedPeriod: CashflowPeriod;
  selectedFrequency?: CashflowPeriodRate;
  labelStatistics: LabelStatisticsResponse;
  unlabeledStatistics: UnlabeledStatisticsResponse;
  offset?: number;
  displayedMonths?: number;
  isLoading?: boolean;
  isUpdating?: boolean;
  isError?: boolean;
  onNextMonth?: () => void;
  isLastPeriod?: boolean;
  onPreviousMonth?: () => void;
  isFirstPeriod?: boolean;
  currentDateIndex?: number;
  onForecastUpdate?: () => void;
}

export function TablesLayout({
  organizationId,
  bankAccounts,
  selectedPeriod,
  selectedFrequency,
  labelStatistics,
  unlabeledStatistics,
  offset = 0,
  displayedMonths = 6,
  isLoading = false,
  isUpdating = false,
  onNextMonth,
  isLastPeriod = false,
  onPreviousMonth,
  isFirstPeriod = false,
  currentDateIndex = -1,
  onForecastUpdate,
}: TablesProps): ReactNode {
  const targetCellRef = useRef<{
    rowIndex: number;
    columnIndex: number;
    targetTbodyRef: React.RefObject<HTMLTableSectionElement>;
    enterEditMode: boolean;
  } | null>(null);

  const flags = useFlags();
  const isForecastSlice1Enabled = flags['featureBooleanCashFlowForecastSlice-1'];

  const { organization } = useOrganizationManager();
  const abilities = useEmberService('abilities');
  const canAssignCategory = abilities.can('assign category');
  const canEditForecast = abilities.can('edit forecast cash-flow');

  const isForecastEditingEnabled =
    canEditForecast && selectedFrequency === CashflowPeriodRate.Monthly;

  const inflowsTbodyRef = useRef<HTMLTableSectionElement>(null);
  const outflowsTbodyRef = useRef<HTMLTableSectionElement>(null);

  const focusCell = (
    targetTbodyRef: React.RefObject<HTMLTableSectionElement>,
    rowIndex: number,
    columnIndex: number,
    enterEditMode: boolean,
    verticalDirection: 'up' | 'down' | 'same'
  ): void => {
    const tbody = targetTbodyRef.current;
    if (!tbody) return;

    const rows = Array.from(tbody.children) as HTMLTableRowElement[];

    let filteredRows: HTMLTableRowElement[];

    if (verticalDirection === 'down') {
      filteredRows = rows.slice(rowIndex);
    } else {
      filteredRows = rows;
    }

    const row = enterEditMode
      ? filteredRows.find(r => r.getAttribute('data-is-editable') === 'true')
      : rows[rowIndex];

    if (!row) return;

    let cell = row.children[columnIndex] as HTMLElement | undefined;
    if (cell === undefined) {
      cell = row.children[columnIndex - 1] as HTMLElement;
    }
    const button = cell.querySelector('button');
    if (!button) return;
    (button as HTMLElement).focus({ preventScroll: true });
    if (enterEditMode) {
      const editModeButton = button.parentElement?.querySelector('[data-edit-mode-button]');
      if (editModeButton) {
        (editModeButton as HTMLElement).click();
      }
    }
  };

  // Handle cell navigation
  const handleCellNavigation = ({
    rowIndex,
    columnIndex,
    direction,
    targetTbodyRef,
    enterEditMode = false,
  }: {
    rowIndex: number;
    columnIndex: number;
    direction: 'up' | 'down' | 'left' | 'right';
    targetTbodyRef: React.RefObject<HTMLTableSectionElement>;
    enterEditMode: boolean;
  }): void => {
    switch (direction) {
      case 'down':
        focusCell(targetTbodyRef, rowIndex + 1, columnIndex, enterEditMode, 'down');
        break;
      case 'up':
        if (rowIndex > 0) focusCell(targetTbodyRef, rowIndex - 1, columnIndex, enterEditMode, 'up');
        break;
      case 'left': {
        const selectedCellIndex = columnIndex - 1;
        if (columnIndex === 1 && !isFirstPeriod) {
          onPreviousMonth?.();
          targetCellRef.current = { rowIndex, columnIndex: 1, targetTbodyRef, enterEditMode };
          return;
        }
        if (selectedCellIndex > 0) {
          focusCell(targetTbodyRef, rowIndex, selectedCellIndex, enterEditMode, 'same');
        }
        break;
      }
      case 'right': {
        const selectedCellIndex = columnIndex + 1;
        if (columnIndex >= displayedMonths && !isLastPeriod) {
          onNextMonth?.();
          targetCellRef.current = {
            rowIndex,
            columnIndex: displayedMonths,
            targetTbodyRef,
            enterEditMode,
          };
          return;
        }
        if (selectedCellIndex <= displayedMonths) {
          focusCell(targetTbodyRef, rowIndex, selectedCellIndex, enterEditMode, 'same');
        }
        break;
      }
    }
  };

  const { queryKey, request } = prepareCashflowTimeseriesRequest({
    selectedFrequency,
    selectedPeriod,
    forecastedPeriods: isForecastSlice1Enabled ? CASHFLOW_FORECASTED_MONTHS_DEFAULT : 0,
    organization,
    bankAccounts,
  });
  const { data: timeseries, isPending: isLoadingTimeseries } = useCashflowTimeseries(
    request,
    queryKey
  );

  const { inflows, inflowSums, outflows, outflowSums } = useCategoriesCashflows(
    offset,
    displayedMonths,
    timeseries
  );

  const onForecastEntryUpdate = (): void => {
    onForecastUpdate?.();
  };

  useLayoutEffect(() => {
    if (targetCellRef.current) {
      const { targetTbodyRef, rowIndex, columnIndex, enterEditMode } = targetCellRef.current;
      focusCell(targetTbodyRef, rowIndex, columnIndex, enterEditMode, 'same');
      targetCellRef.current = null;
    }
  }, [timeseries, inflows, outflows]);

  const { startBalances, endBalances } = useTimeseriesBalances(offset, displayedMonths, timeseries);

  const selectedIndex = currentDateIndex >= 0 ? currentDateIndex : -1;

  return (
    <section className={styles.wrapper} data-testid="tables-layout" key="tables-layout">
      <BalanceTable
        currentDateIndex={selectedIndex}
        data={startBalances}
        data-testid="start-balance-table"
        frequency={selectedFrequency}
        isLoading={isLoading || isUpdating || isLoadingTimeseries}
        key="start-balance-table"
        numberOfColumns={displayedMonths}
        type="start"
      />

      {canAssignCategory ? (
        <>
          <CategoriesTable
            bankAccounts={bankAccounts}
            currentDateIndex={selectedIndex}
            data={inflows}
            data-testid="inflows-table"
            headerLabelKey="cash-flow-categories.group-title.inflows"
            isForecastEditingEnabled={isForecastEditingEnabled}
            isLoading={isLoadingTimeseries || isLoading || isUpdating}
            key={`inflows-${selectedFrequency}-${selectedIndex}`}
            numberOfColumns={displayedMonths}
            offset={offset}
            onCellNavigation={({ rowIndex, columnIndex, direction, enterEditMode }) => {
              handleCellNavigation({
                rowIndex,
                columnIndex,
                direction,
                targetTbodyRef: inflowsTbodyRef,
                enterEditMode,
              });
            }}
            onForecastEntryUpdate={onForecastEntryUpdate}
            sums={inflowSums}
            tbodyRef={inflowsTbodyRef}
          />

          <CategoriesTable
            bankAccounts={bankAccounts}
            currentDateIndex={selectedIndex}
            data={outflows}
            data-testid="outflows-table"
            headerLabelKey="cash-flow-categories.group-title.outflows"
            isForecastEditingEnabled={isForecastEditingEnabled}
            isLoading={isLoadingTimeseries || isLoading || isUpdating}
            key={`outflows-${selectedFrequency}-${selectedIndex}`}
            numberOfColumns={displayedMonths}
            offset={offset}
            onCellNavigation={({ rowIndex, columnIndex, direction, enterEditMode }) => {
              handleCellNavigation({
                rowIndex,
                columnIndex,
                direction,
                targetTbodyRef: outflowsTbodyRef,
                enterEditMode,
              });
            }}
            onForecastEntryUpdate={onForecastEntryUpdate}
            sums={outflowSums}
            tbodyRef={outflowsTbodyRef}
          />
        </>
      ) : (
        <LabelsTable
          data-testid="label-table"
          isLoading={isLoading}
          labelStatistics={labelStatistics}
          numberOfColumns={displayedMonths}
          offset={offset}
          organizationId={organizationId}
          unlabeledStatistics={unlabeledStatistics}
        />
      )}

      <BalanceTable
        currentDateIndex={selectedIndex}
        data={endBalances}
        data-testid="end-balance-table"
        frequency={selectedFrequency}
        isLoading={isLoading || isUpdating || isLoadingTimeseries}
        key="end-balance-table"
        numberOfColumns={displayedMonths}
        type="end"
      />
    </section>
  );
}
