/* import __COLOCATED_TEMPLATE__ from './item.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface ItemSignature {
  Element: HTMLTableRowElement;
  Args: {};
  Blocks: {
    default: [];
  };
}

const Item = templateOnlyComponent<ItemSignature>();

export default Item;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Request::MultiTransfer::Table::Item': typeof Item;
  }
}
