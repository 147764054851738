import { directDebitCollectionClientsNamespace } from 'qonto/constants/hosts';

import ReceivableInvoicesBaseAdapter from './base/receivable-invoices';

export default class CustomerAdapter extends ReceivableInvoicesBaseAdapter {
  namespace = directDebitCollectionClientsNamespace;
  pathForType() {
    return 'direct_debit_collection_clients';
  }
}
