export default {
  "sidebar": "Fp",
  "sidebar-header": "Fx",
  "close-button": "Fw",
  "setup-mode-content": "FN",
  "edit-mode-content": "Fv",
  "reminders-header": "Fo",
  "reminders-title-container": "Fn",
  "reminders-title": "Fi",
  "reminders-subtitle": "FA",
  "reminders-list": "FY",
  "reminder": "Fh",
  "reminder-due-date": "Ff",
  "upsell-button-container": "FK"
};
