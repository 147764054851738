export default {
  "attachment-viewer": "ZW",
  "sidebar": "Zu",
  "attachment-viewer-details": "RS",
  "attachment-viewer-filename": "Rc body-2",
  "attachment-viewer-actions": "Rq",
  "dropzone": "RZ",
  "dropzone-visible": "RR",
  "attachment-main": "RQ"
};
