/* import __COLOCATED_TEMPLATE__ from './item.hbs'; */
import Component from '@glimmer/component';

// @ts-expect-error
import { DUAL_NAV_STATES } from 'qonto/constants/dual-nav';

interface DualNavSettingsItemSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class DualNavSettingsItemComponent extends Component<DualNavSettingsItemSignature> {
  get isHovered() {
    // @ts-expect-error
    let { item, hoveredMenuItemId } = this.args;

    return hoveredMenuItemId === item.id;
  }

  get shouldShowArrowIcon() {
    // @ts-expect-error
    let { state } = this.args;

    return state === DUAL_NAV_STATES.EXPANDED && this.isHovered;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'DualNav::Settings::Item': typeof DualNavSettingsItemComponent;
  }
}
