export default {
  "container": "qWq",
  "security-method": "qWZ",
  "icon": "qWR",
  "body": "qWQ",
  "status": "qWe body-2",
  "device": "qWB",
  "device-icon": "qWE",
  "subtitle": "qWd body-2",
  "current": "qWI"
};
