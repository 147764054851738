export default {
  "details-box": "cgt",
  "picto": "cgM",
  "picto-status": "cgP",
  "ghost": "cgl",
  "primary-info": "cgX",
  "date": "cgC",
  "account-infobox": "cgk",
  "declined-disclaimer": "cgJ",
  "nrc-download": "cgs"
};
