import type { Amount } from 'qonto/react/models/amount';
import {
  UNCATEGORIZED_CATEGORY,
  type CashflowParentCategory,
} from 'qonto/react/models/cash-flow-category';
import type { LabelTableInterval } from './labels-cashflow-display';

export type CategoriesTableRowType = 'category' | 'subcategory' | 'uncategorized';

export interface CategoriesTableColumn {
  amount?: Amount;
  forecastAmount?: Amount;
  forecastAmountSum?: Amount;
  interval: LabelTableInterval;
}

export interface CategoriesTableRow {
  id: string | null;
  name: string;
  columns: CategoriesTableColumn[];
  color: string;
  icon?: string;
  type: CategoriesTableRowType;
  subRows?: CategoriesTableRow[];
}

export interface CategoriesSideTables {
  inflows: CategoriesTableRow[];
  outflows: CategoriesTableRow[];
  inflowSums: CategoriesTableColumn[];
  outflowSums: CategoriesTableColumn[];
}

export const getCategoryType = (category: CashflowParentCategory): CategoriesTableRowType => {
  if (category.subcategories?.length) return 'category';
  if (category.id === UNCATEGORIZED_CATEGORY.id) return 'uncategorized';
  return 'subcategory';
};
