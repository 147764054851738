import { type ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { PlusSign } from 'qonto/react/assets/icons';
import { Label as LabelComponent } from 'qonto/react/components/label';
import { BaseCell } from 'qonto/react/components/table-v2/cells/base-cell';
import { DataWithIconCell } from 'qonto/react/components/table-v2/cells/data-with-icon-cell';
import type { LabelList } from 'qonto/react/models/label';
import { Status, type Label } from 'qonto/react/graphql';
import { cellContextManager } from 'qonto/react/contexts/cell-context';
import { LABEL_CELL_CLICK_EVENT_NAME } from 'qonto/react/constants';
import { LabelCellPopover } from './popover';

interface LabelCellProps {
  label: Label | undefined;
  labelList: LabelList;
}
export function LabelCell({ label, labelList }: LabelCellProps): ReactNode {
  const { formatMessage } = useIntl();
  const segment = useEmberService('segment');

  const transaction = cellContextManager.useCellContext();
  const { id: transactionId, labels: transactionLabels, status } = transaction;
  const isDeclined = status === Status.Declined || status === Status.Reversed;

  if (isDeclined) {
    return (
      <BaseCell>
        <span data-testid="empty">-</span>
      </BaseCell>
    );
  }

  const trackCellOpen = (isOpen: boolean): void => {
    if (isOpen) {
      segment.track(LABEL_CELL_CLICK_EVENT_NAME);
    }
  };

  return (
    <BaseCell
      data-testid={`label-cell-${labelList.id}`}
      onOpenChange={trackCellOpen}
      popoverSlot={
        <LabelCellPopover
          label={label}
          labelList={labelList}
          transactionId={transactionId}
          transactionLabels={transactionLabels}
        />
      }
    >
      {label ? (
        <LabelComponent color={labelList.color}>{label.name}</LabelComponent>
      ) : (
        <DataWithIconCell
          icon={<PlusSign />}
          title={formatMessage({ id: 'transactions.modular_table.labels.add.cta' })}
        />
      )}
    </BaseCell>
  );
}
