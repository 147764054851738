export default {
  "panel-container": "dh",
  "selector-container": "df",
  "container-title": "dK",
  "panel-title": "dG",
  "panel-title-text": "dr",
  "panel-icon": "db",
  "icon": "dy",
  "animate-in": "dU",
  "panel-content": "dj",
  "error-message": "dW"
};
