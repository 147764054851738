export default {
  "row": "mZ body-1",
  "active": "mR",
  "cell": "mQ body-2",
  "empty": "me",
  "no-padding": "mB",
  "cell-content": "mE",
  "item-disabled": "md",
  "item-amount": "mI body-1",
  "centered-cell": "mt",
  "debitor": "mM",
  "item-counterparty-icon": "mP mr-16",
  "avatar": "ml",
  "item-counterparty-name": "mX",
  "item-counterparty-reference": "mC",
  "item-counterparty-name-status": "mk",
  "item-counterparty-name-text": "mJ",
  "amount-on-hold": "ms",
  "icon-on-hold": "mF"
};
