export default {
  "create-invoice-button": "qrm btn btn--primary",
  "filters-container": "qrp",
  "header-actions": "qrx",
  "product-discovery": "qrw",
  "search-field": "qrN",
  "full-width": "qrv",
  "footer-import-cta": "qro",
  "footer-show-existing-invoices-cta": "qrn"
};
