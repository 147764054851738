import { template as template_0e5ea6ff996f4faeb9507b3c04d0f0a5 } from "@ember/template-compiler";
import { on } from '@ember/modifier';
import { LinkTo } from '@ember/routing';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import Dropdown from '@qonto/ui-kit/components/dropdown';
import { Badge } from '@repo/design-system-kit';
import { t } from 'ember-intl';
import track from 'qonto/helpers/track';
import styles from './section.strict-module.css';
import ApprovalWorkflowStateVisualizer from './visualizer';
export default class ApprovalWorkflowStateSidebarSection extends Component {
    badge = Badge;
    @service
    abilities;
    @service
    userManager;
    @service
    intl;
    get canSeeDropdown() {
        return this.abilities.can('create approval-workflow');
    }
    get props() {
        return {
            type: 'newFeature',
            text: this.intl.t('promotions.system.promotional-card.new.badge'),
            'data-test-approval-workflow-new-badge': ''
        };
    }
    static{
        template_0e5ea6ff996f4faeb9507b3c04d0f0a5(`
    <div class={{styles.wrapper}} ...attributes>
      <div class={{styles.workflow}}>
        <div class='mb-16 {{styles.section-title}}'>
          <h2
            class='{{styles.subtitle}} caption-bold mr-8'
            data-test-approval-workflow-sidebar-request-details
          >
            {{t 'approval-workflows.requests.title'}}
          </h2>
          {{#if this.canSeeDropdown}}
            <Dropdown
              @isSmall={{true}}
              @targetAttachment='bottom right'
              @menuClass={{styles.menu}}
              class={{styles.dropdown-menu}}
              data-test-approval-workflow-manage-approval-workflows
            >
              <:menu>
                <LinkTo
                  @route='settings.approval-workflows'
                  class='body-2 {{styles.dropdown-option}}'
                  {{on 'click' (track 'workflow-detail_manage-approval-button_clicked')}}
                  data-test-approval-workflow-manage-approval-workflows-link
                >
                  {{t 'approval-workflows.requests.status.btn.edit'}}
                </LinkTo>
              </:menu>
            </Dropdown>
          {{/if}}
        </div>
        <ApprovalWorkflowStateVisualizer
          @approvalWorkflowState={{@approvalWorkflowState}}
          @supplierSnapshot={{@supplierSnapshot}}
          @requestType={{@requestType}}
          data-test-approval-workflow-visualizer
        />
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
