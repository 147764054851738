export const QUOTE_EXPIRATION_TIME_IN_SECONDS = 30 * 60;
export const RATE_EXPIRATION_TIME_IN_SECONDS = 2 * 24 * 60 * 60;

export const QUOTE_TYPE = {
  SOURCE: 'SOURCE',
  TARGET: 'TARGET',
};

export const QUOTE_STATUSES = {
  PENDING: 'PENDING',
};

export const RATE_TYPE = {
  FIXED: 'FIXED',
  FLOATING: 'FLOATING',
};
