/* import __COLOCATED_TEMPLATE__ from './advantages.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { CARD_LEVELS, CARD_WITHDRAW_FEE, FORMAT_PRICE_OPTIONS } from 'qonto/constants/cards';

interface CardComparisonAdvantagesSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class CardComparisonAdvantages extends Component<CardComparisonAdvantagesSignature> {
  @service declare intl: Services['intl'];

  get atmFee() {
    // @ts-expect-error
    let { cardLevel } = this.args;

    switch (cardLevel) {
      case CARD_LEVELS.STANDARD: {
        // @ts-expect-error
        let fee = this.intl.formatNumber(CARD_WITHDRAW_FEE, FORMAT_PRICE_OPTIONS);

        return {
          label: this.intl.t('cards.details.advantages.atm-fee.standard.label'),
          value: this.intl.t('cards.details.advantages.atm-fee.standard.value', {
            fee,
          }),
        };
      }
      case CARD_LEVELS.PLUS:
        return {
          label: this.intl.t('cards.details.advantages.atm-fee.plus.label'),
          value: this.intl.t('cards.details.advantages.atm-fee.plus.value'),
        };
      default:
        return {};
    }
  }

  get fxFee() {
    // @ts-expect-error
    let { cardLevel, foreignFees } = this.args;

    let fee = this.intl.formatNumber(foreignFees, {
      minimumFractionDigits: 0,
      style: 'percent',
    });

    switch (cardLevel) {
      case CARD_LEVELS.STANDARD:
        return {
          label: this.intl.t('cards.details.advantages.fx.standard.label'),
          value: this.intl.t('cards.details.advantages.fx.standard.value', { fee }),
        };
      case CARD_LEVELS.PLUS:
        return {
          label: this.intl.t('cards.details.advantages.fx.plus.label'),
          value: this.intl.t('cards.details.advantages.fx.plus.value', { fee }),
        };
      default:
        return {};
    }
  }

  get printType() {
    // @ts-expect-error
    let { cardLevel } = this.args;

    let label = this.intl.t('cards.details.advantages.card-details.label');

    switch (cardLevel) {
      case CARD_LEVELS.METAL:
        return {
          label,
          value: this.intl.t('cards.details.advantages.card-details.value.metal'),
        };
      case CARD_LEVELS.PLUS:
        return {
          label,
          value: this.intl.t('cards.details.advantages.card-details.value.plus'),
        };
      case CARD_LEVELS.STANDARD:
        return {
          label,
          value: this.intl.t('cards.details.advantages.card-details.value.standard'),
        };
      default:
        return {};
    }
  }

  get insurance() {
    // @ts-expect-error
    let { cardLevel } = this.args;

    switch (cardLevel) {
      case CARD_LEVELS.METAL:
        return {
          label: this.intl.t('cards.details.advantages.insurance-coverage.label.metal'),
          value: this.intl.t('cards.details.advantages.insurance-coverage.value.metal'),
        };
      case CARD_LEVELS.PLUS:
        return {
          label: this.intl.t('cards.details.advantages.insurance-coverage.label.plus'),
          value: this.intl.t('cards.details.advantages.insurance-coverage.value.plus'),
        };
      case CARD_LEVELS.STANDARD:
        return {
          label: this.intl.t('cards.details.advantages.insurance-coverage.label.standard'),
          value: this.intl.t('cards.details.advantages.insurance-coverage.value.standard'),
        };
      default:
        return null;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Card::Modals::Comparison::Advantages': typeof CardComparisonAdvantages;
  }
}
