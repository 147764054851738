export default {
  "tile-layout": "eC",
  "focus": "ek",
  "no-shadow": "eJ",
  "tile-layout--start-verification": "es",
  "header": "eF",
  "headings": "eg",
  "management": "eD",
  "logo": "eV",
  "title": "eL title-4",
  "name": "eT title-4",
  "loading": "ez",
  "menu-item": "ea body-2",
  "close-account": "eH",
  "content": "eO"
};
