export default {
  "sidebar-header": "qDN",
  "sidebar-header__top": "qDv",
  "sidebar-header__body": "qDo",
  "sidebar-header__content": "qDn",
  "sidebar-header__date": "qDi",
  "infobox": "qDA",
  "infobox--link": "qDY",
  "ghost": "qDh",
  "fail": "qDf",
  "r-transaction-disclaimer": "qDK",
  "hold-disclaimer": "qDG",
  "transaction-link": "qDr body-2",
  "icon-link": "qDb"
};
