import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { isArray } from '@ember/array';
import { camelize } from '@ember/string';

export default class ApprovalWorkflowModel extends Model {
  // @ts-expect-error
  @attr requestType;
  // @ts-expect-error
  @attr isValid;
  // @ts-expect-error
  @attr updatedAt;
  // @ts-expect-error
  @attr isDefault;
  // @ts-expect-error
  @attr workflowErrors;

  // @ts-expect-error
  @belongsTo('membership', { async: false, inverse: null }) createdBy;
  // @ts-expect-error
  @belongsTo('membership', { async: false, inverse: null }) updatedBy;
  // @ts-expect-error
  @belongsTo('organization', { async: false, inverse: null }) organization;

  // @ts-expect-error
  @hasMany('approval-workflow/ruleset', { async: false, inverse: null }) rulesets;

  // @ts-expect-error
  async save() {
    try {
      await super.save();
    } catch (error) {
      // @ts-expect-error
      this.updateErrors(error.errors);
      throw error;
    }
  }

  // @ts-expect-error
  updateErrors(errors) {
    this.removeErrors();
    this.addErrors(errors);
  }

  removeErrors() {
    /* eslint-disable ember/no-array-prototype-extensions */
    // false positive
    this.errors.clear();
    // @ts-expect-error
    this.rulesets.forEach(ruleset => {
      ruleset.errors.clear();
      // @ts-expect-error
      ruleset.conditions.forEach(condition => condition.errors.clear());
      // @ts-expect-error
      ruleset.steps.forEach(step => step.errors.clear());
    });
    /* eslint-enable ember/no-array-prototype-extensions */
  }

  // @ts-expect-error
  addErrors(errors) {
    // @ts-expect-error
    errors?.forEach(error => {
      let pointer = error.detail?.source?.pointer || error.source?.pointer;
      let code = error.detail?.code || error.code;
      this.addError(pointer, code);
    });
  }

  // @ts-expect-error
  addError(pointer, code) {
    pointer = pointer.replace('/approval_workflow/', '').replace(/value\/.*$/, 'value');
    let pathParts = pointer.split('/').map(camelize);
    // error is for a relationship
    if (pathParts.length > 1) {
      let attribute = pathParts.pop();
      let item = this;

      for (let i = 0; item && i < pathParts.length; i++) {
        let path = pathParts[i];
        // @ts-expect-error
        item = isArray(item) ? item[path] : item.get(path);
      }

      if (item) {
        item.errors.add(attribute, code);
      } else {
        // if we can't find the submodel the error belongs to
        // assign it at the base
        this.errors.add(camelize(pointer), code);
      }
    } else {
      this.errors.add(camelize(pointer), code);
    }
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'approval-workflow': ApprovalWorkflowModel;
  }
}
