export default {
  "button-wrapper": "qFU",
  "btn-filter": "qFj",
  "name-wrapper": "qFW",
  "preset-name": "qFu",
  "cancel-placeholder": "qgS",
  "cancel-button": "qgc",
  "dropdown-opened": "qgq",
  "dropdown-closed": "qgZ",
  "dropdown-menu": "qgR",
  "loading-item": "qgQ",
  "placeholder": "qge",
  "list-body": "qgB",
  "list-item": "qgE",
  "error-state": "qgd body-2",
  "warning": "qgI",
  "warning-icon": "qgt",
  "footer": "qgM",
  "add-icon": "qgP",
  "empty-state": "qgl"
};
