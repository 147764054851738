import { htmlSafe } from '@ember/template';

import { variation } from 'ember-launch-darkly';

import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import { ErrorInfo } from 'qonto/utils/error-info';
import getAnnouncement from 'qonto/utils/get-announcement';

/**
 * @typedef {import('./config-types').TopBannerConfig} TopBannerConfig
 */

/**
 * @type {Array<TopBannerConfig>}
 *
 * Expected configuration coming from LaunchDarkly:
 *
 * {
 *   "message": {
 *     "en": "",
 *     "fr": "",
 *     "de": "",
 *     "es": "",
 *     "it": "",
 *     "pt": "",
 *   },
 *   "id": "unique-key",
 * }
 */
export const TEMPORARY_ANNOUNCEMENT_CONFIG = [
  {
    id: 'temporary-announcement',
    type: 'warning',
    componentName: 'topbar/temporary-announcement',
    model: ({ localeManager }) => {
      return {
        data: variation('operational--temporary-top-banner'),
        localeManager,
      };
    },
    message: ({ data, localeManager }) => {
      return data.message[localeManager.locale];
    },
    shouldDisplay: ({ data }) => {
      let hasMessage = Boolean(data?.message);
      let wasDismissed = Boolean(safeLocalStorage.getItem(data?.id));

      return hasMessage && !wasDismissed;
    },
    extraProps: ({ data }) => ({ bannerId: data.id }),
  },
  {
    id: 'temporary-announcement-firebase',
    type: 'info',
    model: async ({ organizationManager, localeManager }) => {
      let { organization, currentAccount } = organizationManager;

      let cbs = currentAccount ? currentAccount.coreBankingSystem : organization.coreBankingSystem;
      let options = {
        locale: localeManager.locale,
        cbs,
      };

      let messages;

      try {
        messages = await getAnnouncement(options);
      } catch (error) {
        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }
      }

      return {
        activeMessage: messages?.[0],
      };
    },
    message: ({ activeMessage }) => htmlSafe(activeMessage),
    shouldDisplay: ({ activeMessage }) => {
      if (activeMessage === undefined) {
        return false;
      }
      return true;
    },
  },
];
