/* import __COLOCATED_TEMPLATE__ from './timeline.hbs'; */
import Component from '@glimmer/component';

import { SkeletonLoader } from '@repo/design-system-kit';

import { Event } from 'qonto/react/components/transfers/international-out/timeline/event';
import {
  type ProcessingEvent,
  ProcessingEventName,
  ProcessingEventStatus,
} from 'qonto/services/international-out/types';

interface Signature {
  Args: {
    events: ProcessingEvent[];
    isLoading?: boolean;
  };
  Element: HTMLDivElement;
}

const UNKNOWN_EVENT = {
  name: ProcessingEventName.Unknown,
  status: ProcessingEventStatus.Awaiting,
  date: null,
} as const;

export const DEFAULT_LOADING_OPTIONS = {
  stepsCount: 4,
  linesCount: 2,
} as const;

export default class TransfersInternationalOutTimelineComponent extends Component<Signature> {
  eventComponent = Event;

  placeholderLine: typeof SkeletonLoader.Line = SkeletonLoader.Line;

  #loadingOptions = DEFAULT_LOADING_OPTIONS;

  get #loadingEvents(): ProcessingEvent[] {
    return Array.from({ length: this.#loadingOptions.stepsCount }, (_, index) => ({
      ...UNKNOWN_EVENT,
      order: index,
    }));
  }

  get #sortedEvents(): ProcessingEvent[] {
    if (!Array.isArray(this.args.events)) {
      return [];
    }

    return [...this.args.events].sort(
      (firstEvent, secondEvent) => firstEvent.order - secondEvent.order
    );
  }

  get events(): ProcessingEvent[] {
    return this.args.isLoading ? this.#loadingEvents : this.#sortedEvents;
  }

  isFirst = (event: ProcessingEvent): boolean => {
    return (
      event.name === ProcessingEventName.Creation ||
      event.order === Math.min(...this.events.map(event => event.order))
    );
  };

  isLast = (event: ProcessingEvent): boolean => {
    return (
      event.name === ProcessingEventName.Completion ||
      event.order === Math.max(...this.events.map(event => event.order))
    );
  };

  isInProgress = (event: ProcessingEvent): boolean => {
    return event.status === ProcessingEventStatus.InProgress;
  };

  isAwaiting = (event: ProcessingEvent): boolean => {
    return event.status === ProcessingEventStatus.Awaiting;
  };

  isCompleted = (event: ProcessingEvent): boolean => {
    return event.status === ProcessingEventStatus.Completed;
  };

  isLastCompleted = (event: ProcessingEvent): boolean => {
    let eventIndex = this.events.indexOf(event);
    if (eventIndex === -1 || eventIndex === this.events.length - 1) {
      return false;
    }

    // The type assertion is acceptable because we previously made sure that the event is not the last one in the list.
    // Therefore, the next event should exist.
    let nextEvent = this.events[eventIndex + 1] as ProcessingEvent;

    return this.isCompleted(event) && !this.isCompleted(nextEvent);
  };

  isSuccessful = (event: ProcessingEvent): boolean => {
    return this.isCompleted(event) && event.name === ProcessingEventName.Completion;
  };
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transfers::InternationalOut::Timeline': typeof TransfersInternationalOutTimelineComponent;
  }
}
