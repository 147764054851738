export default {
  "summary-wrapper": "cho",
  "summary-title": "chn",
  "summary-list": "chi",
  "summary-list-item-wrapper": "chA",
  "summary-list-item-record": "chY",
  "summary-list-item-avatar": "chh",
  "summary-list-item-avatar-image": "chf",
  "summary-list-item-name-count": "chK",
  "item-count": "chG"
};
