/* import __COLOCATED_TEMPLATE__ from './referral-list-item.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface ReferralListItemSignature {
  Element: HTMLDivElement;
  Args: {};
  Blocks: {
    default: [];
  };
}

const ReferralListItem = templateOnlyComponent<ReferralListItemSignature>();

export default ReferralListItem;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    ReferralListItem: typeof ReferralListItem;
  }
}
