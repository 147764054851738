export default {
  "mileage-validation": "qSg",
  "actions": "qSD",
  "action-btn": "qSV",
  "approve-btn": "qSL",
  "request-mileage-validation": "qST",
  "account-selector": "qSz",
  "has-error": "qSa",
  "funds-disclaimer": "qSH",
  "account-options": "qSO",
  "account-balance": "qSm",
  "warnings": "qSp"
};
