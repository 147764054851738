export default {
  "invoice-sidebar": "coF",
  "body": "cog",
  "box": "coD",
  "box-header": "coV",
  "footer": "coL",
  "footer-button": "coT",
  "footer-button-tooltip": "coz",
  "reduced-fontsize": "coa",
  "sidebar-box": "coH",
  "border-top": "coO",
  "row": "com",
  "status-container": "cop",
  "due-date-container": "cox",
  "greyed-out": "cow",
  "struck-through": "coN",
  "overdue": "cov",
  "primary-actions": "coo coH",
  "primary-action": "con",
  "danger-action": "coi",
  "button-icon": "coA",
  "button-label": "coY body-2",
  "related-credit-note-title": "coh caption-bold",
  "paid-at-placeholder": "cof",
  "collapsible-timeline": "coK",
  "timeline-status-label": "coG",
  "related-documents": "cor"
};
