export default {
  "sub-menu": "wJ",
  "collapsed": "ws",
  "collapse-left": "wF",
  "responsive": "wg",
  "expanded": "wD",
  "no-slide": "wV",
  "visible": "wL",
  "hidden": "wT",
  "group": "wz",
  "promo-box": "wa"
};
