import { InvalidError } from '@ember-data/adapter/error';
import Model, { attr, hasMany } from '@ember-data/model';
import { get } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import { waitFor } from '@ember/test-waiters';

// @ts-expect-error
import { apiAction } from '@mainmatter/ember-api-actions';
import { bool } from 'macro-decorators';

// @ts-expect-error
import { errorsArrayToHash } from 'qonto/utils/errors-array-to-hash';
// @ts-expect-error
import titleize from 'qonto/utils/titleize';
// @ts-expect-error
import Validations from 'qonto/validations/user';

// @ts-expect-error
export default class userModel extends Model.extend(Validations) {
  @service declare sessionManager: Services['sessionManager'];
  @service declare localeManager: Services['localeManager'];
  @service declare networkManager: Services['networkManager'];
  @service declare intl: Services['intl'];

  @attr('string') declare locale: string;
  @attr('string') declare gender: string;
  // @ts-expect-error
  @attr birthdate;
  @attr('string') declare birthCity: string;
  @attr('string') declare birthCountry: string;

  @attr('string') declare firstName: string;
  @attr('string') declare lastName: string;
  @attr('string') declare email: string;
  @attr('string') declare phoneNumber: string;
  // @ts-expect-error
  @attr zendesk;

  // @ts-expect-error
  @attr flags;
  @attr('string') declare slug: string;
  @attr('string') declare timezone: string;
  @attr('string') declare password: string;
  @attr('string') declare currentPassword: string;
  @attr('string') declare code: string;
  @attr('string') declare pendingEmail: string;
  @attr('string') declare pendingEmailConfirmationCode: string;
  @attr('boolean') declare mfaEnabled: boolean;
  @attr('string') declare mfaMethod: string;
  // @ts-expect-error
  @attr italianFiscalCode;
  // @ts-expect-error
  @attr() scaEnabled;
  // @ts-expect-error
  @attr() loginToken;

  // @ts-expect-error
  @attr('file') avatar;
  // @ts-expect-error
  @attr('file') avatarThumb;
  // @ts-expect-error
  @attr('file') defaultAvatar;
  // @ts-expect-error
  @attr('file') defaultAvatarThumb;
  @attr('boolean') declare hasDefaultAvatar: boolean;

  // @ts-expect-error
  @hasMany('membership', { async: false, inverse: 'user' }) memberships;

  get fullName() {
    let firstName = this.firstName?.trim();
    let lastName = this.lastName?.trim();
    if (firstName && lastName) {
      return titleize(`${firstName} ${lastName}`);
    }
  }

  get hasPassword() {
    return this.flags.has_password;
  }

  get localeObject() {
    // @ts-expect-error
    return this.intl.getLocales.find(item => get(item, 'code') === this.locale);
  }

  set localeObject(val) {
    this.set('locale', val?.code);
  }

  get organizations() {
    // @ts-expect-error
    return this.memberships.map(item => get(item, 'organization'));
  }

  // @ts-expect-error
  @bool('avatar.fileUrl') hasAvatar;

  get picture() {
    let hasNoAvatar = !this.hasAvatar;
    return hasNoAvatar && this.hasDefaultAvatar ? this.defaultAvatar : this.avatar;
  }

  @waitFor
  async removeAvatar() {
    let data = { user: { avatar: null } };
    let response = await apiAction(this, { method: 'PUT', data });
    this.store.pushPayload(response);
  }

  @waitFor
  async changePassword() {
    let { current_password, password } = this.serialize();
    let data = { user: { current_password, password } };

    try {
      return await apiAction(this, {
        requestType: 'createRecord',
        method: 'POST',
        path: 'passwords/change',
        data,
      });
    } catch (error) {
      // @ts-expect-error
      if (error instanceof InvalidError && error.errors) {
        // @ts-expect-error
        let errors = errorsArrayToHash(error.errors);
        this.networkManager.errorModelInjector(this, errors);
      }

      throw error;
    }
  }

  @waitFor
  async confirmWithPassword() {
    let { current_password } = this.serialize();
    let data = { user: { current_password } };

    try {
      return await apiAction(this, {
        requestType: 'createRecord',
        method: 'POST',
        path: 'passwords/confirm',
        data,
      });
    } catch (error) {
      // @ts-expect-error
      if (error instanceof InvalidError && error.errors) {
        // @ts-expect-error
        let errors = errorsArrayToHash(error.errors);
        this.networkManager.errorModelInjector(this, errors);
      }

      throw error;
    }
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    user: userModel;
  }
}
