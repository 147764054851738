import Controller from '@ember/controller';
import { action } from '@ember/object';
import { debounce } from '@ember/runloop';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import RSVP from 'rsvp';

import { SearchFieldWithDebounce } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';

import { getEmptyStateConfig } from 'qonto/constants/empty-states/supplier-invoices';
import {
  getTrackingNameAndProperties,
  LAYOUT,
  TRACKING_ORIGINS,
  TYPES,
} from 'qonto/constants/empty-states/system';
import { PAY_LATER_ELIGIBILITY_STATUSES } from 'qonto/constants/financing';
import { apiBaseURL, supplierInvoiceNamespace } from 'qonto/constants/hosts';
import { STORAGE_KEYS } from 'qonto/constants/international-out/storage';
import {
  BASE_INSIGHTS,
  DUE_DATE_FILTER,
  DUE_FILTER_EVENT_MAP,
  INVOICE_SOURCES,
  INVOICE_STATUSES,
  SORT_TABS_MAP,
  TABS,
} from 'qonto/constants/supplier-invoice';
import { DEBOUNCE_MS } from 'qonto/constants/timers';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import { InstructionalTooltip } from 'qonto/react/components/product-discovery/instructional-tooltip';
import { SupplierInvoicesTabs } from 'qonto/react/components/supplier-invoices/tabs';
import { ErrorInfo } from 'qonto/utils/error-info';
import { deserializeArrayQP, serializeArrayQP } from 'qonto/utils/query-params';

export const IN_CONTEXT_INTEGRATIONS_VIEWED_KEY = 'supplier-invoices-ici-viewed';

export default class SupplierInvoicesController extends Controller {
  SearchBar = SearchFieldWithDebounce;
  SupplierInvoicesTabs = SupplierInvoicesTabs;
  InstructionalTooltip = InstructionalTooltip;

  @service intl;
  @service router;
  @service organizationManager;
  @service abilities;
  @service supplierInvoicesUploadManager;
  @service supplierInvoicesInsights;
  @service segment;
  @service emptyStates;
  @service flowLinkManager;
  @service subscriptionManager;
  @service modals;
  @service sentry;
  @service toastFlashMessages;
  @service networkManager;
  @service store;

  queryParams = [
    'page',
    'perPage',
    'status',
    'sortBy',
    'supplierInvoiceId',
    'transferStatus',
    'items',
    'dueDate',
    'supplierId',
    'tab',
    'query',
    'matchedTransactions',
    'documentType',
    'hasMissingData',
    'paymentDate',
    'approverId',
  ];

  addInvoicesModal = null;
  isAddInvoicesModalOpen = false;

  @tracked suppliersOptions;
  @tracked isCockpitFolded = false;
  @tracked page = 1;
  @tracked perPage = 25;
  @tracked status;
  @tracked sortBy;
  @tracked showDropzone = false;
  @tracked isSideDrawerDisplayed = false;
  @tracked items = '';
  @tracked dueDate;
  @tracked supplierId = undefined;
  @tracked approverId = undefined;
  @tracked tab = TABS.TO_PAY;
  @tracked query = '';
  @tracked matchedTransactions;
  @tracked assignedToYouActive = true;
  @tracked documentType = null;
  @tracked hasMissingData = null;
  @tracked paymentDate;
  @tracked userClosedInternationalOutTooltip = Boolean(
    safeLocalStorage.getItem(STORAGE_KEYS.INSTRUCTIONAL_TOOLTIP)
  );

  get isInternationalOutTooltipDismissed() {
    return (
      !variation('feature--boolean-ap-fx-out-integration') ||
      !this.model.isEligibleToInternationalTransfers ||
      this.userClosedInternationalOutTooltip
    );
  }

  get isCockpitVisible() {
    return this.abilities.can('access insights supplier-invoice');
  }

  get selectedItems() {
    return deserializeArrayQP(this.items).filter(selectedItemId =>
      this.supplierInvoices.some(({ id }) => id === selectedItemId)
    );
  }

  get dynamicSortBy() {
    if (this.sortBy) return this.sortBy;
    return SORT_TABS_MAP(variation('feature--boolean-approval-workflow-for-supplier-invoices'))[
      this.tab
    ];
  }

  get isArchiveBulkActionDisabled() {
    return this.selectedItems
      .map(invoiceId => this.supplierInvoices.find(({ id }) => invoiceId === id))
      .some(invoice => [INVOICE_STATUSES.paid, INVOICE_STATUSES.archived].includes(invoice.status));
  }

  get showSupplierFilter() {
    return this.abilities.can('view supplier') && this.suppliers.length > 0;
  }

  get toReviewFilterSelected() {
    if (this.tab !== TABS.ALL_INVOICES) return undefined;

    if (this.documentType) return this.documentType;
    if (this.dueDate) return this.dueDate;
    if (this.hasMissingData) return 'missing_data';
    if (this.matchedTransactions) return 'not_reconciled';
    return undefined;
  }

  get filterEventsOptions() {
    return {
      tab: this.tab,
      supplier_filter: Boolean(this.supplierId),
      status_filter: this.status,
      'to-review_filter': this.toReviewFilterSelected,
      'due-date_filter': this.dueDate,
      'payment-date_filter': this.paymentDate,
      unmatched_filter: Boolean(this.matchedTransactions),
      search_key: Boolean(this.query),
    };
  }

  get selectedSupplierName() {
    return this.suppliers?.find(({ id }) => id === this.supplierId);
  }

  get suppliers() {
    if (!this.suppliersOptions && !this.model.fetchSuppliersTask.lastSuccessful) return [];

    let suppliers = this.suppliersOptions || this.model.fetchSuppliersTask.lastSuccessful.value;

    if (this.supplierId) {
      if (this.suppliersOptions?.some(({ id }) => id === this.supplierId)) return suppliers;

      let newSuppliersList = [...suppliers, this.store.peekRecord('supplier', this.supplierId)];
      return [...new Set(newSuppliersList)];
    }
    return suppliers;
  }

  get showBulkActions() {
    return this.numberOfSelected > 0;
  }

  get numberOfSelected() {
    return this.selectedItems.length;
  }

  get isImportedTab() {
    return this.status === INVOICE_STATUSES.toReview;
  }

  get isCompletedTab() {
    return (
      this.status?.includes(INVOICE_STATUSES.archived) ||
      this.status?.includes(INVOICE_STATUSES.paid)
    );
  }

  get isFiltered() {
    if (this.supplierId) return true;
    if (this.isCompletedTab) return this.status?.split(',').length === 1;
    return Boolean(this.dueDate);
  }

  get showStatusOrDueDateFilter() {
    return this.isImportedTab || this.isCompletedTab;
  }

  get showFilterRow() {
    if (
      variation('feature--boolean-approval-workflow-for-supplier-invoices') &&
      [TABS.TO_APPROVE, TABS.TO_PAY].includes(this.tab)
    ) {
      return true;
    }
    if (this.tab === TABS.TASKS) return false;

    return !this.isEmptyLocally || this.hasActiveFilters || this.isLoading;
  }

  get filterOptions() {
    if (this.isImportedTab)
      return [
        {
          code: DUE_DATE_FILTER.PAST_AND_TODAY,
          value: this.intl.t('supplier-invoices.imported.filter.option.due'),
        },
        {
          code: DUE_DATE_FILTER.FUTURE,
          value: this.intl.t('supplier-invoices.imported.filter.option.upcoming'),
        },
        {
          code: DUE_DATE_FILTER.MISSING_DATE,
          value: this.intl.t('supplier-invoices.imported.filter.option.missing-due-date'),
        },
      ];
    else {
      return [
        {
          code: INVOICE_STATUSES.paid,
          value: this.intl.t('supplier-invoices.preview.status.paid'),
        },
        {
          code: INVOICE_STATUSES.archived,
          value: this.intl.t('supplier-invoices.preview.status.archived'),
        },
      ];
    }
  }

  get filterPlaceholder() {
    if (this.isImportedTab) {
      return this.intl.t('supplier-invoices.imported.filter.label');
    }

    return this.intl.t('supplier-invoices.completed.filter.label');
  }

  get filterValue() {
    if (this.isImportedTab) {
      return this.filterOptions.find(el => el.code === this.dueDate);
    }

    return this.filterOptions.find(el => el.code === this.status);
  }

  get hasActiveFilters() {
    if (this.model.supplierInvoicesTask.last?.isError) {
      return false;
    }
    return (
      Boolean(this.status) ||
      Boolean(this.filterValue) ||
      Boolean(this.supplierId) ||
      Boolean(this.dueDate) ||
      Boolean(this.query) ||
      Boolean(this.matchedTransactions) ||
      Boolean(this.documentType) ||
      Boolean(this.hasMissingData) ||
      Boolean(this.paymentDate)
    );
  }

  get emptyStateRevampOptions() {
    if (this.isLoading) {
      return;
    }

    let tab = this.tab;
    if (tab === 'pending' && !this.abilities.can('review transfer request')) {
      tab = 'requests';
    }

    let options = this.emptyStates.getEmptyStateOptions({
      isOrgEligibleForFeature: true,
      isEmptyGlobally: this.isEmptyGlobally,
      isEmptyLocally: this.isEmptyLocally,
      hasActiveFilterOrSearch: this.hasActiveFilters,
      config: getEmptyStateConfig(
        this.intl,
        variation('feature--boolean-approval-workflow-for-supplier-invoices'),
        this.abilities.can('update supplierInvoice')
      ),
      customInputs: {
        tab,
      },
      abilities: {
        canUploadSupplierInvoices: this.abilities.can('upload supplierInvoice'),
        canUpdateSupplierInvoices: this.abilities.can('update supplierInvoice'),
        cannotReadTransfers: this.abilities.cannot('read transfer'),
        cannotAccessRequestTransfers: this.abilities.cannot(
          'access request transfer supplierInvoice'
        ),
      },
    });

    return options;
  }

  get trialInfo() {
    return this.subscriptionManager.currentSubscription.findTrial('supplierInvoices');
  }

  get isEmptyGlobally() {
    if (
      (this.abilities.cannot('access insights supplier-invoice') &&
        (this.tab !== TABS.ALL_INVOICES || this.supplierInvoices.length !== 0)) ||
      variation('feature--boolean-improve-first-time-exp-si')
    ) {
      return false;
    }

    return this.tab === TABS.TASKS
      ? this.supplierInvoicesInsights.totalCount === 0 &&
          this.supplierInvoices.length === 0 &&
          !this.hasActiveFilters
      : this.supplierInvoicesInsights.totalCount === 0 && !this.hasActiveFilters;
  }

  get isEmptyLocally() {
    return this.supplierInvoices.length === 0;
  }

  get isNotInformEmptyState() {
    return this.emptyStateRevampOptions?.layout !== LAYOUT.INFORM;
  }

  get isOrganisationItalian() {
    return this.organizationManager.organization.legalCountry === 'IT';
  }

  inContextIntegrationsViewed =
    safeLocalStorage.getItem(IN_CONTEXT_INTEGRATIONS_VIEWED_KEY) === 'true';

  get shouldAnimateInContextIntegrations() {
    return (
      variation('operational--supplier-invoices-animate-ici-trigger') &&
      !this.inContextIntegrationsViewed
    );
  }

  get isLoading() {
    return (
      this.model.fetchSuppliersTask.isRunning ||
      this.model.supplierInvoicesTask.isRunning ||
      this.supplierInvoicesInsights.isFetchingInsights ||
      this.model.fetchHasNotCompletedEinvoiceTask.isRunning ||
      this.model.payLaterEligibilityTask.isRunning
    );
  }

  get localState() {
    let isShowRoute = this.router.currentRouteName.includes('supplier-invoices.show');

    let localState = {
      isEmpty: false,
      isError: false,
      isLoading: false,
    };

    if (this.isLoading && !isShowRoute) {
      localState.isLoading = true;
    } else if (this.model.supplierInvoicesTask.last?.isError) {
      localState.isError = true;
    }

    return localState;
  }

  get supplierInvoices() {
    return this.model.supplierInvoicesTask.lastSuccessful?.value || [];
  }

  get isPayLaterEligible() {
    return (
      this.model.payLaterEligibilityTask.lastSuccessful?.value?.eligibility ===
      PAY_LATER_ELIGIBILITY_STATUSES.ELIGIBLE
    );
  }

  get firstEInvoiceId() {
    let hasNotCompletedEinvoice =
      this.model.fetchHasNotCompletedEinvoiceTask.lastSuccessful?.value ?? false;

    if (!hasNotCompletedEinvoice) {
      return '';
    }

    let firstIncompleteEinvoice = this.supplierInvoices.find(invoice => {
      let { source, status, isEinvoice } = invoice;

      let isFrenchEInvoice = source === INVOICE_SOURCES.eInvoicing;
      let isGermanEInvoice = isEinvoice ?? false;
      let isIncomplete = [INVOICE_STATUSES.toPay, INVOICE_STATUSES.toReview].includes(status);

      let { organization } = this.organizationManager;
      switch (organization?.legalCountry) {
        case 'DE':
          return isGermanEInvoice && isIncomplete;

        case 'FR':
          return isFrenchEInvoice && isIncomplete;

        default:
          return false;
      }
    });

    return firstIncompleteEinvoice?.id || '';
  }

  get insightLabels() {
    return BASE_INSIGHTS;
  }

  get currentInsights() {
    return this.supplierInvoicesInsights.currentInsights;
  }

  get previousInsights() {
    return this.supplierInvoicesInsights.previousInsights;
  }

  get isExportButtonEnabled() {
    return this.model.supplierInvoicesTask.isRunning || !this.isEmptyGlobally;
  }

  get importingPopoverCopies() {
    return {
      'in-progress': 'supplier-invoices.importing-modal.title.in-progress',
      errors: 'supplier-invoices.importing-modal.title.errors',
      complete: 'supplier-invoices.importing-modal.title.complete',
    };
  }

  get receiptForwardEmail() {
    return this.model.fetchReceiptForwardEmailTask.lastSuccessful?.value?.email;
  }

  searchSuppliers(query, resolve, reject) {
    return this.model.fetchSuppliersTask
      .perform(query)
      .catch(error => reject(error))
      .then(res => resolve(res));
  }

  @action
  handleCloseInternationalOutTooltip() {
    safeLocalStorage.setItem(STORAGE_KEYS.INSTRUCTIONAL_TOOLTIP, Date.now());
    this.userClosedInternationalOutTooltip = true;
  }

  @action
  onUploadCtaClick() {
    this.handleClickTracking('page_header');
    this.handleCloseInternationalOutTooltip();
  }

  @action openAddInvoicesModal() {
    this.handleClickTracking('page_header');
    this.isAddInvoicesModalOpen = true;
    this.addInvoicesModal = this.modals.open('supplier-invoices/add-invoices-modal', {
      receiptForwardEmail: this.receiptForwardEmail,
      onClose: () => {
        this.isAddInvoicesModalOpen = false;
      },
    });
  }

  @action onSearchChange(query) {
    this.query = query;
    this.items = '';
  }

  @action toggleNoMatchedTransactions(status) {
    if (!status) {
      this.matchedTransactions = null;
      return;
    }
    this.matchedTransactions = true;
  }

  resetFilters() {
    this.status = undefined;
    this.items = '';
    this.dueDate = undefined;
    this.supplierId = undefined;
    this.query = '';
    this.matchedTransactions = undefined;
    this.documentType = null;
    this.hasMissingData = null;
    this.paymentDate = undefined;
    this.approverId = undefined;
    this.sortBy = null;
  }

  @action updateTab(tab) {
    this.tab = tab;
    this.resetFilters();

    if ([TABS.SCHEDULED, TABS.INBOX, TABS.TO_APPROVE, TABS.TO_PAY, TABS.COMPLETED].includes(tab)) {
      this.sortBy = SORT_TABS_MAP(
        variation('feature--boolean-approval-workflow-for-supplier-invoices')
      )[tab];
    }

    if (
      variation('feature--boolean-approval-workflow-for-supplier-invoices') &&
      [TABS.TO_APPROVE, TABS.TO_PAY].includes(tab)
    ) {
      this.approverId = this.organizationManager.membership.id;
    }
  }

  @action handleSupplierSearch(query) {
    if (!this.suppliersOptions) {
      this.suppliersOptions = this.suppliers;
    }

    return new RSVP.Promise((resolve, reject) => {
      debounce(this, this.searchSuppliers, query, resolve, reject, DEBOUNCE_MS);
    });
  }

  @action selectSupplier(supplier) {
    this.segment.track('supplier-invoices_supplier-filter_applied');
    this.items = '';
    this.supplierId = supplier?.id;
  }

  @action toggleAssignedToYou(value) {
    let { membership } = this.organizationManager;
    this.assignedToYouActive = value;
    this.approverId = value ? membership.id : undefined;
  }

  @action
  showBulkDeletePopup() {
    let numberOfSelectedWithNonFinancialAttachment = this.supplierInvoices.reduce(
      (count, invoice) =>
        this.selectedItems.includes(invoice.id) && invoice.isAttachmentNonFinancial
          ? count + 1
          : count,
      0
    );

    this.segment.track('supplier-invoices_delete_clicked', {
      action_type: 'bulk',
      selected_invoices_count: this.numberOfSelected,
      non_financial_documents_count: numberOfSelectedWithNonFinancialAttachment,
      ...this.filterEventsOptions,
    });

    this.modals.open('popup/destructive', {
      title: this.intl.t('supplier-invoices.imported.bulk.popup.delete.title', {
        count: this.numberOfSelected,
      }),
      description: this.intl.t('supplier-invoices.imported.bulk.popup.delete.subtitle', {
        count: this.numberOfSelected,
      }),
      cancel: this.intl.t('btn.cancel'),
      confirm: this.intl.t('supplier-invoices.imported.bulk.popup.delete.confirm', {
        count: this.numberOfSelected,
      }),
      confirmTask: this.bulkDeleteTask,
    });
  }

  @action selectItems(newArr) {
    if (newArr.length > 0) {
      this.segment.track('supplier-invoices_multiselection_clicked', { selected: newArr.length });
    }
    this.items = serializeArrayQP(newArr);
  }

  @action resetSelectedItems() {
    this.selectItems([]);
  }

  @action
  showBulkArchivePopup() {
    this.segment.track('supplier-invoices_archive_clicked', {
      action_type: 'bulk',
      ...this.filterEventsOptions,
    });

    this.modals.open('popup/confirmation', {
      title: this.intl.t('supplier-invoices.imported.bulk.popup.archive.title', {
        count: this.numberOfSelected,
      }),
      description: this.intl.t('supplier-invoices.imported.bulk.popup.archive.subtitle', {
        count: this.numberOfSelected,
      }),
      cancel: this.intl.t('btn.cancel'),
      confirm: this.intl.t('supplier-invoices.imported.bulk.popup.archive.confirm', {
        count: this.numberOfSelected,
      }),
      confirmTask: this.bulkArchiveTask,
    });
  }

  @action
  trackCtaEvent(origin) {
    if (this.emptyStateRevampOptions) {
      this.emptyStates.trackCta(this.emptyStateRevampOptions, origin);
    } else {
      let trackingData = getTrackingNameAndProperties({
        type: TYPES.ACTIVATE,
        name: 'supplier-invoices',
      })({
        isClickEvent: true,
        isEmptyState: false,
        origin: TRACKING_ORIGINS.HEADER,
      });
      if (trackingData?.name && trackingData.properties) {
        this.segment.track(trackingData.name, trackingData.properties);
      }
    }
  }

  @action
  handleClickTracking(origin) {
    this.trackCtaEvent(origin);
  }

  @action
  onInvoiceListScroll(isScrolledTop) {
    // The condition is due to a known scroll bug, the cockpit is not folded under 16 invoices in the table.
    if (this.supplierInvoices.length > 15) {
      this.isCockpitFolded = !isScrolledTop;
    }
  }

  @action
  changePage(page) {
    this.page = page;
  }

  @action
  changePerPage(perPage) {
    this.page = 1;
    this.perPage = perPage;
  }

  @action changeSortBy(sortBy) {
    this.sortBy = sortBy;
  }

  @action
  toggleShowDropzone(boolean) {
    this.showDropzone = boolean;
  }

  @action
  reload() {
    this.supplierInvoicesInsights.fetchInsights();
  }

  @action
  onSideDrawerDisplay() {
    this.inContextIntegrationsViewed = true;
    this.isSideDrawerDisplayed = true;
  }

  @action
  onSideDrawerHide() {
    this.isSideDrawerDisplayed = false;
  }

  @action
  updateFilter(selected) {
    if (!selected) {
      if (this.isImportedTab) this.dueDate = null;
      if (this.isCompletedTab)
        this.status = [INVOICE_STATUSES.paid, INVOICE_STATUSES.archived].join(',');
      return;
    }
    if (this.isImportedTab) {
      this.segment.track('supplier-invoices_filter_select', {
        filter_type: 'due_date',
        filter_value: DUE_FILTER_EVENT_MAP[selected.code],
      });
      this.dueDate = selected?.code;
    } else {
      this.segment.track('supplier-invoices_filter_select', {
        filter_type: 'status',
        filter_value: selected.code,
      });
      this.status = selected?.code ?? `${INVOICE_STATUSES.paid},${INVOICE_STATUSES.archived}`;
    }
  }

  @action
  onUploadPopoverClose() {
    this.supplierInvoicesUploadManager.resetState();
    this.segment.track('supplier-invoices_upload-component_close-button_clicked');
  }

  @action
  onPreviewFile(file) {
    this.segment.track('supplier-invoices_upload-component_invoice-item_clicked');
    this.router.transitionTo('supplier-invoices.show', file.invoiceId);
  }

  bulkArchiveTask = dropTask(async close => {
    try {
      await this.networkManager.request(
        `${apiBaseURL}/${supplierInvoiceNamespace}/supplier_invoices/bulk_archive`,
        {
          method: 'POST',
          body: JSON.stringify({
            supplier_invoice_ids: this.selectedItems,
          }),
        }
      );

      this.toastFlashMessages.toastSuccess(
        this.intl.t('supplier-invoices.imported.bulk.archive.toast.success', {
          count: this.numberOfSelected,
        })
      );
      this.model.refreshModel();
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.generic'));
    } finally {
      this.items = '';
      close();
    }
  });

  bulkDeleteTask = dropTask(async close => {
    try {
      await this.networkManager.request(
        `${apiBaseURL}/${supplierInvoiceNamespace}/supplier_invoices/bulk_delete`,
        {
          method: 'POST',
          body: JSON.stringify({
            supplier_invoice_ids: this.selectedItems,
          }),
        }
      );

      this.toastFlashMessages.toastSuccess(
        this.intl.t('supplier-invoices.imported.bulk.archive.toast.delete', {
          count: this.numberOfSelected,
        })
      );
      this.model.refreshModel();
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.generic'));
    } finally {
      this.items = '';
      close();
    }
  });
}
