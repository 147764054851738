/* import __COLOCATED_TEMPLATE__ from './pending-claim.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface PendingClaimSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const PendingClaim = templateOnlyComponent<PendingClaimSignature>();

export default PendingClaim;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'HelpCenter::SelectedItem::Claim::PendingClaim': typeof PendingClaim;
  }
}
