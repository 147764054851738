export const PROVIDER_NAME = 'wise';

export const PAY_IN = {
  LOCAL: 'BANK_TRANSFER',
};

export const PAY_OUT = {
  INTERAC: 'INTERAC',
  LOCAL: 'BANK_TRANSFER',
  OUR: 'SWIFT_OUR',
  SHA: 'SWIFT',
};
