/* import __COLOCATED_TEMPLATE__ from './customer-address.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
// @ts-expect-error
import { cached } from '@glimmer/tracking';

import { alias } from 'macro-decorators';

// @ts-expect-error
import italianProvinces from 'qonto/constants/it-provinces';

interface InvoicesCustomerFormaddressSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLElement;
}

/**
 * This component handles CustomerAddressHash address objects
 */
export default class InvoicesCustomerFormaddressComponent extends Component<InvoicesCustomerFormaddressSignature> {
  @service declare intl: Services['intl'];
  @service declare organizationManager: Services['organizationManager'];

  // @ts-expect-error
  @alias('args.address.countryCode') countryCode;

  get customer() {
    // @ts-expect-error
    return this.args.customer;
  }

  get activeAddressKey() {
    if (this.isDeliveryAddress) {
      return 'deliveryAddress';
    } else {
      return 'billingAddress';
    }
  }

  get organization() {
    return this.organizationManager.organization;
  }

  get isDeliveryAddress() {
    // @ts-expect-error
    return this.args.isDeliveryAddress;
  }

  get defaultCountry() {
    return this.organization.legalCountry;
  }

  get selectedCountry() {
    // @ts-expect-error
    let countryCode = this.args.address.get('countryCode');
    return ['DE', 'ES', 'FR', 'IT'].includes(countryCode) ? countryCode : 'other';
  }

  get shouldDisplayItalianFormUpdates() {
    return this.countryCode === 'IT';
  }

  @cached
  get provinces() {
    // @ts-expect-error
    return italianProvinces.map(key => ({
      key,
      label: `${key} - ${this.intl.t(`italy.provinces.${key.toLowerCase()}`)}`,
    }));
  }

  get selectedProvince() {
    // @ts-expect-error
    return this.provinces.find(province => province.key === this.args.address.provinceCode);
  }

  get maxAddressLength() {
    return this.organization.legalCountry === 'IT' ? 60 : 250;
  }

  get isIndividual() {
    // @ts-expect-error
    return this.args.kind === 'individual';
  }

  // @ts-expect-error
  @action updateProvince({ key }) {
    // @ts-expect-error
    if (this.args.isDeliveryAddress) {
      // @ts-expect-error
      this.args.customer.set('deliveryAddress', {
        // @ts-expect-error
        ...this.args.customer.deliveryAddress,
        provinceCode: key,
      });
    } else {
      // @ts-expect-error
      this.args.customer.set('billingAddress', {
        // @ts-expect-error
        ...this.args.customer.billingAddress,
        provinceCode: key,
      });
    }
  }

  // @ts-expect-error
  @action handleStreetAddress(string) {
    // @ts-expect-error
    if (this.args.isLocalValidation) {
      // @ts-expect-error
      this.args.customer.set(this.activeAddressKey, {
        // @ts-expect-error
        ...this.args.customer[this.activeAddressKey],
        streetAddress: string,
      });
    } else {
      // @ts-expect-error
      this.args.errors?.remove(`${this.activeAddressKey}/streetAddress`);
      // @ts-expect-error
      this.args.address.streetAddress = string;
    }
  }

  // @ts-expect-error
  @action handleZipCode(string) {
    // @ts-expect-error
    if (this.args.isLocalValidation) {
      // @ts-expect-error
      this.args.customer.set(this.activeAddressKey, {
        // @ts-expect-error
        ...this.args.customer[this.activeAddressKey],
        zipCode: string,
      });
    } else {
      // @ts-expect-error
      this.args.errors?.remove(`${this.activeAddressKey}/zipCode`);
      // @ts-expect-error
      this.args.address.zipCode = string;
    }
  }

  // @ts-expect-error
  @action handleCity(string) {
    // @ts-expect-error
    if (this.args.isLocalValidation) {
      // @ts-expect-error
      this.args.customer.set(this.activeAddressKey, {
        // @ts-expect-error
        ...this.args.customer[this.activeAddressKey],
        city: string,
      });
    } else {
      // @ts-expect-error
      this.args.errors?.remove(`${this.activeAddressKey}/city`);
      // @ts-expect-error
      this.args.address.city = string;
    }
  }

  get countryVerticalPosition() {
    // @ts-expect-error
    return this.args.isDeliveryAddress ||
      (this.isIndividual && !this.shouldDisplayItalianFormUpdates)
      ? 'above'
      : 'auto';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ReceivableInvoices::CustomerForm::CustomerAddress': typeof InvoicesCustomerFormaddressComponent;
  }
}
