export default {
  "row": "qVy",
  "item-bulk": "qVU",
  "active": "qVj",
  "disabled": "qVW",
  "cell": "qVu body-2",
  "not-disabled": "qLS",
  "item-amount-disabled": "qLc",
  "item-amount-credit": "qLq",
  "no-padding": "qLZ",
  "cell-content": "qLR",
  "centered-cell": "qLQ",
  "item-counterparty-icon": "qLe mr-16",
  "avatar": "qLB",
  "item-counterparty-name": "qLE",
  "item-counterparty-name-status": "qLd",
  "disputing-chargeback": "qLI",
  "item-counterparty-name-text": "qLt",
  "item-amount": "qLM body-1",
  "item-amount-foreign": "qLP",
  "attachment-icon": "qLl",
  "item-method": "qLX",
  "bank-account-avatar": "qLC",
  "attachment-status-dot": "qLk",
  "attached": "qLJ",
  "missing": "qLs",
  "item-attachment": "qLF",
  "compact": "qLg",
  "upsell-badge": "qLD",
  "fadein-item": "qLV"
};
