/* import __COLOCATED_TEMPLATE__ from './link.hbs'; */
import { assert } from '@ember/debug';
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

// @ts-expect-error
import { DUAL_NAV_STATES } from 'qonto/constants/dual-nav';

const POINTER_TYPE_TOUCH = 'touch';

interface DualNavMainMenuLinkSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLLIElement;
}

export default class DualNavMainMenuLinkComponent extends Component<DualNavMainMenuLinkSignature> {
  // @ts-expect-error
  @tracked previousPointerType;

  constructor(owner: unknown, args: DualNavMainMenuLinkSignature['Args']) {
    super(owner, args);
    // @ts-expect-error
    let { item } = this.args;

    assert('<DualNav::MainMenu::Link /> must have an @item', item);
    assert('<DualNav::MainMenu::Link /> must have @item.label', item.label);
    assert('<DualNav::MainMenu::Link /> must have @item.link', item.link);
  }

  get shouldHideTooltip() {
    return (
      // @ts-expect-error
      this.args.state === DUAL_NAV_STATES.EXPANDED ||
      this.previousPointerType === POINTER_TYPE_TOUCH
    );
  }

  @action
  // @ts-expect-error
  handleClick(event) {
    // @ts-expect-error
    let { closeDropdown, item } = this.args;
    event.preventDefault();

    item.link.transitionTo(event);
    if (closeDropdown) {
      closeDropdown();
    }
  }

  @action
  // @ts-expect-error
  handlePointerUp(event) {
    this.previousPointerType = event.pointerType;
    // @ts-expect-error
    let { onMainMenuLinkPointerUp, item } = this.args;

    onMainMenuLinkPointerUp(item);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'DualNav::MainMenu::Link': typeof DualNavMainMenuLinkComponent;
  }
}
