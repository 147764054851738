export default {
  "wrapper": "wW",
  "content": "wu",
  "compact": "NS",
  "full-width": "Nc",
  "left": "Nq",
  "right-image": "NZ",
  "right-lottie": "NR",
  "badge": "NQ",
  "badge-icon": "Ne",
  "animation": "NB",
  "hidden": "NE"
};
