export default {
  "container": "qgC",
  "item": "qgk caption-bold",
  "hover": "qgJ",
  "active": "qgs",
  "name-wrapper": "qgF",
  "name": "qgg body-2",
  "count": "qgD",
  "actions-placeholder": "qgV",
  "actions": "qgL",
  "show": "qgT",
  "action-button": "qgz"
};
