export default {
  "container": "caL",
  "label": "caT",
  "disabled": "caz",
  "small": "caa caption",
  "large": "caH body-2",
  "caption": "caO",
  "amount": "cam",
  "right-aligned": "cap",
  "expanded": "cax",
  "amount--current": "caw",
  "amount--total": "caN",
  "amount--sep": "cav"
};
