export default {
  "step-container": "jn",
  "content": "ji",
  "detail": "jA",
  "secondary": "jY",
  "reference": "jh",
  "fees-label": "jf",
  "fees-tooltip": "jK",
  "separator": "jG",
  "mt-32": "jr"
};
