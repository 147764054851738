/* import __COLOCATED_TEMPLATE__ from './item.hbs'; */
import Component from '@glimmer/component';

import { Tag } from '@repo/design-system-kit';

import { CARD_LEVELS } from 'qonto/constants/cards';

interface CardTableItemSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLTableRowElement;
}

export default class CardTableItem extends Component<CardTableItemSignature> {
  tag = Tag;

  get paymentLimit() {
    // @ts-expect-error
    let { item } = this.args;
    if (item.cardLevel === CARD_LEVELS.FLASH) {
      return item.paymentLifespanLimit;
    }
    return item.paymentMonthlyLimit;
  }

  get paymentSpent() {
    // @ts-expect-error
    let { item } = this.args;
    if (item.cardLevel === CARD_LEVELS.FLASH) {
      return item.paymentLifespanSpent;
    }
    return item.paymentMonthlySpent;
  }

  get itemIconName() {
    // @ts-expect-error
    return `card-${this.args.item.cardDesign}`;
  }
  get itemIconUri() {
    return `/illustrations/cards/${this.itemIconName}.svg`;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Card::Table::Item': typeof CardTableItem;
  }
}
