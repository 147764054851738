/* import __COLOCATED_TEMPLATE__ from './status-banner.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

interface ConnectionsStatusBannerSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class ConnectionsStatusBannerComponent extends Component<ConnectionsStatusBannerSignature> {
  @service declare abilities: Services['abilities'];
  @service declare organizationManager: Services['organizationManager'];

  get canDisplay() {
    return this.abilities.can('view connections external-account') && this.hasFailedConnections;
  }

  get hasFailedConnections() {
    return Boolean(this.failedConnectionsCount);
  }

  get hasActionableFailures() {
    // @ts-expect-error
    return Boolean(this.args.connectionStatuses?.actionableFailures.length);
  }

  get failedConnectionsCount() {
    // @ts-expect-error
    return this.args.connectionStatuses?.failedConnections.length;
  }

  get failedConnection() {
    // @ts-expect-error
    return this.args.connectionStatuses?.failedConnections[0];
  }

  get actionableConnection() {
    // @ts-expect-error
    return this.args.connectionStatuses?.actionableFailures[0];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Connections::StatusBanner': typeof ConnectionsStatusBannerComponent;
  }
}
