/* import __COLOCATED_TEMPLATE__ from './item.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

interface PresetsListItemSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class PresetsListItemComponent extends Component<PresetsListItemSignature> {
  @service declare intl: Services['intl'];

  @tracked hovered = false;
  @tracked showActions = false;

  get localizedName() {
    // @ts-expect-error
    let { name } = this.args;

    let translations = {
      missing_receipts: this.intl.t('transactions.filters.presets.items.missing_receipts'),
      settled_last_month: this.intl.t('transactions.filters.presets.items.settled_last_month'),
      to_verify: this.intl.t('transactions.filters.presets.items.to_verify'),
    };

    // @ts-expect-error
    return translations[name] || name;
  }

  get count() {
    // @ts-expect-error
    let { count } = this.args;
    return count.toString().length >= 4 ? '+999' : count;
  }

  @action
  // @ts-expect-error
  hoverActions(value) {
    this.hovered = value;
    this.showActions = value;
  }

  @action
  // @ts-expect-error
  handleShowActions(value) {
    this.showActions = value;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transactions::Presets::List::Item': typeof PresetsListItemComponent;
  }
}
