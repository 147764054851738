/* import __COLOCATED_TEMPLATE__ from './theme-selector.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Disclaimer } from '@repo/design-system-kit';

// @ts-expect-error
import APPEARANCE from 'qonto/constants/appearance-setting';

const TRACKING_EVENT_NAMES = {
  [APPEARANCE.LIGHT]: 'appearance_light_selected',
  [APPEARANCE.DARK]: 'appearance_dark_selected',
  [APPEARANCE.SYSTEM]: 'appearance_automatic_selected',
};

interface ThemeSelectorSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class ThemeSelector extends Component<ThemeSelectorSignature> {
  disclaimerInline = Disclaimer.Inline;

  @service declare segment: Services['segment'];
  @service declare tandem: Services['tandem'];

  // @ts-expect-error
  @tracked groupValue = this.args.currentAppearance;

  APPEARANCE = APPEARANCE;

  @action
  // @ts-expect-error
  onCheck(value) {
    let trackingEventName = TRACKING_EVENT_NAMES[value];

    this.groupValue = value;
    // @ts-expect-error
    this.args.setAppearance(value);
    this.tandem.setTheme(value);
    this.segment.track(trackingEventName);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ThemeSelector::ThemeSelector': typeof ThemeSelector;
  }
}
