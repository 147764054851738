import { InvalidError } from '@ember-data/adapter/error';
import { attr, belongsTo, hasMany } from '@ember-data/model';
import { get } from '@ember/object';
import { waitFor } from '@ember/test-waiters';

// @ts-expect-error
import { apiAction } from '@mainmatter/ember-api-actions';
import dayjs from 'dayjs';
import { equal, reads } from 'macro-decorators';

// @ts-expect-error
import { DATE_PICKER_FIELD_FORMAT } from 'qonto/constants/dates';
import Request from 'qonto/models/request';
// @ts-expect-error
import { differenceInCalendar, getCurrentParisDateString } from 'qonto/utils/date';
// @ts-expect-error
import RequestMultiTransferValidations from 'qonto/validations/request-multi-transfer';

// @ts-expect-error
export default class RequestMultiTransfer extends Request.extend(RequestMultiTransferValidations) {
  // @ts-expect-error
  @belongsTo('organization', { async: false, inverse: null }) organization;
  // @ts-expect-error
  @belongsTo('bankAccount', { async: false, inverse: null }) bankAccount;
  // @ts-expect-error
  @belongsTo('membership', { async: true, inverse: null }) initiator;
  // @ts-expect-error
  @belongsTo('membership', { async: true, inverse: null }) approver;
  @hasMany('request-multi-transfer-transfer', { async: true, inverse: 'requestMultiTransfer' })
  // @ts-expect-error
  transfers;
  // @ts-expect-error
  @reads('selectedTransfers.length') selectedTransfersCount;

  // @ts-expect-error
  @attr file;
  @attr('string', { defaultValue: 'multi_transfer' }) declare requestType: string;
  // @ts-expect-error
  @attr status;
  @attr('string', { defaultValue: 'scheduled' }) declare operationType: string;
  @attr('string', { defaultValue: getCurrentParisDateString }) declare scheduledDate: string;
  // @ts-expect-error
  @attr totalTransfersAmount;
  // @ts-expect-error
  @attr totalTransfersAmountCurrency;
  // @ts-expect-error
  @attr totalTransfersCount;
  // @ts-expect-error
  @attr processedAt;
  // @ts-expect-error
  @attr createdAt;
  // @ts-expect-error
  @attr activityTag;

  scheduled = false;

  // @ts-expect-error
  @equal('operationType', 'scheduled') once;
  // @ts-expect-error
  @equal('operationType', 'scheduled_later') later;
  // @ts-expect-error
  @equal('operationType', 'standing_weekly') weekly;
  // @ts-expect-error
  @equal('operationType', 'standing_monthly') monthly;

  get hasPastDate() {
    let { later, weekly, monthly, scheduledDate } = this;
    if (later || weekly || monthly) {
      let nbDays = differenceInCalendar(new Date(), scheduledDate, 'day');
      return nbDays >= 0;
    }
    return false;
  }

  get existingBeneficiaries() {
    // @ts-expect-error
    return this.transfers.filter(request => request.belongsTo('beneficiary').id());
  }

  get newBeneficiaries() {
    // @ts-expect-error
    return this.transfers.filter(request => !request.belongsTo('beneficiary').id());
  }

  get dasherizedRequestType() {
    return 'multi-transfer';
  }

  get selectedTransfers() {
    // @ts-expect-error
    return this.transfers.filter(item => get(item, 'selected') === true);
  }

  get totalSelectedTransfersAmount() {
    // @ts-expect-error
    let amount = this.selectedTransfers.reduce((x, y) => {
      return parseFloat(x) + parseFloat(y.amount);
    }, 0);

    return amount;
  }

  get minDate() {
    return dayjs().add(1, 'day').format(DATE_PICKER_FIELD_FORMAT);
  }

  @waitFor
  async check() {
    let { note, operation_type, organization_id, scheduled_date, transfers } = this.serialize();
    // @ts-expect-error
    transfers.forEach(transfer => {
      delete transfer.id;
    });

    let data = {
      request_multi_transfer: {
        note,
        operation_type,
        organization_id,
        scheduled_date,
        transfers,
      },
    };

    try {
      return await apiAction(this, {
        requestType: 'createRecord',
        method: 'POST',
        path: 'check',
        data,
      });
    } catch (error) {
      // @ts-expect-error
      if (error instanceof InvalidError && error.errors) {
        // @ts-expect-error
        error.errors.forEach(e => {
          let [, relationship, index, field] = e.detail.source.pointer.split('/');
          this.networkManager.errorModelInjector(
            this.hasMany(relationship).value()[index],
            { [field]: e.detail.detail },
            error
          );
        });
      }

      throw error;
    }
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'request-multi-transfer': RequestMultiTransfer;
  }
}
