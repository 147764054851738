export default {
  "header": "crq",
  "header-type": "crZ",
  "close": "crR",
  "close-icon": "crQ",
  "header-infos": "cre",
  "title": "crB body-1",
  "declined": "crE",
  "canceled": "crd",
  "approved": "crI",
  "pending": "crt",
  "date": "crM body-2",
  "container": "crP",
  "picto": "crl",
  "picto-status": "crX",
  "general": "crC",
  "amount": "crk",
  "counterparty": "crJ body-2",
  "initiator": "crs",
  "initiator-avatar": "crF",
  "avatar-image": "crg",
  "initiator-id": "crD",
  "name": "crV body-2",
  "role": "crL caption-bold",
  "infobox": "crT",
  "account-infobox": "crz crT",
  "beneficiary-warning": "cra caption-bold",
  "dates-wrapper": "crH",
  "dates": "crO",
  "recurrence": "crm caption-bold",
  "arrow": "crp",
  "notify-checkbox": "crx"
};
