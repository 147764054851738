import { Button, Disclaimer } from '@repo/design-system-kit';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { type ReactElement } from 'react';
import styles from './transfer-funds.strict-module.css';

interface TransferFundsScreenProps {
  closingDueDate: string;
  onClose: () => void;
  onTransferFunds: () => void;
}

export function TransferFundsScreen({
  closingDueDate,
  onClose,
  onTransferFunds,
}: TransferFundsScreenProps): ReactElement {
  return (
    <div className={styles.content}>
      <h1 className="title-2 mb-32" data-test-transfer-funds-title>
        <FormattedMessage id="subscription.account-closing.transfer-remaining-funds.title" />
      </h1>
      <p className="body-1 mb-32">
        <FormattedMessage
          data-test-transfer-funds-subtitle
          id="subscription.account-closing.transfer-remaining-funds.subtitle"
          values={{
            dueDate: closingDueDate,
            // eslint-disable-next-line react/no-unstable-nested-components -- To be able to use spans for styling
            span: chunks => <span className={styles.dueDate}>{chunks}</span>,
          }}
        />
      </p>
      <Disclaimer.Block className="mb-32" level="warning">
        <FormattedMessage
          data-test-transfer-funds-disclaimer
          id="subscription.account-closing.transfer-remaining-funds.disclaimer"
        />
      </Disclaimer.Block>
      <div>
        <Button
          className="mr-16"
          data-test-transfer-funds-close
          onPress={onClose}
          size="medium"
          type="button"
          variant="secondary"
        >
          <FormattedMessage id="subscription.account-closing.transfer-remaining-funds.continue-closure-cta" />
        </Button>
        <Button onPress={onTransferFunds} size="medium" type="button" variant="primary">
          <FormattedMessage
            data-test-transfer-funds-btn
            id="subscription.account-closing.transfer-remaining-funds.transfer-funds-cta"
          />
        </Button>
      </div>
    </div>
  );
}
