/* import __COLOCATED_TEMPLATE__ from './text.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

import isFunction from 'qonto/utils/is-function';

interface Signature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class TransfersInternationalOutDynamicFormFieldTextComponent extends Component<Signature> {
  get #shouldRefreshOnChange() {
    // @ts-expect-error
    let { field, onRefresh } = this.args;
    return field.refreshRequirementsOnChange && isFunction(onRefresh);
  }

  @action
  onFocusOut() {
    // @ts-expect-error
    let { field, onFocusOut, onRefresh } = this.args;

    onFocusOut(field.key);

    if (this.#shouldRefreshOnChange) {
      onRefresh(field.key);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transfers::InternationalOut::DynamicForm::Field::Text': typeof TransfersInternationalOutDynamicFormFieldTextComponent;
  }
}
