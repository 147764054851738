/* import __COLOCATED_TEMPLATE__ from './item.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { BadgeStatus } from '@repo/design-system-kit';

interface SubscriptionPromocodeFormSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class SubscriptionPromocodeFormComponent extends Component<SubscriptionPromocodeFormSignature> {
  badgeStatus = BadgeStatus;

  @service declare intl: Services['intl'];

  titles = {
    // @ts-expect-error
    initial: this.intl.t('subscription.promo-code.item.voucher-type.initial'),
    // @ts-expect-error
    trial: this.intl.t('subscription.promo-code.item.voucher-type.free-trial'),
    // @ts-expect-error
    subscription: this.intl.t('subscription.promo-code.item.voucher-type.subscription'),
    promotional: {
      // @ts-expect-error
      physical_card_plus: this.intl.t('subscription.promo-code.item.voucher-type.promotional.plus'),
      // @ts-expect-error
      physical_card_metal: this.intl.t(
        'subscription.promo-code.item.voucher-type.promotional.metal'
      ),
      // @ts-expect-error
      additional_user: this.intl.t(
        'subscription.promo-code.item.voucher-type.promotional.additional-user'
      ),
    },
  };

  get title() {
    // @ts-expect-error
    let { voucherType, voucherFeeType } = this.args.discount;
    if (voucherType === 'promotional') {
      // @ts-expect-error
      return this.titles.promotional[voucherFeeType];
    } else {
      // @ts-expect-error
      return this.titles[voucherType];
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Subscription::PromoCode::Item': typeof SubscriptionPromocodeFormComponent;
  }
}
