/* import __COLOCATED_TEMPLATE__ from './animation-wait-wrapper.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';

import { ignoreCancelation } from 'qonto/utils/ignore-error';

interface AnimationWaitWrapperSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class AnimationWaitWrapper extends Component<AnimationWaitWrapperSignature> {
  @service('-ea-motion')
  declare motionService: Services['motionService'];

  @tracked isEaAnimating = true;

  constructor(owner: unknown, args: AnimationWaitWrapperSignature['Args']) {
    super(owner, args);

    // @ts-expect-error
    if (this.motionService.isAnimating) {
      this.waitUntilIdleTask.perform().catch(ignoreCancelation);
    } else {
      this.isEaAnimating = false;
    }
  }

  waitUntilIdleTask = dropTask(async () => {
    // @ts-expect-error
    await this.motionService.waitUntilIdle.perform();

    this.isEaAnimating = false;
  });

  get shouldRender() {
    // @ts-expect-error
    let isEnabled = this.args.isEnabled ?? true;

    return !isEnabled || !this.isEaAnimating;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    AnimationWaitWrapper: typeof AnimationWaitWrapper;
  }
}
