import Controller, { inject as controller } from '@ember/controller';

import { reads } from 'macro-decorators';

export default class ReceivableInvoicesDuplicatePaymentController extends Controller {
  @controller('receivable-invoices.duplicate') parentController;

  @reads('parentController.logo') logo;

  @reads('parentController.saveTask') saveTask;
  @reads('parentController.saveAsDraftTask') saveAsDraftTask;
  @reads('parentController.onCloseTask') onCloseTask;
}
