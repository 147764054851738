/* import __COLOCATED_TEMPLATE__ from './item.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

interface InContextIntegrationsSideDrawerIntegrationQualityItemSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLLIElement;
}

export default class InContextIntegrationsSideDrawerIntegrationQualityItemComponent extends Component<InContextIntegrationsSideDrawerIntegrationQualityItemSignature> {
  @service declare intl: Services['intl'];

  get dataFlowText() {
    // @ts-expect-error
    let { dataFlow } = this.args;

    if (dataFlow === 'in') {
      return this.intl.t(
        'in-context-integrations.side-drawer.integration.accordion.data-flow.import'
      );
    } else if (dataFlow === 'out') {
      return this.intl.t(
        'in-context-integrations.side-drawer.integration.accordion.data-flow.export'
      );
    }
  }

  get dataFlowIcon() {
    // @ts-expect-error
    let { dataFlow } = this.args;

    if (dataFlow === 'in') {
      return 'icon-download-outlined';
    } else if (dataFlow === 'out') {
      return 'icon-upload-outlined';
    }
  }

  get qualities() {
    // @ts-expect-error
    let { qualities } = this.args;

    // @ts-expect-error
    return qualities.map((quality, index) => {
      if (index === qualities.length - 1) {
        return quality;
      } else {
        return quality + ', ';
      }
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InContextIntegrations::SideDrawer::Integration::Quality::Item': typeof InContextIntegrationsSideDrawerIntegrationQualityItemComponent;
  }
}
