/* import __COLOCATED_TEMPLATE__ from './intro.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { Intro } from 'qonto/react/financing/components/flows/pay-later/application/intro';

interface FlowsFinancingPayLaterApplicationIntroSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsFinancingPayLaterApplicationIntroComponent extends Component<FlowsFinancingPayLaterApplicationIntroSignature> {
  intro = Intro;

  @service declare segment: Services['segment'];

  @action
  goToNextStep() {
    this.segment.track('pay-later-intro_check-eligibility_clicked');
    // @ts-expect-error
    this.args.transitionToNext();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Financing::PayLater::Application::Intro': typeof FlowsFinancingPayLaterApplicationIntroComponent;
  }
}
