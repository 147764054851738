/* import __COLOCATED_TEMPLATE__ from './outdated.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface OutdatedSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const Outdated = templateOnlyComponent<OutdatedSignature>();

export default Outdated;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'HelpCenter::SelectedItem::Claim::Outdated': typeof Outdated;
  }
}
