/* import __COLOCATED_TEMPLATE__ from './item.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';

// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';

interface CustomLabelsItemSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class CustomLabelsItem extends Component<CustomLabelsItemSignature> {
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare intl: Services['intl'];
  @service declare modals: Services['modals'];
  @service declare segment: Services['segment'];
  @service declare sentry: Services['sentry'];
  @service declare customLabelsManager: Services['customLabelsManager'];

  @tracked errorMessage = null;

  willDestroy() {
    // @ts-expect-error
    super.willDestroy(...arguments);

    // if the item is destorying or destroyed we can skip cleanup
    // otherwise we will get an error in ember data 4.7 in integration tests:
    // "A record in a disconnected state cannot utilize the store."
    // @ts-expect-error
    if (this.args.item.isDestroying || this.args.item.isDestroyed) return;
    // @ts-expect-error
    this.args.item.rollbackAttributes();
  }

  get showInput() {
    // @ts-expect-error
    return this.args.activeItem === this.args.item || this.args.item.isNew;
  }

  get type() {
    return {
      // @ts-expect-error
      type: this.args.isCategory ? 'category' : 'label',
    };
  }

  @action
  handleAbort() {
    // @ts-expect-error
    this.args.onFocus(null);
  }

  @action
  handleEdit() {
    this.errorMessage = null;
    // @ts-expect-error
    this.args.onFocus(this.args.item);
  }

  saveLabelOnEnterTask = dropTask(async event => {
    if (event.key === 'Enter') {
      await this.saveLabelTask.perform();
    }
  });

  validateLabelTask = dropTask(async () => {
    // @ts-expect-error
    let { validations } = await this.args.item.validate();
    this._displayValidation(validations);
  });

  saveLabelTask = dropTask(async () => {
    // @ts-expect-error
    let { validations } = await this.args.item.validate();
    this.errorMessage = null;

    if (validations.isValid) {
      try {
        this.segment.track('custom_labels_create_button_clicked', this.type);
        // @ts-expect-error
        await this.args.item.save();
        // @ts-expect-error
        this.args.onFocus(null);
      } catch (error) {
        // @ts-expect-error
        this.args.onFocus(null);
        this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
        let errorInfo = ErrorInfo.for(error);
        if (errorInfo.shouldSendToSentry) {
          this.sentry.captureException(error);
        }
      }
    } else {
      this._displayValidation(validations);
    }
  });

  modalConfirmTask = dropTask(async () => {
    await this.modals.open('popup/destructive', {
      // @ts-expect-error
      title: this.args.isCategory
        ? this.intl.t('organizations.analytics.custom-labels.category.delete-modal.title')
        : this.intl.t('organizations.analytics.custom-labels.label.delete-modal.title'),
      // @ts-expect-error
      description: this.args.isCategory
        ? this.intl.t('organizations.analytics.custom-labels.category.delete-modal.body')
        : this.intl.t('organizations.analytics.custom-labels.label.delete-modal.body'),
      cancel: this.intl.t('btn.cancel'),
      confirm: this.intl.t('btn.delete'),
      confirmTask: this.customLabelsManager.modalConfirmTask,
      // @ts-expect-error
      item: this.args.item,
      type: this.type,
      // @ts-expect-error
      isCategory: this.args.isCategory,
    });
  });

  // @ts-expect-error
  _displayValidation(validations) {
    if (validations.error?.type === 'presence') {
      // @ts-expect-error
      this.errorMessage = this.intl.t('organizations.analytics.custom-labels.item.required');
    }

    if (validations.error?.type === 'length') {
      // @ts-expect-error
      this.errorMessage = this.intl.t('organizations.analytics.custom-labels.item.max-char');
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'CustomLabels::Item': typeof CustomLabelsItem;
  }
}
