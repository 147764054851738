/* import __COLOCATED_TEMPLATE__ from './details-modal.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

import { dropTask } from 'ember-concurrency';
import { reads } from 'macro-decorators';

import { ignoreCancelation } from 'qonto/utils/ignore-error';

interface SupplierInvoicesDetailsModalSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class SupplierInvoicesDetailsModal extends Component<SupplierInvoicesDetailsModalSignature> {
  // @ts-expect-error
  @reads('detailsTaskValue.displayAttachment') displayAttachment;

  get detailsTaskValue() {
    // @ts-expect-error
    let { isRunning, lastSuccessful } = this.args.data.detailsTask || {};

    return !isRunning && lastSuccessful?.value;
  }

  get attachment() {
    return this.detailsTaskValue?.attachment;
  }

  get invoice() {
    return this.detailsTaskValue?.invoice;
  }

  get request() {
    return this.detailsTaskValue?.request;
  }

  get confirmTransferResult() {
    // @ts-expect-error
    return this.args.data.confirmTransferTask.lastSuccessful?.value;
  }

  @action
  reloadApprovalWorkflowEstimate() {
    // @ts-expect-error
    this.args.data.fetchApprovalWorkflowEstimateTask.perform(this.invoice).catch(ignoreCancelation);
  }

  get approvalWorkflowEstimate() {
    // @ts-expect-error
    return this.args.data.fetchApprovalWorkflowEstimateTask.lastSuccessful?.value;
  }

  @action
  reloadInvoice() {
    // @ts-expect-error
    this.args.data.detailsTask.perform(this.invoice.id).catch(ignoreCancelation);
  }

  // @ts-expect-error
  @action handleEscape(e) {
    if (e.key === 'Escape') {
      // @ts-expect-error
      this.args.data.onClose();
    }
  }

  onAccountChangeTask = dropTask(async (request, account) => {
    request.bankAccount = account;
    // @ts-expect-error
    await this.args.data.confirmTransferTask.perform(request, this.invoice);
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'SupplierInvoices::DetailsModal': typeof SupplierInvoicesDetailsModal;
  }
}
