export default {
  "banner": "cDc",
  "inner": "cDq",
  "header": "cDZ title-3",
  "content": "cDR",
  "subtitle": "cDQ body-1",
  "list": "cDe",
  "item": "cDB body-1",
  "icon": "cDE",
  "cta": "cDd btn btn--primary",
  "spinner": "cDI",
  "illustration": "cDt",
  "image": "cDM"
};
