export default {
  "page-wrapper": "qug",
  "content-wrapper": "quD",
  "content": "quV",
  "period-field": "quL",
  "format-options": "quT",
  "format-option": "quz",
  "format-radio": "qua",
  "format-description": "quH body-2"
};
