export default {
  "row": "qqF",
  "active": "qqg",
  "dot": "qqD",
  "red": "qqV",
  "green": "qqL",
  "yellow": "qqT",
  "status": "qqz",
  "align-right": "qqa",
  "animated": "qqH",
  "fadein-item": "qqO",
  "cell": "qqm body-2",
  "cell-content": "qqp",
  "amount": "qqx body-1",
  "subtitle": "qqw caption",
  "no-padding": "qqN"
};
