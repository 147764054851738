export default {
  "container": "cAC",
  "content": "cAk",
  "input-row": "cAJ",
  "email-row": "cAs",
  "button-row": "cAF",
  "btn-edit": "cAg",
  "input-column": "cAD",
  "form-btn-row": "cAV",
  "border-bottom": "cAL",
  "avatar-input": "cAT"
};
