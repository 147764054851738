import Controller, { inject as controller } from '@ember/controller';
import { service } from '@ember/service';

import { dropTask } from 'ember-concurrency';
import { reads } from 'macro-decorators';

export default class ReceivableInvoicesDuplicateIndexController extends Controller {
  @service router;

  @controller('receivable-invoices.duplicate') parentController;

  @reads('parentController.logo') logo;

  @reads('parentController.duplicateInvoiceOrigin') duplicateInvoiceOrigin;

  @reads('parentController.saveTask') saveTask;
  @reads('parentController.saveAsDraftTask') saveAsDraftTask;
  @reads('parentController.onCloseTask') onCloseTask;

  continueTask = dropTask(async () => {
    try {
      await this.model.invoice.validate();
      await this.router.transitionTo('receivable-invoices.duplicate.payment');
    } catch (error) {
      this.parentController.handleError(error);
    }
  });
}
