/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Badge, Disclaimer } from '@repo/design-system-kit';
import dayjs from 'dayjs';
// @ts-expect-error
import { variation } from 'ember-launch-darkly';

import {
  EDITABLE_DESCRIPTION_INVOICE_STATUSES,
  INVOICE_STATUSES,
  STATUS_COLORS,
} from 'qonto/constants/supplier-invoice';
// @ts-expect-error
import formats from 'qonto/formats';
import { PayableAmountTooltip } from 'qonto/react/components/supplier-invoices/payable-amount-tooltip/payable-amount-tooltip.tsx';
import { StatusTag } from 'qonto/react/components/supplier-invoices/status-tag';

interface SupplierInvoicesDetailsSidebarHeaderSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLElement;
}

export default class SupplierInvoicesDetailsSidebarHeaderComponent extends Component<SupplierInvoicesDetailsSidebarHeaderSignature> {
  badge = Badge;
  disclaimerBlock = Disclaimer.Block;
  payableAmountTooltip = PayableAmountTooltip;
  statusTag = StatusTag;

  @service declare abilities: Services['abilities'];
  @service declare intl: Services['intl'];
  @service declare organizationManager: Services['organizationManager'];
  @tracked invoiceStatuses = INVOICE_STATUSES;

  get strikeTotalAmount() {
    return (
      (variation('experiment--boolean-ap-credit-notes') ||
        variation('feature--boolean-ap-credit-notes')) &&
      // @ts-expect-error
      Number(this.args.invoice?.payableAmount?.value) === 0 &&
      // @ts-expect-error
      Number(this.args.invoice?.totalAmount?.value) !== 0 &&
      // @ts-expect-error
      !this.args.invoice?.isCreditNote
    );
  }

  get amount() {
    // @ts-expect-error
    let { value, currency } = this.args.invoice?.totalAmount || {};
    // @ts-expect-error
    let { isCreditNote } = this.args.invoice || {};

    if (currency && value !== null && value !== undefined) {
      // @ts-expect-error
      return this.intl.formatMoney(value, {
        currency,
        signus:
          (variation('experiment--boolean-ap-credit-notes') ||
            variation('feature--boolean-ap-credit-notes')) &&
          isCreditNote
            ? '-'
            : null,
      });
    }

    return `--\xA0${formats.number.EUR.currency}`;
  }

  get status() {
    // @ts-expect-error
    let { invoice = {} } = this.args;
    let { status, paymentDate, scheduledDate } = invoice;

    if (status === INVOICE_STATUSES.scheduled) {
      return {
        dateLabel: this.intl.t('supplier-invoices.preview.operation-date'),
        date: scheduledDate,
      };
    }
    if (status === INVOICE_STATUSES.paid) {
      return {
        display: this.intl.t('supplier-invoices.preview.status.paid'),
        dateLabel: this.intl.t('supplier-invoices.preview.payment-date'),
        color: STATUS_COLORS.paid,
        date: paymentDate,
      };
    }
  }

  get hasDescription() {
    // @ts-expect-error
    return Boolean(this.args.invoice?.description?.trim());
  }

  get isOverdue() {
    // @ts-expect-error
    let { dueDate, status } = this.args.invoice || {};
    let canOverdue = dueDate && status !== INVOICE_STATUSES.paid;

    return canOverdue ? dayjs().isAfter(dueDate) : false;
  }

  get showStatusDate() {
    // @ts-expect-error
    return [INVOICE_STATUSES.scheduled, INVOICE_STATUSES.paid].includes(this.args.invoice?.status);
  }

  get isCreditNote() {
    // @ts-expect-error
    let { isCreditNote } = this.args.invoice || {};
    return isCreditNote && variation('feature--boolean-ap-credit-notes');
  }

  get showEditButton() {
    let canUpdateSupplierInvoices = this.abilities.can('update supplierInvoices');

    let hasToReviewOrPaidStatus = [INVOICE_STATUSES.toReview, INVOICE_STATUSES.paid].includes(
      // @ts-expect-error
      this.args.invoice?.status
    );
    let hasEditStatusForCreditNotes = ![
      INVOICE_STATUSES.archived,
      INVOICE_STATUSES.paid,
      INVOICE_STATUSES.scheduled,
    ].includes(
      // @ts-expect-error
      this.args.invoice?.status
    );

    let isNotElectronicInvoicing =
      // @ts-expect-error
      !this.args.invoice?.frenchEInvoicing && !this.args.invoice?.isGermanEInvoice;

    let editInvoiceConditions =
      hasToReviewOrPaidStatus && canUpdateSupplierInvoices && isNotElectronicInvoicing;

    let editCreditNoteConditions =
      hasEditStatusForCreditNotes && canUpdateSupplierInvoices && isNotElectronicInvoicing;

    return this.isCreditNote ? editCreditNoteConditions : editInvoiceConditions;
  }

  get editButtonCopy() {
    return this.isCreditNote
      ? this.intl.t('supplier-invoices.edit-credit-note.cta')
      : this.intl.t('supplier-invoices.edit.cta');
  }

  get eInvoiceToolTipMessage() {
    let { organization } = this.organizationManager;
    switch (organization?.legalCountry) {
      case 'DE':
        return this.intl.t('supplier-invoices.preview.einvoice.tooltip');

      case 'FR':
        return this.intl.t('supplier-invoices.preview.supplier-einvoicing.tooltip');

      default:
        break;
    }
  }

  get isEinvoicingDescriptionFormShown() {
    return (
      // @ts-expect-error
      (this.args.invoice?.frenchEInvoicing || this.args.invoice?.isGermanEInvoice) &&
      // @ts-expect-error
      EDITABLE_DESCRIPTION_INVOICE_STATUSES.includes(this.args.invoice?.status)
    );
  }

  get isFrenchOrGermanEInvoicing() {
    // @ts-expect-error
    return (this.args.invoice?.frenchEInvoicing || this.args.invoice?.isGermanEInvoice) ?? false;
  }

  get documentNumberLabel() {
    // @ts-expect-error
    let { isCreditNote } = this.args.invoice || {};
    return isCreditNote &&
      (variation('experiment--boolean-ap-credit-notes') ||
        variation('feature--boolean-ap-credit-notes'))
      ? this.intl.t('supplier-invoices.preview.credit-note-number')
      : this.intl.t('supplier-invoices.preview.invoice-number');
  }

  get payableAmount() {
    // @ts-expect-error
    let { payableAmount } = this.args.invoice || {};
    // @ts-expect-error
    return this.intl.formatMoney(payableAmount?.value, { currency: payableAmount?.currency });
  }

  get totalAmount() {
    // @ts-expect-error
    let { totalAmount } = this.args.invoice || {};
    // @ts-expect-error
    return this.intl.formatMoney(totalAmount?.value, { currency: totalAmount?.currency });
  }

  get creditNotesAmount() {
    // @ts-expect-error
    let { relatedInvoices } = this.args.invoice || [];

    if (!relatedInvoices?.length) {
      return '';
    }

    let creditNotesSumUp = relatedInvoices
      // @ts-expect-error
      .map(invoice => parseFloat(invoice.total_amount?.value))
      // @ts-expect-error
      .reduce((accumulator, currentValue) => accumulator + currentValue);

    // @ts-expect-error
    return this.intl.formatMoney(creditNotesSumUp, {
      currency: relatedInvoices[0]?.total_amount?.currency,
      signus: '-',
    });
  }

  get creditNotesCount() {
    // @ts-expect-error
    let { relatedInvoices } = this.args.invoice || [];

    return relatedInvoices?.length;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'SupplierInvoices::DetailsSidebar::Header': typeof SupplierInvoicesDetailsSidebarHeaderComponent;
  }
}
