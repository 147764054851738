/* import __COLOCATED_TEMPLATE__ from './eligibility.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { Eligibility } from 'qonto/react/financing/components/flows/pay-later/application/eligibility';

interface FlowsFinancingPayLaterApplicationEligibilitySignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsFinancingPayLaterApplicationEligibilityComponent extends Component<FlowsFinancingPayLaterApplicationEligibilitySignature> {
  eligibility = Eligibility;

  @service declare segment: Services['segment'];

  @action
  goToValueProp() {
    this.segment.track('pay-later-eligibility_activation_clicked');
    // @ts-expect-error
    this.args.transitionToNext();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Financing::PayLater::Application::Eligibility': typeof FlowsFinancingPayLaterApplicationEligibilityComponent;
  }
}
