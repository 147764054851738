export default {
  "details": "Ly",
  "top-details": "LU",
  "name": "Lj",
  "account-infobox": "LW",
  "row": "Lu",
  "icon": "TS",
  "account-number": "Tc",
  "currency": "Tq",
  "activity-tag": "TZ",
  "activity-icon": "TR",
  "edit-btn": "TQ",
  "action-buttons-container": "Te"
};
