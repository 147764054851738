/* import __COLOCATED_TEMPLATE__ from './failure.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

interface FlowsFinancingPayLaterApplicationFailureSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsFinancingPayLaterApplicationFailureComponent extends Component<FlowsFinancingPayLaterApplicationFailureSignature> {
  @service declare segment: Services['segment'];

  constructor(owner: unknown, args: FlowsFinancingPayLaterApplicationFailureSignature['Args']) {
    super(owner, args);
    this.segment.track('pay-later_signed_error_displayed', { origin: 'cockpit' });
  }

  @action
  backToValueProp() {
    this.segment.track('pay-later_try-again_clicked', { origin: 'yousign_failure' });
    // @ts-expect-error
    this.args.backToStep('value-proposition');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Financing::PayLater::Application::Failure': typeof FlowsFinancingPayLaterApplicationFailureComponent;
  }
}
