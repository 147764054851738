import { template as template_bba22bbfa08542b3a46f261382150773 } from "@ember/template-compiler";
import Section from './section';
import styles from './sidebar-section.strict-module.css';
const template = template_bba22bbfa08542b3a46f261382150773(`
  <Section
    @approvalWorkflowState={{@approvalWorkflowState}}
    @requestType={{@requestType}}
    class={{styles.sidebar}}
    ...attributes
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default template;
