/* import __COLOCATED_TEMPLATE__ from './withdrawal-limits.hbs'; */
// @ts-nocheck
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

// @ts-expect-error
import { BADGE_TYPE } from '@qonto/ui-kit/constants/badge-types';

// @ts-expect-error
import { SHOW_BY_COOKIE_RULES } from 'qonto/components/show-by-cookie';
import { CARD_LEVELS_TRACKING, UPSELL_BANNERS_COOKIE_IDS } from 'qonto/constants/cards';
// @ts-expect-error
import { ORIGIN } from 'qonto/routes/flows/setup/cards/upsell';
import {
  getAtmMonthlyLimitsMaximum,
  getAvailableThisMonth,
  getAvailableToday,
  isMonthlyLimitClose,
} from 'qonto/utils/cards/card-limits';

interface CardSidebarWithdrawalLimitsSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class CardSidebarWithdrawalLimits extends Component<CardSidebarWithdrawalLimitsSignature> {
  @service declare abilities: Services['abilities'];
  @service declare flowLinkManager: Services['flowLinkManager'];
  @service declare segment: Services['segment'];

  upsellBannerBadgeType = BADGE_TYPE.SAVE;
  upsellBannerCookieId = UPSELL_BANNERS_COOKIE_IDS.BANNER_WITHDRAWAL;
  upsellBannerCookieRule = SHOW_BY_COOKIE_RULES.FIRST_OF_NEXT_MONTH;

  get availableToday() {
    // @ts-expect-error
    let { card } = this.args;
    return getAvailableToday(card.atmDailyLimit, card.atmDailySpent, this.availableThisMonth);
  }

  get availableThisMonth() {
    // @ts-expect-error
    let { card } = this.args;
    return getAvailableThisMonth(card.atmMonthlyLimit, card.atmMonthlySpent);
  }

  get hasUpsellBanner() {
    // @ts-expect-error
    let { card, cardsLimits } = this.args;
    if (!cardsLimits) return false;

    return (
      this.abilities.can('upsell card', card) &&
      isMonthlyLimitClose(
        getAtmMonthlyLimitsMaximum(card.cardLevel, cardsLimits),
        card.atmMonthlySpent
      )
    );
  }

  @action
  goToUpsellFlow() {
    this.segment.track('cards_upsell_opened', {
      // @ts-expect-error
      card_id: this.args.card.id,
      // @ts-expect-error
      card_level_origin: CARD_LEVELS_TRACKING[this.args.card.cardLevel],
      origin: 'side_panel',
      trigger: 'withdrawal_limit',
    });

    // @ts-expect-error
    this.flowLinkManager.transitionTo({
      name: 'card-upsell',
      stepId: 'upsell',
      // @ts-expect-error
      queryParams: { cardId: this.args.card.id, origin: ORIGIN.WITHDRAWAL_LIMITS },
    });
  }

  @action
  onUpsellBannerClose() {
    this.segment.track('cards_upsell_trigger_closed', {
      // @ts-expect-error
      card_level_origin: CARD_LEVELS_TRACKING[this.args.card.cardLevel],
      origin: 'side_panel',
      trigger: 'withdrawal_limit',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'CardSidebar::WithdrawalLimits': typeof CardSidebarWithdrawalLimits;
  }
}
