/* import __COLOCATED_TEMPLATE__ from './item.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import ClientHubModel from 'qonto/models/client-hub';

interface ClientsTableItemSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLTableRowElement;
}

export default class ClientsTableItem extends Component<ClientsTableItemSignature> {
  @service declare segment: Services['segment'];

  get hasReminders() {
    // @ts-expect-error
    return this.args.item.hasReminders;
  }

  get hasMissingDetails() {
    // @ts-expect-error
    if (this.args.item instanceof ClientHubModel) {
      // @ts-expect-error
      return !this.args.item.validations.isValid;
    } else {
      return false;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Clients::Table::Item': typeof ClientsTableItem;
  }
}
