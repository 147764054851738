import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class InsuranceHubCategoriesRoute extends Route {
  @service insuranceManager;
  @service router;

  controllerName = 'insurance-hub';

  async model(params) {
    let { category_slug: categorySlug } = params;

    let products = await this.insuranceManager.getInsuranceProducts(categorySlug);

    return products.map(product => {
      return {
        logo: product.data.logo,
        name: product.data.name,
        description: product.data.description,
        risks: product.data.risks.map(item => item.risk.data.name),
        price: product.data.price,
        uid: product.uid,
      };
    });
  }
}
