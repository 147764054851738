/* import __COLOCATED_TEMPLATE__ from './placeholder.hbs'; */
import Component from '@glimmer/component';

import { SkeletonLoader } from '@repo/design-system-kit';

// @ts-expect-error
import styles from './placeholder.module.css';

interface TransactionsTablePlaceholderSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLTableRowElement;
}

export default class TransactionsTablePlaceholder extends Component<TransactionsTablePlaceholderSignature> {
  styles = styles;
  placeholderLine = SkeletonLoader.Line;
  placeholderHeader = SkeletonLoader.Header;
  placeholderAvatar = SkeletonLoader.Avatar;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transactions::Table::Placeholder': typeof TransactionsTablePlaceholder;
  }
}
