export default {
  "member-details": "ckW",
  "header": "cku",
  "no-email": "cJS",
  "header-img": "cJc",
  "invited-persona": "cJq",
  "revoked-persona": "cJZ",
  "invited-icon": "cJR",
  "revoked-icon": "cJQ",
  "revised": "cJe",
  "invitable-icon": "cJB",
  "dropdown": "cJE",
  "header-body": "cJd",
  "status": "cJI",
  "name": "cJt title-3",
  "email": "cJM caption-bold",
  "actions": "cJP body-2",
  "actions-cards": "cJl",
  "actions-transactions": "cJX",
  "body": "cJC",
  "expense-permissions-section": "cJk",
  "body-title": "cJJ title-4",
  "body-attr": "cJs",
  "body-label": "cJF",
  "attr-buttons": "cJg",
  "body-role": "cJD small",
  "details-actions": "cJV",
  "actions-item": "cJL",
  "disclaimer": "cJT",
  "permissions": "cJz",
  "permission": "cJa",
  "permission-label": "cJH",
  "permission-check": "cJO",
  "permission-missing": "cJm",
  "editable-section": "cJp",
  "hris-placeholder": "cJx",
  "imported-from": "cJw",
  "integration-logo": "cJN",
  "permission-value": "cJv",
  "tooltip": "cJo",
  "check-icon": "cJn"
};
