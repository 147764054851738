/* import __COLOCATED_TEMPLATE__ from './card.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { BadgeStatus, ProgressBar } from '@repo/design-system-kit';

// @ts-expect-error
import { DEFAULT_ORIGIN_URL, SAVINGS_DEFAULT_URL } from 'qonto/constants/bank-account';
// @ts-expect-error
import { NOTICE_PERIOD_AMOUNT_THRESHOLD } from 'qonto/constants/savings-account';

interface SavingsAccountCardSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class SavingsAccountCardComponent extends Component<SavingsAccountCardSignature> {
  progressBar = ProgressBar;

  badgeStatus = BadgeStatus;

  @service declare intl: Services['intl'];
  @service declare abilities: Services['abilities'];
  @service declare router: Services['router'];

  statusBadgeStrings = {
    // @ts-expect-error
    closed: this.intl.t('accounts.savings.card.status.closed'),
    // @ts-expect-error
    closing: this.intl.t('accounts.savings.card.status.closing'),
    // @ts-expect-error
    processing: this.intl.t('accounts.savings.card.status.processing'),
  };

  statusBadgeColors = {
    closed: 'inactive',
    closing: 'in-progress',
    processing: 'in-progress',
  };

  get noticePeriod() {
    // @ts-expect-error
    return this.args.savingsAccount.amount > NOTICE_PERIOD_AMOUNT_THRESHOLD ? 31 : 3;
  }

  statusTooltips = {
    // @ts-expect-error
    closing: this.intl.t('accounts.savings.card.closing-tooltip', { duration: this.noticePeriod }),
    // @ts-expect-error
    processing: this.intl.t('accounts.savings.card.processing-tooltip'),
  };

  get badgeString() {
    // @ts-expect-error
    return this.statusBadgeStrings[this.args.savingsAccount.status];
  }

  get badgeColor() {
    // @ts-expect-error
    return this.statusBadgeColors[this.args.savingsAccount.status];
  }

  get tooltip() {
    // @ts-expect-error
    return this.statusTooltips[this.args.savingsAccount.status];
  }

  get showOpenContractAction() {
    return (
      // @ts-expect-error
      this.abilities.can('create savingsAccount') && Boolean(this.args.savingsAccount.procedureId)
    );
  }

  get origin() {
    let origin = DEFAULT_ORIGIN_URL;
    if (this.router.currentRouteName?.includes(SAVINGS_DEFAULT_URL)) {
      origin = SAVINGS_DEFAULT_URL;
    }
    return origin;
  }

  get showOpenClosureContractAction() {
    return (
      this.abilities.can('create savingsAccount') &&
      // @ts-expect-error
      (this.args.savingsAccount.isClosing || this.args.savingsAccount.isClosedEarly)
    );
  }

  get showActionsDropdown() {
    return [
      this.showOpenClosureContractAction,
      this.showOpenContractAction,
      // @ts-expect-error
      this.args.savingsAccount.isCloseable,
    ].some(Boolean);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'SavingsAccounts::Card': typeof SavingsAccountCardComponent;
  }
}
