export default {
  "container": "qGn",
  "form-container": "qGi",
  "preview-container": "qGA",
  "credit-note-preview-container": "qGY",
  "credit-note-preview": "qGh",
  "content": "qGf",
  "title": "qGK",
  "tabs-container": "qGG",
  "close-button": "qGr btn btn--icon-only btn--tertiary btn--large",
  "header": "qGb",
  "without-tabs": "qGy"
};
