/* import __COLOCATED_TEMPLATE__ from './value-proposition.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask } from 'ember-concurrency';

import { ValueProposition } from 'qonto/react/financing/components/flows/pay-later/application/value-proposition';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';
interface PayLaterApplicationValuePropositionSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class PayLaterApplicationValuePropositionComponent extends Component<PayLaterApplicationValuePropositionSignature> {
  valueProposition = ValueProposition;

  @service declare deviceManager: Services['deviceManager'];
  @service declare intl: Services['intl'];
  @service declare router: Services['router'];
  @service declare sentry: Services['sentry'];
  @service declare segment: Services['segment'];
  @service declare financing: Services['financing'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];

  @action
  requestPayLaterSignature() {
    this.requestPayLaterSignatureTask.perform().catch(ignoreCancelation);
  }

  @action
  goToCockpit() {
    this.segment.track('pay-later-cockpit_displayed', {
      origin: 'value_prop',
    });
    this.router.replaceWith('financing.pay-later');
  }

  requestPayLaterSignatureTask = dropTask(async () => {
    try {
      let { url } = await this.financing.requestPayLaterSignature();
      // @ts-expect-error
      this.args.context.signature.url = url;
      // @ts-expect-error
      this.args.transitionToNext();
    } catch (error) {
      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      this.toastFlashMessages.toastError(
        this.intl.t('financing.pay-later.application-flow.value-proposition.error-toast')
      );
    }

    this.segment.track('pay-later-value_sign-contract_clicked');
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Financing::PayLater::Application::ValueProposition': typeof PayLaterApplicationValuePropositionComponent;
  }
}
