export default {
  "row": "gE",
  "active": "gd",
  "cell": "gI",
  "name-cell": "gt",
  "email-cell": "gM",
  "actions-cell": "gP",
  "name-container": "gl",
  "quick-actions-wrapper": "gX",
  "empty": "gC",
  "name-icon-container": "gk",
  "icon": "gJ",
  "tooltip-container": "gs",
  "missing-details-container": "gF",
  "missing-details-label": "gg body-2",
  "warning-icon": "gD gJ"
};
