export default {
  "header-cell": "chb",
  "header-content": "chy caption-bold",
  "active": "chU",
  "empty": "chj",
  "request-type": "chW",
  "date": "chu",
  "requester": "cfS",
  "amount": "cfc",
  "header-status": "cfq"
};
