export default {
  "container": "lM",
  "is-loaded": "lP",
  "disabled": "ll",
  "image": "lX",
  "infos": "lC",
  "fully-masked": "lk",
  "black": "lJ",
  "infos-type--virtual": "ls",
  "infos-holder": "lF",
  "infos-pan": "lg",
  "infos-pan-numbers": "lD",
  "infos-exp-date": "lV",
  "infos-cvv": "lL",
  "infos-caption": "lT",
  "copy-btn": "lz",
  "description-details": "la"
};
