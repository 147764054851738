/* import __COLOCATED_TEMPLATE__ from './sidebar.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

// @ts-expect-error
import { getEnabledLegalCountries, PROVIDERS_MAP } from 'qonto/constants/insurance-hub';
import { ProviderLogo } from 'qonto/react/components/insurance-hub/provider-logo';

interface InsuranceHubProductSidebarSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class InsuranceHubProductSidebarComponent extends Component<InsuranceHubProductSidebarSignature> {
  @service declare intl: Services['intl'];
  @service declare localeManager: Services['localeManager'];
  @service declare organizationManager: Services['organizationManager'];

  providerLogo = ProviderLogo;

  get shouldDisplayDisclaimer() {
    // @ts-expect-error
    return !this.args.hasActiveContract;
  }

  get legalCountry() {
    // @ts-expect-error
    return this.organizationManager.organization.legalCountry || this.args.legalCountry;
  }

  get legalArticle() {
    let { locale } = this.localeManager;

    if (!getEnabledLegalCountries().includes(this.legalCountry)) return;

    return (
      // @ts-expect-error
      PROVIDERS_MAP[this.legalCountry].legalArticle?.[locale] ||
      PROVIDERS_MAP[this.legalCountry].legalArticle.en
    );
  }

  get disclaimer() {
    let { organization } = this.organizationManager;
    let translationParams = {
      link: this.intl.t('insurance-hub.details.sidebar.link-text.legal-notice'),
    };

    return organization.isFrench
      ? {
          text: this.intl.t('insurance-hub.details.sidebar.legal-notice-fr', translationParams),
          key: 'insurance-hub.details.sidebar.legal-notice-fr',
        }
      : {
          text: this.intl.t('insurance-hub.details.sidebar.legal-notice-de', translationParams),
          key: 'insurance-hub.details.sidebar.legal-notice-de',
        };
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InsuranceHub::Product::Sidebar': typeof InsuranceHubProductSidebarComponent;
  }
}
