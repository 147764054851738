export default {
  "item-wrapper": "qIq",
  "title-sub-wrapper": "qIZ",
  "icon-title-wrapper": "qIR",
  "icon": "qIQ",
  "icon-wrapper": "qIe",
  "title": "qIB body-1",
  "subtitle": "qIE body-2",
  "amount": "qId body-1"
};
