export default {
  "container": "cRn",
  "form-core": "cRi",
  "label": "cRA",
  "request-description": "cRY",
  "radio-option": "cRh",
  "radio-button": "cRf",
  "inline-inputs": "cRK",
  "placeholder-label": "cRG",
  "placeholder-input": "cRr",
  "placeholder-checkbox": "cRb"
};
