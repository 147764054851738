export default {
  "accounts": "qwS",
  "header-subtitle": "qwc title-4",
  "subtitle-container": "qwq",
  "subtitle": "qwZ title-3 mb-16",
  "amount": "qwR body-2",
  "recommendations-section": "qwQ",
  "dismissing": "qwe",
  "recommendations-divider": "qwB",
  "list--cashbeeSavings": "qwE",
  "connections-banner": "qwd",
  "list": "qwI",
  "icon": "qwt",
  "badge": "qwM",
  "discover-tile": "qwP"
};
