export default {
  "header": "cbq",
  "header-type": "cbZ",
  "close": "cbR",
  "close-icon": "cbQ",
  "header-infos": "cbe",
  "title": "cbB body-1",
  "declined": "cbE",
  "canceled": "cbd",
  "approved": "cbI",
  "pending": "cbt",
  "date": "cbM body-2",
  "mileage-icon": "cbP",
  "distance-subtitle": "cbl body-2",
  "link-icon": "cbX",
  "container": "cbC",
  "picto": "cbk",
  "general": "cbJ",
  "amount": "cbs",
  "calculation-container": "cbF",
  "counterparty": "cbg body-2",
  "infobox": "cbD"
};
