export default {
  "row": "cfF",
  "icon": "cfg",
  "cell": "cfD",
  "cell-amount": "cfV",
  "cell-status": "cfL",
  "cell-date": "cfT",
  "cell-content": "cfz",
  "align-right": "cfa"
};
