import { useContext, type ReactNode } from 'react';
import {
  Button,
  GridList,
  GridListItem,
  Input,
  OverlayTriggerStateContext,
  type PopoverProps,
  type Selection,
} from 'react-aria-components';
import { useIntl } from 'react-intl';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { DataWithIconCell } from 'qonto/react/components/table-v2/cells/data-with-icon-cell';
import {
  Popover,
  PopoverSection,
  type OverlayTriggerState,
} from 'qonto/react/components/table-v2/popover';
import type { LabelList } from 'qonto/react/models/label';
import { Label as LabelComponent } from 'qonto/react/components/label';
import { External, SearchOutlined } from 'qonto/react/assets/icons';
import { LABEL_POPOVER_CLICK_EVENT_NAME } from 'qonto/react/constants';
import { getLabelValue } from '../../cells/label-cell/utils';
import { useLabelFilter } from './use-label-filter';
import styles from './styles.strict-module.css';

interface LabelPopoverProps {
  activeLabelIds: Selection;
  labelList: LabelList;
  onSelectionChange: (selectedLabelId: Selection, triggerState: OverlayTriggerState | null) => void;
  onLabelDismiss?: () => void;
  onEditLabelsLinkClick?: () => void;
  customPopoverProps?: Omit<PopoverProps, 'children'>;
}
export function LabelPopover({
  activeLabelIds,
  labelList,
  onLabelDismiss,
  onSelectionChange,
  onEditLabelsLinkClick,
  customPopoverProps,
}: LabelPopoverProps): ReactNode {
  const intl = useIntl();
  const segment = useEmberService('segment');
  const { labels: listLabels, color } = labelList;

  const triggerState = useContext(OverlayTriggerStateContext);
  const { filteredLabels, filter, resetFilter } = useLabelFilter(listLabels);

  const activeLabel = getLabelValue(activeLabelIds, listLabels);
  const listTitle = !filteredLabels.length
    ? intl.formatMessage({ id: 'labels.select_no_matches_message' })
    : intl.formatMessage({ id: 'placeholders.analytics_choice' });

  const handleSelectionChange = (selection: Selection): void => {
    onSelectionChange(selection, triggerState);
    segment.track(LABEL_POPOVER_CLICK_EVENT_NAME);
  };

  const searchInput = (
    <form className={styles.search} role="search">
      <SearchOutlined aria-hidden="true" />
      <Input
        aria-label="Search labels"
        data-testid="label-search-input"
        onChange={e => {
          filter(e.target.value);
        }}
        placeholder={intl.formatMessage({
          id: 'transactions.modular_table.labels.search.placeholder',
        })}
      />
    </form>
  );

  return (
    <Popover
      isOpen={triggerState?.isOpen}
      onOpenChange={() => {
        resetFilter();
        triggerState?.toggle();
      }}
      {...customPopoverProps}
    >
      <PopoverSection>
        <div className={styles['header-content']}>
          {activeLabel ? (
            <LabelComponent color={color} onDismiss={onLabelDismiss}>
              {activeLabel.name}
            </LabelComponent>
          ) : (
            searchInput
          )}
        </div>
      </PopoverSection>
      {activeLabel ? <PopoverSection>{searchInput}</PopoverSection> : null}
      <PopoverSection>
        <p className={styles['list-title']} data-testid="labels-list-title">
          {listTitle}
        </p>
        <GridList
          aria-label="available labels"
          data-testid="labels-list"
          disallowEmptySelection
          items={filteredLabels}
          onSelectionChange={handleSelectionChange}
          selectedKeys={activeLabelIds}
          selectionMode="single"
        >
          {({ name }) => (
            <GridListItem
              className={styles['list-item']}
              data-testid="labels-list-item"
              textValue={name}
            >
              <LabelComponent color={color}>{name}</LabelComponent>
            </GridListItem>
          )}
        </GridList>
      </PopoverSection>
      {onEditLabelsLinkClick ? (
        <PopoverSection>
          <Button className={styles['popover-link']} onPress={onEditLabelsLinkClick} type="button">
            <DataWithIconCell
              icon={<External />}
              title={intl.formatMessage({ id: 'transactions.modular_table.labels.edit.cta' })}
            />
          </Button>
        </PopoverSection>
      ) : null}
    </Popover>
  );
}
