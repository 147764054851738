export default {
  "row": "Eb body-1",
  "cell": "Ey",
  "counterparty": "EU",
  "counterparty-icon": "Ej",
  "avatar": "EW",
  "icon": "Eu",
  "counterparty-content": "dS",
  "item-counterparty-name": "dc",
  "counterparty-name": "dq body-1",
  "counterparty-status": "dZ",
  "member": "dR",
  "method": "dQ",
  "operation-date": "de",
  "amount": "dB",
  "remove-transaction": "dE",
  "menu-item": "dd",
  "remove": "dI",
  "tooltip": "dt",
  "empty": "dM"
};
