export default {
  "container": "ciR",
  "main": "ciQ",
  "preview": "cie",
  "form": "ciB",
  "scrollable": "ciE",
  "header": "cid title-1",
  "form-sections": "ciI",
  "form-section": "cit",
  "payment-methods": "ciM",
  "footer": "ciP",
  "preview-header": "cil",
  "preview-content-wrapper": "ciX",
  "preview-content": "ciC",
  "payment-link-preview": "cik"
};
