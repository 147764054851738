/* import __COLOCATED_TEMPLATE__ from './travel-assistance-abroad.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface TravelAssistanceAbroadSignature {
  Element: HTMLDivElement;
  Args: {};
  Blocks: {
    default: [];
  };
}

const TravelAssistanceAbroad = templateOnlyComponent<TravelAssistanceAbroadSignature>();

export default TravelAssistanceAbroad;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InsuranceAssistance::TravelAssistanceAbroad': typeof TravelAssistanceAbroad;
  }
}
