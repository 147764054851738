export default {
  "item": "cin",
  "details": "cii",
  "flex": "ciA",
  "header": "ciY ciA",
  "flex-no-gap": "cih ciA",
  "file-name": "cif",
  "label": "ciK body-2",
  "self-icon": "ciG",
  "with-separator": "cir",
  "dimmed": "cib"
};
