import Route from '@ember/routing/route';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';

import { CFT } from 'qonto/constants/sentry-tags';

export default class CashFlowRoute extends Route {
  @service featuresManager;
  @service abilities;
  @service homePage;

  queryParams = {
    bankAccounts: {
      refreshModel: true,
    },
  };

  beforeModel() {
    Sentry.getCurrentScope().setTag('CFT', CFT.CASH_FLOW_MANAGEMENT);

    if (this.abilities.cannot('view cash-flow')) {
      return this.homePage.visitDefaultPage();
    }
  }

  model({ bankAccounts }) {
    return { bankAccounts };
  }
}
