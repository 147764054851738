/* import __COLOCATED_TEMPLATE__ from './teams.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

interface PageHeaderTeamsSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLElement;
}

export default class PageHeaderTeamsComponent extends Component<PageHeaderTeamsSignature> {
  @service declare intl: Services['intl'];
  @service declare router: Services['router'];
  @service declare abilities: Services['abilities'];
  @service declare segment: Services['segment'];
  @service declare menu: Services['menu'];

  get title() {
    // @ts-expect-error
    return this.args.title ?? this.intl.t('navigation.settings.teams_management.members');
  }

  get allTeamsOption() {
    return {
      id: '',
      name: this.intl.t('activities.teams.filter_all'),
    };
  }

  get selectedTeam() {
    let teamId = this.router.currentRoute?.queryParams['teamId'];
    // @ts-expect-error
    let selectedTeam = this.args.teams.find(({ id }) => teamId === id);
    return selectedTeam || this.allTeamsOption;
  }

  get options() {
    // @ts-expect-error
    let options = [...this.args.teams].sort();
    return [this.allTeamsOption, ...options];
  }

  get canShowTeamFilter() {
    // @ts-expect-error
    return this.abilities.can('filter team') && this.args.teams?.length > 1;
  }

  @action
  // @ts-expect-error
  updateTeam({ id }) {
    this.segment.track('members_filter_team');
    this.router.transitionTo({
      queryParams: {
        teamId: id === '' ? null : id,
      },
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'PageHeader::Teams': typeof PageHeaderTeamsComponent;
  }
}
