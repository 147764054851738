export default {
  "container": "qGH",
  "form-container": "qGO",
  "title": "qGm",
  "title-border": "qGp",
  "tabs-container": "qGx",
  "preview-container": "qGw",
  "email-preview-container": "qGN",
  "close-button": "qGv btn btn--icon-only btn--tertiary btn--large"
};
