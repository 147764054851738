/* import __COLOCATED_TEMPLATE__ from './invite.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';
import { reads } from 'macro-decorators';

import { apiBaseURL, apiNamespace } from 'qonto/constants/hosts';
// @ts-expect-error
import { isValidEmail } from 'qonto/utils/email';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';

const TIER_LEVEL = {
  ONE: 1,
  TWO: 2,
};

interface ReferralInviteSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class ReferralInviteComponent extends Component<ReferralInviteSignature> {
  @service declare networkManager: Services['networkManager'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare segment: Services['segment'];
  @service declare intl: Services['intl'];
  @service declare sentry: Services['sentry'];
  @service('errors')
  declare errorsService: Services['errorsService'];

  // @ts-expect-error
  @reads('organizationManager.organization.referralUrl') referralLink;
  // @ts-expect-error
  referralMinimumTransactionAmount;

  @tracked email = null;
  @tracked errors = null;

  @action
  // @ts-expect-error
  updateEmail(email) {
    this.errors = null;
    this.email = email;
  }

  get tier1Eligibility() {
    return this.#getTierProperties(TIER_LEVEL.ONE);
  }

  get tier2Eligibility() {
    return this.#getTierProperties(TIER_LEVEL.TWO);
  }

  get detailErrors() {
    let errorKey = !this.email
      ? 'empty-email'
      : !isValidEmail(this.email)
        ? 'incorrect-email'
        : // @ts-expect-error
          this.errors[0].errors.email[0];
    return this.intl.t(`referral.errors.${errorKey}`, {
      emails: this.email,
      count: 1,
    });
  }

  // @ts-expect-error
  #getTierProperties(tierLevel) {
    // @ts-expect-error
    let { eligibility, reward_amount: referralRewardAmount } = this.args.eligibilities.find(
      // @ts-expect-error
      eligibility => eligibility.tier === tierLevel
    );
    let { required_spent_amount: referralMinimumTransactionAmount } = eligibility;

    return {
      referralRewardAmount,
      referralMinimumTransactionAmount,
    };
  }

  sendReferralTask = dropTask(async () => {
    if (!isValidEmail(this.email)) {
      // @ts-expect-error
      return (this.errors = true);
    }

    let organizationId = this.organizationManager.organization.id;
    let data = {
      referrals: { emails: [this.email], organization_id: organizationId },
    };
    let url = `${apiBaseURL}/${apiNamespace}/referrals`;

    try {
      await this.networkManager.request(url, {
        method: 'POST',
        data: JSON.stringify(data),
      });
      this.toastFlashMessages.toastSuccess(this.intl.t('referral.success.toast'));
    } catch (error) {
      // @ts-expect-error
      if (error.responseJSON?.referrals) {
        // @ts-expect-error
        this.errors = error.responseJSON.referrals;
      } else {
        if (ErrorInfo.for(error).shouldSendToSentry) this.sentry.captureException(error);
        this.toastFlashMessages.toastError(
          // @ts-expect-error
          this.errorsService.messageForStatus(error) || this.intl.t('toasts.errors.server_error')
        );
      }
    }
    this.segment.track('referral_share_click', { action: 'send_email' });
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Referral::Invite': typeof ReferralInviteComponent;
  }
}
