export default {
  "display-block": "qOo",
  "pay-later-tooltip": "qOn",
  "disabled": "qOi",
  "stepper": "qOA",
  "installments": "qOY",
  "flex-items": "qOh",
  "day-label": "qOf body-2",
  "installment-amount": "qOK body-1",
  "spacing": "qOG",
  "info-icon": "qOr",
  "detail": "qOb",
  "detail-value": "qOy",
  "extra": "qOU",
  "extra-value": "qOj",
  "badge": "qOW",
  "title": "qOu",
  "link": "qmS"
};
