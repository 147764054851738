export default {
  "wrapper": "cTJ",
  "cashflow-status": "cTs",
  "status": "cTF",
  "estimated": "cTg",
  "vat-due-amount": "cTD title-3",
  "vat-tooltip": "cTV",
  "icon-info": "cTL",
  "charts-wrapper": "cTT",
  "chart-error": "cTz",
  "chart-controls": "cTa",
  "amount-loading": "cTH"
};
