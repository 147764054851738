/* import __COLOCATED_TEMPLATE__ from './no-mandate.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask } from 'ember-concurrency';
import { reads } from 'macro-decorators';

interface NoMandatesSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class NoMandatesComponent extends Component<NoMandatesSignature> {
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare intl: Services['intl'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare bankAccount: Services['bankAccount'];

  // @ts-expect-error
  @reads('organizationManager.organization') organization;
  // @ts-expect-error
  @reads('organizationManager.membership') membership;
  // @ts-expect-error
  @reads('organizationManager.currentAccount') currentAccount;

  get hasMultiAccounts() {
    return this.organization.hasMultipleActiveAccounts;
  }

  handleDownloadRIB = dropTask(async account => {
    // @ts-expect-error
    await this.bankAccount.downloadIbanPdf(account.id);
  });

  @action
  handleErrorCopy() {
    let message = this.intl.t('mandates.copy-clipboard.fail');
    this.toastFlashMessages.toastError(message);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Mandate::NoMandate': typeof NoMandatesComponent;
  }
}
