/* import __COLOCATED_TEMPLATE__ from './tabs.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface TabsSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const Tabs = templateOnlyComponent<TabsSignature>();

export default Tabs;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ReceivableInvoices::Tabs': typeof Tabs;
  }
}
