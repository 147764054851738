// @ts-nocheck
import Service, { service } from '@ember/service';

import { Popup, type PopupProps } from '@repo/design-system-kit';
import React from 'react';
import { createRoot, type Root } from 'react-dom/client';

import ENV from 'qonto/config/environment';

const isTest = ENV.environment === 'test';

type PopupType = PopupProps['type'];
type ReactComponentOrPopupTypeString = React.ComponentType<any> | PopupType;
type AnyObject = Record<string, any>;

function getComponentToRender(
  PopupComponent: ReactComponentOrPopupTypeString = 'confirm'
): React.ComponentType<any> {
  if (typeof PopupComponent === 'string') {
    return Popup;
  } else {
    return PopupComponent;
  }
}

function getComponentProps(
  PopupComponent: ReactComponentOrPopupTypeString = 'confirm',
  popupData: AnyObject
): AnyObject {
  return {
    ...(typeof PopupComponent === 'string' && { type: PopupComponent }),
    ...popupData,
  };
}

export default class ReactPopupService extends Service {
  @service sentry;

  reactRoot: Root = null;

  open(PopupComponent: ReactComponentOrPopupTypeString, popupData: AnyObject) {
    return new Promise(resolve => {
      let appRoot = document.getElementById('react-bridge-popup-root');

      if (!appRoot) {
        let error = new Error(
          'Could not open the popup because #react-bridge-popup-root node was not found'
        );
        this.sentry.captureException(error);
        return;
      }

      const Component = getComponentToRender(PopupComponent);
      const defaultOnOpenChange = (isOpen: boolean) => {
        if (!isOpen) {
          resolve('cancel');
          this.reactRoot.unmount();
        }
      };

      // react-aria modal escapes the test container, we render it where it can find the modal (i.e. <main> component)
      let TEST_PORTAL_CONTAINER = document.querySelector('main') ?? undefined;
      this.reactRoot = createRoot(appRoot);
      this.reactRoot.render(
        React.createElement(Component, {
          isOpen: true,
          ...getComponentProps(PopupComponent, popupData),
          onConfirm: () => {
            resolve('confirm');
            this.reactRoot.unmount();
          },
          onCancel: () => {
            resolve('cancel');
            this.reactRoot.unmount();
          },
          onOpenChange: popupData.onOpenChange
            ? () => popupData.onOpenChange(resolve, reject)
            : defaultOnOpenChange,
          portalContainer: isTest ? TEST_PORTAL_CONTAINER : undefined,
        })
      );
    });
  }

  close() {
    this.reactRoot.unmount();
  }
}

declare module '@ember/service' {
  interface Registry {
    'react-popup': ReactPopupService;
    reactPopup: ReactPopupService;
  }
}
