/* import __COLOCATED_TEMPLATE__ from './imported.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface ImportedSignature {
  Element: HTMLDivElement;
  Args: {};
  Blocks: {
    default: [];
  };
}

const Imported = templateOnlyComponent<ImportedSignature>();

export default Imported;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ReceivableInvoices::InvoiceModal::Sidebar::Placeholder::Imported': typeof Imported;
  }
}
