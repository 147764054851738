export default {
  "card": "IQ",
  "row": "Ie",
  "icon": "IB",
  "unrevokable": "IE",
  "active": "Id",
  "kyc-pending": "II",
  "shareholder-info": "It",
  "name": "IM",
  "iban": "IP",
  "tooltip": "Il",
  "shareholder-capital": "IX",
  "shareholder-capital-amount": "IC",
  "deposit-capital": "Ik",
  "valid": "IJ",
  "pending": "Is",
  "promised-capital-valid": "IF",
  "promised-capital": "Ig"
};
