export default {
  "iban": "qZa",
  "checkbox": "qZH",
  "toggle-content": "qZO",
  "beneficiary-name": "qZm",
  "category": "qZp",
  "beneficiary-email": "qZx",
  "beneficiary-form": "qZw",
  "beneficiary-email-container": "qZN"
};
