import type { MonthlyBalance, MonthlyFlow } from 'qonto/react/components/cash-flow/api/balance';

import { utcToZonedDate } from '../chart';

export interface FlowTooltip {
  amount: number;
  count: number;
  variation: number;
}

export interface BalanceDisplay {
  netChange: number;
}

export interface CashflowTimevalueData {
  startingBalance: number;
  endingBalance: number;
  inflows: number;
  outflows: number;
}

export interface CashflowTooltip {
  balance: BalanceDisplay;
  inflows: FlowTooltip;
  outflows: FlowTooltip;
}

export interface CashflowTimevalue {
  time: Date;
  data: CashflowTimevalueData;
  display: CashflowTooltip;
}

export function buildCashflowTimevalue(
  inflows: MonthlyFlow,
  outflows: MonthlyFlow,
  balance: MonthlyBalance,
  timezone: string = 'Europe/Paris',
  isCurrent = false,
  isFuture = false
): CashflowTimevalue {
  const currentBalance = Number(balance?.data?.current_balance ?? 0);
  const startingBalance = Number(balance?.data?.starting_balance ?? 0);
  const forecastedStartingBalance = Number(balance?.data?.forecasted_starting_balance ?? 0);
  const endingBalance = Number(balance?.data?.ending_balance ?? 0);
  const forecastedEndingBalance = Number(balance?.data?.forecasted_ending_balance ?? 0);

  const inflowsAmount = Number(inflows?.data?.amount_sum ?? 0);
  const inflowsForecastAmount = Number(inflows?.data?.forecast_amount ?? 0);
  const inflowsCount = Number(inflows?.data?.amount_count ?? 0);
  const outflowsAmount = Number(outflows?.data?.amount_sum ?? 0);
  const outflowsForecastAmount = Number(outflows?.data?.forecast_amount ?? 0);
  const outflowsCount = Number(outflows?.data?.amount_count ?? 0);

  const inflowsVariation = Number(inflows?.data?.growth_rate ?? 0);
  const outflowsVariation = Number(outflows?.data?.growth_rate ?? 0);

  const netChange = endingBalance - startingBalance;
  const forecastedCurrentNetChange = forecastedEndingBalance - startingBalance;
  const forecastedNetChange = forecastedEndingBalance - forecastedStartingBalance;

  const data = {
    isCurrent,
    isFuture,
    currentBalance,
    startingBalance,
    endingBalance,
    forecastedStartingBalance,
    forecastedEndingBalance,
    netChange,
    forecastedNetChange,
    forecastedCurrentNetChange,
    vatCredit: Number(balance?.data?.vat_credit_amount ?? 0),
    vatDebit: Number(balance?.data?.vat_debit_amount ?? 0),
    vatDue: Number(balance?.data?.vat_due_amount ?? 0),
    inflows: inflowsAmount,
    inflowsForecast: inflowsForecastAmount,
    outflows: -outflowsAmount,
    outflowsForecast: -outflowsForecastAmount,
  };

  const balanceNetChange = isCurrent
    ? forecastedCurrentNetChange
    : isFuture
      ? forecastedNetChange
      : netChange;

  const display = {
    balance: { netChange: balanceNetChange },
    inflows: {
      isCurrent,
      isFuture,
      amount: inflowsAmount,
      forecastAmount: inflowsForecastAmount,
      count: inflowsCount,
      variation: inflowsVariation,
    },
    outflows: {
      isCurrent,
      isFuture,
      amount: outflowsAmount,
      forecastAmount: outflowsForecastAmount,
      count: outflowsCount,
      variation: outflowsVariation,
    },
  };

  return { time: utcToZonedDate(balance.start_date, timezone)!, data, display };
}
