export default {
  "container": "cTI",
  "indicator-container": "cTt",
  "transactions-container": "cTM",
  "indicator": "cTP",
  "cashflow-container": "cTl",
  "cashflow": "cTX",
  "donut-container": "cTC",
  "donut": "cTk"
};
