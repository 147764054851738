/* import __COLOCATED_TEMPLATE__ from './bic.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface BicSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const Bic = templateOnlyComponent<BicSignature>();

export default Bic;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'BankAccount::Bic': typeof Bic;
  }
}
