export default {
  "container": "YL",
  "company-details": "YT",
  "sideview": "Yz",
  "form": "Ya",
  "french-company-details-fieldset": "YH",
  "footer": "YO",
  "pdf-preview": "Ym",
  "de-layout": "Yp",
  "de-pdf-preview": "Yx",
  "german-preview": "Yw"
};
