/* import __COLOCATED_TEMPLATE__ from './fail.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface FailSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const Fail = templateOnlyComponent<FailSignature>();

export default Fail;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'HelpCenter::SelectedItem::Claim::Fail': typeof Fail;
  }
}
