import Model, { attr, belongsTo } from '@ember-data/model';
import { action } from '@ember/object';
import { getOwner } from '@ember/owner';
import { service, type Registry as Services } from '@ember/service';
import { isNone } from '@ember/utils';

import CURRENCIES from 'qonto/constants/currencies';
import { DEFAULT_VAT_RATE_PER_COUNTRY } from 'qonto/constants/receivable-invoice';
// @ts-expect-error
import { limitNumberOfNewLines, multiply, round } from 'qonto/utils/receivable-invoicing';

// @ts-expect-error
export function defaultValues(ownerOrOwned, vatRate, vatExemptionCode) {
  let owner = getOwner(ownerOrOwned) || ownerOrOwned;
  let organizationManager = owner.lookup('service:organization-manager');

  let defaultVatRate;
  if (isNone(vatRate)) {
    // @ts-expect-error
    defaultVatRate = DEFAULT_VAT_RATE_PER_COUNTRY[organizationManager.organization?.legalCountry];
  } else {
    defaultVatRate = String(vatRate);
  }

  let lastVatExemptionCode;
  if (vatExemptionCode !== undefined) {
    lastVatExemptionCode = vatExemptionCode;
  }

  return {
    vatRate: defaultVatRate,
    vatExemptionCode: lastVatExemptionCode,
  };
}

export default class ReceivableInvoiceItemModel extends Model {
  @service declare intl: Services['intl'];
  @service declare organizationManager: Services['organizationManager'];

  // @ts-expect-error
  @attr title;
  // @ts-expect-error
  @attr description;
  @attr('string') declare quantity: string;
  /** @type {string | null} */
  // @ts-expect-error
  @attr unit;
  @attr('string') declare unitPrice: string;
  @attr('string') declare vatRate: string;
  // @ts-expect-error
  @attr('hash') discount;
  // @ts-expect-error
  @attr vatExemptionCode;
  @attr('string', { defaultValue: CURRENCIES.default }) declare currency: string;
  /** @type "good" | "service" | null **/
  @attr('string') declare type: string;
  /** @type Array<{ title: string, url: string }> **/
  // @ts-expect-error
  @attr links;
  /** @type {string | null} */
  // @ts-expect-error
  @attr productId;

  // @ts-expect-error
  @belongsTo('receivableInvoice', { async: true, inverse: 'items' }) receivableInvoice;
  // @ts-expect-error
  @belongsTo('selfInvoice', { async: true, inverse: 'items' }) selfInvoice;
  // @ts-expect-error
  @belongsTo('quote', { async: true, inverse: 'items' }) quote;
  // @ts-expect-error
  @belongsTo('invoiceSubscription', { async: true, inverse: 'items' }) invoiceSubscription;

  @action
  // @ts-expect-error
  updateDescription(description) {
    this.description = limitNumberOfNewLines(description, 10);
  }

  /*
    CALCULATIONS: there are 2 getters for each total
    - one getter for the UI (PDF PREVIEW) that is rounded and fixed to 2 decimals
    - one getter (called precise) for internal calculations that not is rounded and not fixed
      that is used for further calculations on the document level
    This is done to match the BE calculations, where every argument of the calculation is recalculated (so it needs to be the absolute value)
  */

  /*
    Returning the not rounded result of the calculation, to reuse it for further calculations
  */
  get preciseTotalExcludingVat() {
    return this.unitPrice && this.quantity
      ? multiply(parseFloat(this.quantity), parseFloat(this.unitPrice))
      : 0;
  }

  /*
    Rounding the float value is required to avoid imprecise decimals rounding
    When there is a 5 in the third decimal position, JS will round down instead of up
    Example: 0.145  will be parsed as 0.14, when instead the rounded value wanted is 0.15
  */
  get totalExcludingVat() {
    return round(this.preciseTotalExcludingVat, 100).toFixed(2);
  }

  get precisePercentageDiscountAmount() {
    return this.preciseTotalExcludingVat && this.discount?.value
      ? multiply(this.discount.value, this.preciseTotalExcludingVat)
      : 0;
  }

  get percentageDiscountAmount() {
    return round(this.precisePercentageDiscountAmount, 100).toFixed(2);
  }

  get preciseTotalDiscount() {
    if (this.discount?.type === 'absolute') {
      // @ts-expect-error
      let sign = Math.sign(this.quantity || 0) * Math.sign(this.unitPrice || 0);
      return this.discount.value * sign;
    } else if (this.discount?.type === 'percentage') {
      return this.precisePercentageDiscountAmount;
    } else {
      return 0;
    }
  }

  get totalDiscount() {
    return round(this.preciseTotalDiscount, 100).toFixed(2);
  }

  get preciseDiscountedTotalExcludingVat() {
    let totalExcludingVat = this.preciseTotalExcludingVat;
    if (this.discount?.type === 'percentage') {
      let totalDiscount = this.precisePercentageDiscountAmount;

      return parseFloat(totalExcludingVat) - parseFloat(totalDiscount);
    }

    return this.discount?.type === 'absolute'
      ? parseFloat(totalExcludingVat) - parseFloat(this.preciseTotalDiscount)
      : totalExcludingVat;
  }

  // discountedTotalExcludingVat = totalExcludingVat - discount
  get discountedTotalExcludingVat() {
    return round(this.preciseDiscountedTotalExcludingVat, 100).toFixed(2);
  }

  get preciseTotalVat() {
    let total = this.preciseTotalExcludingVat - this.preciseTotalDiscount;
    return multiply(this.vatRate, total);
  }

  get totalVat() {
    return round(this.preciseTotalVat, 100).toFixed(2);
  }

  get preciseTotalAmount() {
    return (
      parseFloat(this.preciseTotalExcludingVat) +
      parseFloat(this.preciseTotalVat) -
      parseFloat(this.preciseTotalDiscount)
    );
  }

  get totalAmount() {
    return round(this.preciseTotalAmount, 100).toFixed(2);
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'receivable-invoice/item': ReceivableInvoiceItemModel;
  }
}
