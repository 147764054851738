export default {
  "row": "HR",
  "cell": "HQ body-2",
  "empty": "He",
  "cell-content": "HB",
  "cell-content--amount": "HE body-1",
  "icon": "Hd",
  "description": "HI",
  "tertiary": "Ht",
  "details": "HM"
};
