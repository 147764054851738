export default {
  "invoice-sidebar": "cnR",
  "body": "cnQ",
  "box": "cne",
  "box-header": "cnB",
  "footer": "cnE",
  "footer-button": "cnd",
  "sidebar-box": "cnI",
  "border-top": "cnt",
  "row": "cnM",
  "status-container": "cnP",
  "due-date-container": "cnl",
  "mark-as-btn": "cnX",
  "primary-actions": "cnC cnI",
  "primary-action": "cnk body-1",
  "button-icon": "cnJ"
};
