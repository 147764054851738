import { InvalidError } from '@ember-data/adapter/error';
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { service, type Registry as Services } from '@ember/service';
import { waitFor } from '@ember/test-waiters';

// @ts-expect-error
import { apiAction } from '@mainmatter/ember-api-actions';
import { dropTask, task } from 'ember-concurrency';
import { equal } from 'macro-decorators';

// @ts-expect-error
import { BOOKKEEPING_STATUSES } from 'qonto/constants/bookkeeping';
// @ts-expect-error
import { errorsArrayToHash } from 'qonto/utils/errors-array-to-hash';

export default class BookkeepingTransactionModel extends Model {
  @service declare intl: Services['intl'];

  @attr('date') declare emittedAt: Date;
  @attr('string') declare subjectId: string;
  @attr('string') declare subjectType: string;
  // @ts-expect-error
  @attr() amount;
  // @ts-expect-error
  @attr() localAmount;
  // @ts-expect-error
  @attr() enrichmentData;

  // @ts-expect-error
  @attr() vat;

  @attr('string') declare side: string;
  @attr('string') declare counterpartyName: string;
  @attr('string') declare note: string;
  @attr('string') declare activityTag: string;
  @attr('string') declare bookkeepingStatus: string;
  @attr('string') declare operationMethod: string;
  @attr('string') declare initiatorId: string;
  @attr('boolean') declare attachmentRequested: boolean;
  @attr('boolean') declare attachmentLost: boolean;
  @attr('boolean') declare attachmentRequired: boolean;

  // @ts-expect-error
  @hasMany('bookkeeping-account-entry', { async: false, inverse: 'transaction' }) accountEntries;

  // @ts-expect-error
  @hasMany('attachment', { async: false, inverse: null }) attachments;
  // @ts-expect-error
  @attr() attachmentIds;
  // @ts-expect-error
  @attr() attachmentSuggestionIds;

  // @ts-expect-error
  @belongsTo('membership', { async: true, inverse: null }) initiator;
  // @ts-expect-error
  @belongsTo('subject', { async: false, inverse: null, polymorphic: true }) subject;

  // @ts-expect-error
  @equal('side', 'debit') debit;
  // @ts-expect-error
  @equal('side', 'credit') credit;

  // @ts-expect-error
  @equal('operationMethod', 'pay_later') isPayLater;
  // @ts-expect-error
  @equal('operationMethod', 'biller') isFee;

  get attachmentCount() {
    return this.attachmentIds?.length ?? 0;
  }

  get signedAmount() {
    return this.debit ? -this.amount?.value : this.amount?.value;
  }

  get signedLocalAmount() {
    return this.debit ? -this.localAmount?.value : this.localAmount?.value;
  }

  get amountCurrency() {
    return this.amount?.currency;
  }

  get localAmountCurrency() {
    return this.localAmount?.currency;
  }

  get isFx() {
    let amountCurrency = this.amountCurrency;
    let localAmountCurrency = this.localAmountCurrency;
    if (amountCurrency && localAmountCurrency) {
      return amountCurrency.toLowerCase() !== localAmountCurrency.toLowerCase();
    }

    return false;
  }

  get isTransfer() {
    return this.subjectType?.toLowerCase() === 'transfer';
  }

  get avatarInfo() {
    let activityTagSVG = `/icon/category/${this.activityTag}-m.svg`;

    return {
      mediumLogo: this.enrichmentData?.logo?.medium ?? activityTagSVG,
      smallLogo: this.enrichmentData?.logo?.small ?? activityTagSVG,
    };
  }

  get accountEntriesStringify() {
    // @ts-expect-error
    let entriesCode = this.accountEntries.map(entry => entry?.account?.code);
    return entriesCode.join(', ');
  }

  get operationTypeTranslations() {
    return {
      biller: this.intl.t('transactions.operation-types.biller'),
      card: this.intl.t('transactions.operation-types.card'),
      cheque: this.intl.t('transactions.operation-types.cheque'),
      direct_debit: this.intl.t('transactions.operation-types.direct-debit'),
      tax: this.intl.t('transactions.operation-types.tax'),
      transfer: this.intl.t('transactions.operation-types.transfer'),
      pay_later: this.intl.t('transactions.operation-types.pay-later'),
      other: this.intl.t('transactions.operation-types.unknown'),
    };
  }

  get methodLabel() {
    if (this.operationMethod) {
      // @ts-expect-error
      let method = this.operationTypeTranslations[this.operationMethod];

      if (this.operationMethod === 'card') {
        // @ts-expect-error
        let memberName = this.get('initiator.memberName');
        if (memberName) {
          return { method, name: memberName };
        }
      }
      return { method };
    }
  }

  // @ts-expect-error
  _handleError(error) {
    // @ts-expect-error
    if (error instanceof InvalidError && error.errors) {
      // @ts-expect-error
      let errors = errorsArrayToHash(error.errors);
      // @ts-expect-error
      this.networkManager.errorModelInjector(this, errors);
    }

    throw error;
  }

  // @ts-expect-error
  _adjustResponse(response, transaction) {
    response['bookkeeping-transaction'] = transaction;
    response['bookkeeping-transaction'].amount = {
      value: transaction.amount,
      currency: transaction.amount_currency,
    };
    response['bookkeeping-transaction'].localAmount = {
      value: transaction.local_amount,
      currency: transaction.local_amount_currency,
    };

    return response;
  }

  // @ts-expect-error
  unlinkAttachment(attachments) {
    return this.unlinkAttachmentTask.perform(attachments);
  }

  unlinkAttachmentTask = dropTask(async attachments => {
    // @ts-expect-error
    await this._unlinkAttachment(attachments.map(it => it.id));
  });

  @waitFor
  // @ts-expect-error
  async _unlinkAttachment(attachmentIds) {
    let data = { transaction: { attachment_ids: attachmentIds } };
    try {
      let response = await apiAction(this, {
        method: 'PATCH',
        path: 'unlink_attachments',
        data,
        adapterOptions: {
          useTransactionsEndpoint: true,
        },
      });

      let adjustedResponse = this._adjustResponse(response, response.transaction);
      delete adjustedResponse.transaction;
      this.store.pushPayload(adjustedResponse);
    } catch (error) {
      this._handleError(error);
    }
  }

  // @ts-expect-error
  linkAttachment(attachments) {
    return this.linkAttachmentTask.perform(attachments);
  }

  linkAttachmentTask = task({ maxConcurrency: 5, enqueue: true }, async attachments => {
    // @ts-expect-error
    await this._linkAttachment(attachments.map(it => it.id));
  });

  @waitFor
  // @ts-expect-error
  async _linkAttachment(attachmentIds) {
    let data = { transaction: { attachment_ids: attachmentIds } };

    try {
      let response = await apiAction(this, {
        method: 'PATCH',
        path: 'link_attachments',
        data,
        adapterOptions: {
          useTransactionsEndpoint: true,
        },
      });

      let adjustedResponse = this._adjustResponse(response, response.transaction);
      delete adjustedResponse.transaction;
      this.store.pushPayload(adjustedResponse);
    } catch (error) {
      this._handleError(error);
    }
  }

  @waitFor
  async verify() {
    try {
      await apiAction(this, { method: 'POST', path: 'verify' });
      this.store.pushPayload({
        bookkeeping_transaction: {
          id: this.id,
          bookkeeping_status: BOOKKEEPING_STATUSES.VERIFIED,
        },
      });
    } catch (error) {
      this._handleError(error);
    }
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'bookkeeping-transaction': BookkeepingTransactionModel;
  }
}
