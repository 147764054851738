/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';

import {
  ApproverPlaceholder,
  RequesterPlaceholder,
} from 'qonto/react/spend-management/components/requests-table/placeholder';

interface CardRequestsTableSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class CardRequestsTable extends Component<CardRequestsTableSignature> {
  approverPlaceholder = ApproverPlaceholder;
  requesterPlaceholder = RequesterPlaceholder;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Card::RequestsTable::Index': typeof CardRequestsTable;
  }
}
