import Model, { attr } from '@ember-data/model';

import { equal } from 'macro-decorators';

export default class AttachmentModel extends Model {
  @attr('string') declare slug: string;
  @attr('string') declare downloadUrl: string;
  @attr('date', {
    defaultValue() {
      return new Date();
    },
  })
  // @ts-expect-error
  createdAt;
  // @ts-expect-error
  @attr('file') file;
  // @ts-expect-error
  @attr('file') probativeAttachment;
  // @ts-expect-error
  @attr('file') thumbnail;
  @attr('string') declare attachmentType: string;
  @attr('string') declare documentType: string;

  // @ts-expect-error
  @equal('probativeAttachment.status', 'available') isProbated;

  vatStatus = null;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    attachment: AttachmentModel;
  }
}
