export default {
  "container": "JN",
  "content": "Jv",
  "subtitle": "Jo",
  "switch": "Jn",
  "card-details": "Ji",
  "asset-container": "JA",
  "asset-container-padded": "JY",
  "asset": "Jh",
  "right-container": "Jf",
  "card-brand-title": "JK",
  "card-brand-badge": "JG",
  "free-subtitle": "Jr",
  "benefit-container": "Jb",
  "checkmark": "Jy",
  "container-metal": "JU"
};
