/* import __COLOCATED_TEMPLATE__ from './theme-selector-item.hbs'; */
import Component from '@glimmer/component';

import { RadioButtonControlled } from '@repo/design-system-kit';

interface ThemeSelectorItemSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLLabelElement;
}

export default class ThemeSelectorItem extends Component<ThemeSelectorItemSignature> {
  radioButtonControlled = RadioButtonControlled;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ThemeSelector::ThemeSelectorItem': typeof ThemeSelectorItem;
  }
}
