/* import __COLOCATED_TEMPLATE__ from './footer.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

// @ts-expect-error
import { FACILE_INSURANCE_PRODUCT_LEGAL_TYPES } from 'qonto/constants/insurance-hub';

interface FooterSignature {
  // The arguments accepted by the component
  Args: {
    products?: any[];
    includeLegalNotice?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class InsuranceHubFooterComponent extends Component<FooterSignature> {
  @service declare intl: Services['intl'];
  @service declare organizationManager: Services['organizationManager'];

  get organizationLegalCountry() {
    return this.organizationManager.organization?.legalCountry;
  }

  get productIds() {
    return (
      this.args.products?.reduce((accumulator, current) => [...accumulator, current.uid], []) ?? []
    );
  }

  get legalNotices() {
    let legalNotices = [];

    if (this.productIds.includes(FACILE_INSURANCE_PRODUCT_LEGAL_TYPES.PERSONAL_PROTECTION))
      legalNotices.push(this.intl.t('insurance-hub.footer.legal-notice-it.personal-protection'));

    if (this.productIds.includes(FACILE_INSURANCE_PRODUCT_LEGAL_TYPES.MULTI_RISK_FOR_BUSINESS))
      legalNotices.push(
        this.intl.t('insurance-hub.footer.legal-notice-it.multi-risk-for-business')
      );
    if (
      this.productIds.includes(
        FACILE_INSURANCE_PRODUCT_LEGAL_TYPES.MULTI_RISK_FOR_CORPORATE_ENTITIES
      )
    )
      legalNotices.push(
        this.intl.t('insurance-hub.footer.legal-notice-it.multi-risk-for-corporate-entities')
      );
    if (this.productIds.includes(FACILE_INSURANCE_PRODUCT_LEGAL_TYPES.MULTI_RISK_FOR_PROFESSIONAL))
      legalNotices.push(
        this.intl.t('insurance-hub.footer.legal-notice-it.multi-risk-for-professional')
      );
    if (this.productIds.includes(FACILE_INSURANCE_PRODUCT_LEGAL_TYPES.CATASTROPHIC_DAMAGE))
      legalNotices.push(this.intl.t('insurance-hub.footer.legal-notice-it.catastrophic-damage'));

    return legalNotices;
  }

  get showLegalNotice() {
    return (
      this.args.includeLegalNotice &&
      this.organizationLegalCountry === 'IT' &&
      Boolean(this.legalNotices.length)
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InsuranceHub::Footer': typeof InsuranceHubFooterComponent;
  }
}
