/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

interface TransactionsSidebarHeaderSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLElement;
}

export default class TransactionsSidebarHeaderComponent extends Component<TransactionsSidebarHeaderSignature> {
  @service declare organizationManager: Services['organizationManager'];
  @service declare store: Services['store'];
  @service declare segment: Services['segment'];
  @service declare router: Services['router'];

  get showAccountName() {
    // @ts-expect-error
    let { highlightedItem: transaction } = this.args;

    return (
      this.organizationManager.organization.hasMultipleBankAccounts && transaction.bankAccount?.name
    );
  }

  get isUserInitiator() {
    // @ts-expect-error
    let { highlightedItem: transaction } = this.args;

    if (transaction.initiator?.id) {
      let { id: userId } = this.organizationManager.membership;
      let { id: initiatorId } = transaction.initiator;

      return userId === initiatorId;
    }
    return false;
  }

  get isExternalCheckTransaction() {
    // @ts-expect-error
    let { isExternalTransaction, operationMethod } = this.args.highlightedItem;

    return isExternalTransaction && operationMethod === 'cheque';
  }

  get shouldDisplayKycDisclaimer() {
    // @ts-expect-error
    let { highlightedItem: transaction } = this.args;
    if (!transaction.isTransfer) {
      return false;
    }
    if (this.isUserInitiator) {
      let { kycRequired, kycSubmitted } = this.organizationManager.membership;
      return !kycSubmitted || kycRequired;
    }

    let initiator = this.findInitiatorMembership();
    if (initiator) return !initiator.kycSubmitted || initiator.kycRequired;

    return false;
  }

  findInitiatorMembership() {
    // @ts-expect-error
    let { highlightedItem: transaction } = this.args;

    let initiatorMembership = this.store.peekAll('membership').filter(({ id }) => {
      return transaction.initiator?.id === id;
    });

    if (initiatorMembership.length > 0) {
      let [initiator] = initiatorMembership;
      return initiator;
    }
    return null;
  }

  get disclaimerLevel() {
    if (this.isUserInitiator) {
      return this.isUserKycSubmittedAndPending ? 'info' : 'warning';
    }

    return 'info';
  }

  get isUserKycSubmittedAndPending() {
    if (this.isUserInitiator) {
      let { kycPending, kycSubmitted } = this.organizationManager.membership;
      return kycSubmitted && kycPending;
    }

    return false;
  }

  get shouldDisplayPromoCard() {
    // @ts-expect-error
    let { highlightedItem: transaction } = this.args;

    let isTransactionIncome = transaction.isSwiftIncome || transaction.isIncome;

    // @ts-expect-error
    return isTransactionIncome && this.args.hasNoQuoteAndInvoice;
  }

  get isDepositTransaction() {
    // @ts-expect-error
    let { highlightedItem: transaction } = this.args;
    return Boolean(transaction.description?.match(/deposit|depot|acompte|acconto/i));
  }

  @action
  onPromoCardDismiss() {
    this.segment.track('ar-discoverability_banner_dismissed');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transactions::Sidebar::Header': typeof TransactionsSidebarHeaderComponent;
  }
}
