/* import __COLOCATED_TEMPLATE__ from './budget.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { AmountField } from '@repo/design-system-kit';

import { CARD_LEVELS } from 'qonto/constants/cards';
// @ts-expect-error
import { CARDS_BOTTOM_LIMIT } from 'qonto/constants/requests';

interface FlowsRequestsCardsBudgetSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLFormElement;
}

export default class FlowsRequestsCardsBudgetComponent extends Component<FlowsRequestsCardsBudgetSignature> {
  @service declare intl: Services['intl'];
  @service declare segment: Services['segment'];

  // @ts-expect-error
  @tracked errorMessage;

  amountField = AmountField;

  constructor(owner: unknown, args: FlowsRequestsCardsBudgetSignature['Args']) {
    super(owner, args);

    this.segment.track('request_creation_budget_displayed', {
      // @ts-expect-error
      request_type: `${this.args.context.cardLevel} card`,
    });
  }

  get isFlashCard() {
    // @ts-expect-error
    return this.args.context.cardLevel === CARD_LEVELS.FLASH;
  }

  get amount() {
    // @ts-expect-error
    let entity = this.args.context.entity;
    return this.isFlashCard ? entity.paymentLifespanLimit : entity.paymentMonthlyLimit;
  }

  @action
  // @ts-expect-error
  onAmountChange(amountValue) {
    this.errorMessage = this.validateAmount(amountValue);

    if (this.isFlashCard) {
      // @ts-expect-error
      this.args.context.entity.paymentLifespanLimit = amountValue;
    } else {
      // @ts-expect-error
      this.args.context.entity.paymentMonthlyLimit = amountValue;
    }
  }

  @action
  // @ts-expect-error
  continue(event) {
    event.preventDefault();
    this.errorMessage = this.validateAmount(this.amount);

    if (this.errorMessage) {
      return;
    }

    // @ts-expect-error
    this.args.transitionToNext();
  }

  // @ts-expect-error
  validateAmount(amount) {
    // @ts-expect-error
    let { cardMaxLimit } = this.args.context;

    if (!amount || !amount.trim()) {
      return this.intl.t('validations.errors.presence');
    }

    if (amount > cardMaxLimit) {
      let formattedAmount = this.formatTranslationAmount(cardMaxLimit);
      return this.isFlashCard
        ? this.intl.t('requests.creation.spending-limit.error.too-high', {
            amount: formattedAmount,
          })
        : this.intl.t('requests.creation.monthly-limit.error.too-high', {
            amount: formattedAmount,
          });
    }

    if (amount < CARDS_BOTTOM_LIMIT) {
      let formattedAmount = this.formatTranslationAmount(CARDS_BOTTOM_LIMIT);
      return this.isFlashCard
        ? this.intl.t('requests.creation.spending-limit.error.too-low', {
            amount: formattedAmount,
          })
        : this.intl.t('requests.creation.monthly-limit.error.too-low', {
            amount: formattedAmount,
          });
    }

    return null;
  }

  // @ts-expect-error
  formatTranslationAmount(amount) {
    return this.intl.formatNumber(amount, {
      minimumFractionDigits: 0,
      currency: 'EUR',
      style: 'currency',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Requests::Cards::Budget': typeof FlowsRequestsCardsBudgetComponent;
  }
}
