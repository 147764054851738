/* import __COLOCATED_TEMPLATE__ from './deposit.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import {
  AmountField,
  Disclaimer,
  NumberField,
  TextAreaField,
  TextField,
} from '@repo/design-system-kit';
import { isNil } from 'es-toolkit';
import { alias, equal, reads } from 'macro-decorators';

import CURRENCIES from 'qonto/constants/currencies';
import { STATUS } from 'qonto/constants/receivable-invoice';
// @ts-expect-error
import { round } from 'qonto/utils/receivable-invoicing';

// @ts-expect-error
import styles from './deposit.module.css';

const MAX_TITLE_LENGTH = 120;
const MAX_DESCRIPTION_LENGTH = 600;
const DEPOSIT_PRICE_LIMIT = 1000000;

const PERCENTAGE_SYMBOL = '%';
const DEPOSIT_PERCENTAGE = 'percentage';
const DEPOSIT_ABSOLUTE = 'absolute';

const VAT_RATE_PRECISION = 0.01;

interface ReceivableInvoicesFormDepositSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class ReceivableInvoicesFormDepositComponent extends Component<ReceivableInvoicesFormDepositSignature> {
  amountField = AmountField;
  numberField = NumberField;
  textField = TextField;
  textAreaField = TextAreaField;
  disclaimer = Disclaimer.Inline;

  @service declare intl: Services['intl'];
  @service declare organizationManager: Services['organizationManager'];

  // @ts-expect-error
  @reads('args.document.currency', CURRENCIES.default) currency;
  // @ts-expect-error
  @reads('args.document.quote') quote;
  // @ts-expect-error
  @alias('args.document.items.0') deposit;
  // @ts-expect-error
  @equal('args.document.status', STATUS.DRAFT) isDraft;

  @tracked depositAmount;
  @tracked selectedDepositUnit = this.unitOptions[0];

  styles = styles;
  maxTitleLength = MAX_TITLE_LENGTH;
  maxDescriptionLength = MAX_DESCRIPTION_LENGTH;
  depositPriceLimit = DEPOSIT_PRICE_LIMIT;
  formatOptions = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };

  constructor(owner: unknown, args: ReceivableInvoicesFormDepositSignature['Args']) {
    super(owner, args);
    this.deposit.quantity = 1;

    if (this.isDraft) {
      // @ts-expect-error
      let isDepositDraft = !this.args.document.changedAttributes().isDeposit;

      if (!isDepositDraft) {
        this.deposit.title = this.intl.t(
          'receivable-invoices.invoice-creation.deposit.title.invoice-prefill'
        );
        this.deposit.description = null;
        this.deposit.unitPrice = null;
        this.deposit.unit = null;
      }

      this.depositAmount = round(this.deposit.get('unitPrice'), 100);
      this.selectedDepositUnit = this.unitOptions[1];
    } else if (this.fromQuote) {
      this.deposit.title = this.intl.t(
        'receivable-invoices.invoice-creation.deposit.title.prefill',
        { quoteNumber: this.quote.get('number') }
      );
      this.deposit.description = null;
      this.depositAmount = 30;
      this.updateDepositAmount();
    } else {
      this.deposit.title = this.intl.t(
        'receivable-invoices.invoice-creation.deposit.title.invoice-prefill'
      );
      this.deposit.description = null;
      this.deposit.unitPrice = null;
    }
  }

  get fromQuote() {
    return Boolean(this.quote?.get('id'));
  }

  get quoteDiscountedTotalAmountExcludingVat() {
    return this.quote.get('preciseDiscountedTotalExcludingVat') ?? 0;
  }

  get depositUnitPrice() {
    return this.deposit?.get('unitPrice') ?? 0;
  }

  get unitOptions() {
    return [
      { value: DEPOSIT_PERCENTAGE, label: PERCENTAGE_SYMBOL },
      { value: DEPOSIT_ABSOLUTE, label: this.currency },
    ];
  }

  get vatRateErrorMessage() {
    let error = this.deposit.get('errors.vatRate.0');

    switch (error?.message) {
      case 'required':
        return this.intl.t('receivable-invoices.invoice-creation.errors.vat.required-field');
      case 'invalid':
        return this.intl.t('receivable-invoices.invoice-creation.errors.vat-over-100');
      default:
        return null;
    }
  }

  convertToPercentage() {
    if (!isNil(this.depositAmount)) {
      this.depositAmount = round(
        (this.depositAmount / this.quoteDiscountedTotalAmountExcludingVat) * 100,
        100
      );
    }
  }

  convertToFixedAmount() {
    if (!isNil(this.depositAmount)) {
      this.depositAmount = round(
        (this.quoteDiscountedTotalAmountExcludingVat * this.depositAmount) / 100,
        100
      );
    }
  }

  convertDepositAmount() {
    // @ts-expect-error
    if (this.selectedDepositUnit.value === DEPOSIT_PERCENTAGE) {
      this.convertToPercentage();
    } else {
      this.convertToFixedAmount();
    }
  }

  updateDepositAmount() {
    if (this.fromQuote && this.selectedDepositUnit?.value === DEPOSIT_PERCENTAGE) {
      this.deposit.unitPrice = round(
        (this.quoteDiscountedTotalAmountExcludingVat * this.depositAmount) / 100,
        100
      );
    } else {
      this.deposit.unitPrice = this.depositAmount;
    }
  }

  @tracked selectedVatOption = this.defaultVatOption;
  // @ts-expect-error
  @tracked vatRatePercentage = String((this.deposit?.get('vatRate') ?? 0) * 100).replace(
    /[,.]/g,
    // @ts-expect-error
    this.decimalSeparator
  );

  get defaultVatOption() {
    if (this.deposit?.get('vatRate') === undefined) return undefined;
    let vatCategory = this.organizationManager.organization.vatRatesCategories.find(
      // @ts-expect-error
      vatRateCategory =>
        Math.abs(vatRateCategory - parseFloat(this.deposit.get('vatRate')) * 100) <
        VAT_RATE_PRECISION
    );

    if (vatCategory !== undefined)
      return {
        clearable: false,
        value: vatCategory,
      };
    return { clearable: true, value: -1 };
  }

  get shouldDisplayMaxDepositAmountDisclaimer() {
    return this.depositUnitPrice > this.quoteDiscountedTotalAmountExcludingVat;
  }

  @action
  // @ts-expect-error
  handleTitleChange(title) {
    this.deposit.title = title;
  }

  @action
  // @ts-expect-error
  handleDescriptionChange(description) {
    this.deposit.description = description;
  }

  @action
  // @ts-expect-error
  handleDepositUnitSelection(option) {
    // @ts-expect-error
    if (option.value !== this.selectedDepositUnit.value) {
      let newOption = this.unitOptions.find(unitOption => unitOption.label === option.label);
      if (newOption) {
        this.selectedDepositUnit = newOption;
        if (this.fromQuote) {
          this.convertDepositAmount();
        }
      }
    }
  }

  @action
  // @ts-expect-error
  handleDepositAmountChange(amount) {
    this.depositAmount = amount;
    this.updateDepositAmount();
  }

  // @ts-expect-error
  @action updateVatOption(option) {
    this.selectedVatOption = option;
    if (option.value >= 0) {
      this.setDepositVatRate(option.value / 100);
    } else {
      // @ts-expect-error
      this.vatRatePercentage = null;
      this.setDepositVatRate(null);
    }
  }

  // @ts-expect-error
  @action handleVatRateInput(newRate) {
    this.vatRatePercentage = newRate;
    this.setDepositVatRate(newRate.replace(/[,.]/g, '.') / 100);
  }

  // @ts-expect-error
  @action handleVatRateChange(newRate) {
    // @ts-expect-error
    this.vatRatePercentage = newRate.replace(/[,.]/g, this.decimalSeparator);
    this.setDepositVatRate(newRate.replace(/[,.]/g, '.') / 100);
  }

  // @ts-expect-error
  setDepositVatRate(value) {
    this.deposit.vatRate = value === null ? null : String(value);
    if (value !== 0) this.deposit.vatExemptionCode = null;
    if (value === 0 && this.organizationManager.organization?.get('legalCountry') === 'IT')
      this.deposit.vatExemptionCode = 'N2.2';
    else this.deposit.vatExemptionCode = undefined;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ReceivableInvoices::Form::Deposit': typeof ReceivableInvoicesFormDepositComponent;
  }
}
