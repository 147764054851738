export default {
  "row": "cHM",
  "cell": "cHP body-1",
  "row-cancelled": "cHl",
  "cell-amount": "cHX",
  "empty": "cHC",
  "align-right": "cHk",
  "debitor-container": "cHJ",
  "debitor-info": "cHs",
  "debitor-name": "cHF body-1",
  "with-sidebar": "cHg",
  "method-col": "cHD",
  "resource-number": "cHV caption",
  "avatar": "cHL"
};
