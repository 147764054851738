/* import __COLOCATED_TEMPLATE__ from './schedule-transfer-selector.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { parseDate } from '@internationalized/date';
import { DatePicker, Disclaimer } from '@repo/design-system-kit';
import dayjs from 'dayjs';

// @ts-expect-error
import { DATE_PICKER_FIELD_FORMAT } from 'qonto/constants/dates';
// @ts-expect-error
import { SCHEDULE_OPERATION_TYPES } from 'qonto/constants/standing-orders';
import { OPERATION_TYPES } from 'qonto/constants/transfers';
// @ts-expect-error
import { getCurrentParisDateString } from 'qonto/utils/date';

interface TransfersSepaTransferFormScheduleTransferSelectorSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class TransfersSepaTransferFormScheduleTransferSelectorComponent extends Component<TransfersSepaTransferFormScheduleTransferSelectorSignature> {
  disclaimerInline = Disclaimer.Inline;
  datePicker = DatePicker;

  @service declare intl: Services['intl'];
  @service declare segment: Services['segment'];
  @service declare featuresManager: Services['featuresManager'];

  @tracked scheduled = this._getTransferWasScheduled();

  editOperationTypes = [
    SCHEDULE_OPERATION_TYPES.WEEKLY,
    SCHEDULE_OPERATION_TYPES.MONTHLY,
    SCHEDULE_OPERATION_TYPES.QUARTERLY,
    SCHEDULE_OPERATION_TYPES.HALF_YEARLY,
    SCHEDULE_OPERATION_TYPES.YEARLY,
  ];

  get operationTypes() {
    // @ts-expect-error
    return this.args.operationTypes || [];
  }

  operationTypeTranslations = {
    // @ts-expect-error
    [SCHEDULE_OPERATION_TYPES.SCHEDULED]: this.intl.t('transfers.frequency.scheduled'),
    // @ts-expect-error
    scheduled_later: this.intl.t('transfers.frequency.scheduled_later'),
    // @ts-expect-error
    [SCHEDULE_OPERATION_TYPES.WEEKLY]: this.intl.t('transfers.frequency.standing_weekly'),
    // @ts-expect-error
    standing_weekly_short: this.intl.t('transfers.frequency.standing_weekly_short'),
    // @ts-expect-error
    [SCHEDULE_OPERATION_TYPES.MONTHLY]: this.intl.t('transfers.frequency.standing_monthly'),
    // @ts-expect-error
    standing_monthly_gender: this.intl.t('transfers.frequency.standing_monthly_gender'),
    // @ts-expect-error
    [SCHEDULE_OPERATION_TYPES.QUARTERLY]: this.intl.t('transfers.frequency.standing_quarterly'),
    // @ts-expect-error
    [SCHEDULE_OPERATION_TYPES.HALF_YEARLY]: this.intl.t('transfers.frequency.standing_half_yearly'),
    // @ts-expect-error
    [SCHEDULE_OPERATION_TYPES.YEARLY]: this.intl.t('transfers.frequency.standing_yearly'),
    // @ts-expect-error
    [OPERATION_TYPES.PAY_LATER]: this.intl.t('transfers.frequency.scheduled'),
  };

  get scheduledDate() {
    // @ts-expect-error
    return this.args.transfer?.scheduledDate
      ? // @ts-expect-error
        parseDate(this.args.transfer.scheduledDate.toString())
      : null;
  }

  get standingEndingDate() {
    // @ts-expect-error
    return this.args.transfer?.standingEndingDate
      ? // @ts-expect-error
        parseDate(this.args.transfer.standingEndingDate.toString())
      : null;
  }

  get optionArray() {
    // @ts-expect-error
    return this.args.transfer.id ? this.editOperationTypes : this.operationTypes;
  }

  get minDate() {
    let minDate = dayjs().add(1, 'day').startOf('day').format(DATE_PICKER_FIELD_FORMAT).toString();
    return parseDate(minDate);
  }

  get endMinDate() {
    // @ts-expect-error
    let scheduledDate = this.args.transfer.scheduledDate;

    if (scheduledDate) {
      return parseDate(
        dayjs(scheduledDate).add(1, 'day').format(DATE_PICKER_FIELD_FORMAT).toString()
      );
    }

    return this.minDate;
  }

  _getTransferWasScheduled() {
    let {
      // @ts-expect-error
      transfer: { scheduledDate, isPayLater, isScheduled },
    } = this.args;

    if (isPayLater) {
      return false;
    }

    // @ts-expect-error
    return !(isScheduled && dayjs(scheduledDate).isToday());
  }

  get disclaimer() {
    let {
      isStandingWeekly,
      isStandingMonthly,
      isStandingQuarterly,
      isStandingHalfYearly,
      isStanding,
      standingEndingDate,
      scheduledDate,
      occurrencesCount,
      // @ts-expect-error
    } = this.args.transfer;

    if (!isStanding || !scheduledDate) {
      return null;
    }

    let disclaimerOptions = {
      startDate: dayjs(scheduledDate).toDate(),
      endDate: dayjs(standingEndingDate).toDate(),
      transferOccurences: occurrencesCount,
    };

    if (isStandingWeekly) {
      return standingEndingDate
        ? this.intl.t('disclaimer.transfer_weekly_frequency', disclaimerOptions)
        : this.intl.t('disclaimer.transfer_weekly_frequency_unlimited', disclaimerOptions);
    } else if (isStandingMonthly) {
      return standingEndingDate
        ? this.intl.t('disclaimer.transfer_monthly_frequency', disclaimerOptions)
        : this.intl.t('disclaimer.transfer_monthly_frequency_unlimited', disclaimerOptions);
    } else if (isStandingQuarterly) {
      return standingEndingDate
        ? this.intl.t('disclaimer.transfer_quarterly_frequency', disclaimerOptions)
        : this.intl.t('disclaimer.transfer_quarterly_frequency_unlimited', disclaimerOptions);
    } else if (isStandingHalfYearly) {
      return standingEndingDate
        ? this.intl.t('disclaimer.transfer_half_yearly_frequency', disclaimerOptions)
        : this.intl.t('disclaimer.transfer_half_yearly_frequency_unlimited', disclaimerOptions);
    } else {
      return standingEndingDate
        ? this.intl.t('disclaimer.transfer_yearly_frequency', disclaimerOptions)
        : this.intl.t('disclaimer.transfer_yearly_frequency_unlimited', disclaimerOptions);
    }
  }

  // @ts-expect-error
  @action handleOperationType(operationType) {
    // @ts-expect-error
    let { transfer } = this.args;

    transfer.changeOperationType(operationType);
    transfer.standingOn = null;
  }

  // @ts-expect-error
  @action setScheduledDate(date) {
    // @ts-expect-error
    let { transfer } = this.args;
    transfer.scheduledDate = date?.toString();

    // @ts-expect-error
    if (dayjs(transfer.standingEndingDate).isBefore(this.endMinDate)) {
      transfer.standingEndingDate = this.endMinDate?.toString();
    }
  }

  // @ts-expect-error
  @action setStandingDate(date) {
    // @ts-expect-error
    this.args.transfer.standingEndingDate = date?.toString();
  }

  // @ts-expect-error
  @action handleToggleScheduled(transfer) {
    let today = getCurrentParisDateString();

    transfer.scheduledDate = today;
    this._toggleScheduledState();

    if (this.scheduled) {
      this.segment.track('transfer_schedule_checkbox');
    }
  }

  _toggleScheduledState() {
    // @ts-expect-error
    let { transfer } = this.args;

    this.scheduled = !this.scheduled;

    if (transfer.isNew && !transfer.isScheduled) {
      transfer.changeOperationType('scheduled');
    }

    if (this.scheduled) {
      let scheduledDate = transfer.scheduledDate;

      // @ts-expect-error
      if (dayjs(scheduledDate).isBefore(this.minDate)) {
        transfer.scheduledDate = this.minDate?.toString();
      }
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transfers::Sepa::TransferForm::ScheduleTransferSelector': typeof TransfersSepaTransferFormScheduleTransferSelectorComponent;
  }
}
