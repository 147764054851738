export default {
  "page-wrapper": "qoc",
  "container": "qoq",
  "spinner-container": "qoZ",
  "left-panel": "qoR",
  "form": "qoQ",
  "left-content": "qoe",
  "form-footer": "qoB",
  "delete-button": "qoE",
  "header": "qod",
  "preview-container": "qoI",
  "email-preview": "qot",
  "close-button": "qoM btn btn--icon-only btn--tertiary btn--large"
};
