/* import __COLOCATED_TEMPLATE__ from './create.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Spinner } from '@repo/design-system-kit';
import { all, dropTask, race, rawTimeout, task } from 'ember-concurrency';

// @ts-expect-error
import { ACCOUNT_TYPE, WEB_SOCKET_TIMEOUT } from 'qonto/constants/bank-account';

const TIMEOUT_EVENT = 'timeout';
const BANK_ACCOUNT_SYNCED_EVENT = 'bank_account.synced';

interface FlowsAccountsRemuneratedCreateSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class FlowsAccountsRemuneratedCreate extends Component<FlowsAccountsRemuneratedCreateSignature> {
  spinner = Spinner;

  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare intl: Services['intl'];
  @service declare notifierManager: Services['notifierManager'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare segment: Services['segment'];

  @tracked error = null;
  @tracked isLoading = false;
  @tracked value = null;
  @tracked promiseResolve = null;

  constructor(owner: unknown, args: FlowsAccountsRemuneratedCreateSignature['Args']) {
    super(owner, args);

    // @ts-expect-error
    this.waitForBankAccountSyncedEventPromise = new Promise(resolve => {
      // @ts-expect-error
      this.promiseResolve = resolve;
    });

    // @ts-expect-error
    this.notifierManager.on(BANK_ACCOUNT_SYNCED_EVENT, this, '_handleBankAccountSync');
  }

  willDestroy() {
    // @ts-expect-error
    this.notifierManager.off(BANK_ACCOUNT_SYNCED_EVENT, this, '_handleBankAccountSync');

    super.willDestroy();
  }

  createTask = dropTask(async () => {
    // @ts-expect-error
    this.error = this.validateAccountNamePresence();

    // @ts-expect-error
    let { account } = this.args.context;
    let event;

    if (!this.error) {
      try {
        this.isLoading = true;
        account.addIdempotencyHeader();
        account.name = this.value;
        let saveAccountPromise = account.save();

        let waitForTimeoutEventTask = this.waitForTimeoutTask.perform();

        let syncEventPromise = race([
          // @ts-expect-error
          this.waitForBankAccountSyncedEventPromise,
          waitForTimeoutEventTask,
        ]);

        let [, syncEvent] = await all([saveAccountPromise, syncEventPromise]);

        ({ event } = syncEvent ?? {});
      } catch (error) {
        // @ts-expect-error
        let isForbiddenError = error.errors?.some(err => err.status === '403');
        let message = isForbiddenError
          ? this.intl.t('toasts.errors.forbidden_error')
          : this.intl.t('toasts.errors.server_error');
        this.toastFlashMessages.toastError(message);
        return;
      } finally {
        account.removeIdempotencyHeader();
      }
      await this.handleNext(event);
    }
  });

  // @ts-expect-error
  _handleBankAccountSync(payload) {
    if (payload) {
      let { object_type, object } = payload;
      if (object_type === 'bank_account' && object && object.id && object.account_type) {
        // @ts-expect-error
        this.promiseResolve({ event: BANK_ACCOUNT_SYNCED_EVENT });
      }
    }
  }

  waitForTimeoutTask = task(async () => {
    await rawTimeout(WEB_SOCKET_TIMEOUT);
    return { event: TIMEOUT_EVENT };
  });

  // @ts-expect-error
  async handleNext(event) {
    if (event === BANK_ACCOUNT_SYNCED_EVENT) {
      this.segment.track('accounts_new_account_created', {
        account_type: ACCOUNT_TYPE.REMUNERATED,
      });
      await this.organizationManager.updateBankAccounts();
      // @ts-expect-error
      return this.args.transitionToNext();
    }
    this.isLoading = false;
    this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
  }

  @action
  // @ts-expect-error
  updateField(value) {
    this.value = value?.trim();
    // @ts-expect-error
    this.error = this.validateAccountNamePresence();
  }

  validateAccountNamePresence() {
    // @ts-expect-error
    return !this.value?.trim() ? this.intl.t('validations.errors.presence') : null;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Accounts::Remunerated::Creation::Create': typeof FlowsAccountsRemuneratedCreate;
  }
}
