export default {
  "label-wrapper": "yp body-1",
  "avatar": "yx",
  "container": "yw",
  "transaction-info": "yN",
  "secondary": "yv",
  "amount-container": "yo",
  "amount": "yn",
  "disabled": "yi",
  "checkbox": "yA",
  "disclaimer": "yY"
};
