/* import __COLOCATED_TEMPLATE__ from './card-features.hbs'; */
import Component from '@glimmer/component';

import { CARD_LEVELS } from 'qonto/constants/cards';

interface CardComparisonModalCardFeaturesSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class CardComparisonModalCardFeatures extends Component<CardComparisonModalCardFeaturesSignature> {
  get isMetalCard() {
    // @ts-expect-error
    return this.args.cardLevel === CARD_LEVELS.METAL;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Card::Modals::Comparison::CardFeatures': typeof CardComparisonModalCardFeatures;
  }
}
