/* import __COLOCATED_TEMPLATE__ from './options.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface OptionsSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const Options = templateOnlyComponent<OptionsSignature>();

export default Options;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'CardSidebar::Options': typeof Options;
  }
}
