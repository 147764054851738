export default {
  "container": "cCN",
  "mapped": "cCv",
  "header": "cCo",
  "title": "cCn caption",
  "select-option-label": "cCi",
  "content": "cCA",
  "item": "cCY body-2",
  "empty": "cCh"
};
