/* import __COLOCATED_TEMPLATE__ from './intro.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { importAsset } from 'qonto/helpers/import-asset';

const VIDEO_PATHS = {
  de: '/videos/budgets/value-prop-de.webm',
  en: '/videos/budgets/value-prop-en.webm',
  es: '/videos/budgets/value-prop-es.webm',
  fr: '/videos/budgets/value-prop-fr.webm',
  it: '/videos/budgets/value-prop-it.webm',
};

const IMAGE_PATHS = {
  de: '/illustrations/budgets/value-prop-de.webp',
  en: '/illustrations/budgets/value-prop-en.webp',
  es: '/illustrations/budgets/value-prop-es.webp',
  fr: '/illustrations/budgets/value-prop-fr.webp',
  it: '/illustrations/budgets/value-prop-it.webp',
};

interface FlowsCreateBudgetIntroSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsCreateBudgetIntroComponent extends Component<FlowsCreateBudgetIntroSignature> {
  @service declare localeManager: Services['localeManager'];
  @service declare segment: Services['segment'];

  get videoPath() {
    // @ts-expect-error
    return importAsset([VIDEO_PATHS[this.localeManager.locale]]);
  }

  get imagePath() {
    // @ts-expect-error
    return importAsset([IMAGE_PATHS[this.localeManager.locale]]);
  }

  @action next() {
    this.segment.track('budget-creation_value-proposition-button_clicked');
    // @ts-expect-error
    this.args.transitionToNext();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Budgets::CreateBudget::Intro': typeof FlowsCreateBudgetIntroComponent;
  }
}
