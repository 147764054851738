/* import __COLOCATED_TEMPLATE__ from './export-form.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Checkbox, Disclaimer } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';

// @ts-expect-error
import { FILE_GROUPS, FILE_TYPES } from 'qonto/constants/transactions-export';

interface TransactionsExportFormSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLFormElement;
}

export default class TransactionsExportForm extends Component<TransactionsExportFormSignature> {
  checkbox = Checkbox;

  disclaimerInline = Disclaimer.Inline;

  @service declare intl: Services['intl'];

  @tracked fileType = null;

  fileTypeTranslations = {
    // @ts-expect-error
    csv: this.intl.t('transactions.modals.export.file_formats.csv'),
    // @ts-expect-error
    excel: this.intl.t('transactions.modals.export.file_formats.excel'),
  };

  // @ts-expect-error
  selectedCSVSeparator = this.args.selectedCSVSeparator;
  showFormatSettings = false;
  withAttachments = false;

  constructor(owner: unknown, args: TransactionsExportFormSignature['Args']) {
    super(owner, args);

    // @ts-expect-error
    this.fileTypes = FILE_GROUPS;
    // @ts-expect-error
    this.fileType = this.fileTypes[0];
  }

  get showCsvBasicDescription() {
    return this.selectedFileType.code === 'csv';
  }

  get fileTypeName() {
    // @ts-expect-error
    return this.fileType.name.split('.').pop();
  }

  get selectedFileType() {
    // @ts-expect-error
    return this.fileTypes.find(({ code }) => code === this.fileType.code);
  }

  get isCSVOptionSelected() {
    return this.selectedFileType.group === FILE_TYPES.CSV;
  }

  confirmTask = dropTask(async () => {
    let options = {
      withAttachments: this.withAttachments,
      fileType: this.selectedFileType.code,
      separator: this.selectedCSVSeparator,
    };

    // @ts-expect-error
    await this.args.confirm(options);
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transactions::ExportForm': typeof TransactionsExportForm;
  }
}
