/* import __COLOCATED_TEMPLATE__ from './beneficiary.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';

import { State } from 'qonto/react/components/transfers/international-out/state';
import { ignoreCancelation } from 'qonto/utils/ignore-error';
import maybeT from 'qonto/utils/maybe-t';

export const DEFAULT_LOADING_OPTIONS = {
  count: 10,
  widths: {
    value: { md: 70 },
    label: { sm: 110, md: 170 },
  },
};

interface Signature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class TransfersInternationalOutModalsBeneficiaryComponent extends Component<Signature> {
  state = State;

  @service declare internationalOutManager: Services['internationalOutManager'];
  @service declare intl: Services['intl'];
  @service declare localeManager: Services['localeManager'];

  @tracked beneficiaryDetails = [];
  @tracked isError = false;

  placeholders = Array.from({ length: DEFAULT_LOADING_OPTIONS.count }, (_, index) => {
    let { label, value } = DEFAULT_LOADING_OPTIONS.widths;
    return {
      labelWidthInPx: index % 2 === 1 ? label.sm : label.md,
      valueWidthInPx: value.md,
    };
  });

  constructor(owner: unknown, args: Signature['Args']) {
    super(owner, args);
    this.loadBeneficiaryDetailsTask.perform().catch(ignoreCancelation);
  }

  loadBeneficiaryDetailsTask = dropTask(async () => {
    this.isError = false;

    try {
      // @ts-expect-error
      this.beneficiaryDetails = (
        await this.internationalOutManager
          // @ts-expect-error
          .getBeneficiaryDetails(this.args.data.beneficiary.id)
      ).map(this.#serializeBeneficiaryDetail);
    } catch {
      this.isError = true;
    }
  });

  // @ts-expect-error
  #serializeBeneficiaryDetail = ({ key, title, value }) => {
    return {
      label: maybeT(
        {
          key: `international-out.dynamic-form.label.${key}`,
          fallback: title,
        },
        this.intl,
        // @ts-expect-error
        this.localeManager.locale
      ),
      value,
    };
  };
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transfers::InternationalOut::Modals::Beneficiary': typeof TransfersInternationalOutModalsBeneficiaryComponent;
  }
}
