/* import __COLOCATED_TEMPLATE__ from './cancel.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { task } from 'ember-concurrency';

// @ts-expect-error
import { STATUS } from 'qonto/constants/requests';
import { INVOICE_STATUSES } from 'qonto/constants/supplier-invoice';

interface RequestTransferModalsCancelSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class RequestTransferModalsCancelComponent extends Component<RequestTransferModalsCancelSignature> {
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare intl: Services['intl'];
  @service declare router: Services['router'];
  @service declare errors: Services['errors'];

  get showSupplierInvoicesModal() {
    // @ts-expect-error
    let { invoice, request } = this.args.data || {};
    return invoice?.status === INVOICE_STATUSES.pending && request?.status === STATUS.PENDING;
  }

  get description() {
    if (!this.showSupplierInvoicesModal) {
      return this.intl.t('requests.modals.cancel-modal.description');
    }

    return this.intl.t('requests.modals.cancel-modal.supplier-invoices.description');
  }

  confirmTask = task({ drop: true }, async () => {
    // @ts-expect-error
    let { request, isMultiTransfer, invoice } = this.args.data;

    try {
      await request.cancelRequest();

      if (!invoice) {
        let message = this.intl.t('transfers.toasts.info.cancel-request');
        // @ts-expect-error
        this.toastFlashMessages.toastInfo(message, 'transfer_create');
      }

      if (isMultiTransfer) {
        this.router.transitionTo('requests.pending');
      }

      // @ts-expect-error
      this.args.close('success');
    } catch (error) {
      // @ts-expect-error
      if (error.errors?.[0]?.detail?.code === 'request_not_pending') {
        // @ts-expect-error
        this.args.close();

        this.toastFlashMessages.toastError(
          this.intl.t('supplier-invoices.preview.request.info-toast.same-invoice')
        );

        // Now that we know that request status is outdated, we refresh it for the request and the invoice
        try {
          // @ts-expect-error
          await Promise.all([this.args.data.request.reload(), this.args.data.invoice.reload()]);
        } catch (error) {
          this.errors.handleError(error);
        }
      } else {
        this.errors.handleError(error);
      }
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Request::Transfer::Modals::Cancel': typeof RequestTransferModalsCancelComponent;
  }
}
