/* import __COLOCATED_TEMPLATE__ from './attachment.hbs'; */
import { action, set, setProperties } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { Button } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';
import window from 'ember-window-mock';
import { TrackedArray } from 'tracked-built-ins';

import { EVENTS } from 'qonto/constants/international-out/tracking';

export const UPLOAD_OPTIONS = {
  model: 'attachment',
  url: 'v3/attachments',
};

export const UPLOADER_OPTIONS = {
  allowedExtensions: '.png,.pdf,.jpeg,.jpg',
  filesLimit: 5,
  maxSize: 15 * 1e6,
};

interface Signature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsTransfersInternationalOutNewAttachmentComponent extends Component<Signature> {
  button = Button;

  @service declare intl: Services['intl'];
  @service declare modals: Services['modals'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare segment: Services['segment'];

  errors = new TrackedArray();
  uploaderOptions = UPLOADER_OPTIONS;

  get files() {
    // @ts-expect-error
    return this.args.context.attachments.map(attachment => attachment.file).filter(Boolean);
  }

  get uploadOptions() {
    let model = UPLOAD_OPTIONS.model;
    return {
      ...UPLOAD_OPTIONS,
      payload: {
        fileKey: `${model}[file]`,
        data: {
          [`${model}[organization_id]`]: this.organizationManager.organization.id,
          // @ts-expect-error
          [`${model}[bank_account_id]`]: this.args.context.bankAccount.id,
        },
      },
    };
  }

  @action
  // @ts-expect-error
  addFile(file, attachment) {
    this.segment.track(EVENTS.ATTACHMENT_UPLOADED);
    this.#normalizeFile(file);
    attachment.file = file;
    // @ts-expect-error
    this.args.context.attachments = [...this.args.context.attachments, attachment];
  }

  @action
  // @ts-expect-error
  deleteFile(file) {
    this.#removeAttachment(this.#findAttachmentByFile(file));
  }

  @action
  // @ts-expect-error
  downloadFile(file) {
    window.open(this.#findAttachmentByFile(file).downloadUrl, '_blank');
  }

  @action
  // @ts-expect-error
  previewFile(file) {
    this.modals.open('transfers/international-out/modals/file-preview', {
      file,
      isFullScreenModal: true,
      onDownload: this.downloadFile,
      onRemove: this.deleteFile,
    });
  }

  onSubmitTask = dropTask(async confirmTask => {
    if (!this.files.length) {
      this.errors.push({ message: this.intl.t('international-out.attachment.error.required') });
      return;
    }

    if (await confirmTask.perform()) {
      this.segment.track(EVENTS.ATTACHMENT_SUBMITTED);
      // @ts-expect-error
      this.args.transitionToNext();
    }
  });

  // @ts-expect-error
  #findAttachmentByFile(file) {
    // @ts-expect-error
    return this.args.context.attachments.find(attachment =>
      file.id ? attachment.file.id === file.id : file.fileUrl.includes(attachment.id)
    );
  }

  // @ts-expect-error
  #normalizeFile(file) {
    let { name, size, type, url } = file;

    setProperties(file, {
      fileContentType: type,
      filename: name,
      fileName: name,
      filesizeBytes: size,
      fileSize: size,
      fileUrl: url,
    });

    // @ts-expect-error
    return file.readAsDataURL().then(data => {
      set(file, 'fileUrl', data);
    });
  }

  // @ts-expect-error
  #removeAttachment(attachment) {
    // @ts-expect-error
    this.args.context.attachments = [
      // @ts-expect-error
      ...this.args.context.attachments.slice(0, this.args.context.attachments.indexOf(attachment)),
      // @ts-expect-error
      ...this.args.context.attachments.slice(this.args.context.attachments.indexOf(attachment) + 1),
    ];
    attachment.unloadRecord();
    return attachment;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Transfers::InternationalOut::New::Attachment': typeof FlowsTransfersInternationalOutNewAttachmentComponent;
  }
}
