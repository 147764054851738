export default {
  "modal": "qPH",
  "action-container": "qPO",
  "similar-label": "qPm",
  "info-icon": "qPp",
  "container": "qPx",
  "subtitle": "qPw caption-bold",
  "error": "qPN",
  "item-container": "qPv"
};
