// @ts-nocheck
import Helper from '@ember/component/helper';
import { service } from '@ember/service';

import { CENTS_MULTIPLIER } from 'qonto/constants/transfers';

interface FormatMoneySignature {
  Args: {
    Positional: [number];
    // Named: {} -- Todo
  };
  Return: string;
}

interface FormatMoneyOptions {
  currency: string;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
  displayUndefined?: boolean;
  intlFormatMoney: (value: number, options: FormatMoneyOptions) => string;
}

export function formatMoney(value: number, options: FormatMoneyOptions): string {
  const formatOptions = {
    minimumFractionDigits: 2,
    ...options,
  };
  const {
    minimumFractionDigits,
    maximumFractionDigits,
    displayUndefined,
    currency,
    intlFormatMoney,
  } = formatOptions;
  const centMultiplier = CENTS_MULTIPLIER[currency];

  if (centMultiplier === 1) {
    formatOptions.minimumFractionDigits = options.minimumFractionDigits ?? 0;
    formatOptions.maximumFractionDigits =
      options.maximumFractionDigits ?? formatOptions.minimumFractionDigits;
  }

  const shouldSkipFormatting = !Number.isFinite(value) && displayUndefined;
  return shouldSkipFormatting ? '--' : intlFormatMoney(value, formatOptions);
}

export default class FormatMoneyHelper extends Helper<FormatMoneySignature> {
  @service intl;

  init() {
    super.init(...arguments);
    this.intl.onLocaleChanged(this.recompute, this);
  }

  compute(params, options) {
    return formatMoney(params[0], {
      ...options,
      intlFormatMoney: this.intl.formatMoney.bind(this.intl),
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'format/money': typeof FormatMoneyHelper;
  }
}
