export default {
  "wrapper": "qJK",
  "section-wrapper": "qJG",
  "header": "qJr",
  "title": "qJb body-1",
  "close-icon": "qJy",
  "section": "qJU",
  "section-title": "qJj caption-bold",
  "labels": "qJW",
  "labels-title": "qJu",
  "section-checkbox": "qsS",
  "grow": "qsc scroll",
  "label": "qsq",
  "buttons": "qsZ"
};
