/* import __COLOCATED_TEMPLATE__ from './beneficiaries-list.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { SearchFieldWithDebounce } from '@repo/design-system-kit';
import { task } from 'ember-concurrency';

interface RequestTransferBeneficiarySidebarBeneficiariesListSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class RequestTransferBeneficiarySidebarBeneficiariesListComponent extends Component<RequestTransferBeneficiarySidebarBeneficiariesListSignature> {
  @service declare segment: Services['segment'];
  @service declare intl: Services['intl'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare modals: Services['modals'];

  searchField = SearchFieldWithDebounce;

  @action
  handleClick() {
    this.segment.track('transfer_rolodex_create_beneficiary_click');
    // @ts-expect-error
    this.args.addOrEditBeneficiary(null);
  }

  @action
  // @ts-expect-error
  confirmBeneficiaryDelete(beneficiary) {
    this.segment.track('transfer_rolodex_delete_beneficiary_click');
    this.modals.open('popup/destructive', {
      title: this.intl.t('transfers.modals.delete-beneficiary.title'),
      description: this.intl.t('transfers.modals.delete-beneficiary.desc'),
      cancel: this.intl.t('btn.cancel'),
      confirm: this.intl.t('btn.delete'),
      confirmTask: this.deleteBeneficiaryTask,
      beneficiary,
    });
  }

  deleteBeneficiaryTask = task(async (close, { beneficiary }) => {
    try {
      this.segment.track('transfer_rolodex_delete_beneficiary_confirmed');

      beneficiary.deleteRecord();
      await beneficiary.save();

      close();
      this.toastFlashMessages.toastInfo(
        this.intl.t('transfers.beneficiaries.delete.success-message')
      );
      // @ts-expect-error
      this.args.onBeneficiaryDeleted?.();
    } catch {
      this.toastFlashMessages.toastError(this.intl.t('errors.internal_server_error'));
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Request::Transfer::BeneficiarySidebar::BeneficiariesList': typeof RequestTransferBeneficiarySidebarBeneficiariesListComponent;
  }
}
