export default {
  "vat-lines": "qpY",
  "vat-line": "qph",
  "vat-input": "qpf",
  "vat-rate": "qpK",
  "vat-option-button": "qpG",
  "disabled": "qpr",
  "vat-option-tooltip": "qpb",
  "remove-label": "qpy",
  "remove-button": "qpU",
  "vat-total": "qpj",
  "vat-total-amount": "qpW",
  "add-vat-line": "qpu",
  "with-vat-lines": "qxS"
};
