import { type ReactNode } from 'react';
import { FormattedNumber } from 'react-intl';
import { useFlags } from '@qonto/react-migration-toolkit/react/hooks';
import type { BalanceData } from '../../models/balance';
import styles from './styles.strict-module.css';

interface BalanceCellProps {
  balance: BalanceData;
}

export function BalanceCell({ balance }: BalanceCellProps): ReactNode {
  const { amount, forecastAmount, isPastMonth, isFutureMonth, isCurrentMonth, type } = balance;

  const flags = useFlags();

  /*feature--boolean-cash-flow-forecast-slice-1*/
  const isForecastSlice1Enabled = flags['featureBooleanCashFlowForecastSlice-1'];

  if (type === 'end' && isCurrentMonth) {
    if (isForecastSlice1Enabled) {
      return (
        <span className={styles.forecastAmount}>
          <FormattedNumber
            currency="EUR"
            maximumFractionDigits={0}
            minimumFractionDigits={0}
            style="currency"
            value={forecastAmount ?? 0}
          />
        </span>
      );
    }
    return <span>-</span>;
  }

  if (isCurrentMonth || isPastMonth) {
    return (
      <FormattedNumber
        currency="EUR"
        maximumFractionDigits={0}
        minimumFractionDigits={0}
        style="currency"
        value={amount ?? 0}
      />
    );
  }
  if (isFutureMonth) {
    return (
      <span className={styles.forecastAmount}>
        <FormattedNumber
          currency="EUR"
          maximumFractionDigits={0}
          minimumFractionDigits={0}
          style="currency"
          value={forecastAmount ?? 0}
        />
      </span>
    );
  }

  return <span>-</span>;
}
