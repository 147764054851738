import Model, { attr } from '@ember-data/model';

export default class PaymentLinkMethodModel extends Model {
  /** @type {'apple_pay' | 'credit_card' | 'bank_transfer' | 'paypal'} */
  // @ts-expect-error
  @attr method;
  /** @type {boolean} */
  // @ts-expect-error
  @attr enabled;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'payment-link-method': PaymentLinkMethodModel;
  }
}
