import dayjs from 'dayjs';

import { ErrorInfo } from 'qonto/utils/error-info';

/**
 * @typedef {import('./config-types').TopBannerConfig} TopBannerConfig
 */

/**
 * @typedef {import('./config-types').Services} Services
 */

const CACHE_KEY = 'kyc-kyb';

/**
 * @param {Services} services - The parameters for the function.
 */
async function model({
  store,
  sentry,
  organizationManager,
  zendeskLocalization,
  productDiscovery,
  periodicUpdate,
  onBehalfRegistration,
}) {
  let { organization, membership } = organizationManager;

  let isDeCompanyCreation = organization.isDeCompanyCreation;
  let isKybPending = organization.isKybPending;
  let isKybPendingAssociation = organization.isAssociationKybPending;
  let isKybRefused = organization.kybRefused;

  let isKycPending = membership.kycPending;
  let isKycRefused = membership.kycRefused;
  let isKycSubmitted = membership.kycSubmitted;
  let isKycRequired = membership.kycRequired;
  let isKycWaitingDocument = false;
  let shouldSubmitKyc = membership.shouldSubmitKyc;

  if (isKycRefused) {
    // membership.kycStatus is received as `refused` when `waiting_document`
    // thus we leverage a new endpoint that returns the correct kyc for these cases.
    let kyc = await store.queryRecord('identitiesKyc', {
      identityId: membership.identityId,
    });

    isKycWaitingDocument = kyc.kycWaitingDocument;
  }

  let behalfRegistration;
  if (isKybPending) {
    behalfRegistration = await onBehalfRegistration.loadOnBehalfRegistration(organization.id);
  }

  let { isProductDiscoverySystemFeatureEnabled } = productDiscovery;
  if (isProductDiscoverySystemFeatureEnabled) {
    let updateProcess = await periodicUpdate.fetchUpdateProcess();
    try {
      await productDiscovery.fetchUserActions({ updateProcess });
    } catch (error) {
      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry && errorInfo.httpStatus !== 404) {
        sentry.captureException(error);
      }
    }
  }

  // This should only true when membership.kycSubmitted is false.
  // That's why within the kybPending block we can make the assumption of not showing any banners,
  // as in this case, it would only be replacing kyc banners
  let hasKycUserAction = productDiscovery.hasAction('kyc-action');
  let hasKybOnbehalfAction = productDiscovery.hasAction('kyb-onbehalf-action');
  let hasKybAssociationAction = productDiscovery.hasAction('kyb-association-action');

  let hasKycOrKybUserActions = hasKycUserAction || hasKybOnbehalfAction || hasKybAssociationAction;

  return {
    isDeCompanyCreation,
    isKybPending,
    isKybPendingAssociation,
    isKybRefused,
    isKycWaitingDocument,
    isKycPending,
    isKycRefused,
    isKycSubmitted,
    isKycRequired,
    shouldSubmitKyc,
    hasKycUserAction,
    hasKycOrKybUserActions,

    organization,
    membership,
    behalfRegistration,

    // Services
    zendeskLocalization,
  };
}

function buildMessageOptions({ zendeskLocalization, intl, organizationSlug }) {
  return {
    qonto_kyb_and_kyc_alerts_url: intl.t('topbar_msg.qonto_kyb_and_kyc_alerts_url', {
      organizationSlug,
      faqUrl: zendeskLocalization.getLocalizedArticle(4359529),
    }),
    qonto_kyb_and_kyc_alerts_url_text: intl.t('topbar_msg.qonto_kyb_and_kyc_alerts_url_text'),
    htmlSafe: true,
  };
}

function isOnBehalfRegistration({ membership, behalfRegistration }) {
  if (!behalfRegistration) {
    return false;
  }

  let poweredMembership = behalfRegistration?.poweredMemberships[0];
  let isPowerOfAttorneyWaiting = poweredMembership?.powerOfAttorney === 'WAITING';

  if (!isPowerOfAttorneyWaiting || poweredMembership.id !== membership.id) {
    return false;
  }

  return true;
}

function daysSince(date) {
  let today = dayjs();
  return Math.floor(today.diff(date, 'hours') / 24) + 1;
}

/**
 * @type {Array<TopBannerConfig>}
 */
export const KYC_KYB_CONFIG = [
  // KYB refused
  {
    id: 'kyb-refused',
    modelCacheKey: CACHE_KEY,
    model,
    type: 'info',
    message: ({ zendeskLocalization, organization }, intl) => {
      return intl.t(
        'topbar_msg.qonto_kyb_refused',
        buildMessageOptions({ zendeskLocalization, intl, organizationSlug: organization.slug })
      );
    },
    shouldDisplay: ({ isKybRefused }) => {
      return isKybRefused;
    },
  },
  // KYB pending
  {
    id: 'kyb-pending-kyc-waiting-doc',
    modelCacheKey: CACHE_KEY,
    model,
    componentName: 'topbar/kyc-in-app-waiting-document',
    type: 'warning',
    message: () => ' ', // required due to the bannerFlashMessages implementation
    shouldDisplay: ({ isKybPending, hasKycOrKybUserActions, isKycWaitingDocument }) => {
      return isKybPending && !hasKycOrKybUserActions && isKycWaitingDocument;
    },
  },
  {
    id: 'kyb-pending-kyc-refused',
    modelCacheKey: CACHE_KEY,
    model,
    type: 'info',
    message: ({ zendeskLocalization, organization }, intl) =>
      intl.t(
        'topbar_msg.qonto_kyc_refused',
        buildMessageOptions({ zendeskLocalization, intl, organizationSlug: organization.slug })
      ),
    shouldDisplay: ({ isKybPending, isKycRefused, hasKycOrKybUserActions }) => {
      return isKybPending && isKycRefused && !hasKycOrKybUserActions;
    },
  },
  {
    id: 'kyb-pending-kyc-not-submitted',
    modelCacheKey: CACHE_KEY,
    model,
    componentName: 'topbar/kyc-in-app',
    type: 'warning',
    message: () => ' ', // required due to the bannerFlashMessages implementation
    shouldDisplay: ({ isKybPending, shouldSubmitKyc, hasKycOrKybUserActions }) => {
      return isKybPending && shouldSubmitKyc && !hasKycOrKybUserActions;
    },
  },
  {
    id: 'kyb-pending-association',
    modelCacheKey: CACHE_KEY,
    model,
    componentName: 'topbar/association-kyb',
    type: 'warning',
    message: (_, intl) => intl.t('topbar_msg.association-kyb-pending'),
    shouldDisplay: ({ isKybPendingAssociation, hasKycOrKybUserActions }) => {
      return isKybPendingAssociation && !hasKycOrKybUserActions;
    },
  },
  {
    id: 'kyb-pending-on-behalf-first-8-days',
    modelCacheKey: CACHE_KEY,
    model,
    type: 'warning',
    componentName: 'topbar/upload-power-of-attorney',
    message: (_, intl) => intl.t('on-behalf.topbar-banner.reminder-1.text'),
    shouldDisplay: ({
      isKybPending,
      hasKycOrKybUserActions,
      organization,
      membership,
      behalfRegistration,
    }) => {
      if (
        !isKybPending ||
        hasKycOrKybUserActions ||
        !isOnBehalfRegistration({ membership, behalfRegistration })
      ) {
        return false;
      }

      return daysSince(organization.contractSignedAt) < 8;
    },
  },
  {
    id: 'kyb-pending-on-behalf-after-8-days',
    modelCacheKey: CACHE_KEY,
    model,
    type: 'error',
    componentName: 'topbar/upload-power-of-attorney',
    message: (_, intl) => intl.t('on-behalf.topbar-banner.reminder-2.text'),
    shouldDisplay: ({
      isKybPending,
      hasKycOrKybUserActions,
      organization,
      membership,
      behalfRegistration,
    }) => {
      if (
        !isKybPending ||
        hasKycOrKybUserActions ||
        !isOnBehalfRegistration({ membership, behalfRegistration })
      ) {
        return false;
      }

      return daysSince(organization.contractSignedAt) >= 8;
    },
  },
  {
    id: 'kyb-pending-de-cc',
    modelCacheKey: CACHE_KEY,
    model,
    type: 'info',
    message: ({ zendeskLocalization, organization }, intl) =>
      intl.t(
        'topbar_msg.qonto_kyb_pending_unregistered_DE',
        buildMessageOptions({ zendeskLocalization, intl, organizationSlug: organization.slug })
      ),
    shouldDisplay: ({ isKybPending, isDeCompanyCreation, hasKycOrKybUserActions }) => {
      return isKybPending && isDeCompanyCreation && !hasKycOrKybUserActions;
    },
  },
  {
    id: 'kyb-pending',
    modelCacheKey: CACHE_KEY,
    model,
    type: 'info',
    message: ({ zendeskLocalization, organization }, intl) =>
      intl.t(
        'topbar_msg.qonto_kyb_pending',
        buildMessageOptions({ zendeskLocalization, intl, organizationSlug: organization.slug })
      ),
    shouldDisplay: ({ isKybPending, hasKycOrKybUserActions }) => {
      return isKybPending && !hasKycOrKybUserActions;
    },
  },
  // KYB accepted
  {
    id: 'kyb-accepted-kyc-not-submitted',
    modelCacheKey: CACHE_KEY,
    model,
    type: 'warning',
    componentName: 'topbar/kyc-missing-id-fourthline',
    message: () => ' ', // required due to the bannerFlashMessages implementation
    shouldDisplay: ({ isKycSubmitted, isKycRequired, hasKycUserAction }) => {
      return !isKycSubmitted && isKycRequired && !hasKycUserAction;
    },
  },
  {
    id: 'kyb-accepted-kyc-refused',
    modelCacheKey: CACHE_KEY,
    model,
    type: 'info',
    message: ({ zendeskLocalization, organization }, intl) =>
      intl.t(
        'topbar_msg.qonto_kyc_refused',
        buildMessageOptions({ zendeskLocalization, intl, organizationSlug: organization.slug })
      ),
    shouldDisplay: ({ isKybRefused, isKybPending, isKycRefused, isKycWaitingDocument }) => {
      // We keep the same logic as the previous banner implementation.
      // The banner was in an if/else block, but here we need to be explicit with the conditions.
      let isKybAccepted = !isKybRefused && !isKybPending;
      return isKybAccepted && isKycRefused && !isKycWaitingDocument;
    },
  },
  {
    id: 'kyb-accepted-kyc-pending',
    modelCacheKey: CACHE_KEY,
    model,
    type: 'info',
    message: ({ zendeskLocalization, organization }, intl) =>
      intl.t(
        'topbar_msg.qonto_kyc_pending',
        buildMessageOptions({ zendeskLocalization, intl, organizationSlug: organization.slug })
      ),
    shouldDisplay: ({ isKybRefused, isKybPending, isKycPending, isKycSubmitted }) => {
      // We keep the same logic as the previous banner implementation.
      // The banner was in an if/else block, but here we need to be explicit with the conditions.
      let isKybAccepted = !isKybRefused && !isKybPending;
      return isKybAccepted && isKycPending && isKycSubmitted;
    },
  },
];
