export default {
  "row": "cjH",
  "empty": "cjO",
  "active": "cjm",
  "animated": "cjp",
  "fadein-item": "cjx",
  "cell": "cjw body-2",
  "cell-content": "cjN",
  "cell-amount": "cjv",
  "cell-content-amount": "cjo body-1"
};
