import Model, { attr } from '@ember-data/model';
import { service, type Registry as Services } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import CURRENCIES from 'qonto/constants/currencies';
// @ts-expect-error
import { getSupportedUnits } from 'qonto/utils/receivable-invoicing';

export default class ProductModel extends Model {
  @service declare intl: Services['intl'];

  @attr('string') declare title: string;
  @attr('string') declare description: string;

  /** @type { value: string, currency: string } **/
  // @ts-expect-error
  @attr('hash', {
    defaultValue: () => {
      return { value: undefined, currency: CURRENCIES.default };
    },
  })
  // @ts-expect-error
  unitPrice;
  @attr('string') declare vatRate: string;
  @attr('string') declare vatExemptionCode: string;
  @attr('string') declare unit: string;

  /** @type "good" | "service" **/
  @attr('string', { defaultValue: 'service' }) declare type: string;

  /** @type Array<{ title: string, url: string }> **/
  // @ts-expect-error
  @attr({ defaultValue: () => [] }) links;
  @attr('string') declare internalNote: string;

  @tracked isSelected = false;

  get formattedUnit() {
    if (!this.unit) {
      return '-';
    }
    // @ts-expect-error
    let standardUnit = getSupportedUnits(this.intl).find(({ unitCode }) => unitCode === this.unit);
    return standardUnit?.unitLongName ?? standardUnit?.unitName ?? this.unit;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    product: ProductModel;
  }
}
