/* import __COLOCATED_TEMPLATE__ from './payment-modal.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Disclaimer } from '@repo/design-system-kit';
import { CheckoutPanel, PaymentMethodSelectorToggle } from '@repo/domain-kit/invoices';

import { PAYMENT_LINK_METHODS } from 'qonto/constants/receivable-invoice';
import {
  PAYMENT_MODAL_TABS,
  PaymentModalTabs,
} from 'qonto/react/components/invoices/payment-modal';
import { TrackRender } from 'qonto/react/components/track-render';

interface ReceivableInvoicesPaymentModalSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class ReceivableInvoicesPaymentModalComponent extends Component<ReceivableInvoicesPaymentModalSignature> {
  PaymentMethodSelectorToggle = PaymentMethodSelectorToggle;
  DisclaimerBlock = Disclaimer.Block;
  TrackRender = TrackRender;
  PaymentModalTabs = PaymentModalTabs;
  CheckoutPanel = CheckoutPanel;

  @service declare intl: Services['intl'];

  @tracked activeTabId = PAYMENT_MODAL_TABS.PAYMENT_LINK;

  get enabledPaymentMethods() {
    // @ts-expect-error
    return this.args.paymentMethods.filter(({ enabled }) => enabled);
  }

  get someMethodsDisabled() {
    // @ts-expect-error
    return this.enabledPaymentMethods.length < this.args.paymentMethods.length;
  }

  get selectedPaymentMethods() {
    // @ts-expect-error
    return this.args.invoice.paymentLink?.methods || [];
  }

  get paymentLinkTabIsActive() {
    return this.activeTabId === PAYMENT_MODAL_TABS.PAYMENT_LINK;
  }

  get locales() {
    // @ts-expect-error
    return this.intl.getLocales.map(({ code, name }) => ({ id: code, label: name }));
  }

  @action
  // @ts-expect-error
  changeTab(newTabId) {
    this.activeTabId = newTabId;
  }

  @action
  // @ts-expect-error
  toggleMethod(method) {
    let newMethods = this.selectedPaymentMethods;
    if (this.isMethodSelected(method)) {
      // @ts-expect-error
      newMethods = newMethods.filter(m => m !== method);
    } else {
      newMethods.push(method);
    }

    // @ts-expect-error
    this.args.invoice.paymentLink = {
      // @ts-expect-error
      ...this.args.invoice.paymentLink,
      // @ts-expect-error
      methods: this.args.paymentMethods
        // @ts-expect-error
        .filter(({ method }) => newMethods.includes(method))
        // @ts-expect-error
        .map(({ method }) => method),
    };
  }

  // @ts-expect-error
  isMethodSelected = paymentMethod => this.selectedPaymentMethods.includes(paymentMethod);
  // @ts-expect-error
  isBankTransfer = paymentMethod => paymentMethod === PAYMENT_LINK_METHODS.BANK_TRANSFER;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ReceivableInvoices::PaymentModal': typeof ReceivableInvoicesPaymentModalComponent;
  }
}
