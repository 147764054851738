/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { ApplyCta } from 'qonto/react/components/financing/offer-details/apply-cta';

interface FinancingOfferDetailsHeaderSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLElement;
}

export default class FinancingOfferDetailsHeaderComponent extends Component<FinancingOfferDetailsHeaderSignature> {
  ApplyCta = ApplyCta;
  @service declare router: Services['router'];
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Financing::OfferDetails::Header': typeof FinancingOfferDetailsHeaderComponent;
  }
}
