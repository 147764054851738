export default {
  "header": "qyg",
  "content": "qyD",
  "read-only": "qyV",
  "document-collection-banner": "qyL",
  "submit-banner": "qyT",
  "review-banner": "qyz",
  "disclaimer": "qya",
  "title": "qyH",
  "card-container": "qyO",
  "representatives-section": "qym",
  "disabled-href": "qyp"
};
