import Model, { attr, belongsTo } from '@ember-data/model';
import { service, type Registry as Services } from '@ember/service';
import { waitFor } from '@ember/test-waiters';
import { tracked } from '@glimmer/tracking';

// @ts-expect-error
import { apiAction } from '@mainmatter/ember-api-actions';

import { INVOICE_SOURCES } from 'qonto/constants/supplier-invoice';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';

const SENTRY_IGNORE_HTTP_STATUSES = [404];

export default class SupplierInvoiceModel extends Model {
  // @ts-expect-error
  @attr invoiceNumber;
  // @ts-expect-error
  @attr fileName;
  // @ts-expect-error
  @attr supplierName;
  // @ts-expect-error
  @attr description;
  // @ts-expect-error
  @attr totalAmount;
  // @ts-expect-error
  @attr status;
  // @ts-expect-error
  @attr iban;
  // @ts-expect-error
  @attr source; // 'email_forward' | 'pay_by_invoice' | 'e_invoicing' | 'supplier_invoices' | 'integration'
  // @ts-expect-error
  @attr selfInvoiceId;
  // @ts-expect-error
  @attr requestTransfer;
  // @ts-expect-error
  @attr hasDuplicates;
  // @ts-expect-error
  @attr({ defaultValue: false }) hasDiscrepancies;
  // @ts-expect-error
  @attr isAttachmentInvoice;
  // @ts-expect-error
  @attr isAttachmentNonFinancial;
  // @ts-expect-error
  @attr('hash', {
    defaultValue: () => ({}),
  })
  // @ts-expect-error
  supplierSnapshot;
  // @ts-expect-error
  @attr supplierId;
  // @ts-expect-error
  @attr({ defaultValue: false }) isEinvoice;

  /** @type {string} YYYY-MM-DD */
  // @ts-expect-error
  @attr issueDate;
  /** @type {string} YYYY-MM-DD */
  // @ts-expect-error
  @attr dueDate;
  /** @type {string} YYYY-MM-DD */
  // @ts-expect-error
  @attr paymentDate;
  /** @type {string} YYYY-MM-DD */
  // @ts-expect-error
  @attr scheduledDate;
  /** @type {string} ISO Date String  */
  // @ts-expect-error
  @attr createdAt;
  /** @type {string} ISO Date String  */
  // @ts-expect-error
  @attr analyzedAt; // Ex value: "2022-03-04T17:58:30+02:00"
  /** @type Array<{ status_code: number, reason: string, reason_message: string, timestamp: string }> | null **/
  // @ts-expect-error
  @attr({ defaultValue: null }) einvoicingLifecycleEvents;
  // @ts-expect-error
  @attr({ defaultValue: () => [] }) matchedTransactionsIds;
  // @ts-expect-error
  @attr('hash', { defaultValue: () => ({}) }) availableActions;
  // @ts-expect-error
  @attr('hash') approvalWorkflow;

  // @ts-expect-error
  @belongsTo('organization', { async: true, inverse: null }) organization;
  // @ts-expect-error
  @belongsTo('membership', { async: false, inverse: null }) initiator;
  // @ts-expect-error
  @belongsTo('attachment', { async: false, inverse: null }) attachment;
  // @ts-expect-error
  @belongsTo('attachment', { async: false, inverse: null }) displayAttachment;

  /** @type boolean */
  // @ts-expect-error
  @attr({ defaultValue: false }) hasSuggestedCreditNotes;
  /** @type boolean */
  // @ts-expect-error
  @attr({ defaultValue: false }) isCreditNote;
  /** @type MoneyObject */
  // @ts-expect-error
  @attr payableAmount;
  /** @type Array<SupplierInvoice> */
  // @ts-expect-error
  @attr({ defaultValue: () => [] }) relatedInvoices;
  /** @type MoneyObject */
  // @ts-expect-error
  @attr totalAmountCreditNotes;

  // @ts-expect-error
  beneficiaryId;

  // @ts-expect-error
  @tracked approvalWorkflowState;

  @service declare sentry: Services['sentry'];

  async loadApprovalWorkflowState() {
    if (this.approvalWorkflow) {
      try {
        // @ts-expect-error
        this.approvalWorkflowState = (
          await this.store.query('approval-workflow-state', {
            request_type: 'supplier_invoice',
            request_id: this.approvalWorkflow.supplierInvoiceRequestId,
          })
        )[0];
      } catch (error) {
        if (
          ErrorInfo.for(error).shouldSendToSentry &&
          // @ts-expect-error
          !SENTRY_IGNORE_HTTP_STATUSES.includes(error.status)
        ) {
          this.sentry.captureException(error);
        }
      }
    }
  }

  @waitFor
  // @ts-expect-error
  async markAsPaid(paymentDate) {
    let data = { supplier_invoice: { payment_date: paymentDate } };
    let response = await apiAction(this, { method: 'POST', path: 'mark_as_paid', data });
    this.store.pushPayload(response);
  }

  @waitFor
  async convertToInvoice() {
    let response = await apiAction(this, { method: 'POST', path: 'convert_to_invoice' });
    this.store.pushPayload(response);
  }

  @waitFor
  async convertToCreditNote() {
    let response = await apiAction(this, { method: 'POST', path: 'convert_to_credit_note' });
    this.store.pushPayload(response);
  }

  @service declare organizationManager: Services['organizationManager'];

  get frenchEInvoicing() {
    return this.source
      ? this.source === INVOICE_SOURCES.eInvoicing &&
          this.organizationManager.organization.legalCountry === 'FR'
      : false;
  }

  get isGermanEInvoice() {
    return this.isEinvoice && this.organizationManager.organization.legalCountry === 'DE';
  }

  get hasRelatedInvoices() {
    return Boolean(this.relatedInvoices?.length);
  }

  get amountToPay() {
    return this.hasRelatedInvoices ? this.payableAmount : this.totalAmount;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'supplier-invoice': SupplierInvoiceModel;
  }
}
