/* import __COLOCATED_TEMPLATE__ from './image.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface ImageSignature {
  Element: HTMLDivElement;
  Args: {};
  Blocks: {
    default: [];
  };
}

const Image = templateOnlyComponent<ImageSignature>();

export default Image;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Checks::Image': typeof Image;
  }
}
