export default {
  "card-sidebar-header": "cWZ",
  "content": "cWR",
  "header-type": "cWQ",
  "close": "cWe",
  "close-icon": "cWB",
  "header-infos": "cWE",
  "title": "cWd body-1",
  "danger": "cWI",
  "success": "cWt",
  "warning": "cWM",
  "date": "cWP body-2",
  "card-image-container": "cWl",
  "card-image": "cWX",
  "info-container": "cWC",
  "spending-reason": "cWk",
  "spending-reason-label": "cWJ body-2 mr-8",
  "spending-reason-note": "cWs body-2"
};
