export default {
  "document": "ciJ",
  "document-details": "cis",
  "document-details-amount": "ciF body-2",
  "negative": "cig",
  "positive": "ciD",
  "rejected": "ciV",
  "document-icon": "ciL",
  "active": "ciT",
  "document-details-link": "ciz body-link",
  "canceled": "cia",
  "status-badge": "ciH"
};
