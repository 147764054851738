export default {
  "upsell-cta": "ccb",
  "discover-badge": "ccy",
  "role-selection-item": "ccU",
  "unavailable": "ccj",
  "role-headline": "ccW",
  "illustration": "ccu",
  "role-description": "cqS",
  "description-item": "cqc",
  "description-icon": "cqq",
  "description-text": "cqZ",
  "current-role": "cqR"
};
