export default {
  "header": "qxl",
  "btn-preset": "qxX btn btn-filter mr-8 mb-8",
  "invoices-wrapper": "qxC",
  "statements-table": "qxk",
  "no-result": "qxJ",
  "lottie-illustration": "qxs",
  "wrapper": "qxF",
  "access-denied": "qxg",
  "access-denied-illustration": "qxD",
  "application-card": "qxV",
  "presets-wrapper": "qxL",
  "error-wrapper": "qxT",
  "error-container": "qxz",
  "error-illustration": "qxa mb-32",
  "invoicing-info": "qxH"
};
