export default {
  "notifications-settings": "cFm",
  "opened": "cFp",
  "icon": "cFx",
  "header": "cFw",
  "header-toggle": "cFN",
  "avatar-image": "cFv",
  "title": "cFo",
  "icon--right": "cFn",
  "toggle-container": "cFi",
  "toggle": "cFA"
};
