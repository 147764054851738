import Controller from '@ember/controller';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { isTesting, macroCondition } from '@embroider/macros';
import { Spinner } from '@repo/design-system-kit';
import { dropTask, rawTimeout } from 'ember-concurrency';

import { FRENCH_EINVOICE_PAYMENT_STORAGE_KEY } from 'qonto/constants/supplier-invoice';
import { USER_ACTIONS_STATUS, USER_ACTIONS_TYPE } from 'qonto/constants/user-actions';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import { ErrorInfo } from 'qonto/utils/error-info';

const LIST_VIEW_CARDS_TYPES = [USER_ACTIONS_TYPE.SETUP, USER_ACTIONS_TYPE.REQUIRED];

export default class OverviewNewController extends Controller {
  spinner = Spinner;

  @service store;
  @service sentry;
  @service segment;
  @service productDiscovery;
  @service bannerFlashMessages;
  @service topBanners;
  @service organizationManager;
  @service subscriptionManager;

  @tracked hasNotFoundError = false;

  get hasAtLeastOneSetupOrRequiredProductDiscoveryAction() {
    return this.userActions.some(({ type }) => LIST_VIEW_CARDS_TYPES.includes(type));
  }

  get hasAtLeastOneDiscoveryProductDiscoveryAction() {
    return this.userActions.some(({ type }) => type === USER_ACTIONS_TYPE.DISCOVERY);
  }

  get hasTransactions() {
    return this.model.transactionsTask.lastSuccessful?.value?.transactions?.length > 0;
  }

  get hasNoInternalBankAccount() {
    return this.organizationManager.organization.internalAccounts.length === 0;
  }

  get showSubheading() {
    return (
      this.userActions.some(({ type }) => type === USER_ACTIONS_TYPE.SETUP) &&
      !this.userActions.some(({ isPrimary }) => isPrimary)
    );
  }

  get showBanklessSubheading() {
    return !this.subscriptionManager.features.bankAccount;
  }

  get userActions() {
    let userActions = this.productDiscovery.visibleUserActions();
    return userActions.filter(
      action => !action.hiddenBy?.some(hider => userActions.some(a => a.name === hider))
    );
  }

  get canDisplayConnectionStatusBanner() {
    return (
      !this.organizationManager.membership.shouldSubmitKyc ||
      !this.organizationManager.organization.kybPending
    );
  }

  fetchUserActionsTask = dropTask(async () => {
    let retryCount = 0;
    let maxRetries = 60;
    let delayInMs = 1000;
    if (macroCondition(isTesting())) {
      delayInMs = 100;
    }

    let startTime = Date.now();

    while (retryCount < maxRetries) {
      try {
        await this.productDiscovery.fetchUserActions({
          updateProcess: this.model.updateProcess,
        });

        if (this.hasNotFoundError) {
          this.trackLoadFailed(Date.now() - startTime);

          // We have to re-trigger the top-banners because
          // they are dependent on the user actions
          this.bannerFlashMessages.clearTopBannerMessages();
          this.topBanners.triggerBanners();
        }
        this.hasNotFoundError = false;

        break;
      } catch (error) {
        let errorInfo = ErrorInfo.for(error);
        if (errorInfo.httpStatus !== 404) {
          if (errorInfo.shouldSendToSentry) {
            this.sentry.captureException(error);
          }
          break;
        }

        this.hasNotFoundError = true;

        if (retryCount === maxRetries) {
          this.trackLoadFailed();
        }

        retryCount++;
        await rawTimeout(delayInMs);
      }
    }
  });

  trackLoadFailed(elapsedTimeInMs) {
    this.segment.track('product-discovery_first-load-failed', {
      'loading-time-in-seconds': elapsedTimeInMs ? elapsedTimeInMs / 1000 : null,
    });
  }

  setActionToDismissedTask = dropTask(async ({ actionRecord }) => {
    try {
      actionRecord.status = USER_ACTIONS_STATUS.DISMISSED;

      if (actionRecord.key === 'french-e-invoicing-payement-action') {
        safeLocalStorage.setItem(FRENCH_EINVOICE_PAYMENT_STORAGE_KEY, true);
      }

      await actionRecord.save();
    } catch (error) {
      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  });
}
