export default {
  "container": "ceS",
  "has-background": "cec",
  "wrapper": "ceq",
  "error-wrapper": "ceZ",
  "bottom-btns": "ceR",
  "bottom-wrapper": "ceQ",
  "tab-nav": "cee",
  "tab-nav-small": "ceB",
  "btn-clear": "ceE",
  "error-illustration": "ced",
  "error-title": "ceI title-1",
  "disclaimer-wrapper": "cet",
  "disclaimer": "ceM",
  "error-description": "ceP body-2",
  "table-wrapper": "cel",
  "title": "ceX title-1",
  "lineup-toggle": "ceC",
  "description": "cek body-2",
  "loading-wrapper": "ceJ",
  "card": "ces",
  "plan": "ceF",
  "placeholder-header": "ceg",
  "disclaimer-info": "ceD",
  "spinner": "ceV"
};
