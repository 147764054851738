import Route from '@ember/routing/route';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';
import window from 'ember-window-mock';

import { INVITATION_TYPES } from 'qonto/constants/membership';
import { DEFAULT_SORT_BY } from 'qonto/constants/transactions';
import { ErrorInfo } from 'qonto/utils/error-info';

export default class ProductDiscoveryRedirectRoute extends Route {
  @service router;
  @service store;
  @service sentry;
  @service homePage;
  @service flowLinkManager;

  beforeModel() {
    Sentry.getCurrentScope().setTag('CFT', 'onboarding');
  }

  model() {
    let organization = this.modelFor('organizations');
    return {
      organization,
    };
  }

  async afterModel(model, transition) {
    let { queryParams } = transition.to;
    let { action, ...otherQueryParams } = queryParams;

    if (!action) {
      this.sentry.captureMessage(`Product Discovery - Deeplink action not provided`);
      return this.homePage.replaceWithDefaultPage();
    }

    let config = REDIRECTS_CONFIG.find(c => c.action === action);
    if (!config) {
      this.sentry.captureMessage(`Product Discovery - Deeplink action "${action}" does not exist`);
      return this.homePage.replaceWithDefaultPage();
    }

    await config.redirect(
      {
        flowLinkManager: this.flowLinkManager,
        router: this.router,
        store: this.store,
        sentry: this.sentry,
      },
      {
        transitionFromRouteName: transition.from?.name,
        organization: model.organization,
        queryParams: otherQueryParams,
      }
    );
  }
}

/**
 * @typedef {import('../../services/product-discovery/redirect-config-types').ProductDiscoveryRedirectConfig} ProductDiscoveryRedirectConfig
 */

/**
 * @type {Array<ProductDiscoveryRedirectConfig>}
 */
export const REDIRECTS_CONFIG = [
  {
    action: 'subscription.add_on.manage',
    redirect: ({ flowLinkManager }, { queryParams }) =>
      flowLinkManager.replaceWith({
        name: 'addon-change',
        stepId: 'addons',
        queryParams: { ...queryParams, origin: 'discovery_card' },
      }),
  },
  {
    action: 'mileage.request',
    redirect: ({ flowLinkManager }) =>
      flowLinkManager.replaceWith({
        name: 'request-mileage',
        stepId: 'journey',
      }),
  },
  {
    action: 'member-invite.select-role',
    redirect: ({ flowLinkManager }) =>
      flowLinkManager.replaceWith({
        name: 'member-invite',
        stepId: 'role',
      }),
  },
  {
    action: 'member-invite.guest',
    redirect: ({ flowLinkManager }) =>
      flowLinkManager.replaceWith({
        name: 'member-invite',
        stepId: 'personal-info',
        queryParams: {
          memberId: null,
          invitationType: INVITATION_TYPES.GUEST,
        },
      }),
  },
  {
    action: 'receivable-invoices.index',
    redirect: ({ router }) => router.replaceWith('receivable-invoices'),
  },
  {
    action: 'supplier-invoices.index',
    redirect: ({ router }) => router.replaceWith('supplier-invoices'),
  },
  {
    action: 'quotes.index',
    redirect: ({ router }) => router.replaceWith('quotes'),
  },
  {
    action: 'e-invoice.onboarding',
    redirect: ({ flowLinkManager }) =>
      flowLinkManager.replaceWith({
        name: 'invoice-onboarding',
        stepId: 'einvoice-activation',
      }),
  },
  {
    action: 'accounting-discovery',
    redirect: ({ flowLinkManager }) =>
      flowLinkManager.replaceWith({
        name: 'accounting-discovery',
        stepId: 'landing',
      }),
  },
  {
    action: 'pay-later.invoice-upload',
    redirect: ({ flowLinkManager }) =>
      flowLinkManager.replaceWith({
        name: 'pay-later-transfer',
        stepId: 'invoice-upload',
      }),
  },
  {
    action: 'pay-later.in-progress',
    redirect: ({ router }) => router.replaceWith('financing.pay-later.in-progress'),
  },
  {
    action: 'account-aggregation.import-external-account',
    redirect: ({ flowLinkManager }, { transitionFromRouteName }) =>
      flowLinkManager.replaceWith({
        name: 'external-account-import',
        stepId: 'select',
        queryParams: {
          ...(transitionFromRouteName && {
            origin: transitionFromRouteName,
          }),
        },
      }),
  },
  {
    action: 'connect-hub.featured',
    redirect: ({ router }) => router.replaceWith('settings.connect-hub.featured'),
  },
  {
    action: 'connect-hub.applications.payfit',
    redirect: ({ router }, { organization }) =>
      router.replaceWith(
        'settings.connect-hub.applications.hub-application.details.index',
        organization.slug,
        'payfit'
      ),
  },
  {
    action: 'connect-hub.applications.google-drive',
    redirect: ({ router }, { organization }) =>
      router.replaceWith(
        'settings.connect-hub.applications.hub-application.details.index',
        organization.slug,
        'googledrivein'
      ),
  },
  {
    action: 'connect-hub.categories',
    redirect: ({ router }, { organization, queryParams }) =>
      router.replaceWith(
        'settings.connect-hub.categories',
        organization.slug,
        queryParams.category
      ),
  },
  {
    action: 'connect-hub.categories.invoice-import',
    redirect: ({ router }, { organization }) =>
      router.replaceWith('settings.connect-hub.categories', organization.slug, 'invoice-import'),
  },
  {
    action: 'cards.order-new-physical',
    redirect: ({ flowLinkManager }) =>
      flowLinkManager.replaceWith({
        name: 'card-new-physical',
        stepId: 'choose-card',
      }),
  },
  {
    action: 'insurance-hub.policies',
    redirect: ({ router }) => router.replaceWith('insurance-hub.policies'),
  },
  {
    action: 'payment-activation',
    redirect: ({ flowLinkManager }) =>
      flowLinkManager.replaceWith({
        name: 'payment-activation',
        stepId: 'context',
      }),
  },
  {
    action: 'settings.company-profile',
    redirect: ({ router }) => router.replaceWith('settings.company-profile'),
  },
  {
    action: 'kyc.start',
    redirect: ({ router }) =>
      router.replaceWith('kyc.start', {
        queryParams: {
          trackingEventOrigin: 'upsize_banner',
        },
      }),
  },
  {
    action: 'topup',
    redirect: ({ router }, { organization }) =>
      router.replaceWith('onboarding.topup.amount', organization, {
        queryParams: {
          trackingEventOrigin: 'product_discovery',
        },
      }),
  },
  {
    action: 'bank-accounts.details',
    redirect: ({ router, store }) => {
      let bankAccount = store.peekAll('bank-account').find(({ main }) => main);
      return router.replaceWith('accounts.details', bankAccount.slug);
    },
  },
  {
    action: 'kyb.upload-power-of-attorney',
    redirect: ({ router }) => router.replaceWith('upload-power-of-attorney'),
  },
  {
    action: 'kyb.de-flex-kyb',
    redirect: ({ router }) => router.replaceWith('flex-kyb'),
  },
  {
    action: 'kyb.association',
    redirect: (_, { organization }) => (window.location.href = organization.associationTypeformUrl),
  },
  {
    action: 'transactions.attach-receipt',
    redirect: async ({ router, store, sentry }, { organization }) => {
      let [property, direction] = DEFAULT_SORT_BY.split(':');
      let query = {
        includes: [],
        sort: { property, direction },
        pagination: { page: 1, per_page: 1 },
        organization_id: organization.id,
        filter_group: {
          conditional: 'and',
          expressions: [
            {
              values: ['missing'],
              operator: 'in',
              property: 'attachment_status',
            },
            {
              values: ['pending', 'completed'],
              operator: 'in',
              property: 'status',
            },
            {
              values: ['biller'],
              operator: 'not_in',
              property: 'operation_method',
            },
          ],
        },
      };

      try {
        let { transactions } = await store.adapterFor('transaction').search(query);
        if (transactions.length === 1) {
          return router.replaceWith('transactions.index', {
            queryParams: {
              highlight: transactions[0].id,
            },
          });
        }
      } catch (error) {
        let errorInfo = ErrorInfo.for(error);
        if (errorInfo.shouldSendToSentry) {
          sentry.captureException(error, {
            captureContext: {
              extra: {
                description: `Product Discovery - Deeplink action "transactions.attach-receipt" error`,
              },
            },
          });
        }
      }

      return router.replaceWith('transactions.index');
    },
  },
  {
    action: 'cash-flow-management-test',
    redirect: ({ router }, { organization, queryParams }) =>
      router.replaceWith('feature-discovery', organization.slug, {
        queryParams: { flow: 'cash-flow-management', price_plan: queryParams.pricePlan },
      }),
  },
  {
    action: 'invoice-automation-test',
    redirect: ({ router }, { organization, queryParams }) =>
      router.replaceWith('feature-discovery', organization.slug, {
        queryParams: { flow: 'invoice-automation', price_plan: queryParams.pricePlan },
      }),
  },
  {
    action: 'recurring-invoices.index',
    redirect: ({ router }) => router.replaceWith('invoice-subscriptions'),
  },
  {
    action: 'external-account-import',
    redirect: ({ flowLinkManager }) =>
      flowLinkManager.replaceWith({ name: 'external-account-import', stepId: 'select' }),
  },
  {
    action: 'referral.show',
    redirect: ({ router }) => router.replaceWith('settings.referrals'),
  },
];
