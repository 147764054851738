/* import __COLOCATED_TEMPLATE__ from './monthly-transfer-limit.hbs'; */
import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';

interface MemberMonthlyTransferLimitSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class MemberMonthlyTransferLimitComponent extends Component<MemberMonthlyTransferLimitSignature> {
  /**
   * Calculate the percentage of the used monthly limit.
   * @returns a number between 0 and 100
   */
  get progress() {
    // @ts-expect-error
    let { monthlyTransferLimit, currentMonthSpendings } = this.args;
    return Math.min(
      100,
      Math.floor((100 / monthlyTransferLimit.value) * currentMonthSpendings.value)
    );
  }

  /**
   * Creates the style rule for the progress bar.
   * @returns and htmlsafe string with a percentage value
   */
  get progressWidth() {
    let width = this.progress + '%';
    return htmlSafe(`width: ${width};`);
  }

  /**
   * Verifies if the current progress percentage is close to the limit.
   */
  get isLimitClose() {
    return this.progress > 80;
  }

  /**
   * Execute the calculation for the monthly availability, considering the current month spendings
   * and the total monthly limit.
   */
  get monthlyAvailability() {
    // @ts-expect-error
    let { monthlyTransferLimit, currentMonthSpendings } = this.args;
    return monthlyTransferLimit.value - currentMonthSpendings.value;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Member::MonthlyTransferLimit': typeof MemberMonthlyTransferLimitComponent;
  }
}
