export default {
  "mileage-validation": "cbH",
  "actions": "cbO",
  "action-btn": "cbm",
  "action-btn-right": "cbp",
  "request-mileage-validation": "cbx",
  "account-selector": "cbw",
  "has-error": "cbN",
  "funds-disclaimer": "cbv",
  "account-options": "cbo",
  "account-balance": "cbn",
  "warnings": "cbi"
};
