export default {
  "summary-description": "cEP",
  "detail": "cEl",
  "detail-value": "cEX",
  "account-icon": "cEC",
  "color-secondary": "cEk",
  "beneficiary-info": "cEJ",
  "currency": "cEs",
  "beneficiary-name": "cEF",
  "separator": "cEg",
  "instant-subtitle": "cED",
  "instant-wrapper": "cEV",
  "instant-header": "cEL",
  "instant-toggle": "cET",
  "instant--disabled": "cEz",
  "limit-exceeded-disclaimer": "cEa",
  "sticky-panel": "cEH",
  "total-interest": "cEO",
  "tooltip-icon": "cEm",
  "tooltip": "cEp",
  "payment-options": "cEx"
};
