/* import __COLOCATED_TEMPLATE__ from './invoice-upload.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask } from 'ember-concurrency';

import { PAY_LATER_FLOW_ORIGIN } from 'qonto/constants/financing';
import { TRANSFER_FLOW_ORIGIN } from 'qonto/constants/transfers';
// @ts-expect-error
import { getCurrentParisDateString } from 'qonto/utils/date';

interface FlowsTransfersSepaNewInvoiceUploadSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsTransfersSepaNewInvoiceUploadComponent extends Component<FlowsTransfersSepaNewInvoiceUploadSignature> {
  @service declare errors: Services['errors'];
  @service declare payByInvoiceUploadManager: Services['payByInvoiceUploadManager'];
  @service declare segment: Services['segment'];
  @service declare store: Services['store'];
  @service declare abilities: Services['abilities'];

  get isPayLaterLimitationsVisible() {
    let { isPayLaterContractSigned, isPayLaterEligible, isDedicatedFlow, origin } =
      // @ts-expect-error
      this.args.context;
    let payLaterOrigins = [TRANSFER_FLOW_ORIGIN.PAY_LATER_COCKPIT, TRANSFER_FLOW_ORIGIN.OVERVIEW];

    return (
      this.abilities.can('create pay later transfer in financing') &&
      isPayLaterContractSigned &&
      isPayLaterEligible &&
      payLaterOrigins.includes(origin) &&
      !isDedicatedFlow
    );
  }

  @action
  onInvoiceSelected() {
    // @ts-expect-error
    let { context, transitionToNext } = this.args;
    let { origin } = context;

    this.segment.track('transfer-sepa_invoice_confirmed', {
      ...(origin && { origin }),
    });

    transitionToNext();
  }

  @action
  // @ts-expect-error
  onInvoiceUploaded(invoiceId) {
    // @ts-expect-error
    let { context, transitionToNext } = this.args;
    let { invoice, origin, sepaTransfer, defaultOperationType } = context;

    if (invoice?.id !== invoiceId) {
      if (origin !== PAY_LATER_FLOW_ORIGIN.PAY_BY_BENEFICIARY) {
        let inputProperties = { operationType: defaultOperationType };
        this._resetTransfer(sepaTransfer, inputProperties);
      }

      this._attachInvoiceToTransfer(invoiceId);

      this.segment.track('transfer-sepa_invoice_uploaded', {
        ...(origin && { origin }),
      });

      transitionToNext();
    }
  }

  @action
  onUploadError() {
    // @ts-expect-error
    let { origin } = this.args.context;
    this.segment.track('transfer-sepa_invoice_uploaded', {
      ...(origin && { origin }),
    });
  }

  deleteInvoiceTask = dropTask(async invoice => {
    try {
      await invoice.destroyRecord();
    } catch (error) {
      this.errors.handleError(error);
    }
  });

  onRemoveFileTask = dropTask(async invoiceId => {
    // @ts-expect-error
    let { context } = this.args;
    let { origin } = context;

    this.segment.track('transfer-sepa_invoice_deleted', {
      ...(origin && { origin }),
    });

    let invoice = this.store.peekRecord('supplier-invoice', invoiceId);
    if (invoice) {
      await this.deleteInvoiceTask.perform(invoice);
    }

    // @ts-expect-error
    this.payByInvoiceUploadManager.resetState();

    context.invoice = null;
    context.sepaTransfer.attachments = [];
  });

  // @ts-expect-error
  _attachInvoiceToTransfer(invoiceId) {
    // @ts-expect-error
    let { context } = this.args;

    let invoice = this.store.peekRecord('supplier-invoice', invoiceId);

    context.invoice = invoice;
    // @ts-expect-error
    context.sepaTransfer.attachments = [invoice.attachment];
  }

  // @ts-expect-error
  _resetTransfer(transfer, inputProperties) {
    this._resetTransferDetails(transfer, inputProperties);
    this._resetTransferBeneficary(transfer);
  }

  // @ts-expect-error
  _resetTransferDetails(transfer, inputProperties) {
    transfer.setProperties({
      activityTag: 'other_expense',
      amount: null,
      attachments: [],
      email: null,
      notifyByEmail: false,
      reference: null,
      scheduledDate: getCurrentParisDateString(),
      standingEndingDate: null,
      standingOn: null,
      ...inputProperties,
    });
  }

  // @ts-expect-error
  _resetTransferBeneficary(transfer) {
    transfer.beneficiary = null;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Transfers::Sepa::New::InvoiceUpload': typeof FlowsTransfersSepaNewInvoiceUploadComponent;
  }
}
