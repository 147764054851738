export default {
  "bullet": "cLw",
  "slice-0": "cLN",
  "slice-1": "cLv",
  "slice-2": "cLo",
  "slice-3": "cLn",
  "slice-4": "cLi",
  "slice-5": "cLA",
  "slice-6": "cLY",
  "slice-other": "cLh",
  "slice-uncategorized": "cLf",
  "label": "cLK",
  "ellipsis": "cLG",
  "rest": "cLr",
  "previous-date-range": "cLb caption"
};
