export default {
  "header-cell": "oA caption-bold",
  "sortable": "oY",
  "animated": "oh",
  "fadein-item": "of",
  "animated-cell": "oK",
  "col1": "oG",
  "header-content": "or caption-bold",
  "active": "ob"
};
