export default {
  "item": "cJi",
  "selected": "cJA",
  "hover-revised": "cJY",
  "asset-container": "cJh",
  "asset": "cJf",
  "revoked-icon": "cJK",
  "pending-icon": "cJG",
  "revoked": "cJr",
  "revised-revoke": "cJb",
  "pending": "cJy",
  "revised-pending": "cJU",
  "invitable": "cJj",
  "info": "cJW",
  "name": "cJu",
  "email": "csS",
  "actions": "csc",
  "invitable-member-delete": "csq",
  "invitable-member-invite": "csZ"
};
