import { Dialog, Modal } from 'react-aria-components';
import cx from 'clsx';
import type { ModalOverlayProps as AriaModalProps } from 'react-aria-components';
import type { ReactElement } from 'react';
import type { AriaDialogProps } from 'react-aria';
import styles from '../popup.strict-module.css';

export interface PopupRootProps extends AriaModalProps, AriaDialogProps {
  children: ReactElement | ReactElement[];
  portalContainer?: Element;
}

export function PopupRoot({
  children,
  className,
  portalContainer,
  ...props
}: PopupRootProps): ReactElement {
  const { 'aria-label': ariaLabel, 'aria-labelledby': ariaLabelledBy, ...restProps } = props;

  return (
    <Modal
      className={cx(styles.wrapper, className)}
      {...restProps}
      UNSTABLE_portalContainer={portalContainer}
    >
      <Dialog aria-label={ariaLabel} aria-labelledby={ariaLabelledBy} className={styles.dialog}>
        {children}
      </Dialog>
    </Modal>
  );
}
