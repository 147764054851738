export default {
  "dates-filter": "no",
  "dates-wrapper": "nn",
  "date-interval": "ni",
  "date-field-wrapper": "nA",
  "dates-conditional": "nY",
  "placeholder": "nh",
  "error": "nf",
  "error-message": "nK body-2"
};
