import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { dropTask } from 'ember-concurrency';

import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class PaymentLinksRoute extends Route {
  @service store;
  @service sentry;

  queryParams = {
    status: { refreshModel: true },
    page: { refreshModel: true },
    perPage: { refreshModel: true },
    sortBy: { refreshModel: true },
    highlight: { refreshModel: false },
  };

  model(params) {
    this.fetchPaymentLinksTask
      .perform(params)
      .catch(ignoreCancelation)
      .catch(error => this.#handleError(error));

    return {
      fetchPaymentLinksTask: this.fetchPaymentLinksTask,
    };
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.resetQueryParams();
    }
  }

  fetchPaymentLinksTask = dropTask(async queryParams => {
    let { status, page, perPage, sortBy } = queryParams;

    let paymentLinksQueryParams = {
      filter: { status },
      page: { number: page, size: perPage },
      sort: sortBy,
    };

    let paymentLinks = await this.store.query('payment-link', paymentLinksQueryParams);

    return {
      paymentLinks: paymentLinks ?? [],
      meta: {
        total_count: paymentLinks?.meta.total ?? 0,
        total_pages: paymentLinks?.meta.total_pages ?? 1,
        per_page: perPage,
        current_page: page,
      },
    };
  });

  #handleError(exception) {
    if (ErrorInfo.for(exception).shouldSendToSentry) {
      this.sentry.captureException(exception);
    }
  }
}
