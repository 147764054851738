export default {
  "container": "qmp",
  "container-column": "qmx",
  "content-wrapper": "qmw",
  "media-wrapper": "qmN",
  "content": "qmv",
  "title": "qmo",
  "subtitle": "qmn",
  "content-slot": "qmi",
  "sticky-panel": "qmA",
  "sticky-actions": "qmY"
};
