export default {
  "details": "cZg",
  "disclaimer": "cZD",
  "data": "cZV",
  "confirm": "cZL",
  "form": "cZT",
  "textarea": "cZz",
  "preview": "cZa",
  "form-title": "cZH title1",
  "form-subtitle": "cZO title2",
  "divider": "cZm",
  "submit": "cZp",
  "doc": "cZx",
  "amount-input-error": "cZw"
};
