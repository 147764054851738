/* import __COLOCATED_TEMPLATE__ from './main.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { reads } from 'macro-decorators';

interface CardSidebarMainSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class CardSidebarMain extends Component<CardSidebarMainSignature> {
  @service declare organizationManager: Services['organizationManager'];
  @service declare abilities: Services['abilities'];
  @service declare userManager: Services['userManager'];

  // @ts-expect-error
  @reads('organizationManager.organization') currentOrganization;

  get areRestrictionsShown() {
    // @ts-expect-error
    let { card } = this.args;
    if (card?.isAdvertising) return false;

    let canViewRestrictions = this.abilities.can('view restrictions card');
    let hasRestrictions = card?.activeDaysOption || card?.hasCategoryTags;

    return canViewRestrictions || hasRestrictions;
  }

  get isCardHolder() {
    // @ts-expect-error
    return this.args.card.holder.get('id') === this.organizationManager.membership.id;
  }

  get isScaEnabled() {
    // @ts-expect-error
    return this.userManager.currentUser.scaEnabled;
  }

  get cardInitiatorId() {
    // @ts-expect-error
    let { card } = this.args;
    return card.belongsTo('holder').id();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'CardSidebar::Main': typeof CardSidebarMain;
  }
}
