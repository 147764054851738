export default {
  "cell": "En caption-bold",
  "empty": "Ei",
  "transaction": "EA",
  "member": "EY",
  "method": "Eh",
  "operation-date": "Ef",
  "amount": "EK",
  "label": "EG caption-bold",
  "active": "Er"
};
