export default {
  "body": "qft",
  "members": "qfM",
  "members-results": "qfP",
  "members-list": "qfl",
  "members-title": "qfX caption-bold",
  "member": "qfC",
  "empty-illustration": "qfk",
  "empty-title": "qfJ title-3",
  "empty-description": "qfs body-2",
  "invitable-members": "qfF",
  "details": "qfg",
  "pagination-footer": "qfD"
};
