export default {
  "card-header": "qDg",
  "counterparty-name": "qDD",
  "pan-section": "qDV header-section body-2",
  "infobox": "qDL header-section",
  "see-card-button": "qDT",
  "card-identifier-section": "qDz",
  "card-icon": "qDa",
  "nickname": "qDH body-1",
  "pan": "qDO body-2"
};
