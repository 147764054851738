/* import __COLOCATED_TEMPLATE__ from './item.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { DATE_FORMAT_TOKENS, dateToken } from '@qonto/ui-kit/utils/date-token';
import { Checkbox } from '@repo/design-system-kit';

interface InvoicesTableHeaderSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLTableRowElement;
}

export default class InvoicesTableHeaderComponent extends Component<InvoicesTableHeaderSignature> {
  checkbox = Checkbox;

  @service declare intl: Services['intl'];

  get subscriptionPeriodText() {
    let startDate = dateToken({
      // @ts-expect-error
      date: this.args.fromDate,
      // @ts-expect-error
      locale: this.intl.primaryLocale,
      token: DATE_FORMAT_TOKENS.DATE_S,
    });

    let endDate = dateToken({
      // @ts-expect-error
      date: this.args.toDate,
      // @ts-expect-error
      locale: this.intl.primaryLocale,
      token: DATE_FORMAT_TOKENS.DATE_YEAR_S,
    });

    return this.intl.t('subscription.invoice.table.period.value', { startDate, endDate });
  }

  get checkboxAriaLabel() {
    return `${this.intl.t('subscription.invoice.table.aria-labels.select-invoice')} ${
      this.subscriptionPeriodText
    }`;
  }

  get buttonAriaLabel() {
    // If items are selected, action of button is to select
    // @ts-expect-error
    if (this.args.someSelected || this.args.allSelected) {
      return this.checkboxAriaLabel;
    }

    return `${this.intl.t('subscription.invoice.table.aria-labels.download-invoice')} ${
      this.subscriptionPeriodText
    }`;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Invoices::Table::Item': typeof InvoicesTableHeaderComponent;
  }
}
