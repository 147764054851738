export default {
  "row": "aI body-1",
  "cell": "at",
  "empty": "aM",
  "amount": "aP",
  "review": "al",
  "cell-content": "aX",
  "icon": "aC",
  "name": "ak",
  "name-text": "aJ",
  "quick-actions": "as",
  "account-select": "aF"
};
