import type { ReactElement, SVGProps } from 'react';

export function IconVisa(props: SVGProps<SVGSVGElement>): ReactElement {
  return (
    <svg fill="none" height={20} width={27} xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect fill="#1B16E6" height={20} rx={3} width={26.667} />
      <path
        d="M13.677 8.798c-.011.929.802 1.447 1.414 1.755.63.316.841.519.839.802-.005.432-.502.623-.968.63-.812.014-1.284-.226-1.66-.406l-.292 1.412c.377.18 1.074.336 1.797.343 1.697 0 2.808-.865 2.814-2.206.006-1.702-2.28-1.796-2.265-2.557.005-.23.219-.476.686-.54.231-.03.87-.055 1.593.29l.284-1.368a4.213 4.213 0 0 0-1.512-.286c-1.597 0-2.721.876-2.73 2.131Zm6.972-2.013a.737.737 0 0 0-.688.473l-2.424 5.975h1.696l.337-.963h2.073l.196.963h1.494L22.03 6.785h-1.38Zm.237 1.742.49 2.421h-1.341l.851-2.421Zm-9.265-1.742-1.337 6.448H11.9l1.336-6.448h-1.615Zm-2.391 0-1.682 4.389-.68-3.732c-.08-.417-.396-.657-.746-.657h-2.75l-.039.187c.565.126 1.206.33 1.595.548.238.134.306.25.384.567L6.6 13.233h1.708l2.619-6.448H9.23Z"
        fill="#fff"
      />
    </svg>
  );
}
