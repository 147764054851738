import type { ColumnDef, Row } from '@tanstack/react-table';
import { type IntlFormatters } from 'react-intl';
import { FormattedMessage } from 'react-intl';
import { type Transaction } from 'qonto/react/graphql';
import { type LabelList } from 'qonto/react/models/label';
import type AbilitiesService from 'ember-can/services/abilities';
import { DateCell } from '../../table-v2/cells/date-cell/date-cell';
import { AmountCell } from '../../table-v2/cells/amount-cell';
import { CaptionCell } from '../../table-v2/cells/caption-cell';
import { StatusCell } from './cells/status-cell';
import { TransactionCell } from './cells/transaction-cell';
import { AttachmentCell } from './cells/attachment-cell';
import { CategoryCell } from './cells/category-cell';
import { LabelCell } from './cells/label-cell';
import { PaymentMethodCell } from './cells/payment-method';
import { BulkCheckboxCell, BulkCheckboxHeader } from './cells/bulk-checkbox';
import { CommentsCell } from './cells/comments-cell';
import { MembershipCell } from './cells/membership-cell';
import { VerificationStatusCell } from './cells/verification-status-cell';

export const generateColumns = (
  labelLists: LabelList[],
  formatMessage: IntlFormatters['formatMessage'],
  abilities: AbilitiesService
): { columns: ColumnDef<Transaction>[]; defaultColumnOrder: string[] } => {
  const { labelColumnsIds, labelColumns } = createLabelColumns(labelLists, formatMessage);

  const staticColumns = getStaticColumns(formatMessage, abilities);

  const aggregatedColumns: ColumnDef<Transaction>[] = [...staticColumns, ...labelColumns];

  const defaultColumnOrder: string[] = [
    'transactions',
    'amount',
    'paymentMethod',
    'operationDate',
    'comments',
    'attachments',
    'membership',
    'bkVerificationStatus',
    'status',
    'category',
    'reference',
    ...labelColumnsIds,
    // 'settlementDate',
    // 'source',
    // 'settledBalance',
    // 'target',
  ];

  return {
    columns: !labelLists.length ? staticColumns : aggregatedColumns,
    defaultColumnOrder,
  };
};

const createLabelColumns = (
  labelLists: LabelList[],
  formatMessage: IntlFormatters['formatMessage']
): {
  labelColumnsIds: string[];
  labelColumns: ColumnDef<Transaction>[];
} => {
  const labelColumnsIds: string[] = [];

  const labelColumns = labelLists.map(labelList => {
    // Tanstack table breaks with ids containing '-'
    const listId = labelList.id.replaceAll('-', '_');
    labelColumnsIds.push(listId);

    return {
      id: listId,
      accessorKey: listId,
      header: formatMessage(
        { id: 'transactions.modular_table.labels.header' },
        { label_category_name: labelList.name }
      ),
      cell: ({ row }: { row: Row<Transaction> }) => {
        const matchingTransactionLabel = row.original.labels.find(
          label => label.listId === labelList.id
        );

        return <LabelCell label={matchingTransactionLabel} labelList={labelList} />;
      },
      size: 160,
    };
  });

  return {
    labelColumnsIds,
    labelColumns,
  };
};

const getStaticColumns = (
  formatMessage: IntlFormatters['formatMessage'],
  abilities: AbilitiesService
): ColumnDef<Transaction>[] => {
  const canViewVatBookkeeping = abilities.can('qualify for accounting transactions');

  const verificationColumn: ColumnDef<Transaction>[] = canViewVatBookkeeping
    ? [
        {
          id: 'bkVerificationStatus',
          accessorKey: 'bkVerificationStatus',
          header: formatMessage({ id: 'transactions.modular_table.verification.header' }),
          cell: ({ row }: { row: Row<Transaction> }) => (
            <VerificationStatusCell transaction={row.original} />
          ),
          size: 128,
        },
      ]
    : [];

  return [
    {
      id: 'transaction',
      accessorKey: 'transaction',
      header: ({ table }) => (
        <>
          <BulkCheckboxHeader table={table} />
          <span>
            <FormattedMessage id="transactions.modular_table.transactions.header" />
          </span>
        </>
      ),
      cell: ({ row }) => (
        <>
          <BulkCheckboxCell row={row} />
          <TransactionCell />
        </>
      ),
      size: 320,
    },
    ...verificationColumn,
    {
      id: 'operationDate',
      accessorKey: 'operationDate',
      header: formatMessage({ id: 'transactions.modular_table.operation_date.header' }),
      cell: () => <DateCell />,
      size: 128,
    },
    {
      id: 'amount',
      accessorKey: 'amount',
      header: formatMessage({ id: 'transactions.modular_table.amount.header' }),
      cell: () => <AmountCell />,
      size: 128,
    },
    {
      id: 'status',
      accessorKey: 'status',
      header: formatMessage({ id: 'transactions.modular_table.status.header' }),
      cell: ({ row }) => (
        <StatusCell status={row.original.status} statusHistory={row.original.statusHistory} />
      ),
      size: 128,
    },
    {
      id: 'paymentMethod',
      accessorKey: 'paymentMethod',
      header: formatMessage({ id: 'transactions.modular_table.payment_method.header' }),
      cell: () => <PaymentMethodCell />,
      size: 160,
    },
    {
      id: 'reference',
      accessorKey: 'reference',
      header: formatMessage({ id: 'transactions.modular_table.reference.header' }),
      cell: () => <CaptionCell />,
      size: 320,
    },
    {
      id: 'attachments',
      accessorKey: 'attachments',
      header: formatMessage({ id: 'transactions.modular_table.attachments.header' }),
      cell: () => <AttachmentCell />,
      size: 160,
    },
    {
      id: 'membership',
      accessorKey: 'membership',
      header: formatMessage({ id: 'transactions.modular_table.member.header' }),
      cell: ({ row }) => <MembershipCell transaction={row.original} />,
      size: 184,
    },
    {
      id: 'comments',
      accessorKey: 'comments',
      header: formatMessage({ id: 'transactions.modular_table.comments.header' }),
      cell: ({ row }) => <CommentsCell transaction={row.original} />,
      size: 104,
    },
    {
      id: 'category',
      accessorKey: 'category',
      header: formatMessage({ id: 'transactions.modular_table.category.header' }),
      cell: ({ row }) => (
        <CategoryCell
          category={row.original.activityTag?.toLowerCase()}
          transactionId={row.original.id}
        />
      ),
      size: 184,
    },
    // {
    //   id: 'source',
    //   accessorKey: 'source',
    //   header: formatMessage({ id: 'transactions.modular_table.debited_from.header' }),
    //   cell: ({ row }) => <SourceCell transaction={row.original} />,
    //   size: 215,
    // },
    // {
    //   id: 'target',
    //   accessorKey: 'target',
    //   header: formatMessage({ id: 'transactions.modular_table.credited_to.header' }),
    //   cell: ({ row }) => <TargetCell transaction={row.original} />,
    //   size: 215,
    // },
    // {
    //   id: 'settledBalance',
    //   accessorKey: 'settledBalance',
    //   header: formatMessage({ id: 'transactions.modular_table.settled_balance.header' }),
    //   cell: ({ row }) => <AmountCell amount={row.original.settledBalance} />,
    //   size: 200,
    // },
  ];
};
