export default {
  "members-loading": "csH",
  "members-loading--ff": "csO",
  "header": "csm",
  "header-search-bar": "csp",
  "body": "csx",
  "members": "csw",
  "placeholder-container": "csN",
  "icon": "csv",
  "details": "cso",
  "member-loading": "csn",
  "member-loading--no-ff": "csi",
  "body-members": "csA",
  "body-details": "csY"
};
