export default {
  "container": "qKQ",
  "content": "qKe",
  "logos": "qKB",
  "logo": "qKE",
  "animation": "qKd",
  "title": "qKI title-2 mb-32",
  "avatar": "qKt",
  "dropdown": "qKM",
  "disabled": "qKP",
  "organization": "qKl",
  "actions": "qKX"
};
