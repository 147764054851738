/* import __COLOCATED_TEMPLATE__ from './commercial.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface CommercialSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const Commercial = templateOnlyComponent<CommercialSignature>();

export default Commercial;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'HelpCenter::SelectedItem::Claim::Commercial': typeof Commercial;
  }
}
