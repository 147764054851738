import {
  FRENCH_EINVOICING_RECEIVE_INVOICES_STATUS,
  FRENCH_EINVOICING_STATUS_COLORS,
} from 'qonto/constants/french-einvoicing-settings';

type StatusKey = keyof typeof FRENCH_EINVOICING_STATUS_COLORS;

interface StatusTag {
  value: string | undefined;
  color: string | undefined;
  label: string | undefined;
}

export function getEinvoicingFrSettingsStatusTag(intl: any, status: StatusKey): StatusTag {
  switch (status) {
    case FRENCH_EINVOICING_RECEIVE_INVOICES_STATUS.disabled:
      return {
        value: status,
        color: FRENCH_EINVOICING_STATUS_COLORS[status],
        label: intl.t('settings.einvoicing.receive-status.status.disabled'),
      };
    case FRENCH_EINVOICING_RECEIVE_INVOICES_STATUS.no_qonto:
      return {
        value: status,
        color: FRENCH_EINVOICING_STATUS_COLORS[status],
        label: intl.t('settings.einvoicing.receive-status.status.disabled'),
      };
    case FRENCH_EINVOICING_RECEIVE_INVOICES_STATUS.pending_creation:
      return {
        value: status,
        color: FRENCH_EINVOICING_STATUS_COLORS[status],
        label: intl.t('settings.einvoicing.receive-status.status.in-progress'),
      };
    case FRENCH_EINVOICING_RECEIVE_INVOICES_STATUS.pending_deletion:
      return {
        value: status,
        color: FRENCH_EINVOICING_STATUS_COLORS[status],
        label: intl.t('settings.einvoicing.receive-status.status.in-progress'),
      };
    case FRENCH_EINVOICING_RECEIVE_INVOICES_STATUS.enabled:
      return {
        value: status,
        color: FRENCH_EINVOICING_STATUS_COLORS[status],
        label: intl.t('settings.einvoicing.receive-status.status.enabled'),
      };
    default:
      return {
        value: status,
        color: 'grey',
        label: intl.t('settings.einvoicing.receive-status.status.in-progress'),
      };
  }
}
