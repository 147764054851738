/* import __COLOCATED_TEMPLATE__ from './navigable.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';

import { dateToken } from '@qonto/ui-kit/utils/date-token';
// @ts-expect-error
import { scaleBand } from 'd3-scale';
import dayjs from 'dayjs';
import { restartableTask, timeout } from 'ember-concurrency';
// @ts-expect-error
import { variation } from 'ember-launch-darkly';
import { trackedTask } from 'ember-resources/util/ember-concurrency';

import { PERIOD_RATES } from 'qonto/react/models/cash-flow-period';
// @ts-expect-error
import { getExtrema } from 'qonto/utils/chart/scale';

import CashflowChartComponent from '../cashflow';

export default class CashflowNavigableChartComponent extends CashflowChartComponent {
  // @ts-expect-error
  @service segment;

  // @ts-expect-error
  tickFormatter = d => {
    // @ts-expect-error
    let frequency = this.args.selectedFrequency;

    if (frequency === PERIOD_RATES.YEARLY) {
      return `${dayjs(d).year()}`;
    }

    if (frequency === PERIOD_RATES.QUARTERLY) {
      return `Q${dayjs(d).quarter()} ${dayjs(d).year()}`;
    }

    return dateToken({ date: d, locale: this.intl.primaryLocale, token: 'month-year-s' });
  };

  get balanceTimeseries() {
    // @ts-expect-error
    return this.args.balanceTimeseries || this.timeseries;
  }

  get fullTimeseries() {
    // @ts-expect-error
    return this.args.fullTimeseries || this.timeseries;
  }

  get isNextDisabled() {
    if (variation('feature--boolean-cash-flow-forecast-slice-1')) {
      // @ts-expect-error
      return this.args.isLastPeriod;
    }
    return this.isCurrentPeriodDisplayed;
  }

  @action
  drawGraph() {
    /**
     * Core graph
     */
    this._setRenderer(this.chartContainer);
    // @ts-expect-error
    this.yScale = this._getYScale(this.fullTimeseries);
    // @ts-expect-error
    this.xScale = this._getXScale(this.timeseries);

    /**
     * Axis
     */
    // @ts-expect-error
    this._setYAxis(this.yScale);
    // @ts-expect-error
    this._setYGridlines(this.yScale);
    // @ts-expect-error
    this._setXAxis(this.xScale, this.tickFormatter);
    /**
     * Bars
     */
    // @ts-expect-error
    this._drawMonthContainers(this.timeseries, this.xScale, this.args.currentDateRelativeIndex);

    if (this.hasData) {
      // @ts-expect-error
      this._drawBars(this.timeseries, this.xScale, this.yScale);
      // @ts-expect-error
      this._drawBalance(this.balanceTimeseries, this.xScale, this.yScale);
    }

    this._linkFlowTooltips();
    this._linkMonthTooltips();

    this.isFirstDraw = false;
  }

  @action drawXAxis() {
    // @ts-expect-error
    this.chartRenderer.selectAll('.x-axis-tick').remove();
    // @ts-expect-error
    this._setXAxis(this.xScale, this.tickFormatter);
  }

  @action onPreviousMonth() {
    // @ts-expect-error
    this.args.onPreviousMonth?.();
    this.segment.track('cash-flow_combo-chart_next-period', { direction: 'past' });
    this.onResize();
  }

  @action onNextMonth() {
    // @ts-expect-error
    this.args.onNextMonth?.();
    this.segment.track('cash-flow_combo-chart_next-period', { direction: 'future' });
    this.onResize();
  }

  @action
  // @ts-expect-error
  _setMonthTooltip(target, { time, data, display }) {
    super._setMonthTooltip(target, { time, data, display });

    this.segment.track('cash-flow_chart_hover', {
      period: this.tickFormatter(time),
    });
  }

  get indexOffset() {
    this.onResize();
    // @ts-expect-error
    return this.args.offset;
  }

  _getYExtrema() {
    // Extract the relevant fields from the fullTimeseries
    let series = this.fullTimeseries
      .flatMap(({ data }: { data: any }) => [
        data.currentBalance,
        data.endingBalance,
        data.forecastedStartingBalance,
        data.forecastedEndingBalance,
        data.inflows,
        data.inflowsForecast,
        data.outflows,
        data.outflowsForecast,
      ])
      .filter((value: any) => typeof value === 'number'); // Ensure only numeric values are included

    // Calculate extrema
    return getExtrema(series, this.defaultYMax, this.headroom);
  }

  drawXAxisTask = restartableTask(async () => {
    await timeout(0);
    this.drawXAxis();
    // @ts-expect-error
    return this.args.selectedFrequency;
  });

  lastSelectedFrequency = trackedTask(this, this.drawXAxisTask, () => [
    // @ts-expect-error
    this.args.selectedFrequency,
  ]);

  // @ts-expect-error
  _getXScale(timeseries) {
    let leftPadding = this.chartSpacing.left;

    if (variation('feature--boolean-cash-flow-forecast-slice-1')) {
      let firstBandWidth = 260; // Fixed width for the first band
      let totalColumns = timeseries.length;

      // Calculate the available width for the remaining bands
      let remainingWidth = this.chartWidth - this.chartSpacing.left / 2 - firstBandWidth;
      let dynamicBandWidth = remainingWidth / (totalColumns - 1);

      // Adjust left padding to include chartSpacing.left
      leftPadding = firstBandWidth - dynamicBandWidth;
    }

    return (
      scaleBand()
        // @ts-expect-error
        .domain(timeseries.map(d => d.time))
        .range([leftPadding, this.chartWidth])
    );
  }
}
