/* import __COLOCATED_TEMPLATE__ from './default-trigger.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const DefaultTrigger = templateOnlyComponent<Signature>();

export default DefaultTrigger;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transfers::InternationalOut::DynamicForm::Field::Select::DefaultTrigger': typeof DefaultTrigger;
  }
}
