/* import __COLOCATED_TEMPLATE__ from './summary.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface SummarySignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const Summary = templateOnlyComponent<SummarySignature>();

export default Summary;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reimbursements::Bulk::Sidebar::Summary': typeof Summary;
  }
}
