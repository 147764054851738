export default {
  "header": "cXi",
  "center": "cXA",
  "header-placeholder": "cXY",
  "placeholder": "cXh",
  "name-block": "cXf",
  "block": "cXK",
  "detail-block": "cXG",
  "border-top": "cXr"
};
