export default {
  "wrapper": "cUB",
  "card-header": "cUE",
  "subtitle-caption": "cUd",
  "estimated-earnings": "cUI",
  "progress": "cUt",
  "progress-bar": "cUM",
  "progress-bar-text": "cUP",
  "subtitle": "cUl",
  "badge": "cUX",
  "core-information": "cUC",
  "secondary-information": "cUk",
  "remuneration-table": "cUJ",
  "name": "cUs title-3",
  "separator": "cUF",
  "remuneration-line": "cUg",
  "german-disclaimer": "cUD",
  "table": "cUV",
  "table-right": "cUL"
};
