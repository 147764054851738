export default {
  "animated": "Ga",
  "container": "GH",
  "cards-selectors": "GO",
  "card-selector": "Gm",
  "selector": "Gp",
  "header": "Gx",
  "footer": "Gw",
  "skip-cta": "GN",
  "pricing-caption": "Gv",
  "show": "Go",
  "selectorFadeIn": "Gn",
  "fadeIn": "Gi",
  "asset-one": "GA",
  "onePlusAssetFadeIn": "GY",
  "assetMoveUp": "Gh",
  "asset-plus": "Gf",
  "asset-x": "GK",
  "xAssetFadein": "GG",
  "feature": "Gr",
  "highlighted": "Gb"
};
