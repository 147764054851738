import Route from '@ember/routing/route';
import { service } from '@ember/service';
import { waitFor } from '@ember/test-waiters';

import * as Sentry from '@sentry/ember';
import { dropTask } from 'ember-concurrency';

import { ErrorInfo } from 'qonto/utils/error-info';

export default class CardAcquirerPaymentsRoute extends Route {
  @service abilities;
  @service homePage;
  @service organizationManager;
  @service router;
  @service sentry;
  @service store;

  queryParams = {
    page: {
      defaultValue: 1,
      refreshModel: true,
    },
    per_page: {
      defaultValue: 25,
      refreshModel: true,
    },
    status: {
      defaultValue: '',
      refreshModel: true,
    },
  };

  beforeModel(transition) {
    Sentry.getCurrentScope().setTag('CFT', 'cards');
    if (this.abilities.cannot('read card-acquirer-payment')) {
      let queryParams = transition.to.queryParams;
      delete queryParams.query;
      this.homePage.visitDefaultPage({ queryParams });
    }
  }

  model(params) {
    this.fetchPaymentsTask.perform(params).catch(error => {
      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    });

    return { fetchPaymentsTask: this.fetchPaymentsTask };
  }

  async search(query) {
    let { organization } = this.organizationManager;

    let result = await this.store.adapterFor('card-acquirer-payment').search(query, {
      'X-Qonto-Organization-ID': organization.id,
    });
    return result;
  }

  fetchPaymentsTask = dropTask(
    waitFor(async params => {
      let { page, per_page, status } = params;

      page = page ?? 1;
      per_page = per_page ?? 25;
      status = status ?? '';

      let query = {
        pagination: {
          page,
          per_page,
        },
        filters: {
          status: status ? [status] : null,
        },
      };

      let { payments, meta } = await this.search(query);

      return { payments, meta };
    })
  );
}
