export default {
  "request-transfer-validation": "qRH",
  "account-selector": "qRO",
  "has-error": "qRm",
  "actions": "qRp",
  "action-btn": "qRx",
  "warnings": "qRw",
  "top-border": "qRN",
  "account-selector-subtitle": "qRv",
  "action-btn-right": "qRo",
  "funds-disclaimer": "qRn",
  "account-options": "qRi",
  "account-balance": "qRA",
  "request-multi-transfer-validation": "qRY",
  "wrapper": "qRh",
  "warning-message-row": "qRf",
  "multi-transfer-align": "qRK",
  "button-approve": "qRG",
  "button-approve-spinner": "qRr",
  "button-decline": "qRb"
};
