export default {
  "main": "RP",
  "spinner": "Rl",
  "error": "RX",
  "error-illustration": "RC",
  "wrapper": "Rk",
  "content": "RJ",
  "illustration": "Rs mb-32",
  "title": "RF mb-16 title-2",
  "subtitle": "Rg body-2"
};
