export default {
  "header": "cWK",
  "header-type": "cWG",
  "close": "cWr",
  "close-icon": "cWb",
  "header-infos": "cWy",
  "title": "cWU body-1",
  "declined": "cWj",
  "canceled": "cWW",
  "approved": "cWu",
  "pending": "cuS",
  "date": "cuc body-2",
  "container": "cuq",
  "picto": "cuZ",
  "picto-status": "cuR",
  "general": "cuQ",
  "amount": "cue",
  "counterparty": "cuB body-2",
  "initiator": "cuE",
  "initiator-avatar": "cud",
  "avatar-image": "cuI",
  "initiator-id": "cut",
  "name": "cuM body-2",
  "role": "cuP caption-bold",
  "infobox": "cul",
  "account-infobox": "cuX cul",
  "beneficiary-warning": "cuC caption-bold",
  "dates-wrapper": "cuk",
  "dates": "cuJ",
  "recurrence": "cus caption-bold",
  "arrow": "cuF",
  "notify-checkbox": "cug"
};
