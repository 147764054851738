export default {
  "wrapper": "vf",
  "legend": "vK",
  "operation-code": "vG",
  "table": "vr",
  "footer": "vb",
  "footer-detraction": "vy",
  "footer-total-g": "vU",
  "footer-total-h": "vj",
  "footer-net-g-h": "vW"
};
