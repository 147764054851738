export default {
  "row": "oy",
  "row-active": "oU",
  "cell": "oj body-2",
  "terminated": "oW",
  "amount": "ou body-1",
  "item-name-cell": "nS",
  "status-icon": "nc",
  "item-name-container": "nq",
  "item-info": "nZ",
  "name": "nR body-1",
  "status-displayed": "nQ caption"
};
