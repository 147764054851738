export default {
  "wrapper": "clI",
  "mobile-mode": "clt",
  "logo": "clM",
  "address-form": "clP",
  "button-container": "cll",
  "address-form-header": "clX",
  "address-form-content": "clC",
  "address-form-address-search": "clk",
  "ember-basic-dropdown": "clJ",
  "address-form-footer": "cls",
  "submit-cta": "clF",
  "form": "clg",
  "city-section": "clD",
  "title": "clV",
  "mobile-title": "clL"
};
