import React, { type ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { BadgeStatus, Disclaimer } from '@repo/design-system-kit';
import { CockpitTile } from 'qonto/react/components/financing/pay-later/cockpit/insights/cockpit-tile';
import type { Amount } from 'qonto/react/models/amount';
import styles from './styles.strict-module.css';

interface SubtitleProps {
  isContractOnHold: boolean;
  isNoMoreEligible: boolean;
  creditLimitAmount: Amount;
}

function Subtitle({
  isContractOnHold,
  isNoMoreEligible,
  creditLimitAmount,
}: SubtitleProps): ReactNode {
  const intl = useIntl();

  if (isNoMoreEligible) {
    return (
      <span className={styles.subtitle} data-test-cockpit-tile-subtitle="">
        <FormattedMessage id="financing.pay-later.cockpit.insights.available-credit.no-more-eligible.subtitle" />
      </span>
    );
  }

  if (isContractOnHold) {
    return (
      <Disclaimer.Inline data-test-cockpit-tile-subtitle="" level="info">
        <FormattedMessage id="financing.pay-later.cockpit.insights.available-credit.on-hold.subtitle" />
      </Disclaimer.Inline>
    );
  }

  return (
    <span className={styles.subtitle} data-test-cockpit-tile-subtitle="">
      <FormattedMessage
        id="financing.pay-later.cockpit.insights.available-credit.subtitle"
        values={{
          amount: intl.formatNumber(creditLimitAmount.value, {
            style: 'currency',
            currency: creditLimitAmount.currency,
          }),
        }}
      />
    </span>
  );
}

export interface AvailableCreditTileProps {
  availableCreditAmount: Amount;
  creditLimitAmount: Amount;
  isContractOnHold?: boolean;
  isNoMoreEligible?: boolean;
}

export function AvailableCreditTile({
  availableCreditAmount,
  creditLimitAmount,
  isContractOnHold = false,
  isNoMoreEligible = false,
}: AvailableCreditTileProps): ReactNode {
  const intl = useIntl();
  const amount = isContractOnHold ? creditLimitAmount : availableCreditAmount;
  const shouldShowBadge = isContractOnHold && !isNoMoreEligible;

  return (
    <CockpitTile
      amount={amount}
      badge={
        shouldShowBadge ? (
          <BadgeStatus
            data-test-cockpit-tile-badge
            level="waiting"
            text={intl.formatMessage({
              id: 'financing.pay-later.cockpit.insights.available-credit.on-hold.badge-text',
            })}
          />
        ) : undefined
      }
      dataTestId="available-credit-tile"
      strikeThrough={isNoMoreEligible}
      subtitle={
        <Subtitle
          creditLimitAmount={creditLimitAmount}
          data-test-cockpit-tile-subtitle=""
          isContractOnHold={isContractOnHold}
          isNoMoreEligible={isNoMoreEligible}
        />
      }
      title={intl.formatMessage({
        id: 'financing.pay-later.cockpit.insights.available-credit.label',
      })}
    />
  );
}
