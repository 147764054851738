/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
import Component from '@glimmer/component';

import { PaymentMethodTitle } from '@repo/domain-kit/invoices';

import { PAYMENT_LINK_STATUS_ICONS } from 'qonto/constants/payment-links';

interface PaymentLinksSidebarHeaderSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class PaymentLinksSidebarHeader extends Component<PaymentLinksSidebarHeaderSignature> {
  paymentMethodTitle = PaymentMethodTitle;

  get avatarIcon() {
    // @ts-expect-error
    return PAYMENT_LINK_STATUS_ICONS[this.args.paymentLink.status];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'PaymentLinks::Sidebar::Header': typeof PaymentLinksSidebarHeader;
  }
}
