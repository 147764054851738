export default {
  "header-cell": "qqM",
  "header-content": "qqP caption-bold",
  "active": "qql",
  "empty": "qqX",
  "request-type": "qqC",
  "status": "qqk",
  "note": "qqJ",
  "amount": "qqs"
};
