export default {
  "row": "qml",
  "counterparty-name": "qmX",
  "counterparty-status": "qmC",
  "amount": "qmk",
  "next-date": "qmJ",
  "end-date": "qms",
  "frequency": "qmF",
  "active": "qmg",
  "cell": "qmD body-2",
  "no-padding": "qmV",
  "cell-content": "qmL",
  "counterparty": "qmT",
  "status-avatar": "qmz",
  "beneficiary-name": "qma",
  "empty": "qmH",
  "disabled": "qmO",
  "fadein-item": "qmm"
};
