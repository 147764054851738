export default {
  "custom-period-wrapper": "cLE",
  "fadeIn": "cLd",
  "custom-period-description": "cLI body-2",
  "header": "cLt",
  "arrow-left": "cLM",
  "body": "cLP",
  "period-wrapper": "cLl",
  "back-button": "cLX",
  "footer": "cLC"
};
