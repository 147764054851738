export default {
  "header-cell": "cCM",
  "empty": "cCP",
  "header-content": "cCl caption-bold",
  "active": "cCX",
  "align-right": "cCC",
  "first-col": "cCk",
  "mid-col": "cCJ",
  "status-col": "cCs"
};
