export default {
  "row": "YE",
  "cell": "Yd",
  "cell-content": "YI",
  "supplier": "Yt",
  "next-installment": "YM",
  "installment-amount": "YP",
  "total-repayment": "Yl",
  "cell-details": "YX",
  "align-right": "YC",
  "empty": "Yk"
};
