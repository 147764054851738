export default {
  "attachment-viewer": "cGi",
  "hide-sidebar": "cGA",
  "sidebar": "cGY",
  "header": "cGh",
  "header-top": "cGf",
  "header-icon": "cGK",
  "header-amount": "cGG",
  "infobox": "cGr",
  "vat": "cGb",
  "validation": "cGy"
};
