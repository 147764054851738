/* import __COLOCATED_TEMPLATE__ from './related-deposit.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface RelatedDepositSignature {
  Element: HTMLFieldSetElement;
  Args: {};
  Blocks: {
    default: [];
  };
}

const RelatedDeposit = templateOnlyComponent<RelatedDepositSignature>();

export default RelatedDeposit;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ReceivableInvoices::Form::RelatedDeposit': typeof RelatedDeposit;
  }
}
