/* import __COLOCATED_TEMPLATE__ from './mandate-select.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface MandateSelectSignature {
  Element: HTMLDivElement;
  Args: {};
  Blocks: {
    default: [];
  };
}

const MandateSelect = templateOnlyComponent<MandateSelectSignature>();

export default MandateSelect;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'PowerSelect::Mandates::MandateSelect': typeof MandateSelect;
  }
}
