export default {
  "row": "qdI",
  "disabled": "qdt",
  "cell": "qdM body-2",
  "cell-link": "qdP",
  "cell-content": "qdl",
  "item-truncate-text": "qdX",
  "item-name-cell": "qdC",
  "item-account-cell": "qdk",
  "icon": "qdJ",
  "icon-download": "qds"
};
