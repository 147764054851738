export default {
  "container": "qfU",
  "content": "qfj",
  "logos": "qfW",
  "logo": "qfu",
  "animation": "qKS",
  "title": "qKc title-2 mb-32",
  "avatar": "qKq",
  "actions": "qKZ mb-32",
  "footer": "qKR body-2"
};
