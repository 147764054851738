export default {
  "modal": "qsD",
  "section": "qsV",
  "header": "qsL",
  "title": "qsT",
  "icon": "qsz",
  "content": "qsa",
  "export-template-wrapper": "qsH",
  "export-template-radio": "qsO",
  "export-template-content": "qsm",
  "export-template-label": "qsp",
  "export-template-button": "qsx",
  "export-template-list": "qsw",
  "inactive": "qsN",
  "export-template-title": "qsv",
  "export-template-description": "qso",
  "export-template-item-content": "qsn",
  "permission-disclaimer-container": "qsi",
  "export-template-footer": "qsA",
  "export-template-cta-container": "qsY",
  "upsell": "qsh",
  "upsell-section": "qsf",
  "_content": "qsK",
  "_cta": "qsG",
  "upsell-title": "qsr",
  "upsell-description": "qsb"
};
