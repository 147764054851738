/* import __COLOCATED_TEMPLATE__ from './select-amount.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { AmountField, Disclaimer } from '@repo/design-system-kit';

const MIN_AMOUNT_REQUIRED = 50;
const MAX_AMOUNT_REQUIRED = 3600;
const FORMAT_MONEY_PARAMS = {
  minimumFractionDigits: 0,
  currency: 'EUR',
  style: 'currency',
};

interface SelectAmountSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLElement;
}

export default class SelectAmountComponent extends Component<SelectAmountSignature> {
  disclaimerInline = Disclaimer.Inline;
  amountField = AmountField;

  @service declare intl: Services['intl'];
  @service declare segment: Services['segment'];
  @service declare subscriptionManager: Services['subscriptionManager'];

  // @ts-expect-error
  @tracked amount;
  // @ts-expect-error
  @tracked errorMessage;
  // @ts-expect-error
  @tracked warningMessage;

  constructor(owner: unknown, args: SelectAmountSignature['Args']) {
    super(owner, args);
    this.updateAmount(this.recommendedPrice);
  }

  get recommendedPrice() {
    // @ts-expect-error
    return this.args.context.recommendedPrice;
  }

  get sortedPriceScale() {
    // @ts-expect-error
    return this.args.context.sortedPriceScale;
  }

  get scale() {
    // Filter out the options that are less than or equal to the recommended price.
    let filteredScale = this.sortedPriceScale.filter(
      // @ts-expect-error
      price => parseFloat(price) >= parseFloat(this.recommendedPrice)
    );

    // If only the highest option remains, don't show the scale.
    if (filteredScale.length <= 1) {
      return null;
    }

    return filteredScale;
  }

  get subtitle() {
    let limits = {
      // @ts-expect-error
      minimum: this.intl.formatNumber(MIN_AMOUNT_REQUIRED, FORMAT_MONEY_PARAMS),
      // @ts-expect-error
      maximum: this.intl.formatNumber(MAX_AMOUNT_REQUIRED, FORMAT_MONEY_PARAMS),
    };

    if (this.scale) {
      return this.intl.t('payment-activation-top-up.add-amount.subtitle-select', limits);
    }
    return this.intl.t('payment-activation-top-up.add-amount.subtitle-enter', limits);
  }

  @action
  // @ts-expect-error
  updateAmount(value) {
    this.amount = value;
    this.errorMessage = null;

    if (parseFloat(this.amount) < parseFloat(this.subscriptionManager.planPriceWithVAT.value)) {
      this.warningMessage = this.intl.t('payment-activation-top-up.warning.low-amount');
    } else {
      this.warningMessage = null;
    }
  }

  validateAmount() {
    if (parseFloat(this.amount) > MAX_AMOUNT_REQUIRED) {
      this.warningMessage = null;
      this.errorMessage = this.intl.t('payment-activation-top-up.errors.maximum', {
        // @ts-expect-error
        maximum: this.intl.formatNumber(MAX_AMOUNT_REQUIRED, FORMAT_MONEY_PARAMS),
      });
      return false;
    }
    if (parseFloat(this.amount) < MIN_AMOUNT_REQUIRED) {
      this.warningMessage = null;
      this.errorMessage = this.intl.t('payment-activation-top-up.errors.minimum', {
        // @ts-expect-error
        minimum: this.intl.formatNumber(MIN_AMOUNT_REQUIRED, FORMAT_MONEY_PARAMS),
      });
      return false;
    }
    return true;
  }

  @action
  // @ts-expect-error
  nextStep(event) {
    event.preventDefault();
    if (this.validateAmount()) {
      this.segment.track('onboarding_top_up_amount_filled', { origin: 'payment_activation' });
      // @ts-expect-error
      this.args.context.amount = this.amount;
      // @ts-expect-error
      this.args.transitionToNext();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::PaymentActivation::SelectAmount': typeof SelectAmountComponent;
  }
}
