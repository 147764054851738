export default {
  "wrapper": "QY",
  "core-information": "Qh",
  "iban": "Qf",
  "bic": "QK",
  "full-line": "QG",
  "secondary-information": "Qr",
  "title": "Qb",
  "rib": "Qy",
  "rib-copy": "QU",
  "flex-column": "Qj",
  "label": "QW body-1",
  "value": "Qu body-2",
  "right-spaced": "eS",
  "name": "ec title-3",
  "disclaimer": "eq"
};
