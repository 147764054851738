/* import __COLOCATED_TEMPLATE__ from './copy-button.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask } from 'ember-concurrency';

interface CopyButtonSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLButtonElement;
}

export default class CopyButton extends Component<CopyButtonSignature> {
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare clipboardManager: Services['clipboardManager'];

  copyTask = dropTask(async () => {
    // @ts-expect-error
    let { clipboardText, error, success, successLabel } = this.args;

    await this.clipboardManager.copyTask.perform({
      clipboardText,
      onError: error,
      onSuccess: success,
      successLabel,
    });
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    CopyButton: typeof CopyButton;
  }
}
