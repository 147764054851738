export default {
  "bank-account-option": "qkO",
  "row": "qkm",
  "active": "qkp",
  "animated": "qkx",
  "fadein-item": "qkw",
  "cell": "qkN body-2",
  "cell-content": "qkv",
  "subtitle": "qko caption",
  "no-padding": "qkn",
  "empty": "qki",
  "note": "qkA",
  "cell-content-amount": "qkY body-1",
  "cell-content-receipt": "qkh body-1",
  "cell-quick-actions": "qkf",
  "account-selector": "qkK",
  "cell-type": "qkG"
};
