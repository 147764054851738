/* import __COLOCATED_TEMPLATE__ from './period-limit.hbs'; */
import { action } from '@ember/object';
import { inject } from '@ember/service';
import Component from '@glimmer/component';

import { parseDate } from '@internationalized/date';
import { DatePicker } from '@repo/design-system-kit';
import dayjs from 'dayjs';

// @ts-expect-error
import { DATE_PICKER_FIELD_FORMAT } from 'qonto/constants/dates';

interface CardSettingsPeriodLimitSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLFieldSetElement;
}

export default class CardSettingsPeriodLimitComponent extends Component<CardSettingsPeriodLimitSignature> {
  // @ts-expect-error
  @inject intl;

  datePicker = DatePicker;

  // @ts-expect-error
  #parseDate(date) {
    let parsedDate = dayjs(date);
    return parsedDate.isValid() ? parseDate(parsedDate.format(DATE_PICKER_FIELD_FORMAT)) : null;
  }

  get dateValue() {
    // @ts-expect-error
    return this.#parseDate(this.args.value);
  }

  get minDate() {
    // @ts-expect-error
    return this.#parseDate(this.args.minDate);
  }

  get maxDate() {
    // @ts-expect-error
    return this.#parseDate(this.args.maxDate);
  }

  get formattedValue() {
    return this.dateValue?.toString();
  }

  @action
  // @ts-expect-error
  onChange(value) {
    // @ts-expect-error
    this.args.onChange(value);
  }

  @action
  // @ts-expect-error
  onChipChange(value) {
    // @ts-expect-error
    this.args.onChange(this.#parseDate(value));
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Card::Settings::PeriodLimit': typeof CardSettingsPeriodLimitComponent;
  }
}
