/* import __COLOCATED_TEMPLATE__ from './other-transaction.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { parseDate } from '@internationalized/date';
import { DatePicker } from '@repo/design-system-kit';
import dayjs from 'dayjs';
import { task } from 'ember-concurrency';

// @ts-expect-error
import { DATE_PICKER_FIELD_FORMAT } from 'qonto/constants/dates';

interface MatchInvoiceFlowMatchingOtherTransactionSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class MatchInvoiceFlowMatchingOtherTransactionComponent extends Component<MatchInvoiceFlowMatchingOtherTransactionSignature> {
  @service declare modals: Services['modals'];
  @service declare intl: Services['intl'];
  @service declare segment: Services['segment'];

  datePicker = DatePicker;

  @tracked datePickerValue = null;
  // @ts-expect-error
  @tracked error;

  isDirty = false;

  get isValid() {
    return !this.error;
  }

  get dateString() {
    return this.datePickerValue ? parseDate(this.datePickerValue) : null;
  }

  get maxDateString() {
    return parseDate(dayjs().format(DATE_PICKER_FIELD_FORMAT));
  }

  get invoice() {
    // @ts-expect-error
    return this.args.context.invoice;
  }

  @action
  // @ts-expect-error
  updatePaidAtDate(paidAt) {
    // @ts-expect-error
    if (this.datePickerValue?.toString() === paidAt?.toString()) {
      return;
    }

    this.segment.track('receivable_invoice_paid_on_edited');
    this.datePickerValue = paidAt?.toString();
    this.isDirty = true;
    this._validateInput();
  }

  markAsPaidWithoutAttachmentTask = task(this, { drop: true }, async () => {
    this._validateInput();

    if (this.isValid) {
      try {
        await this.invoice.markAsPaid(this.datePickerValue);
        this.segment.track('receivable_invoice_paid_on_confirmed');
        // @ts-expect-error
        this.args.context.hasMarkedWithoutTransaction = true;
        // @ts-expect-error
        this.args.transitionToNext();
      } catch {
        this._handleError();
      }
    }
  });

  onCloseTask = task(this, { drop: true }, async () => {
    this.segment.track('receivable_invoice_transaction_list_dropped', {
      origin: 'payment_date_cancel_btn',
    });
    if (this.isDirty) {
      let result = await this.modals.open('popup/confirmation', {
        title: this.intl.t(
          'receivable-invoices.mark-as-paid.without-transaction-step.exit-modal.title'
        ),
        description: this.intl.t(
          'receivable-invoices.mark-as-paid.without-transaction-step.exit-modal.description'
        ),
        confirm: this.intl.t(
          'receivable-invoices.mark-as-paid.without-transaction-step.exit-modal.cta.leave'
        ),
        cancel: this.intl.t('btn.back'),
        confirmTask: this.modalConfirmTask,
      });

      // @ts-expect-error
      if (result === 'cancel' || result === 'close') {
        this.segment.track('receivable_invoice_transaction_list_stay');
      }
    } else {
      // @ts-expect-error
      this.args.abort();
    }
  });

  modalConfirmTask = task(this, { drop: true }, async close => {
    // @ts-expect-error
    this.args.context.abortOrigin = 'confirmation_modal';
    // @ts-expect-error
    this.args.abort();
    await close();
  });

  _validateInput() {
    if (!this.datePickerValue) {
      this.error = this.intl.t(
        'receivable-invoices.mark-as-paid.without-transaction-step.payment-date-field.errors.required'
      );
      return;
    }

    this.error = null;
  }

  _handleError() {
    // @ts-expect-error
    this.args.context.abortOrigin = 'error';
    // @ts-expect-error
    this.args.abort();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::MatchInvoice::OtherTransaction': typeof MatchInvoiceFlowMatchingOtherTransactionComponent;
  }
}
