export default {
  "credit-note-sidebar": "cxj",
  "body": "cxW",
  "box": "cxu",
  "box-header": "cwS",
  "footer": "cwc",
  "footer-button": "cwq",
  "sidebar-box": "cwZ",
  "border-top": "cwR",
  "row": "cwQ",
  "related-invoice-title": "cwe caption-bold",
  "einvoice-rejected": "cwB",
  "related-documents": "cwE"
};
