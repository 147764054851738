export default {
  "payment-details": "cvv",
  "label-payment-conditions": "cvo",
  "dropdown-content": "cvn",
  "dropdown-option": "cvi",
  "activate-sdd-container": "cvA",
  "activate-sdd-header": "cvY",
  "selector-toggle-sdd": "cvh",
  "selector-toggle-sdd-subtitle": "cvf",
  "selector-toggle-content": "cvK",
  "selector-toggle": "cvG",
  "new-badge": "cvr",
  "new-badge-activation": "cvb",
  "mt-16": "cvy",
  "sdd-mandate-select-loading": "cvU"
};
