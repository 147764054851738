/* import __COLOCATED_TEMPLATE__ from './dummy-data.hbs'; */
import Component from '@glimmer/component';

import { SearchFieldWithDebounce } from '@repo/design-system-kit';

// @ts-expect-error
import { CODES } from 'qonto/constants/empty-states/system';
import { BASE_INSIGHTS } from 'qonto/constants/supplier-invoice';
import { SupplierInvoicesTabs } from 'qonto/react/components/supplier-invoices/tabs';

interface EmptyStatesSupplierInvoicesDummyDataSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class EmptyStatesSupplierInvoicesDummyDataComponent extends Component<EmptyStatesSupplierInvoicesDummyDataSignature> {
  SupplierInvoicesTabs = SupplierInvoicesTabs;
  SearchBar = SearchFieldWithDebounce;
  sortBy = 'due_date:asc';
  noop() {}

  fakeSuppliers = [{ id: 'id' }];

  get enableAllTabs() {
    // @ts-expect-error
    return [CODES.ES_U1, CODES.ES_T1].includes(this.args.emptyStateCode);
  }

  get BASE_INSIGHTS() {
    return BASE_INSIGHTS;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'EmptyStates::SupplierInvoices::DummyData': typeof EmptyStatesSupplierInvoicesDummyDataComponent;
  }
}
