export default {
  "header-cell": "cKf",
  "empty": "cKK",
  "header-bulk-checkbox": "cKG",
  "header-type": "cKr",
  "header-requester": "cKb",
  "header-date": "cKy",
  "header-amount": "cKU",
  "header-approval": "cKj",
  "header-content": "cKW caption-bold",
  "active": "cKu",
  "header-receipt": "cGS",
  "row-compact": "cGc",
  "row-sidebar": "cGq",
  "hidden": "cGZ",
  "animated": "cGR",
  "fadein-item": "cGQ",
  "animated-cell": "cGe",
  "checkbox": "cGB"
};
