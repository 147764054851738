export default {
  "container": "qUs",
  "wrapper": "qUF",
  "grey-header-block": "qUg",
  "connect-app-header": "qUD",
  "connect-app-header-link": "qUV",
  "connect-disclaimer-wrapper": "qUL",
  "connect-app-header-content": "qUT",
  "connect-app-header-cta": "qUz",
  "connect-app-content": "qUa",
  "connect-app-description": "qUH",
  "connect-app-body": "qUO"
};
