export default {
  "wrapper": "cQH",
  "confirm": "cQO",
  "plan-info": "cQm",
  "plan-info-left": "cQp",
  "info-title": "cQx body-1",
  "initial-trial-content": "cQw",
  "extra-fees-item": "cQN body-2",
  "extra-fees-container": "cQv",
  "bottom-wrapper": "cQo",
  "total-price-container": "cQn",
  "extra-fees-element": "cQi body-1",
  "border": "cQA",
  "subtitle": "cQY body-2",
  "group-item": "cQh",
  "amount": "cQf",
  "subscription-billing-summary": "cQK",
  "summary": "cQG",
  "summary-list": "cQr",
  "disclaimer": "cQb",
  "disclaimer-revamp": "cQy",
  "error": "cQU",
  "error-link": "cQj",
  "warning-wrapper": "cQW",
  "warning-label": "cQu body-1"
};
