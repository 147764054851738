/* import __COLOCATED_TEMPLATE__ from './ar-promo-card.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

// @ts-expect-error
import { variation } from 'ember-launch-darkly';

import { STATUS } from 'qonto/constants/receivable-invoice';

interface TransactionsSiderbarHeaderArPromoCardSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class TransactionsSiderbarHeaderArPromoCard extends Component<TransactionsSiderbarHeaderArPromoCardSignature> {
  @service declare segment: Services['segment'];
  @service declare router: Services['router'];
  @service declare flowLinkManager: Services['flowLinkManager'];

  constructor(owner: unknown, args: TransactionsSiderbarHeaderArPromoCardSignature['Args']) {
    super(owner, args);
    // @ts-expect-error
    if (variation('feature--ar-deposit-invoices') && this.args.isDepositTransaction) {
      this.segment.track('ar-discoverability_banner_displayed', {
        type: 'tab',
        message: 'deposit',
      });
    } else {
      this.segment.track('ar-discoverability_banner_displayed', { type: 'tab' });
    }
  }

  @action
  onClick() {
    // @ts-expect-error
    if (variation('feature--ar-deposit-invoices') && this.args.isDepositTransaction) {
      this.segment.track('ar-discoverability_banner_clicked', { type: 'tab', message: 'deposit' });
      this.flowLinkManager.transitionTo({
        name: 'account-receivable-onboarding',
        stepId: 'initial',
      });
    } else {
      this.segment.track('ar-discoverability_banner_clicked', { type: 'tab' });
      this.router.transitionTo('receivable-invoices.index', {
        queryParams: {
          status: STATUS.DRAFT,
        },
      });
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transactions::Sidebar::Header::ArPromoCard': typeof TransactionsSiderbarHeaderArPromoCard;
  }
}
