export default {
  "voucher-wrapper": "cBs",
  "left-side": "cBF",
  "left-side__wrapper": "cBg",
  "voucher-illustration": "cBD",
  "left-side__wrapper-title": "cBV",
  "voucher-list": "cBL",
  "left-side__wrapper-details": "cBT",
  "right-side": "cBz",
  "multi-accounts": "cBa",
  "right-side__wrapper": "cBH"
};
