/* import __COLOCATED_TEMPLATE__ from './application-cta.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { Spinner } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';

import {
  CONNECT_FEATURE_LEVELS,
  GMI_SOLUTION_ID_PREFIX,
  INTEGRATION_SOURCES,
} from 'qonto/constants/connect';
// @ts-expect-error
import { GMI_STATUSES_CONNECTING_STATUSES } from 'qonto/constants/gmi-solution-instance';
// @ts-expect-error
import { HRIS_CONNECT_FEATURE } from 'qonto/constants/hris-integration';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

interface ApplicationCtaSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class ApplicationCta extends Component<ApplicationCtaSignature> {
  @service declare abilities: Services['abilities'];
  @service declare flowLinkManager: Services['flowLinkManager'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare modals: Services['modals'];
  @service declare segment: Services['segment'];
  @service declare router: Services['router'];
  @service declare subscriptionManager: Services['subscriptionManager'];
  @service declare intl: Services['intl'];
  @service declare sentry: Services['sentry'];
  @service declare store: Services['store'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare userManager: Services['userManager'];
  @service declare upsellManager: Services['upsellManager'];

  spinner = Spinner;

  get isExternalBank() {
    // @ts-expect-error
    return Boolean(this.args.application.bank_id);
  }

  get isIntegration() {
    // @ts-expect-error
    let { integration_type, tray_solution_id, kombo_connect_provider_name } = this.args.application;

    return (
      this.isExternalBank ||
      integration_type === 'internal_integration' ||
      tray_solution_id ||
      kombo_connect_provider_name
    );
  }

  get isWaitlisted() {
    // @ts-expect-error
    let { tray_solution_id, waitlisted } = this.args.application;

    // Reverted logic for GMI to whitelist the integration
    if (tray_solution_id?.startsWith(GMI_SOLUTION_ID_PREFIX)) {
      return !waitlisted;
    }

    return waitlisted;
  }

  get isGMIConnecting() {
    // @ts-expect-error
    let { tray_solution_id } = this.args.application;

    return (
      this.store
        .peekAll('gmi-solution-instance')
        // @ts-expect-error
        .some(
          // @ts-expect-error
          ({ solutionId, gmiStatus, status }) =>
            solutionId === tray_solution_id &&
            GMI_STATUSES_CONNECTING_STATUSES.includes(gmiStatus) &&
            status === 'created'
        )
    );
  }

  get canActivateIntegration() {
    if (this.isKomboConnectButCantUse) {
      return false;
    }

    // @ts-expect-error
    switch (this.args.application.minimum_integration_level) {
      case CONNECT_FEATURE_LEVELS.silver:
        return this.abilities.can('access silver connect');
      case CONNECT_FEATURE_LEVELS.gold:
        return this.abilities.can('access gold connect');
      case CONNECT_FEATURE_LEVELS.accounts_payable:
        return this.abilities.can('access accounts payable connect');
      case CONNECT_FEATURE_LEVELS.accounts_receivable:
        return this.abilities.can('access accounts receivable connect');
      default:
        return true;
    }
  }

  get isKomboConnectButCantUse() {
    return (
      // @ts-expect-error
      this.args.application.kombo_connect_provider_name &&
      this.abilities.cannot('use hris-integration')
    );
  }

  get upsellTrigger() {
    if (this.isKomboConnectButCantUse) return HRIS_CONNECT_FEATURE;
    // @ts-expect-error
    return this.args.application.minimum_integration_level;
  }

  get hasAvailableFreeTrial() {
    // @ts-expect-error
    let minimumIntegrationLevel = this.args.application?.minimum_integration_level;
    let hasAvailableFreeTrial = this.upsellManager.hasAvailableFreeTrial(minimumIntegrationLevel);

    return Boolean(minimumIntegrationLevel) && hasAvailableFreeTrial;
  }

  get externalAppURL() {
    let {
      external_app_link: { url: baseUrl },
      send_organization_id,
      // @ts-expect-error
    } = this.args.application;

    if (!baseUrl) return;

    let url = new URL(baseUrl);

    if (send_organization_id) {
      url.searchParams.set('organization_id', this.organizationManager.organization.id);
    }

    return url.toString();
  }

  get isICISource() {
    // @ts-expect-error
    return this.args.source === INTEGRATION_SOURCES.ICI;
  }

  @action uninstallApplication() {
    // @ts-expect-error
    if (this.args.trackCta) {
      // @ts-expect-error
      this.args.trackCta('disconnect');
    }
    // @ts-expect-error
    this.args.uninstallApplication();
  }

  // @ts-expect-error
  @action installApplication(applicationSlug) {
    // @ts-expect-error
    if (this.args.trackCta) {
      // @ts-expect-error
      this.args.trackCta('connect');
    }
    // @ts-expect-error
    this.args.installApplication(applicationSlug);
  }

  onUpsellClickTask = dropTask(async ({ queryParams }) => {
    // @ts-expect-error
    let featureCode = this.args.application.minimum_integration_level;

    await this.subscriptionManager.upgradeWithRecommendation(featureCode, {
      queryParams,
    });
  });

  @action
  // @ts-expect-error
  joinWaitlist(application) {
    // @ts-expect-error
    let { source } = this.args;
    let { name: integrationName, slug: integrationSlug } = application;
    let {
      // @ts-expect-error
      currentUser: { firstName, email },
    } = this.userManager;
    let {
      organization: { id: organizationID },
    } = this.organizationManager;

    this.segment.track('gmi_integration_waitlist_joined', {
      organizationID,
      firstName,
      email,
      integrationName,
      integrationSlug,
      source,
    });

    return this.modals.open('connect/modals/join-waitlist');
  }

  @action
  onUpsellClick() {
    // @ts-expect-error
    if (this.args.trackCta) {
      // @ts-expect-error
      this.args.trackCta('upsell');
    }

    // @ts-expect-error
    if (this.args.trialInfo) {
      this.modals.open('discover/trial/confirm', {
        isFullScreenModal: true,
        // @ts-expect-error
        trialInfo: this.args.trialInfo,
      });
    } else {
      this.onUpsellClickTask
        .perform({
          queryParams: {
            upsellTrigger: this.upsellTrigger,
          },
        })
        .catch(ignoreCancelation)
        .catch(error => {
          this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
          if (ErrorInfo.for(error).shouldSendToSentry) {
            this.sentry.captureException(error);
          }
        });
    }
  }

  @action
  trackRedirect() {
    // @ts-expect-error
    if (this.args.trackCta) {
      // @ts-expect-error
      this.args.trackCta('redirect');
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Connect::ApplicationCta': typeof ApplicationCta;
  }
}
