/* import __COLOCATED_TEMPLATE__ from './table.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

interface RequestTableSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class RequestTable extends Component<RequestTableSignature> {
  @service declare segment: Services['segment'];

  @action
  trackEmptyState() {
    this.segment.track('request_empty_state_displayed', {
      // @ts-expect-error
      original_price_plan_code: this.args.organization?.originalPricePlanCode,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Request::Table': typeof RequestTable;
  }
}
