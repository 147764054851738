/* import __COLOCATED_TEMPLATE__ from './payment-lifespan-limit.hbs'; */
import Component from '@glimmer/component';

interface CardSidebarPaymentLifespanLimitSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class CardSidebarPaymentLifespanLimit extends Component<CardSidebarPaymentLifespanLimitSignature> {
  get availableBudget() {
    // @ts-expect-error
    let { card } = this.args;
    return card.paymentLifespanLimit - card.paymentLifespanSpent;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'CardSidebar::PaymentLifespanLimit': typeof CardSidebarPaymentLifespanLimit;
  }
}
