export default {
  "row": "qG body-1",
  "active": "qr",
  "cell": "qb",
  "empty": "qy",
  "ellipsis": "qU",
  "text-secondary": "qj",
  "row-sidebar": "qW",
  "cell-conditions": "qu",
  "animated": "ZS",
  "fadein-item": "Zc",
  "animated-cell": "Zq",
  "pr-24": "ZZ",
  "rule-with-error": "ZR",
  "icon": "ZQ"
};
