/* import __COLOCATED_TEMPLATE__ from './check.hbs'; */
import Component from '@glimmer/component';

import { Amount } from 'qonto/react/components/transactions/sidebar/header/amount/amount';
import { Counterparty } from 'qonto/react/components/transactions/sidebar/header/counterparty';
import { Date } from 'qonto/react/components/transactions/sidebar/header/date';
import { Type } from 'qonto/react/components/transactions/sidebar/header/type';
// @ts-expect-error
import cmc7Splitter from 'qonto/utils/cmc7-splitter';

interface TransactionsSidebarHeaderCheckSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class TransactionsSidebarHeaderCheck extends Component<TransactionsSidebarHeaderCheckSignature> {
  amount = Amount;
  Counterparty = Counterparty;
  Date = Date;
  type = Type;

  get checkCmc7() {
    // @ts-expect-error
    return cmc7Splitter(this.args.highlightedItem.subject.get('cmc7'), ' ');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transactions::Sidebar::Header::Check': typeof TransactionsSidebarHeaderCheck;
  }
}
