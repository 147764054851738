export default {
  "infobox": "sS",
  "account-infobox": "sc sS",
  "check-sidebar-header": "sq",
  "type": "sZ",
  "validated": "sR",
  "pending": "sQ",
  "received": "se",
  "canceled": "sB",
  "declined": "sE",
  "refunded": "sd",
  "date": "sI",
  "close": "st",
  "column-layout": "sM",
  "container": "sP",
  "picto": "sl",
  "avatar": "sX",
  "status": "sC",
  "general": "sk",
  "amount": "sJ",
  "counterparty": "ss"
};
