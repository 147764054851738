/* import __COLOCATED_TEMPLATE__ from './bulk-cta.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface BulkCtaSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const BulkCta = templateOnlyComponent<BulkCtaSignature>();

export default BulkCta;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reimbursements::Bulk::Sidebar::Footer::BulkCta': typeof BulkCta;
  }
}
