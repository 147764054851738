export default {
  "transaction": "qFA",
  "transaction-details": "qFY",
  "transaction-details-amount": "qFh body-1",
  "credit": "qFf",
  "negative-amount": "qFK",
  "transaction-details-description": "qFG body-2",
  "transaction-remove-cta": "qFr",
  "avatar": "qFb mr-16",
  "spinner": "qFy"
};
