/* import __COLOCATED_TEMPLATE__ from './archive-popup.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask } from 'ember-concurrency';
// @ts-expect-error
import { variation } from 'ember-launch-darkly';

import { apiBaseURL, supplierInvoiceNamespace } from 'qonto/constants/hosts';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';

interface ArchivePopupSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class ArchivePopup extends Component<ArchivePopupSignature> {
  @service declare intl: Services['intl'];
  @service declare networkManager: Services['networkManager'];
  @service declare router: Services['router'];
  @service declare sentry: Services['sentry'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare supplierInvoicesInsights: Services['supplierInvoicesInsights'];

  get showCreditNoteText() {
    // @ts-expect-error
    return this.args.data?.isCreditNote && variation('feature--boolean-ap-credit-notes');
  }

  get titleTextPopup() {
    return this.showCreditNoteText
      ? this.intl.t('supplier-invoices.archive-popup.credit-note.title')
      : this.intl.t('supplier-invoices.archive-popup.title');
  }

  get subtitleTextPopup() {
    return this.showCreditNoteText
      ? this.intl.t('supplier-invoices.archive-popup.credit-note.subtitle')
      : this.intl.t('supplier-invoices.archive-popup.subtitle');
  }

  get confirmButtonTextPopup() {
    return this.showCreditNoteText
      ? this.intl.t('supplier-invoices.archive-popup.credit-note.cta')
      : this.intl.t('supplier-invoices.archive-popup.confirm');
  }

  archiveTask = dropTask(async close => {
    // @ts-expect-error
    let { id } = this.args.data;
    try {
      await this.networkManager.request(
        `${apiBaseURL}/${supplierInvoiceNamespace}/supplier_invoices/bulk_archive`,
        {
          method: 'POST',
          body: JSON.stringify({
            supplier_invoice_ids: [id],
          }),
        }
      );

      this.supplierInvoicesInsights.fetchInsights().catch(() => {});

      this.toastFlashMessages.toastSuccess(this.intl.t('supplier-invoices.archive.toast.success'));
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.generic'));
    } finally {
      this.router.transitionTo('supplier-invoices');
      close();
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'SupplierInvoices::ArchivePopup': typeof ArchivePopup;
  }
}
