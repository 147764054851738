/* import __COLOCATED_TEMPLATE__ from './success.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { SubscriptionSuccess } from '@repo/domain-kit/pricing';

interface SubscriptionChangeSuccessSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class SubscriptionChangeSuccessComponent extends Component<SubscriptionChangeSuccessSignature> {
  @service declare segment: Services['segment'];
  subscriptionSuccess = SubscriptionSuccess;

  constructor(owner: unknown, args: SubscriptionChangeSuccessSignature['Args']) {
    super(owner, args);
    // @ts-expect-error
    this.segment.track('checkout_confirmation_success', this.args.context.tracking);
  }

  @action
  onClick() {
    // @ts-expect-error
    this.args.transitionToNext();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::AddonChange::Success': typeof SubscriptionChangeSuccessComponent;
  }
}
