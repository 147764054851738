import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { assert } from '@ember/debug';
import { dependentKeyCompat } from '@ember/object/compat';
import { camelize } from '@ember/string';

import dayjs from 'dayjs';

// @ts-expect-error
import { EXERCISE_KINDS } from 'qonto/constants/budget';
// @ts-expect-error
import { DATE_PICKER_FIELD_FORMAT } from 'qonto/constants/dates';
// @ts-expect-error
import { differenceInCalendar } from 'qonto/utils/date';
// @ts-expect-error
import ExerciseValidations from 'qonto/validations/exercise';

function startAndEndDatesAreEqual(
  // @ts-expect-error
  { startDate, endDate },
  // @ts-expect-error
  { startDate: startDateB, endDate: endDateB }
) {
  return startDate === startDateB && endDate === endDateB;
}

// @ts-expect-error
export default class ExerciseModel extends Model.extend(ExerciseValidations) {
  /** @type {string} YYYY-MM-DD */
  // @ts-expect-error
  @attr startDate;
  /** @type {string} YYYY-MM-DD */
  // @ts-expect-error
  @attr endDate;

  // @ts-expect-error
  @belongsTo('budget', { async: false, inverse: 'exercises' }) budget;
  // @ts-expect-error
  @hasMany('period', { async: false, inverse: 'exercise' }) periods;

  // @ts-expect-error
  addError(pointer, code) {
    let [, , attrOrRel, index, ...subPointer] = pointer.split('/');
    if (attrOrRel === 'periods') {
      this.periods.at(index).errors.add(camelize(subPointer.at(0)), code);
    } else {
      this.errors.add(camelize(attrOrRel || ''), code);
    }
  }

  @dependentKeyCompat
  get totalBudgeted() {
    // @ts-expect-error
    return this.periods.reduce((total, period) => {
      if (period.isDeleted) return total;
      return total + (parseFloat(period.amountBudgetedValue) || 0);
    }, 0);
  }

  get isGlobal() {
    return this.budget.isGlobal;
  }

  get isStarted() {
    return !dayjs().startOf('day').isBefore(this.startDate);
  }

  get isDeleteable() {
    // @ts-expect-error
    let existingExercises = this.budget.exercises.filter(({ id }) => Boolean(id));
    return !this.isStarted && !this.isNew && existingExercises.length > 1;
  }

  toggleExerciseKind() {
    assert('Cannot toggle exercise kind on a persisted budget', this.budget.isNew);
    this.budget.exerciseKind = this.isGlobal ? EXERCISE_KINDS.MONTHLY : EXERCISE_KINDS.GLOBAL;
    [...this.periods].forEach(p => p.unloadRecord());
    this.initPeriods();
  }

  updateStartAndEndDates({ startDate = this.startDate, endDate = this.endDate }) {
    if (startAndEndDatesAreEqual({ startDate, endDate }, this)) return;

    if (this.isGlobal) {
      this.periods[0].startDate = startDate;
      this.periods[0].endDate = endDate;
    } else {
      // for the earliest startDate and latest endDate
      // look at each date
      // is it a period we want to remove, keep, or add?
      let rangeStartDate = dayjs(startDate).isBefore(this.startDate) ? startDate : this.startDate;
      let rangeEndDate = dayjs(endDate).isAfter(this.endDate) ? endDate : this.endDate;
      for (let possiblePeriod of this.periodDates(rangeStartDate, rangeEndDate)) {
        let period = this.periods.find(this.#withRange(possiblePeriod));
        if (
          // @ts-expect-error
          dayjs(possiblePeriod.startDate).isSameOrAfter(startDate) &&
          // @ts-expect-error
          dayjs(possiblePeriod.endDate).isSameOrBefore(endDate)
        ) {
          if (!period) {
            this.store.createRecord('period', {
              exercise: this,
              endDate: possiblePeriod.endDate,
              startDate: possiblePeriod.startDate,
            });
          } else if (period.isDeleted) {
            period.rollbackAttributes();
          }
        } else if (period) {
          period.deleteRecord();
        }
      }
    }
    this.startDate = startDate;
    this.endDate = endDate;
  }

  // @ts-expect-error
  #withRange(periodA) {
    // @ts-expect-error
    return function startAndEndDatesAreEqualFnc(periodB) {
      return startAndEndDatesAreEqual(periodA, periodB);
    };
  }

  initPeriods() {
    if (this.isGlobal) {
      this.store.createRecord('period', {
        exercise: this,
        endDate: this.endDate,
        startDate: this.startDate,
      });
    } else {
      for (let { startDate, endDate } of this.periodDates(this.startDate, this.endDate)) {
        this.store.createRecord('period', {
          exercise: this,
          endDate,
          startDate,
          amountBudgeted: null,
        });
      }
    }
  }

  // @ts-expect-error
  *periodDates(rangeStartDate, rangeEndDate) {
    let months = differenceInCalendar(rangeEndDate, rangeStartDate, 'month');
    for (let month = 0; month <= months; month++) {
      let startDate = dayjs(rangeStartDate).add(month, 'month').format(DATE_PICKER_FIELD_FORMAT);
      yield {
        startDate,
        endDate: dayjs(startDate).endOf('month').format(DATE_PICKER_FIELD_FORMAT),
      };
    }
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    exercise: ExerciseModel;
  }
}
