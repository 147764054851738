/* import __COLOCATED_TEMPLATE__ from './edit-form.hbs'; */
import { assert } from '@ember/debug';
import { action } from '@ember/object';
import { next } from '@ember/runloop';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { AmountField, Disclaimer, Toggle } from '@repo/design-system-kit';
import dayjs from 'dayjs';
import { dropTask, waitForProperty } from 'ember-concurrency';

// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';

interface EditFormSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLFormElement;
}

export default class EditFormComponent extends Component<EditFormSignature> {
  amountField = AmountField;
  disclaimerBlock = Disclaimer.Block;
  toggle = Toggle;

  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare intl: Services['intl'];
  @service declare sentry: Services['sentry'];

  @tracked submitButtonIsDisabled = false;
  @tracked showErrors = false;

  constructor(owner: unknown, args: EditFormSignature['Args']) {
    super(owner, args);
    // @ts-expect-error
    assert('You must pass an @submitTask', this.args.submitTask);
    // @ts-expect-error
    assert('You must pass an @deleteTask', this.args.deleteTask);

    // @ts-expect-error
    this.locale = this.intl.primaryLocale;
  }

  get periods() {
    // @ts-expect-error
    return this.args.exercise.periods.filter(period => !period.isDeleted);
  }

  get globalAmountFieldErrorMessage() {
    // @ts-expect-error
    if (this.args.exercise.isGlobal && this.showErrors) {
      return this.intl.t('team-budgets.creation.customize.error.global');
    }
  }

  // @ts-expect-error
  @action shouldDisplayYear(period) {
    return (
      // @ts-expect-error
      !this.args.exercise.isGlobal &&
      (this.periods.indexOf(period) === 0 || dayjs(period.startDate).month() === 0)
    );
  }

  // @ts-expect-error
  @action getDisplayedYear(date) {
    return this.intl.formatDate(date, { year: 'numeric' });
  }

  @action
  // @ts-expect-error
  getIsDisabled(period) {
    return dayjs().isAfter(period.endDate);
  }

  @action
  // @ts-expect-error
  updateAmount(period, value) {
    this.submitButtonIsDisabled = false;
    next(() => {
      period.amountBudgetedValue = value;
    });
  }

  submitTask = dropTask(async () => {
    if (!this.submitButtonIsDisabled) {
      try {
        // @ts-expect-error
        await this.args.exercise.validate();
        // @ts-expect-error
        if (this.args.exercise.validations.isValid) {
          // @ts-expect-error
          await this.args.submitTask.perform();
        } else {
          // @ts-expect-error
          await this.handleError();
        }
      } catch (error) {
        await this.handleError(error);
      } finally {
        // @ts-expect-error
        this.showErrors = !this.args.exercise.validations.attrs.totalBudgeted.isValid;
      }
    }
  });

  // @ts-expect-error
  async handleError(error) {
    this.submitButtonIsDisabled = true;
    // any error that doesn't result in totatlBugeted validation failure isn't displayed in form so lets display a toast error.
    // @ts-expect-error
    await waitForProperty(this.args.exercise.validations, 'isValidating', false);

    // @ts-expect-error
    if (this.args.exercise.validations.attrs.totalBudgeted.isValid) {
      this.toastFlashMessages.toastError(this.intl.t('errors.unexpected_error_occured'));

      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Budget::EditForm': typeof EditFormComponent;
  }
}
