export default {
  "row": "cGE body-1",
  "active": "cGd",
  "cell": "cGI",
  "empty": "cGt",
  "ellipsis": "cGM",
  "text-secondary": "cGP",
  "transfer-type": "cGl",
  "transfer-type__icon": "cGX",
  "quick-actions": "cGC",
  "amount": "cGk",
  "cell-approve": "cGJ",
  "account-select": "cGs",
  "row-compact": "cGF",
  "cell-amount": "cGg",
  "cell-content-receipt": "cGD body-1",
  "cell-date": "cGV",
  "row-sidebar": "cGL",
  "hidden": "cGT",
  "animated": "cGz",
  "fadein-item": "cGa",
  "animated-cell": "cGH",
  "note": "cGO",
  "checkbox": "cGm"
};
