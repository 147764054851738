/* import __COLOCATED_TEMPLATE__ from './amount-field.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

interface AmountFieldSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class AmountField extends Component<AmountFieldSignature> {
  get updateFunction() {
    // fix to avoid the focusout event to be called without an update function in ember-amount-input
    // as it would still try to call the update function: "this.args.update is not a function"
    // @ts-expect-error
    return typeof this.args.onUpdate === 'function' ? this.args.onUpdate : this.defaultUpdate;
  }

  @action
  // @ts-expect-error
  defaultUpdate(value) {
    return value;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'F24::FormFields::AmountField': typeof AmountField;
  }
}
