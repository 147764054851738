export default {
  "item-status": "czE",
  "item-date": "czd",
  "item-amount": "czI body-1",
  "item-amount-credit": "czt",
  "row": "czM",
  "icon": "czP",
  "disabled": "czl",
  "icon-container": "czX",
  "text-container": "czC",
  "text-row": "czk",
  "item-details": "czJ",
  "counterparty-name": "czs",
  "column": "czF",
  "item-amount-disabled": "czg",
  "attachment-tooltip": "czD",
  "bank-account-avatar": "czV"
};
