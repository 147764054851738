export default {
  "item": "qPo",
  "checkbox": "qPn",
  "details": "qPi",
  "flex": "qPA",
  "header": "qPY qPA",
  "flex-no-gap": "qPh qPA",
  "file-name": "qPf",
  "label": "qPK body-2",
  "self-icon": "qPG",
  "einvoice-icon": "qPr",
  "type-value": "qPb",
  "with-separator": "qPy",
  "dimmed": "qPU"
};
