export default {
  "header-cell": "qp",
  "empty": "qx",
  "header-type": "qw",
  "header-rules": "qN",
  "header-date": "qv",
  "header-action": "qo",
  "header-content": "qn caption-bold",
  "active": "qi",
  "row-sidebar": "qA",
  "hidden": "qY",
  "animated": "qh",
  "fadein-item": "qf",
  "animated-cell": "qK"
};
