// @ts-nocheck
/* eslint-disable @qonto/no-import-roles-constants */
import Service, { service } from '@ember/service';

import { hasMFAError } from '@qonto/qonto-sca/utils/mfa-error';
import { dropTask } from 'ember-concurrency';

import { apiBaseURL, billingNamespace } from 'qonto/constants/hosts';
import { ROLES } from 'qonto/constants/membership';
import { SUBSCRIPTION_PRODUCT_TYPES } from 'qonto/constants/subscriptions';

export default class MemberManagerService extends Service {
  @service segment;
  @service sensitiveActions;
  @service organizationManager;
  @service modals;
  @service intl;
  @service subscriptionManager;
  @service networkManager;
  @service toastFlashMessages;
  @service sentry;

  handleSelectRoleTask = dropTask(
    async (dataContext, role, transitionToNext, pushFlow, trackerValue) => {
      if (!dataContext.isEditingMember) {
        return await this.handleSelectRoleInviteFlowTask.perform(
          dataContext,
          role,
          transitionToNext,
          pushFlow,
          trackerValue
        );
      }

      await this.handleSelectRoleEditMemberFlowTask.perform(
        dataContext,
        role,
        transitionToNext,
        pushFlow,
        trackerValue
      );
    }
  );

  addOnRedirectionTask = dropTask(async (dataContext, pushFlow, featureCode) => {
    try {
      let {
        recommended_recurrence: recurrence,
        recommended_product: { code, type },
      } = await this.networkManager.request(
        `${apiBaseURL}/${billingNamespace}/subscriptions/upgrade_recommendation?feature_code=${featureCode}`
      );

      dataContext.recurrence = recurrence;
      dataContext.addonCode = code;
      if (type === SUBSCRIPTION_PRODUCT_TYPES.ADDON) {
        pushFlow('addon-change', 'addons');
      } else if (type === SUBSCRIPTION_PRODUCT_TYPES.PLAN) {
        pushFlow('subscription-change', 'plans');
      }
    } catch (error) {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
      this.sentry.captureException(error);
    }
  });

  handleSelectRoleInviteFlowTask = dropTask(
    async (dataContext, role, transitionToNext, pushFlow, trackerValue) => {
      if (role.available) {
        dataContext.member.role = role.key;
        dataContext.role = role;

        if (role.key !== ROLES.MANAGER && role.key !== ROLES.ADVANCED_MANAGER) {
          dataContext.member.set('customPermissions', undefined);
          dataContext.member.set('monthlyTransferLimit', undefined);
          dataContext.member.set('perTransferLimit', undefined);
        }

        this.segment.track('team-invite_role-chosen_clicked', {
          role: role.key,
          origin: 'team_page',
        });

        dataContext.showComparison = false;

        await transitionToNext();
      } else {
        dataContext.upsellTrigger = [ROLES.ADMIN, ROLES.EMPLOYEE].includes(role.key)
          ? 'additionalUsers'
          : 'managerRole';

        if (role.key === ROLES.ADVANCED_MANAGER) {
          await this.addOnRedirectionTask.perform(
            dataContext,
            pushFlow,
            'advanced_manager_permission'
          );
        } else if (role.key === ROLES.MANAGER) {
          await this.addOnRedirectionTask.perform(dataContext, pushFlow, 'manager_role');
        } else {
          pushFlow('subscription-change', 'discover-teams');
        }
        this.segment.track('team_invite_upsell', {
          plan: dataContext.pricePlanCode,
          role: role.key === ROLES.ADVANCED_MANAGER ? 'manager' : role.key,
          page: trackerValue ? trackerValue : 'role-selection',
        });
      }
    }
  );

  handleSelectRoleEditMemberFlowTask = dropTask(
    async (dataContext, role, transitionToNext, pushFlow, trackerValue) => {
      if (role.available) {
        dataContext.role = role;

        if (role.key !== ROLES.MANAGER && role.key !== ROLES.ADVANCED_MANAGER) {
          dataContext.currentUserSIpermission =
            dataContext.member.customPermissions?.groups?.manage_supplier_invoices;
          dataContext.member.set('customPermissions', undefined);
        }

        if (role.key === ROLES.ADVANCED_MANAGER) {
          dataContext.member.role = ROLES.ADVANCED_MANAGER;
        }

        dataContext.showComparison = false;

        if (role.key === ROLES.ADMIN || role.key === ROLES.EMPLOYEE) {
          return this.sensitiveActions.runTask.perform(
            this.saveTask,
            dataContext,
            transitionToNext
          );
        }

        if (role.key === ROLES.REPORTING) {
          return this.modals.open('popup/confirmation', {
            title: this.intl.t('members.downgrade.title'),
            description: this.intl.t('members.downgrade.subtitle'),
            cancel: this.intl.t('btn.cancel'),
            confirm: this.intl.t('members.downgrade.cta'),
            confirmTask: this.handleConfirmSaveAccountantModalTask,
            dataContext,
            transitionToNext,
          });
        }

        await transitionToNext();
      } else {
        if (role.key === ROLES.ADVANCED_MANAGER) {
          await this.addOnRedirectionTask.perform(
            dataContext,
            pushFlow,
            'advanced_manager_permission'
          );
        } else if (role.key === ROLES.MANAGER) {
          await this.addOnRedirectionTask.perform(dataContext, pushFlow, 'manager_role');
        } else {
          pushFlow('subscription-change', 'discover-teams');
        }
        this.segment.track('team_invite_upsell', {
          plan: dataContext.pricePlanCode,
          role: role.key === ROLES.ADVANCED_MANAGER ? 'manager' : role.key,
          page: trackerValue ? trackerValue : 'role-selection',
        });
      }
    }
  );

  saveTask = dropTask(async (dataContext, transitionToNext) => {
    try {
      dataContext.member.role = dataContext.role.key;

      await dataContext.member.updateRole();

      this.segment.track('team_member_role_edited', {
        originalRole: dataContext.currentUserRole,
        newRole: dataContext.member.role,
      });

      if (dataContext.member.role === ROLES.MANAGER) {
        this.segment.track('member_permissions_update_confirmed', {
          flow: 'change-role-to-manager',
        });
      }

      if (dataContext.currentUserSIpermission) {
        this.segment.track('manager-invitation_supplier-invoices-permission_off');
      }

      transitionToNext();
    } catch (error) {
      dataContext.member.rollbackAttributes();

      if (hasMFAError(error?.errors)) {
        throw error;
      }
    }
  });

  handleConfirmSaveAccountantModalTask = dropTask(
    async (close, { dataContext, transitionToNext }) => {
      let { organization } = this.organizationManager;

      await this.sensitiveActions.runTask.perform(this.saveTask, dataContext, transitionToNext);

      await organization.reload();

      await close();
    }
  );
}

declare module '@ember/service' {
  interface Registry {
    'member-manager': MemberManagerService;
    memberManager: MemberManagerService;
  }
}
