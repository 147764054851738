export default {
  "wrapper": "vu",
  "subtitle": "oS body-2",
  "legend": "oc",
  "table": "oq",
  "footer": "oZ",
  "footer-total-c": "oR",
  "footer-total-d": "oQ",
  "footer-balance": "oe"
};
