/* import __COLOCATED_TEMPLATE__ from './payment-limits.hbs'; */
// @ts-nocheck
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

// @ts-expect-error
import { BADGE_TYPE } from '@qonto/ui-kit/constants/badge-types';
import { PromotionalCard } from '@repo/design-system-kit';

// @ts-expect-error
import { SHOW_BY_COOKIE_RULES } from 'qonto/components/show-by-cookie';
import { CARD_LEVELS_TRACKING, UPSELL_BANNERS_COOKIE_IDS } from 'qonto/constants/cards';
// @ts-expect-error
import { ORIGIN } from 'qonto/routes/flows/setup/cards/upsell';
import {
  getAvailableThisMonth,
  getAvailableToday,
  getPaymentMonthlyLimitsMaximum,
  isMonthlyLimitClose,
} from 'qonto/utils/cards/card-limits';

interface CardSidebarPaymentLimitsSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class CardSidebarPaymentLimits extends Component<CardSidebarPaymentLimitsSignature> {
  @service declare abilities: Services['abilities'];
  @service declare intl: Services['intl'];
  @service declare flowLinkManager: Services['flowLinkManager'];
  @service declare segment: Services['segment'];

  upsellBannerBadgeType = BADGE_TYPE.SAVE;
  upsellBannerCookieId = UPSELL_BANNERS_COOKIE_IDS.BANNER_PAYMENT;
  upsellBannerCookieRule = SHOW_BY_COOKIE_RULES.FIRST_OF_NEXT_MONTH;

  promotionalCard = PromotionalCard;

  get availableToday() {
    // @ts-expect-error
    let { card } = this.args;
    return getAvailableToday(
      card.paymentDailyLimit,
      card.paymentDailySpent,
      this.availableThisMonth
    );
  }

  get availableThisMonth() {
    // @ts-expect-error
    let { card } = this.args;
    return getAvailableThisMonth(card.paymentMonthlyLimit, card.paymentMonthlySpent);
  }

  get hasAdvertisingCardDisclaimer() {
    // @ts-expect-error
    let { card } = this.args;
    if (!card.isAdvertising) return false;

    return this.abilities.can('view payment limits disclaimer card', card);
  }

  get hasMetalCardDisclaimer() {
    // @ts-expect-error
    let { card } = this.args;
    // @ts-expect-error
    if (!card.isMetal || this.args.isScaEnabled) return false;

    return this.abilities.can('view payment limits disclaimer card', card);
  }

  get hasUpsellBanner() {
    // @ts-expect-error
    let { card, cardsLimits } = this.args;
    if (!cardsLimits) return false;

    return (
      this.abilities.can('upsell card', card) &&
      isMonthlyLimitClose(
        getPaymentMonthlyLimitsMaximum(card.cardLevel, cardsLimits),
        card.paymentMonthlySpent
      )
    );
  }

  get disclaimerText() {
    // @ts-expect-error
    return this.args.card.isOperational
      ? this.intl.t('cards.sidebar.disclaimer.payment-limits.extended.ready')
      : this.intl.t('cards.sidebar.disclaimer.payment-limits.extended.not-ready');
  }

  @action
  goToUpsellFlow() {
    this.segment.track('cards_upsell_opened', {
      // @ts-expect-error
      card_id: this.args.card.id,
      // @ts-expect-error
      card_level_origin: CARD_LEVELS_TRACKING[this.args.card.cardLevel],
      origin: 'side_panel',
      trigger: 'payment_limit',
    });

    // @ts-expect-error
    this.flowLinkManager.transitionTo({
      name: 'card-upsell',
      stepId: 'upsell',
      // @ts-expect-error
      queryParams: { cardId: this.args.card.id, origin: ORIGIN.PAYMENT_LIMITS },
    });
  }

  @action
  onUpsellBannerClose() {
    this.segment.track('cards_upsell_trigger_closed', {
      // @ts-expect-error
      card_level_origin: CARD_LEVELS_TRACKING[this.args.card.cardLevel],
      origin: 'side_panel',
      trigger: 'payment_limit',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'CardSidebar::PaymentLimits': typeof CardSidebarPaymentLimits;
  }
}
