import cx from 'clsx';
import type { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { dateToken } from '@qonto/ui-kit/utils/date-token';
import type { Check } from 'qonto/react/api/models/check';
import { CheckSidebarStep } from '../step';
import styles from './styles.strict-module.css';

interface CheckSidebarStatusProps {
  check: Check;
}

export function CheckSidebarStatus({ check }: CheckSidebarStatusProps): ReactNode {
  const { formatMessage } = useIntl();
  const featuresManager = useEmberService('featuresManager');

  const shippingAddress =
    check.shippingAddress ?? formatMessage({ id: 'checks.descriptions.pending_address' });
  const showDynamicDelay = featuresManager.isEnabled('checkAmountAndTimeImprovements') as boolean;

  return (
    <div className={styles.status}>
      <h2 className={cx('caption-bold', styles['status-title'])}>
        {formatMessage({ id: 'checks.sidebar.deposit_status' })}
      </h2>

      <CheckSidebarStep hasNextStep isComplete isNextStepComplete>
        <StepOne check={check} />
      </CheckSidebarStep>

      <CheckSidebarStep
        hasNextStep={check.isOtherThanNotReceived}
        isComplete
        isNextStepComplete={check.hasArrived}
      >
        <StepTwo check={check} shippingAddress={shippingAddress} />
      </CheckSidebarStep>

      {check.isOtherThanNotReceived ? (
        <>
          <CheckSidebarStep
            hasNextStep
            isComplete={check.hasArrived}
            isNextStepComplete={check.hasResponse}
          >
            <StepThree check={check} showDynamicDelay={showDynamicDelay} />
          </CheckSidebarStep>

          <CheckSidebarStep
            hasNextStep={false}
            isComplete={check.hasResponse}
            isNextStepComplete={false}
          >
            <StepFour check={check} />
          </CheckSidebarStep>
        </>
      ) : null}
    </div>
  );
}

interface StepProps {
  check: Check;
  shippingAddress?: string;
  showDynamicDelay?: boolean;
}

function StepOne({ check }: StepProps): ReactNode {
  const { formatMessage, locale } = useIntl();

  return (
    <StepTitle>
      {`${formatMessage({ id: 'checks.sidebar.requested_at' })} ${dateToken({
        date: check.createdAt,
        locale,
        token: 'date-year-s',
      })}`}
    </StepTitle>
  );
}

function StepTwo({ check, shippingAddress }: StepProps): ReactNode {
  const { formatMessage, locale } = useIntl();
  let title: ReactNode = null;
  let description: ReactNode = null;

  if (check.pending) {
    title = (
      <div data-test-check-status-label-second>
        {formatMessage({ id: 'checks.status.pending' })}
      </div>
    );
    description = (
      <div data-test-check-description>
        {check.isCreatedByUser ? (
          <>
            {formatMessage({ id: 'checks.descriptions.pending' })}
            <div className={styles.address} data-test-check-address>
              {shippingAddress}
            </div>
          </>
        ) : (
          formatMessage({ id: 'checks.descriptions.create_by_BO' })
        )}
      </div>
    );
  } else if (check.isDeclinedAndNotReceived) {
    title = (
      <>
        {`${formatMessage({ id: 'checks.sidebar.canceled_at' })} ${dateToken({
          date: check.declinedAt || check.canceledAt,
          locale,
          token: 'date-year-s',
        })}`}
      </>
    );
    description = formatMessage({ id: 'checks.descriptions.canceled' });
  } else {
    title = (
      <>
        {`${formatMessage({ id: 'checks.sidebar.received_at' })} ${dateToken({
          date: check.receivedAt,
          locale,
          token: 'date-year-s',
        })}`}
      </>
    );
  }

  return (
    <>
      <StepTitle>{title}</StepTitle>
      {description ? <StepDescription>{description}</StepDescription> : null}
    </>
  );
}

function StepThree({ check, showDynamicDelay }: StepProps): ReactNode {
  const { formatMessage } = useIntl();

  const title = check.hasArrived ? (
    <span>{formatMessage({ id: 'checks.sidebar.status.received' })}</span>
  ) : (
    <div className={cx('body-1', styles.blurred)} data-test-check-label-incomplete>
      {formatMessage({ id: 'checks.sidebar.status.received' })}
    </div>
  );

  const description = check.received ? (
    <span data-test-check-delay>
      {formatMessage({
        id: showDynamicDelay ? 'checks.descriptions.received' : 'checks.descriptions.received-old',
      })}
    </span>
  ) : null;

  return (
    <>
      <StepTitle>{title}</StepTitle>
      {description ? <StepDescription>{description}</StepDescription> : null}
    </>
  );
}

function StepFour({ check }: StepProps): ReactNode {
  const { formatMessage, locale } = useIntl();
  let title: ReactNode = null;
  let description: ReactNode = null;
  let dateInfo: ReactNode = null;

  if (check.hasResponse || check.canceled) {
    title = (
      <div className="body-1" data-test-check-status={check.validated ? 'validated' : check.status}>
        {formatMessage({ id: `checks.sidebar.${check.validated ? 'validated' : check.status}_at` })}
      </div>
    );

    let date;
    if (check.validated) {
      date = check.validatedAt;
    } else if (check.refunded) {
      date = check.refundedAt;
    } else if (check.declined) {
      date = check.declinedAt;
    }
    dateInfo = date ? (
      <div className={cx(styles.info, 'body-1')}>
        {dateToken({ date, locale, token: 'date-year-s' })}
      </div>
    ) : null;
  } else {
    title = (
      <div className={cx('body-1', styles.blurred)} data-test-check-label-incomplete>
        {formatMessage({ id: 'checks.sidebar.status.validated' })}
      </div>
    );
  }

  if (
    check.refunded ||
    check.canceled ||
    check.validated ||
    (check.declined && check.declinedReason !== 'already_issued')
  ) {
    if (check.validated) {
      description = formatMessage({ id: 'checks.descriptions.validated' });
    } else if (check.declinedReason) {
      description = formatMessage({ id: `checks.declined_reasons.${check.declinedReason}` });
    }
  }

  return (
    <>
      <StepTitle>
        {title} {dateInfo}
      </StepTitle>
      {description ? <StepDescription>{description}</StepDescription> : null}
    </>
  );
}

function StepTitle({ children }: { children: ReactNode }): ReactNode {
  return <h3 className={cx('body-1', styles.title)}>{children}</h3>;
}

function StepDescription({ children }: { children: ReactNode }): ReactNode {
  return <p className={cx('body-2 mb-32', styles.description)}>{children}</p>;
}
