export default {
  "cockpit-list": "zk",
  "cockpit-item": "zJ",
  "cockpit-item-tile": "zs",
  "guarding-title": "zF",
  "guarding-title-icon": "zg",
  "subtitle-link": "zD",
  "subtitle-wrapper": "zV",
  "subtitle-tooltip": "zL"
};
