import { type ReactElement } from 'react';
import { type IntlShape, useIntl } from 'react-intl';
import cx from 'clsx';
import { type DetailsItem, DetailsList } from 'qonto/react/components/sidebar/details-list';
import type { FinancingModel } from 'qonto/react/models/financing';
import { FINANCING_TRANSFER_STATUS } from 'qonto/constants/financing';
import { FinancingDeclinedDisclaimer } from './internal/financing-declined-disclaimer';

function getFinancingItems(intl: IntlShape, financing: FinancingModel): DetailsItem[] {
  const items: DetailsItem[] = [
    {
      label: intl.formatMessage({
        id: 'financing.pay-later.cockpit.details.data-list.financed-amount',
      }),
      text: intl.formatNumber(Number(financing.financedAmount.value), {
        style: 'currency',
        currency: financing.financedAmount.currency,
      }),
    },
    {
      label: intl.formatMessage({
        id: 'financing.pay-later.cockpit.details.data-list.interest',
      }),
      text: intl.formatNumber(Number(financing.interestAmount?.value), {
        style: 'currency',
        currency: financing.interestAmount?.currency,
      }),
    },
  ];

  if (financing.penaltyFeesAmount) {
    items.push({
      label: intl.formatMessage({
        id: 'financing.pay-later.cockpit.details.data-list.penalty-fees',
      }),
      text: intl.formatNumber(Number(financing.penaltyFeesAmount.value), {
        style: 'currency',
        currency: financing.penaltyFeesAmount.currency,
      }),
    });
  }
  items.push({
    label: intl.formatMessage({
      id: 'financing.pay-later.cockpit.details.data-list.started-at',
    }),
    text: intl.formatDate(financing.settledAt || financing.createdAt, {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
    }),
  });
  if (financing.completedAt) {
    items.push({
      label: intl.formatMessage({
        id: 'financing.pay-later.cockpit.details.data-list.completed-at',
      }),
      text: intl.formatDate(financing.completedAt, {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
      }),
    });
  }
  items.push({
    label: intl.formatMessage({
      id: 'financing.pay-later.cockpit.details.data-list.account',
    }),
    text: financing.bankAccountName,
  });

  return items;
}

interface FinancingDetailsProps {
  financing: FinancingModel;
}

export function FinancingDetails({ financing }: FinancingDetailsProps): ReactElement {
  const intl = useIntl();
  const isFinancingDeclined = financing.status === FINANCING_TRANSFER_STATUS.DECLINED;

  return (
    <>
      <div className={cx(isFinancingDeclined && 'mb-16')}>
        <DetailsList items={getFinancingItems(intl, financing)} />
      </div>
      {isFinancingDeclined ? <FinancingDeclinedDisclaimer /> : null}
    </>
  );
}
