/* import __COLOCATED_TEMPLATE__ from './item.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { Badge, Checkbox, Tag } from '@repo/design-system-kit';

// @ts-expect-error
import { DIRECT_DEBIT_COLLECTION_STATUS } from 'qonto/constants/direct-debit-collections';

interface TransactionsTableItemSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLTableRowElement;
}

export default class TransactionsTableItem extends Component<TransactionsTableItemSignature> {
  tag = Tag;
  checkbox = Checkbox;
  badge = Badge;

  @service declare intl: Services['intl'];
  @service declare userManager: Services['userManager'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare featuresManager: Services['featuresManager'];
  @service declare segment: Services['segment'];
  @service declare upsellManager: Services['upsellManager'];

  get sepaTransferUpsellRecommendation() {
    // @ts-expect-error
    return this.upsellManager.sepaTransferUpsellRecommendation(this.args.item);
  }
  get hasExternalAccounts() {
    return this.organizationManager.organization?.hasExternalAccounts;
  }

  get isAttachmentStatusHidden() {
    // @ts-expect-error
    let { item } = this.args;
    return item.status === 'declined' || item.operationMethod === 'biller' || item.isPagoPaPayment;
  }

  get isSelected() {
    // @ts-expect-error
    return this.args.selectedItems.includes(this.args.item);
  }

  get status() {
    let statuses = {
      completed: this.intl.t('transactions.status.completed'),
      pending: this.intl.t('transactions.status.pending'),
      canceled: this.intl.t('transactions.status.canceled'),
      declined: this.intl.t('transactions.status.declined'),
      reversed: this.intl.t('transactions.status.reversed'),
    };

    // @ts-expect-error
    let { item: transaction } = this.args;

    let transactionStatus =
      transaction.isCheckTransaction && !transaction.bankAccount.isExternalAccount
        ? transaction.subject.get('status')
        : transaction.status;

    // @ts-expect-error
    return transaction.canceled ? statuses.canceled : statuses[transactionStatus];
  }

  get shouldDisplayName() {
    // @ts-expect-error
    let { name: cardHolderName } = this.args.item.methodLabel;
    // @ts-expect-error
    let { fullName } = this.userManager.currentUser;
    let { membershipsCount } = this.organizationManager.organization;
    let { employee, owner } = this.organizationManager.membership;

    if (owner) {
      return true;
    }

    return membershipsCount > 1 ? cardHolderName !== fullName && !employee : false;
  }

  get attachmentStatus() {
    // @ts-expect-error
    let item = this.args.item;

    if (item.attachmentRequired) {
      if (item.attachmentLost) {
        return {
          status: 'lost',
          color: 'red',
          copy: this.intl.t('transactions.receipt.status.lost'),
        };
      }

      if (item.attachmentCount) {
        return {
          status: 'attached',
          color: 'green',
          copy: this.intl.t('transactions.receipt.status.attached'),
        };
      }

      return {
        status: 'missing',
        color: 'gray',
        copy: this.intl.t('transactions.receipt.status.missing'),
      };
    }

    return {
      status: 'notrequired',
      color: 'green',
      copy: this.intl.t('transactions.receipt.status.not-required'),
    };
  }

  get isDisabled() {
    // @ts-expect-error
    let { item } = this.args;

    if (item.subjectType === 'DirectDebitCollection' && !item.bankAccount.isExternalAccount) {
      return (
        item.status === DIRECT_DEBIT_COLLECTION_STATUS.CANCELED ||
        item.status === DIRECT_DEBIT_COLLECTION_STATUS.DECLINED
      );
    }

    return item.declined || item.reversed;
  }

  get isDisputingChargeback() {
    // @ts-expect-error
    let { item } = this.args;

    return item.chargebackDisputingTypes.length > 0;
  }

  @action
  // @ts-expect-error
  selectWithKeyboard(event) {
    event.stopPropagation();
    if (event.key === 'Enter') {
      // @ts-expect-error
      this.args.onSelectItem();
    }
  }

  @action
  rowClick() {
    // @ts-expect-error
    let { item } = this.args;
    let { bankAccount, subject } = item;

    this.segment.track('transaction_details_opened', {
      account_type: bankAccount?.accountType,
      account_bank_system: bankAccount?.coreBankingSystem,
      operation_type: subject?.get('operationType') || item.operationType,
      operation_subtype: subject?.get('operationSubtype'),
      ...(this.organizationManager.organization?.hasFeeUpsell
        ? { has_upsell_entrypoint: Boolean(this.sepaTransferUpsellRecommendation) }
        : {}),
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transactions::Table::Item': typeof TransactionsTableItem;
  }
}
