export default {
  "section-container": "cfv",
  "disclaimer-section": "cfo",
  "trip-title": "cfn caption-bold mb-16",
  "google-link-container": "cfi",
  "link-icon": "cfA",
  "trip-details": "cfY body-2",
  "trip-details-icon": "cfh",
  "trip-details-title": "cff",
  "subtitle": "cfK caption-bold mb-16",
  "disclaimer": "cfG",
  "visualizer": "cfr"
};
