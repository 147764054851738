export default {
  "header": "PW",
  "hidden": "Pu",
  "header-inline": "lS",
  "header-inline__search-bar": "lc",
  "header-bottom": "lq",
  "margin-top": "lZ",
  "header-bottom-loading": "lR",
  "header-bottom-loading-state": "lQ",
  "actions__filters": "le",
  "actions__filters__toggle-btn": "lB",
  "tabs": "lE",
  "header-inline__filters": "ld",
  "filter-ml": "lI",
  "filter-clear": "lt"
};
