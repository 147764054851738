/* import __COLOCATED_TEMPLATE__ from './insurance-links.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { CARD_LEVELS } from 'qonto/constants/cards';

interface InsuranceLinksSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class InsuranceLinksComponent extends Component<InsuranceLinksSignature> {
  @service declare intl: Services['intl'];

  get insuranceNotice() {
    // @ts-expect-error
    switch (this.args.cardLevel) {
      case CARD_LEVELS.METAL:
        return {
          cta: this.intl.t('insurance.notice.metal.download'),
          link: this.intl.t('insurance.notice.metal.download-link'),
        };
      case CARD_LEVELS.PLUS:
        return {
          cta: this.intl.t('insurance.notice.plus.download'),
          link: this.intl.t('insurance.notice.plus.download-link'),
        };
      default:
        return {
          cta: this.intl.t('insurance.notice.standard.download'),
          link: this.intl.t('insurance.notice.standard.download-link'),
        };
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InsuranceAssistance::InsuranceLinks': typeof InsuranceLinksComponent;
  }
}
