export const GENERIC_WARNINGS = {
  ALREADY_ISSUED: 'already_issued',
  INSUFFICIENT_FUNDS: 'insufficient_funds',
};

export const SPEND_LIMIT_WARNINGS = {
  INSUFFICIENT_MONTHLY_LIMIT: 'insufficient_monthly_limits',
  INSUFFICIENT_PER_TRANSFER_LIMIT: 'insufficient_per_transfer_limits',
};

export const CONFIRMATION_WARNINGS = {
  ...GENERIC_WARNINGS,
  ...SPEND_LIMIT_WARNINGS,
};
