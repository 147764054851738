/* import __COLOCATED_TEMPLATE__ from './swiper.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface SwiperSignature {
  Element: HTMLDivElement;
  Args: {};
  Blocks: {
    default: [];
  };
}

const Swiper = templateOnlyComponent<SwiperSignature>();

export default Swiper;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Discover::Overview::Swiper': typeof Swiper;
  }
}
