// order is important here
export const ROLES = {
  OWNER: 'owner',
  ADMIN: 'admin',
  MANAGER: 'manager',
  ADVANCED_MANAGER: 'advanced_manager',
  EMPLOYEE: 'employee',
  REPORTING: 'reporting',
};

export const GENDER = {
  FEMALE: 'female',
  MALE: 'male',
} as const;

/**
 * Backend cleanup task (includes also other cleanups not related to this object)
 * Link: https://www.notion.so/qonto/Decouple-Membership-and-Organization-domain-data-for-clients-14931ee4c69680eda748ce3a24342f86?pvs=4
 */
export const MEMBER_STATUS = {
  ACTIVE: 'active',
  REVOKED: 'revoked',
  INVITABLE: 'invitable',
  INVITED: 'invited',
  /**
   * Same as INVITED
   * @deprecated
   */
  PENDING: 'pending',
  /**
   * @deprecated
   */
  IGNORED: 'ignored',
  /**
   * @deprecated
   */
  DELETED: 'deleted',
  /**
   * @deprecated
   */
  DRAFT: 'draft',
};

export const KYC_STATUS = {
  PENDING: 'pending',
  REVIEWABLE: 'reviewable',
  SUBMITTED: 'submitted',
  ACCEPTED: 'accepted',
  REFUSED: 'refused',
  WAITING_DOCUMENT: 'waiting_document',
  SUBMISSION_ERROR: 'submission_error',
  KYC_NOT_APPLICABLE: 'kyc_not_applicable',
  PROCESSING: 'processing',
};

export const DEFAULT_CUSTOM_PERMISSIONS_TEAM = Object.freeze({
  create_cards: true,
  review_request_cards: true,
  create_transfers: false,
  access_direct_debits: false,
  access_checks: false,
  manage_memberships: false,
  manage_bookkeepings: false,
  manage_supplier_invoices: false,
});

export const DEFAULT_CUSTOM_PERMISSIONS_ORGANIZATION = Object.freeze({
  create_cards: true,
  review_request_cards: true,
  create_transfers: false,
  access_direct_debits: false,
  access_checks: false,
  manage_teams: false,
  access_bank_accounts_statements: false,
  manage_bookkeepings: false,
  manage_supplier_invoices: false,
});

export const INVITATION_TYPES = {
  STAFF: 'staff', // ADMIN, MANAGER, EMPLOYEE
  GUEST: 'guest', // REPORTING
};

export const INVITE_DISCOVERABILITY_STORAGE_KEY = 'invite-discoverability-viewed';

export const MEMBERSHIP_BASE_EXCLUDES = ['taxPayerOutsideLegalCountry', 'usTaxPayer'];

export const MEMBERSHIP_UBO_EXCLUDES = [
  'uboConnectionToOrganization',
  'uboConnectionToOrganizationRaw',
];
