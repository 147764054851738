import { action } from '@ember/object';
import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { variation } from 'ember-launch-darkly';

import { INDEX_ROUTE } from 'qonto/constants/insurance-hub';
export default class InsuranceHubProductRoute extends Route {
  @service organizationManager;
  @service prismic;
  @service router;
  @service store;
  @service insuranceManager;
  @service qontent;

  previousRoute;
  previousModels;

  async model({ uid }) {
    let product = await this.insuranceManager.getInsuranceProduct(uid);

    // feature--boolean-qontent-insurance-hub cleanup -> return product
    return {
      ...product,
      risks: product.risks?.map(item => item?.risk?.data.name || item),
      ctaLabel: product.ctaLabel || product.cta_label,
      ctaPath: product.ctaPath || product.cta_path?.url,
    };
  }

  @action
  error(error) {
    if (!variation('feature--boolean-qontent-insurance-hub')) {
      if (error.message === 'not-found') {
        this.router.replaceWith('/404');
      } else {
        return true;
      }
    } else {
      return !this.model.uid;
    }
  }

  @action
  loading(transition) {
    let previousRoute = transition.from;

    this.previousRoute = previousRoute?.name || INDEX_ROUTE;
    this.previousModels = [
      this.organizationManager.organization.slug,
      ...(previousRoute?.params.category_slug ? [previousRoute.params.category_slug] : []),
    ];

    return true;
  }

  setupController(controller, model) {
    super.setupController(controller, model);

    controller.set('previousRoute', this.previousRoute);
    controller.set('previousModels', this.previousModels);
  }
}
