export default {
  "y-axis-placeholder": "cVZ",
  "y-axis-navigable": "cVR",
  "y-axis-tick": "cVQ",
  "x-axis-placeholder": "cVe",
  "x-axis-navigable": "cVB",
  "x-axis-tick-wrapper": "cVE",
  "x-axis-tick": "cVd",
  "chart-loading-renderer": "cVI"
};
