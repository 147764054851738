export default {
  "header-cell": "qkR",
  "header-content": "qkQ caption-bold",
  "active": "qke",
  "empty": "qkB",
  "type": "qkE",
  "requester": "qkd",
  "request-date": "qkI",
  "amount": "qkt",
  "receipt": "qkM",
  "quick-actions": "qkP"
};
