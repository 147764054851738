/* import __COLOCATED_TEMPLATE__ from './status-box.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface StatusBoxSignature {
  Element: HTMLDivElement;
  Args: {};
  Blocks: {
    default: [];
  };
}

const StatusBox = templateOnlyComponent<StatusBoxSignature>();

export default StatusBox;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'F24::Sidebar::StatusBox': typeof StatusBox;
  }
}
