import Model, { attr, belongsTo } from '@ember-data/model';
import { service, type Registry as Services } from '@ember/service';

import {
  DIRECT_DEBIT_COLLECTION_TYPES,
  SDD_MANDATE_STATUS,
  // @ts-expect-error
} from 'qonto/constants/direct-debit-collections';
// @ts-expect-error
import { DIRECT_DEBIT_SUBSCRIPTION_SCHEDULE_TYPES } from 'qonto/constants/direct-debit-subscriptions';

export default class DirectDebitCollectionMandateModel extends Model {
  @service declare intl: Services['intl'];

  @attr('string') declare status: string;
  @attr('string') declare uniqueMandateReference: string;
  @attr('string') declare creditorSchemeId: string;
  @attr('date') declare mandateSignatureDate: Date;
  @attr('string', { defaultValue: DIRECT_DEBIT_COLLECTION_TYPES.CORE }) declare type: string;
  @attr('string', { defaultValue: 'recurring' }) declare recurrenceType: string;
  @attr('string') declare link: string;
  @attr('string') declare clientIban: string;
  @attr('string') declare clientIbanLastDigits: string;
  @attr('string') declare clientBic: string;
  // @ts-expect-error
  @attr('hash', {
    defaultValue: () => {
      return {};
    },
  })
  // @ts-expect-error
  clientSnapshot;
  // @ts-expect-error
  @attr('hash', {
    defaultValue: () => {
      return {};
    },
  })
  // @ts-expect-error
  organizationSnapshot;
  // @ts-expect-error
  @attr('hash', {
    defaultValue: () => {
      return {};
    },
  })
  // @ts-expect-error
  paymentInfo;

  // @ts-expect-error
  @belongsTo('customer', { async: false, inverse: null }) client;
  // @ts-expect-error
  @belongsTo('organization', { async: false, inverse: null }) organization;

  get isOneOff() {
    return this.paymentInfo.scheduleType === DIRECT_DEBIT_SUBSCRIPTION_SCHEDULE_TYPES.ONE_OFF;
  }

  get isRecurring() {
    return !this.isOneOff;
  }

  get isPending() {
    return this.status === SDD_MANDATE_STATUS.PENDING;
  }

  get isApproved() {
    return this.status === SDD_MANDATE_STATUS.APPROVED;
  }

  get isDeclined() {
    return this.status === SDD_MANDATE_STATUS.DECLINED;
  }

  get isCanceled() {
    return this.status === SDD_MANDATE_STATUS.CANCELED;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'direct-debit-collection-mandate': DirectDebitCollectionMandateModel;
  }
}
