import { type HTMLAttributes, type ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { Button, Tag } from '@repo/design-system-kit';
import cx from 'clsx';
import type { FRENCH_EINVOICING_RECEIVE_INVOICES_STATUS } from 'qonto/constants/french-einvoicing-settings';
import styles from './styles.strict-module.css';

type StatusOption = keyof typeof FRENCH_EINVOICING_RECEIVE_INVOICES_STATUS;

interface FrenchEinvoicingStatusProps extends HTMLAttributes<HTMLDivElement> {
  ctaClick?: () => void;
  ctaText?: string;
  ctaType?: 'primary' | 'tertiary';
  status: {
    value: StatusOption;
    color: 'gray' | 'red' | 'green';
    label: string;
  };
  subtitle: string;
  title: string;
}

export function FrenchEinvoicingStatus(props: FrenchEinvoicingStatusProps): ReactNode {
  const { formatMessage } = useIntl();

  const { className, ctaClick, ctaText, ctaType = 'primary', status, subtitle, title } = props;

  return (
    <div
      className={cx(styles.container, className)}
      {...props}
      data-testid="fr-einvicing-status-container"
    >
      <div
        className={styles['header-container']}
        data-testid="fr-einvicing-status-header-container"
      >
        <p className="body-1" data-testid="fr-einvicing-status-title">
          {title}
        </p>
        <Tag color={status.color} label={formatMessage({ id: status.label })} />
      </div>
      <div className={styles['body-container']} data-testid="fr-einvicing-status-body-container">
        <p className="body-2" data-testid="fr-einvicing-status-subtitle">
          {formatMessage({ id: subtitle })}
        </p>
        {ctaText && ctaClick ? (
          <Button data-testid="fr-einvicing-status-btn" onPress={ctaClick} variant={ctaType}>
            {ctaText}
          </Button>
        ) : null}
      </div>
    </div>
  );
}
