/* import __COLOCATED_TEMPLATE__ from './attachments-suggested.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import { waitFor } from '@ember/test-waiters';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import dayjs from 'dayjs';
import { all, dropTask } from 'ember-concurrency';
// @ts-expect-error
import { variation } from 'ember-launch-darkly';

import { apiBaseURL, supplierInvoiceNamespace } from 'qonto/constants/hosts';
import { INVOICE_STATUSES } from 'qonto/constants/supplier-invoice';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';

const INVOICE_ALREADY_MATCHED_ERROR_CODE = 'transaction_attachment_exists';

interface AttachmentsSuggestedSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class AttachmentsSuggestedComponent extends Component<AttachmentsSuggestedSignature> {
  @service declare intl: Services['intl'];
  @service declare store: Services['store'];
  @service declare segment: Services['segment'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare sentry: Services['sentry'];
  @service declare networkManager: Services['networkManager'];
  @service declare supplierInvoicesInsights: Services['supplierInvoicesInsights'];
  @service declare abilities: Services['abilities'];

  // @ts-expect-error
  @tracked selectedReceivableInvoiceId;
  // @ts-expect-error
  @tracked selectedSupplierInvoiceId;

  @tracked invoicesCurrentPage = 1;
  // @ts-expect-error
  @tracked invoicesTotalCount;
  // @ts-expect-error
  @tracked invoicesTotalPages;
  // @ts-expect-error
  @tracked invoicesPerPage;

  // @ts-expect-error
  @tracked selectedPeriod;

  @tracked invoicesSearchQuery = '';
  // @ts-expect-error
  @tracked dateFrom;
  // @ts-expect-error
  @tracked dateTo;
  @tracked receivableInvoicesStats = null;
  // @ts-expect-error
  @tracked issueDateQuery;
  // @ts-expect-error
  @tracked invoiceStatusQuery;

  constructor(owner: unknown, args: AttachmentsSuggestedSignature['Args']) {
    super(owner, args);
    /* eslint-disable ember-concurrency/no-perform-without-catch */
    this.fetchInvoicesTask.perform();
    if (
      this.abilities.can('access supplier-invoice') &&
      this.abilities.can('view supplier-invoice') &&
      this.abilities.can('read receivable-invoice')
    ) {
      this.fetchInvoicesStatsTask.perform();
    }
    /* eslint-enable ember-concurrency/no-perform-without-catch */
  }

  /**
   * @experimental
   * Do not reuse this getter. It is experimental and it is not tested
   */
  get showCreditNoteExperimentalSidebar() {
    // @ts-expect-error
    return variation('experiment--boolean-ap-credit-notes') && this.args.creditNote?.isCreditNote;
  }

  get showCreditNoteSidebar() {
    // @ts-expect-error
    return variation('feature--boolean-ap-credit-notes') && this.args.creditNote?.isCreditNote;
  }

  fetchInvoicesStatsTask = dropTask(async () => {
    try {
      await this.supplierInvoicesInsights.fetchInsights();
      this.receivableInvoicesStats = await this.store
        .modelFor('receivable-invoice')
        // @ts-expect-error
        .getStats(this.store);
    } catch (error) {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.generic'));
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  });

  get hasInvoices() {
    // @ts-expect-error
    if (this.isFetchingStats) {
      return false;
    }

    if (this.isDebitTransaction) {
      return this.supplierInvoicesStats;
    } else {
      // @ts-expect-error
      return this.receivableInvoicesStats?.created?.total > 0;
    }
  }

  get showSidebarForCreditNote() {
    return this.showCreditNoteExperimentalSidebar || this.showCreditNoteSidebar;
  }

  @action supplierInvoicesStats() {
    let {
      // @ts-expect-error
      inbox_status_counter,
      // @ts-expect-error
      paid_status_counter,
      // @ts-expect-error
      pending_status_counter,
      // @ts-expect-error
      scheduled_status_counter,
    } = this.supplierInvoicesInsights.currentInsights;

    // @ts-expect-error
    return (this.hasSupplierInvoices =
      inbox_status_counter > 0 ||
      paid_status_counter > 0 ||
      pending_status_counter > 0 ||
      scheduled_status_counter > 0);
  }

  get isDebitTransaction() {
    // @ts-expect-error
    return this.args.transaction.side === 'debit';
  }

  get supplierInvoices() {
    if (this.showCreditNoteExperimentalSidebar) {
      return this.fetchCreditNoteRelatedInvoicesTask.lastSuccessful?.value || [];
    } else if (this.showCreditNoteSidebar) {
      return this.fetchRelatedInvoicesTask.lastSuccessful?.value || [];
    }
    return this.fetchSupplierInvoicesTask.lastSuccessful?.value || [];
  }

  get receivableInvoices() {
    return this.fetchReceivableInvoicesTask.lastSuccessful?.value || [];
  }

  get file() {
    if (!this.selectedSupplierInvoiceId && !this.selectedReceivableInvoiceId) {
      return null;
    }

    if (
      this.isDebitTransaction ||
      this.showCreditNoteExperimentalSidebar ||
      this.showCreditNoteSidebar
    ) {
      return (
        this.supplierInvoices
          .find(invoice => invoice.id === this.selectedSupplierInvoiceId)
          // @ts-expect-error
          ?.get('attachment.file')
      );
    } else {
      return (
        this.receivableInvoices
          // @ts-expect-error
          .find(invoice => invoice.id === this.selectedReceivableInvoiceId)
          ?.attachment?.get('file')
      );
    }
  }

  get isSearchError() {
    return Boolean(
      (this.invoicesSearchQuery ||
        this.selectedPeriod ||
        this.issueDateQuery ||
        this.invoiceStatusQuery) &&
        (this.fetchSupplierInvoicesTask.last?.isError ||
          this.fetchReceivableInvoicesTask.last?.isError)
    );
  }

  get hasNoInvoiceSelected() {
    return (
      this.hasInvoices &&
      ((this.isDebitTransaction && !this.selectedSupplierInvoiceId) ||
        (!this.isDebitTransaction && !this.selectedReceivableInvoiceId))
    );
  }

  get isLoading() {
    return this.fetchInvoicesTask.isRunning || this.fetchInvoicesStatsTask.isRunning;
  }

  get totalAmountInCents() {
    // @ts-expect-error
    return Number(this.args.creditNote.totalAmount?.value) * 100;
  }

  searchTask = dropTask(async query => {
    this.invoicesSearchQuery = query;

    this.invoicesCurrentPage = 1;
    this.selectedSupplierInvoiceId = null;
    this.selectedReceivableInvoiceId = null;

    await this.fetchInvoicesTask.perform();
  });

  updatePeriodSelectionTask = dropTask(
    waitFor(async period => {
      this.selectedPeriod = period;
      this.dateFrom = period?.startDate || null;
      this.dateTo = period?.endDate || null;

      await this.fetchInvoicesTask.perform();
    })
  );

  cancelPeriodSelectionTask = dropTask(
    waitFor(async () => {
      this.invoicesCurrentPage = 1;
      this.selectedSupplierInvoiceId = null;
      this.selectedReceivableInvoiceId = null;
      await this.updatePeriodSelectionTask.perform(null);
    })
  );

  handlePeriodUpdateTask = dropTask(
    waitFor(async value => {
      this.invoicesCurrentPage = 1;
      this.selectedSupplierInvoiceId = null;
      this.selectedReceivableInvoiceId = null;
      await this.updatePeriodSelectionTask.perform(value);
    })
  );

  updateIssueDateTask = dropTask(
    waitFor(async issueDate => {
      this.issueDateQuery = issueDate;

      this.invoicesCurrentPage = 1;
      this.selectedSupplierInvoiceId = null;
      this.selectedReceivableInvoiceId = null;
      this.invoiceStatusQuery = null;

      await this.fetchInvoicesTask.perform();
    })
  );

  updateInvoiceStatusTask = dropTask(
    waitFor(async invoiceStatus => {
      this.invoiceStatusQuery = invoiceStatus;

      this.invoicesCurrentPage = 1;
      this.selectedSupplierInvoiceId = null;
      this.selectedReceivableInvoiceId = null;
      this.issueDateQuery = null;

      await this.fetchInvoicesTask.perform();
    })
  );

  fetchSupplierInvoicesTask = dropTask(
    waitFor(async () => {
      let supplierInvoices;

      if (
        // @ts-expect-error
        this.args.transaction.attachmentSuggestionIds.length &&
        !this.invoicesSearchQuery &&
        !this.dateFrom &&
        !this.dateTo
      ) {
        let response = await this.store.query('supplier-invoice', {
          filter: {
            // @ts-expect-error
            attachment_id: this.args.transaction.attachmentSuggestionIds,
          },
        });

        supplierInvoices = response.toArray();
        this.selectedSupplierInvoiceId = supplierInvoices[0]?.id;
        // @ts-expect-error
        this.updatePagination(response.meta);
      } else {
        let params = {
          per_page: 25,
          page: this.invoicesCurrentPage,
          sort_by: 'created_at:desc',
        };

        if (this.invoicesSearchQuery !== '') {
          // @ts-expect-error
          params.query = this.invoicesSearchQuery;
          // @ts-expect-error
          params.query_fields = 'supplier_name,amount,file_name,invoice_number';
        }

        if (this.dateFrom && this.dateTo) {
          // @ts-expect-error
          params.filter = {
            created_at_from: dayjs(this.dateFrom).startOf('day').toISOString(),
            created_at_to: dayjs(this.dateTo).endOf('day').toISOString(),
          };
        }

        let response = await this.store.query('supplier-invoice', params);

        supplierInvoices = response.toArray();

        // @ts-expect-error
        this.updatePagination(response.meta);
      }

      await all(
        supplierInvoices.map(invoice =>
          this.store.findRecord('attachment', invoice.belongsTo('attachment').id())
        )
      );

      return supplierInvoices;
    })
  );

  fetchReceivableInvoicesTask = dropTask(
    waitFor(async () => {
      let receivableInvoices;

      if (
        // @ts-expect-error
        this.args.transaction.attachmentSuggestionIds.length &&
        !this.invoicesSearchQuery &&
        !this.dateFrom &&
        !this.dateTo
      ) {
        let response = await this.store.query('receivable-invoice', {
          filter: {
            // @ts-expect-error
            attachment_id: this.args.transaction.attachmentSuggestionIds.join(),
          },
        });

        receivableInvoices = response.toArray();
        this.selectedReceivableInvoiceId = receivableInvoices[0]?.id;
        // @ts-expect-error
        this.updatePagination(response.meta);
      } else {
        if (this.invoicesSearchQuery || (this.dateFrom && this.dateTo)) {
          let searchParams = {
            query: this.invoicesSearchQuery,
            query_fields: 'customer_name,amount,file_name,invoice_number',
            sorts: [{ field: 'due_date', direction: 'asc' }],
            pagination: {
              page: this.invoicesCurrentPage,
              per_page: 25,
            },
          };

          if (this.dateFrom && this.dateTo) {
            // @ts-expect-error
            searchParams.filters = [
              {
                field: 'issue_date_or_import_analyzed_at',
                value: [dayjs(this.dateFrom).startOf('day').toISOString()],
                operator: 'gte',
              },
              {
                field: 'issue_date_or_import_analyzed_at',
                value: [dayjs(this.dateTo).endOf('day').toISOString()],
                operator: 'lte',
              },
            ];
          }

          // @ts-expect-error
          let response = await this.store.adapterFor('receivable-invoice').search(searchParams);
          receivableInvoices = response.invoices;
          this.updatePagination(response.meta);
        } else {
          let response = await this.store.query('receivable-invoice', {
            page: { number: this.invoicesCurrentPage, size: 25 },
            sort: 'due_date,-number',
          });

          receivableInvoices = response.toArray();
          // @ts-expect-error
          this.updatePagination(response.meta);
        }
      }
      return receivableInvoices;
    })
  );

  fetchCreditNoteRelatedInvoicesTask = dropTask(
    waitFor(async () => {
      let supplierInvoices;

      if (!this.invoicesSearchQuery && !this.dateFrom && !this.dateTo) {
        let response = await this.store.query('supplier-invoice', {
          filter: {
            // @ts-expect-error
            supplier_id: this.args.creditNote.supplierSnapshot.id,
            exclude_credit_notes: true,
          },
        });

        supplierInvoices = response.toArray();
        this.selectedSupplierInvoiceId = supplierInvoices[0]?.id;
        // @ts-expect-error
        this.updatePagination(response.meta);
      } else {
        let params = {
          per_page: 25,
          page: this.invoicesCurrentPage,
          sort_by: 'created_at:desc',
          filter: {
            // @ts-expect-error
            supplier_id: this.args.creditNote.supplierSnapshot.id,
            exclude_credit_notes: true,
          },
        };

        if (this.invoicesSearchQuery !== '') {
          // @ts-expect-error
          params.query = this.invoicesSearchQuery;
          // @ts-expect-error
          params.query_fields = 'supplier_name,amount,file_name,invoice_number';
        }

        if (this.dateFrom && this.dateTo) {
          params.filter = {
            ...params.filter,
            // @ts-expect-error
            created_at_from: dayjs(this.dateFrom).startOf('day').toISOString(),
            created_at_to: dayjs(this.dateTo).endOf('day').toISOString(),
          };
        }

        let response = await this.store.query('supplier-invoice', params);

        supplierInvoices = response.toArray();

        // @ts-expect-error
        this.updatePagination(response.meta);
      }

      await all(
        supplierInvoices.map(invoice =>
          this.store.findRecord('attachment', invoice.belongsTo('attachment').id())
        )
      );

      return supplierInvoices;
    })
  );

  fetchRelatedInvoicesTask = dropTask(
    waitFor(async () => {
      let supplierInvoices;

      if (!this.invoicesSearchQuery && !this.issueDateQuery && !this.invoiceStatusQuery) {
        let response = await this.store.query('supplier-invoice', {
          filter: {
            // @ts-expect-error
            supplier_id: this.args.creditNote.supplierSnapshot.id,
            exclude_credit_notes: true,
            ...(this.showCreditNoteSidebar && { missing_data: false }),
            ...(this.showCreditNoteSidebar && {
              payable_amount_cents: `lte:${this.totalAmountInCents}`,
            }),
          },
        });

        supplierInvoices = response.toArray();
        // @ts-expect-error
        this.updatePagination(response.meta);
      } else {
        let params = {
          per_page: 25,
          page: this.invoicesCurrentPage,
          sort_by: 'total_amount:asc',
          filter: {
            // @ts-expect-error
            supplier_id: this.args.creditNote.supplierSnapshot.id,
            exclude_credit_notes: true,
          },
        };

        if (this.invoicesSearchQuery !== '') {
          // @ts-expect-error
          params.query = this.invoicesSearchQuery;
          // @ts-expect-error
          params.query_fields = 'supplier_name,amount,file_name,invoice_number';
        }

        if (this.issueDateQuery) {
          params.filter = {
            ...params.filter,
            // @ts-expect-error
            issue_date: this.issueDateQuery,
          };
        }

        if (this.invoiceStatusQuery) {
          params.filter = {
            ...params.filter,
            // @ts-expect-error
            status: this.invoiceStatusQuery,
          };
        }

        let response = await this.store.query('supplier-invoice', params);
        supplierInvoices = response.toArray();
        // @ts-expect-error
        this.updatePagination(response.meta);
      }

      await all(
        supplierInvoices.map(invoice =>
          this.store.findRecord('attachment', invoice.belongsTo('attachment').id())
        )
      );

      return supplierInvoices;
    })
  );

  fetchInvoicesTask = dropTask(async () => {
    try {
      // @ts-expect-error
      if (this.args.creditNote) {
        if (variation('experiment--boolean-ap-credit-notes')) {
          return await this.fetchCreditNoteRelatedInvoicesTask.perform();
        }

        if (variation('feature--boolean-ap-credit-notes')) {
          return await this.fetchRelatedInvoicesTask.perform();
        }
      }
      if (this.isDebitTransaction) {
        await this.fetchSupplierInvoicesTask.perform();
      } else {
        await this.fetchReceivableInvoicesTask.perform();
      }
    } catch (error) {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.generic'));

      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  });

  handleChangeInvoicesPageTask = dropTask(
    waitFor(async pageNumber => {
      this.selectedSupplierInvoiceId = null;
      this.selectedReceivableInvoiceId = null;
      this.invoicesCurrentPage = pageNumber;

      await this.fetchInvoicesTask.perform();
    })
  );

  linkSuggestedAttachmentTask = dropTask(
    waitFor(async () => {
      // @ts-expect-error
      if (this.args.creditNote?.isCreditNote) {
        try {
          // For the example this API always return 200
          if (this.showCreditNoteExperimentalSidebar) {
            await this.networkManager.request(
              // @ts-expect-error
              `${apiBaseURL}/${supplierInvoiceNamespace}/supplier_invoices/${this.args.creditNote.id}/link_related_invoice`,
              {
                method: 'POST',
                body: JSON.stringify({
                  related_invoice_id: this.selectedSupplierInvoiceId,
                }),
              }
            );
          } else if (this.showCreditNoteSidebar) {
            await this.networkManager.request(
              `${apiBaseURL}/${supplierInvoiceNamespace}/supplier_invoices/${this.selectedSupplierInvoiceId}/link_credit_note`,
              {
                method: 'POST',
                body: JSON.stringify({
                  // @ts-expect-error
                  credit_note_id: this.args.creditNote.id,
                }),
              }
            );
          }
          // eslint-disable-next-line no-empty
        } catch {}
        let selectedSupplierInvoice = await this.store.findRecord(
          'supplier-invoice',
          this.selectedSupplierInvoiceId
        );

        // Optimistically update the credit note status
        // @ts-expect-error
        this.args.creditNote.relatedInvoices[0] = {
          id: this.selectedSupplierInvoiceId,
          invoice_number: selectedSupplierInvoice.invoiceNumber,
          total_amount: selectedSupplierInvoice.totalAmount,
        };

        // @ts-expect-error
        this.args.creditNote.status = INVOICE_STATUSES.paid;

        if (this.showCreditNoteSidebar) {
          this.segment.track('supplier-invoices_link-invoice_submitted');

          this.toastFlashMessages.toastSuccess(
            this.intl.t('supplier-invoices.invoice-linked-to-credit-note.toast')
          );
        }

        // @ts-expect-error
        this.args.onClose();
        return;
      }
      this.segment.track('find_on_qonto_button_match_clicked');

      try {
        let attachment;

        if (this.isDebitTransaction) {
          let selectedSupplierInvoice = await this.store.findRecord(
            'supplier-invoice',
            this.selectedSupplierInvoiceId
          );

          attachment = await selectedSupplierInvoice.attachment;
        } else {
          let selectedReceivableInvoice = await this.store.findRecord(
            'receivable-invoice',
            this.selectedReceivableInvoiceId
          );

          attachment = await selectedReceivableInvoice.attachment;
        }

        // @ts-expect-error
        if (!this.args.transaction.attachments.includes(attachment)) {
          // @ts-expect-error
          this.args.transaction.attachments.push(attachment);
        }

        // @ts-expect-error
        await this.args.transaction.linkAttachment([attachment]);

        // @ts-expect-error
        this.args.onClose();

        this.toastFlashMessages.toastInfo(this.intl.t('find-invoice-modal.match.success.toast'));
      } catch (error) {
        let isInvoiceAlreadyMatchedError =
          // @ts-expect-error
          error.status === 422 &&
          // @ts-expect-error
          error.errors?.some(({ detail }) => detail?.code === INVOICE_ALREADY_MATCHED_ERROR_CODE);

        if (isInvoiceAlreadyMatchedError) {
          this.segment.track('find_on_qonto_button_attachments-link-re-attempted');

          this.toastFlashMessages.toastError(
            this.intl.t('find-invoice-modal.match.error-already-matched.toast')
          );
        } else {
          this.toastFlashMessages.toastError(this.intl.t('toasts.errors.generic'));
        }
      }
    })
  );

  // @ts-expect-error
  updatePagination(meta) {
    this.invoicesTotalCount = meta.total_count || meta.total;
    this.invoicesCurrentPage = meta.current_page;
    this.invoicesTotalPages = meta.total_pages;
    this.invoicesPerPage = meta.per_page;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Attachments::AttachmentsSuggested': typeof AttachmentsSuggestedComponent;
  }
}
