export default {
  "numbering-container": "cNj",
  "automatic-number-label": "cNW",
  "icon-tooltip": "cNu",
  "fix-width": "cvS",
  "dates-container": "cvc",
  "with-performance-date": "cvq",
  "date-picker": "cvZ",
  "label": "cvR",
  "header-text-field-container": "cvQ",
  "mt-16": "cve",
  "header-text-field": "cvB",
  "header-text-field-close-button": "cvE",
  "deposit-toggle-container": "cvd"
};
