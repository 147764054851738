/* import __COLOCATED_TEMPLATE__ from './upsell-card.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { Badge } from '@repo/design-system-kit';

import { TRACKING_TRIAL_STATE } from 'qonto/constants/subscriptions';

interface BulkTransferUpsellCardSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class BulkTransferUpsellCard extends Component<BulkTransferUpsellCardSignature> {
  badge = Badge;

  @service declare intl: Services['intl'];
  @service declare flowLinkManager: Services['flowLinkManager'];
  @service declare segment: Services['segment'];

  TRACKING_TRIAL_STATE = TRACKING_TRIAL_STATE;

  get badgeType() {
    // @ts-expect-error
    return this.args.trialInfo ? 'tryForFree' : 'upgrade';
  }

  get badgeText() {
    // @ts-expect-error
    return this.args.trialInfo
      ? this.intl.t('upsell.discover.trial.badge')
      : this.intl.t('upsell.discover.badge');
  }

  @action
  handleUpsell() {
    // @ts-expect-error
    if (this.args.trialInfo) {
      // @ts-expect-error
      this.args.onTrialClick();
    } else {
      this.segment.track('upsell_bulk-transfer_clicked', {
        trial_state: this.TRACKING_TRIAL_STATE.NONE,
      });
      this.flowLinkManager.transitionTo({
        name: 'subscription-change',
        stepId: 'plans',
      });
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transfers::Bulk::UpsellCard': typeof BulkTransferUpsellCard;
  }
}
