export default {
  "main-container": "qNW",
  "main": "qNu",
  "main-wrapper": "qvS",
  "close-button": "qvc",
  "active-block": "qvq",
  "title": "qvZ",
  "list-options": "qvR",
  "card": "qvQ",
  "card-container": "qve",
  "card-image": "qvB",
  "card-tiles": "qvE"
};
