export default {
  "container": "hD",
  "numbering": "hV",
  "sideview": "hL",
  "form": "hT",
  "numbering-fields": "hz",
  "row": "ha",
  "label-tooltip": "hH",
  "tooltip-icon": "hO",
  "legend": "hm",
  "next-invoice-number": "hp",
  "pdf-preview": "hx",
  "de-layout": "hw",
  "de-pdf-preview": "hN"
};
