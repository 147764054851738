export default {
  "filter": "ng",
  "expression": "nD",
  "conditional-wrapper": "nV mr-8",
  "dropdown-property-select": "nL",
  "is-advanced-filters-mode": "nT",
  "dropdown-operator-select": "nz",
  "conditional-text": "na",
  "property-select": "nH",
  "operator-select": "nO",
  "error-message": "nm",
  "values-field": "np",
  "input-placeholder": "nx",
  "filter-actions": "nw",
  "label-color": "nN",
  "button-container": "nv"
};
