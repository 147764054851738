export default {
  "item": "xA",
  "qonto-pilot-nav-item": "xY",
  "wrapper": "xh",
  "collapsed": "xf",
  "label": "xK body-1",
  "icon-wrapper": "xG",
  "icon": "xr",
  "key": "xb",
  "keys": "xy"
};
