export default {
  "selector": "rg",
  "metal-design": "rD",
  "content": "rV",
  "header": "rL",
  "big": "rT",
  "header-name": "rz",
  "subtitle": "ra",
  "button": "rH",
  "features": "rO",
  "feature": "rm",
  "highlighted": "rp"
};
