/* import __COLOCATED_TEMPLATE__ from './discover-bookkeeping.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface DiscoverBookkeepingSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const DiscoverBookkeeping = templateOnlyComponent<DiscoverBookkeepingSignature>();

export default DiscoverBookkeeping;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::SubscriptionChange::DiscoverBookkeeping': typeof DiscoverBookkeeping;
  }
}
