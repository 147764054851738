/* import __COLOCATED_TEMPLATE__ from './advertising-merchants.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface AdvertisingMerchantsSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const AdvertisingMerchants = templateOnlyComponent<AdvertisingMerchantsSignature>();

export default AdvertisingMerchants;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'CardSidebar::AdvertisingMerchants': typeof AdvertisingMerchants;
  }
}
