export default {
  "content": "cUT",
  "balance": "cUz",
  "duration": "cUa",
  "green-text": "cUH",
  "balance-subtitle": "cUO",
  "balance-duration": "cUm",
  "footer": "cUp",
  "progress": "cUx",
  "progress-bar": "cUw",
  "progress-bar-text": "cUN",
  "progress-active": "cUv"
};
