/* import __COLOCATED_TEMPLATE__ from './placeholder.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { SkeletonLoader } from '@repo/design-system-kit';
// @ts-expect-error
import { variation } from 'ember-launch-darkly';

import { TABS } from 'qonto/constants/supplier-invoice';

interface PlaceholderSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLTableRowElement;
}

export default class PlaceholderComponent extends Component<PlaceholderSignature> {
  @service declare abilities: Services['abilities'];
  skeletonLoaderAvatar = SkeletonLoader.Avatar;

  // This doesn't include the first an last columns
  // that are always shown
  #numberOfPlaceholderLinesByTab = {
    [TABS.ALL_INVOICES]: 4,
    [TABS.TO_APPROVE]: 1,
    [TABS.TO_PAY]: variation('feature--boolean-improve-first-time-exp-si') ? 2 : 1,
    [TABS.SCHEDULED]: 2,
    [TABS.COMPLETED]: 3,
    // @ts-expect-error
    [TABS.TASKS]: this.abilities.can('load transfer request') ? 2 : 1,
  };

  get columns() {
    // @ts-expect-error
    return new Array(this.#numberOfPlaceholderLinesByTab[this.args.tab]);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'SupplierInvoices::Table::Placeholder': typeof PlaceholderComponent;
  }
}
