export default {
  "row": "qav body-1",
  "active": "qao",
  "cell": "qan",
  "empty": "qai",
  "ellipsis": "qaA",
  "text-secondary": "qaY",
  "transfer-type": "qah",
  "transfer-type__icon": "qaf",
  "quick-actions": "qaK",
  "amount": "qaG",
  "cell-approve": "qar",
  "cell-type": "qab",
  "cell-requester": "qay",
  "account-select": "qaU",
  "row-compact": "qaj",
  "row-sidebar": "qaW",
  "cell-amount": "qau",
  "hidden": "qHS",
  "animated": "qHc",
  "fadein-item": "qHq",
  "animated-cell": "qHZ"
};
