export default {
  "sidebar": "cXb",
  "top-section": "cXy",
  "title": "cXU",
  "primary-action": "cXj",
  "edit-icon": "cXW",
  "close-icon": "cXu",
  "box-wrapper": "cCS",
  "bottom-action": "cCc",
  "related-invoices": "cCq",
  "related-invoices-title": "cCZ mb-16 caption-bold",
  "box": "cCR",
  "box-element": "cCQ",
  "cancel-text": "cCe",
  "box-header": "cCB",
  "row": "cCE",
  "greyed-out": "cCd",
  "struck-through": "cCI",
  "share-mandate-link": "cCt"
};
