/* import __COLOCATED_TEMPLATE__ from './details.hbs'; */
/* eslint-disable ember/no-classic-components */
import Component from '@ember/component';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { AmountField } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';
import { alias } from 'macro-decorators';

import { CheckField } from 'qonto/react/components/form-fields/check-field';
// @ts-expect-error
import ChecksDetailsValidations from 'qonto/validations/forms/checks-details';

// @ts-expect-error
export default class FlowsCheckDetailsComponent extends Component.extend(ChecksDetailsValidations) {
  amountField = AmountField;
  checkField = CheckField;

  // @ts-expect-error
  @service segment;
  // @ts-expect-error
  @service intl;
  // @ts-expect-error
  @service sentry;
  // @ts-expect-error
  @service zendeskLocalization;

  // @ts-expect-error
  @alias('context.check') check;

  @tracked showAmountErrorMessage = false;
  @tracked showCheckErrorMessage = false;

  tagName = '';
  highlightAmount = false;
  highlightCheckId = false;

  get hasExceedingAmount() {
    return this.get('check.amount') > this.context.maxCheckDepositAmount;
  }

  /*
    This is a workaround to a known issue with ember-intl/cp-validations, where the addon tries to spread `htmlSafe: true` to
    intl.t(), which then treats it like a variable to replace in the translation key and then fails miserably.
    Link to issue :  https://github.com/ember-intl/cp-validations/issues/49
   */
  get invalidAmountErrorMessage() {
    if (this.hasExceedingAmount) {
      let options = {
        minimumFractionDigits: 0,
        currency: 'EUR',
        style: 'currency',
      };
      return this.intl.t('validations.errors.checks.amountCents', {
        checkAmount: this.intl.formatNumber(this.get('check.amount'), options),
        htmlSafe: true,
        faqUrl: this.zendeskLocalization.getLocalizedArticle(5259491),
      });
    }
    return null;
  }

  get amountErrorMessage() {
    return this.showAmountErrorMessage
      ? this.invalidAmountErrorMessage || this.validations.attrs.check.amount.message
      : null;
  }

  get idErrorMessage() {
    return this.cmc7ErrorMessage || this.rlmcErrorMessage;
  }

  get cmc7ErrorMessage() {
    return this.showCheckErrorMessage ? this.validations.attrs.check.cmc7.message : null;
  }

  get rlmcErrorMessage() {
    return this.showCheckErrorMessage ? this.validations.attrs.check.rlmc.message : null;
  }

  confirmFlightTask = dropTask(async () => {
    let { check } = this;

    // Clear previous API action errors
    // eslint-disable-next-line ember/no-array-prototype-extensions
    check.errors.clear();

    let { validations } = await check.validate();

    this.showAmountErrorMessage = true;
    this.showCheckErrorMessage = true;

    if (!validations.get('isValid')) {
      return;
    }

    this.segment.track('checks_deposit_amount_and_numb_submitted');

    try {
      await check.confirm();
    } catch (error) {
      // @ts-expect-error
      let { errors = [] } = error;

      // @ts-expect-error
      let hasAmountCentsError = errors.some(({ source }) =>
        source?.pointer?.includes('amount_cents')
      );

      this.segment.track('checks_deposit_amount_validation_failure', {
        error: hasAmountCentsError ? 'amount' : 'other',
      });

      // @ts-expect-error
      if (error.status !== 422) {
        this.sentry.captureException(error);
      }

      return;
    }

    this.segment.track('checks_deposit_amount_validation_success');
    await this.transitionToNext();
  });

  @action
  // @ts-expect-error
  handleCmc7Update(value) {
    this.showCheckErrorMessage = false;
    this.set('check.cmc7', value);
  }

  @action
  // @ts-expect-error
  handleRlmcUpdate(value) {
    this.showCheckErrorMessage = false;
    this.set('check.rlmc', value);
  }

  @action
  handleCheckIdFocus() {
    this.setProperties({
      highlightAmount: false,
      highlightCheckId: true,
    });
  }

  @action
  handleCheckIdBlur() {
    this.set('highlightCheckId', false);
  }

  @action
  // @ts-expect-error
  onAmountChange(amountValue) {
    if (this.check.amount === amountValue) {
      return;
    }

    this.check.amount = amountValue || null;
    this.showAmountErrorMessage = false;

    this.segment.track('checks_deposit_amount_entered', {
      amount_entered: parseFloat(amountValue),
      exceeding_amount: this.hasExceedingAmount,
    });
  }

  @action
  // @ts-expect-error
  onAmountFocusChange(isFocused) {
    this.set('highlightAmount', isFocused);
  }
}
