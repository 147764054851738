export default {
  "header-cell": "aq",
  "type": "aZ",
  "request-date": "aR",
  "amount": "aQ",
  "quick-actions": "ae",
  "empty": "aB",
  "header-content": "aE caption-bold",
  "active": "ad"
};
