/* import __COLOCATED_TEMPLATE__ from './other-permissions.hbs'; */
/* eslint-disable @qonto/no-import-roles-constants */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

// @ts-expect-error
import { hasMFAError } from '@qonto/qonto-sca/utils/mfa-error';
import { dropTask } from 'ember-concurrency';

// @ts-expect-error
import { MEMBER_EDIT_FLOW_NAMES } from 'qonto/constants/member-edit-flow';
import { ROLES } from 'qonto/constants/membership';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';

interface MemberInviteOtherPermissionsSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class MemberInviteOtherPermissionsComponent extends Component<MemberInviteOtherPermissionsSignature> {
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare intl: Services['intl'];
  @service declare router: Services['router'];
  @service declare segment: Services['segment'];
  @service declare sensitiveActions: Services['sensitiveActions'];
  @service declare sentry: Services['sentry'];
  @service declare abilities: Services['abilities'];
  @service declare flow: Services['flow'];

  permissionsKeys = {
    manageMemberships: 'manage_memberships',
    manageBookkeepings: 'manage_bookkeepings',
    manageTeams: 'manage_teams',
    accessBankAccountsStatements: 'access_bank_accounts_statements',
  };

  // @ts-expect-error
  @tracked _permissions = this.args.context.member.customPermissions.groups;

  get permissions() {
    let { manage_memberships, manage_teams } = this._permissions;

    // @ts-expect-error
    return this.args.context.member.customPermissions.scope === 'team'
      ? [
          {
            key: 'teamManagement',
            title: this.intl.t('invitation-flow.permissions.team.manage-memberships'),
            illustration: '/illustrations/permission/team-management.svg',
            permissions: [
              {
                key: this.permissionsKeys.manageMemberships,
                text: undefined,
                checked: manage_memberships,
              },
            ],
          },
        ]
      : [
          {
            key: 'teamManagement',
            title: this.intl.t('invitation-flow.permissions.team.manage-memberships'),
            illustration: '/illustrations/permission/team-management.svg',
            permissions: [
              {
                key: this.permissionsKeys.manageTeams,
                text: undefined,
                checked: manage_teams,
              },
            ],
          },
        ];
  }

  get fullName() {
    // @ts-expect-error
    return this.args.context.member.fullName;
  }

  @action
  // @ts-expect-error
  onPermissionToggle(key) {
    this._permissions = {
      ...this._permissions,
      [key]: !this._permissions[key],
    };
  }

  confirmTask = dropTask(async () => {
    // @ts-expect-error
    this.args.context.member.customPermissions.groups = this._permissions;

    // @ts-expect-error
    if (!this.args.context.isEditingMember) {
      // @ts-expect-error
      return this.args.transitionToNext();
    }

    // @ts-expect-error
    await this.sensitiveActions.runTask.linked().perform(this.saveTask);
  });

  saveTask = dropTask(async () => {
    // @ts-expect-error
    let { context, transitionToNext } = this.args;
    let isEditScopeFlow = this.flow.flowName === MEMBER_EDIT_FLOW_NAMES.MEMBER_EDIT_SCOPE;
    let isEditRoleFlow = this.flow.flowName === MEMBER_EDIT_FLOW_NAMES.MEMBER_EDIT_ROLE;
    let isOtherPermissionsFlow =
      this.flow.flowName === MEMBER_EDIT_FLOW_NAMES.MEMBER_EDIT_OTHER_PERMISSIONS;

    try {
      if (context.role?.key) {
        context.member.role = context.role.key;
      }

      if (context.role?.key === ROLES.ADVANCED_MANAGER) {
        context.member.role = ROLES.MANAGER;
      }

      // For the edit role flow, we need to update the allowed bank accounts before updating the role
      if (isEditRoleFlow) {
        try {
          if (this.abilities.can('update allowed-bank-account', context.member)) {
            await context.member.updateAllowedBankAccounts(context.member.allowedBankAccountsIds);
          }
        } catch (error) {
          if (ErrorInfo.for(error).shouldSendToSentry) {
            this.sentry.captureException(error);
          }

          context.member.rollbackAttributes();
          this.router.transitionTo('members.active.member', context.member.id);
          this.toastFlashMessages.toastError(this.intl.t('toasts.errors.try-later'));
          return;
        }
      }

      await context.member.updateRole();

      if (isEditScopeFlow) {
        this.segment.track('member_permissions-update_confirmed');
      } else if (isEditRoleFlow) {
        this.segment.track('member_permissions-update_confirmed');
        this.segment.track('team_member_role_edited', {
          originalRole: context.currentUserRole,
          newRole: context.member.role,
        });
      }
      if (
        Boolean(context.currentUserSIpermission) !==
        context.member.customPermissions.groups.manage_supplier_invoices
      ) {
        let trackMessage = context.currentUserSIpermission
          ? 'manager-invitation_supplier-invoices-permission_off'
          : 'manager-invitation_supplier-invoices-permission_on';
        this.segment.track(trackMessage);
      }
    } catch (error) {
      context.member.role = context.currentUserRole;

      // @ts-expect-error
      if (hasMFAError(error?.errors)) {
        throw error;
      }

      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      // For edit scope flow, we want to stop execution and stay in the current screen,
      // while for edit role flow, we want to exit the flow with the error toast
      if (isEditScopeFlow || isEditRoleFlow) {
        this.toastFlashMessages.toastError(this.intl.t('toasts.errors.try-later'));
      }
      if (isOtherPermissionsFlow) {
        // We abort the flow and go back to the members.active.member page
        context.member.rollbackAttributes();
        this.router.transitionTo('members.active.member', context.member.id);
        this.toastFlashMessages.toastError(this.intl.t('toasts.errors.try-later'));
      }
      return;
    }

    try {
      if (this.abilities.can('update by role transfer-limit', context.member.role)) {
        await context.member.updateSpendLimits();
      }
    } catch (error) {
      // @ts-expect-error
      if (hasMFAError(error?.errors)) {
        throw error;
      }

      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.try-later'));
      return;
    }

    // For the edit scope flow, we need to update the allowed bank accounts after updating the role
    if (isEditScopeFlow) {
      try {
        if (this.abilities.can('update allowed-bank-account', context.member)) {
          await context.member.updateAllowedBankAccounts(context.member.allowedBankAccountsIds);
        }
      } catch (error) {
        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }
        context.hasAllowedBankAccountsError = true;
      }
    }

    transitionToNext();
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Member::OtherPermissions': typeof MemberInviteOtherPermissionsComponent;
  }
}
