import { FormattedMessage, FormattedNumber } from 'react-intl';
import type { ReactElement } from 'react';
import { Button, LottiePlayer } from '@repo/design-system-kit';
import { useThemedAssetPath } from 'qonto/react/hooks/use-themed-asset-path';
import styles from './styles.strict-module.css';

export interface EligibilityProps {
  goToNextStep: () => void;
  creditLimitAmount: {
    value: number;
    currency: string;
  };
}

export function Eligibility({ goToNextStep, creditLimitAmount }: EligibilityProps): ReactElement {
  return (
    <div className={styles.eligibility}>
      <LottiePlayer
        className={styles.lottie}
        data-test-eligibility-lottie=""
        loop={false}
        path={useThemedAssetPath('/lotties/financing/pay-later/eligibility.json')}
      />

      <h1 className="title-2 mb-16 align-center" data-test-eligibility-title>
        <FormattedMessage
          id="financing.pay-later.application-flow.eligible.title"
          values={{
            amount: (
              <FormattedNumber
                currency={creditLimitAmount.currency}
                maximumFractionDigits={0}
                minimumFractionDigits={0}
                style="currency"
                value={Number(creditLimitAmount.value)}
              />
            ),
          }}
        />
      </h1>
      <p className="body-1 align-center mb-32" data-test-eligibility-description>
        <FormattedMessage id="financing.pay-later.application-flow.eligible.description" />
      </p>
      <Button className="btn btn--primary" data-test-eligibility-cta onPress={goToNextStep}>
        <FormattedMessage id="financing.pay-later.application-flow.eligible.cta" />
      </Button>
    </div>
  );
}
