import { type ReactNode } from 'react';
import cx from 'clsx';
import { useIntl, FormattedNumber } from 'react-intl';
import { useFlags } from '@qonto/react-migration-toolkit/react/hooks';
import { dateToken } from '@qonto/ui-kit/utils/date-token';
import { INVOICE_STATUSES } from 'qonto/constants/supplier-invoice';
import { StatusTag } from '../../supplier-invoices/status-tag';
import styles from './styles.strict-module.css';

interface InvoiceProps {
  id: string;
  date: string;
  showSignus: boolean;
  currency: string;
  name: string;
  invoiceNumberOrFileName?: string;
  status?: string;
  selectedInvoiceId: string;
  handleSelectInvoice: (invoiceId: string) => void;
  amount?: number | undefined;
}

export function InvoiceDetailsCard({
  id,
  currency,
  name,
  invoiceNumberOrFileName,
  status,
  showSignus,
  date,
  amount,
  selectedInvoiceId,
  handleSelectInvoice,
}: InvoiceProps): ReactNode {
  const { locale } = useIntl();
  const { formatMessage } = useIntl();
  const { featureBooleanApCreditNotes } = useFlags();
  // TODO: feature--boolean-ap-credit-notes, adding this to help global search for cleanup later
  const flagForCreditNotesInAPEnabled = Boolean(featureBooleanApCreditNotes);

  const isSelected = selectedInvoiceId === id;

  const missingData = formatMessage({ id: 'receivable-invoices.label.missing-data' });
  const formattedDate = date
    ? `${formatMessage({ id: 'attachment-auto-matching.invoice-selection-modal.update-text' })} ${dateToken({ date, locale, token: 'date-time-s' })}`
    : missingData;
  const formattedDateWithStatus = getFormattedDate(
    formatMessage({ id: 'attachment-auto-matching.invoice-selection-modal.issued-on' }),
    date,
    locale,
    status
  );

  const nameValue = name || missingData;
  const invoiceNameValue = invoiceNumberOrFileName || missingData;

  const formattedAmount = (): JSX.Element | string => {
    if (amount === undefined) {
      return missingData;
    }

    const sign = showSignus ? '-\xA0' : '+\xA0';
    return (
      <>
        {sign}
        <FormattedNumber maximumFractionDigits={2} minimumFractionDigits={2} value={amount} />
        {'\xA0'}
        {currency}
      </>
    );
  };

  return (
    <button
      className={cx(styles.card, isSelected ? styles.selected : styles.unselected)}
      data-test-invoice-details-card-button
      key={id}
      onClick={() => {
        handleSelectInvoice(id);
      }}
      type="button"
    >
      <div className={styles.titleRow}>
        <p className={cx('body-1', styles.titleText)} data-test-invoice-details-name>
          {flagForCreditNotesInAPEnabled ? invoiceNameValue : nameValue}
        </p>
        <p
          className={cx('body-1', !amount ? styles.greyedOut : '', styles.amount)}
          data-test-invoice-details-amount
        >
          {formattedAmount()}
        </p>
      </div>

      {flagForCreditNotesInAPEnabled ? (
        <div className={styles.titleRow}>
          {status ? <StatusTag status={status} /> : '-'}
          <div className={styles.date}>
            <time data-test-invoice-details-date>{formattedDateWithStatus}</time>
          </div>
        </div>
      ) : (
        <div className={styles.date}>
          <time data-test-invoice-details-date>{date ? formattedDate : '-'}</time>
        </div>
      )}
    </button>
  );
}

const getFormattedDate = (
  copyText: string,
  date: string,
  locale: string,
  status?: string
): string => {
  if (!date) {
    return '-';
  }

  const isToApprove = Boolean(status && status === INVOICE_STATUSES.toApprove);
  const baseDate = dateToken({ date, locale, token: 'date-year-s' });

  if (isToApprove) {
    return `${copyText} ${baseDate}`;
  }

  return baseDate;
};
