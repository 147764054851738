export default {
  "wrapper": "vJ",
  "legend": "vs",
  "grid": "vF",
  "subtitle": "vg body-2",
  "fiscal-code": "vD",
  "personal-data-name": "vV",
  "personal-data-birth": "vL",
  "personal-data": "vT",
  "tax-residence": "vz",
  "payer-agent": "va",
  "errors": "vH",
  "business": "vO",
  "address": "vm"
};
