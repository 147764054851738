export default {
  "beneficiary-details": "qHu",
  "beneficiary-account-types": "qOS",
  "divider": "qOc",
  "label": "qOq",
  "labels": "qOZ",
  "error": "qOR",
  "link": "qOQ caption",
  "sticky-panel": "qOe"
};
