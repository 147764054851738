/* import __COLOCATED_TEMPLATE__ from './dummy-data.hbs'; */
import Component from '@glimmer/component';

import { SearchField } from '@repo/design-system-kit';

interface EmptyStatesOutgoingDirectDebitsDummyDataSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class EmptyStatesOutgoingDirectDebitsDummyDataComponent extends Component<EmptyStatesOutgoingDirectDebitsDummyDataSignature> {
  searchField = SearchField;

  noop() {}

  get mandate() {
    // @ts-expect-error
    return this.args.dummyData.items[0];
  }

  get selectedMandateId() {
    return this.mandate.id;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'EmptyStates::OutgoingDirectDebits::DummyData': typeof EmptyStatesOutgoingDirectDebitsDummyDataComponent;
  }
}
