export default {
  "section-container": "cuo",
  "disclaimer-section": "cun",
  "trip-title": "cui caption-bold mb-16",
  "google-link-container": "cuA",
  "link-icon": "cuY",
  "trip-details": "cuh body-2",
  "trip-details-icon": "cuf",
  "trip-details-title": "cuK",
  "subtitle": "cuG caption-bold mb-16",
  "disclaimer": "cur",
  "visualizer": "cub"
};
