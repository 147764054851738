/* import __COLOCATED_TEMPLATE__ from './empty-state.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

interface F24EmptyStateSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class F24EmptyState extends Component<F24EmptyStateSignature> {
  @service declare segment: Services['segment'];
  @service declare intl: Services['intl'];
  @service declare router: Services['router'];
  @service declare flowLinkManager: Services['flowLinkManager'];

  @action
  ctaCallback() {
    this.segment.track('f24_create-flow_start');

    this.flowLinkManager.transitionTo({
      name: 'f24-manual-declaration',
      stepId: 'form-ordinario',
    });
  }

  get emptyStateOptions() {
    let button = {
      label: this.intl.t('f24.pay-f24-cta'),
      callback: this.ctaCallback,
    };
    return {
      title: this.intl.t('f24.empty-state.title'),
      subtitle: this.intl.t('f24.empty-state.subtitle'),
      lottieSrc: '/lotties/f24/empty-state.json',
      button,
    };
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'F24::EmptyState': typeof F24EmptyState;
  }
}
