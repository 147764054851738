/* import __COLOCATED_TEMPLATE__ from './horizontal-scroller.hbs'; */
import Component from '@glimmer/component';

interface HorizontalScrollerSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class HorizontalScrollerComponent extends Component<HorizontalScrollerSignature> {
  constructor(owner: unknown, args: HorizontalScrollerSignature['Args']) {
    super(owner, args);

    document.addEventListener('wheel', handleScroll);
    document.body.classList.add('disable-overscroll');
  }

  // @ts-expect-error
  willDestroy(...props) {
    // @ts-expect-error
    super.willDestroy(...props);
    document.removeEventListener('wheel', handleScroll);
    document.body.classList.remove('disable-overscroll');
  }

  isDown = false;
  // @ts-expect-error
  startX;
  // @ts-expect-error
  scrollLeft;

  // @ts-expect-error
  startSlider = e => {
    this.isDown = true;
    // @ts-expect-error
    this.startX = e.pageX - this.columnsElm.offsetLeft;
    // @ts-expect-error
    this.scrollLeft = this.columnsElm.scrollLeft;
  };

  stopSlider = () => {
    this.isDown = false;
  };

  // @ts-expect-error
  moveSlider = e => {
    if (!this.isDown) return;
    e.preventDefault();
    let delta = e.pageX - this.startX;
    // @ts-expect-error
    this.columnsElm.scrollLeft = this.scrollLeft - delta;
  };
}

// @ts-expect-error
function handleScroll(e) {
  let { deltaX, deltaY, deltaZ, target } = e;

  let dropdown = document.getElementById('ember-basic-dropdown-wormhole');
  if (dropdown && dropdown.contains(target)) {
    return;
  }

  let columnsElm = document.querySelector('[data-horizontal-scroller-slider-element]');

  if (!columnsElm) {
    return;
  }

  if (deltaZ === 0 && Math.abs(deltaY) > Math.abs(deltaX)) {
    columnsElm.scrollLeft = columnsElm.scrollLeft + deltaY;
  }

  if (deltaZ === 0 && Math.abs(deltaX) > Math.abs(deltaY)) {
    columnsElm.scrollLeft = columnsElm.scrollLeft + deltaX;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Invoices::Mapping::HorizontalScroller': typeof HorizontalScrollerComponent;
  }
}
