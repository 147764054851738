/* import __COLOCATED_TEMPLATE__ from './disclaimers-confirm.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';

import { Disclaimer } from '@repo/design-system-kit';
// @ts-expect-error -- not typed
import { variation } from 'ember-launch-darkly';

import type { EstimationError, EstimationWarning } from './dataContext.type';

type DisclaimersProps = {
  addonName: string;
  hasInsufficientFunds: boolean;
  planName: string;
  warnings: EstimationWarning[];
  errors: EstimationError[];
};

export default class FlowsAddonChangeDisclaimersConfirmComponent extends Component<DisclaimersProps> {
  @service declare intl: Services['intl'];
  @service declare subscriptionManager: Services['subscriptionManager'];
  @service declare organizationManager: Services['organizationManager'];

  disclaimer = Disclaimer.Block;

  get currentPlan() {
    return this.subscriptionManager?.currentPricePlan;
  }

  get showDisclaimerForItalianOrganizations(): boolean {
    return (
      variation('feature--boolean-pricing-italian-disclaimers') &&
      this.organizationManager.organization.legalCountry === 'IT'
    );
  }

  get italianDisclaimerText(): string {
    return this.intl.t('subscription.change.bank-of-italy-disclaimer.body', {
      // @ts-expect-error
      faqUrl: htmlSafe(
        `<a href="https://support-it.qonto.com/hc/it/articles/26999640842513-Restrizioni-in-Italia-e-miglioramento-delle-misure-di-antiriciclaggio" target="_blank" rel="noopener noreferrer"
            data-test-addons-italian-disclaimer-link>${this.intl.t(
              'subscription.change.bank-of-italy-disclaimer.link'
            )}</a>`
      ),
      htmlSafe: true,
    });
  }

  get showLegacyDisclaimer(): boolean {
    return this.args.warnings.some(warning => warning.code === 'current_plan_disabled');
  }

  get showNoRefundDisclaimer(): boolean {
    return this.args.warnings.some(warning => warning.code === 'no_refund');
  }

  get showPlanChangeDisclaimer(): boolean {
    return this.args.warnings.some(
      warning => warning.code === 'current_plan_does_not_support_target_addon'
    );
  }

  get showRecurrenceDisclaimer(): boolean {
    return this.args.warnings.some(
      warning => warning.code === 'current_plan_recurrence_does_not_support_target_addon_recurrence'
    );
  }

  get showESMDisclaimerForItalianOrganizations(): boolean {
    if (variation('feature--boolean-boi-restrictions-suspended-accounts')) {
      return this.args.errors.some(error => error.code === 'boi_change_disallowed');
    }
    return false;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::AddonChange::DisclaimersConfirm': typeof FlowsAddonChangeDisclaimersConfirmComponent;
  }
}
