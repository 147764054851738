import type TransactionModel from 'qonto/models/transaction';
import type {
  Side,
  Status,
  Transaction,
  OperationMethod,
  ActivityTag,
  OperationType,
} from 'qonto/react/graphql';

export type PartialTransaction = Omit<
  Transaction,
  // TODO: Will be addressed in next MR
  | 'attachments'
  // TODO: Will be addressed in next MR
  | 'labels'
  // TODO: Will be addressed in next MR
  | 'labelIds'
  // Not needed to be translated to GraphQL
  | 'vat'
  | 'organizationId'
  | 'bankAccount'
  | 'bankAccountId'
  | 'initiator'
  // Fields not available in transaction ember model
  | 'attachmentRequestedAt'
  | 'statusHistory'
  | 'settledBalance'
  | 'payableInvoices'
  | 'receivableInvoices'
  | 'providerObjectID'
>;

/**
 * Turns an EmberData transaction into a GraphQL transaction object.
 *
 * @see Not all the GraphQL fields are available on the EmberData model.
 *
 * Fields that are not available on EmberData:
 * - threads
 * - billingTransfer
 *
 * Fields that are not included since they are not expected to change:
 * - initiator
 * - bankAccount
 */
export const serializeTransaction = (transactionModel: TransactionModel): PartialTransaction => {
  return {
    activityTag: transactionModel.activityTag as ActivityTag,
    displayAt: transactionModel.displayAt.toString(),
    operationType: transactionModel.operationType.toUpperCase() as OperationType,
    amount: {
      value: transactionModel.amount.toFixed(2),
      currency: transactionModel.amountCurrency,
    },
    localAmount: {
      value: transactionModel.localAmount.toFixed(2),
      currency: transactionModel.localAmountCurrency,
    },
    note: transactionModel.note,
    side: transactionModel.side.toUpperCase() as Side,
    slug: transactionModel.slug,
    status: transactionModel.status.toUpperCase() as Status,
    qualifiedForAccounting: transactionModel.qualifiedForAccounting,
    attachmentLost: transactionModel.attachmentLost,
    attachmentRequired: transactionModel.attachmentRequired,
    attachmentIds: transactionModel.attachmentIds,
    hasAttachment: transactionModel.attachmentIds.length > 0,
    attachmentSuggestionIds: transactionModel.attachmentSuggestionIds,
    automaticallyAddedAttachmentIds: transactionModel.automaticallyAddedAttachmentIds,
    attachmentRequested: transactionModel.attachmentRequested,
    rawCounterpartyName: transactionModel.rawCounterpartyName,
    counterpartyName: transactionModel.counterpartyName,
    fx: transactionModel.fx,
    operationMethod: transactionModel.operationMethod.toUpperCase() as OperationMethod,
    id: transactionModel.id,
    description: transactionModel.description,
    enrichmentData: transactionModel.enrichmentData,
    settledAt: transactionModel.settledAt.toISOString(),
    emittedAt: transactionModel.emittedAt.toString(),
    subjectType: transactionModel.subjectType,
  };
};
