import type { ReactElement, SVGProps } from 'react';

export function IconMastercard(props: SVGProps<SVGSVGElement>): ReactElement {
  return (
    <svg fill="none" height={20} width={28} xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect fill="#000" height={20} rx={3} width={26.667} x={0.667} />
      <path d="M11.5 14.167a4.167 4.167 0 1 0 0-8.334 4.167 4.167 0 0 0 0 8.334Z" fill="#E7001A" />
      <path d="M16.5 14.167a4.167 4.167 0 1 0 0-8.334 4.167 4.167 0 0 0 0 8.334Z" fill="#F49B1C" />
      <path
        clipRule="evenodd"
        d="M14 6.667A4.16 4.16 0 0 1 15.667 10 4.16 4.16 0 0 1 14 13.334 4.16 4.16 0 0 1 12.333 10 4.16 4.16 0 0 1 14 6.667Z"
        fill="#E7001A"
        fillRule="evenodd"
        opacity={0.3}
      />
    </svg>
  );
}
