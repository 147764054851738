/* import __COLOCATED_TEMPLATE__ from './dynamic-form.hbs'; */
import EmberObject, { action } from '@ember/object';
import { getOwner } from '@ember/owner';
import { next } from '@ember/runloop';
import { service, type Registry as Services } from '@ember/service';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
// @ts-expect-error
import { cached, tracked } from '@glimmer/tracking';

import { Button } from '@repo/design-system-kit';
import { dropTask, restartableTask, timeout } from 'ember-concurrency';
// @ts-expect-error
import { buildValidations } from 'ember-cp-validations';
import { isNil } from 'es-toolkit';
import { TrackedObject } from 'tracked-built-ins';

import { VALIDATION_TRIGGER_TYPES } from 'qonto/constants/dynamic-form';
// @ts-expect-error
import { DEBOUNCE_MS } from 'qonto/constants/timers';
// @ts-expect-error
import { deepMerge } from 'qonto/utils/deep-merge';
import {
  extendFieldProperties,
  extendFieldsetProperties,
  mergeAdjacentFieldsets,
  resolveDefaultValue,
  transformFieldsToFieldsets,
} from 'qonto/utils/dynamic-form';
// @ts-expect-error
import scrollIntoView from 'qonto/utils/scroll-into-view';
// @ts-expect-error
import { unflatten } from 'qonto/utils/unflatten';
import {
  extractCustomValidators,
  extractFieldsValidators,
  mergeValidators,
  // @ts-expect-error
} from 'qonto/utils/validators';

interface Signature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLFormElement;
}

export default class TransfersInternationalOutDynamicFormComponent extends Component<Signature> {
  button = Button;

  @service declare intl: Services['intl'];

  // @ts-expect-error
  @tracked groupType = this.args.formData[0].type;

  constructor(owner: unknown, args: Signature['Args']) {
    super(owner, args);
    // @ts-expect-error
    this.fieldValuesTracker = new TrackedObject();
    // @ts-expect-error
    this.fieldValidationStatusTracker = new TrackedObject();
  }

  // @ts-expect-error
  isValidationEnabledFor = fieldKey => {
    let fieldValidationStatus =
      // @ts-expect-error
      this.fieldValidationStatusTracker[this.#getIdentifier(this.#currentGroup.type, fieldKey)];

    if (!isNil(fieldValidationStatus)) {
      return fieldValidationStatus;
    }

    // @ts-expect-error
    return !isNil(this.#fields.find(({ key }) => key === fieldKey)?.defaultValue);
  };

  get #allFields() {
    return this.#flattenFields.map(extendFieldProperties);
  }

  get #currentGroup() {
    // @ts-expect-error
    return this.args.formData?.find(formDataGroup => formDataGroup.type === this.groupType);
  }

  get #fields() {
    return this.#omitOptional ? this.#requiredFields : this.#allFields;
  }

  get fieldsets() {
    let fieldsets = transformFieldsToFieldsets(this.#fields);
    let mergedFieldsets = mergeAdjacentFieldsets(fieldsets);
    return mergedFieldsets.map(extendFieldsetProperties);
  }

  get #flattenFields() {
    // @ts-expect-error
    return this.#currentGroup?.fields?.map(({ group }) => group).flat() || [];
  }

  get formData() {
    return Object.keys(this.formDataModel).reduce((accumulator, key) => {
      if (this.formDataModel.hasOwnProperty(key) && key !== '_super') {
        // @ts-expect-error
        accumulator[key] = this.formDataModel.get(key);
      }
      return accumulator;
    }, {});
  }

  @cached
  get formDataModel() {
    return this.#createFormDataModel({
      fields: this.#fields,
      validators: this.#validators,
    });
  }

  get isFocused() {
    // @ts-expect-error
    return this.args.isFocused ?? true;
  }

  get #omitOptional() {
    // @ts-expect-error
    return this.args.omitOptional ?? false;
  }

  get #requiredFields() {
    // @ts-expect-error
    return this.#allFields.filter(({ required }) => required);
  }

  get submissionText() {
    // @ts-expect-error
    return this.args.submissionText ?? this.intl.t('international-out.dynamic-form.submit');
  }

  get #validationTrigger() {
    // @ts-expect-error
    return this.args.validationTrigger ?? VALIDATION_TRIGGER_TYPES.INPUT;
  }

  get #validators() {
    // @ts-expect-error
    return this.args.validators ?? {};
  }

  // @ts-expect-error
  #createDefaultFieldKeyValuesMap(fields) {
    return fields.reduce(
      // @ts-expect-error
      (map, { key, ...field }) => ({
        ...map,
        // @ts-expect-error
        [key]: resolveDefaultValue(field) ?? null,
      }),
      {}
    );
  }

  // @ts-expect-error
  #createFormDataModel({ fields, validators }) {
    let FormValidationModel = this.#createFormValidationModel({ fields, validators });

    // @ts-expect-error
    class FormDataModel extends EmberObject.extend(FormValidationModel) {
      // @ts-expect-error
      @service intl;
      // @ts-expect-error
      @service localeManager;
    }

    let initialFormData = deepMerge(
      this.#createDefaultFieldKeyValuesMap(fields),
      this.#getPreviousValues(fields)
    );

    // @ts-expect-error
    return FormDataModel.create(getOwner(this).ownerInjection(), {
      ...unflatten(initialFormData),
    });
  }

  // @ts-expect-error
  #createFormValidationModel({ fields, validators }) {
    let fieldsValidators = extractFieldsValidators(fields);
    let customValidators = extractCustomValidators(validators);
    return buildValidations(mergeValidators(fieldsValidators, customValidators));
  }

  // @ts-expect-error
  #getIdentifier(groupType, fieldKey) {
    return `${groupType}.${fieldKey}`;
  }

  // @ts-expect-error
  #getPreviousValues(fields) {
    // @ts-expect-error
    let currentFormDataGroupKeys = fields.map(({ key }) =>
      this.#getIdentifier(this.#currentGroup.type, key)
    );

    return Object.fromEntries(
      // @ts-expect-error
      Object.entries(this.fieldValuesTracker)
        .filter(([key]) => currentFormDataGroupKeys.includes(key))
        .map(([key, value]) => {
          let [, ...destructuredFieldKey] = key.split('.');
          return [destructuredFieldKey.join('.'), value];
        })
    );
  }

  #scrollToError() {
    next(() => scrollIntoView('[data-has-error]'));
  }

  // @ts-expect-error
  #updateFieldValidationStatus({ fieldKey, isValidationEnabled }) {
    // @ts-expect-error
    this.fieldValidationStatusTracker[this.#getIdentifier(this.#currentGroup.type, fieldKey)] =
      isValidationEnabled;
  }

  @action
  // @ts-expect-error
  onClose(fieldKey) {
    this.#updateFieldValidationStatus({ fieldKey, isValidationEnabled: true });
  }

  @action
  // @ts-expect-error
  onFocusIn(fieldKey) {
    this.#updateFieldValidationStatus({ fieldKey, isValidationEnabled: false });
  }

  @action
  // @ts-expect-error
  onFocusOut(fieldKey) {
    this.#updateFieldValidationStatus({ fieldKey, isValidationEnabled: true });
  }

  @action
  onRefresh() {
    // @ts-expect-error
    this.args.onRefresh?.({
      type: this.groupType,
      data: this.formData,
    });
  }

  onSubmitTask = dropTask(async () => {
    // @ts-expect-error
    let { onError, onSubmit } = this.args;

    let { validations } = await this.formDataModel.validate();

    // @ts-expect-error
    this.#fields.forEach(({ key: fieldKey }) => {
      this.#updateFieldValidationStatus({ fieldKey, isValidationEnabled: true });
    });

    if (validations.isValid) {
      onSubmit?.({
        type: this.groupType,
        data: this.formData,
      });
    } else {
      let { error } = validations;
      onError?.({
        type: error.type,
        attribute: error.attribute,
      });
      this.#scrollToError();
    }
  });

  onUpdateTask = restartableTask(async (fieldKey, value) => {
    // @ts-expect-error
    this.fieldValuesTracker[this.#getIdentifier(this.#currentGroup.type, fieldKey)] = isEmpty(value)
      ? null
      : value;

    await timeout(DEBOUNCE_MS);

    if (this.#validationTrigger === VALIDATION_TRIGGER_TYPES.INPUT) {
      this.#updateFieldValidationStatus({ fieldKey, isValidationEnabled: true });
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transfers::InternationalOut::DynamicForm': typeof TransfersInternationalOutDynamicFormComponent;
  }
}
