/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { DATE_FORMAT_TOKENS, dateToken } from '@qonto/ui-kit/utils/date-token';

import { CARD_LEVELS } from 'qonto/constants/cards';

interface RequestSidebarCardHeaderSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class RequestSidebarCardHeaderComponent extends Component<RequestSidebarCardHeaderSignature> {
  @service declare intl: Services['intl'];

  get pan() {
    return '•'.repeat(16);
  }

  get statusClass() {
    // @ts-expect-error
    let { status } = this.args.request;

    if (status === 'declined' || status === 'canceled') {
      return 'danger';
    } else if (status === 'approved') {
      return 'success';
    } else if (status === 'pending') {
      return 'warning';
    }
  }

  get design() {
    // @ts-expect-error
    let { requestType } = this.args.request;

    return requestType === 'virtual_card' ? CARD_LEVELS.VIRTUAL : CARD_LEVELS.FLASH;
  }

  get titleStatus() {
    // @ts-expect-error
    let { approver, status } = this.args.request;
    let approverName;

    if (status === 'declined' || status === 'approved') {
      let firstName = approver.get('firstName');
      let lastName = approver.get('lastName');
      approverName = `${firstName.charAt(0)}. ${lastName}`;
    }

    if (status === 'declined') {
      return this.intl.t('requests.sidebar.header.status.declined', {
        approver_name: approverName,
      });
    } else if (status === 'approved') {
      return this.intl.t('requests.sidebar.header.status.accepted', {
        approver_name: approverName,
      });
    } else if (status === 'canceled') {
      return this.intl.t('requests.sidebar.header.status.canceled');
    } else if (status === 'pending') {
      return this.intl.t('requests.sidebar.header.status.pending');
    }
  }

  get lastActionDate() {
    // @ts-expect-error
    let { createdAt, processedAt, status } = this.args.request;

    if (status === 'pending') {
      return this.intl.t('requests.sidebar.header.created-date', {
        created_at: dateToken({
          date: createdAt,
          // @ts-expect-error
          locale: this.intl.primaryLocale,
          token: DATE_FORMAT_TOKENS.DATE_YEAR_S,
        }),
      });
    }

    return this.intl.t('requests.sidebar.header.updated-date', {
      updated_at: dateToken({
        date: processedAt,
        // @ts-expect-error
        locale: this.intl.primaryLocale,
        token: DATE_FORMAT_TOKENS.DATE_YEAR_S,
      }),
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Request::Sidebar::Card::Header': typeof RequestSidebarCardHeaderComponent;
  }
}
