export default {
  "invitations-password-form": "clr",
  "mobile-mode": "clb",
  "logo": "cly",
  "header": "clU",
  "controls": "clj",
  "footer": "clW",
  "submit-cta": "clu",
  "subtitle": "cXS"
};
