export default {
  "wrapper": "eZ",
  "title": "eR",
  "button-icon": "eQ",
  "download-button": "ee",
  "specification-list": "eB",
  "certified-details-wrapper": "eE",
  "details-wrapper": "ed",
  "certified-specification-list": "eI",
  "specification": "et",
  "icon": "eM",
  "cross": "eP",
  "checkmark": "el",
  "pit-link": "eX"
};
