import type { HeadingProps, Key } from 'react-aria-components';
import {
  Select,
  SelectValue,
  Popover,
  ListBox,
  ListBoxItem,
  useContextProps,
  HeadingContext,
  CalendarStateContext,
} from 'react-aria-components';
import type { ReactNode } from 'react';
import { useContext } from 'react';
import { getLocalTimeZone, today, CalendarDate } from '@internationalized/date';
import cx from 'clsx';
import { Button } from '../../../button';
import { IconArrowBottom } from './icon/icon-arrow-bottom';
import styles from './heading.strict-module.css';

const NB_YEAR_OPTIONS = 21;

export function CalendarHeadingSelect(
  headingProps: HeadingProps,
  headingRef: React.ForwardedRef<HTMLHeadingElement>
): ReactNode {
  const state = useContext(CalendarStateContext);
  const [props, ref] = useContextProps(headingProps, headingRef, HeadingContext);

  const baseDate = state?.value ?? today(getLocalTimeZone());

  const yearOptions = Array.from({ length: NB_YEAR_OPTIONS }, (_, i) => baseDate.year + (i - 10));

  const handleSelectionChange = (selectedYear: Key): void => {
    state?.setFocusedDate(new CalendarDate(Number(selectedYear), state.focusedDate.month, 1));
  };

  return (
    <Select aria-label="select" onSelectionChange={handleSelectionChange}>
      <Button className={styles.button} variant="tertiary">
        <div className={styles['button-children']}>
          <SelectValue className="body-1" {...props} ref={ref} />
          <IconArrowBottom aria-hidden="true" />
        </div>
      </Button>
      <Popover className={styles.popover} maxHeight={180}>
        <ListBox className={styles.list}>
          {yearOptions.map(yearOption => (
            <ListBoxItem
              className={cx('body-1', 'overlay', styles.item)}
              id={yearOption}
              key={yearOption}
            >
              {yearOption}
            </ListBoxItem>
          ))}
        </ListBox>
      </Popover>
    </Select>
  );
}
