import { FormattedMessage, useIntl } from 'react-intl';
import type { ReactElement } from 'react';
import { Button } from '@repo/design-system-kit';
import { StaticThemedAsset } from 'qonto/react/components/static-themed-asset';
import styles from './styles.strict-module.css';

export interface ValuePropositionProps {
  isSafari: boolean;
  isSignatureLoading: boolean;
  goToCockpit: () => void;
  requestPayLaterSignature: () => void;
}

export function ValueProposition({
  isSafari,
  isSignatureLoading,
  goToCockpit,
  requestPayLaterSignature,
}: ValuePropositionProps): ReactElement {
  const { locale } = useIntl();

  const videoPath = `/videos/financing/pay-later/value-prop-flexible-installments/value-prop-${locale}.webm`;
  const imagePath = `/illustrations/financing/pay-later/value-prop-flexible-installments/value-prop-${locale}.webp`;

  return (
    <div className={styles.wrapper}>
      <h1 className="title-1 mb-16" data-test-value-prop-title>
        <FormattedMessage id="financing.pay-later.application-flow.value-proposition.title" />
      </h1>

      <p className="body-2 mb-32" data-test-value-prop-description>
        <FormattedMessage id="financing.pay-later.application-flow.value-proposition.description" />
      </p>

      <div className={styles.container}>
        <div className={styles['video-wrapper']}>
          {isSafari ? (
            <StaticThemedAsset
              aria-hidden="true"
              assetPath={imagePath}
              data-test-value-prop-fallback
              posterPath={imagePath}
              type="image"
            />
          ) : (
            <StaticThemedAsset
              aria-hidden="true"
              assetPath={videoPath}
              autoPlay
              data-test-value-prop-video
              playOnMouseOver
              playsInline
              posterPath={imagePath}
              preload="auto"
              type="video"
              width="500"
            />
          )}
        </div>

        <div>
          <div className={styles['content-block']}>
            <h3 className="title-3 mb-8" data-test-value-prop-activation-title>
              <FormattedMessage id="financing.pay-later.application-flow.value-proposition.activation.title" />
            </h3>

            <p className="body-2" data-test-value-prop-activation-description>
              <FormattedMessage id="financing.pay-later.application-flow.value-proposition.activation.description" />
            </p>
          </div>

          <div className={styles['content-block']}>
            <h3 className="title-3 mb-8" data-test-value-prop-down-payment-title>
              <FormattedMessage id="financing.pay-later.application-flow.value-proposition.down-payment.title" />
            </h3>

            <p className="body-2" data-test-value-prop-down-payment-description>
              <FormattedMessage id="financing.pay-later.application-flow.value-proposition.down-payment.description" />
            </p>
          </div>

          <div className={styles['content-block']}>
            <h3 className="title-3 mb-8" data-test-value-prop-installments-title>
              <FormattedMessage id="financing.pay-later.application-flow.value-proposition.flexible-installments.title" />
            </h3>

            <p className="body-2" data-test-value-prop-installments-description>
              <FormattedMessage id="financing.pay-later.application-flow.value-proposition.flexible-installments.description" />
            </p>
          </div>
        </div>
      </div>

      <div className={styles.actions}>
        <Button
          className="mb-16"
          data-test-value-prop-primary-cta
          isLoading={isSignatureLoading}
          onPress={requestPayLaterSignature}
          type="button"
        >
          <FormattedMessage id="financing.pay-later.application-flow.value-proposition.primary-cta" />
        </Button>

        <Button
          data-test-value-prop-secondary-cta
          onPress={goToCockpit}
          type="button"
          variant="tertiary"
        >
          <FormattedMessage id="financing.pay-later.application-flow.value-proposition.secondary-cta" />
        </Button>
      </div>
    </div>
  );
}
