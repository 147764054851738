export default {
  "bank-accounts": "qbY l-app-content__wrapper",
  "header-wrapper": "qbh",
  "scrolled-top": "qbf",
  "header": "qbK l-app-content__page-header",
  "create-account-button": "qbG btn btn--primary",
  "main": "qbr",
  "accounts": "qbb",
  "subtitle-container": "qby",
  "subtitle": "qbU title-3 mb-16",
  "amount": "qbj body-2",
  "list--cashbeeSavings": "qbW"
};
