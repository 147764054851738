/* import __COLOCATED_TEMPLATE__ from './confirm.hbs'; */
/* eslint-disable @qonto/no-import-roles-constants, require-await */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { DATE_FORMAT_TOKENS, dateToken } from '@qonto/ui-kit/utils/date-token';
import { Disclaimer, TextAreaField } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';
import { bool, reads } from 'macro-decorators';

import { ROLES } from 'qonto/constants/membership';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';

interface TransferMultiConfirmSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class TransferMultiConfirm extends Component<TransferMultiConfirmSignature> {
  disclaimerInline = Disclaimer.Inline;
  textAreaField = TextAreaField;

  @service declare organizationManager: Services['organizationManager'];
  @service declare intl: Services['intl'];
  @service declare sentry: Services['sentry'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare errors: Services['errors'];

  // @ts-expect-error
  @reads('organizationManager.organization') organization;
  // @ts-expect-error
  @reads('organization.hasMultipleActiveAccounts') hasMultipleActiveAccounts;
  // @ts-expect-error
  @bool('args.data.multiTransfer.limitsData.isAboveLimits') isAboveLimits;

  @tracked showErrors = false;

  get isRequestMultiTransfer() {
    // @ts-expect-error
    let modelName = this.args.data.multiTransfer.constructor.modelName;
    return modelName === 'request-multi-transfer';
  }

  get showAccount() {
    // @ts-expect-error
    return this.hasMultipleActiveAccounts && !this.args.data.isRequest;
  }

  get date() {
    if (this.isAboveLimits) {
      return this.intl.t('requests.transfers.modals.confirm_multi_transfer.date');
    }

    return dateToken({
      // @ts-expect-error
      date: this.args.data.multiTransfer.scheduledDate,
      // @ts-expect-error
      locale: this.intl.primaryLocale,
      token: DATE_FORMAT_TOKENS.COMPACT,
    });
  }

  submitTransfersTask = dropTask(async () => {
    // @ts-expect-error
    let { submitTransfers } = this.args.data;

    // @ts-expect-error
    return submitTransfers(this.args.close);
  });

  submitRequestMultiTransfersTask = dropTask(async () => {
    // @ts-expect-error
    let { submitTransfers, multiTransfer } = this.args.data;

    let { validations } = await multiTransfer.validate();
    if (!validations.isValid) {
      return;
    }
    // @ts-expect-error
    return submitTransfers(this.args.close);
  });

  submitTask = dropTask(async () => {
    this.showErrors = true;
    try {
      if (this.isRequestMultiTransfer) {
        await this.submitRequestMultiTransfersTask.perform();
      } else {
        await this.submitTransfersTask.perform();
      }
    } catch (error) {
      this._handleError(error);
    }
  });

  // @ts-expect-error
  _handleError(error) {
    if (ErrorInfo.for(error).shouldSendToSentry) {
      this.sentry.captureException(error);
    }
    if (this.errors.shouldFlash(error)) {
      this.toastFlashMessages.toastError(this.errors.messageForStatus(error));
    }
  }

  get description() {
    // @ts-expect-error
    if (this.args.data.isRequest) {
      return this.intl.t('requests.transfers.modals.confirm_multi_transfer.desc');
    } else {
      return this.intl.t('transfers.modals.confirm_multi_transfer.desc');
    }
  }

  get transferAmount() {
    // @ts-expect-error
    let { multiTransfer } = this.args.data;

    // request multi transfer
    if (multiTransfer?.totalSelectedTransfersAmount) {
      let { totalSelectedTransfersAmount, totalTransfersAmountCurrency } = multiTransfer;
      // @ts-expect-error
      return this.intl.formatMoney(totalSelectedTransfersAmount, {
        currency: totalTransfersAmountCurrency,
      });
    }

    // multi transfer
    // @ts-expect-error
    return this.intl.formatMoney(multiTransfer?.transfersAmount);
  }

  get hasInsufficientFundsError() {
    // @ts-expect-error
    let { multiTransfer } = this.args.data;

    // @ts-expect-error
    return multiTransfer.confirmErrors?.some(error => {
      return error === 'biller_amount_insufficient_funds';
    });
  }

  get insufficientFundsMessage() {
    return this.organizationManager.membership.role === ROLES.MANAGER
      ? this.intl.t('transfers.errors.manager.multi_insufficient_funds')
      : this.intl.t('transfers.errors.multi_insufficient_funds');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'TransferMulti::Confirm': typeof TransferMultiConfirm;
  }
}
