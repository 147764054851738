/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

import { DEFAULT_SORT_ORDER, SORT_ORDER } from 'qonto/constants/sort';
import { SortIcon } from 'qonto/react/components/paginated-table';

const DEFAULT_SORT_FIELD = 'expiration_date';

interface ConnectionsTableHeaderSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLTableRowElement;
}

export default class ConnectionsTableHeaderComponent extends Component<ConnectionsTableHeaderSignature> {
  sortIcon = SortIcon;

  get sortField() {
    // @ts-expect-error
    let { sortBy } = this.args;

    return sortBy?.split(':')[0] || DEFAULT_SORT_FIELD;
  }

  get sortOrder() {
    // @ts-expect-error
    let { sortBy } = this.args;

    return sortBy?.split(':')[1] || DEFAULT_SORT_ORDER;
  }

  @action
  // @ts-expect-error
  handleSortBy(sortField) {
    // @ts-expect-error
    let { handleSortBy } = this.args;
    let sortOrder = DEFAULT_SORT_ORDER;

    if (sortField === this.sortField) {
      // @ts-expect-error
      sortOrder = this.sortOrder === SORT_ORDER.ASC ? SORT_ORDER.DESC : SORT_ORDER.ASC;
    }

    return handleSortBy?.(sortField, sortOrder);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Connections::Table::Header': typeof ConnectionsTableHeaderComponent;
  }
}
