export default {
  "header-cell": "qXl",
  "first-col": "qXX",
  "mid-col": "qXC",
  "outstanding-col": "qXk",
  "header-content": "qXJ caption-bold",
  "active": "qXs",
  "quick-actions": "qXF",
  "empty": "qXg"
};
