export default {
  "row": "qXL",
  "greyed-out": "qXT",
  "supplier-name": "qXz",
  "cell": "qXa",
  "supplier": "qXH",
  "email-address": "qXO",
  "outstanding-balance": "qXm",
  "quick-actions": "qXp",
  "empty": "qXx",
  "supplier-container": "qXw",
  "grey-background": "qXN"
};
