import { type ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Disclaimer } from '@repo/design-system-kit';
import cx from 'clsx';
import { type InstallmentModel, InstallmentStatus } from 'qonto/react/models/installment';
import type { FinancingModel } from 'qonto/react/models/financing';
import { InstallmentTimeline } from '../installment-timeline';
import styles from './styles.strict-module.css';

function getDisclaimer(installments: InstallmentModel[]): ReactNode {
  const atRiskInstallment = installments.find(
    installment => installment.status === InstallmentStatus.AtRisk
  );
  const overdueInstallment = installments.find(
    installment => installment.status === InstallmentStatus.Late
  );

  if (overdueInstallment) {
    return (
      <Disclaimer.Inline
        data-testid="financing-installments-overdue-installment-disclaimer"
        level="error"
      >
        <FormattedMessage id="financing.pay-later.cockpit.details.timeline.disclaimer.overdue" />
      </Disclaimer.Inline>
    );
  } else if (atRiskInstallment) {
    return (
      <Disclaimer.Inline
        data-testid="financing-installments-at-risk-installment-disclaimer"
        level="warning"
      >
        <FormattedMessage id="financing.pay-later.cockpit.details.timeline.disclaimer.at-risk" />
      </Disclaimer.Inline>
    );
  }

  return null;
}

export function FinancingInstallments({
  financing,
  installments,
  onPressRepay,
  isEarlyRepaymentLoading,
}: {
  financing: FinancingModel;
  installments: InstallmentModel[];
  onPressRepay: () => void;
  isEarlyRepaymentLoading: boolean;
}): ReactNode {
  const canEarlyRepay = financing.status === 'in_progress';
  const hasExtraContent = canEarlyRepay || installments.length > 3;

  return (
    <div
      className={cx(styles.container, hasExtraContent ? 'mb-8' : '')}
      data-testid="financing-installments"
    >
      <p className={cx('body-1', styles.title)} data-testid="financing-installments-title">
        <FormattedMessage
          id="financing.pay-later.cockpit.details.timeline.installments-title"
          values={{
            count: financing.paidInstallments,
            current: financing.paidInstallments,
            total: financing.totalInstallments,
          }}
        />
      </p>
      <InstallmentTimeline list={installments} />
      {canEarlyRepay ? (
        <div className={styles.container}>
          <Button
            data-testid="pay-now-button"
            isLoading={isEarlyRepaymentLoading}
            onPress={onPressRepay}
            size="small"
            stretch
            variant="secondary"
          >
            <FormattedMessage id="financing.pay-later.cockpit.details.early-repayment-cta" />
          </Button>

          {getDisclaimer(installments)}
        </div>
      ) : null}
    </div>
  );
}
