export default {
  "vat-number": "qjF body-2",
  "banner": "qjg",
  "container": "qjD",
  "organization-profile": "qjV",
  "input": "qjL",
  "medium-input": "qjT medium",
  "small-input": "qjz small",
  "actions": "qja",
  "company-info": "qjH",
  "company-info-section": "qjO",
  "company-address-section": "qjm",
  "company-country": "qjp",
  "company-billing-email": "qjx",
  "company-address": "qjw"
};
