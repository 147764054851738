/* import __COLOCATED_TEMPLATE__ from './select.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
// @ts-expect-error
import { cached } from '@glimmer/tracking';

import { Flag } from '@repo/design-system-kit';

import { TRIGGER_SELECT_COMPONENTS } from 'qonto/constants/dynamic-form';
import isFunction from 'qonto/utils/is-function';

export const MINIMUM_OPTIONS_THRESHOLD_FOR_SEARCH = 5;

interface Signature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class TransfersInternationalOutDynamicFormFieldSelectComponent extends Component<Signature> {
  flag = Flag;

  get hasFlagIcon() {
    // @ts-expect-error
    return this.args.field.triggerComponent === TRIGGER_SELECT_COMPONENTS.COUNTRY;
  }

  get isSearchEnabled() {
    return this.options.length > MINIMUM_OPTIONS_THRESHOLD_FOR_SEARCH;
  }

  @cached
  get options() {
    return (
      // @ts-expect-error
      this.args.field.valuesAllowed?.map(({ key, name, ...rest }) => ({
        ...rest,
        key,
        name,
        searchableKeywords: [key, name],
      })) || []
    );
  }

  get selectedOption() {
    // @ts-expect-error
    return this.options.find(option => option.key === this.args.value);
  }

  get shouldRefreshOnChange() {
    // @ts-expect-error
    let { field, onRefresh } = this.args;
    return field.refreshRequirementsOnChange && isFunction(onRefresh);
  }

  @action
  // @ts-expect-error
  onSelect(selectedOption) {
    // @ts-expect-error
    let { field, onRefresh, onUpdate } = this.args;

    onUpdate(field.key, selectedOption.key);

    if (this.shouldRefreshOnChange) {
      onRefresh(field.key);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transfers::InternationalOut::DynamicForm::Field::Select': typeof TransfersInternationalOutDynamicFormFieldSelectComponent;
  }
}
