/* import __COLOCATED_TEMPLATE__ from './customization-brand-color.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

// @ts-expect-error
import { PLACEHOLDER_SECTIONS } from '@qonto/ui-kit/constants/pdf-preview';
import dayjs from 'dayjs';
import { dropTask } from 'ember-concurrency';

import {
  ONBOARDING_STEP_STATUS,
  ONBOARDING_TRACKING_EVENTS,
} from 'qonto/constants/receivable-invoice';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';

interface FlowsAccountReceivableOnboardingCustomizationBrandColorSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class FlowsAccountReceivableOnboardingCustomizationBrandColor extends Component<FlowsAccountReceivableOnboardingCustomizationBrandColorSignature> {
  @service declare intl: Services['intl'];
  @service declare sentry: Services['sentry'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare localeManager: Services['localeManager'];
  @service declare segment: Services['segment'];

  willDestroy() {
    // @ts-expect-error
    super.willDestroy(...arguments);

    if (this.settings.isDestroying || this.settings.isDestroyed) return;

    this.settings.rollbackAttributes();
  }

  get organization() {
    return this.onboardingState.organization;
  }

  get settings() {
    // @ts-expect-error
    return this.args.context.settings;
  }

  get onboardingState() {
    // @ts-expect-error
    return this.args.context.onboardingState;
  }

  get isGermanOrganization() {
    // @ts-expect-error
    return this.args.context.isGermanOrganization;
  }

  get exampleInvoice() {
    let deFields = {
      issueDate: dayjs().format('YYYY-MM-DD'),
      dueDate: dayjs().add(30, 'day').format('YYYY-MM-DD'),
      performanceDate: true,
      purchaseOrder: true,
      quote: {
        number: true,
      },
      customer: {
        billingAddress: {
          streetAddress: this.intl.t('ui-kit.pdf-preview.invoice-example.client-address-line1'),
          city: this.intl.t('ui-kit.pdf-preview.invoice-example.client-city'),
          zipCode: this.intl.t('ui-kit.pdf-preview.invoice-example.client-zipcode'),
          countryCode: this.intl.t('ui-kit.pdf-preview.invoice-example.client-countrycode'),
        },
        name: this.intl.t('ui-kit.pdf-preview.invoice-example.client-name'),
        locale: this.organization.locale,
        vatNumber: true,
        tinNumber: true,
      },
    };
    return {
      organization: {
        legalName: this.organization.legalName,
        legalNumber: this.organization.legalNumber,
        address: this.organization.address,
        vatNumber: this.settings.vatNumber,
        companyLeadership: this.settings.companyLeadership,
        taxNumber: this.settings.taxNumber,
      },
      locale: this.localeManager.locale,
      number: this.settings.invoiceNextNumberFormatted,
      currency: 'EUR',
      contactEmail: this.settings.contactEmail,
      totalVat: this.intl.t('ui-kit.pdf-preview.invoice-example.item-vat-total'),
      totalExcludingVat: this.intl.t('ui-kit.pdf-preview.invoice-example.item-total-without-vat'),
      totalAmount: this.intl.t('ui-kit.pdf-preview.invoice-example.invoice-total'),
      items: [
        {
          title: this.intl.t('ui-kit.pdf-preview.invoice-example.item-title'),
          description: this.intl.t('ui-kit.pdf-preview.invoice-example.item-description'),
          quantity: this.intl.t('ui-kit.pdf-preview.invoice-example.item-quantity'),
          unitPrice: this.intl.t('ui-kit.pdf-preview.invoice-example.item-price'),
          vatRate: this.intl.t('ui-kit.pdf-preview.invoice-example.item-vat-amount'),
          totalVat: this.intl.t('ui-kit.pdf-preview.invoice-example.item-vat-total'),
          totalExcludingVat: this.intl.t(
            'ui-kit.pdf-preview.invoice-example.item-total-without-vat'
          ),
          subTotal: this.intl.t('ui-kit.pdf-preview.invoice-example.item-total'),
          totalAmount: this.intl.t('ui-kit.pdf-preview.invoice-example.item-total'),
        },
      ],
      customer: {
        name: this.intl.t('ui-kit.pdf-preview.invoice-example.client-name'),
      },
      ...(this.isGermanOrganization ? deFields : {}),
    };
  }

  get logo() {
    return this.organization.get('isDefaultAvatar') === false ? this.organization.picture : null;
  }

  get placeholderConfig() {
    let deFields = [
      PLACEHOLDER_SECTIONS.CONTACT_CUSTOMER,
      PLACEHOLDER_SECTIONS.ISSUE_DATE,
      PLACEHOLDER_SECTIONS.PERFORMANCE_DATE,
      PLACEHOLDER_SECTIONS.DE_CUSTOMER_VAT_NUMBER,
      PLACEHOLDER_SECTIONS.DE_CUSTOMER_TIN_NUMBER,
      PLACEHOLDER_SECTIONS.DE_PURCHASE_ORDER,
      PLACEHOLDER_SECTIONS.DE_RELATED_QUOTE,
      PLACEHOLDER_SECTIONS.DE_RELATED_QUOTE,
      PLACEHOLDER_SECTIONS.DE_DUE_DATE_DISCLAIMER,
    ];
    return {
      enablePlaceholders: true,
      activeSections: [
        PLACEHOLDER_SECTIONS.NUMBER,
        PLACEHOLDER_SECTIONS.CONTACT_ORGANIZATION,
        PLACEHOLDER_SECTIONS.ITEMS_TABLE,
        PLACEHOLDER_SECTIONS.TERMS_AND_CONDITIONS,
        PLACEHOLDER_SECTIONS.FOOTER,
        ...(this.isGermanOrganization ? deFields : []),
      ],
    };
  }

  @action
  // @ts-expect-error
  handleColorUpdate(key, value) {
    this.settings.set(key, value);
  }

  @action
  handleDoItLater() {
    this.settings.rollbackAttributes();

    if (this.isGermanOrganization) {
      // @ts-expect-error
      this.args.transitionToNext();
    } else {
      // @ts-expect-error
      this.args.backToStep('summary');
    }
  }

  handleConfirmTask = dropTask(async () => {
    try {
      await this.settings.save();
      this.segment.track(ONBOARDING_TRACKING_EVENTS.STEP_COMPLETED, {
        // @ts-expect-error
        source: this.args.context.source,
        step: 'customization',
      });

      this.onboardingState.substepStatusColors = ONBOARDING_STEP_STATUS.CONFIRMED;
      this.onboardingState.stepStatusCustomization = ONBOARDING_STEP_STATUS.CONFIRMED;
      await this.onboardingState.save();

      if (this.isGermanOrganization) {
        // @ts-expect-error
        this.args.transitionToNext();
      } else {
        // @ts-expect-error
        this.args.backToStep('summary');
      }
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::AccountReceivableOnboarding::CustomizationBrandColor': typeof FlowsAccountReceivableOnboardingCustomizationBrandColor;
  }
}
