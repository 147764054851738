/* import __COLOCATED_TEMPLATE__ from './pending.hbs'; */
import { dasherize } from '@ember/string';
import Component from '@glimmer/component';

// @ts-expect-error
import { ensureSafeComponent } from '@embroider/util';

interface RequestTableItemPendingSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLTableRowElement;
}

export default class RequestTableItemPendingComponent extends Component<RequestTableItemPendingSignature> {
  get requestComponentName() {
    // @ts-expect-error
    let type = dasherize(this.args.request.requestType);
    return ensureSafeComponent(`request/table/cell/${type}`, this);
  }

  get requestAmount() {
    // @ts-expect-error
    let { request } = this.args;
    switch (request.requestType) {
      case 'mileage':
        return { value: request.amount.value, currency: request.amount.currency };
      case 'expense_report': {
        return request.amount;
      }
      case 'transfer':
        return { value: request.amount, currency: request.amountCurrency };
      case 'multi_transfer':
        return {
          value: request.totalTransfersAmount,
          currency: request.totalTransfersAmountCurrency,
        };
      case 'virtual_card':
        return { value: request.paymentMonthlyLimit, currency: request.currency };
      case 'flash_card':
        return { value: request.paymentLifespanLimit, currency: request.currency };
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Request::Table::Item::Pending': typeof RequestTableItemPendingComponent;
  }
}
