import { FormattedMessage } from 'react-intl';
import type { ReactElement } from 'react';
import { Button, LottiePlayer } from '@repo/design-system-kit';
import cx from 'clsx';
import { useThemedAssetPath } from 'qonto/react/hooks/use-themed-asset-path';
import styles from './styles.strict-module.css';

export interface IntroProps {
  goToNextStep: () => void;
}

export function Intro({ goToNextStep }: IntroProps): ReactElement {
  const lottiePath = useThemedAssetPath('/lotties/financing/pay-later/intro.json');

  return (
    <div className={styles['page-wrapper']}>
      <div className={styles.container} data-test-intro>
        <LottiePlayer
          autoplay
          className={cx(styles.illustration, 'mb-32')}
          data-test-intro-illustration=""
          loop={false}
          path={lottiePath}
        />

        <section className={styles.content}>
          <h1 className="title-1 mb-16" data-test-intro-title>
            <FormattedMessage id="financing.pay-later.application-flow.intro.title" />
          </h1>

          <p className="body-1 mb-32" data-test-intro-description>
            <FormattedMessage id="financing.pay-later.application-flow.intro.description" />
          </p>

          <Button data-test-intro-not-signed-cta onPress={goToNextStep} stretch>
            <FormattedMessage id="financing.pay-later.application-flow.intro.cta-not-signed" />
          </Button>
        </section>
      </div>
    </div>
  );
}
