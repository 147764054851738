export default {
  "container": "qUq",
  "link-grey": "qUZ",
  "slack-section-title": "qUR title-4 mb-8",
  "edit-channel-text": "qUQ body-2 mb-16",
  "rule-section-title": "qUe title-4 mb-16",
  "rule-cards-container": "qUB",
  "edit-slack-container": "qUE",
  "rule-card": "qUd",
  "add-rule-container": "qUI",
  "add-rule-link-button": "qUt",
  "add-rule-text": "qUM",
  "empty-state": "qUP",
  "empty-state-textblock": "qUl",
  "empty-state-title": "qUX title-2",
  "empty-state-description": "qUC body-2",
  "empty-state-illustration": "qUk",
  "rules": "qUJ"
};
