/* import __COLOCATED_TEMPLATE__ from './additional-settings.hbs'; */
// @ts-nocheck
import { next } from '@ember/runloop';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Disclaimer } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';

// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';
// @ts-expect-error
import scrollIntoView from 'qonto/utils/scroll-into-view';

interface FlowsSepaTransferAdditionalSetingsSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsSepaTransferAdditionalSetingsComponent extends Component<FlowsSepaTransferAdditionalSetingsSignature> {
  disclaimerBlock = Disclaimer.Block;

  @service declare abilities: Services['abilities'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare segment: Services['segment'];
  @service declare sentry: Services['sentry'];
  @service declare store: Services['store'];
  @service declare financing: Services['financing'];
  @service declare errors: Services['errors'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare intl: Services['intl'];

  @tracked isEmailNotificationValidationEnabled = false;
  @tracked isValidationEnabled = false;

  get requiredAttachmentFiles() {
    // @ts-expect-error
    let { invoice } = this.args.context;

    if (invoice) {
      return [invoice.attachment.file];
    }

    return [];
  }

  get sepaTransfer() {
    // @ts-expect-error
    return this.args.context.sepaTransfer;
  }

  get shouldShowInvalidInvoiceDisclaimer() {
    return (
      // @ts-expect-error
      // @ts-expect-error
      this.args.context.invoice?.isGermanEInvoice &&
      this.args.context.invoice?.isAttachmentNonFinancial
    );
  }

  handlePayLaterInstallments = dropTask(async () => {
    let { amount, amountCurrency } = this.sepaTransfer;

    // @ts-expect-error
    let { context } = this.args;
    let { invoice, payLaterErrors, isPayLaterEligible, didValidateFinancing } = context;

    context.didInstallmentsFail = false;

    if (!invoice || !didValidateFinancing || !isPayLaterEligible || payLaterErrors) {
      return;
    }

    try {
      // @ts-expect-error
      context.payLaterInstallments = await this.financing.getPayLaterTransferInstallments({
        value: amount,
        currency: amountCurrency,
      });
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
      context.didInstallmentsFail = true;
    }
  });

  onClickNextTask = dropTask(async (confirmTransferTask, sepaTransfer) => {
    // @ts-expect-error
    let { context, transitionToNext } = this.args;

    await sepaTransfer.validate();

    this.isValidationEnabled = true;
    this.isEmailNotificationValidationEnabled = true;

    let { attachments, email, vatAmount } = sepaTransfer.validations.attrs;
    let isEmailInvalid = sepaTransfer.notifyByEmail && email.isInvalid;

    if (attachments.isInvalid || isEmailInvalid || vatAmount.isInvalid) {
      this._scrollToErrorField();
      return;
    }

    if (!sepaTransfer.notifyByEmail) {
      sepaTransfer.email = sepaTransfer.originalBeneficiaryEmail;
    }

    if (this.abilities.can('read budget')) {
      try {
        // @ts-expect-error
        let filteredBudgets = await this.store.adapterFor('budget').search({
          initiatorId: this.organizationManager.membership.id,
          scheduledDate: sepaTransfer.scheduledDate,
        });
        if (filteredBudgets.length) {
          // @ts-expect-error
          this.args.context.budgetName = filteredBudgets[0].budget.name;
        }
      } catch (error) {
        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }
      }
    }

    let confirmationResult = await confirmTransferTask.perform(sepaTransfer);

    if (confirmationResult) {
      await this.handlePayLaterInstallments.perform();

      if (context.isDedicatedFlow && context.didInstallmentsFail) {
        // In Pay later transfer flow, user can only continue if we have the installments data
        this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
        return;
      }
      context.isInstantFallback = false;
      this._trackTransferConfirmation();
      transitionToNext();
    }
  });

  _scrollToErrorField() {
    next(() => scrollIntoView('[data-has-error]'));
  }

  _trackTransferConfirmation() {
    let { attachments, email, labels, note, notifyByEmail } = this.sepaTransfer;

    let hasAttachments = Boolean(attachments.length);
    let hasLabels = Boolean(labels.length);
    let hasNote = Boolean(note);
    let hasEmail = Boolean(notifyByEmail && email);

    // @ts-expect-error
    let { invoice, origin } = this.args.context;

    this.segment.track('transfer-sepa_additional-information_submitted', {
      ...(hasAttachments && { attachments: hasAttachments }),
      ...(hasLabels && { labels: hasLabels }),
      ...(hasNote && { note: hasNote }),
      ...(hasEmail && { email: hasEmail }),
      ...(invoice?.id && { flow: 'byinvoice' }),
      ...(origin && { origin }),
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Transfers::Sepa::New::AdditionalSettings': typeof FlowsSepaTransferAdditionalSetingsComponent;
  }
}
