export default {
  "details": "qhz",
  "header": "qha",
  "avatar": "qhH mr-16",
  "header-beneficiary": "qhO",
  "header-beneficiary-activity": "qhm",
  "header-beneficiary-details": "qhp",
  "header-beneficiary-details-recurring": "qhx",
  "infos": "qhw",
  "infos-title": "qhN",
  "infos-empty": "qhv",
  "infos-list": "qho",
  "infos-list-item": "qhn",
  "infos-list-item-left": "qhi",
  "infos-list-item-right": "qhA",
  "infos-list-item-attachment": "qhY",
  "infos-transactions": "qhh",
  "actions": "qhf"
};
