export default {
  "row": "Ci",
  "active": "CA",
  "status": "CY",
  "align-right": "Ch",
  "animated": "Cf",
  "fadein-item": "CK",
  "cell": "CG body-2",
  "cell-content": "Cr",
  "amount": "Cb body-1",
  "subtitle": "Cy caption",
  "no-padding": "CU",
  "note": "Cj"
};
