/* import __COLOCATED_TEMPLATE__ from './expression.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

interface FiltersPresetExpressionSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class FiltersPresetExpressionComponent extends Component<FiltersPresetExpressionSignature> {
  @service declare intl: Services['intl'];
  @service declare abilities: Services['abilities'];

  properties = [];

  arrayOperators = [
    // @ts-expect-error
    { key: 'in', label: this.intl.t('transactions.filters.operators.in') },
    // @ts-expect-error
    { key: 'not_in', label: this.intl.t('transactions.filters.operators.not-in') },
  ];

  booleanOperators = [
    // @ts-expect-error
    { key: 'not_exists', label: this.intl.t('transactions.filters.operators.not-exists') },
    // @ts-expect-error
    { key: 'exists', label: this.intl.t('transactions.filters.operators.exists') },
  ];

  conditionals = [
    // @ts-expect-error
    { key: 'and', label: this.intl.t('transactions.filters.conditionals.and') },
    // @ts-expect-error
    { key: 'or', label: this.intl.t('transactions.filters.conditionals.or') },
  ];

  dateOperators = [
    // @ts-expect-error
    { key: 'interval', label: this.intl.t('transactions.filters.operators.is') },
    // @ts-expect-error
    { key: 'within', label: this.intl.t('transactions.filters.operators.within'), type: 'range' },
    // @ts-expect-error
    { key: 'within', label: this.intl.t('transactions.filters.operators.on') },
    // @ts-expect-error
    { key: 'lt', label: this.intl.t('transactions.filters.operators.before') },
    // @ts-expect-error
    { key: 'gt', label: this.intl.t('transactions.filters.operators.after') },
    // @ts-expect-error
    { key: 'lte', label: this.intl.t('transactions.filters.operators.on-before') },
    // @ts-expect-error
    { key: 'gte', label: this.intl.t('transactions.filters.operators.on-after') },
  ];

  numberOperators = [
    // @ts-expect-error
    { key: 'eq', label: this.intl.t('transactions.filters.operators.eq') },
    // @ts-expect-error
    { key: 'not_eq', label: this.intl.t('transactions.filters.operators.not-eq') },
    // @ts-expect-error
    { key: 'lte', label: this.intl.t('transactions.filters.operators.lte') },
    // @ts-expect-error
    { key: 'lt', label: this.intl.t('transactions.filters.operators.lt') },
    // @ts-expect-error
    { key: 'gte', label: this.intl.t('transactions.filters.operators.gte') },
    // @ts-expect-error
    { key: 'gt', label: this.intl.t('transactions.filters.operators.gt') },
  ];

  constructor(owner: unknown, args: FiltersPresetExpressionSignature['Args']) {
    super(owner, args);

    this._setProperties();
  }

  get selectedConditional() {
    return (
      // @ts-expect-error
      this.conditionals.find(it => it.key === this.args.group.conditional) || this.conditionals[0]
    );
  }

  get selectedProperty() {
    // @ts-expect-error
    return this.properties.find(it => it.key === this.args.expression.property);
  }

  get selectedOperator() {
    // @ts-expect-error
    let { operator, operatorType } = this.args.expression;

    if (operatorType === 'range') {
      // @ts-expect-error
      return this.operators.find(({ key, type }) => key === operator && type === operatorType);
    }

    return (
      // @ts-expect-error
      this.operators.find(({ key, type }) => key === operator && type !== 'range') ||
      this.operators[0]
    );
  }

  get operators() {
    // @ts-expect-error
    return this._getPropertyOperators(this.args.expression.property);
  }

  get isDisabledOperatorProperty() {
    // @ts-expect-error
    let { disabledOperatorProperties, expression } = this.args;

    return disabledOperatorProperties?.includes(expression.property);
  }

  get isDisabledOperator() {
    // @ts-expect-error
    return this.args.disabled || this.isDisabledOperatorProperty;
  }

  get hasValues() {
    // @ts-expect-error
    return !['exists', 'not_exists'].includes(this.args.expression.operator);
  }

  @action
  // @ts-expect-error
  updateConditional({ key }) {
    // @ts-expect-error
    let { updateConditional, onUpdate, expression } = this.args;

    updateConditional(key);
    if (expression.values?.length || !this.hasValues) {
      onUpdate?.();
    }
  }

  @action
  // @ts-expect-error
  updateProperty({ key }) {
    let operator = this._getPropertyOperators(key)[0].key;
    let operatorType = this._getPropertyOperators(key)[0].type;

    // @ts-expect-error
    this.args.expression.showValidations = false;
    // @ts-expect-error
    this.args.updateExpression(key, operator, [], operatorType);
  }

  @action
  // @ts-expect-error
  updateOperator({ key, type }) {
    // @ts-expect-error
    let { property } = this.args.expression;
    // @ts-expect-error
    this.args.updateExpression(property, key, [], type);

    if (!this.hasValues) {
      // @ts-expect-error
      this.args.onUpdate?.();
    }
  }

  @action
  // @ts-expect-error
  updateValues(values) {
    // @ts-expect-error
    let { property, operator, operatorType } = this.args.expression;
    // @ts-expect-error
    this.args.updateExpression(property, operator, values, operatorType);
  }

  _setProperties() {
    let showVerificationProperty = this.abilities.can('qualify for accounting in transaction');

    let verificationProperty = {
      key: 'qualified_for_accounting',
      label: this.intl.t('transactions.filters.properties.verification'),
    };

    let showMemberProperty = this.abilities.can('read memberships');
    let memberProperty = {
      key: 'initiator_id',
      label: this.intl.t('labels.member_select'),
    };

    // @ts-expect-error
    let labelLists = this.args.labelLists.map(it => ({
      key: it.id,
      label: it.name,
      color: it.color,
    }));
    let teamsProperty = {
      key: 'team_id',
      label: this.intl.t('transactions.filters.properties.team'),
    };

    let properties = [
      this.abilities.can('filter team') ? teamsProperty : null,
      { key: 'operation_method', label: this.intl.t('labels.operation_type') },
      showMemberProperty ? memberProperty : undefined,
      this.abilities.can('assign category')
        ? { key: 'category_assignment_category_id', label: this.intl.t('labels.activity_tag') }
        : { key: 'activity_tag', label: this.intl.t('labels.activity_tag') },
      { key: 'attachment_status', label: this.intl.t('labels.attachment') },
      showVerificationProperty ? verificationProperty : undefined,
      { key: 'note', label: this.intl.t('labels.note') },
      { key: 'status', label: this.intl.t('labels.transaction_status') },
      { key: 'vat_completeness', label: this.intl.t('labels.vat') },
      { key: 'amount', label: this.intl.t('labels.amount') },
      { key: 'emitted_at', label: this.intl.t('transactions.filters.properties.payment-date') },
      { key: 'updated_at', label: this.intl.t('transactions.filters.properties.updated-date') },
      { key: 'settled_at', label: this.intl.t('transactions.filters.properties.settlement-date') },
      ...labelLists,
    ];

    // @ts-expect-error
    let selectedPropertiesKeys = this.args.group.expressions.map(it => it.property);
    let validProperties = properties.filter(
      // @ts-expect-error
      property => Boolean(property) && !this.args.excludedProperties?.includes(property.key)
    );

    validProperties.forEach(
      property => (property.disabled = selectedPropertiesKeys.includes(property.key))
    );

    // @ts-expect-error
    this.properties = validProperties;
  }

  // @ts-expect-error
  _getPropertyOperators(property) {
    let { arrayOperators, booleanOperators, numberOperators, dateOperators } = this;
    let mixedOperators = [...arrayOperators, ...booleanOperators];

    let operators = {
      operation_method: arrayOperators,
      initiator_id: mixedOperators,
      activity_tag: mixedOperators,
      attachment_status: arrayOperators,
      qualified_for_accounting: [
        { key: 'eq', label: this.intl.t('transactions.filters.operators.is') },
      ],
      note: booleanOperators,
      status: arrayOperators,
      vat_completeness: arrayOperators,
      amount: numberOperators,
      emitted_at: dateOperators,
      updated_at: dateOperators,
      settled_at: dateOperators,
    };

    // @ts-expect-error
    return operators[property] || mixedOperators;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Filters::Preset::Expression': typeof FiltersPresetExpressionComponent;
  }
}
