import Model, { attr, belongsTo } from '@ember-data/model';
import { underscore } from '@ember/string';
import { waitFor } from '@ember/test-waiters';

// @ts-expect-error
import { apiAction } from '@mainmatter/ember-api-actions';
import dayjs from 'dayjs';

import { Addons } from 'qonto/constants/addons';

const INITIAL_TRIAL_UPSELL_CUTOFF_DAYS = 1;
export default class OrganizationSubscriptionNewModel extends Model {
  @attr('string') declare status: string;
  @attr('string') declare nextRecurrenceDate: string;
  @attr('string') declare nextSubscriptionBillingDate: string;
  @attr('string') declare nextInvoicingDate: string;
  @attr('string') declare recurrence: string;
  // @ts-expect-error
  @attr nextBillingDate;
  // @ts-expect-error
  @attr activeTrial;
  // @ts-expect-error
  @attr trialByFeatures;
  // @ts-expect-error
  @attr('hash') availableTrials;
  // @ts-expect-error
  @attr productAnnualPriceVatIncluded;
  // @ts-expect-error
  @attr productMonthlyPriceVatIncluded;

  // @ts-expect-error
  @belongsTo('organization', { async: true, inverse: null }) organization;
  // @ts-expect-error
  @belongsTo('subscription-product', { async: false, inverse: null }) product;

  get activeTrialRemainingDays() {
    let { activeTrial } = this;
    if (!activeTrial) {
      return 0;
    }
    // Compare dates by day and hours, ex: when less than 23 hours differenceInDays will return 0
    let endDate = dayjs(activeTrial.end_date).endOf('day');
    let today = dayjs().startOf('hour');
    return endDate.diff(today, 'day');
  }

  get hasInitialTrial() {
    let { activeTrial } = this;

    if (!activeTrial) {
      return false;
    }
    return !activeTrial.previous_product_id && !activeTrial.previous_recurrence;
  }

  get hasInitialTrialUpsell() {
    return this.hasInitialTrial && this.activeTrialRemainingDays > INITIAL_TRIAL_UPSELL_CUTOFF_DAYS;
  }

  @waitFor
  async estimate() {
    // @ts-expect-error
    let { organization_id, product_id, recurrence } = this.serialize();
    let data = { subscription: { organization_id, product_id, recurrence } };
    return await apiAction(this, { method: 'POST', path: 'estimate', data });
  }

  @waitFor
  async inactivateAddon() {
    return await apiAction(this, { method: 'PATCH', data: { active: false } });
  }

  // @ts-expect-error
  findTrial(feature) {
    if (!feature) {
      return null;
    }
    let id = this.trialByFeatures?.[underscore(feature)];
    if (!id) {
      return null;
    }
    // @ts-expect-error
    return this.availableTrials?.find(({ productId }) => productId === id) || null;
  }

  get arAddonTrial() {
    // @ts-expect-error
    return this.availableTrials.find(trial => trial.productCode === Addons.AccountsReceivable);
  }
}

export interface Subscription {
  status: string;
  nextRecurrenceDate: string;
  nextSubscriptionBillingDate: string;
  nextInvoicingDate: string;
  recurrence: string;
  nextBillingDate: any;
  activeTrial: any;
  trialByFeatures: any;
  availableTrials: Record<string, any>;
  productAnnualPriceVatIncluded: any;
  productMonthlyPriceVatIncluded: any;

  organization: Promise<any>;
  product: any;

  activeTrialRemainingDays: number;
  hasInitialTrial: boolean;
  hasInitialTrialUpsell: boolean;

  estimate(): Promise<any>;
  inactivateAddon(): Promise<any>;
  findTrial(feature: string): any | null;
  arAddonTrial: any;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'organization-subscription': OrganizationSubscriptionNewModel;
  }
}
