export default {
  "wrapper": "czL",
  "header": "czT",
  "fadeIn": "czz",
  "header-title": "cza title-4",
  "transactions-container": "czH",
  "placeholder-title": "czO",
  "transaction-item-list-container": "czm",
  "transaction-item-list": "czp",
  "item": "czx"
};
