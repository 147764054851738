import { attr, belongsTo } from '@ember-data/model';
import { waitFor } from '@ember/test-waiters';

// @ts-expect-error
import { apiAction } from '@mainmatter/ember-api-actions';
import { reads } from 'macro-decorators';

import CURRENCIES from 'qonto/constants/currencies';
import Subject from 'qonto/models/subject';

export const ERROR_GENERIC_REQUEST_REFUND_ISSUE = 6000;
export const ERROR_CANNOT_REFUND = 6001;
export const ERROR_REFUND_ALREADY_REQUESTED = 6002;

export default class DirectDebitModel extends Subject {
  @attr('number') declare amount: number;
  @attr('string', { defaultValue: CURRENCIES.default }) declare amountCurrency: string;

  @attr('string') declare emitterBic: string;
  @attr('string') declare emitterIban: string;
  @attr('string') declare emitterIdentification: string;
  @attr('string') declare emitterName: string;
  @attr('string') declare reference: string;
  @attr('boolean', { defaultValue: true }) declare isIBANAccount: boolean;
  @attr('string', { defaultValue: 'iban' }) declare accountType: string;
  // @ts-expect-error
  @attr('hash', {
    defaultValue: () => {
      return {};
    },
  })
  // @ts-expect-error
  enrichmentData;

  // @ts-expect-error
  @attr refundable;
  @attr('date') declare refundRequestedAt: Date;

  // @ts-expect-error
  @belongsTo('organization', { async: true, inverse: null }) organization;
  // @ts-expect-error
  @belongsTo('bank-account', { async: true, inverse: null }) bankAccount;

  // @ts-expect-error
  @reads('emitterBic') bic;
  // @ts-expect-error
  @reads('emitterIban') iban;

  @waitFor
  async requestRefund() {
    try {
      let response = await apiAction(this, { method: 'PUT', path: 'request_refund' });
      this.store.pushPayload(response);
    } catch (error) {
      // if the backend replies with an error that the direct debit refund
      // was already requested we will update our copy of the direct
      // debit accordingly
      // @ts-expect-error
      if (error.code === ERROR_REFUND_ALREADY_REQUESTED) {
        this.store.pushPayload({
          direct_debit: {
            id: this.id,
            refundable: false,
            refund_requested_at: new Date().toISOString(),
          },
        });
      }

      // if the backend replies with an error that the direct debit can not be
      // refunded anymore we will update our copy of the direct
      // debit accordingly
      // @ts-expect-error
      if (error.code === ERROR_CANNOT_REFUND) {
        this.store.pushPayload({
          direct_debit: {
            id: this.id,
            refundable: false,
          },
        });
      }

      throw error;
    }
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'direct-debit': DirectDebitModel;
  }
}
