/* import __COLOCATED_TEMPLATE__ from './non-eligible.hbs'; */
import Component from '@glimmer/component';

import { Button } from '@repo/design-system-kit';

interface Signature {
  Args: {
    data: {
      title: string;
      subtitle: string;
    };
    close: () => void;
  };
}

export default class TransfersInternationalOutModalsNonEligibleComponent extends Component<Signature> {
  button = Button;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transfers::InternationalOut::Modals::NonEligible': typeof TransfersInternationalOutModalsNonEligibleComponent;
  }
}
