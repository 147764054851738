export default {
  "row": "OP",
  "icon": "Ol",
  "cell": "OX",
  "empty": "OC",
  "no-padding": "Ok",
  "cell-content": "OJ",
  "right": "Os",
  "cube": "OF",
  "row--sidebar-open": "Og",
  "status": "OD"
};
