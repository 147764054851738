import Model, { attr } from '@ember-data/model';
import { service, type Registry as Services } from '@ember/service';

import { type Column, DEFAULT_COLUMNS, type DisplayColumn } from 'qonto/constants/table-view';

export default class TableViewModel extends Model {
  @service declare store: Services['store'];

  @attr('string') name!: string;
  @attr('string') resource!: string;
  @attr('number') position!: number;

  @attr() columns!: Column[];

  static updateColumn(columns: DisplayColumn[], updatedColumn: DisplayColumn) {
    return columns.map(column =>
      column.id === updatedColumn.id ? { ...column, ...updatedColumn } : column
    );
  }

  static getVisibleAndHiddenColumns(view: TableViewModel): DisplayColumn[] {
    let visibleColumns: DisplayColumn[] = (view ? view.columns : DEFAULT_COLUMNS).map(column => {
      if ('isVisible' in column) {
        return column as DisplayColumn;
      }

      return {
        ...column,
        isVisible: true,
      } as DisplayColumn;
    });

    let hiddenColumns: DisplayColumn[] = DEFAULT_COLUMNS.filter(
      defaultColumn => !visibleColumns.some(column => column.id === defaultColumn.id)
    ).map(column => ({
      ...column,
      isVisible: false,
    }));

    return [...visibleColumns, ...hiddenColumns];
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'table-view': TableViewModel;
    tableView: TableViewModel;
  }
}
