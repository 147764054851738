/* import __COLOCATED_TEMPLATE__ from './digital-card.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface DigitalCardSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const DigitalCard = templateOnlyComponent<DigitalCardSignature>();

export default DigitalCard;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Cards::ChooseCard::DigitalCard': typeof DigitalCard;
  }
}
