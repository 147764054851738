/* import __COLOCATED_TEMPLATE__ from './email-preview.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import {
  DIRECT_DEBIT_SUBSCRIPTION_SCHEDULE_TYPES,
  FREQUENCY_CUSTOM_UNITS,
  // @ts-expect-error
} from 'qonto/constants/direct-debit-subscriptions';
import { EmailPreviewFooter } from 'qonto/react/components/receivable-invoices/email-preview';

interface DirectDebitCollectionsEmailPreviewSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class DirectDebitCollectionsEmailPreviewComponent extends Component<DirectDebitCollectionsEmailPreviewSignature> {
  EmailPreviewFooter = EmailPreviewFooter;

  @service declare intl: Services['intl'];
  @service declare theme: Services['theme'];

  get footerDisclaimer() {
    return this.intl
      .t('sdd-collections.creation-flow.payment-link.email.qonto-disclaimer', {
        // @ts-expect-error
        language: this.args.language,
        htmlSafe: true,
      })
      .toString();
  }

  get scheduleLabel() {
    let { scheduleType, scheduleCustomInterval, scheduleCustomUnit } =
      // @ts-expect-error
      this.args.mandate.paymentInfo;

    // @ts-expect-error
    let language = this.args.language;
    let recurrence;

    switch (scheduleType) {
      case DIRECT_DEBIT_SUBSCRIPTION_SCHEDULE_TYPES.WEEKLY:
        return this.intl.t('sdd-collections.creation-flow.payment-link.email.recurrence.weekly', {
          language,
        });
      case DIRECT_DEBIT_SUBSCRIPTION_SCHEDULE_TYPES.MONTHLY:
        return this.intl.t('sdd-collections.creation-flow.payment-link.email.recurrence.monthly', {
          language,
        });
      case DIRECT_DEBIT_SUBSCRIPTION_SCHEDULE_TYPES.QUARTERLY:
        return this.intl.t(
          'sdd-collections.creation-flow.payment-link.email.recurrence.quarterly',
          { language }
        );
      case DIRECT_DEBIT_SUBSCRIPTION_SCHEDULE_TYPES.YEARLY:
        return this.intl.t('sdd-collections.creation-flow.payment-link.email.recurrence.yearly', {
          language,
        });
      case DIRECT_DEBIT_SUBSCRIPTION_SCHEDULE_TYPES.CUSTOM:
        switch (scheduleCustomUnit) {
          case FREQUENCY_CUSTOM_UNITS.WEEKS: {
            recurrence = this.intl.t(
              'sdd-collections.creation-flow.payment-link.email.recurrence.weeks',
              { language }
            );
            break;
          }
          case FREQUENCY_CUSTOM_UNITS.MONTHS: {
            recurrence = this.intl.t(
              'sdd-collections.creation-flow.payment-link.email.recurrence.months',
              { language }
            );
            break;
          }
          case FREQUENCY_CUSTOM_UNITS.YEARS: {
            recurrence = this.intl.t(
              'sdd-collections.creation-flow.payment-link.email.recurrence.years',
              { language }
            );
            break;
          }
        }

        return this.intl.t('sdd-collections.creation-flow.payment-link.email.recurrence.custom', {
          language,
          recurrence,
          number: scheduleCustomInterval,
        });
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'DirectDebitCollections::EmailPreview': typeof DirectDebitCollectionsEmailPreviewComponent;
  }
}
