/* import __COLOCATED_TEMPLATE__ from './due-date-selector.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { parseDate } from '@internationalized/date';
import { DatePicker } from '@repo/design-system-kit';
import dayjs from 'dayjs';

// @ts-expect-error
import { DATE_PICKER_FIELD_FORMAT } from 'qonto/constants/dates';
// @ts-expect-error
import { differenceInCalendar } from 'qonto/utils/date';

export const COMMON_CREDIT_PERIODS = [0, 15, 30];

export const COMMON_QUOTE_EXPIRY_PERIODS = [15, 30, 90];

const CREDIT_PERIODS_TRACKING_EVENTS = {
  [-1]: { period: 'custom' },
  0: { period: 'today' },
  15: { period: 'in15days' },
  30: { period: 'in30days' },
};

interface ReceivableInvoicesFormDueDateSelectorSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class ReceivableInvoicesFormDueDateSelectorComponent extends Component<ReceivableInvoicesFormDueDateSelectorSignature> {
  @service declare intl: Services['intl'];
  @service declare segment: Services['segment'];
  // @ts-expect-error
  @tracked expiryDate;
  // @ts-expect-error
  @tracked dueDate;
  @tracked selectedOption = this.defaultSelectedOption();

  datePicker = DatePicker;

  get date() {
    // Accessing tracked properties to create dependencies
    // eslint-disable-next-line no-unused-expressions
    this.expiryDate;
    // eslint-disable-next-line no-unused-expressions
    this.dueDate;

    // @ts-expect-error
    if (this.args.isQuote) {
      // @ts-expect-error
      return this.args.invoice.expiryDate ? parseDate(this.args.invoice.expiryDate) : null;
    } else {
      // @ts-expect-error
      return this.args.invoice.dueDate ? parseDate(this.args.invoice.dueDate) : null;
    }
  }

  @action
  // @ts-expect-error
  onDateChange(date) {
    // @ts-expect-error
    if (this.args.isQuote) {
      // @ts-expect-error
      this.args.invoice.expiryDate = date?.toString();
      // Setting tracked property to trigger reactivity
      this.expiryDate = date?.toString();
    } else {
      // Setting tracked property to trigger reactivity
      // @ts-expect-error
      this.args.invoice.dueDate = date?.toString();
      this.dueDate = date?.toString();
    }
  }

  get minDate() {
    // @ts-expect-error
    let { invoice } = this.args;
    return invoice.issueDate ? parseDate(invoice.issueDate) : null;
  }

  get dateSelectorOptions() {
    // @ts-expect-error
    let options = this.args.isQuote ? COMMON_QUOTE_EXPIRY_PERIODS : COMMON_CREDIT_PERIODS;
    return [...options, -1].map(value => {
      let label = '';
      let clearable = false;
      if (value > 0) {
        label = this.intl.t(
          'receivable-invoices.invoice-creation.invoice-details.due-date.options.relative',
          { number: value }
        );
      } else if (value === 0) {
        label = this.intl.t(
          'receivable-invoices.invoice-creation.invoice-details.due-date.options.on-receipt',
          { number: value }
        );
      } else {
        label = this.intl.t(
          'receivable-invoices.invoice-creation.invoice-details.due-date.options.custom'
        );
        clearable = true;
      }

      return {
        clearable,
        label,
        value,
      };
    });
  }

  defaultSelectedOption() {
    // @ts-expect-error
    return this.args.isQuote
      ? this._defaultSelectedExpiryPeriod()
      : this._defaultSelectedCreditPeriod();
  }

  _defaultSelectedExpiryPeriod() {
    // @ts-expect-error
    if (!this.args.invoice.expiryDate) return undefined;

    let currentExpiryPeriod = differenceInCalendar(
      // @ts-expect-error
      this.args.invoice.expiryDate,
      // @ts-expect-error
      this.args.invoice.issueDate,
      'day'
    );

    let selectedPeriod = { clearable: true, value: -1 };
    if (COMMON_QUOTE_EXPIRY_PERIODS.includes(currentExpiryPeriod)) {
      selectedPeriod = {
        clearable: false,
        value: currentExpiryPeriod,
      };
    }

    // @ts-expect-error
    this.args.onPeriodChange?.(selectedPeriod.value);
    return selectedPeriod;
  }

  _defaultSelectedCreditPeriod() {
    // @ts-expect-error
    if (!this.args.invoice.dueDate) return undefined;

    let currentCreditPeriod = differenceInCalendar(
      // @ts-expect-error
      this.args.invoice.dueDate,
      // @ts-expect-error
      this.args.invoice.issueDate,
      'day'
    );

    let selectedPeriod = { clearable: true, value: -1 };
    if (COMMON_CREDIT_PERIODS.includes(currentCreditPeriod)) {
      selectedPeriod = {
        clearable: false,
        value: currentCreditPeriod,
      };
    }

    // @ts-expect-error
    this.args.onPeriodChange?.(selectedPeriod.value);
    return selectedPeriod;
  }

  // @ts-expect-error
  @action updateExpiryOrDueDateOption(option) {
    this.selectedOption = option;
    // @ts-expect-error
    this.args.onPeriodChange?.(option.value);

    // @ts-expect-error
    return this.args.isQuote
      ? this.updateExpiryDateOption(option)
      : this.updateDueDateOption(option);
  }

  // @ts-expect-error
  updateExpiryDateOption(option) {
    if (option.value >= 0) {
      // @ts-expect-error
      this.args.invoice.expiryDate = dayjs(this.args.invoice.issueDate)
        .add(option.value, 'day')
        .format(DATE_PICKER_FIELD_FORMAT);
      return;
    }

    // @ts-expect-error
    this.args.invoice.expiryDate = undefined;
    this.expiryDate = undefined;
  }

  // @ts-expect-error
  updateDueDateOption(option) {
    if (option.value !== undefined) {
      // @ts-expect-error
      this.segment.track('invoice_due_date-change', CREDIT_PERIODS_TRACKING_EVENTS[option.value]);
    }

    if (option.value >= 0) {
      // @ts-expect-error
      this.args.invoice.dueDate = dayjs(this.args.invoice.issueDate)
        .add(option.value, 'day')
        .format(DATE_PICKER_FIELD_FORMAT);
      return;
    }

    // @ts-expect-error
    this.args.invoice.dueDate = undefined;
    this.dueDate = undefined;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ReceivableInvoices::Form::DueDateSelector': typeof ReceivableInvoicesFormDueDateSelectorComponent;
  }
}
