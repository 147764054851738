import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { service, type Registry as Services } from '@ember/service';
import { waitFor } from '@ember/test-waiters';

import dayjs from 'dayjs';

import CURRENCIES from 'qonto/constants/currencies';
// @ts-expect-error
import { DATE_PICKER_FIELD_FORMAT } from 'qonto/constants/dates';

/**
 * @typedef {Object} SupplierSnapshot
 * @property {String} name eg. "Apple Inc."
 * @property {String} address first line of address eg. "Sesame street 6"
 * @property {String} zipCode eg. "50-306"
 * @property {String} city eg. "Paris"
 * @property {String} countryCode eg. "FR"
 * @property {String} [vatNumber] suppliers vat number
 * @property {"RF18"} taxRegime hardcoded Italian fiscal regime
 *
 * @typedef {Object} SupplierInvoiceSnapshot
 * @property {String} id related supplier invoice uuid
 * @property {String} [number] related supplier invoice number
 * @property {String} [issueDate] related supplier invoice number eg. "2022-01-20"
 */
export default class SelfInvoiceModel extends Model {
  /** @type {'TD17 | TD18 | TD19'} */
  // @ts-expect-error
  @attr documentType;
  /** @type {string} YYYY-MM-DD */
  @attr('string', { defaultValue: () => dayjs().format(DATE_PICKER_FIELD_FORMAT) })
  declare issueDate: string;
  /** @type {string} */
  // @ts-expect-error
  @attr number;
  @attr('string', { defaultValue: CURRENCIES.default }) declare currency: string;
  /** @type {string} */
  // @ts-expect-error
  @attr locale;
  /** @type {null|'approved'|'not_delivered'|'pending'|'submitted'|'rejected'} */
  // @ts-expect-error
  @attr einvoicingStatus;
  /** @type {'created | canceled'} */
  // @ts-expect-error
  @attr status;
  /** @type {SupplierSnapshot} */
  // @ts-expect-error
  @attr('hash', {
    defaultValue: () => {
      return {};
    },
  })
  // @ts-expect-error
  supplierSnapshot;
  /** @type {SupplierInvoiceSnapshot} */
  // @ts-expect-error
  @attr('hash', {
    defaultValue: () => {
      return {};
    },
  })
  // @ts-expect-error
  supplierInvoiceSnapshot;
  /** @type {Date} */
  // @ts-expect-error
  @attr updatedAt;
  /** @type {Date} */
  // @ts-expect-error
  @attr createdAt;
  /** @type {Date} */
  // @ts-expect-error
  @attr canceledAt;
  /** @type {String} */
  // @ts-expect-error
  @attr amountDue;

  /** @type {OrganizationModel} */
  // @ts-expect-error
  @belongsTo('organization', { async: false, inverse: null }) organization;
  /** @type {SupplierInvoiceModel} */
  // @ts-expect-error
  @belongsTo('supplier-invoice', { async: false, inverse: null }) supplierInvoice;
  /** @type {Item} */
  // @ts-expect-error
  @hasMany('receivable-invoice/item', { async: false, inverse: 'selfInvoice' }) items;

  @service declare networkManager: Services['networkManager'];
  @service declare store: Services['store'];

  get isNotValid() {
    return (
      ['submission_failed', 'declined'].includes(this.einvoicingStatus) ||
      this.status === 'canceled'
    );
  }

  get totalAmount() {
    let totalAmount = parseFloat(this.totalVat) + parseFloat(this.totalExcludingVat);
    return totalAmount.toFixed(2);
  }

  get totalVat() {
    return (
      this.items
        // @ts-expect-error
        .reduce((total, item) => {
          return parseFloat(total) + parseFloat(item.totalVat);
        }, 0)
        .toFixed(2)
    );
  }

  get totalExcludingVat() {
    return (
      this.items
        // @ts-expect-error
        .reduce((total, item) => {
          return parseFloat(total) + parseFloat(item.totalExcludingVat);
        }, 0)
        .toFixed(2)
    );
  }

  get pdfUrl() {
    // @ts-expect-error
    return this.store.adapterFor('self-invoice').urlForPdf(this.id);
  }

  @waitFor
  // @ts-expect-error
  async save() {
    try {
      return await super.save(...arguments);
    } catch (error) {
      // @ts-expect-error
      if (error.isAdapterError) {
        this._assignItemsErrors(error);
      }
      throw error;
    }
  }

  // @ts-expect-error
  _assignItemsErrors(error) {
    // reduce
    // [
    //   { attribute: "items/0/title", message: "required" }
    //   { attribute: "items/1/title", message: "required" }
    // ]
    // to
    // {
    //   0: { "title": ["required"] }
    //   1: { "title": ["required"] }
    // }
    // then assign to items
    let parsedErrors = this.errors.reduce((errorsForItems, error) => {
      let [invoiceAttr, index, attribute] = error.attribute.split('/');
      if (invoiceAttr === 'items') {
        error = { attribute, message: error.message };
        errorsForItems[index] = errorsForItems[index] || {};
        errorsForItems[index][attribute] = errorsForItems[index][attribute] || [];
        errorsForItems[index][attribute].push(error.message);
      }
      return errorsForItems;
    }, {});

    Object.entries(parsedErrors).forEach(([index, parsedErrorsForItem]) => {
      // @ts-expect-error
      this.networkManager.errorModelInjector(this.items.at(index), parsedErrorsForItem, error);
    });
  }

  clearItemsWithNoId() {
    // @ts-expect-error
    this.items.filter(item => item.get('id') === null).forEach(item => item.deleteRecord());
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'self-invoice': SelfInvoiceModel;
  }
}
