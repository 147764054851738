export default {
  "container": "cXC",
  "form-container": "cXk",
  "title": "cXJ",
  "title-border": "cXs",
  "tabs-container": "cXF",
  "preview-container": "cXg",
  "email-preview-container": "cXD",
  "close-button": "cXV btn btn--icon-only btn--tertiary btn--large"
};
