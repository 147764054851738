/* import __COLOCATED_TEMPLATE__ from './card.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface CardSignature {
  Element: HTMLDivElement;
  Args: {};
  Blocks: {
    default: [];
  };
}

const Card = templateOnlyComponent<CardSignature>();

export default Card;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Request::Sidebar::Card': typeof Card;
  }
}
