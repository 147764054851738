import React from 'react';
import { useIntl } from 'react-intl';
import { clsx } from 'clsx';
import { IconAmericanExpress } from '../../assets/icons/icon-american-express.tsx';
import { IconMastercard } from '../../assets/icons/icon-mastercard.tsx';
import { IconVisa } from '../../assets/icons/icon-visa.tsx';
import styles from './credit-card-icons.strict-module.css';

export function CreditCardIcons({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>): React.ReactNode {
  const { formatMessage } = useIntl();

  return (
    <div className={clsx(className, styles.icons)} {...props}>
      <IconVisa
        aria-label={formatMessage({
          id: 'receivable-invoices.public-payment-link.credit-card.visa-aria-label',
        })}
      />
      <IconMastercard
        aria-label={formatMessage({
          id: 'receivable-invoices.public-payment-link.credit-card.mastercard-aria-label',
        })}
      />
      <IconAmericanExpress
        aria-label={formatMessage({
          id: 'receivable-invoices.public-payment-link.credit-card.american-express-aria-label',
        })}
      />
    </div>
  );
}
