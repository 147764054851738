export default {
  "container": "cvI",
  "title-and-trash": "cvt",
  "trash": "cvM",
  "quantity-amount": "cvP",
  "flex-row": "cvl",
  "quantity": "cvX",
  "quantityInput": "cvC",
  "border-left": "cvk",
  "dropdown": "cvJ",
  "item-row": "cvs",
  "unit-price": "cvF",
  "price-input": "cvg",
  "hidden": "cvD",
  "d-none": "cvV",
  "dropdown-option": "cvL",
  "list-row": "cvT",
  "row-title": "cvz",
  "row-price": "cva body-2",
  "row-missing-details-container": "cvH",
  "row-missing-details-label": "cvO body-2",
  "warning-icon": "cvm",
  "row-internal-note": "cvp",
  "with-internal-note": "cvx",
  "dropdown-content": "cvw",
  "select-item": "cvN"
};
