/* eslint-disable @qonto/no-redirect-in-unrelated-hooks, require-await */
import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { task } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';
import window from 'ember-window-mock';

import { GMI_SOLUTION_ID_PREFIX } from 'qonto/constants/connect';
import { SOLUTION_INSTANCE_EVENTS } from 'qonto/constants/listeners';
import SOLUTION_INSTANCE_STATUS from 'qonto/constants/solution-instance';

export default class ConnectHubHubApplicationSetupRoute extends Route {
  @service deviceManager;
  @service menu;
  @service notifierManager;
  @service organizationManager;
  @service router;
  @service store;
  @service webviewManager;

  detailsRouteName = 'settings.connect-hub.applications.hub-application.details';
  hubApplicationRouteName = 'settings.connect-hub.applications.hub-application';

  activate() {
    if (this.menu.isVisible) {
      this.menu.hide();
    }
  }

  resetController(controller) {
    controller.closePopup();
  }

  async _handleSolutionCreated({ solution_instance_id: id }) {
    let solutionInstance = this._getSolutionInstanceById(id);
    let gmiSolutionInstance = this._getGMISolutionInstanceById(id);

    if (solutionInstance) solutionInstance.status = SOLUTION_INSTANCE_STATUS.CREATED;
    if (gmiSolutionInstance) gmiSolutionInstance.status = SOLUTION_INSTANCE_STATUS.CREATED;

    await solutionInstance.getWizardURL();
  }

  _handleSolutionEnabled({ solution_instance_id: id }) {
    let solutionInstance = this._getSolutionInstanceById(id);
    let gmiSolutionInstance = this._getGMISolutionInstanceById(id);

    if (solutionInstance) solutionInstance.status = SOLUTION_INSTANCE_STATUS.ENABLED;
    if (gmiSolutionInstance) gmiSolutionInstance.status = SOLUTION_INSTANCE_STATUS.ENABLED;
  }

  async _handleSolutionFailed({ solution_instance_id: id }) {
    let solutionInstance = this._getSolutionInstanceById(id);
    let gmiSolutionInstance = this._getGMISolutionInstanceById(id);

    if (solutionInstance) solutionInstance.status = SOLUTION_INSTANCE_STATUS.FAILED;
    if (gmiSolutionInstance) gmiSolutionInstance.status = SOLUTION_INSTANCE_STATUS.FAILED;
  }

  _setListeners() {
    this.notifierManager.on(SOLUTION_INSTANCE_EVENTS.CREATED, this, '_handleSolutionCreated');
    this.notifierManager.on(SOLUTION_INSTANCE_EVENTS.ENABLED, this, '_handleSolutionEnabled');
    this.notifierManager.on(SOLUTION_INSTANCE_EVENTS.FAILED, this, '_handleSolutionFailed');
  }

  _removeListeners() {
    this.notifierManager.off(SOLUTION_INSTANCE_EVENTS.CREATED, this, '_handleSolutionCreated');
    this.notifierManager.off(SOLUTION_INSTANCE_EVENTS.ENABLED, this, '_handleSolutionEnabled');
    this.notifierManager.off(SOLUTION_INSTANCE_EVENTS.FAILED, this, '_handleSolutionFailed');
  }

  _getSolutionInstanceById(solutionInstanceId) {
    return this.store.peekRecord('solution-instance', solutionInstanceId);
  }

  _getGMISolutionInstanceById(solutionInstanceId) {
    return this.store
      .peekAll('gmi-solution-instance')
      .find(record => record.solutionInstanceId === solutionInstanceId);
  }

  beforeModel(params) {
    this.fromTransition = params.from;
  }

  setupController(controller, model) {
    super.setupController(controller, model);
    controller.set('fromTransition', this.fromTransition);
  }

  async model() {
    let { applicationSlug, organization, prismicData } = this.modelFor(
      this.hubApplicationRouteName
    );
    let solutionId = prismicData.tray_solution_id;

    if (!solutionId) {
      return this.router.replaceWith(this.detailsRouteName);
    }

    this._setListeners();

    let solutionInstance = await this.initSolutionTask.perform(solutionId);

    if (
      this.webviewManager.isWebview &&
      solutionInstance.status === SOLUTION_INSTANCE_STATUS.ENABLED
    ) {
      this.webviewManager._callBridgeMethod('onConnectionSuccess');
    } else if (
      this.deviceManager.isMobile &&
      solutionInstance.status === SOLUTION_INSTANCE_STATUS.ENABLED
    ) {
      return window.location.replace(
        `/detectapp.html?appUrl=deeplinks?action=connect-hub.hub-application.setup.success&organization_slug=${organization.slug}`
      );
    }

    return {
      applicationSlug,
      initSolutionTask: this.initSolutionTask,
      prismicData,
      organization,
      solutionInstance,
    };
  }

  deactivate() {
    this._removeListeners();
    this.menu.show();
  }

  initSolutionTask = task(async solutionId => {
    let organization = this.organizationManager.organization;
    let isGMISolutionInstance =
      variation('feature--bolean-gmi-async-flow') && solutionId.startsWith(GMI_SOLUTION_ID_PREFIX);

    let solutionInstances = await this.store.query('solution-instance', {
      organization_id: organization.id,
      solution_id: solutionId,
    });

    let solutionInstance = solutionInstances?.[0];

    if (!solutionInstance) {
      solutionInstance = this.store.createRecord('solution-instance', {
        solutionId,
        organization,
      });

      await solutionInstance.save();
    }

    // gmi-solution-instances have no POST request, a record in BE is created by calling POST solution_instances request
    // we need to fetch them to retrieve the payload of the saved gmi-solution-instance
    if (isGMISolutionInstance) {
      await this.store.query('gmi-solution-instance', {});
    }

    return solutionInstance;
  });
}
