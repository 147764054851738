export default {
  "row": "qqv",
  "active": "qqo",
  "animated": "qqn",
  "fadein-item": "qqi",
  "cell": "qqA body-2",
  "cell-content": "qqY",
  "subtitle": "qqh caption",
  "no-padding": "qqf",
  "note": "qqK",
  "cell-amount": "qqG",
  "cell-content-amount": "qqr body-1"
};
