import React, { Fragment, type ReactNode } from 'react';
import { Collection, Header } from 'react-aria-components';
import { IconArrowSmallRightFilled, IconArrowSmallBottomFilled } from '@repo/monochrome-icons';
import type { CashflowParentCategory } from 'qonto/react/models/cash-flow-category';
import { useCashFlowCategoriesStateStorage } from 'qonto/react/hooks/use-cash-flow-categories-storage';
import styles from './styles.strict-module.css';
import { Category, CategoryListBoxItem } from './category';

interface ParentCategoryProps {
  category: CashflowParentCategory;
}

export function ParentCategory({ category }: ParentCategoryProps): ReactNode {
  const hasSubcategories = (category.subcategories?.length ?? 0) > 0;
  const { isExpanded, setIsExpanded } = useCashFlowCategoriesStateStorage(
    'cash-flow-category-selector-expanded-states',
    category.id
  );

  const handleClick = (): void => {
    setIsExpanded(!isExpanded);
  };

  if (!hasSubcategories) {
    return <CategoryListBoxItem item={category} />;
  }

  return (
    <Fragment key={category.id}>
      <Header
        className={styles['parent-category']}
        data-testid="parent-category"
        onClick={handleClick}
      >
        {isExpanded ? (
          <IconArrowSmallBottomFilled data-testid="icon-expanded" height={16} width={16} />
        ) : (
          <IconArrowSmallRightFilled data-testid="icon-collapsed" height={16} width={16} />
        )}
        <Category item={category} />
      </Header>
      {isExpanded ? (
        <Collection items={category.subcategories}>
          {subitem => <CategoryListBoxItem item={subitem} key={subitem.id} showIcon={false} />}
        </Collection>
      ) : null}
    </Fragment>
  );
}
