/* import __COLOCATED_TEMPLATE__ from './review.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

// @ts-expect-error
import { hasMFAError } from '@qonto/qonto-sca/utils/mfa-error';
import { Disclaimer } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';

// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';

interface F24ManualDeclarationConfirmSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class F24ManualDeclarationConfirmComponent extends Component<F24ManualDeclarationConfirmSignature> {
  disclaimerBlock = Disclaimer.Block;

  @service declare errors: Services['errors'];
  @service declare intl: Services['intl'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare segment: Services['segment'];
  @service declare sentry: Services['sentry'];

  handleSubmitTask = dropTask(async () => {
    // @ts-expect-error
    let { f24Order } = this.args.context;

    try {
      await f24Order.create();
      this.segment.track('f24_create-flow_step5-info-reviewed');
      // @ts-expect-error
      this.args.transitionToNext();
    } catch (error) {
      // @ts-expect-error
      if (hasMFAError(error?.errors) || !error.isAdapterError) {
        throw error;
      }

      let isInvalidError = f24Order.isInvalidErrorWithPointers(error);
      if (!isInvalidError && ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      // @ts-expect-error
      let genericMessage = this.errors.messageForStatus(error);
      if (genericMessage) {
        this.toastFlashMessages.toastError(genericMessage);
      } else {
        this.toastFlashMessages.toastError(
          this.intl.t('f24.manual-declaration.review.generic-error')
        );
      }
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::F24::ManualDeclaration::Review': typeof F24ManualDeclarationConfirmComponent;
  }
}
