export default {
  "account-container": "czu",
  "badge": "caS",
  "subtitle": "cac title-4",
  "accounts-filter": "caq",
  "account-filter-label": "caZ",
  "status": "caR",
  "account-info": "caQ",
  "balance": "cae",
  "revealed-balance": "caB",
  "hidden-balance": "caE"
};
