/* import __COLOCATED_TEMPLATE__ from './past.hbs'; */
import { dasherize } from '@ember/string';
import Component from '@glimmer/component';

// @ts-expect-error
import { ensureSafeComponent } from '@embroider/util';
import { Tag } from '@repo/design-system-kit';

import { Member } from 'qonto/react/components/tasks/table/cell/member';

interface RequestTableItemPastSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLTableRowElement;
}

export default class RequestTableItemPastComponent extends Component<RequestTableItemPastSignature> {
  tag = Tag;
  member = Member;

  get requestComponentName() {
    // @ts-expect-error
    let type = dasherize(this.args.request.requestType);
    return ensureSafeComponent(`tasks/table/cell/${type}`, this);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Tasks::Table::Item::Past': typeof RequestTableItemPastComponent;
  }
}
