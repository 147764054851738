export default {
  "mandates": "Nk",
  "mandates-list": "NJ",
  "left-section": "Ns",
  "header-search": "NF",
  "header": "Ng",
  "header-content": "ND",
  "search": "NV",
  "search-bar": "NL",
  "body": "NT",
  "list-title": "Nz caption-bold",
  "details": "Na",
  "avatar": "NH mr-16",
  "header-beneficiary": "NO",
  "header-beneficiary-activity": "Nm",
  "header-beneficiary-details": "Np",
  "header-beneficiary-details-recurring": "Nx",
  "infos": "Nw",
  "infos-title": "NN",
  "infos-empty": "Nv",
  "infos-list": "No",
  "infos-list-item": "Nn",
  "infos-list-item-left": "Ni",
  "infos-list-item-right": "NA",
  "infos-list-item-attachment": "NY"
};
