/* import __COLOCATED_TEMPLATE__ from './month.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { dateToken } from '@qonto/ui-kit/utils/date-token';
import dayjs from 'dayjs';
// @ts-expect-error
import { variation } from 'ember-launch-darkly';

import { PERIOD_RATES } from 'qonto/react/models/cash-flow-period';

interface OverviewChartTooltipMonthSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class OverviewChartTooltipMonthComponent extends Component<OverviewChartTooltipMonthSignature> {
  @service declare intl: Services['intl'];

  get canDisplayForecast() {
    // @ts-expect-error
    return variation('feature--boolean-cash-flow-forecast-slice-1') && this.args.displayForecasts;
  }

  get monthlyCashflow() {
    // @ts-expect-error
    let monthlyCashflow = this.args.monthlyCashflow;
    if (dayjs(monthlyCashflow.time).isAfter(Date.now()) && !this.canDisplayForecast) {
      return Object.keys(monthlyCashflow).forEach(key => {
        monthlyCashflow[key] = undefined;
      });
    } else {
      return monthlyCashflow;
    }
  }

  get title() {
    if (!this.monthlyCashflow) return '';

    let d = dayjs(this.monthlyCashflow.time).toDate();
    // @ts-expect-error
    if (this.args.selectedFrequency === PERIOD_RATES.YEARLY) {
      return `${dayjs(d).year()}`;
    }

    // @ts-expect-error
    if (this.args.selectedFrequency === PERIOD_RATES.QUARTERLY) {
      return `Q${dayjs(d).quarter()} ${dayjs(d).year()}`;
    }

    // @ts-expect-error
    return dateToken({ date: d, locale: this.intl.primaryLocale, token: 'month-year-l' });
  }

  get monthlyTotalTranslation() {
    // @ts-expect-error
    if (this.args.selectedFrequency === PERIOD_RATES.YEARLY) {
      // @ts-expect-error
      return this.args.isCurrent
        ? this.intl.t('overview.year-to-date')
        : this.intl.t('overview.yearly-total');
    }

    // @ts-expect-error
    if (this.args.selectedFrequency === PERIOD_RATES.QUARTERLY) {
      // @ts-expect-error
      return this.args.isCurrent
        ? this.intl.t('overview.quarter-to-date')
        : this.intl.t('overview.quarterly-total');
    }

    // @ts-expect-error
    return this.args.isCurrent
      ? this.intl.t('overview.month-to-date')
      : this.intl.t('overview.monthly-total');
  }

  get period() {
    let labels = {
      monthly: this.intl.t('cash-flow.frequency-selector.month'),
      quarterly: this.intl.t('cash-flow.frequency-selector.quarter'),
      yearly: this.intl.t('cash-flow.frequency-selector.year'),
    };

    // @ts-expect-error
    return this.args.selectedFrequency ? labels[this.args.selectedFrequency] : labels.monthly;
  }

  get startOfPeriod() {
    return this.intl.t('overview.start-of-period', { period: this.period });
  }

  get endOfPeriod() {
    // @ts-expect-error
    if (this.args.isCurrent && !this.canDisplayForecast) {
      return this.intl.t('overview.current');
    }

    return this.intl.t('overview.end-of-period', { period: this.period });
  }

  get vatCopy() {
    if (this.monthlyCashflow.vatDue < 0) {
      return this.intl.t('cash-flow.graph.period-tooltip.vat.debit.title');
    }
    return this.intl.t('cash-flow.graph.period-tooltip.vat.credit.title');
  }

  get startingBalance() {
    if (this.isFutureTimeframe) {
      return this.monthlyCashflow.forecastedStartingBalance;
    }
    return this.monthlyCashflow.startingBalance;
  }

  get endingBalance() {
    if ((this.isCurrentTimeframe || this.isFutureTimeframe) && this.canDisplayForecast) {
      return this.monthlyCashflow.forecastedEndingBalance;
    }
    return this.monthlyCashflow.endingBalance;
  }

  get netChange() {
    if (this.isCurrentTimeframe && this.canDisplayForecast) {
      return this.monthlyCashflow.forecastedCurrentNetChange;
    }

    if (this.isFutureTimeframe && this.canDisplayForecast) {
      return this.monthlyCashflow.forecastedNetChange;
    }

    return this.monthlyCashflow.netChange;
  }

  get isCurrentTimeframe() {
    return this.monthlyCashflow.isCurrent;
  }

  get isFutureTimeframe() {
    return this.monthlyCashflow.isFuture;
  }

  get showCurrentForecastIndicator() {
    return this.canDisplayForecast && this.isCurrentTimeframe;
  }

  get showFutureForecastIndicator() {
    return this.canDisplayForecast && this.isFutureTimeframe;
  }

  get showEndBalance() {
    // @ts-expect-error
    if (this.args.isCurrent && !this.canDisplayForecast) {
      return false;
    }
    return true;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Overview::Chart::Tooltip::Month': typeof OverviewChartTooltipMonthComponent;
  }
}
