// @ts-nocheck
import Service, { service } from '@ember/service';

import { dropTask } from 'ember-concurrency';
import { reads } from 'macro-decorators';

export default class ActivityTypeService extends Service {
  @service intl;
  @service organizationManager;

  @reads('loadActivityCodesTask.lastSuccessful.value') activityCodesCache;
  @reads('organizationManager.organization.legalCountry') legalCountry;

  get activityCodes() {
    // Quick fix while we don't have the activity codes translated to "pt":
    let primaryLocale = this.intl.primaryLocale === 'pt' ? 'en' : this.intl.primaryLocale;

    if (this.legalCountry === 'FR') {
      return this.activityCodesCache.fr[primaryLocale];
    }
    if (this.legalCountry === 'IT') {
      return this.activityCodesCache.it[primaryLocale];
    }
    return this.activityCodesCache.int[primaryLocale];
  }

  loadActivityCodesTask = dropTask(async () => {
    let response = await fetch(`/activity-codes.json`);
    return response.json();
  });
}

declare module '@ember/service' {
  interface Registry {
    'activity-type': ActivityTypeService;
    activityType: ActivityTypeService;
  }
}
