/* import __COLOCATED_TEMPLATE__ from './cell.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { BadgeStatus } from '@repo/design-system-kit';
// @ts-expect-error
import { variation } from 'ember-launch-darkly';

import {
  FIRST_EINVOICE_TOOLTIP_DISMISSED_LOCAL_STORAGE_KEY,
  FIRST_GERMAN_EINVOICE_TOOLTIP_DISMISSAL_KEY,
  GERMAN_E_INVOICES_ARTICLE_FOR_DE,
  GERMAN_E_INVOICES_ARTICLE_FOR_OTHERS,
  INVOICE_STATUSES,
} from 'qonto/constants/supplier-invoice';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import { InstructionalTooltip } from 'qonto/react/components/product-discovery/instructional-tooltip';
import { PayableAmountTooltip } from 'qonto/react/components/supplier-invoices/payable-amount-tooltip';
import { StatusTag } from 'qonto/react/components/supplier-invoices/table/status-tag';
import { Avatar } from 'qonto/react/components/suppliers/table/item/avatar';

interface SupplierInvoicesTableCellSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLTableCellElement;
}

export default class SupplierInvoicesTableCellComponent extends Component<SupplierInvoicesTableCellSignature> {
  avatar = Avatar;
  Badge = BadgeStatus;
  InstructionalTooltip = InstructionalTooltip;
  PayableAmountTooltip = PayableAmountTooltip;
  statusTag = StatusTag;

  @service declare intl: Services['intl'];
  @service declare zendeskLocalization: Services['zendeskLocalization'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare localeManager: Services['localeManager'];

  @tracked isFirstFrenchEinvoiceTooltipDismissed = false;
  @tracked hasDismissedTooltipForFirstGermanEInvoice = false;

  get shouldDisplayFirstEinvoiceTooltip() {
    // @ts-expect-error
    let { firstEInvoiceId, cell, key } = this.args;
    return firstEInvoiceId === cell?.id && key === 'supplierName';
  }

  get showTooltipOrEInvoiceLabel() {
    // @ts-expect-error
    let { isEInvoice, hasSelfInvoice } = this.args.cell;
    return isEInvoice || hasSelfInvoice;
  }

  get isStatus() {
    // @ts-expect-error
    return Object.values(INVOICE_STATUSES).includes(this.args.cell);
  }

  get shouldUseCreditNoteAmountColor() {
    // @ts-expect-error
    let { cell, key } = this.args;
    return (
      variation('feature--boolean-ap-credit-notes') && key === 'totalAmount' && cell.isCreditNote
    );
  }

  get einvoicingFAQLink() {
    switch (this.organizationLegalCountry) {
      case 'FR':
        return this.intl.t('supplier-invoices.instructional-tooltip.einvoicing-disclaimer.url', {
          faqUrl: this.zendeskLocalization.getLocalizedArticle('fr-einvoice'),
        });
      case 'DE':
        if (this.currentLocale === 'de') {
          return this.intl.t(
            'supplier-invoices.table.instructional-tooltip.first-einvoice.subtitle.url',
            {
              lpUrl: GERMAN_E_INVOICES_ARTICLE_FOR_DE,
            }
          );
        } else {
          return this.intl.t(
            'supplier-invoices.table.instructional-tooltip.first-einvoice.subtitle.url',
            {
              lpUrl: GERMAN_E_INVOICES_ARTICLE_FOR_OTHERS,
            }
          );
        }
      default:
        break;
    }
  }

  get isNotCreditNoteOrFFDisabled() {
    return (
      !variation('feature--boolean-ap-credit-notes') ||
      // @ts-expect-error
      (!this.args.cell?.isCreditNote && variation('feature--boolean-ap-credit-notes'))
    );
  }

  get toolTipTextContent() {
    switch (this.organizationLegalCountry) {
      case 'FR':
        return {
          title: this.intl.t('supplier-invoices.first-einvoice-card.title'),
          description: this.intl.t('supplier-invoices.first-einvoice-card.subtitle.body'),
          linkText: this.intl.t(
            'supplier-invoices.instructional-tooltip.einvoicing-disclaimer.link'
          ),
          faqSource: '',
        };
      case 'DE':
        return {
          title: this.intl.t('supplier-invoices.table.instructional-tooltip.first-einvoice.title'),
          description: this.intl.t(
            'supplier-invoices.table.instructional-tooltip.first-einvoice.subtitle.body'
          ),
          linkText: this.intl.t(
            'supplier-invoices.table.instructional-tooltip.first-einvoice.subtitle.link'
          ),
          faqSource: 'first-einvoice',
        };
      default:
        break;
    }
  }

  get organizationLegalCountry() {
    return this.organizationManager.organization?.legalCountry;
  }

  get currentLocale() {
    return this.localeManager.locale;
  }

  get isInstructionalToolTipDismissed() {
    switch (this.organizationLegalCountry) {
      case 'FR':
        return this.isFirstFrenchEinvoiceTooltipDismissed;

      case 'DE':
        return this.hasDismissedTooltipForFirstGermanEInvoice;

      default:
        return false;
    }
  }

  get tooltipWarningDuplicateMessage() {
    // @ts-expect-error
    let { cell } = this.args;
    if (cell.isCreditNote && variation('feature--boolean-ap-credit-notes')) {
      return this.intl.t('supplier-invoices.table.preview.credit-notes.duplicate-tooltip');
    }
    return this.intl.t('supplier-invoices.table.preview.duplicate-tooltip');
  }

  @action
  handleFirstFrenchEinvoiceTooltipClose() {
    this.isFirstFrenchEinvoiceTooltipDismissed = true;
    // @ts-expect-error
    safeLocalStorage.setItem(FIRST_EINVOICE_TOOLTIP_DISMISSED_LOCAL_STORAGE_KEY, true);
  }

  @action
  handleCloseFirstGermanEInvoiceTooltip() {
    this.hasDismissedTooltipForFirstGermanEInvoice = true;
    // @ts-expect-error
    safeLocalStorage.setItem(FIRST_GERMAN_EINVOICE_TOOLTIP_DISMISSAL_KEY, true);
  }

  @action
  handleInstructionalTooltipClosure() {
    switch (this.organizationLegalCountry) {
      case 'FR':
        this.handleFirstFrenchEinvoiceTooltipClose();
        break;

      case 'DE':
        this.handleCloseFirstGermanEInvoiceTooltip();
        break;

      default:
        break;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'SupplierInvoices::Table::Row::Cell': typeof SupplierInvoicesTableCellComponent;
  }
}
