export default {
  "row": "qkr",
  "icon": "qkb",
  "cell": "qky",
  "cell-date": "qkU",
  "cell-amount": "qkj",
  "cell-status": "qkW",
  "cell-content": "qku",
  "align-right": "qJS"
};
