export default {
  "card": "ckd",
  "focus": "ckI",
  "title-format": "ckt",
  "header": "ckM",
  "headings": "ckP",
  "title": "ckl title-4",
  "main": "ckX title-3",
  "footer": "ckC"
};
