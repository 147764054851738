/* eslint-disable @qonto/no-import-roles-constants */
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { isTesting, macroCondition } from '@embroider/macros';
import APPEARANCE, { APPEARANCE_KEY } from '@qonto/ui-kit/constants/appearance-setting';
import { Spinner } from '@repo/design-system-kit';
import * as Sentry from '@sentry/ember';
import { dropTask, forever } from 'ember-concurrency';
import window from 'ember-window-mock';
import fetch from 'fetch';

import ENV from 'qonto/config/environment';
import {
  authBaseURL,
  companyCreationJsURL,
  registerJsURL,
  registerPartnersJsURL,
} from 'qonto/constants/hosts';
import { IS_PERIODIC_WARNING_REVIEWED } from 'qonto/constants/kyc-kyb-update-process';
import { ROLES } from 'qonto/constants/membership';
import { NRC_LOGIN_PROMOTION_KEY } from 'qonto/constants/nrc-payments';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import { routeAfterAuthentication } from 'qonto/services/session-manager';
import urlFromRouteInfo from 'qonto/utils/url-from-route-info';

async function fetchLatestVersion() {
  let response;
  try {
    response = await fetch(`/LATEST_VERSION.txt`);
  } catch (error) {
    // network request failures are harmless on this call
    if (!(error instanceof TypeError)) {
      Sentry.captureException(error);
    }
  }

  if (response?.ok) {
    let rawVersion = await response.text();
    return rawVersion.trim();
  }
}

const BACKGROUND_COLORS = ['purple', 'mint', 'mustard', 'peach'];

export default class SigninController extends Controller {
  spinner = Spinner;

  @service toastFlashMessages;
  @service intl;
  @service modals;
  @service networkManager;
  @service organizationManager;
  @service router;
  @service scaManager;
  @service segment;
  @service sensitiveActions;
  @service sessionManager;
  @service userManager;
  @service deviceManager;
  @service subscriptionManager;
  @service abilities;
  @service sentry;

  animationReady = false;
  registerJsURL = registerJsURL;
  @tracked isUpdateSessionModalShown = false;

  queryParams = [{ isUpdateSessionModalShown: 'session-reset-modal' }];
  backgroundColor =
    BACKGROUND_COLORS[
      macroCondition(isTesting()) ? 0 : Math.floor(Math.random() * BACKGROUND_COLORS.length)
    ];

  // The spinner overlay should only be rendered while making requests, not when SCA or trust modals
  // are presented to the user. Since `onGoogleSignInSuccessTask` & `onAppleSignInSuccessTask`
  // contains every step of the flow, relying on it would mean we cover the modals with an overlay
  // and making them impossible to interact with. Also, it should never appear for the `emil_pwd`
  // flow.
  get shouldShowSpinnerOverlay() {
    return (
      this._providerStartSessionTask.isRunning || this._afterProviderAuthenticateTask.isRunning
    );
  }

  get showNrcPromotionalContent() {
    return safeLocalStorage.getItem(NRC_LOGIN_PROMOTION_KEY) === '1';
  }

  get newMarketsPromoImage() {
    return `/illustrations/signin/new-markets-${this.backgroundColor}.svg`;
  }

  startSessionTask = dropTask(async credentials => {
    await this.userManager.setup(credentials);
  });

  authenticateTask = dropTask(async (email, password) => {
    await this.sensitiveActions.runTask
      .linked()
      .perform(this.startSessionTask, { email, password });

    if (this.sessionManager.isAuthenticated) {
      await this.openTrustBrowserTask.perform();
      await this.afterAuthenticateTask.perform({
        authMethod: 'email_pwd',
      });
    }
  });

  onGoogleSignInSuccessTask = dropTask(async ({ jwt: authToken }) => {
    await this.sensitiveActions.runTask.linked().perform(this._providerStartSessionTask, authToken);
    if (this.sessionManager.isAuthenticated) {
      await this.openTrustBrowserTask.perform();
      await this._afterProviderAuthenticateTask.perform('google');
    }
  });

  onAppleSignInSuccessTask = dropTask(async ({ jwt: authToken }) => {
    await this.sensitiveActions.runTask.linked().perform(this._providerStartSessionTask, authToken);
    if (this.sessionManager.isAuthenticated) {
      await this.openTrustBrowserTask.perform();
      await this._afterProviderAuthenticateTask.perform('apple');
    }
  });

  // This task exists only because we won't want to listen to `startSessionTask` when show the
  // loading spinner. Doing so would obstruct the regular, non-provider flow with unneceseary UI.
  // This task merely contains the `startSessionTask`, so that we can derive its state
  // independently from the contained task.
  _providerStartSessionTask = dropTask(async authToken => {
    return await this.startSessionTask.perform({ authToken });
  });

  /**
   * @param {object} options
   * @param {'email_pwd' | 'google' | 'apple'} options.authMethod The authentication method used to
   * authenticate the user
   */
  afterAuthenticateTask = dropTask(async ({ authMethod }) => {
    let { attemptedTransition } = this.sessionManager;

    let [version] = await Promise.all([
      fetchLatestVersion(),
      this.organizationManager.loadRegisteringOrganizations(),
      this.organizationManager.setupTask.perform({ checkForPendingInvite: true }),
    ]);

    let organization = this.organizationManager.getDefaultOrganization();

    // qonto-company-creation-js is reusing the login form in qonto-js, and
    // it needs a way to tell the login form to redirect back to itself
    // after a successful authentication. if a `redirect` query parameter is
    // set when loading the `signin` route, we will use that to redirect the
    // user back to the company creation app. to prevent potential misuse we
    // restrict this parameter to only work on the same domain as qonto-js
    // itself is running on.
    let queryParams = new URLSearchParams(window.location.search);
    let redirectURL = queryParams.get('redirect');

    if (redirectURL) {
      let { hostname } = new URL(redirectURL);
      let redirectDomain = hostname.split('.').slice(-2).join('.');
      let currentDomain = window.location.hostname.split('.').slice(-2).join('.');
      if (redirectDomain === currentDomain) {
        window.location.href = redirectURL;

        if (macroCondition(!isTesting())) {
          // let's keep the page loading state
          await forever;
        }
      }
    }

    if (organization) {
      this.segment.track('logged_in', {
        browser_width: `${window.innerWidth}px`,
        browser_height: `${window.innerHeight}px`,
        app_version: ENV.qontoAppVersion,
        time_spent_on_page: Date.now() - this.arrivedOnPageAt,
        authentication_method: authMethod,
      });

      if (window.matchMedia?.('(prefers-color-scheme)').media !== 'not all') {
        let appAppearanceMode = JSON.parse(safeLocalStorage.getItem(APPEARANCE_KEY));
        let deviceAppearanceMode = window.matchMedia('(prefers-color-scheme: dark)').matches
          ? 'dark'
          : 'light';

        this.segment.identify({
          device_appearance_mode: deviceAppearanceMode,
          app_appearance_mode:
            appAppearanceMode === APPEARANCE.SYSTEM ? 'automatic' : appAppearanceMode, // for Segment, automatic should be used instead of system
        });
      }

      // Ensure every organization that is suspended or at risk of suspension sees a full-page modal after signing in.
      // This modal is displayed once per session. Additional logic for this behavior resides in the `organizations` route.
      sessionStorage.removeItem(IS_PERIODIC_WARNING_REVIEWED);

      // We need to load the permissions, subscriptions and price plan before redirecting to the right page
      await this.organizationManager.setCurrentOrganizationAndMembership(organization);
      await this.subscriptionManager.refresh(organization);
    } else if (this.organizationManager.partnerOrganizations.length) {
      window.location.replace(registerPartnersJsURL);
      return;
    } else if (this.organizationManager.companyCreationOrganizations?.length) {
      window.location.replace(companyCreationJsURL);
      return;
    } else {
      // We still have users who have been created without organization so we want to redirect them to the register
      window.location.replace(registerJsURL);
      return;
    }

    let targetUrl =
      attemptedTransition && attemptedTransition.targetName !== routeAfterAuthentication
        ? urlFromRouteInfo(this.router, attemptedTransition.to)
        : this.getLandingPage(organization, { tdm: attemptedTransition?.to?.queryParams?.tdm });
    this.set('sessionManager.attemptedTransition', null);

    if (version && version !== ENV.qontoAppVersion) {
      let tdm = attemptedTransition?.to?.queryParams?.tdm;
      window.location.replace(tdm ? `${targetUrl}?tdm=${tdm}` : targetUrl);

      if (macroCondition(!isTesting())) {
        // let's keep the page loading state
        await forever;
      }
    } else {
      this.router.replaceWith(targetUrl);
    }
  });

  getLandingPage(organization, queryParams) {
    let isEmployee = this.organizationManager.membership.role === ROLES.EMPLOYEE;

    if (this.abilities.can('access accounting-hub')) {
      return this.router.urlFor('accounting-hub', { queryParams });
    } else if (this.abilities.can('view overview')) {
      return this.router.urlFor('overview', organization.slug, {
        queryParams,
      });
    } else if (this.abilities.can('navigate tasks')) {
      return this.router.urlFor('tasks.pending', organization.slug, { queryParams });
    } else if (this.abilities.can('navigate request') && !isEmployee) {
      return this.router.urlFor('requests.index', organization.slug, { queryParams });
    }
    return this.router.urlFor('organizations.show', organization.slug, { queryParams });
  }

  // This task exists only because we won't want to listen to `afterAuthenticateTask` when show the
  // loading spinner. Doing so would show the spinner for the `email_pwd` flow, which shouldn't
  // happen. This task merely contains the `afterAuthenticateTask`, so that we can derive its state
  // independently from the contained task.
  _afterProviderAuthenticateTask = dropTask(async authMethod => {
    return await this.afterAuthenticateTask.perform({ authMethod });
  });

  trustBrowserTask = dropTask(async close => {
    this.segment.track('sca.trust-browser.trusted');

    await this.networkManager.rawRequest(`${authBaseURL}/v1/devices?device[remember]=true`, {
      method: 'PUT',
    });
    close();
  });

  openTrustBrowserTask = dropTask(async () => {
    if (this.scaManager.shouldOpenTrustModal) {
      this.segment.track('sca.trust-browser.viewed');

      let closeReason = await this.modals.open('popup/confirmation', {
        title: this.intl.t('sca.trust-browser.title'),
        description: this.intl.t('sca.trust-browser.subtitle'),
        cancel: this.intl.t('sca.trust-browser.dismiss-button'),
        confirm: this.intl.t('sca.trust-browser.trust-button'),
        confirmTask: this.trustBrowserTask,
      });

      if (closeReason === 'cancel' || closeReason === 'close') {
        this.segment.track('sca.trust-browser.dismissed');
      }
    }
  });

  @action
  handleError(error) {
    if (Boolean(error.status) && error.status >= 400 && error.status < 500) {
      this.sentry.captureException(error);
    }
  }
}
