/* import __COLOCATED_TEMPLATE__ from './customization.hbs'; */
import { registerDestructor } from '@ember/destroyable';
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { isTesting, macroCondition } from '@embroider/macros';
import { dropTask, task, waitForQueue } from 'ember-concurrency';
import { reads } from 'macro-decorators';

import {
  CARD_DESIGNS,
  CARD_LEVELS,
  CARD_LEVELS_TRACKING,
  CARD_PRINT_TYPES,
} from 'qonto/constants/cards';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

const PLUS_DESIGN_ASSETS = [
  {
    design: CARD_DESIGNS.PLUS_RECYCLED_PLASTIC_SILVER_2023,
    embossed: '/illustrations/cards/customization/card-plus-silver-embossed.png',
    embossedOpen: '/illustrations/cards/customization/card-plus-silver-embossed-open.png',
    default: '/illustrations/cards/customization/card-plus-silver-printed.png',
    defaultOpen: '/illustrations/cards/customization/card-plus-silver-printed-open.png',
    videoEmbossed: '/videos/cards/customization/card-plus-silver-embossed.webm',
    videoDefault: '/videos/cards/customization/card-plus-silver-printed.webm',
  },
  {
    design: CARD_DESIGNS.PLUS_RECYCLED_PLASTIC_LILAC_2023,
    embossed: '/illustrations/cards/customization/card-plus-lilac-embossed.png',
    embossedOpen: '/illustrations/cards/customization/card-plus-lilac-embossed-open.png',
    default: '/illustrations/cards/customization/card-plus-lilac-printed.png',
    defaultOpen: '/illustrations/cards/customization/card-plus-lilac-printed-open.png',
    videoEmbossed: '/videos/cards/customization/card-plus-lilac-embossed.webm',
    videoDefault: '/videos/cards/customization/card-plus-lilac-printed.webm',
  },
  {
    design: CARD_DESIGNS.PLUS_RECYCLED_PLASTIC_BLACK_2023,
    embossed: '/illustrations/cards/customization/card-plus-black-embossed.png',
    embossedOpen: '/illustrations/cards/customization/card-plus-black-embossed-open.png',
    default: '/illustrations/cards/customization/card-plus-black-printed.png',
    defaultOpen: '/illustrations/cards/customization/card-plus-black-printed-open.png',
    videoEmbossed: '/videos/cards/customization/card-plus-black-embossed.webm',
    videoDefault: '/videos/cards/customization/card-plus-black-printed.webm',
  },
];

const X_CARDS_DESIGN_ASSETS = [
  {
    design: CARD_DESIGNS.METAL_MINERAL_GRAY_2024,
    embossed: '/illustrations/cards/customization/card-metal-gray.png',
    embossedOpen: '/illustrations/cards/customization/card-metal-gray-open.png',
    default: '/illustrations/cards/customization/card-metal-gray.png',
    defaultOpen: '/illustrations/cards/customization/card-metal-gray-open.png',
    videoEmbossed: '/videos/cards/customization/card-metal-gray.webm',
    videoDefault: '/videos/cards/customization/card-metal-gray.webm',
  },
  {
    design: CARD_DESIGNS.METAL_SAND_GOLD_2024,
    embossed: '/illustrations/cards/customization/card-metal-gold.png',
    embossedOpen: '/illustrations/cards/customization/card-metal-gold-open.png',
    default: '/illustrations/cards/customization/card-metal-gold.png',
    defaultOpen: '/illustrations/cards/customization/card-metal-gold-open.png',
    videoEmbossed: '/videos/cards/customization/card-metal-gold.webm',
    videoDefault: '/videos/cards/customization/card-metal-gold.webm',
  },
  {
    design: CARD_DESIGNS.METAL_GRAPHITE_BLACK_2019,
    embossed: '/illustrations/cards/customization/card-metal-black.png',
    embossedOpen: '/illustrations/cards/customization/card-metal-black-open.png',
    default: '/illustrations/cards/customization/card-metal-black.png',
    defaultOpen: '/illustrations/cards/customization/card-metal-black-open.png',
    videoEmbossed: '/videos/cards/customization/card-metal-black.webm',
    videoDefault: '/videos/cards/customization/card-metal-black.webm',
  },
];

const PLUS_DEFAULT_ACTIVE_DESIGN = CARD_DESIGNS.PLUS_RECYCLED_PLASTIC_LILAC_2023;
const X_DEFAULT_ACTIVE_DESIGN = CARD_DESIGNS.METAL_SAND_GOLD_2024;

const FADE_DURATION = macroCondition(isTesting()) ? 0 : 400;
const TRANSITION_DURATION = macroCondition(isTesting()) ? 0 : 500;

interface FlowsCardsCustomizationSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsCardsCustomization extends Component<FlowsCardsCustomizationSignature> {
  @service declare deviceManager: Services['deviceManager'];
  @service declare intl: Services['intl'];
  @service declare segment: Services['segment'];
  @service declare sentry: Services['sentry'];

  // @ts-expect-error
  @reads('args.context.card.cardLevel') contextCardLevel;

  ulGuid = `${guidFor(this)}-ul`;

  @tracked activeLi = null;
  @tracked isAnimating = false;

  CARD_LEVELS = CARD_LEVELS;

  constructor(owner: unknown, args: FlowsCardsCustomizationSignature['Args']) {
    super(owner, args);
    this.initCarouselTask.perform().catch(ignoreCancelation);
  }

  get designAssets() {
    switch (this.flowCardLevel) {
      case CARD_LEVELS.METAL:
        return X_CARDS_DESIGN_ASSETS;
      case CARD_LEVELS.PLUS:
      default:
        return PLUS_DESIGN_ASSETS;
    }
  }

  get defaultActiveDesign() {
    switch (this.flowCardLevel) {
      case CARD_LEVELS.METAL:
        return X_DEFAULT_ACTIVE_DESIGN;
      case CARD_LEVELS.PLUS:
      default:
        return PLUS_DEFAULT_ACTIVE_DESIGN;
    }
  }

  get flowCardLevel() {
    // @ts-expect-error
    let { cardUpsellLevel } = this.args.context;
    return cardUpsellLevel || this.contextCardLevel;
  }

  get title() {
    switch (this.flowCardLevel) {
      case CARD_LEVELS.METAL:
        return this.intl.t('cards.steps.customization.title.metal');
      case CARD_LEVELS.PLUS:
      default:
        return this.intl.t('cards.steps.customization.title.plus');
    }
  }

  get subtitle() {
    switch (this.flowCardLevel) {
      case CARD_LEVELS.METAL:
        return this.intl.t('cards.steps.customization.subtitle.metal');
      case CARD_LEVELS.PLUS:
      default:
        return this.intl.t('cards.steps.customization.subtitle.plus');
    }
  }

  get isSafariBrowser() {
    return this.deviceManager.isSafariDesktop;
  }

  get activeDesign() {
    // @ts-expect-error
    let { cardDesign } = this.args;
    // @ts-expect-error
    return this.activeLi?.getAttribute('data-design') || cardDesign || this.defaultActiveDesign;
  }

  get showEmbossed() {
    // @ts-expect-error
    return this.args.typeOfPrint === CARD_PRINT_TYPES.EMBOSSED;
  }

  get activeLiIndex() {
    // @ts-expect-error
    return Array.from(this.ul.childNodes).indexOf(this.activeLi);
  }

  get isLiFirstChild() {
    return this.activeLiIndex === 0;
  }

  get shouldNotSwitchDesign() {
    // if the user selects the already highlighted design, we do not switch
    return this.activeLiIndex === 1;
  }

  get canEditTypeOfPrint() {
    return this.flowCardLevel === CARD_LEVELS.PLUS;
  }

  initCarouselTask = dropTask(async () => {
    try {
      await waitForQueue('afterRender');
      this.initCarousel();
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  });

  @action
  initCarousel() {
    let { activeDesign } = this;
    // @ts-expect-error
    this.ul = document.getElementById(this.ulGuid);
    // @ts-expect-error
    this.activeLi = this.ul.querySelector(`li[data-design="${activeDesign}"]`);

    // @ts-expect-error
    this.ul.addEventListener('transitionend', this._transitionEnd, false);
    registerDestructor(this, () => {
      // @ts-expect-error
      this.ul.removeEventListener('transitionend', this._transitionEnd, false);
    });

    // update the pre-checked design, as it doesn't match the default design of the card
    // @ts-expect-error
    this.args.onColorChange(activeDesign);

    if (this.activeDesign !== this.defaultActiveDesign) {
      this._switchDesignWithoutAnimation();
    }
    // Safari doesn't support videos with transparent background, so we only use static images
    if (!this.isSafariBrowser) {
      this._playActiveVideos();
    }
  }

  @action
  // @ts-expect-error
  onColorSwitch(design) {
    this._onDesignChange(design);
  }

  @action
  // @ts-expect-error
  onDesignClick({ currentTarget }) {
    if (this.isAnimating || this.activeLi === currentTarget) {
      return;
    }
    let design = currentTarget.getAttribute('data-design');
    this._onDesignChange(design);
  }

  @action
  // @ts-expect-error
  toggleEmbossed(typeOfPrint) {
    // @ts-expect-error
    this.args.onTypeOfPrintChange(typeOfPrint);
  }

  @action
  handleNext() {
    let {
      // @ts-expect-error
      context: { flowTrackingOrigin },
      // @ts-expect-error
      transitionToNext,
    } = this.args;

    this.segment.track('cards_customization_done', {
      origin: flowTrackingOrigin,
      card_level: CARD_LEVELS_TRACKING[this.contextCardLevel],
    });
    transitionToNext();
  }

  // @ts-expect-error
  _onDesignChange(design) {
    // @ts-expect-error
    this.activeLi = this.ul?.querySelector(`li[data-design="${design}"]`);
    // @ts-expect-error
    this.args.onColorChange(design);
    this._switchDesign();
  }

  _switchDesignWithoutAnimation() {
    if (this.shouldNotSwitchDesign) {
      return;
    }
    let { isLiFirstChild } = this;
    let indexToMove = isLiFirstChild ? 2 : 0;
    // @ts-expect-error
    this.liToMove = this.ul.querySelector(`li:nth-child(${indexToMove + 1})`);
    // @ts-expect-error
    this.ul.insertBefore(this.liToMove, isLiFirstChild ? this.ul.firstChild : null);
  }

  _switchDesign() {
    if (this.shouldNotSwitchDesign) {
      return;
    }

    this.isAnimating = macroCondition(isTesting()) ? false : true;

    let { isLiFirstChild } = this;

    // to get which design will be moved out of the screen
    // as the design moving out should be moving in at the same time from the other side, we need to clone it
    let indexToMove = isLiFirstChild ? 2 : 0;
    // @ts-expect-error
    let liToMove = this.ul.querySelector(`li:nth-child(${indexToMove + 1})`);
    // @ts-expect-error
    this.liClone = liToMove.cloneNode(true);
    // for tests sake (we don't care about the clone in tests)
    // @ts-expect-error
    this.liClone.removeAttribute('data-test-card-customization-asset');

    // we insert the clone but only 3 designs should be visible
    // we adjust the justify-content depending on the element we need to hide
    // @ts-expect-error
    this.ul.style['justify-content'] = isLiFirstChild ? 'flex-end' : 'flex-start';

    // @ts-expect-error
    liToMove.replaceWith(this.liClone);

    if (isLiFirstChild) {
      // @ts-expect-error
      this.ul.prepend(liToMove);
    } else {
      // @ts-expect-error
      this.ul.append(liToMove);
    }

    // designs translation to left or right
    // @ts-expect-error
    let px = this.ul.offsetWidth / 3;
    if (!isLiFirstChild) {
      px *= -1;
    }
    // @ts-expect-error
    this.ul.style.transform = `translateX(${px}px)`;
    // @ts-expect-error
    this.ul.style.transition = `transform ${TRANSITION_DURATION}ms ease-out 0s`;

    // translation comes with a fadeout of the design which moves out of the screen
    // @ts-expect-error
    this.liClone.animate([{ opacity: 1 }, { opacity: 0 }], {
      duration: FADE_DURATION,
      easing: 'linear',
      fill: 'forwards',
    });
    // the design which was moved out now moves in from the other side, with a fadein
    liToMove.animate([{ opacity: 0 }, { opacity: 1 }], {
      duration: FADE_DURATION,
      easing: 'linear',
      fill: 'forwards',
    });

    if (!this.isSafariBrowser) {
      this._playActiveVideos();
      this._stopInactiveVideos();
    }
  }

  @action
  // @ts-expect-error
  _transitionEnd(event) {
    // to avoid bubbling transitions and transforms from ul child elements
    if (event.propertyName !== 'transform' || event.target !== event.currentTarget) {
      return;
    }

    // @ts-expect-error
    this.liClone.remove();

    // reset the translation
    // @ts-expect-error
    this.ul.style.transform = `translateX(0)`;
    // @ts-expect-error
    this.ul.style.transition = 'none';

    this.isAnimating = false;
  }

  _playActiveVideos() {
    // @ts-expect-error
    this.activeLi.querySelectorAll('video').forEach(video => {
      // the video requires to be muted via js to then be played via js
      video.muted = true;

      this._playVideo.perform(video).catch(() => {
        // do nothing, not playing the video is enough
      });
    });
  }

  _stopInactiveVideos() {
    // @ts-expect-error
    this.ul.querySelectorAll('li').forEach(li => {
      if (li !== this.activeLi) {
        // @ts-expect-error
        li.querySelectorAll('video').forEach(video => {
          video.pause();
          video.currentTime = 0;
        });
      }
    });
  }

  _playVideo = task({ maxConcurrency: 2 }, async video => {
    //!\ See https://developer.chrome.com/blog/play-request-was-interrupted/
    if (video?.paused) {
      await video.play();
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Cards::Customization': typeof FlowsCardsCustomization;
  }
}
