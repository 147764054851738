export default {
  "chip": "gn",
  "chip--hidden": "gi",
  "chip--collapsed": "gA",
  "chip--selected": "gY",
  "chip--disabled": "gh",
  "wrapper": "gf",
  "content": "gK body-1",
  "clear": "gG",
  "clear-icon": "gr"
};
