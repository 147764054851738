/* import __COLOCATED_TEMPLATE__ from './fraud.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface FraudSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const Fraud = templateOnlyComponent<FraudSignature>();

export default Fraud;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'HelpCenter::SelectedItem::Claim::Fraud': typeof Fraud;
  }
}
