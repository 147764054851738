/* import __COLOCATED_TEMPLATE__ from './multi-accounts.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

const featuresOrderByTrigger = ['dedicatedBudget', 'independentExpenses', 'customizedInsights'];

interface DiscoverBundleMultiAccountsSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class DiscoverBundleMultiAccountsComponent extends Component<DiscoverBundleMultiAccountsSignature> {
  @service declare intl: Services['intl'];
  @service declare localeManager: Services['localeManager'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare segment: Services['segment'];
  @service declare subscriptionManager: Services['subscriptionManager'];

  name = 'multi-accounts';

  localSlides = {
    customizedInsights: {
      // @ts-expect-error
      title: this.intl.t('upsell.bundle-multi-accounts.customized-insights.title'),
      // @ts-expect-error
      description: this.intl.t('upsell.bundle-multi-accounts.customized-insights.text'),
      img: {
        de: '/illustrations/upsell/de/customized-insights.png',
        en: '/illustrations/upsell/en/customized-insights.png',
        es: '/illustrations/upsell/es/customized-insights.png',
        fr: '/illustrations/upsell/fr/customized-insights.png',
        it: '/illustrations/upsell/it/customized-insights.png',
      },
    },
    dedicatedBudget: {
      // @ts-expect-error
      title: this.intl.t('upsell.bundle-multi-accounts.dedicated-budget.title'),
      // @ts-expect-error
      description: this.intl.t('upsell.bundle-multi-accounts.dedicated-budget.text'),
      img: {
        de: '/illustrations/upsell/de/dedicated-budget.png',
        en: '/illustrations/upsell/en/dedicated-budget.png',
        es: '/illustrations/upsell/es/dedicated-budget.png',
        fr: '/illustrations/upsell/fr/dedicated-budget.png',
        it: '/illustrations/upsell/it/dedicated-budget.png',
      },
    },
    independentExpenses: {
      // @ts-expect-error
      title: this.intl.t('upsell.bundle-multi-accounts.independent-expenses.title'),
      // @ts-expect-error
      description: this.intl.t('upsell.bundle-multi-accounts.independent-expenses.text'),
      img: {
        de: '/illustrations/upsell/de/independent-expenses.png',
        en: '/illustrations/upsell/en/independent-expenses.png',
        es: '/illustrations/upsell/es/independent-expenses.png',
        fr: '/illustrations/upsell/fr/independent-expenses.png',
        it: '/illustrations/upsell/it/independent-expenses.png',
      },
    },
  };

  get slides() {
    return featuresOrderByTrigger.map(key => {
      // @ts-expect-error
      let { title, description, img } = this.localSlides[key];
      return {
        title,
        description,
        img: img[this.localeManager.locale],
      };
    });
  }

  @action
  onClick() {
    let { code: price_plan } = this.subscriptionManager.currentPricePlan;
    let { role } = this.organizationManager.membership;

    this.segment.track('upsell_bundle_page_clicked', {
      empty_state_name: this.name,
      price_plan,
      role,
    });

    // @ts-expect-error
    this.args.transitionToNext?.();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Discover::Bundle::MultiAccounts': typeof DiscoverBundleMultiAccountsComponent;
  }
}
