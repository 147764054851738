/* import __COLOCATED_TEMPLATE__ from './main.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';

import { Disclaimer } from '@repo/design-system-kit';
import { reads } from 'macro-decorators';

// @ts-expect-error
import computedDomiciliation from 'qonto/utils/computed-domiciliation';

interface BankAccountMainSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class BankAccountMainComponent extends Component<BankAccountMainSignature> {
  disclaimerInline = Disclaimer.Inline;

  @service declare intl: Services['intl'];
  @service declare featuresManager: Services['featuresManager'];
  @service declare zendeskLocalization: Services['zendeskLocalization'];

  // @ts-expect-error
  @reads('featuresManager.flags.accountDetailsIntermediaryBic') isIntermediaryBicEnabled;

  get addressFirstPart() {
    return (
      ['firstLine', 'secondLine', 'thirdLine']
        // @ts-expect-error
        .map(addressPart => this.args.organization.address[addressPart])
        .filter(v => v)
        .join(', ')
    );
  }

  get addressSecondPart() {
    // @ts-expect-error
    let address = this.args.organization.address;
    return address.zipcode + ', ' + address.city + ' - ' + address.country;
  }

  get clipboardRIB() {
    // @ts-expect-error
    let bankAccount = this.args.bankAccount;

    let parts = [
      { text: this.intl.t('bank_accounts.additional_details.bank'), data: 'bankCode' },
      { text: this.intl.t('bank_accounts.additional_details.agency'), data: 'branchCode' },
      { text: this.intl.t('bank_accounts.additional_details.account'), data: 'accountNumber' },
      { text: this.intl.t('bank_accounts.additional_details.key'), data: 'controlDigits' },
    ];

    return parts
      .map(({ text, data }) => bankAccount[data] && `${text}: ${bankAccount[data]}`)
      .filter(v => v)
      .join(`\n`);
  }

  // @ts-expect-error
  @computedDomiciliation() bankDomiciliation;

  get correspondentDisclaimerText() {
    return this.intl.t('bank_accounts.correspondent_bank.body', {
      // keep link text on the same line as <a></a> to avoid extra spaces around the link text
      // @ts-expect-error
      link: htmlSafe(
        `<a
          href=${this.intl.t('bank_accounts.correspondent_bank.link', {
            faqUrl: this.zendeskLocalization.getLocalizedArticle(4359548),
          })}
          target="_blank"
          rel="noopener noreferrer"
          data-test-link
         >${this.intl.t('bank_accounts.correspondent_bank.link-text')}</a>`
      ),
      htmlSafe: true,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'BankAccount::Main': typeof BankAccountMainComponent;
  }
}
