export default {
  "email-container": "cwG",
  "email-body": "cwr",
  "invoice-info": "cwb",
  "email-subject": "cwy",
  "reference-disclaimer": "cwU",
  "fake-button": "cwj",
  "einvoice-body": "cwW",
  "disabled-promo-card": "cwu"
};
