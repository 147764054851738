import { useEffect, useMemo, useState, type ReactNode } from 'react';
import {
  useReactTable,
  getCoreRowModel,
  getExpandedRowModel,
  type ColumnDef,
  createColumnHelper,
  flexRender,
  type ExpandedState,
  type Header,
  type CellContext,
} from '@tanstack/react-table';
import { useIntl } from 'react-intl';
import cx from 'clsx';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { ArrowRight } from 'qonto/react/assets/icons/arrow-right';
import { ArrowDown } from 'qonto/react/assets/icons/arrow-down';
import { IconCog } from 'qonto/react/assets/icons/cash-flow-categories/icon-cog';
import {
  generateLoadingCategoriesData,
  generateLoadingHeaders,
} from '../../utils/generate-loading-data';
import { TableCell } from '../shared/table-cell';
import type {
  CategoriesTableColumn,
  CategoriesTableRow,
} from '../../models/categories-table-display';
import { MemoizedTableCell } from '../shared/table-cell/table-cell';
import { CategoryCell } from './category-cell';
import { BalanceCell } from './balance-cell';
import styles from './styles.strict-module.css';

interface TableCellContext extends CellContext<CategoriesTableRow, CategoriesTableColumn[]> {
  rowIndex: number;
  columnIndex: number;
}

export interface Amount {
  value: string;
  currency: string;
}

const buildTable = (
  data: CategoriesTableRow[],
  sums: CategoriesTableColumn[],
  isLoading: boolean,
  numberOfColumns: number,
  headerLabel: string,
  onCellNavigation: (params: {
    rowIndex: number;
    columnIndex: number;
    direction: 'up' | 'down' | 'left' | 'right';
    enterEditMode: boolean;
  }) => void,
  bankAccounts?: string | undefined,
  isForecastEditingEnabled?: boolean,
  onForecastEntryUpdate?: () => void
): {
  tableData: CategoriesTableRow[];
  columns: ColumnDef<CategoriesTableRow>[];
} => {
  const headersData = isLoading
    ? generateLoadingHeaders(numberOfColumns)
    : Array.from({ length: numberOfColumns }, (_, index) => String(index));

  const tableData = isLoading ? generateLoadingCategoriesData(numberOfColumns) : data;

  const columnHelper = createColumnHelper<CategoriesTableRow>();
  const columns = [
    columnHelper.accessor('name', {
      header: headerLabel,
      cell: info => (
        <CategoryCell
          canExpand={info.row.getCanExpand()}
          color={info.row.original.color}
          icon={info.row.original.icon}
          isExpanded={info.row.getIsExpanded()}
          name={info.getValue()}
          onExpandToggle={info.row.getToggleExpandedHandler()}
          type={info.row.original.type}
        />
      ),
    }),
    ...headersData.map((col, index) =>
      columnHelper.accessor('columns', {
        id: col,
        // Set period total amount when available
        header: () => {
          return (
            <BalanceCell
              amount={sums[index]?.amount}
              bankAccounts={bankAccounts}
              categories={data}
              forecastAmount={sums[index]?.forecastAmountSum}
              interval={sums[index]?.interval}
            />
          );
        },
        cell: info => {
          const { rowIndex, columnIndex } = info as TableCellContext;
          const isUncategorized = info.row.original.type === 'uncategorized';
          const isSubcategory = info.row.original.type === 'subcategory';
          const enableForecastEditing =
            !isUncategorized && isForecastEditingEnabled && isSubcategory;

          return (
            <BalanceCell
              amount={info.getValue()[index]?.amount}
              bankAccounts={bankAccounts}
              categories={[info.row.original]}
              forecastAmount={info.getValue()[index]?.forecastAmount}
              interval={sums[index]?.interval}
              isForecastEditingEnabled={enableForecastEditing}
              onForecastEntryUpdate={onForecastEntryUpdate}
              onNavigateDown={(enterEditMode: boolean): void => {
                onCellNavigation({ rowIndex, columnIndex, direction: 'down', enterEditMode });
              }}
              onNavigateLeft={(enterEditMode: boolean) => {
                onCellNavigation({ rowIndex, columnIndex, direction: 'left', enterEditMode });
              }}
              onNavigateRight={(enterEditMode: boolean): void => {
                onCellNavigation({ rowIndex, columnIndex, direction: 'right', enterEditMode });
              }}
              onNavigateUp={(enterEditMode: boolean): void => {
                onCellNavigation({ rowIndex, columnIndex, direction: 'up', enterEditMode });
              }}
            />
          );
        },
      })
    ),
  ] as ColumnDef<CategoriesTableRow>[];

  return {
    tableData,
    columns,
  };
};

interface CategoriesTableProps {
  data: CategoriesTableRow[];
  sums: CategoriesTableColumn[];
  isLoading?: boolean;
  numberOfColumns?: number;
  headerLabelKey?: string;
  currentDateIndex?: number;
  bankAccounts?: string | undefined;
  isForecastEditingEnabled?: boolean;
  tbodyRef?: React.RefObject<HTMLTableSectionElement>;
  onCellNavigation: (params: {
    rowIndex: number;
    columnIndex: number;
    direction: 'up' | 'down' | 'left' | 'right';
    enterEditMode: boolean;
  }) => void;
  onForecastEntryUpdate?: () => void;
  offset?: number;
}

export function CategoriesTable({
  data,
  sums,
  isLoading = false,
  numberOfColumns = 4,
  headerLabelKey,
  currentDateIndex,
  bankAccounts,
  isForecastEditingEnabled = false,
  tbodyRef,
  onCellNavigation,
  onForecastEntryUpdate,
  offset = 0,
  ...props
}: CategoriesTableProps): ReactNode {
  const { formatMessage } = useIntl();
  const segment = useEmberService('segment');
  const router = useEmberService('router');
  const headerLabel = useMemo(
    () => formatMessage({ id: headerLabelKey }),
    [formatMessage, headerLabelKey]
  );

  const expandedTableLocalStorageKey = useMemo(
    () => `cashflow-categories-${headerLabel.toLowerCase()}-table`,
    [headerLabel]
  );

  const expandedRowsLocalStorageKey = useMemo(
    () => `cashflow-categories-${headerLabel.toLowerCase()}-table-rows`,
    [headerLabel]
  );

  const cachedExpandedTableState = localStorage.getItem(expandedTableLocalStorageKey);
  const cachedExpandedRowsState = localStorage.getItem(expandedRowsLocalStorageKey);

  const [isTableExpanded, setIsTableExpanded] = useState(
    cachedExpandedTableState ? (JSON.parse(cachedExpandedTableState) as boolean) : true
  );
  const [expandedRows, setExpandedRows] = useState<ExpandedState>(
    cachedExpandedRowsState ? (JSON.parse(cachedExpandedRowsState) as ExpandedState) : true
  );

  useEffect(() => {
    localStorage.setItem(expandedTableLocalStorageKey, JSON.stringify(isTableExpanded));
  }, [isTableExpanded, expandedTableLocalStorageKey]);

  useEffect(() => {
    localStorage.setItem(expandedRowsLocalStorageKey, JSON.stringify(expandedRows));
  }, [expandedRows, expandedRowsLocalStorageKey]);

  useEffect(() => {
    const expandedRowsCount = Object.keys(expandedRows).length;
    if (isTableExpanded && expandedRowsCount) {
      segment.track('cash-flow_category_expand');
    }
  }, [expandedRows, segment, isTableExpanded]);

  const { tableData, columns } = useMemo(
    () =>
      buildTable(
        data,
        sums,
        isLoading,
        numberOfColumns,
        headerLabel,
        onCellNavigation,
        bankAccounts,
        isForecastEditingEnabled,
        onForecastEntryUpdate
      ),
    [
      data,
      sums,
      isLoading,
      numberOfColumns,
      headerLabel,
      onCellNavigation,
      bankAccounts,
      isForecastEditingEnabled,
      onForecastEntryUpdate,
    ]
  );

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      expanded: expandedRows,
    },
    onExpandedChange: setExpandedRows,
    getCoreRowModel: getCoreRowModel(),
    getSubRows: row => row.subRows,
    getExpandedRowModel: getExpandedRowModel(),
    getRowCanExpand: row => Boolean(row.subRows.length),
  });

  const handleManageCategories = (header: Header<CategoriesTableRow, unknown>): void => {
    const title =
      typeof header.column.columnDef.header === 'string'
        ? header.column.columnDef.header.toLowerCase()
        : undefined;
    if (title) {
      segment.track('cash-flow_category_manage');
      void router.transitionTo('cash-flow-categories.type', title);
    }
  };

  return (
    <table aria-busy={isLoading} aria-live="polite" className={styles.categoriesTable} {...props}>
      <colgroup>
        <col />
        {Array.from({ length: numberOfColumns }, (_, index) => (
          <col key={index} />
        ))}
      </colgroup>
      <thead>
        {table.getHeaderGroups().map(headerGroup => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header, index) => {
              const isFirstColumn = index === 0;
              return (
                <th
                  className={cx(
                    styles.colHeader,
                    !isTableExpanded && styles.tableCollapsed,
                    currentDateIndex === index && styles.current
                  )}
                  key={header.id}
                  scope="col"
                >
                  {header.isPlaceholder ? null : (
                    <TableCell
                      align={isFirstColumn ? 'left' : 'center'}
                      data-header-col={index}
                      isLoading={isFirstColumn ? false : isLoading}
                    >
                      {isFirstColumn ? (
                        <div className={styles.colHeaderActions}>
                          <div className={styles.colHeaderActionsLabel}>
                            <button
                              className={styles.categoriesActionButton}
                              data-testid="expand-table-button"
                              onClick={() => {
                                setIsTableExpanded(!isTableExpanded);
                              }}
                              type="button"
                            >
                              {isTableExpanded ? <ArrowDown /> : <ArrowRight />}
                            </button>
                            <span data-testid="table-title">
                              {flexRender(header.column.columnDef.header, header.getContext())}
                            </span>
                          </div>

                          <button
                            className={styles.categoriesActionButton}
                            data-testid="manage-categories-button"
                            onClick={() => {
                              handleManageCategories(header);
                            }}
                            type="button"
                          >
                            <IconCog data-testid="manage-categories-icon" />
                          </button>
                        </div>
                      ) : (
                        flexRender(header.column.columnDef.header, header.getContext())
                      )}
                    </TableCell>
                  )}
                </th>
              );
            })}
          </tr>
        ))}
      </thead>

      <tbody ref={tbodyRef}>
        {isTableExpanded
          ? table.getRowModel().rows.map((row, rowIndex) => {
              const dataIsSubcategory =
                row.original.type === 'subcategory' || row.original.type === 'uncategorized';

              return (
                <tr data-is-editable={dataIsSubcategory} key={row.id}>
                  {row.getVisibleCells().map((cell, cellIndex) => {
                    const isCurrentDate = cellIndex === currentDateIndex;

                    if (cellIndex === 0) {
                      return (
                        <th
                          className={styles.rowHeader}
                          data-testid="row-header"
                          key={cell.id}
                          scope="row"
                        >
                          <TableCell
                            align="left"
                            data-col={cellIndex}
                            data-row={rowIndex}
                            isLabel
                            isLoading={isLoading}
                          >
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </TableCell>
                        </th>
                      );
                    }

                    const keyId = `${offset}-${row.original.id}-${cellIndex}`;

                    if (dataIsSubcategory) {
                      return (
                        <td
                          className={`${styles.rowCell} ${isCurrentDate ? styles.current : ''}`}
                          key={keyId}
                          role="gridcell"
                        >
                          <MemoizedTableCell
                            data-col={cellIndex}
                            data-row={rowIndex}
                            isLoading={isLoading}
                          >
                            {flexRender(cell.column.columnDef.cell, {
                              ...cell.getContext(),
                              rowIndex,
                              columnIndex: cellIndex,
                            })}
                          </MemoizedTableCell>
                        </td>
                      );
                    }

                    return (
                      <td
                        className={`${styles.rowCell} ${isCurrentDate ? styles.current : ''}`}
                        key={cell.id}
                        role="gridcell"
                      >
                        <TableCell data-col={cellIndex} data-row={rowIndex} isLoading={isLoading}>
                          {flexRender(cell.column.columnDef.cell, {
                            ...cell.getContext(),
                            rowIndex,
                            columnIndex: cellIndex,
                          })}
                        </TableCell>
                      </td>
                    );
                  })}
                </tr>
              );
            })
          : null}
      </tbody>
    </table>
  );
}
