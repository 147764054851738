// @ts-nocheck
import Service, { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { dropTask, restartableTask } from 'ember-concurrency';

import { apiBaseURL, productsNamespace } from 'qonto/constants/hosts';
import { MIGRATION_STATUSES } from 'qonto/constants/products';

export default class ProductsCatalogService extends Service {
  @service networkManager;
  @service store;

  @tracked migrationStatus;

  get isMigrationPending() {
    return this.migrationStatus === MIGRATION_STATUSES.PENDING;
  }

  get isMigrationDismissed() {
    return this.migrationStatus === MIGRATION_STATUSES.DISMISSED;
  }

  get isMigrationCompleted() {
    return this.migrationStatus === MIGRATION_STATUSES.COMPLETED;
  }

  fetchMigrationStatusTask = dropTask(async () => {
    let response = await this.networkManager.request(
      `${apiBaseURL}/${productsNamespace}/products/migration_status`
    );
    this.migrationStatus = response.migration_status;
    return this.migrationStatus;
  });

  bulkCreateProductsTask = dropTask(async products => {
    return await this.networkManager.request(`${apiBaseURL}/${productsNamespace}/products/bulk`, {
      method: 'POST',
      data: JSON.stringify({
        products,
      }),
    });
  });

  bulkDeleteProductsTask = dropTask(async (productIds, onSuccess) => {
    await this.networkManager.request(`${apiBaseURL}/${productsNamespace}/products/bulk_delete`, {
      method: 'POST',
      data: JSON.stringify({ product_ids: productIds }),
    });

    onSuccess?.();
  });

  fetchProductsTask = restartableTask(async params => {
    let fetchParams = {
      page: { number: 1, size: 200 },
      sort_by: 'title',
      ...params,
    };

    let products = await this.store.query('product', fetchParams);
    let total = products.meta.total_count;

    while (total > products.length) {
      fetchParams.page.number++;
      products = await this.store.query('product', fetchParams);
    }

    return products;
  });
}

declare module '@ember/service' {
  interface Registry {
    'products-catalog': ProductsCatalogService;
    productsCatalog: ProductsCatalogService;
  }
}
