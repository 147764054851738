/* import __COLOCATED_TEMPLATE__ from './detailed-fees.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { Disclaimer } from '@repo/design-system-kit';
import { reads } from 'macro-decorators';

interface SubscritionDetailedFeesSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class SubscritionDetailedFeesComponent extends Component<SubscritionDetailedFeesSignature> {
  disclaimerBlock = Disclaimer.Block;

  @service declare intl: Services['intl'];
  @service declare organizationManager: Services['organizationManager'];

  // @ts-expect-error
  @reads('organizationManager.organization') organization;

  fees = {
    sepa_transfers_and_sdds: {
      icon: 'consumption-transfer',
      // @ts-expect-error
      title: this.intl.t(
        'subscription.consumption-table.plan-overview.details-fees.features.sepa_transfers_and_sdds.title'
      ),
      // @ts-expect-error
      subtitle: options => {
        return this.intl.t(
          'subscription.consumption-table.plan-overview.details-fees.features.sepa_transfers_and_sdds.subtitle',
          options
        );
      },
    },
    swift_incomes: {
      icon: 'icon_arrow_bottom_outlined',
      // @ts-expect-error
      title: this.intl.t(
        'subscription.consumption-table.plan-overview.details-fees.features.swift_incomes.title'
      ),
      // @ts-expect-error
      subtitle: options => {
        return this.intl.t(
          'subscription.consumption-table.plan-overview.details-fees.features.swift_incomes.subtitle',
          options
        );
      },
    },
    fx_transfers: {
      icon: 'icon_arrow_top_outlined',
      // @ts-expect-error
      title: this.intl.t(
        'subscription.consumption-table.plan-overview.details-fees.features.fx_transfers.title'
      ),
      // @ts-expect-error
      subtitle: (options, { isAmountAndPercentUnit }) => {
        if (isAmountAndPercentUnit) {
          return this.intl.t(
            'subscription.consumption-table.plan-overview.details-fees.features.fx_transfers.subtitle-fees',
            { ...options, fees: options.price }
          );
        }
        return this.intl.t(
          'subscription.consumption-table.plan-overview.details-fees.features.fx_transfers.subtitle-generic',
          options
        );
      },
    },
    one_cards: {
      icon: 'icon_card_outlined',
      // @ts-expect-error
      title: this.intl.t(
        'subscription.consumption-table.plan-overview.details-fees.features.one_cards.title'
      ),
      // @ts-expect-error
      subtitle: options => {
        return this.intl.t(
          'subscription.consumption-table.plan-overview.details-fees.features.one_cards.subtitle',
          options
        );
      },
    },
    one_cards_atm: {
      icon: 'icon_nav_atm',
      // @ts-expect-error
      title: this.intl.t(
        'subscription.consumption-table.plan-overview.details-fees.features.one_cards_atm.title'
      ),
      // @ts-expect-error
      subtitle: options => {
        return this.intl.t(
          'subscription.consumption-table.plan-overview.details-fees.features.one_cards_atm.subtitle',
          options
        );
      },
    },
    plus_cards: {
      icon: 'icon_card_outlined',
      // @ts-expect-error
      title: this.intl.t(
        'subscription.consumption-table.plan-overview.details-fees.features.plus_cards.title'
      ),
      // @ts-expect-error
      subtitle: options => {
        return this.intl.t(
          'subscription.consumption-table.plan-overview.details-fees.features.plus_cards.subtitle',
          options
        );
      },
    },
    plus_cards_atm: {
      icon: 'icon_nav_atm',
      // @ts-expect-error
      title: this.intl.t(
        'subscription.consumption-table.plan-overview.details-fees.features.plus_cards_atm.title'
      ),
      // @ts-expect-error
      subtitle: options => {
        return this.intl.t(
          'subscription.consumption-table.plan-overview.details-fees.features.plus_cards_atm.subtitle',
          options
        );
      },
    },
    metal_cards: {
      icon: 'icon_card_outlined',
      // @ts-expect-error
      title: this.intl.t(
        'subscription.consumption-table.plan-overview.details-fees.features.metal_cards.title'
      ),
      // @ts-expect-error
      subtitle: options => {
        return this.intl.t(
          'subscription.consumption-table.plan-overview.details-fees.features.metal_cards.subtitle',
          options
        );
      },
    },
    virtual_cards: {
      icon: 'consumption-virtual-card',
      // @ts-expect-error
      title: this.intl.t(
        'subscription.consumption-table.addon-overview.details-fees.features.virtual_cards.title'
      ),
      // @ts-expect-error
      subtitle: options => {
        return this.intl.t(
          'subscription.consumption-table.addon-overview.details-fees.features.virtual_cards.subtitle',
          { total: options.count, price: options.price }
        );
      },
    },
    checks: {
      icon: 'icon_check',
      // @ts-expect-error
      title: this.intl.t(
        'subscription.consumption-table.plan-overview.details-fees.features.check_deposits.title'
      ),
      // @ts-expect-error
      subtitle: (options, { hasIncludedChecks }) => {
        if (hasIncludedChecks) {
          return this.intl.t(
            'subscription.consumption-table.plan-overview.details-fees.features.check_deposits.subtitle.other-plans',
            options
          );
        }
        return this.intl.t(
          'subscription.consumption-table.plan-overview.details-fees.features.check_deposits.subtitle.basic-plan',
          options
        );
      },
    },
    direct_debit_collection_send: {
      icon: 'consumption-incoming-direct-debits',
      // @ts-expect-error
      title: this.intl.t(
        'subscription.consumption-table.plan-overview.details-fees.features.incoming_direct_debits.title'
      ),
      // @ts-expect-error
      subtitle: options => {
        return this.intl.t(
          'subscription.consumption-table.plan-overview.details-fees.features.incoming_direct_debits.subtitle',
          options
        );
      },
    },
    memberships: {
      icon: 'icon_user_outlined',
      // @ts-expect-error
      title: this.intl.t(
        'subscription.consumption-table.plan-overview.details-fees.features.memberships.title'
      ),
      // @ts-expect-error
      subtitle: options => {
        return this.intl.t(
          'subscription.consumption-table.plan-overview.details-fees.features.memberships.subtitle',
          options
        );
      },
    },
    other_fees: {
      icon: 'consumption-invoice',
      // @ts-expect-error
      title: this.intl.t(
        'subscription.consumption-table.plan-overview.details-fees.features.other_fees.title'
      ),
      subtitle: () => {
        return this.intl.t(
          'subscription.consumption-table.plan-overview.details-fees.features.other_fees.subtitle'
        );
      },
    },
  };

  get detailedFeesList() {
    // Returns an array of fee items to be used in DetailedFees component
    // @ts-expect-error
    return this.args.additionalFees.map(fee => {
      let { code, totalAmount } = fee;
      // @ts-expect-error
      let { title, icon } = this.fees[code];
      let { value: totalFee, currency } = totalAmount;
      let subtitle = this.getSubtitleFor(fee);

      return { code, icon, currency, title, totalFee, subtitle };
    });
  }

  // @ts-expect-error
  getSubtitleFor(fee) {
    let {
      code,
      extraItemAmount,
      extraItemUnit,
      extraItemAmountPercent: rate,
      consumedNumber,
      limitNumber,
    } = fee;

    let options = {
      count: consumedNumber,
      limitIncluded: limitNumber,
      price: extraItemAmount?.value
        ? this.intl.formatNumber(extraItemAmount.value, {
            currency: extraItemAmount.currency,
            style: 'currency',
          })
        : null,
      countLessThanLimit: consumedNumber < limitNumber,
      rate,
    };

    let conditions = {
      hasIncludedChecks: limitNumber > 0,
      isAmountAndPercentUnit: extraItemUnit === 'amount_and_percent',
    };

    // @ts-expect-error
    return this.fees[code].subtitle(options, conditions);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Subscription::DetailedFees': typeof SubscritionDetailedFeesComponent;
  }
}
