/* import __COLOCATED_TEMPLATE__ from './check-sidebar.hbs'; */
import Component from '@glimmer/component';

import { CheckSidebarStatus } from 'qonto/react/components/checks/check-sidebar/status';

interface CheckSidebarSignature {
  Element: HTMLDivElement;
  Args: {};
  Blocks: {
    default: [];
  };
}

export default class CheckSidebar extends Component<CheckSidebarSignature> {
  CheckSidebarStatus = CheckSidebarStatus;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    CheckSidebar: typeof CheckSidebar;
  }
}
