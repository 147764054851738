// @ts-nocheck
import Service, { service } from '@ember/service';
import { waitForPromise } from '@ember/test-waiters';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';

import { apiBaseURL, requestsNamespace } from 'qonto/constants/hosts';
import { REQUEST_EVENTS } from 'qonto/constants/listeners';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class NotifierCounterManager extends Service {
  @service abilities;
  @service featuresManager;
  @service networkManager;
  @service notifierManager;
  @service organizationManager;
  @service sentry;

  @tracked counter = null;
  hasSubscription = false;

  willDestroy() {
    this._unsubscribe();
    super.willDestroy(...arguments);
  }

  get hasAccessCounter() {
    return (
      this.abilities.can('renew card') ||
      this.abilities.can('create transfer') ||
      this.abilities.can('review transfer request') ||
      this.abilities.can('review card request') ||
      this.abilities.can('review expense report request') ||
      this.abilities.can('review mileage request') ||
      this.abilities.can('view mandate') ||
      this.abilities.can('review direct debit collection request')
    );
  }

  /**
   * Triggers new event subscription for requests count
   *
   * @public
   * @method subscribe
   *
   * @returns void
   */
  subscribe() {
    if (this.hasSubscription) {
      this._unsubscribe();
    }
    if (!this.hasAccessCounter) {
      return;
    }

    this.updateCounter();

    for (let event of Object.values(REQUEST_EVENTS)) {
      this.notifierManager.on(event, this, 'updateCounter');
    }

    this.hasSubscription = true;
  }

  /**
   * Unsubscribe for all events
   *
   * @private
   * @method _unsubscribe
   *
   * @returns void
   */
  _unsubscribe() {
    this.counter = null;

    for (let event of Object.values(REQUEST_EVENTS)) {
      this.notifierManager.off(event, this, 'updateCounter');
    }
  }

  /**
   * Update the counter
   */
  async updateCounter() {
    try {
      await waitForPromise(this.updateTask.perform().catch(ignoreCancelation));
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  }

  updateTask = dropTask(async () => {
    let { organization } = this.organizationManager;

    if (organization) {
      let response = await this.networkManager.request(
        `${apiBaseURL}/${requestsNamespace}/requests/counters`,
        {
          data: { organization_id: organization.id },
        }
      );

      let COMPLETED_PROPS = ['canceled', 'approved', 'declined'];
      let ALL_PROPS = ['canceled', 'approved', 'declined', 'pending'];

      this.counter = {
        request: response?.total_pending,
        cardRequests: response?.card_requests?.pending,
        expenseReportRequests: response?.expense_report_requests?.pending,
        expenseReportRequestsToPay: response?.expense_report_requests?.to_pay,
        expenseReportRequestsToReview: response?.expense_report_requests?.to_review,
        mileageRequests: response?.mileage_requests?.pending,
        mileageRequestsToPay: response?.mileage_requests?.to_pay,
        mileageRequestsToReview: response?.mileage_requests?.to_review,
        expenseReportCompleted: this.count(response?.expense_report_requests, COMPLETED_PROPS),
        mileageCompleted: this.count(response?.mileage_requests, COMPLETED_PROPS),
        transferRequests: response?.transfer_requests?.pending,
        directDebitCollectionRequests: response?.direct_debit_collection_requests?.pending,
        transferRequestsCompleted: this.count(response?.transfer_requests, COMPLETED_PROPS),
        // totals per request type
        totalCardRequests: this.count(response?.card_requests, ALL_PROPS),
        totalExpenseReportRequests: this.count(response?.expense_report_requests, ALL_PROPS),
        totalMileageRequests: this.count(response?.mileage_requests, ALL_PROPS),
        totalTransferRequests: this.count(response?.transfer_requests, ALL_PROPS),
        totalDirectDebitCollectionRequests: this.count(
          response?.direct_debit_collection_requests,
          ALL_PROPS
        ),
      };
    }
  });

  count(obj = {}, keys = []) {
    return keys.reduce((sum, key) => sum + obj[key] || 0, 0);
  }
}

declare module '@ember/service' {
  interface Registry {
    'notifier-counter-manager': NotifierCounterManager;
    notifierCounterManager: NotifierCounterManager;
  }
}
