/* import __COLOCATED_TEMPLATE__ from './table.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

interface ReceivableInvoicesTableSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class ReceivableInvoicesTableComponent extends Component<ReceivableInvoicesTableSignature> {
  @service declare router: Services['router'];
  @service declare intl: Services['intl'];

  // @ts-expect-error
  @action handleShowItem(document) {
    // @ts-expect-error
    if (this.args.isQuote) {
      this.router.transitionTo('quotes.show', document.id);
      return;
    }
    this.router.transitionTo('receivable-invoices.show', document.id, {
      queryParams: document?.imported ? { imported: document.imported } : {},
    });
  }

  // @ts-expect-error
  @action handleItemKeydown(document, event) {
    if (event.key === 'Enter') {
      this.handleShowItem(document);
    }
  }

  get noResultsError() {
    // @ts-expect-error
    if (this.args.isQuote) {
      return {
        title: this.intl.t(
          'receivable-invoices.quotes-list.tabs.completed.errors.no-results.empty-state.title'
        ),
        body: this.intl.t(
          'receivable-invoices.quotes-list.tabs.completed.errors.no-results.empty-state.body'
        ),
      };
    }

    // @ts-expect-error
    if (this.args.query !== '') {
      return {
        title: this.intl.t('empty-states.system.inform.i2.title'),
        body: this.intl.t('empty-states.system.inform.i2.subtitle'),
      };
    }

    return {
      title: this.intl.t(
        'receivable-invoices.invoices-list.tabs.completed.errors.no-results.empty-state.title'
      ),
      body: this.intl.t(
        'receivable-invoices.invoices-list.tabs.completed.errors.no-results.empty-state.body'
      ),
    };
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ReceivableInvoices::Table': typeof ReceivableInvoicesTableComponent;
  }
}
