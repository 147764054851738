/* import __COLOCATED_TEMPLATE__ from './no-result.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface NoResultSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const NoResult = templateOnlyComponent<NoResultSignature>();

export default NoResult;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Counterparties::Table::NoResult': typeof NoResult;
  }
}
