export default {
  "row": "Av",
  "active": "Ao",
  "cell": "An",
  "empty": "Ai",
  "supplier": "AA",
  "supplier-content": "AY",
  "supplier-avatar": "Ah mr-16",
  "financing-status": "Af body-2",
  "installments": "AK body-2",
  "overflow-hidden": "AG",
  "ellipsis": "Ar",
  "next-installment-date": "Ab",
  "next-installment-amount": "Ay body-2",
  "amount": "AU",
  "disclaimer": "Aj",
  "disclaimer-icon": "AW",
  "warning": "Au",
  "error": "YS",
  "total-repayment": "Yc body-1",
  "tooltip-container": "Yq"
};
