export default {
  "container": "bw",
  "disabled-option": "bN",
  "content": "bv",
  "spinner": "bo",
  "subtitle": "bn",
  "details-table": "bi",
  "caption": "bA",
  "tooltip-icon": "bY",
  "tooltip-icon-right": "bh",
  "card-monthly-cost": "bf",
  "card-monthly-discounted-cost": "bK",
  "strikethrough": "bG",
  "purple-text": "br",
  "budget-label": "bb",
  "card-cost-label": "by",
  "card-billing-account": "bU"
};
