export default {
  "subtitle": "cyy",
  "green-text": "cyU",
  "balance-subtitle": "cyj",
  "options": "cyW",
  "shadow": "cyu",
  "menu-item": "cUS body-2",
  "close-account": "cUc",
  "footer": "cUq",
  "progress": "cUZ",
  "progress-bar": "cUR",
  "progress-bar-text": "cUQ",
  "progress-active": "cUe"
};
