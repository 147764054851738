export default {
  "content": "cer",
  "error-message": "ceb",
  "wrapper": "cey",
  "banner": "ceU",
  "banner-content": "cej",
  "banner-text": "ceW",
  "banner-image": "ceu",
  "banner-title": "cBS",
  "banner-description": "cBc body-2",
  "link": "cBq",
  "plans-comparison": "cBZ",
  "plan-box": "cBR",
  "arrow": "cBQ",
  "arrow-icon": "cBe",
  "link-downgrade": "cBB",
  "stroke-text": "cBE"
};
