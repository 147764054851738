/* import __COLOCATED_TEMPLATE__ from './phone-number-verification.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

// @ts-expect-error
import { MFA_METHODS } from '@qonto/qonto-sca/constants/mfa';
import { LottiePlayer } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';

import { authBaseURL } from 'qonto/constants/hosts';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

const ERROR_CODES = ['invalid_token', 'missing_token'];

interface PopupSecurityPhoneNumberVerificationSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class PopupSecurityPhoneNumberVerificationComponent extends Component<PopupSecurityPhoneNumberVerificationSignature> {
  lottiePlayer = LottiePlayer;

  @service declare errors: Services['errors'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare intl: Services['intl'];
  @service declare modals: Services['modals'];
  @service declare networkManager: Services['networkManager'];
  @service declare otpManager: Services['otpManager'];
  @service declare segment: Services['segment'];
  @service declare sensitiveActions: Services['sensitiveActions'];
  @service declare sentry: Services['sentry'];
  @service declare userManager: Services['userManager'];

  // @ts-expect-error
  @tracked errorMessage;
  // @ts-expect-error
  @tracked verificationCode;

  @tracked mfaOptionsModal = null;
  mfaOptions = [];

  constructor(owner: unknown, args: PopupSecurityPhoneNumberVerificationSignature['Args']) {
    super(owner, args);
    let { currentUser } = this.userManager;
    // @ts-expect-error
    if (currentUser.mfaEnabled && !currentUser.scaEnabled && currentUser.phoneNumber) {
      this.fetchMFAOptionsTask.perform().catch(ignoreCancelation);
    }
  }

  willDestroy() {
    // @ts-expect-error
    this.mfaOptionsModal?.close();
    // @ts-expect-error
    super.willDestroy(...arguments);
  }

  get description() {
    // @ts-expect-error
    let phoneNumber = this.userManager.currentUser?.pendingPhoneNumber;
    return this.intl.t('users.profile.modals.confirm-phone-number.subtitle', { phoneNumber });
  }

  fetchMFAOptionsTask = dropTask(async () => {
    try {
      let { mfa_options } = await this.networkManager.request(
        `${authBaseURL}/v5/user/phone_number/mfa_options`
      );
      this.mfaOptions = mfa_options;
    } catch {
      // ignore error, if it fails we default to MFA_METHODS.SMS
    }
  });

  // @ts-expect-error
  @action updateVerificationCode(value) {
    this.errorMessage = null;
    this.verificationCode = value;
  }

  validateOtpTask = dropTask(async otp => {
    try {
      await this.networkManager.request(
        `${authBaseURL}/v5/user/phone_number/validate_new_phone_otp`,
        {
          method: 'POST',
          data: JSON.stringify({
            new_phone_otp: otp,
          }),
        }
      );
      return true;
    } catch (error) {
      // @ts-expect-error
      if (error.errors?.some(({ code }) => ERROR_CODES.includes(code))) {
        return false;
      }
      throw error;
    }
  });

  showMfaOptionsTask = dropTask(async () => {
    try {
      if (this.mfaOptions.length > 1) {
        let isOtpValid = await this.validateOtpTask.perform(this.verificationCode);
        if (!isOtpValid) {
          this.errorMessage = this.intl.t('users.profile.security.errors.invalid-token');
          return;
        }
        // @ts-expect-error
        this.mfaOptionsModal = this.modals.open('popup/security/mfa-options', {
          mfaOptions: this.mfaOptions,
          selectMethodTask: this.activatePhoneNumberSensitiveTask,
        });
        await this.mfaOptionsModal;
        // mfaOptionsModal might be set to null at line 125
        if (this.mfaOptionsModal) {
          // @ts-expect-error
          this.args.close();
        }
      } else {
        await this.activatePhoneNumberSensitiveTask.perform(MFA_METHODS.SMS);
      }
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) this.sentry.captureException(error);
      this.toastFlashMessages.toastError(
        // @ts-expect-error
        this.errors.messageForStatus(error) || this.intl.t('toasts.errors.server_error')
      );
    }
  });

  activatePhoneNumberSensitiveTask = dropTask(async mfaMethod => {
    // @ts-expect-error
    this.otpManager.clearToken(); // clear mfa token
    // @ts-expect-error
    await this.sensitiveActions.runTask.linked().perform(this.activatePhoneNumberTask, mfaMethod);
  });

  activatePhoneNumberTask = dropTask(async mfaMethod => {
    try {
      switch (mfaMethod) {
        case 'by_email':
          this.segment.track('profile_phone-update_mfa_email');
          break;
        case 'by_sms':
          this.segment.track('profile_phone-update_mfa_text', {
            has_options: this.mfaOptions.length > 1,
          });
          break;
      }

      await this.networkManager.request(`${authBaseURL}/v5/user/phone_number/activate`, {
        method: 'POST',
        data: JSON.stringify({
          new_phone_otp: this.verificationCode,
          mfa_method: mfaMethod,
        }),
      });

      // @ts-expect-error
      this.args.close('success');
    } catch (error) {
      // @ts-expect-error
      if (error.errors?.some(({ code }) => ERROR_CODES.includes(code))) {
        this.errorMessage = this.intl.t('users.profile.security.errors.invalid-token');
        // @ts-expect-error
        this.mfaOptionsModal?.close();
        this.mfaOptionsModal = null;
        return;
      }
      throw error;
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Popup::Security::PhoneNumberVerification': typeof PopupSecurityPhoneNumberVerificationComponent;
  }
}
