import { InvalidError } from '@ember-data/adapter/error';
import { attr, belongsTo } from '@ember-data/model';
import { service, type Registry as Services } from '@ember/service';
import { waitFor } from '@ember/test-waiters';

// @ts-expect-error
import { apiAction } from '@mainmatter/ember-api-actions';
import { bool, equal, or } from 'macro-decorators';

import Subject from 'qonto/models/subject';
// @ts-expect-error
import cmc7Splitter from 'qonto/utils/cmc7-splitter';
// @ts-expect-error
import { errorsArrayToHash } from 'qonto/utils/errors-array-to-hash';
// @ts-expect-error
import Validations from 'qonto/validations/check';

// @ts-expect-error
export default class CheckModel extends Subject.extend(Validations) {
  @service declare networkManager: Services['networkManager'];

  @attr('string') declare sequentialId: string;
  @attr('string') declare initiatorId: string;

  @attr('string') declare emitterName: string;
  @attr('number') declare amount: number;
  @attr('string') declare amountCurrency: string;
  @attr('string') declare shippingAddress: string;
  @attr('string') declare rlmc: string;

  // Here, cmc7 is the 'real' attribute sent and received from/to backend
  // @ts-expect-error
  @attr cmc7;

  // For UI purpose cmc7 is splitted in 3 parts, and joined in check serializer
  // For UI purpose cmc7 is splitted in 3 parts, and joined in check serializer
  @attr('string') declare cmc7a: string;
  @attr('string') declare cmc7b: string;
  @attr('string') declare cmc7c: string;

  @attr('number') declare creditDelayDays: number;
  @attr('string') declare status: string;
  @attr('string') declare declinedReason: string;
  // @ts-expect-error
  @attr emittedDate;
  @attr('date') declare receivedAt: Date;
  @attr('date') declare declinedAt: Date;
  @attr('date') declare refundedAt: Date;
  @attr('date') declare canceledAt: Date;
  @attr('date') declare validatedAt: Date;
  @attr('date') declare createdAt: Date;
  // @ts-expect-error
  @attr('hash', {
    defaultValue: () => {
      return {};
    },
  })
  // @ts-expect-error
  enrichmentData;

  // @ts-expect-error
  @belongsTo('organization', { async: true, inverse: null }) organization;
  // @ts-expect-error
  @belongsTo('bankAccount', { async: false, inverse: null }) bankAccount;
  // @ts-expect-error
  @belongsTo('membership', { async: false, inverse: null }) initiator;

  get checkNumber() {
    return this.cmc7 ? cmc7Splitter(this.cmc7, ' - ') : '';
  }

  get isDeclinedAndNotReceived() {
    return this.declined && this.declinedReason === 'not_received';
  }

  get isOtherThanNotReceived() {
    return this.declinedReason !== 'not_received';
  }

  // @ts-expect-error
  @bool('initiatorId') isCreatedByUser;
  // @ts-expect-error
  @or('declined', 'refunded', 'canceled') ghost;
  // @ts-expect-error
  @or('received', 'validated', 'declined', 'refunded') hasArrived;
  // @ts-expect-error
  @or('validated', 'declined', 'refunded') hasResponse;

  // @ts-expect-error
  @equal('status', 'pending') pending;
  // @ts-expect-error
  @equal('status', 'validated') validated;
  // @ts-expect-error
  @equal('status', 'declined') declined;
  // @ts-expect-error
  @equal('status', 'refunded') refunded;
  // @ts-expect-error
  @equal('status', 'received') received;
  // @ts-expect-error
  @equal('status', 'canceled') canceled;

  get avatarInfo() {
    let checkSVG = `/icon/feature/feature_check-m.svg`;

    let icon = 'status_cancelled';

    if (this.pending || this.received) {
      icon = 'status_processing';
    } else if (this.validated) {
      icon = 'status_approved';
    }

    return {
      mediumLogo: this.enrichmentData?.logo?.medium ?? checkSVG,
      smallLogo: this.enrichmentData?.logo?.small ?? checkSVG,
      icon,
    };
  }

  @waitFor
  async confirm() {
    let data = { check: this.serialize() };
    try {
      return await apiAction(this, {
        requestType: 'createRecord',
        method: 'POST',
        path: 'confirm',
        data,
      });
    } catch (error) {
      // @ts-expect-error
      if (error instanceof InvalidError && error.errors) {
        // @ts-expect-error
        let errors = errorsArrayToHash(error.errors);
        if (errors.amount_cents) {
          errors.amount = errors.amount_cents;
          delete errors.amount_cents;
        }
        this.networkManager.errorModelInjector(this, errors);
      }
      throw error;
    }
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    check: CheckModel;
  }
}
