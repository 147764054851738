export default {
  "wrapper": "cpg",
  "per-page-container": "cpD",
  "options": "cpV",
  "option": "cpL",
  "active": "cpT",
  "description": "cpz",
  "description-per-page": "cpa",
  "summary": "cpH"
};
