export default {
  "option": "cOJ",
  "account-description": "cOs",
  "account-identification": "cOF",
  "account-avatar": "cOg",
  "account-name": "cOD body-2",
  "account-balance": "cOV body-2",
  "external-accounts-disclaimer": "cOL",
  "status": "cOT body-2",
  "failed-connection": "cOz"
};
