/* import __COLOCATED_TEMPLATE__ from './insurance-support-contact.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface InsuranceSupportContactSignature {
  Element: HTMLDivElement;
  Args: {};
  Blocks: {
    default: [];
  };
}

const InsuranceSupportContact = templateOnlyComponent<InsuranceSupportContactSignature>();

export default InsuranceSupportContact;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InsuranceAssistance::InsuranceSupportContact': typeof InsuranceSupportContact;
  }
}
