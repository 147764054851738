/* import __COLOCATED_TEMPLATE__ from './edit-vehicle.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

interface RequestDetailsEditVehicleSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class RequestDetailsEditVehicleComponent extends Component<RequestDetailsEditVehicleSignature> {
  @service declare intl: Services['intl'];

  get vehicleFiscalPower() {
    // @ts-expect-error
    let { vehiclePower, isVehicleElectric } = this.args;
    if (vehiclePower) {
      return this.intl.t(
        isVehicleElectric
          ? 'requests.mileage.steps.request-details.fiscal-power-electric'
          : 'requests.mileage.steps.request-details.fiscal-power',
        {
          fiscal_power: vehiclePower,
        }
      );
    }
    if (isVehicleElectric) {
      return this.intl.t('requests.mileage.steps.request-details.electric');
    }
    return vehiclePower;
  }

  @action
  handleClick() {
    // @ts-expect-error
    this.args.onClick?.();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::RequestMileage::RequestDetails::EditVehicle': typeof RequestDetailsEditVehicleComponent;
  }
}
