/* import __COLOCATED_TEMPLATE__ from './summary.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { Disclaimer } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';

import { ObfuscatedIban } from 'qonto/react/components/direct-debit-collections';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';

interface FlowsDirectDebitCollectionsSubscriptionSummarySignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsDirectDebitCollectionsSubscriptionSummaryComponent extends Component<FlowsDirectDebitCollectionsSubscriptionSummarySignature> {
  disclaimerBlock = Disclaimer.Block;

  @service declare intl: Services['intl'];
  @service declare sentry: Services['sentry'];
  @service declare router: Services['router'];
  @service declare segment: Services['segment'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare subscriptionManager: Services['subscriptionManager'];

  get subscription() {
    // @ts-expect-error
    return this.args.context.directDebitSubscription;
  }

  get client() {
    return this.subscription.client;
  }

  get mandate() {
    return this.subscription.mandate;
  }

  get holdAmount() {
    return (
      // @ts-expect-error
      Number(this.subscription.amount.value) * Number(this.args.context.guardingDepositPercentage)
    );
  }

  get guardingPeriodPercentage() {
    // @ts-expect-error
    return this.intl.formatNumber(this.args.context.guardingDepositPercentage, {
      style: 'percent',
    });
  }

  onSaveSubscriptionTask = dropTask(async subscription => {
    let withExistingMandate = Boolean(subscription.mandate?.id);

    try {
      await subscription.save();
    } catch (error) {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.try-later'));

      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      return;
    }

    if (withExistingMandate) {
      this.toastFlashMessages.toastSuccess(
        this.intl.t('sdd-collections.toasts.success.autocharge')
      );

      this.router.transitionTo('direct-debit-collections.processing');
    } else {
      this.toastFlashMessages.toastSuccess(
        this.intl.t('sdd-collections.toasts.success.request-created')
      );

      this.router.transitionTo('direct-debit-collections.processing.payment-link', subscription.id);
    }

    let {
      notifyClient,
      isOneOff,
      isRecurring,
      scheduleType,
      client,
      attachment,
      scheduleTotalCollectionCount,
    } = subscription;

    this.segment.track('incoming-direct-debit-flow_summary_confirmed', {
      notify_client: notifyClient,
      collection_type: isOneOff ? 'one_off' : 'recurring',
      ...(isRecurring && { frequency: scheduleType }),
      ...(isRecurring && {
        duration: scheduleTotalCollectionCount ? 'number_payments' : 'no_limit',
      }),
      client_address: Boolean(client.address),
      attachment: Boolean(attachment),
      // @ts-expect-error
      guarding_percentage: this.args.context.guardingDepositPercentage,
    });
  });

  ObfuscatedIban = ObfuscatedIban;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::DirectDebitCollections::Subscription::Summary': typeof FlowsDirectDebitCollectionsSubscriptionSummaryComponent;
  }
}
