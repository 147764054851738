export default {
  "container": "iH",
  "header-wrapper": "iO mb-16",
  "header": "im",
  "logo": "ip mr-16",
  "title": "ix title-4",
  "description": "iw body-2",
  "conditions": "iN",
  "label": "iv caption mb-4",
  "tags": "io"
};
