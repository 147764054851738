import Model, { attr, belongsTo } from '@ember-data/model';
import { service, type Registry as Services } from '@ember/service';
import { waitFor } from '@ember/test-waiters';
import { tracked } from '@glimmer/tracking';

import CURRENCIES from 'qonto/constants/currencies';
import { NetworkManagerError } from 'qonto/services/network-manager';
// @ts-expect-error
import { errorsArrayToHash } from 'qonto/utils/errors-array-to-hash';
// @ts-expect-error
import { multiply, round } from 'qonto/utils/receivable-invoicing';

/**
 * @typedef DiscountHash
 * @type {object}
 * @property {string} amount
 * @property {string} value
 * @property {string} type
 */

export default class BaseInvoicingDocumentModel extends Model {
  @service declare networkManager: Services['networkManager'];

  /** @type {DiscountHash} */
  // @ts-expect-error
  @attr('hash') discount;
  /** @type {string} */
  @attr('string', { defaultValue: CURRENCIES.default }) declare currency: string;
  /** @type {string} */
  // @ts-expect-error
  @attr locale;
  /** @type {string} */
  // @ts-expect-error
  @attr beneficiaryName;
  /** @type {string} */
  // @ts-expect-error
  @attr contactEmail;
  /** @type {string} */
  // @ts-expect-error
  @attr termsAndConditions;
  /** @type {string} */
  // @ts-expect-error
  @attr stampDutyAmount;
  /** @type {string} */
  // @ts-expect-error
  @attr header;
  /** @type {string} */
  // @ts-expect-error
  @attr footer;
  /** @type {string} */
  // @ts-expect-error
  @attr amountDue;
  /** @type {Date} */
  // @ts-expect-error
  @attr updatedAt;
  /** @type {Date} */
  // @ts-expect-error
  @attr createdAt;
  /** @type {string} */
  // @ts-expect-error
  @attr status;

  /** @type {WelfareFund} */
  // @ts-expect-error
  @belongsTo('receivable-invoice/welfare-fund', { async: false, inverse: null }) welfareFund;
  /** @type {WithholdingTax} */
  // @ts-expect-error
  @belongsTo('receivable-invoice/withholding-tax', { async: false, inverse: null }) withholdingTax;

  /** @type {Customer} */
  // @ts-expect-error
  @belongsTo('client-hub', { async: true, inverse: null }) customer;
  /** @type {Organization} */
  // @ts-expect-error
  @belongsTo('organization', { async: false, inverse: null }) organization;

  // pdfPreviewIframeUrl is not sent to BE, it should be set to null as a tracked property
  @tracked pdfPreviewIframeUrl = null;

  @waitFor
  // @ts-expect-error
  async save() {
    try {
      return await super.save(...arguments);
    } catch (error) {
      // @ts-expect-error
      if (error.isAdapterError) {
        this._assignRelationshipErrors(error);
      }
      throw error;
    }
  }

  clearItemsWithNoId() {
    // @ts-expect-error
    this.items.filter(item => item.get('id') === null).forEach(item => item.deleteRecord());
  }

  // @ts-expect-error
  _handleError(error) {
    if ((error.isAdapterError || error instanceof NetworkManagerError) && error.errors) {
      let errors = errorsArrayToHash(error.errors, { useErrorCode: true });
      this.networkManager.errorModelInjector(this, errors);
    }

    if (error.isAdapterError || error instanceof NetworkManagerError) {
      this._assignRelationshipErrors(error);
    }

    throw error;
  }

  // @ts-expect-error
  _assignRelationshipErrors(error) {
    // reduce
    // [
    //   { attribute: "items/0/title", message: "required" }
    //   { attribute: "items/1/title", message: "required" }
    // ]
    // to
    // {
    //   0: { "title": ["required"] }
    //   1: { "title": ["required"] }
    // }
    // then assign to items
    let parsedErrors = this.errors.reduce((errorsForItems, error) => {
      let [invoiceAttr, index, attribute] = error.attribute.split('/');
      if (invoiceAttr === 'items') {
        error = { attribute, message: error.message };
        errorsForItems[index] = errorsForItems[index] || {};
        errorsForItems[index][attribute] = errorsForItems[index][attribute] || [];
        errorsForItems[index][attribute].push(error.message);
      }
      return errorsForItems;
    }, {});

    let parsedRelationshipErrors = this.errors.reduce(
      (errorsForRelationships, relationshipError) => {
        let [invoiceAttr, attribute] = relationshipError.attribute.split('/');
        if (
          invoiceAttr === 'payment' ||
          invoiceAttr === 'welfareFund' ||
          invoiceAttr === 'withholdingTax'
        ) {
          errorsForRelationships[invoiceAttr] = errorsForRelationships[invoiceAttr] || {};
          errorsForRelationships[invoiceAttr][attribute] =
            errorsForRelationships[invoiceAttr][attribute] || [];
          errorsForRelationships[invoiceAttr][attribute].push(relationshipError.message);
        }
        return errorsForRelationships;
      },
      {}
    );

    Object.entries(parsedErrors).forEach(([index, parsedErrorsForItem]) => {
      // @ts-expect-error
      this.networkManager.errorModelInjector(this.items[index], parsedErrorsForItem, error);
    });

    Object.entries(parsedRelationshipErrors).forEach(([attribute, parsedRelationshipError]) => {
      if (attribute === 'payment') {
        // @ts-expect-error
        this.networkManager.errorModelInjector(this.payment, parsedRelationshipError, error);
      }
      if (attribute === 'welfareFund') {
        // @ts-expect-error
        this.networkManager.errorModelInjector(this.welfareFund, parsedRelationshipError, error);
      }
      if (attribute === 'withholdingTax') {
        // @ts-expect-error
        this.networkManager.errorModelInjector(this.withholdingTax, parsedRelationshipError, error);
      }
    });
  }

  /*
    CALCULATIONS: there are 2 getters for each total
    - one getter for the UI (PDF PREVIEW) that is rounded and fixed to 2 decimals
    - one getter (called precise) for internal calculations that not is rounded and not fixed 
      that is used for further calculations on the document level
    This is done to match the BE calculations, where every argument of the calculation is recalculated (so it needs to be the absolute value)
  */

  /*
    Returning the not rounded result of the calculation, to reuse it for further calculations
  */
  get preciseTotalExcludingVat() {
    // @ts-expect-error
    return this.items.reduce((total, item) => {
      return parseFloat(total) + parseFloat(item.preciseDiscountedTotalExcludingVat);
    }, 0);
  }

  /*
    Rounding the float value is required to avoid imprecise decimals rounding
    When there is a 5 in the third decimal position, JS will round down instead of up
    Example: 0.145  will be parsed as 0.14, when instead the rounded value wanted is 0.15
  */
  get totalExcludingVat() {
    return round(this.preciseTotalExcludingVat, 100).toFixed(2);
  }

  get preciseDiscountedTotalExcludingVat() {
    if (this.discount?.type === 'percentage') {
      return (
        parseFloat(this.preciseTotalExcludingVat) - parseFloat(this.precisePercentageDiscountAmount)
      );
    }

    return this.discount?.type === 'absolute'
      ? parseFloat(this.preciseTotalExcludingVat) - parseFloat(this.preciseTotalDiscount)
      : this.preciseTotalExcludingVat;
  }

  get discountedTotalExcludingVat() {
    return round(this.preciseDiscountedTotalExcludingVat, 100).toFixed(2);
  }

  get totalAmount() {
    let totalAmount =
      // @ts-expect-error
      parseFloat(this.preciseTotalVat) +
      parseFloat(this.preciseDiscountedTotalExcludingVat) +
      parseFloat(this.preciseWelfareFundAmount) -
      parseFloat(this.preciseWithholdingTaxAmount);

    return totalAmount.toFixed(2);
  }

  get totalAmountDue() {
    // @ts-expect-error
    if (this.depositAmount) {
      // @ts-expect-error
      let totalAmountDue = parseFloat(this.totalAmount) - parseFloat(this.depositAmount);
      return totalAmountDue.toFixed(2);
    }
  }

  get preciseTotalVatWithoutWelfareFund() {
    let totalVatWithoutWelfareFund = 0;
    // if there is a discount, the total vat is calculated after applying the document discount on each item
    if (this.discount?.value && this.vatRates.length > 0) {
      totalVatWithoutWelfareFund = this.calculateVatSubtotals.reduce((total, vatSubtotal) => {
        // @ts-expect-error
        return parseFloat(total) + parseFloat(vatSubtotal.preciseTotalVat);
      }, 0);
    } else {
      // if no discount the items total vat are precised and can be used
      // @ts-expect-error
      totalVatWithoutWelfareFund = this.items.reduce((total, item) => {
        return parseFloat(total) + parseFloat(item.preciseTotalVat);
      }, 0);
    }

    return totalVatWithoutWelfareFund;
  }

  get totalVatWithoutWelfareFund() {
    return round(this.preciseTotalVatWithoutWelfareFund, 100).toFixed(2);
  }

  get preciseTotalVat() {
    return (
      // @ts-expect-error
      parseFloat(this.preciseTotalVatWithoutWelfareFund) +
      parseFloat(this.preciseWelfareFundVatAmount)
    );
  }

  get totalVat() {
    return round(this.preciseTotalVat, 100).toFixed(2);
  }

  get preciseWelfareFundVatAmount() {
    return this.welfareFund?.rate
      ? multiply(
          // @ts-expect-error
          parseFloat(this.preciseTotalVatWithoutWelfareFund),
          parseFloat(this.welfareFund.rate)
        )
      : '0.00';
  }

  get welfareFundVatAmount() {
    return round(this.preciseWelfareFundVatAmount, 100).toFixed(2);
  }

  get preciseWelfareFundAmount() {
    return this.welfareFund?.rate
      ? multiply(
          parseFloat(this.preciseDiscountedTotalExcludingVat),
          parseFloat(this.welfareFund.rate)
        )
      : '0.00';
  }

  get welfareFundAmount() {
    return round(this.preciseWelfareFundAmount, 100).toFixed(2);
  }

  get preciseWithholdingTaxAmount() {
    if (!this.withholdingTax?.rate) return '0.00';

    if (this.welfareFund?.type === 'TC22')
      return multiply(
        parseFloat(this.preciseDiscountedTotalExcludingVat) +
          parseFloat(this.preciseWelfareFundAmount),
        parseFloat(this.withholdingTax.rate)
      );

    return multiply(
      parseFloat(this.preciseDiscountedTotalExcludingVat),
      parseFloat(this.withholdingTax.rate)
    );
  }

  get withholdingTaxAmount() {
    return round(this.preciseWithholdingTaxAmount, 100).toFixed(2);
  }

  // Document discount calculations
  get precisePercentageDiscountAmount() {
    return this.preciseTotalExcludingVat && this.discount?.value
      ? multiply(this.discount.value, this.preciseTotalExcludingVat)
      : 0;
  }

  get percentageDiscountAmount() {
    return round(this.precisePercentageDiscountAmount, 100).toFixed(2);
  }

  get preciseTotalDiscount() {
    if (this.discount?.type === 'absolute') {
      let sign = Math.sign(this.totalExcludingVat);

      return this.discount.value * sign;
    } else if (this.discount?.type === 'percentage') {
      return this.precisePercentageDiscountAmount;
    } else {
      return 0;
    }
  }

  get totalDiscount() {
    return round(this.preciseTotalDiscount, 100).toFixed(2);
  }

  // Subtotals for each VAT rate

  // display subtotals for each VAT rate if more than one rate is present
  get displayEachVatSubtotals() {
    return this.vatRates.length > 1;
  }

  // all the unique different vat rates used in the document saved in an array
  get vatRates() {
    // @ts-expect-error
    return [...new Set(this.items.map(item => item?.vatRate))].sort();
  }

  // an array of objects for each unique different vat rates with their calculated totals rounded for the UI
  get vatSubtotals() {
    // @ts-expect-error
    let vatSubtotals = [];
    if (this.displayEachVatSubtotals) {
      vatSubtotals = this.calculateVatSubtotals.map(subtotal => {
        return {
          rate: subtotal.rate,
          vatTotal: subtotal.preciseTotalVat.toFixed(2),
          totalExcludingVat: subtotal.preciseTotalExcludingVat.toFixed(2),
        };
      });
    }

    // @ts-expect-error
    return vatSubtotals;
  }

  // an array of objects for each unique different vat rates with their calculated totals precise and not rounded
  get calculateVatSubtotals() {
    let preciseVatSubtotals = [];
    if (this.vatRates.length > 0) {
      for (let index = 0; index < this.vatRates.length; index++) {
        let rate = this.vatRates[index];

        let preciseTotalExcludingVat = 0;
        let preciseTotalVat = 0;

        // @ts-expect-error
        for (let item of this.items) {
          if (rate === item?.vatRate) {
            preciseTotalExcludingVat += parseFloat(item.preciseDiscountedTotalExcludingVat);
            preciseTotalVat += parseFloat(item.preciseTotalVat);
          }
        }

        // in the case of a discount on the document level, each item needs to have a part of the document discount applied
        if (this.discount?.value) {
          // precise total excluding vat for each vat rate calculated after applying the discount
          preciseTotalExcludingVat = multiply(
            // @ts-expect-error
            parseFloat(preciseTotalExcludingVat),
            // @ts-expect-error
            parseFloat(this.totalExcludingVatDiscountRatio)
          );

          // precise total vat for each vat rate calculated after applying the discount
          preciseTotalVat = multiply(preciseTotalExcludingVat, rate);
        }

        preciseVatSubtotals.push({
          rate,
          preciseTotalVat,
          preciseTotalExcludingVat,
        });
      }
    }

    return preciseVatSubtotals;
  }

  // discounted total excluding vat divided by total excluding vat
  get totalExcludingVatDiscountRatio() {
    return (
      parseFloat(this.preciseDiscountedTotalExcludingVat) /
      parseFloat(this.preciseTotalExcludingVat)
    );
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'receivable-invoice/base': BaseInvoicingDocumentModel;
  }
}
