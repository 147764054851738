import { template as template_7191e492c09641db94067bc52f32dcf8 } from "@ember/template-compiler";
import { on } from '@ember/modifier';
import themedAsset from '@qonto/ui-kit/helpers/themed-asset';
import { t } from 'ember-intl';
import svgJar from 'ember-svg-jar/helpers/svg-jar';
import styles from './step-selectors.strict-module.css';
import ApproversSelector from './step-selectors/approvers-selector';
import OperationSelector from './step-selectors/operation-selector';
const StepSelectors = template_7191e492c09641db94067bc52f32dcf8(`
  <div class={{styles.rule-selectors}} ...attributes>
    <OperationSelector
      @selectedOperation={{@selectedOperation}}
      @handleOperationTypeChange={{@handleOperationTypeChange}}
      @validateOperation={{@validateOperation}}
      @operationErrors={{@operationErrors}}
    />
    <ApproversSelector
      @approversOptions={{@approversOptions}}
      @onApproversChange={{@onApproversChange}}
      @handleApproversSelectorClose={{@handleApproversSelectorClose}}
      @approversErrors={{@approversErrors}}
      @removeInvalidUsersFromStep={{@removeInvalidUsersFromStep}}
      @shouldApproversSelectorBeOpen={{@shouldApproversSelectorBeOpen}}
      @lastApproverSelected={{@lastApproverSelected}}
      data-test-step-selectors-approvers-selector
    />
    <button
      class='btn btn--icon-only btn--tertiary btn--medium ml-8'
      disabled={{@isRemoveButtonDisabled}}
      aria-label={{t 'a11y.buttons.delete-aria-label'}}
      type='button'
      {{on 'click' @handleStepRemoval}}
      data-test-delete-button
    >
      {{svgJar (themedAsset 'icon_trash_outlined') aria-hidden='true'}}
    </button>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default StepSelectors;
