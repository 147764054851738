export default {
  "sidebar-section": "cPg body-2",
  "sidebar-section-header": "cPD body-1 mb-4",
  "providers-logos": "cPV",
  "risks": "cPL",
  "tag": "cPT tag",
  "external-link-icon": "cPz",
  "disclaimer": "cPa",
  "divider": "cPH"
};
