export const FRENCH_EINVOICING_RECEIVE_INVOICES_STATUS = {
  enabled: 'enabled',
  no_qonto: 'no_qonto',
  disabled: 'disabled',
  pending_creation: 'pending_creation',
  pending_deletion: 'pending_deletion',
};

export const FRENCH_EINVOICING_STATUS_COLORS = {
  enabled: 'green',
  no_qonto: 'red',
  disabled: 'red',
  pending_creation: 'grey',
  pending_deletion: 'grey',
};
